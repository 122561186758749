import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class NavbarService {

  constructor() { }
  public abstract getMenuItems():Observable<JSON>
}
