import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-update-course-seat',
  templateUrl: './update-course-seat.component.html',
  styleUrls: ['./update-course-seat.component.css']
})


export class UpdateCourseSeatComponent implements OnInit {
  actionApi = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(params)=>{
        this.formUrl = environment.apiUrl + `api/lms/courses/seats/${params["seatId"]}/update`
      }
    })
  }

}
