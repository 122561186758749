<div class="basic-user-details formMain form-maroon-border form-default-border" *ngIf="userResponse">
    <div class="details-title">Servis Hesabı Bilgileri</div>
    <br/>
    <div class="basic-user-details-item">
        <p><b>Kullanıcı Adı : </b>{{userResponse.username}}</p>
        <p><b>Servis Adı : </b>{{userResponse.name}}</p>
    </div>
    <div class="active-sessions-container" *ngIf="activeSessions">
        <div class="details-title">Aktif Oturumlar</div>
        <br/>
        <div class="active-session-item" *ngFor="let session of activeSessions.activeSessionItems">
            <p style="margin: 0;"><b>Oturum Id : </b> {{session.sessionId}}</p>
            <p style="margin: 0;"><b>Oluşturma : </b> {{session.created}}</p>
            <p style="margin: 0;"><b>Son Erişim : </b> {{session.lastAccess}}*</p>
        </div>
        <div *ngIf="activeSessions.activeSessionItems.length == 0 && sessionLoading == false">
            <div class="alert alert-info" role="alert">
                Aktif oturum mevcut değil.
              </div>
        </div>
    </div>
</div>
<lib-get-assigned-service-roles [extUserId]="userResponse.id"></lib-get-assigned-service-roles>

