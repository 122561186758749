<div class="dashboardBasicMain row">
    <div class="col-md-4">
        <div class="basicItem">
            <p class="itemLabel">Host Name</p>
            <p class="itemValue">{{hostBasic.hostName}}</p>
        </div>
    </div>
    <div class="col-md-4">
        <div class="basicItem">
            <p class="itemLabel">Type Name</p>
            <p class="itemValue">{{hostBasic.typeName}}</p>
        </div>

    </div>
    <div class="col-md-4">
        <div class="basicItem">
            <p class="itemLabel">Status</p>
            <p class="itemValue">{{hostBasic.status}}</p>
        </div>

    </div>
</div>