import { ActivatedRoute } from '@angular/router';
import { FormResponseObject } from './../../../../../usorta-library/src/lib/models/CustomFormModels/formResponseObject';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
  formId:string = "";
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(response=>{
      this.formId = response["formId"]
    })
  }
  getResponse(response:FormResponseObject){
  }

}
