import { Component, Input, OnInit } from '@angular/core';
import { TenantService } from '../../services/tenant/tenant.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-session-loader',
  templateUrl: './session-loader.component.html',
  styleUrls: ['./session-loader.component.css']
})
export class SessionLoaderComponent implements OnInit {
  siteInfo:any;
  logo:string;
  @Input() error:boolean[] = [false];
  @Input() startSocket: () => void;
  @Input() status:number[] = [-1];
  constructor(private tenantService:TenantService) { }

  ngOnInit(): void {
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo != null){
      this.siteInfo = JSON.parse(tenantInfo);
      this.logo = this.siteInfo.applicationConfiguration.logo as string
    }else{
      this.tenantService.getAppsettings(environment.applicationId,environment.tenantId ?? undefined).subscribe((response)=>{
        this.siteInfo = response.content as any;
        console.log(this.siteInfo);
        this.logo = this.siteInfo.applicationConfiguration.logo as string;
        //alert(this.siteInfo.tenant.logo);
      })
    }

  }
  startSocketFnc() {
    this.startSocket();
  }

}
