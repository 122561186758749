<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-xs-12">
                <div class="courseInfo">
                    <h4><i class="bi bi-info-circle"></i> {{'courses.detailsForEducationVersion' | translate}} <!-- <button class="btn btn-warning xsBtn sectionActionButton"><i class="bi bi-pencil-fill"></i> Eğitimi Düzenle</button> --> </h4>
                    <hr>
            
                    <label *ngIf="versionDetail.name"><b>{{'courseManagement.versionName' | translate}} : </b><font class="detailValue">{{versionDetail.name}}</font></label>

                    <label *ngIf="versionDetail.courseName"><b>{{'courses.versionRelatedCourse' | translate}}: </b> <font class="detailValue">{{versionDetail.courseName}}</font></label>

                    <label *ngIf="versionDetail.codeSuffix"><b>Eğitim Kodu Eki : </b> <font class="detailValue">{{versionDetail.codeSuffix}}</font></label>

                    <label *ngIf="versionDetail.imageId"><b>{{'courseManagement.courseImage' | translate}} </b><br/><img class="courseImage" [src]="getImage(versionDetail.imageId)" /></label>
                    <label *ngIf="versionDetail.teaserText"><b>{{'courses.versionDescription' | translate}}</b><br/><font class="courseDescription">{{versionDetail.teaserText}}</font></label>


                    <label><button *ngIf="versionDetail.description" class="btn btn-secondary" (click)="setDescriptionDetail()">Detaylı Açıklayı Görüntüle</button></label>
                    <label *ngIf="versionDetail.themeName"><b>{{'courses.themeName' | translate}} : </b> <font class="detailValue">{{versionDetail.themeName}}</font></label>

                    <label *ngIf="versionDetail.passingScore"><b>{{'courses.passingPoint' | translate}} : </b> <font class="detailValue">{{versionDetail.passingScore}}</font></label>

                    <label *ngIf="versionDetail.passingPct"><b>{{'courses.passingPct' | translate}} : </b> <font class="detailValue">{{versionDetail.passingPct}}</font></label>

                    <label *ngIf="versionDetail.maxScore"><b>{{'courses.maxPoint' | translate}} : </b> <font class="detailValue">{{versionDetail.maxScore}}</font></label>

                    <label><b>{{'courseManagement.published' | translate}} : </b> <input type="checkbox" [checked]="versionDetail.active" [readOnly]="true" onclick="return false"></label>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="row">
            <div class="col-xs-12">

            </div>
        </div>