import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-list-course-seats',
  templateUrl: './list-course-seats.component.html',
  styleUrls: ['./list-course-seats.component.css']
})
export class ListCourseSeatsComponent implements OnInit {
  tableUrl:string = environment.apiUrl+"api/lms/courses/seats/list";
  constructor() { }

  ngOnInit(): void {
  }

}
