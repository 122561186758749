import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems } from '../../models/menu/menuItems';

import { TenantInfoModel } from '../../models/TenantModels/tenantInfoModel';
import { LoggedUserListenerService } from '../../services/listener/logged-user-listener.service';
import { LoginService } from '../../services/login/login.service';
import { NavbarService } from '../../services/navbar/navbar.service';
import { GlobalOkCancelEventService } from '../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CmsImageService } from '../../services/cmsImages/cms-image.service';
declare var window:any;
@Component({
  selector: 'lib-custom-navbar',
  templateUrl: './custom-navbar.component.html',
  styleUrls: ['./custom-navbar.component.css']
})
export class CustomNavbarComponent implements OnInit {
  @Input() menuItems:MenuItems[];
  siteInfo:TenantInfoModel;
  loginSubscribe:any;
  isLogged:boolean;
  updateLoggedUserSubscribe:any;
  logo:string;
  constructor(private cmsImageService:CmsImageService,private globalOkCancel:GlobalOkCancelEventService,private navigationService:NavigationService,private navbarService:NavbarService,private loggedUserListener:LoggedUserListenerService,private loginService:LoginService,private router:Router) { }

  ngOnInit(): void {
    this.getSiteInfo();
    this.loggedUserSubscribe();
    //this.getMenuItems();

  }
  getSiteInfo(){
    let tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.siteInfo = JSON.parse(tenantInfo).applicationConfiguration;
      this.logo = this.siteInfo.logo;
      if(!this.siteInfo.logo.includes("/")){
        this.logo = this.cmsImageService.getUrl(this.siteInfo.logo);
      }
    }

  }
  getMenuItems(){
    this.navbarService.getMenuItems().subscribe(response=>{
      let json:string = JSON.stringify(response);
      this.menuItems = JSON.parse(json).items;
      console.log(this.menuItems);
    });
  }
  loggedUserSubscribe(){
    this.loginSubscribe = this.loggedUserListener.loggedUserStatus$.subscribe(response=>{
      console.log(response);
      if(response == 'false'){
        this.isLogged = false;
        this.loggedControlWithExpiration();
      }else{
        this.isLogged = true;
      }
    }
    );
  }
  loggedControlWithExpiration(){
    this.isLogged = this.loginService.isLoggedControlWithExpiration();
  }
  getName(){
    return sessionStorage.getItem('firstname');
  }
  getTarget(item:MenuItems){
    if(item.target){
      return item.target;
    }else{
      return '_self';
    }
  }
  redirect(item:MenuItems){
    if(window['exitNotify'] !== undefined && window['exitNotify']['notify'] == true && window['exitNotify']['notifyUrl'] == this.router.url){
      this.globalOkCancel.showOkCancelWindow(window['exitNotify']['notifyTitle'],window['exitNotify']['notifyMessage']).then(result=>{
        if(result){
          if(window['exitNotify']['notifyAction'] !== undefined){
            window['exitNotify'].notifyAction();

          }
          this.redirectProcess(item);
          window['exitNotify'] = undefined;
        }
      })

    }else{
      this.redirectProcess(item);
    }

  }
  redirectProcess(item:MenuItems){
    console.log(item.link);
    if(item.link.startsWith('http') || item.link.startsWith('https')){
      window.open(item.link,this.getTarget(item));
    }else{
      this.router.navigate([item.link]);
    }
  }
  logout(){
    this.loginService.logout();
    this.updateLoggedUserStatus();


  }
  updateLoggedUserStatus(){
    if(this.updateLoggedUserSubscribe){
      this.updateLoggedUserSubscribe.unsubscribe();
    }
    this.updateLoggedUserSubscribe = this.loggedUserListener.getLoggedUserStatus('false').subscribe();
  }

}
