import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { CourseContentService } from '../../../services/education/course-content.service';
import { CourseContentDetailsModel } from '../../../models/CourseContentModels/courseContentDetailsModel';

@Component({
  selector: 'lib-manage-selected-content-panel',
  templateUrl: './manage-selected-content-panel.component.html',
  styleUrls: ['./manage-selected-content-panel.component.css']
})
export class ManageSelectedContentPanelComponent implements OnInit, OnChanges {
  @Input() selectedItem: string[] = []
  @Output() updateContents: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  selectedContentId: string;
  details: CourseContentDetailsModel;
  constructor(private globalModalService: GlobalModalService, private courseContentService: CourseContentService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedContentId == null || this.selectedItem[0] != this.selectedContentId) {
      this.selectedContentId = this.selectedItem[0];
      this.getContentInfo();
    }
  }

  ngOnInit(): void {
  }
  setEditPanel() {

  }
  openContentEditPanel() {
    var modal = this.globalModalService.showModal({
      data: "/modal/lms/courses/content/" + this.selectedContentId + "/update",
      helperModals: [],
      title: "",
      type: ModalTypes.LINK
    }, true)
    modal['onclose'].subscribe({
      next: () => {
        this.updateContents.emit(true);
      }
    })
  }
  openContentDeletePanel() {
    var modal = this.globalModalService.showModal({
      data: "/modal/lms/courses/content/" + this.selectedContentId + "/delete",
      helperModals: [],
      title: "",
      type: ModalTypes.LINK
    }, false)
    modal['onclose'].subscribe({
      next: () => {
        this.updateContents.emit(true);
      }
    })
  }
  getContentInfo() {
    this.courseContentService.getContentDetail(this.selectedContentId).subscribe({
      next: (response) => {
        this.details = response.content;
      }
    })
  }
  viewContentPanel(){
    this.globalModalService.showModal({
      data:"/full-page/outline-editor/media-item/"+this.details.mediaId+"/view",
      helperModals:[],
      title:"",
      type:ModalTypes.LINK
    },false)
  }
  getFormat(format: number) {
    switch (format) {
      case 0:
        return "PlainText";
      case 1:
        return "Html";
      case 2:
        return "Markdown";
      case 3:
        return "Json";
      case 4:
        return "Xml";
      case 5:
        return "Layout";
    }
    return "undefined";
  }

}
