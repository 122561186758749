import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { UpdateVmInVirtualLabModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/updateVmInVirtualLab';
import { VmInLabModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmInLabModel';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-update-vm-in-lab',
  templateUrl: './update-vm-in-lab.component.html',
  styleUrls: ['./update-vm-in-lab.component.css']
})
export class UpdateVmInLabComponent implements OnInit {
  labId:string;
  vmId:number;
  vmInfo:VmInLabModel;
  networks:ListItemModel[];
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(params)=>{
        this.labId = params["labId"];
        this.vmId = parseInt(params["vmId"]);
        this.getVmInLabInfo();
        this.getNetworks();
      }
    })
  }
  getNicMappings(){
    try {
      var dataList:{nicName:string,network:string,ipAddress:string}[] = [];
      this.vmInfo.nicMappings.forEach((nic)=>{
        dataList.push({
          nicName:nic.nicName,
          ipAddress:(document.getElementById("ipAddress_"+nic.nicName) as HTMLInputElement).value,
          network:(document.getElementById("network_"+nic.nicName) as HTMLInputElement).value
        })
      })
      console.log(dataList);
      return dataList;
    }catch{
      throw "";
    }


  }
  getVmInLabInfo(){
    this.virtualLabService.getVmInLab(this.labId,this.vmId).subscribe({
      next:(response)=>{
        this.vmInfo = response.content;
      }
    })
  }
  getNetworks(){
    this.virtualLabService.getNetworks(this.labId).subscribe({
      next:(response)=>{
        this.networks = response.content;
      }
    })
  }
  updateVm(){

  
      var nicMap = this.getNicMappings();
      var identifier = (document.getElementById("identifier") as HTMLInputElement)
      var name = (document.getElementById("name") as HTMLInputElement)
      var notes = (document.getElementById("notes") as HTMLTextAreaElement)
      var updateVmModel:UpdateVmInVirtualLabModel = {
        id: this.vmId,
        labId: this.labId,
        identifier: identifier.value,
        name: name.value,
        nicMappings: nicMap,
        notes: notes.value,

      }
      console.log(updateVmModel);
      this.virtualLabService.updateVirtualMachineInLab(this.labId,updateVmModel).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("lab.updateVmInLab.success"));
        },
        error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })

  }

}
