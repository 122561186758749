<form class="formMain form-default-border form-maroon-border" *ngIf="showForm == true" [formGroup]="categoryForm">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.updateCategory' | translate}}</font>
    </div>
    <input type="hidden" name="leafId" id="leafId" formControlName="leafId">
    <label class="title mainLabel" for="parentId">{{'courseManagement.parentCategory' | translate}}</label>
    <select class="form-select" id="parentId" name="parentId" formControlName="parentId">
        <option [value]=null>{{'courseManagement.noSelection' | translate}}</option>
        <option *ngFor="let category of pCategoryList" [value]="category.value">{{category.label}}</option>
    </select>
    <br /><br />
    <label class="title mainLabel" for="name">{{'courseManagement.categoryName' | translate}}</label> <input class="form-control form-control"
        id="name" formControlName="name" type="text" [placeholder]="'courseManagement.categoryName' | translate"
        aria-label="Kategori adı">
    <br />
    <label for="isActive" class="mainLabel"><input type="checkbox" id="active" name="active" formControlName="active" />
        {{'sessionManagement.active' | translate}}</label>
    <br /><br />
    <label class="title mainLabel" for="color">{{'courseManagement.categoryColor' | translate}}</label><br /><input type="color" id="color" name="color"
        formControlName="color">
    <br /><br />
    <label class="title mainLabel" for="options">{{'courseManagement.options' | translate}}</label>
    <br />
    <label *ngFor="let option of options" class="mainLabel" style="display: block;"><input type="checkbox" formControlName="options"
            name="options" id="options_{{option.value}}" [value]="option.value">{{option.name}}</label>
    <br />
    <label class="title mainLabel" for="sortValue">{{'courseManagement.priority' | translate}}</label>
    <input class="form-control" type="number" id="sortValue" formControlName="orderValue" name="orderValue" />
    <div style="text-align: right;">
        <button class="btn btn-primary" [disabled]="loading" (click)="updateEducationCategory()">{{'btn.update' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
    </div>
    
</form>

