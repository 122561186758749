import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-customize-add-new-user',
  templateUrl: './customize-add-new-user.component.html',
  styleUrls: ['./customize-add-new-user.component.css']
})
export class CustomizeAddNewUserComponent implements OnInit {
  addNewUserForm:FormGroup
  vmId:string;
  constructor(private translateService:TranslateService,private toastrService:ToastrService,private virtualLabService:VirtualLabService,private formBuilder:FormBuilder,private activatedRoute:ActivatedRoute) { }

  createForm(){
    this.addNewUserForm = this.formBuilder.group({
      username:["",[Validators.required,Validators.pattern("^[a-zA-Z1-9]*$")]],
      password:[null],
      homeDir:[null],
      groups:[""],
      systemUser:[false],
      shell:[""]
    })
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
        this.createForm();
      }
    })

  }
  togglePasswordIcon(){
    var inp = document.getElementById("password") as HTMLInputElement;
    return inp.type == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"
  }
  togglePasswordPreview(){
    var inp = document.getElementById("password") as HTMLInputElement;
    inp.type = inp.type == "password" ? "text" : "password"
  }
  errorControl(name:string){
    if(this.addNewUserForm.get(name)?.errors){
      return true;
    }
    return false;
  }
  addUser(){
    if(this.addNewUserForm.valid){
      var formValues:any = this.addNewUserForm.value;
      var customizationModel:VMCustomization = {
        type: "new-user",
        args: JSON.stringify(formValues)
      }
      console.log(customizationModel);
      this.virtualLabService.addCustomizationToVm(this.vmId,customizationModel).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmCustomization.addedParameter"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }

}
