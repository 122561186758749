import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-delete-session-template-feature',
  templateUrl: './delete-session-template-feature.component.html',
  styleUrls: ['./delete-session-template-feature.component.css']
})
export class DeleteSessionTemplateFeatureComponent implements OnInit {
  checkedUnderstandCheck:boolean = false;
  featureId:number | null;
  templateId:number | null;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.featureId = param['featureId'];
      this.templateId = param['templateId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  
  deleteTemplateFeature(){
    if(this.featureId != null && this.templateId != null){
      this.sessionTemplateService.deleteSessionTemplateFeature(this.templateId,this.featureId).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
            this.success = true;
          }else{
            this.toastrService.error(this.translateService.instant("global.deletedError"));
          }
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }

  }

}
