import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-clone-lab-draft',
  templateUrl: './clone-lab-draft.component.html',
  styleUrls: ['./clone-lab-draft.component.css']
})
export class CloneLabDraftComponent implements OnInit {
  draftId:string;
  actionUrl:string = environment.apiUrl; 
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.draftId = param["draftId"];
        this.formUrl = environment.apiUrl + `api/v2/range/lab/drafts/${this.draftId}/clone`
      }
    })
  }

}
