import { Component, OnInit } from '@angular/core';
declare var cytoscape: any;
@Component({
  selector: 'lib-lab-network-graph',
  templateUrl: './lab-network-graph.component.html',
  styleUrls: ['./lab-network-graph.component.css']
})
export class LabNetworkGraphComponent implements OnInit {
  graph: any;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() =>
      {
        this.graph = document.getElementById("cy");
        var cy = cytoscape({
          container: this.graph,
          elements: {
            nodes: [
              {
                data: { id: 'net1' },
                position: { x: 400, y: 200 }
              },
  
              {
                data: { id: 'net2' },
                position: { x: 150, y: 100 }
              },
              {
                data: { id: 'firewall' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'red2' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'red1' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'webserver' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'wan' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'office1' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'office2' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'office3' },
                position: { x: 100, y: 150 },
                style: {
                  'background-image': [
                    'https://upload.wikimedia.org/wikipedia/commons/b/b4/High_above_the_Cloud_the_Sun_Stays_the_Same.jpg',
                    'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Pigeon_silhouette_4874.svg/1000px-Pigeon_silhouette_4874.svg.png'
                  ],
                  'background-fit': 'cover cover',
                  'background-image-opacity': 0.5
                }
              },
              {
                data: { id: 'debian' },
                position: { x: 100, y: 150 },
              },
              {
                data: { id: 'printServer' },
                position: { x: 100, y: 150 },
              },
            ],
            edges: [
              {
                data: { id: 'wanred2', source: 'red2', target: 'wan' }
  
              },
              {
                data: { id: 'wanred1', source: 'red1', target: 'wan' }
              },
              {
                data: { id: 'wanfirewall', source: 'wan', target: 'firewall' }
              },
              {
                data: { id: 'net1webserver', source: 'webserver', target: 'net1' }
              },
              {
                data: { id: 'firewallnet1', source: 'firewall', target: 'net1' }
              },
              {
                data: { id: 'webservernet2', source: 'webserver', target: 'net2' }
              },
              {
                data: { id: 'of1net2', source: 'office1', target: 'net2' }
              },
              {
                data: { id: 'of2net2', source: 'office2', target: 'net2' }
              },
              {
                data: { id: 'of3net2', source: 'office3', target: 'net2' }
              },
              {
                data: { id: 'net2deb', source: 'debian', target: 'net2' }
              },
              {
                data: { id: 'psent2', source: 'printServer', target: 'net2' }
              },
  
            ]
          },
  
          layout: {
            name: 'cose',
            animate:false,
            fit:true,
            nodeDimensionsIncludeLabels:false
          },
  
          // so we can see the ids
          style: [
            {
              selector: 'node',
              style: {
                'label': 'data(id)'
              }
            }
          ]
        })
        cy.on('tap', 'node', function(evt:any){
          console.log('event: tap node');
          console.log(evt.target.id());
        });
        cy.on('tap', 'edge', function(evt:any){
          console.log('event: tap edge');
          console.log(evt.target.id());
        });
        cy.on('unselect', function(evt:any){
          console.log('event: unselect');
          console.log('node:  ' + cy.$('node:selected').length);
          console.log('edge:  ' + cy.$('edge:selected').length);
        });
        cy.style().selector('edge')
        .style({
        'width': 3,
        'line-color': 'yellow'
      }).selector('node').style({
        'border-radius':'0px',
        'border':"1px solid black"
          })
      }, 500)
  }
  

}
