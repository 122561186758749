<div class="formMain form-default-border form-maroon-border">
    <div style="margin-bottom: 3px;">
        <font class="form-title-content">{{'localizedstrings.details.pagetitle' | translate}}</font>
    </div>
    <div *ngIf="localizedStringResponse">
        <div class="mb-3">
            <b>Key : </b>
            <font>{{localizedStringResponse.key}}</font>
        </div>
        <div class="mb-3">
            <b>Locale : </b>
            <font>{{localizedStringResponse.locale}}</font>
        </div>
        <div class="mb-3">
            <b>Application : </b>
            <font>{{localizedStringResponse.application}}</font>
        </div>
        <div class="mb-3">
            <b>Tenant : </b>
            <font>{{localizedStringResponse.tenant}}</font>
        </div>
        <div class="mb-3">
            <b>Format : </b>
            <font>{{localizedStringResponse.format}}</font>
        </div>
        <div class="mb-3">
            <lib-preview-text [allowHtml]="allowHtml" [text]="localizedStringResponse.value"></lib-preview-text>
        </div>
    </div>

</div>
<br/><br/>
<div *ngIf="tableUrl">
    <lib-custom-table [externalAPIUrl]="tableUrl"></lib-custom-table>
</div>