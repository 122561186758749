import { ElementRef, Injectable,ViewContainerRef } from '@angular/core';
import { ModalCreateModel } from '../../models/ModalManagementModels/modalCreateModel';
import { GlobalModalComponent } from '../../components/global-modal/global-modal.component';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {
  public subscriber:Subscriber<any>;
  public viewContainerRef:ViewContainerRef;
  public elementRef:ElementRef<HTMLDivElement>

  set globalModalRef(vcr:ViewContainerRef,){
    this.viewContainerRef = vcr;

  }
  set globalElementRef(elr:ElementRef){
    this.elementRef = elr;
  }

  showModal(modalData:ModalCreateModel,wide?:boolean,width?:string){
    let createdComponent = this.viewContainerRef.createComponent(GlobalModalComponent);
    createdComponent.instance.modalCreateData = modalData;

    if(width){
      createdComponent.instance.width = width;
    }
    if(wide){
      createdComponent.instance.wide = wide;
    }
    createdComponent.changeDetectorRef.detectChanges();
    this.elementRef.nativeElement.innerHTML = "";
    this.elementRef.nativeElement.append(createdComponent.location.nativeElement);
    var anyCreatedComponent = createdComponent as any;
    anyCreatedComponent["onclose"] = createdComponent.instance.closeEvent;
    return anyCreatedComponent;
  }
}
