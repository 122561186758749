import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionOptionEnum } from '../../../models/questionManagementModels/questionOptionsEnum';
import { LocaleService } from '../../../services/locale/locale.service';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ToastrService } from 'ngx-toastr';
import { CreateQuestionModel } from '../../../models/questionManagementModels/createQuestionModel';
import { ActivatedRoute } from '@angular/router';
import { CreateQuestionTranslationModel } from '../../../models/questionManagementModels/createQuestionTranslationModel';
import { QuestionModel } from '../../../models/questionManagementModels/questionModel';
import { TranslateService } from '@ngx-translate/core';
import { QuestionFormatConst, QuestionFormatEnum } from '../../../models/questionManagementModels/questionFormatEnum';
import { UpdateQuestionFieldModel } from '../../../models/questionManagementModels/updateQuestionFieldModel';
declare var window:any;
@Component({
  selector: 'lib-add-question-translation',
  templateUrl: './add-question-translation.component.html',
  styleUrls: ['./add-question-translation.component.css']
})
export class AddQuestionTranslationComponent implements OnInit {
  questionId:number = 0;
  selectedType:number = 0;
  questionFormat:number[] = [0];
  questionTextValue:any;
  wrongAnswerInfoFormat:number[] = [0];
  wrongAnswerInfoValue:any;
  languages:ListItemModel[] = [];
  questionForm:FormGroup;
  setList:ListItemModel[] = [];
  loading:boolean = false;
  selectedSets:string[] = [];
  formCreated=false;
  baseQuestionInfo:UpdateQuestionFieldModel;
  questionOptions = Object.entries(QuestionOptionEnum).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]}
  }).filter(x=>x.value != 0);
  questionFormats = Object.entries(QuestionFormatConst).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]}
  })
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private formBuilder:FormBuilder,private localeService:LocaleService,private questionService:QuestionService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = parseInt(param["id"]);
      }
    })
    this.getLanguages();
    this.getBaseQuestionInfo()
    
  }
  getQuestionFormatName(format:number){
    return this.questionFormats.find(x=>x.value == format)?.key ?? "";
  }
  getBaseQuestionInfo(){
    this.questionService.updateQuestionFields(this.questionId).subscribe({
      next:(response)=>{
        this.baseQuestionInfo = response.content;
        this.selectedType = this.baseQuestionInfo.type;
        var sets:string[] = [];
        this.baseQuestionInfo.sets.forEach(a=>sets.push(a.toString()));
        this.selectedSets = sets;
        this.createQuestionForm();
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getLanguages(){
    this.localeService.getLocalesWithSelectListSchema().subscribe({
      next:(response)=>{
        this.languages = response.content;
        this.getQuestionSets();
      }
    })
  }
  getQuestionSets(){
    this.questionService.getQuestionSetList().subscribe({
      next:(response)=>{
        this.setList=response.content;
        this.getBaseQuestionInfo();
      }
    })
  }
  questionFormatChange(ev:any){
    var el = ev.target as HTMLSelectElement;
    this.questionFormat = [parseInt(el.value)]
  }
  questionFormatValue(ev:any){
    this.questionTextValue = ev;
  }

  wrongAnswerInfoFormatChange(ev:any){
    var el = ev.target as HTMLSelectElement;
    this.wrongAnswerInfoFormat = [parseInt(el.value)]
  }
  wrongAnswerInfoFormatValue(ev:any){
    this.wrongAnswerInfoValue = ev;
  }

  typeChange(ev:Event){
    console.log(ev);
    let el = ev.target as HTMLInputElement;
    this.selectedType = parseInt(el.value)
    console.log(el.value);
  }
  createQuestionForm(){


    this.questionForm = this.formBuilder.group({
      name: ["",Validators.required],
      questionText: ["",Validators.required],
      type:[this.baseQuestionInfo.type],
      format:[0],
      active:[false],
      locale:[null,Validators.required],
      trueFalseAnswer:[this.baseQuestionInfo.trueFalseAnswer],
      shortAnswerRegex:[""],
      shortAnwserMinMatchScore:[this.baseQuestionInfo.shortAnwserMinMatchScore],
      minNumChoices:[this.baseQuestionInfo.minNumChoices],
      maxNumChoices:[this.baseQuestionInfo.maxNumChoices],
      wrongAnswerInfo:[""],
      wrongAnswerInfoFormat:[0],
      options:[this.baseQuestionInfo.options],
      customType:[this.baseQuestionInfo.customType],
      //sets:[],
      difficulty:[this.baseQuestionInfo.difficulty],
      copyResponseOptions:[false]
    })
    this.formCreated = true;
    setTimeout(()=>{
      this.setOptionsCheckbox(this.baseQuestionInfo.options);
    },100);
  }
  optionsValue(){
    var val = 0;
    var checks = document.getElementsByClassName("optionsCheckbox");
    for (let index = 0; index < checks.length; index++) {
      const checkbox = checks[index] as HTMLInputElement;
      if(checkbox.checked){
        val+= parseInt(checkbox.value)
      }
    }
    return val;
  }

  setOptionsCheckbox(optionValue:number){
    setTimeout(()=>{
      this.questionOptions.forEach((option)=>{
        if(optionValue & option.value){
          var check = document.querySelectorAll(`.optionsCheckbox[value='${option.value}']`).item(0) as HTMLInputElement;
          check.checked = true;
        }
      })
    },500)

  }

  addNewQuestion(){
    this.questionForm.get("questionText")?.setValue(this.questionTextValue())
    this.questionForm.get("wrongAnswerInfo")?.setValue(this.wrongAnswerInfoValue())
    console.log(this.questionForm.value);
    if(this.questionForm.valid){
      var createQuestionModel:CreateQuestionTranslationModel = Object.assign({},this.questionForm.value);
      var sets:number[] = [];
      window["autocomplete_sets"].forEach((a:string)=>sets.push(parseInt(a as any)));
      createQuestionModel.sets = sets;
      createQuestionModel.baseQuestionId = this.questionId;
      console.log(createQuestionModel.sets);
      createQuestionModel.format = typeof(createQuestionModel.format) == "string" ? parseInt(createQuestionModel.format) : createQuestionModel.format;
      createQuestionModel.wrongAnswerInfoFormat = typeof(createQuestionModel.wrongAnswerInfoFormat) == "string" ? parseInt(createQuestionModel.wrongAnswerInfoFormat) : createQuestionModel.wrongAnswerInfoFormat;
      console.log(createQuestionModel);
      createQuestionModel.options = this.optionsValue();
      this.loading = true;
      this.questionService.addQuestionTranslation(this.questionId,createQuestionModel).subscribe({
        next:(response)=>{
          if(response.success){
            this.loading = false;
            this.toastrService.success("Soru eklendi");
          }else{
            this.loading = false;
            this.toastrService.error("Soru eklenemedi");
          }

        },error:(err)=>{
          this.loading = false;
          this.toastrService.error("Soru eklenemedi");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
      console.log(this.questionForm.errors)
    }
  }

}
