import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-application-tenant-list-table',
  templateUrl: './application-tenant-list-table.component.html',
  styleUrls: ['./application-tenant-list-table.component.css']
})
export class ApplicationTenantListTableComponent implements OnInit {
  tableUrl:string;
  baseUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseUrl = environment.apiUrl;
        this.tableUrl = this.baseUrl + "api/cms/admin/multitenancy/tenant/"+param["tenantId"]+"/applications"
      }
    })
  }

}
