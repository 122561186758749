import { Component, OnInit } from '@angular/core';
import { SetupVmInstanceService } from '../../../services/setupVmInstance/setup-vm-instance.service';
import { SetupVmInstanceSummaryResponse } from '../../../models/setupVMInstanceModels/setupVmInstanceSummaryResponse';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { TranslateService } from '@ngx-translate/core';
import { SetupVmInstanceRowModel } from '../../../models/setupVMInstanceModels/setupVmInstanceRowModel';
import { SetupVmInstanceSpecsResponse } from '../../../models/setupVMInstanceModels/setupVmInstanceSpecsResponse';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-setup-vm-instance-dashboard',
  templateUrl: './setup-vm-instance-dashboard.component.html',
  styleUrls: ['./setup-vm-instance-dashboard.component.css']
})
export class SetupVmInstanceDashboardComponent implements OnInit {
  setupInstanceSummary: SetupVmInstanceSummaryResponse;
  messageDismiss: boolean;
  vmList: SetupVmInstanceRowModel[];
  selectedItem: string | null;
  vmSpecs: SetupVmInstanceSpecsResponse;
  specsLoading: boolean = false;
  summaryLoading: boolean = false;
  constructor(private setupVmInstanceService: SetupVmInstanceService, private toastrService: ToastrService, private globalModalService: GlobalModalService, private translateService: TranslateService, private okCancelEventService: GlobalOkCancelEventService) { }

  ngOnInit(): void {
    this.messageDismiss = localStorage.getItem("workbenchMessageDismiss") == '1' ? true : false;
    this.getVmList();
  }
  getVmIcon(osType: string) {
    return this.setupVmInstanceService.getVmIcon(osType);
  }
  createNewVm() {
    this.globalModalService.showModal({
      data: "/modal/range/vm/setupinstance/create",
      helperModals: [],
      title: this.translateService.instant("setupVmInstance.createNewVmTitle"),
      type: ModalTypes.LINK
    })
  }
  getVmList() {
    this.setupVmInstanceService.getSetupVmInstanceList().subscribe({
      next: (response) => {
        this.vmList = response.content;
        console.log(this.vmList);
      }
    })
  }
  vmItemClick(el: any) {
    var vmItems = document.getElementsByClassName("vm-list-item")
    var specItem = document.getElementsByClassName("vm-specs")[0] as HTMLDivElement;
    for (var i = 0; i < vmItems.length; i++) {
      vmItems[i].classList.remove("selected");
    }
    var elDiv = el as HTMLDivElement;
    var elVmId = elDiv.getAttribute("vmId")
    if (this.selectedItem != elVmId) {
      el.classList.add("selected");
      this.selectedItem = elVmId;
      this.getSummary(elVmId ?? "");

    } else {
      this.selectedItem = null;
    }
  }
  deleteVm() {
    if (this.selectedItem == null || this.selectedItem == '') {
      this.toastrService.error("global.errorOccureed");
      return;
    }
    this.okCancelEventService.showOkCancelWindow(this.translateService.instant("setupVmInstance.deleteModalTitle"), this.translateService.instant("setupVmInstance.deleteMessage"), true, undefined, undefined
      , true, this.translateService.instant("setupVmInstance.deleteConfirmMessage")).then(x => {
        if (x == true) {
          if (this.selectedItem != null) {
            this.setupVmInstanceService.deleteSetupVmInstance({ setupVMId: this.selectedItem ?? "" }).subscribe({
              next: (response) => {
                this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
              }
            })
          }

        }
      });
  }
  shutdownVm() {
    if (this.selectedItem == null || this.selectedItem == '') {
      this.toastrService.error("global.errorOccureed");
      return;
    }
    this.okCancelEventService.showOkCancelWindow(this.translateService.instant("setupVmInstance.shutdownVmModalTitle"), this.translateService.instant("setupVmInstance.shutdownMessage"), true, undefined, undefined
      , false, undefined).then(x => {
        if (x == true) {
          if (this.selectedItem != null) {
            this.setupVmInstanceService.shutdownVm({ setupVMId: this.selectedItem ?? "" }).subscribe({
              next: (response) => {
                this.toastrService.success(this.translateService.instant("setupVmInstances.shutdownProcessMessage"));
              }
            })
          }

        }
      });
  }
  restartVm() {
    if (this.selectedItem == null || this.selectedItem == '') {
      this.toastrService.error("global.errorOccureed");
      return;
    }
    this.okCancelEventService.showOkCancelWindow(this.translateService.instant("setupVmInstance.restartVmModalTitle"), this.translateService.instant("setupVmInstance.restartMessage"), true, undefined, undefined
      , false, undefined).then(x => {
        if (x == true) {
          if (this.selectedItem != null) {
            this.setupVmInstanceService.restartVm({ setupVMId: this.selectedItem ?? "" }).subscribe({
              next: (response) => {
                this.toastrService.success(this.translateService.instant("setupVmInstances.restartProcessMessage"));
              }
            })
          }

        }
      });
  }
  startVm() {
    if (this.selectedItem != null) {
      this.setupVmInstanceService.startVm({ setupVMId: this.selectedItem ?? "" }).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("setupVmInstances.startProcessMessage"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }
  }

  openClonePage() {
    this.globalModalService.showModal({
      data: `/modal/range/vm/setupinstance/${this.selectedItem}/clone`,
      helperModals: [],
      title: this.translateService.instant("setupVmInstance.cloneModalTitle"),
      type: ModalTypes.LINK
    })
  }
  openPublishPage() {
    this.globalModalService.showModal({
      data: `/modal/range/vm/setupinstance/${this.selectedItem}/publish`,
      helperModals: [],
      title: this.translateService.instant("setupVmInstance.publishModalTitle"),
      type: ModalTypes.LINK
    })
  }
  dismiss() {
    localStorage.setItem("workbenchMessageDismiss", "1");
  }
  getSpecs(id: string) {
    if (id == '') {
      return;
    }
    this.specsLoading = true;
    this.setupVmInstanceService.getSetupVmInstanceSpecs(id).subscribe({
      next: (response) => {
        this.specsLoading = false;
        this.vmSpecs = response.content;
      }, error: (err) => {
        this.specsLoading = false;
      }
    })
  }
  getSummary(id: string) {
    if (id == '') {
      return;
    }
    this.summaryLoading = true;
    this.setupVmInstanceService.getSummary(id).subscribe({
      next: (response) => {
        this.summaryLoading = false;
        this.setupInstanceSummary = response.content;
      }
    })
  }

}
