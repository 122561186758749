import { Injectable } from '@angular/core';
import { UpdateNoteModel } from '../../models/LearnModels/Notes/updateNoteModel';
import { ResponseModel } from '../../models/responseModel';
import { environment } from 'projects/usorta-library/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CreateNoteModel } from '../../models/LearnModels/Notes/createNoteModel';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private httpClient:HttpClient) { }
  updateNote(note:UpdateNoteModel,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/${note.id}/update`;
    return this.httpClient.post<ResponseModel>(apiUrl,note);
  }
  createNote(note:CreateNoteModel,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/create`;
    return this.httpClient.post<ResponseModel>(apiUrl,note);
  }
  deleteNote(noteId:string,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/${noteId}/delete`;
    return this.httpClient.delete<ResponseModel>(apiUrl);
  }
}
