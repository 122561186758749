import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-media-content',
  templateUrl: './create-media-content.component.html',
  styleUrls: ['./create-media-content.component.css']
})
export class CreateMediaContentComponent implements OnInit {
  baseApiUrl:string = environment.apiUrl;
  formUrl:string = this.baseApiUrl + "api/lms/contents/media/create"
  constructor() { }

  ngOnInit(): void {
  }

}
