import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { EducationModel } from '../../../models/Education/educationModel';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { UpdateEducationRequestModel } from '../../../models/Education/updateEducationRequestModel';
import { ActivatedRoute } from '@angular/router';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { CmsImageService } from '../../../services/cmsImages/cms-image.service';
import { CmsImageSizes } from '../../../models/Enums/CmsImageSizes';
declare var monaco: any;
declare var window: any;
declare var $:any;
@Component({
  selector: 'lib-update-education',
  templateUrl: './update-education.component.html',
  styleUrls: ['./update-education.component.css']
})
export class UpdateEducationComponent implements OnInit {
  educationId:string;
  educationModel: EducationModel;
  selectedFormat:number = 0;
  currentFormat:number;
  themeList:ListItemModel[] = [];
  educationCategoryList: ListItemModel[] = [];
  categoryTreeId:number = 1;
  catalogImageChangedEvent: any = '';
  croppedCatalogImage: any = '';
  croppedCatalogImage64: string | null | undefined = undefined;
  detailImageChangedEvent: any = '';
  croppedDetailImage: any = '';
  croppedDetailImage64: string | null | undefined = undefined;
  loading:boolean = false;
  constructor(private cmsImageService:CmsImageService,private globalModalService:GlobalModalService,private activetedRoute:ActivatedRoute,private sanitizer: DomSanitizer, private toastrService: ToastrService, private educationService: EducationService) { }

  ngOnInit(): void {
    this.activetedRoute.params.subscribe({
      next:(param)=>{
        this.educationId = param["educationId"];
        this.getEducationCategories();
        this.getEducationThemeList();
        setTimeout(()=>{
          window.initLayoutEditor($('#description'), {});
        },150)
      }
    })

  }
  getEducationThemeList(){
    this.educationService.getEducationThemeList().subscribe({
      next:(response)=>{
        this.themeList = response.content;
      }
    })
  }
  getImageUrl(imageId:string){
    return this.cmsImageService.getUrl(imageId,CmsImageSizes.Size_250x150);
  }
  getEducation(educationCode: string) {
    this.educationService.getEducation(educationCode).subscribe({
      next: (response) => {
        console.log(this.educationModel)
        this.educationModel = response.content;
        this.currentFormat = this.educationModel.descriptionFormat;
        this.selectedFormat = this.educationModel.descriptionFormat;
        setTimeout(()=>{
          this.prepareEducationInputs();
          window.initLayoutEditor($('#description'), {});
        },150)
      },
      error: (err) => {
        this.toastrService.error("Eğitim getirilemedi");
      }
    })
  }
  getEducationCategories() {
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next: (response) => {
        this.educationCategoryList = response.content;
        this.getEducation(this.educationId);
      }, error: (err) => {
        this.toastrService.error("Kategoriler alınamadı")
      }
    })
  }
  getLayoutEditor() {
    var ta = document.getElementById("description-layout-editor") as HTMLTextAreaElement;
  }
  /* Katalog Resmi Kırpma Fonksiyonları */
  changeCatalogImage(ev: Event) {
    this.catalogImageChangedEvent = ev;
  }
  catalogImageCropped(event: ImageCroppedEvent) {
    this.croppedCatalogImage64 = event.base64;
    this.croppedCatalogImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success("Seçilen bölge kayıt edildi");
  }
  catalogImageLoaded(image: LoadedImage) {

  }
  catalogCropperReady() {

  }
  catalogImageLoadFailed() {

  }

  /* Detay Resmi Kırpma Fonksiyonları */
  changeDetailImage(ev: Event) {
    this.detailImageChangedEvent = ev;
  }
  detailImageCropped(event: ImageCroppedEvent) {
    this.croppedDetailImage64 = event.base64;
    this.croppedDetailImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success("Seçilen bölge kayıt edildi");
  }
  detailImageLoaded(image: LoadedImage) {

  }
  detailCropperReady() {

  }
  detailImageLoadFailed() {

  }
  prepareEducationInputs() {
    (document.getElementById("code") as HTMLInputElement).value = this.educationModel.code;
    (document.getElementById("name") as HTMLInputElement).value = this.educationModel.name;
    (document.getElementById("active") as HTMLInputElement).checked = this.educationModel.active;
    if(this.educationModel.descriptionFormat != 1){
      (document.getElementById("description") as HTMLInputElement).value = this.educationModel.description;
    }

    (document.getElementById("teaserText") as HTMLInputElement).value = this.educationModel.teaserText;
    var themeSelect = (document.getElementById("theme") as HTMLSelectElement);
    if(this.educationModel.themeId){
      themeSelect.value = this.educationModel.themeId.toString();
    }

    //(document.getElementById("category") as HTMLSelectElement). = this.educationModel.categories;
    $("#categories").val(this.educationModel.categories);
    (document.getElementById("level") as HTMLInputElement).value = this.educationModel.level;
    (document.getElementById("courseLength") as HTMLInputElement).value = this.educationModel.courseLength;
    this.croppedCatalogImage64 = this.educationModel.image;
    this.croppedDetailImage64 = this.educationModel.teaserImage;
  }
  /* Model oluştur */
  createUpdateEducationModel(){
    var educationCode = (document.getElementById("code") as HTMLInputElement).value;
    var educationName = (document.getElementById("name") as HTMLInputElement).value;
    var isActive = (document.getElementById("active") as HTMLInputElement).checked;
    var descriptionDetail;
    if(this.selectedFormat == 1){
      descriptionDetail = (document.getElementById("description") as HTMLInputElement).value;
    }else{
      descriptionDetail = (document.getElementById("descriptionTextarea") as HTMLInputElement).value;
    }
    var description = (document.getElementById("teaserText") as HTMLInputElement).value;
    var categoryEl = (document.getElementById("categories") as HTMLSelectElement);
    var selectedCategories = Array.from(categoryEl.options).filter(option => option.selected).map(option => parseInt(option.value));
    var level = (document.getElementById("level") as HTMLInputElement).value;
    var length = (document.getElementById("courseLength") as HTMLInputElement).value;
    var descriptionFormat = (document.getElementById("descriptionFormat") as HTMLInputElement).value;
    var deleteTeaserImage = (document.getElementById("deleteTeaserImage") as HTMLInputElement).checked;
    var deleteImage = (document.getElementById("deleteImage") as HTMLInputElement).checked;
   var themeId = (document.getElementById("theme") as HTMLSelectElement).value;

    var educationModel:UpdateEducationRequestModel = {
      code: educationCode,
      name: educationName,
      active: isActive,
      image: this.croppedCatalogImage64 ?? undefined,
      teaserText: description,
      teaserImage: this.croppedDetailImage64 ?? undefined,
      description: descriptionDetail,
      categories: selectedCategories,
      level: parseInt(level),
      courseLength: length,
      descriptionFormat: parseInt(descriptionFormat),
      themeId: parseInt(themeId),
      courseId: this.educationId,
      options: 0,
      deleteTeaserImage: deleteTeaserImage,
      deleteImage: deleteImage
    }
    console.log(educationModel)
    this.educationModelControl(educationModel);
    return educationModel;
  }

  educationModelControl(educationModel:UpdateEducationRequestModel){
    var emptyAllowed = ["outline","themeId","locale","descriptionFormat","image","description","teaserImage","teaserText","deleteTeaserImage","deleteImage"];
    var educationModelKeys = Object.keys(educationModel);
    educationModelKeys.forEach(key=>{
      var model = educationModel as any;
      if((model[key] === "" || model[key] === undefined) && emptyAllowed.findIndex(x=> x == key) == -1){
        console.log(key);
        console.log(key);
        var label = (document.querySelector('[for="'+key+'"]') as HTMLLabelElement).innerText;

        this.toastrService.error(`${label} bilgisi boş olamaz`)
        throw "Modeli kontrol edin";
      }
    });
  }
  selectFormat(ev:any){
    this.selectedFormat = parseInt($(ev.target).val())
  }
  updateEducation() {
    var educationModel = this.createUpdateEducationModel();
    this.loading = true;
    this.educationService.updateEducation(educationModel).subscribe({
      next: (response) => {
        this.loading = false;
        this.toastrService.success("Eğitim güncellendi");
      }, error: (err) => {
        this.loading = false;
        this.toastrService.error("Bir hata oluştu");
      }
    });
  }
  showAddCategoryModal(){
    var modalCreateModel:ModalCreateModel = {
      title: 'Yeni kategori',
      type: ModalTypes.LINK,
      data: "modal/education/addCategory",
      helperModals: []
    }
    var modal = this.globalModalService.showModal(modalCreateModel);
    modal.onclose.subscribe({
      next:()=>{
        this.getEducationCategories();
      }
    })
  }

}
