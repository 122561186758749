import { Component, OnInit } from '@angular/core';
import { DynamicFormToJsonElementTypesModel } from '../../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { CreateVirtualLabModel, VirtualLabNetwork, VirtualLabNetworkSpec } from '../../../models/VirtualLabModels/createVirtualLabModel';
import { ToastrService } from 'ngx-toastr';
import { InputFormats } from '../../../models/Enums/inputFormatsEnum';
import { TranslateService } from '@ngx-translate/core';
import { FormSubmitRedirectService } from '../../../services/formSubmitRedirect/form-submit-redirect.service';

@Component({
  selector: 'lib-create-lab',
  templateUrl: './create-lab.component.html',
  styleUrls: ['./create-lab.component.css']
})
export class CreateLabComponent implements OnInit {
  networkSelect:DynamicFormToJsonElementTypesModel[] = [];
  providers:ListItemModel[];
  networks:any[] = [];
  validNetworks:boolean;
  descriptionFormat:number = 0;
  inputFormats:InputFormats[] = [InputFormats.PlainText]
  descriptionValue:any;
  loading:boolean = false;

  constructor(private formSubmitRedirect:FormSubmitRedirectService,private translateService:TranslateService,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.createNetworkDynamicFormSelect();
    this.getProviders();
  }
  changeDescriptionFormat($event:any){

    var value = $event.target.value;
    this.inputFormats = [parseInt(value)];
    this.descriptionFormat = parseInt(value);
  }
  descriptionValueFnc($event:any){
    this.descriptionValue = $event;
  }
  getValidation(ev:boolean){
    this.validNetworks = ev;
  }
  saveNetwork(ev:any){
    this.networks = JSON.parse(ev);
  }
  createNetworkDynamicFormSelect(){
    var item:DynamicFormToJsonElementTypesModel = {
      typeName: 'network',
      typeText: 'Yeni Ağ Ekle',
      element: [
        {name:"identifier",valueType:"string",label:"Tanımlayıcı Ad",pattern:"^[A-Za-z0-9]*$"},
        {name:"name",valueType:"string",label:"Ağ Adı"},
        {name:"shared",valueType:"boolean",label:"Paylaşılan"},
        {name:"networkAddress",valueType:"string",label:"Network Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
        {name:"subnetMask",valueType:"string",label:"Alt Ağ Maskesi"},
        {name:"dhcpEnabled",valueType:"boolean",label:"DHCP Aktif"},
        {name:"dhcpStart",valueType:"string",label:"DHCP Başlangıç Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
        {name:"dhcpEnd",valueType:"string",label:"DHCP Bitiş Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
      ]
    }
    this.networkSelect.push(item);
  }
  getProviders(){
    this.virtualLabService.getEnvironmentListItem().subscribe({
      next:(response)=>{
        this.providers = response.content;
      }
    })
  }
  createData(){
    if(!this.validNetworks){
      this.toastrService.error("Ağ verilerini kontrol edin");
      return;
    }
    var name = document.getElementById("name") as HTMLInputElement;
    var code = document.getElementById("code") as HTMLInputElement;
    var tags = document.getElementById("tags") as HTMLInputElement;
    var networkList:VirtualLabNetwork[] = [];
    this.networks.forEach(network=>{
      var spec:VirtualLabNetworkSpec = {
        networkAddress: network.networkAddress,
        subnetMask: network.subnetMask,
        dhcpEnabled: network.dhcpEnabled,
        dhcpStart: network.dhcpStart,
        dhcpEnd: network.dhcpEnd
      }
      var netModel:VirtualLabNetwork = {
        identifier: network.identifier,
        name: network.name,
        networkSpec: spec,
        shared:network.shared
        
      }
      
      networkList.push(netModel);
    })
    var vlabModel:CreateVirtualLabModel = {
      name: name.value,
      code: code.value,
      tags: tags.value,
      networks: networkList,
      descriptionFormat:this.descriptionFormat,
      description:this.descriptionValue()
    }
    this.loading = true;
    this.virtualLabService.createLabBlueprint(vlabModel).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("labBlueprints.createdSuccessfully"));
        this.formSubmitRedirect.showModal("/range/labblueprints/{id}/details",response.content);
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("labBlueprints.createFailed"));
      }
    })
    console.log(vlabModel);
  }

}
