import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-customize-set-root-password',
  templateUrl: './customize-set-root-password.component.html',
  styleUrls: ['./customize-set-root-password.component.css']
})
export class CustomizeSetRootPasswordComponent implements OnInit {
  setRootPasswordForm: FormGroup;
  vmId:string;
  constructor(private translateService:TranslateService,private formBuilder: FormBuilder,private virtualLabService:VirtualLabService  , private activatedRoute: ActivatedRoute, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
        this.createForm();
      }
    })

  }
  createForm() {
    this.setRootPasswordForm = this.formBuilder.group({
      password: ["", Validators.required],
      repassword: ["", Validators.required]
    })
  }
  prepareSetRootPasswordValue(formValues:any) {
    var val: VMCustomization = { 
      args: formValues.password, 
      type: "root-password"
    };
    return val;
  }
  errorControl(name:string){
    if(this.setRootPasswordForm.get(name)?.errors){
      return true;
    }
    return false;
  }
  setRootPassword() {
    if (this.setRootPasswordForm.valid) {
      var formValues = this.setRootPasswordForm.value;
      if (formValues.password !== formValues.repassword) {
        this.toastrService.error(this.translateService.instant("vmCustomization.passwordFieldsNotMatch"));
        return;
      }
      var preparedValue = this.prepareSetRootPasswordValue(formValues);
      console.log(preparedValue);
      this.virtualLabService.addCustomizationToVm(this.vmId,preparedValue).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmCustomization.addedParameter"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    } else {
      this.toastrService.error("Formu kontrol edin.");
    }
  }

}
