import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { AddEducationCategoryRequestModel } from '../../../models/Education/addEducationCategoryRequestModel';
import { CourseCategoryFlags } from '../../../models/Education/Enum/courseCategoryFlags';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-add-education-category',
  templateUrl: './add-education-category.component.html',
  styleUrls: ['./add-education-category.component.css']
})
export class AddEducationCategoryComponent implements OnInit {
  options: { name: string, value: number }[] = [{ name: "CourseCategoryFlags_Testing", value: 1 }]
  pCategoryList: ListItemModel[] = [];
  categoryForm: FormGroup;
  categoryTreeId:number;
  loading:boolean = false;
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private toastrService: ToastrService, private educationService: EducationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.categoryTreeId = parseInt(param["categoryTreeId"]);
        this.createCategoryForm();
        this.getCategories();
      }
    })

  }
  createCategoryForm() {
    this.categoryForm = this.formBuilder.group({
      parentId: [undefined],
      name: ["", Validators.required],
      active: [false],
      description:[""],
      color: ["#ff0000", Validators.required],
      options: [0],
      orderValue: [0]
    })
  }
  getCategories() {
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next: (categories => {
        this.pCategoryList = categories.content;
      })
      ,error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  addEducationCategory() {
    /*var categoryName = (document.getElementById("categoryName") as HTMLInputElement).value;
    if (categoryName == null || categoryName == "") {
      this.toastrService.error("Kategori adı boş olamaz");
    } else {
      var addCategoryRequest: AddEducationCategoryRequestModel = {
        categoryName: categoryName,
        parentCategory: 0,
        isActive: false,
        color: '',
        options: [],
        sortValue: 0
      }


    }*/
    if (this.categoryForm.valid) {
      var addCategoryRequest: AddEducationCategoryRequestModel = Object.assign({}, this.categoryForm.value);
      addCategoryRequest.treeId = this.categoryTreeId;
      addCategoryRequest.options = this.getFlagValue();
      this.loading = true;
      this.educationService.addEducationCategory(this.categoryTreeId,addCategoryRequest).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("courseManagement.category.added"));
          this.loading = false;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("courseManagement.category.addError"));
          this.loading = false;
        }
      },)

    } else {
      console.log(this.categoryForm.value)
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
      this.loading = false;
    }
  }
  getFlagValue() {
    var flag = 0;
    var optionChecks = document.getElementsByName("options");
    optionChecks.forEach(check => {
      var option = check as HTMLInputElement;
      if (option.checked) {
        flag = flag | parseInt(option.value)
      }
    })
    return flag;
  }

}
