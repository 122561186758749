import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'lib-preview-text',
  templateUrl: './preview-text.component.html',
  styleUrls: ['./preview-text.component.css']
})
export class PreviewTextComponent implements OnInit,AfterViewInit {
  preview:boolean = false;
  @Input() allowHtml:boolean = false;
  @Input() text:string;
  
  uuid:string;
  constructor() {
    this.uuid = (Math.random() + 1).toString(36).substring(7);
   }
  ngAfterViewInit(): void {
    this.setPreviewToFrame();
  }

  ngOnInit(): void {

  }
  toggle(preview:boolean){
    this.preview = preview;
  }
  setPreviewToFrame(){
    var preFrame = document.getElementsByClassName("htmlPreviewFrame_"+this.uuid)[0] as HTMLIFrameElement;
    $(preFrame).contents().find('body').html(this.text);
  }

}
