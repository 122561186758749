<div class="main" *ngIf="success == false">
    <div class="alert alert-info" role="alert">
        {{'vmhosts.removeMessage' | translate}}
    </div>
    <div><label for="confirm1"><input type="checkbox" (click)="conf1()" onclick="return false" id="confirm1" name="confirm1"/>{{'global.removeConfirm1' | translate}}</label></div>
    <div><label for="confirm2"><input type="checkbox" (click)="conf2()" onclick="return false" id="confirm2" name="confirm2"/>{{'global.removeConfirm2' | translate}}</label></div>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="deleteVmHost()" [disabled]="confirm1 == false ||confirm2 == false"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
<div class="successDiv" *ngIf="success">
    <div class="successContent">
        <div class="successMessage">
            <i class="bi bi-check-circle-fill checkIcon"></i><br/>
            <font class="checkMessage">{{'global.deletedSuccessfully' | translate}}</font>
        </div>
        <br/>
        <br/>
        <div class="successButtons">
            <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.close' | translate}}</button>
        </div>

    </div>
</div>