import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-create-base-vm-image',
  templateUrl: './create-base-vm-image.component.html',
  styleUrls: ['./create-base-vm-image.component.css']
})
export class CreateBaseVmImageComponent implements OnInit {
  formUrl:string = this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages/create";
  baseUrl:string = this.apiUrlService.getApiUrl();
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
  }

}
