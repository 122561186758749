import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-create-localized-format-string',
  templateUrl: './create-localized-format-string.component.html',
  styleUrls: ['./create-localized-format-string.component.css']
})
export class CreateLocalizedFormatStringComponent implements OnInit {
  formUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.formUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/formatstring/create"
  }

}
