import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicFormToJsonElementTypesModel } from 'projects/usorta-library/src/lib/models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { CreateSessionModel } from 'projects/usorta-library/src/lib/models/SessionApiModels/createSessionModel';
import { SessionApiService } from 'projects/usorta-library/src/lib/services/session/session-api.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html',
  styleUrls: ['./add-session.component.css']
})
export class AddSessionComponent implements OnInit {
  elementList:DynamicFormToJsonElementTypesModel[] = [];
  requestForm:boolean[] = [true];
  addSessionFormGroup:FormGroup;
  templateList:ListItemModel[];
  creating:boolean = false;
  formTopInput:HTMLElement;
  formBottomInput:HTMLElement;
  fieldNameText:string  = "";
  fieldValueText:string = "";
  fieldRequiredText:string = "";
  fieldTypeText:string = "";
  loaded:boolean = false;
  constructor(private translateService:TranslateService, private router:Router,private templateService:SessionTemplateService, private sessionApiService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.formTopInput = document.getElementById("formTopEl") as HTMLElement;
    this.formBottomInput = document.getElementById("formBottomEl") as HTMLElement;
    this.getTemplatesListItem();
    this.getFieldTranslations();
  }
  

  getFieldTranslations(){
    this.translateService.get("fields.parameterName").subscribe({
      next:(response)=>{
        console.log(response);
        this.fieldNameText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.parameterValue").subscribe({
      next:(response)=>{
        this.fieldValueText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.required").subscribe({
      next:(response)=>{
        this.fieldRequiredText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.valueType").subscribe({
      next:(response)=>{
        this.fieldTypeText = response;
        this.fieldsControl();
      }
    })
  }
  fieldsControl(){
    if(this.loaded){
      return;
    }

    setTimeout(()=>{
      if(this.fieldNameText != "" && this.fieldRequiredText != "" && this.fieldTypeText != "" && this.fieldValueText != ""){
        if(this.loaded){
          return;
        }
        this.elementList.push({typeName:"stringVal",typeText:"String",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"string"},{name:"fieldRequired",label:this.fieldRequiredText,valueType:"boolean"},{name:"valueType",label:this.fieldTypeText,valueType:"static",defaultValue:"string"}]});
        this.elementList.push({typeName:"booleanVal",typeText:"Boolean",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"boolean"},{name:"valueType",label:this.fieldTypeText,valueType:"static",defaultValue:"boolean"}]});
        this.loaded = true;
      }
    },500);

  }


  getFormGroup($ev:any){
    if($ev == undefined){
      setTimeout(()=>{
        this.requestForm = [true];
      },500)
    }else{
      this.addSessionFormGroup = $ev;
      console.log($ev);
    }
  }
  getTemplatesListItem(){
    return this.templateService.getSessionTemplateList().subscribe({next:(response)=>{
      var tmpList = response.content;
      var newItem:ListItemModel = {value:"-1",label:this.translateService.instant("addSession.doNotUseTemplate")}
      tmpList.unshift(newItem);
      this.templateList = tmpList;
    },error:(err)=>{
      var errorMessage = this.translateService.instant("addSession.getTemplateError");
      this.toastrService.error(errorMessage);
    }})
  }
  postData($ev:CreateSessionModel){
    if(this.creating == true){
      var pendingMessage = this.translateService.instant("addSession.pendingSessionCreationProcess");
      this.toastrService.error(pendingMessage);
      return;
    }
    this.creating = true;
    var templateSelect = document.getElementById("templateSelect") as HTMLInputElement;
    var createSessionModel:CreateSessionModel = $ev;
    createSessionModel.scheduledStart = new Date(createSessionModel.scheduledStart).toJSON();
    if(createSessionModel.scheduledEnd && createSessionModel.scheduledEnd !== ""){
      createSessionModel.scheduledEnd = new Date(createSessionModel.scheduledEnd).toJSON();
    }else{
      createSessionModel.scheduledEnd = undefined;
    }
    if(templateSelect.value != "-1" && templateSelect.value != undefined){
      console.log(templateSelect.value);
      createSessionModel.templateId = parseInt(templateSelect.value);
    }
    console.log(createSessionModel);
    this.sessionApiService.createSession(createSessionModel).subscribe({
      next:(response)=>{
        var addedSessionMessage = this.translateService.instant("addSession.sessionAdded");
        this.toastrService.success(addedSessionMessage);
        this.router.navigate([`/session/${response.content}`])
        this.creating = false;
      },error:(err)=>{
        var errorSessionMessage = this.translateService.instant("addSession.sessionAddError");
        this.toastrService.success(errorSessionMessage);
        this.creating = false;
      }
    });
  }
  onDynamicFormDataChange($ev:any){
    this.addSessionFormGroup.get("data")?.setValue($ev);
  }

}
