<div class="formMain form-maroon-border form-default-border" *ngIf="removableImage">
    <h4>{{'removableImages.detailsPage' | translate}}</h4>
    <hr/>
    <div class="row-item">
        <b>{{'removableImages.name' | translate}} : </b><font>{{removableImage.name}}</font>
    </div>
    <div class="row-item">
        <b>{{'removableImages.type' | translate}} : </b><font>{{removableImage.type}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.filename">
        <b>{{'removableImages.filename' | translate}} : </b><font>{{removableImage.filename}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.format">
        <b>{{'removableImages.format' | translate}} : </b><font>{{removableImage.format}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.filesize">
        <b>{{'removableImages.filesize' | translate}} : </b><font>~{{round((removableImage.filesize / 1000) / 1000)}} MB</font>
    </div>
    <div class="row-item" *ngIf="removableImage.fileHash">
        <b>{{'removableImages.fileHash' | translate}} : </b><font>{{removableImage.fileHash}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.removeAfter">
        <b>{{'removableImages.removeAfter' | translate}} : </b><font>{{removableImage.removeAfter | formattedDate}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.description">
        <b>{{'removableImages.description' | translate}} : </b><font>{{removableImage.description}}</font>
    </div>
    <div class="row-item" *ngIf="removableImage.tags">  
        <b>{{'removableImages.tags' | translate}} : </b><font>{{removableImage.tags}}</font>
    </div>
    <div class="row-item">
        <b>{{'removableImages.status' | translate}} : </b><font>{{removableImage.status}}</font>
    </div>
    <div class="row-item">
        <b>{{'removableImages.readOnly' | translate}} :  </b><input type="checkbox" class="form-check-input" onclick="return false" [checked]="removableImage.readOnly"/>
    </div>
    <div class="row-item">
        <b>{{'removableImages.temporary' | translate}} :  </b><input type="checkbox" class="form-check-input" onclick="return false" [checked]="removableImage.temporary"/>
    </div>
    <div class="row-item">
        <b>{{'removableImages.shared' | translate}} : </b><input type="checkbox" class="form-check-input" onclick="return false" [checked]="removableImage.shared"/>
    </div>
    <div class="row-item">
        <b>{{'removableImages.active' | translate}}  : </b><input type="checkbox" class="form-check-input" onclick="return false" [checked]="removableImage.active"/>
    </div>
</div>