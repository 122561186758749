import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-education-theme',
  templateUrl: './delete-education-theme.component.html',
  styleUrls: ['./delete-education-theme.component.css']
})
export class DeleteEducationThemeComponent implements OnInit {
  themeId:number;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.themeId = param["themeId"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteTheme(){
    this.educationService.deleteTheme(this.themeId).subscribe({next:(response)=>{
      this.toastrService.success(this.translateService.instant("educationTheme.themeDeletedSuccessful"));
    },error:(err)=>{
      this.toastrService.error(this.translateService.instant("educationTheme.themeDeletedError"));
    }})
  }

}
