import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserManagerService } from '../../../services/user-manager.service';
import { ToastrService } from 'ngx-toastr';
import { ActiveUserSessionsResponse } from '../../../models/activeSessionsResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userId:string;
  userResponse:any;
  activeSessions:ActiveUserSessionsResponse = {userId:"",username:"",activeSessionItems : []};
  sessionLoading:boolean = false;
  constructor(private activatedRoute:ActivatedRoute,private userService:UserManagerService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getUserId();
    this.getActiveSessions();
    this.getUserDetails();
  }
  getUserId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param['userId'];
      }
    })
  }
  getActiveSessions(){
    this.sessionLoading = true;
    this.userService.getUserSessions(this.userId).subscribe({
      next:(response)=>{
        this.sessionLoading = false;
        this.activeSessions = response.content;
      },error:(err)=>{
        this.sessionLoading = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getUserDetails(){
    this.userService.getUserById(this.userId).subscribe({
      next:(response)=>{
        this.userResponse = response.content;
      }
    })
  }
  logout(sessionId:string){
    this.userService.logout(sessionId).subscribe({
      next:(response)=>{
        if(response){
          this.toastrService.success("Oturumdan çıkış yapıldı");
        }else{
          this.toastrService.error("Oturumdan çıkış yapılamadı");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
