<div class="note-container" >
    <div class="note-toolbar">
        <button class="btn btn-sm btn-primary" (click)="createNote()"><i class="bi bi-plus-lg"></i>{{'btn.addNote' | translate}}</button>
    </div>
    <div class="d-flex justify-content-center" *ngIf="loading">
        <br/>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    <div *ngIf="notes">
        <div class="note-item" *ngFor="let note of notes.items">
            <div class="note-title">{{note.title}}</div>
            <div class="note-content" [innerHTML]="note.content"></div>
            <div class="note-bottom">
                <div class="note-timestamp">
                    {{note.timestamp | formattedDate}}
                </div>
                <div class="note-actions">
                    <i (click)="deleteNote(note.noteId)" class="bi note-action-item bi-trash-fill hoverHand"></i>
                    <i (click)="updateNote(note.noteId)" class="bi note-action-item bi-pencil-fill hoverHand"></i>
                </div>
            </div>
        </div>
        <div class="page-navigation" style="text-align:right">
            <button [disabled]="!previousPageAvailabe()" class="btn btn-sm btn-secondary me-1" (click)="previousPage()">{{'btn.previousPage' | translate}}</button>
            <button class="btn btn-sm btn-secondary" [disabled]="!nextPageAvailabe()" (click)="nextPage()">{{'btn.nextPage' | translate}}</button>
        </div>

    </div>

</div>
