<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-xs-12">
                <div class="courseInfo">
                    <h3><i class="bi bi-info-circle"></i> {{'courseDetails' | translate}} <button class="btn btn-warning xsBtn sectionActionButton" (click)="editEducation()"><i class="bi bi-pencil-fill"></i> {{'courseManagement.editCourse' | translate}}</button></h3>
                    <hr>
                    <b>Eğitim Kodu : </b>
                    <font class="courseName">{{education.code}}</font>
                    <br />
                    <b>Eğitim Adı : </b>
                    <font class="courseName">{{education.name}}</font>
                    <br />
                    <b *ngIf="education.themeId">Eğitim Teması : </b>
                    <font *ngIf="education.themeId" class="courseName">{{getThemeName(education.themeId)}}</font>
                    <br *ngIf="education.themeId" />
                    <b>Aktif : </b>
                    <input type="checkbox" onclick="return false" [readOnly]="true" [checked]="education.active">
                    <br />
                    <b [class.displayNone]="education.image == null">Kurs Resmi</b>
                    <br [class.displayNone]="education.image == null" />
                    <img [class.displayNone]="education.image == null" class="courseImage" [src]="getImage(education.image)" />
                    <br [class.displayNone]="education.teaserImage == null" />

                    
                    <b [class.displayNone]="education.teaserImage == null">Kurs Detay Resmi</b>
                    <br [class.displayNone]="education.teaserImage == null"/>
                    <img [class.displayNone]="education.teaserImage == null" class="courseImage" [src]="getImage(education.teaserImage)">
                    <br [class.displayNone]="education.teaserImage == null" />
                    <b>Açıklama ve Detay Açıklama</b><br/>
                    <font class="courseDescription">{{education.teaserText}}</font>
                    <br/>
                    <br/>
                    <button (click)="setDescriptionDetail()" class="btn btn-secondary">Detaylı Açıklamayı Göster</button>
                </div>

                <div class="versions">
                    <!-- Tablo componenti ile versiyon listesi görüntülenecek -->
                    <lib-custom-table [externalAPIUrl]="apiUrl+'api/lms/courses/'+education.courseId+'/versions/list'"></lib-custom-table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="row">
            <div class="col-xs-12">

            </div>
        </div>