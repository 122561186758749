<div class="loginContainer">
  <div class="loginMainDiv">
    <div class="login-image-container">
      <div *ngIf="loadingLogo" class="loadingLoginLogo">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <img src="{{logo}}" (load)="logoOnLoad()" [class.displayNone]="loadingLogo == true" *ngIf="logo" class="loginImage">
    </div>

    <h4 *ngIf="knownUser() == false">{{'loginPageTitle' | translate}}</h4>
    <form [formGroup]="loginForm">
      <div *ngIf="knownUser() == true" class="form-group" style="margin-bottom: 10px;">
        <div style="text-align: center;">
          <i style="font-size:50px" class="bi bi-person-circle"></i><br/>
          {{'HelloMsg' | translate}} <b>{{getFirstname()}}</b>
        </div>
        <div class="verifyAccountMessage" *ngIf="verifyPage">
          <font >{{'VerifyAccountContinue' | translate}}</font>
        </div>

        <input type="hidden" class="form-control" id="username" placeholder="{{'username' | translate}}"
          formControlName="username">

      </div>
      <div *ngIf="knownUser() == false" class="form-group">
        <label for="username">{{'username' | translate}}</label>
        <input type="text" class="form-control" id="username" placeholder="{{'username' | translate}}"
          formControlName="username" autocomplete="username">
      </div>
      <div class="form-group">
        <label for="password">{{'password' | translate}}</label>
        <input type="password" class="form-control" placeholder="{{'password' | translate}}" id="password"
          formControlName="password" autocomplete="current-password">
      </div>
      <div *ngIf="verifyPage == false" style="display: inline-block;">
        <input type="checkbox" id="rememberMe" formControlName="rememberMe" [(ngModel)]="checkedRememberMe" style="vertical-align: middle;" /><label  style="font-size:13px"
        for="rememberMe"> {{'rememberMe'|translate}}</label>
      </div>

      <font routerLink="/forgot-password" class="forgotPassword">{{'forgotPassword' | translate}}</font>
      <br/>
      <button type="submit" class="btn btn-danger loginBtn" *ngIf="loginProcess == false && verifyPage == false" (click)="login(admin)"><i class="bi bi-person"></i>  {{'login' | translate}}</button>
      <button type="submit" class="btn btn-danger loginBtn" *ngIf="loginProcess == false && verifyPage == true" (click)="verifyPassword()"><i class="bi bi-person"></i>  {{'verify' | translate}}</button>
      <button type="submit" class="btn btn-danger loginBtn disabled" *ngIf="loginProcess == true"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></button>
      <button (click)="changeAccount()" *ngIf="knownUser() == true && verifyPage == false" class="btn btn-secondary"><i class="bi bi-people"></i> {{'changeAccount' | translate}}</button>
    </form>

    <br/>
    <p *ngIf="availabeRedirectAfterLogin" style="font-size: 13px; color: gray;">Giriş yaptıktan sonra istediğiniz sayfaya yönlendirileceksiniz</p>
  </div>
</div>
