import { Component, OnInit } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-create-installer-image',
  templateUrl: './create-installer-image.component.html',
  styleUrls: ['./create-installer-image.component.css']
})
export class CreateInstallerImageComponent implements OnInit {
  formUrl:string;
  apiUrl:string = this.apiUrlService.getApiUrl();
  constructor(private apiUrlService:ApiUrlService){}
  ngOnInit(): void {
    this.formUrl = this.apiUrlService.getApiUrl() + "api/range/vm/installerimages/create"
  }


}
