<div class="main" *ngIf="success == false">
    <div>
        {{'sessionManagement.removeMemberMessage' | translate}}
    </div>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="removeMemberFromSession()"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
<div class="successDiv" *ngIf="success">
    <div class="successContent">
        <div class="successMessage">
            <i class="bi bi-check-circle-fill checkIcon"></i><br/>
            <font class="checkMessage">{{'sessionManagement.memberRemoved' | translate}}</font>
        </div>
        <br/>
        <br/>
        <div class="successButtons">
            <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.close' | translate}}</button>
        </div>

    </div>
</div>