import { Component, Input, OnInit } from '@angular/core';
import { CourseContentDetailsModel } from 'projects/usorta-library/src/lib/models/CourseContentModels/courseContentDetailsModel';
import { MediaContentDetailsResponse } from 'projects/usorta-library/src/lib/models/MediaContent/mediaContentDetailsResponse';
import { CourseContentService } from 'projects/usorta-library/src/lib/services/education/course-content.service';
import { MediaContentService } from 'projects/usorta-library/src/lib/services/mediaContent/media-content.service';

@Component({
  selector: 'lib-outline-item-media-content-preview',
  templateUrl: './outline-item-media-content-preview.component.html',
  styleUrls: ['./outline-item-media-content-preview.component.css']
})
export class OutlineItemMediaContentPreviewComponent implements OnInit {
  @Input() contentId: string;
  mediaDetails: MediaContentDetailsResponse;
  courseContentDetails: CourseContentDetailsModel;
  audioIcon: string = "bi bi-music-note-beamed audIcon";
  videoIcon: string = "bi bi-camera-video vidIcon";
  view:boolean = false;
  constructor(private mediaContentService: MediaContentService, private courseContentService: CourseContentService) { }

  ngOnInit(): void {

    this.courseContentService.getContentDetail(this.contentId).subscribe({
      next: (contentResponse) => {
        console.log(contentResponse.content.mediaId);
        var mediaId = contentResponse.content.mediaId as string;
        if (mediaId == null) {
          return;
        }
        this.mediaContentService.getMediaContentDetails(mediaId).subscribe({
          next: (response) => {
            this.mediaDetails = response.content;
          }
        })

      }, error: (err) => {
        console.log(err);
      }
    })

  }
  viewMedia(){
    this.view = true;
  }

}
