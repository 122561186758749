import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-localized-string-table',
  templateUrl: './localized-string-table.component.html',
  styleUrls: ['./localized-string-table.component.css']
})
export class LocalizedStringTableComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/localizedstring/list"
  }

}
