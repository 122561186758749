import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-customize-copy-files',
  templateUrl: './customize-copy-files.component.html',
  styleUrls: ['./customize-copy-files.component.css']
})
export class CustomizeCopyFilesComponent implements OnInit {
  files:{targetPath:string,fileName:string,fileIndex:number,overwrite:boolean}[] = [];
  vmId:string;
  fileInputList:FileList;
  constructor(private translateService:TranslateService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
      }
    })
  }
  prepareFiles(){
    this.files = [];
    var fileEl = document.getElementById("fileList") as HTMLInputElement
    let fileList = fileEl.files as FileList;
    this.fileInputList = fileList;
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      var fileInfo = {fileName:file.name,index:index};
      this.addFileToFilesArray(fileInfo);

    }
  }
  setTargetPath(file:any,ev:any){
    var selectedFile = this.files.find(x=>x == file);
    if(selectedFile != undefined){
      selectedFile.targetPath = (ev.target as HTMLInputElement).value;
    }
    console.log(this.files);

  }
  setName(file:any,ev:any){
    var selectedFile = this.files.find(x=>x == file);
    if(selectedFile != undefined){
      selectedFile.fileName = (ev.target as HTMLInputElement).value;
    }
    console.log(this.files);

  }
  setOverwrite(file:any,ev:any){
    var selectedFile = this.files.find(x=>x == file);
    if(selectedFile != undefined){
      selectedFile.overwrite = (ev.target as HTMLInputElement).checked;
    }
    console.log(this.files);

  }
  addFileToFilesArray(fileInfo:any){
    this.files.push({fileIndex:fileInfo.index,fileName:fileInfo.fileName,targetPath:"",overwrite:false});
  }
  addFilesToCustomization(){
    if(this.files.find(x=>x.targetPath == "") != null){
      this.toastrService.error(this.translateService.instant("vmCustomization.targetPathMusntBeEmpty"));
      return;
    }
    var customizationValue:VMCustomization = {
      type: "copy-files",
      args: JSON.stringify(this.files)
    }
    var data = new FormData();
    data.append("type","copy-files");
    data.append("args",JSON.stringify(this.files));
    var fileEl = document.getElementById("fileList") as HTMLInputElement
    let fileList = fileEl.files as FileList;
    this.fileInputList = fileList;
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      data.append("files[]",file);
    }
    console.log(data.get("files[]"));
    this.virtualLabService.addCustomizationFilesToVm(this.vmId,data).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("vmCustomization.addedFiles"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })

  }

}
