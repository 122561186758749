import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-tenant-configuration',
  templateUrl: './create-tenant-configuration.component.html',
  styleUrls: ['./create-tenant-configuration.component.css']
})
export class CreateTenantConfigurationComponent implements OnInit {
  baseUrl:string;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseUrl = environment.apiUrl;
        this.formUrl = this.baseUrl + "api/cms/tenants/" + param["tenantId"] + "/configurations/create"
      }
    })
  }

}
