<h5>{{'sessionManagement.relatedFeatures' | translate}} <button class="btn btn-sm btn-warning" [title]="'sessionManagement.addNewFeature' | translate" (click)="addNewFeature()"><i class="bi bi-plus-lg"></i></button></h5>
<hr>
<div *ngIf="showFeatures && featureTypesSelectList">
    <div *ngFor="let feature of showFeatures" class="featureItem">
    <div  class="featureInfoItem">
        <div class="featureMain"><i class="bi bi-box-fill" style="color: brown;"></i> <font class="featureNameFont"> {{feature.name}}  <i>({{getFeatureLabel(feature.type)}})</i></font></div>
        <div class="featureEdit">
            <i (click)="showFeatureConfiguration(feature)" class="bi bi-eye-fill showIcon"></i>
            &nbsp;&nbsp;
            <i (click)="deleteSessionFeature(feature.id)" class="bi bi-trash-fill trash"></i>

        </div>
        
    </div>
    <div><span class="badge bg-danger">{{feature.enabled ? ('sessionManagement.active' | translate) : ('sessionManagement.passive' | translate)}}</span>&nbsp;<span class="badge bg-success">{{feature.ready ? ('sessionManagement.ready' | translate) : ('sessionManagement.notReady' | translate)}}</span></div>

    </div>

    <div class="featuresManagementDiv">
        <div class="featuresManagementDivLeft"></div>
        <div class="featuresManagementDivShowAll" *ngIf="allFeatures == false && showAllFeaturesBtn" (click)="showAllToggle()">{{'btn.showAll' | translate}}</div>
        <div class="featuresManagementDivHide" *ngIf="allFeatures" (click)="showAllToggle()">{{'btn.hide' | translate}}</div>
    </div>
</div>
