<div class="panel-container">
    <div class="area-title">{{'outlineItemPanel.area.toolbarTitle' | translate}}</div>
    <div class="toolbar">
        <div class="toolbar-selector" [class.selected]="dragSelector" (click)="selectItem('drag')"><i
                class="bi bi-arrows-move"></i></div>
        <div class="toolbar-selector" [class.selected]="handSelector" (click)="selectItem('hand')"><i
                class="bi bi-hand-index"></i></div>
    </div>
    <br />
    <div *ngIf="dragSelector == true" class="select-move-type">
        <div class="area-title">{{'outlineItemPanel.area.moveType' | translate}}</div>
        <label><input type="radio" name="moveType" [checked]="moveType == 'addAfter'" (click)="setMoveType('addAfter')"
                id="moveType_addAfter"> {{'outlineItemPanel.area.moveType.addAfter' | translate}}</label>
        <br />
        <label><input type="radio" name="moveType" [checked]="moveType == 'child'" (click)="setMoveType('child')"
                id="moveType_child"> {{'outlineItemPanel.area.moveType.addChildItem' | translate}}</label>
    </div>
    <div *ngIf="dragSelector == true" class="automatic-save-outlines">
        <label class="form-check-label"><input type="checkbox" (change)="changeAutomaticSave()" class="form-check-input" id="automatic-save-checkbox" [checked]="false"/> {{'automatic-save-outlines' | translate}}</label>
    </div>

    <div class="area-title">{{'outlineItemPanel.area.outlineItemsTitle' | translate}}</div>

    <div *ngIf="outlineList && render" class="outline-items">
        <div #ref (dragstart)="outlineItemDragStart($event)" [class.hand]="handSelector" [title]="handSelector == true ? 'Double click for load contents' : ''" (dblclick)="setPreviewItem(outlineItem.id)" (drop)="moveItem($event,ref)"
            (dragover)="allowDrop($event)" [draggable]="dragSelector" [id]="outlineItem.id"
            class="outline-item-container outline-parent-item-container" *ngFor="let outlineItem of outlineList">
            <div class="outline-item outline-parent-item" [class.selected]="outlineItem.id == selectedItem">
                <font style="user-select: none;"><i *ngFor="let dot of childLevelArr(outlineItem.level)"  class="bi bi-dot"></i><i
                        [class]="outlineItem.icon"></i> {{outlineItem.name}} </font>

                <div  class="outline-item-actions">
                    <div class="updateOutlineItemContainer">
                        <div (click)="editOutlineItem(outlineItem.id)" class="updateOutlineItemDiv">
                            <i class="bi bi-pencil-fill"></i>
                        </div>
                    </div>
                    <div class="deleteOutlineItemContainer">
                        <div (click)="deleteOutlineItem(outlineItem.id)" class="deleteOutlineItemDiv">
                            <i class="bi bi-trash-fill"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="outline-item-container outline-parent-item-container">
            <div (click)="addNew()" class="outline-item outline-add-new-item">
                <font><i [class]="'bi bi-plus-lg'"></i> {{'btn.addNew' | translate}}</font>
            </div>
        </div>
        <button *ngIf="automaticSave == false" class="btn btn-sm btn-primary" (click)="runMoveProcess()">
            Save <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <span *ngIf="automaticSave == true && saving == true" class="badge bg-danger">{{'saving' | translate}} <span class="spinner-border spinner-border-sm" style="vertical-align:middle;" role="status" aria-hidden="true"></span></span>
        <div *ngIf="loadingOutlines" class="loader">
            <div class="d-flex justify-content-center">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    </div>