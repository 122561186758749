import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageUpdateNoteRequest } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageUpdateNoteRequest';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
declare var $:any;
@Component({
  selector: 'lib-update-base-vm-image-note',
  templateUrl: './update-base-vm-image-note.component.html',
  styleUrls: ['./update-base-vm-image-note.component.css']
})
export class UpdateBaseVmImageNoteComponent implements OnInit {
  noteId:string;
  vmImageId:string;
  noteFields:BaseVmImageUpdateNoteRequest;
  constructor(private activatedRoute:ActivatedRoute,private baseImageService:BaseVmImageService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.noteId = param["noteId"];
        this.vmImageId = param["vmImageId"];

        this.getNoteFields();
      }
    })
  }

  getNoteFields(){
    this.baseImageService.getUpdateNoteFields(this.vmImageId,this.noteId).subscribe({
      next:(response)=>{
        this.noteFields = response.content;
        console.log(response.content);
        var titleEl = document.getElementById("note-title") as HTMLInputElement;
        titleEl.value = this.noteFields.title ?? "";
        $('#summernote').summernote('code',this.noteFields.content);
      }
    })
  }
  updateNote(){
    var titleEl = document.getElementById("note-title") as HTMLInputElement;
    var title = titleEl.value;
    var content = $('#summernote').summernote('code');
    if(content == null || content == ""){
      this.toastrService.error(this.translateService.instant("baseVmImage.note.contentCannotBeEmpty"));
      return;
    }
    this.noteFields = {
      content:content,
      vmImageId:this.vmImageId,
      title:title,
      noteId:this.noteId
    };
    this.baseImageService.updateNote(this.noteFields).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("baseVmImage.note.addedSuccessfully"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    });
  }

}
