import { Component, OnInit } from '@angular/core';
import { QuestionService } from '../../../services/questionManager/question.service';
import { QuestionSetDetail } from '../../../models/questionManagementModels/questionSetDetail';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-question-set-details',
  templateUrl: './question-set-details.component.html',
  styleUrls: ['./question-set-details.component.css']
})
export class QuestionSetDetailsComponent implements OnInit {
  questionSetId:number;
  questionSetDetail:QuestionSetDetail
  constructor(private activatedRoute:ActivatedRoute  ,private questionService:QuestionService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionSetId = param["questionSetId"];
        this.getQuestionSetDetails();
      }
    })

  }
  getQuestionSetDetails(){
    this.questionService.getQuestionSetWithContent(this.questionSetId).subscribe({
      next:(response)=>{
        this.questionSetDetail = response.content;
      }
    })
  }

}
