import { FooterService } from './footer.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterTestService implements FooterService{

  constructor() { }
  public getFooterMenu(): Observable<JSON>
  {
    throw new Error('Method not implemented.');
  }
}
