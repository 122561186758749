import { ElementRef, Injectable, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscriber } from 'rxjs';
declare var $: any;
declare var bootstrap: any;
@Injectable({
  providedIn: 'root'
})
export class GlobalOkCancelEventService {
  public subscriber: Subscriber<any>;
  public viewContainerRef: ViewContainerRef;
  public elementRef: ElementRef<HTMLDivElement>
  constructor(private translateService: TranslateService, private toastrService: ToastrService) { }
  set globalOkCancelRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;

  }
  set globalOkCancelElementRef(elr: ElementRef) {
    this.elementRef = elr;
  }
  showOkCancelWindow(title: string, body: string, showCancelButton?: boolean, okBtnString?: string, cancelBtnString?: string, showCheckbox?: boolean, checkboxMessage?: string) {
    return new Promise((resolve, reject) => {
      $(".okCancelModal").remove();
      var modalDiv = document.createElement("div");
      modalDiv.classList.add("okCancelModal");
      modalDiv.innerHTML = `<div class="modal fade" id="globalModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="globalModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="globalModalLabel">${title}</h5>
            <!--<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
          </div>

          <div class="modal-body">
            ${body}

                      ${showCheckbox ? '<label><input id="okcancel-checkbox" class="okcancel-checkbox" type="checkbox" class="form-check-input"/>' + (this.translateService.instant(checkboxMessage ?? 'checkbox.readAndConfirm')) + '</label>' : ''}
          </div>
          <div class="modal-footer">
            ${showCancelButton == false ? '' : '<button type="button" class="btn btn-secondary global-okcancel-cancelBtn" data-bs-dismiss="modal">' + (cancelBtnString != undefined ? this.translateService.instant(cancelBtnString) : this.translateService.instant('btn.cancel')) + '</button>'}
            <button type="button" class="btn btn-primary global-okcancel-okBtn" data-bs-dismiss="modal">${(okBtnString != undefined ? this.translateService.instant(okBtnString) : this.translateService.instant('btn.ok'))}</button>
          </div>
        </div>
      </div>
    </div>`
      this.elementRef.nativeElement.innerHTML = "";
      this.elementRef.nativeElement.append(modalDiv);
      var bsModal = new bootstrap.Modal(modalDiv.firstChild, {
        keyboard: false
      })
      bsModal.show();
      modalDiv.addEventListener('click', (ev) => {

        if ($(ev.target).hasClass("global-okcancel-cancelBtn")) {
          resolve(false);
        } else if ($(ev.target).hasClass("global-okcancel-okBtn")) {
          if (showCheckbox == undefined || showCheckbox == false || (showCheckbox == true && (modalDiv.getElementsByClassName("okcancel-checkbox")[0] as HTMLInputElement).checked == true)){
            resolve(true);
          }else{
            this.toastrService.error(this.translateService.instant("global.pleaseReadAndCheck"));
          }

        }
      })

    })
  }
}