import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, firstValueFrom, from } from 'rxjs';
import { RefreshTokenService } from '../lib/services/auth/refresh-token.service';
import { LoginService } from '../lib/services/login/login.service';

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService, private refreshTokenSvc: RefreshTokenService, private loginService: LoginService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.interceptProcess(request, next));

  }
  refreshTokenProcess(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.loginService.isLoggedControlWithExpiration() == false) {
        if (this.loginService.refreshExpirationControl() == true) {
          this.refreshTokenSvc.getTokenWithRefreshToken(sessionStorage.getItem("refresh_token") ?? "").subscribe({
            next: (response) => {
              var expiry = new Date();
              expiry.setSeconds(expiry.getSeconds()+response.content.expires_in);
              var refreshExpiry = new Date();
              refreshExpiry.setSeconds(expiry.getSeconds()+response.content.refresh_expires_in)
              sessionStorage.setItem("token", response.content.access_token);
              sessionStorage.setItem("refresh_token", response.content.refresh_token);
              sessionStorage.setItem("refresh_token_expiration", refreshExpiry.toJSON());
              sessionStorage.setItem("token_expiration", expiry.toJSON());
              resolve(true);
            },
            error: (err) => {
              resolve(true);
            }
          })
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    })
  }
  private async interceptProcess(request: HttpRequest<unknown>, next: HttpHandler): Promise<HttpEvent<unknown>> {

      var response = this.refreshTokenProcess().then(()=>{
        console.log("next");
        let token = sessionStorage.getItem("token");
        let tenantUrl = window.location.hostname;
  
        request = request.clone({
          headers: request.headers.append("SiteUrl", tenantUrl)
        })
        if (this.translateService.currentLang && this.translateService.defaultLang) {
          let acceptLanguage: string = "";

          let currentFullLanguage: string = localStorage.getItem("selectedLanguage") ?? this.translateService.currentLang;
          console.log(currentFullLanguage);
          console.log("-------------")
          console.log(currentFullLanguage)
          let currentLanguage: string = currentFullLanguage.substring(0, 2);
          let defaultFullLanguage: string = this.translateService.defaultLang;
          let defaultLanguage: string = defaultFullLanguage.substring(0, 2);
          acceptLanguage = currentFullLanguage + ", " + currentLanguage + ";q=0.9, " + defaultFullLanguage + ", " + defaultLanguage + ";q=0.2";
          console.log("acceptLanguage: " + acceptLanguage);
          request = request.clone({
            headers: request.headers.append("Accept-Language", acceptLanguage)
          })
        }
        request = request.clone({
          withCredentials: true,
        })
        if (token != null && request.headers.get("Authorization") == null) {
          let newRequest: HttpRequest<any>;
          newRequest = request.clone({
            headers: request.headers.append("Authorization", "Bearer " + token)
  
          });
          console.log(newRequest);
          return next.handle(newRequest).toPromise();
        }
  
        return next.handle(request).toPromise();
      })
      //return next.handle(request).toPromise() as Promise<HttpEvent<unknown>>;
      return response as Promise<HttpEvent<any>>


  }
}
