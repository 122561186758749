import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-icon-set',
  templateUrl: './create-icon-set.component.html',
  styleUrls: ['./create-icon-set.component.css']
})
export class CreateIconSetComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl = environment.apiUrl + "api/cms/iconsets/create"
  constructor() { }

  ngOnInit(): void {
  }

}
