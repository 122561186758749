import { Component, OnInit } from '@angular/core';
import { ActiveUserSessionsResponse } from '../../../models/activeSessionsResponse';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiceAccountManagerService } from '../../../services/service-account-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service-account-details',
  templateUrl: './service-account-details.component.html',
  styleUrls: ['./service-account-details.component.css']
})
export class ServiceAccountDetailsComponent implements OnInit {
  userId:string;
  userResponse:any;
  activeSessions:ActiveUserSessionsResponse = {userId:"",username:"",activeSessionItems : []};
  sessionLoading:boolean = false;
  constructor(private activatedRoute:ActivatedRoute,private accountService:ServiceAccountManagerService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getUserId();
    this.getActiveSessions();
    this.getUserDetails();
  }
  getUserId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param['userId'];
      }
    })
  }
  getActiveSessions(){
    this.sessionLoading = true;
    this.accountService.getServiceSessions(this.userId).subscribe({
      next:(response)=>{
        this.sessionLoading = false;
        this.activeSessions = response.content;
      },error:(err)=>{
        this.sessionLoading = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getUserDetails(){
    this.accountService.getServiceAccount(this.userId).subscribe({
      next:(response)=>{
        console.log(response);
        this.userResponse = response.content;
      }
    })
  }

}
