import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { AddSessionItemRequest } from '../../../models/SessionApiModels/addSessionItemRequest';
import { ActivatedRoute } from '@angular/router';
import { SessionTemplateService } from '../../../services/session/session-template.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-add-item',
  templateUrl: './session-management-add-item.component.html',
  styleUrls: ['./session-management-add-item.component.css']
})
export class SessionManagementAddItemComponent implements OnInit {

  constructor(private translateService:TranslateService,private sessionTemplateService:SessionTemplateService,private sessionApiService:SessionApiService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }
  selectedType:string;
  selectedId:string;
  @Input() sessionId:string = "";
  options:ListItemModel[] = [];
  loading:boolean = false;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        if(this.sessionId == ""){
          this.sessionId = param["sessionId"];
        }
      }
    })
    var selectEl = document.getElementById("typeSelect") as HTMLSelectElement;
    this.selectedType = selectEl.value;
    this.getOptions();
  }
  getOptions(){
    this.loading = true;
    this.sessionTemplateService.getSessionTemplateItemSourceId(this.selectedType).subscribe({
      next:(response)=>{
        this.loading = false;
        this.options = response.content;
        console.log(this.options)
        setTimeout(()=>{
          var selectIdElement = document.getElementById("sourceIdSelect") as HTMLSelectElement;
          this.selectedId = selectIdElement.value;
        },20);

      },error:(err)=>{
        this.loading = false;
        var errorMessage = this.translateService.instant("global.errorOccurred");
        this.toastrService.error(errorMessage);
      }
    })
  }
  changeType($ev:any){
    this.selectedType = $ev.target.value;
    this.getOptions();
  }
  changeSourceId($ev:any){
    this.selectedId = $ev.target.value;

  }
  addData(){
    console.log({itemType:this.selectedType,itemSourceId:this.selectedId})
    var addSessionItemRequest:AddSessionItemRequest = {
      sessionId:this.sessionId,
      itemType:this.selectedType,
      itemSourceId:this.selectedId,
      data:"",
      dependsOnFeatures:""
    };
    this.sessionApiService.addItemToSession(addSessionItemRequest).subscribe({
      next:(response)=>{
        var addedItemMessage = this.translateService.instant("sessionManagement.itemAdded");
        this.toastrService.success(addedItemMessage);
      },
      error:()=>{
        var itemAddError=this.translateService.instant("sessionManagement.itemAddedError")
        this.toastrService.error(itemAddError);
      }
    })
    //this.templateItemData.emit(sessionTemplateItem);
  }

}
