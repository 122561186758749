<div class="mainModal" *ngIf="open == true && componentData.data != ''">
<div class="modal fade" id="mainModal" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="mainModalLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable" [class.wideMargin]="wide">
      <div class="modal-content" [class.wideModalContent]="wide" [style.width]="width">
        <div class="modal-header globalModal-header">
          <h5 class="modal-title " id="mainModalLabel">{{modalData().title}}</h5>
          <button type="button" (click)="onCloseFnc()" class="btn-close globalModal-close-x" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="globalModal-content modalData">
          <div *ngIf="modalData().type == 1" [innerHTML]="sanitizedData"></div>
          <div class="iframeContainer"></div>
        </div>
        <div class="modal-footer globalModal-footer">
          <!--<button class="btn btn-primary" data-bs-target="#helperModal" data-bs-toggle="modal">Open second modal</button>-->
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="componentData.helperModals != undefined">
  <div *ngFor="let helper of componentData.helperModals" class="modal fade" id="helperModal-{{helper.id}}" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="helperModalLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="helperModalLabel">{{helper.title ?? 'Modal'}}</h5>
          <!--<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
        </div>
        <div class="modal-body helperModal-{{helper.id}}-content" >
          <div [innerHTML]="helper.data"></div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" data-bs-target="#mainModal" data-bs-toggle="modal" (click)="accept(helper.id)">Kabul Et</button>
          <button class="btn btn-danger" data-bs-target="#mainModal" data-bs-toggle="modal" (click)="cancel(helper.id)">Reddet</button>
        </div>
      </div>
    </div>
  </div>
</div>
  <a class="btn btn-primary" id="gotoMain" style="display: none;" data-bs-toggle="modal" href="#mainModal" role="button">Open first modal</a>
  <div *ngIf="componentData.helperModals.length > 0">
    <a *ngFor="let helper of componentData.helperModals" style="display:none;" class="btn btn-primary helperModalBtn-{{helper.id}}" data-bs-toggle="modal" [href]="'#helperModal-'+helper.id"  role="button">Open {{helper.id}} modal</a>
  </div>
</div>