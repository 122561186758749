import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-list-host-provider-type',
  templateUrl: './list-host-provider-type.component.html',
  styleUrls: ['./list-host-provider-type.component.css']
})
export class ListHostProviderTypeComponent implements OnInit {
  apiUrl:string = environment.apiUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
