<form *ngIf="loaded" class="formMain form-default-border form-maroon-border" [formGroup]="questionForm">
    <div class="form-title-div">
        <font class="form-title-content">Soruyu Güncelle</font>
    </div>
    <input type="hidden" class="form-control" id="id" name="id" formControlName="id">
    <div class="mb-3">
        <label for="name" class="form-label mainLabel">Başlık</label>
        <input type="text" class="form-control" id="name" name="name" formControlName="name">
    </div>

    <div class="mb-3">
        <label for="format" class="form-label mainLabel">Format</label>
        <select (change)="changeQuestionTextFormat($event)" class="form-select" id="format" name="format" formControlName="format">
            <option [value]="0">PlainText</option>
            <option [value]="1">HTML</option>
            <option [value]="2">Markdown</option>
            <option [value]="3">Json</option>
            <option [value]="4">Xml</option>
            <option [value]="5">Layout</option>
        </select>
    </div>

    <div class="mb-3">
        <label for="questionText" class="form-label mainLabel">Soru Metni</label>
        <input type="hidden" class="form-control" id="questionText" name="questionText"
            formControlName="questionText"/>
        <lib-typed-strings *ngIf="questionTextFormat" (getValue)="questionTextValueFnc($event)" [inputType]="questionTextFormat" [defaultValue]="questionTextDefault"></lib-typed-strings>
    </div>


    <!--
        Soru tipine göre gelecek elementler
    -->
    <font class="mainLabel">Soru Tipi Seçenekleri</font>
    <hr>
    <div class="mb-3" [class.hide]="selectedType != 0">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="trueFalseAnswer" name="trueFalseAnswer"
                formControlName="trueFalseAnswer">
            <label class="form-check-label mainLabel" for="trueFalseAnswer">
                Doğru
            </label>
        </div>
    </div>


    <div [class.hide]="selectedType != 2" class="mb-3">
        <label for="shortAnswerRegex" class="form-label mainLabel">Kısa Cevap Yanıtı</label>
        <input type="text" class="form-control" id="shortAnswerRegex" name="shortAnswerRegex" formControlName="shortAnswerRegex">
    </div>
    <div [class.hide]="selectedType != 2" class="mb-3">
        <label for="shortAnwserMinMatchScore" class="form-label mainLabel">Kısa Cevap Minimum Eşleşme Skoru</label>
        <input type="number" class="form-control" id="shortAnwserMinMatchScore" name="shortAnwserMinMatchScore" formControlName="shortAnwserMinMatchScore">
    </div>

    <div [class.hide]="selectedType != 1" class="mb-3">
        <label for="minNumChoices" class="form-label mainLabel">Minimum Seçim Sayısı</label>
        <input type="number" class="form-control" id="minNumChoices" name="minNumChoices" formControlName="minNumChoices">
    </div>

    <div [class.hide]="selectedType != 1" class="mb-3">
        <label for="maxNumChoices" class="form-label mainLabel">Maksimum Seçim Sayısı</label>
        <input type="number" class="form-control" id="maxNumChoices" name="maxNumChoices" formControlName="maxNumChoices">
    </div>
    <hr>
    <!--
        ------------------------------
    -->
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="active" name="active" formControlName="active">
        <label class="form-check-label mainLabel" for="active">
            Aktif
        </label>
    </div>

    <div class="mb-3">
        <label for="type" class="form-label mainLabel">Dil Seçimi</label>
        <select class="form-select" id="locale" name="locale" formControlName="locale">
            <option *ngFor="let language of languages" [selected]="questionModel.locale == language.value" [value]="language.value">{{language.label}}</option>
        </select>
    </div>
    

    <div class="mb-3">
        <label for="wrongAnswerInfoFormat" class="form-label mainLabel">Format</label>
        <select (change)="changeWrongAnswerFormat($event)" class="form-select" id="wrongAnswerInfoFormat" name="wrongAnswerInfoFormat"
            formControlName="wrongAnswerInfoFormat">
            <option [value]="0">PlainText</option>
            <option [value]="1">HTML</option>
            <option [value]="2">Markdown</option>
            <option [value]="3">Json</option>
            <option [value]="4">Xml</option>
            <option [value]="5">Layout</option>
        </select>
    </div>

    <div class="mb-3">
        <label for="wrongAnswerInfo" class="form-label mainLabel">Yanlış Cevap Açıklama Metni</label>
        <input type="hidden" class="form-control" id="wrongAnswerInfo" formControlName="wrongAnswerInfo" placeholder="">
        <lib-typed-strings *ngIf="wrongAnswerInfoFormat" [inputType]="wrongAnswerInfoFormat" [defaultValue]="wrongAnswerTextDefault" (getValue)="wrongAnswerValueFnc($event)"></lib-typed-strings>
    </div>


    <input type="hidden" id="options" [value]="0" name="options" formControlName="options" />
    <div class="optionsMainDiv mb-3">
        <b class="mainLabel">Seçenekler : </b>
        <div *ngFor="let option of questionOptions">
            <label class="form-check-label optionsLabel"> <input class="form-check-input optionsCheckbox"
                    type="checkbox" [value]="option.value" /> {{option.key}} </label>
        </div>

    </div>
    <div class="mb-3">
        <b class="mainLabel">Soru Kümeleri </b><i style="font-size: 12px;">Çoklu seçime izin veriliyor</i>
        <!--<select formControlName="sets" id="sets" name="sets" class="form-select" size="5" multiple aria-label="multiple size 5 select example">
            <option *ngFor="let set of setList" [value]="set.value">{{set.label}}</option>
        </select>-->
        <lib-autocomplete-input [showMiniItems]="true" [showToast]="true" [name]="'sets'" [values]="setList" [placeholder]="'Soru kümesi seçin'" [selectedValues]="selectedSets"></lib-autocomplete-input>
    </div>

    <div class="mb-3">
        <label for="difficult" class="form-label mainLabel">Zorluk Derecesi</label>
        <select class="form-select" id="difficulty" name="difficulty" formControlName="difficulty">
            <option [value]="0">Kolay</option>
            <option [value]="1">Orta</option>
            <option [value]="2">Zor</option>
        </select>
    </div>
    <div style="text-align: right;">
        <button class="btn btn-primary btn-sm" onclick="return false" (click)="updateQuestion()" [disabled]="loading">Güncelle</button>
    </div>
</form>

<br/>