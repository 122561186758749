import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { AddOutlineToEducationRequestModel } from '../../../models/Education/addOutlineToEducationRequestModel';
import { ToastrService } from 'ngx-toastr';
declare var $:any;
declare var window:any;
declare var monaco:any;
@Component({
  selector: 'lib-add-outline-to-education',
  templateUrl: './add-outline-to-education.component.html',
  styleUrls: ['./add-outline-to-education.component.css']
})
export class AddOutlineToEducationComponent implements OnInit {
  educationId:string;
  constructor(private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.educationId = param["educationId"];
      }
    })
    this.initializeEditor();
  }
  initializeEditor(){
    var editorElement = document.getElementById("outlineEditor") as HTMLDivElement;
    var monacoEditor: any = null;
    if(typeof(monaco) !== 'undefined'){
      setTimeout(function () {
        monacoEditor = monaco.editor.create(editorElement, {
          language: 'json',
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['outlineEditor'] = monacoEditor;
      }, 50)
    }
  }
  addOutline(){
    var addOutlineToEducationRequestModel:AddOutlineToEducationRequestModel = {
      educationId: this.educationId,
      outline: window['outlineEditor'].getValue()
    }
    this.educationService.addOutlineToEducation(this.educationId,addOutlineToEducationRequestModel).subscribe({
      next:(response)=>{
        this.toastrService.success("Sürüm eklendi");
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
