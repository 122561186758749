
<div *ngIf="lab" class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'lab.labManagement' | translate}}</font>
    </div>
    <h6>{{'lab.basicLabInfo' | translate}}</h6>
    <div class="item"><b class="itemName">{{'lab.labName' | translate}} : </b>
        <font class="itemValue">{{lab.name}}</font>
    </div>
    <div class="item"><b class="itemName">{{'lab.code' | translate}} : </b>
        <font class="itemValue">{{lab.code}}</font>
    </div>
    <div class="mb-3">
        <div class="item"><b class="itemName">{{'lab.tags' | translate}}</b>
            <div class="tagItem" *ngFor="let tag of tags">{{tag}}</div>
        </div>
    </div>
    <div class="mb-3">
        <div class="item"><b class="itemName">{{'lab.description'}}</b>
            <div>
                <lib-preview-text [allowHtml]="lab.descriptionFormat != 0 ? true : false" [text]="lab.description"></lib-preview-text>
            </div>
        </div>
    </div>

    <div class="mb-3">
        <h6>{{'lab.networkInfo' | translate}}</h6>
        <div class="networksContainer">
            <div class="mb-3" *ngFor="let net of lab.networks">
                <b class="networkName"><i class="bi bi-ethernet"></i> {{net.name}} adlı ağ</b>
                <ul>
                    <li><b class="itemName">{{'lab.network.shared' | translate}} : </b><font class="itemValue">{{net.shared ? "Evet" : "Hayır"}}</font></li>
                    <li><b class="itemName">{{'lab.network.networkName' | translate}} : </b><font class="itemValue">{{net.networkSpec.networkAddress}}</font></li>
                    <li><b class="itemName">{{'lab.network.subnetMask' | translate}} : </b><font class="itemValue">{{net.networkSpec.subnetMask}}</font></li>
                    <li><b class="itemName">{{'lab.network.dhcpActive' | translate}} : </b><font class="itemValue">{{net.networkSpec.dhcpEnabled ? "Evet" : "Hayır"}}</font></li>
                    <li *ngIf="net.networkSpec.dhcpEnabled"><b class="itemName" >{{'lab.network.dhcpStartBlock' | translate}} : </b><font class="itemValue">{{net.networkSpec.dhcpStart}}</font></li>
                    <li *ngIf="net.networkSpec.dhcpEnabled"><b class="itemName">{{'lab.network.dhcpEndBlock' | translate}} : </b><font class="itemValue">{{net.networkSpec.dhcpEnd}}</font></li>
                </ul>
            </div>
        </div>

    </div>
    <hr>
    <button class="btn btn-secondary btn-sm" [routerLink]="'/lab/'+labId+'/update'"><i class="bi bi-pencil-square"></i> {{'btn.edit'}}</button>&nbsp;
    <button class="btn btn-success btn-sm"> {{'btn.publish'}}</button>
</div>
<div class="mb-3 itemContainer">
    <h6>{{'lab.virtualMachines' | translate}}</h6>
    <pre>Sanal Makineler Tablosu Eklenecek</pre>
    <button class="btn btn-warning btn-sm" (click)="showAddVmModal()"><i class="bi bi-pc-display"></i> {{'lab.addVirtualMachineToLab' | translate}}</button>
</div>
<br/>
<lib-custom-table [externalAPIUrl]="tableUrl" *ngIf="tableUrl"></lib-custom-table>