import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../../models/responseModel';
import { ApiUrlService } from '../api/api-url.service';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { SetupVmInstanceSpecsResponse } from '../../models/setupVMInstanceModels/setupVmInstanceSpecsResponse';
import { SetupVmInstanceSummaryResponse } from '../../models/setupVMInstanceModels/setupVmInstanceSummaryResponse';
import { ListResponseModel } from '../../models/listResponseModel';
import { SetupVmInstanceRowModel } from '../../models/setupVMInstanceModels/setupVmInstanceRowModel';
import { SetupVmInstanceCloneRequest } from '../../models/setupVMInstanceModels/setupVmInstanceCloneRequest';
import { SetupVmInstanceCloneResponse } from '../../models/setupVMInstanceModels/setupVmInstanceCloneResponse';
import { SetupVmInstanceDeleteResponse } from '../../models/setupVMInstanceModels/setupVmInstanceDeleteResponse';
import { SetupVmInstanceDeleteRequest } from '../../models/setupVMInstanceModels/setupVmInstanceDeleteRequest';
import { RestartSetupVmInstanceRequest, SetupVmInstancePowerActionResponse, ShutdownSetupVmInstanceRequest, StartSetupVmInstanceRequest } from '../../models/setupVMInstanceModels/setupVmInstancePowerManagementModels';
import { SetupVmInstancePublishRequest } from '../../models/setupVMInstanceModels/setupVmInstancePublishRequest';
import { SetupVmInstancePublishResponse } from '../../models/setupVMInstanceModels/setupVmInstancePublishResponse';


@Injectable({
  providedIn: 'root'
})
export class SetupVmInstanceService {

  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) { }
  createSetupVmInstance(data: any) {
    return this.httpClient.post<SingleResponseModel<string>>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/create", data);
  }
  getSetupVmInstanceSpecs(id: string) {
    return this.httpClient.get<SingleResponseModel<SetupVmInstanceSpecsResponse>>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/" + id + "/specs");
  }
  buildSetupVmInstance(setupVmInstanceId: string) {
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/" + setupVmInstanceId + "/build", {
      instanceId: setupVmInstanceId,
      createBaseVMImageEntity: true
    })
  }
  getSummary(setupVmInstanceId: string) {
    return this.httpClient.get<SingleResponseModel<SetupVmInstanceSummaryResponse>>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/" + setupVmInstanceId + "/summary");
  }
  getSetupVmInstanceList() {
    return this.httpClient.get<ListResponseModel<SetupVmInstanceRowModel>>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/list");
  }
  getVmIcon(osType: string) {
    osType = osType.toLowerCase();
    switch (osType) {
      case "linux":
        return "/assets/os-icons/linux.png";

      default:
        return "";
    }
  }
  cloneSetupVmInstance(cloneRequest: SetupVmInstanceCloneRequest) {
    return this.httpClient.post<SingleResponseModel<SetupVmInstanceCloneResponse>>(this.apiUrlService.getApiUrl() + `api/range/vm/setupvminstances/${cloneRequest.setupVMId}/clone`, cloneRequest)
  }
  deleteSetupVmInstance(deleteRequest: SetupVmInstanceDeleteRequest) {
    return this.httpClient.delete<SingleResponseModel<SetupVmInstanceDeleteResponse>>(this.apiUrlService.getApiUrl() + `api/range/vm/setupvminstances/${deleteRequest.setupVMId}/delete`, { body: deleteRequest });
  }
  startVm(startVmRequest:StartSetupVmInstanceRequest) {
    return this.httpClient.post<SingleResponseModel<SetupVmInstancePowerActionResponse>>(this.apiUrlService.getApiUrl()+`api/range/vm/setupvminstances/${startVmRequest.setupVMId}/start`,startVmRequest)
  }
  shutdownVm(shutdownVmRequest:ShutdownSetupVmInstanceRequest) {
    return this.httpClient.post<SingleResponseModel<SetupVmInstancePowerActionResponse>>(this.apiUrlService.getApiUrl()+`api/range/vm/setupvminstances/${shutdownVmRequest.setupVMId}/shutdown`,shutdownVmRequest)
  }
  restartVm(restartVmRequest:RestartSetupVmInstanceRequest) {
    return this.httpClient.post<SingleResponseModel<SetupVmInstancePowerActionResponse>>(this.apiUrlService.getApiUrl()+`api/range/vm/setupvminstances/${restartVmRequest.setupVMId}/restart`,restartVmRequest)
  }
  publishVm(publishRequest:SetupVmInstancePublishRequest) {
    return this.httpClient.post<SingleResponseModel<SetupVmInstancePublishResponse>>(this.apiUrlService.getApiUrl()+`api/v2/range/vm/setupvminstances/${publishRequest.setupVMId}/publish`,publishRequest);
  }
}
