import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '../../../services/locale/locale.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ToastrService } from 'ngx-toastr';
import { LocaleUpdateDataResponse } from '../../../models/Locales/localeUpdateDataResponse';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-update-locale',
  templateUrl: './update-locale.component.html',
  styleUrls: ['./update-locale.component.css']
})
export class UpdateLocaleComponent implements OnInit {
  updateLocaleForm:FormGroup;
  localeUpdateData:LocaleUpdateDataResponse;
  locales:ListItemModel[];
  localeId:string;
  constructor(private localeService:LocaleService,private formBuilder:FormBuilder,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.localeId = param['locale'];
        this.getLocaleItems().then(()=>{
          this.getUpdateData().then(()=>{
            this.createForm();
          })
        })
      }
    })

  }
  createForm(){
    this.updateLocaleForm = this.formBuilder.group({
      id:[this.localeUpdateData.id,Validators.required],
      name:[this.localeUpdateData.name,Validators.required],
      active:[this.localeUpdateData.active],
      cultureCode:[this.localeUpdateData.cultureCode,Validators.required],
      iso639P1:[this.localeUpdateData.iso639P1],
      iso639P2:[this.localeUpdateData.iso639P2],
      wildcardLocaleId:[this.localeUpdateData.wildcardLocaleId,Validators.required],
      parentLocaleId:[this.localeUpdateData.parentLocaleId ?? ""],
    })
  }
  getUpdateData():Promise<boolean>{
    return new Promise((resolve,reject)=>{
      this.localeService.getLocaleUpdateData(this.localeId).subscribe({
        next:(response)=>{
          this.localeUpdateData = response.content;
          resolve(true);
          return;
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
          resolve(false);
          return;
        }
      })
    })

  }
  getLocaleItems():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.localeService.getLocalesWithSelectListSchema().subscribe({
        next:(response)=>{
          this.locales = response.content.filter(x=>x.value.toLowerCase() != this.localeId.toLowerCase());
          resolve(true);
          return;
        },error:(err)=>{
          resolve(false);
          return;
        }
      })
    })

  }
  submitForm(){
    if(this.updateLocaleForm.valid){
      var formData = Object.assign({},this.updateLocaleForm.value);
      formData.parentLocaleId = formData.parentLocaleId == '' ? null : formData.parentLocaleId;
      this.localeService.updateLocale(this.localeId,formData).subscribe({
        next:(response)=>{
          this.toastrService.success("Dil güncellendi");
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
