<div class="row">
    <div class="col-sm-6" >
        <div class="row">
            <div class="col-12" *ngFor="let question of questionModelsLeft">
                <div class="card mb-3">
                    <div class="card-body">
                        <h5 style="display: inline-block;" class="card-title">{{question.name}} </h5>
                        <h6 style="display: inline-block; margin-left: 5px;"> ( {{questionTypes[question.type].key}} ) </h6>
                        <p class="card-text"><b>Soru Metni : </b>{{question.questionText}}</p>
                        <div *ngIf="question.type == 0">
                            <b>Doğru : </b><input type="checkbox" [checked]="question.trueFalseAnswer" onclick="return false"
                                [readOnly]="true" />
                        </div>
                        <div *ngIf="question.type == 1">
                            <b>Seçilebilecek Minimum Yanıt : </b>
                            <font>{{question.minNumChoices}}</font>
                            <br />
                            <b>Seçilebilecek Maksimum Yanıt : </b>
                            <font>{{question.maxNumChoices}}</font>
                            <br /><br />
                            <h6>Yanıtlar</h6>
                            <div class="card" style="width: 100%;">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item trueFalseIconItem" *ngFor="let choice of question.choices">
                                        {{choice.label}} <i class="bi" [class.bi-check-lg]="choice.isCorrect"
                                            [class.bi-x-lg]="choice.isCorrect == false"></i></li>
        
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="question.type == 2">
                            <b>Kısa Cevap Yanıtı (Regex) : </b>
                            <font>{{question.shortAnswerRegex}}</font>
                            <br />
                            <b>Kısa Cevap Minimum Eşleşme Skoru : </b>
                            <font>{{question.shortAnwserMinMatchScore}}</font>
                        </div>
                        <div *ngIf="question.type == 3"></div>
                        <div *ngIf="question.type == 4"></div>
                        <div *ngIf="question.type == 5"></div>
                        <br />
                        <a href="#" class="btn btn-secondary btn-sm"
                            [routerLink]="'/lms/questions/'+question.id+'/details'">Soru Detayları</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-sm-6" >
        <div class="row">
            <div class="col-12" *ngFor="let question of questionModelsRight">
                <div class="card mb-3">
                    <div class="card-body">
                        <h5 style="display: inline-block;" class="card-title">{{question.name}} </h5>
                        <h6 style="display: inline-block; margin-left: 5px;"> ( {{questionTypes[question.type].key}} ) </h6>
                        <p class="card-text"><b>Soru Metni : </b>{{question.questionText}}</p>
                        <div *ngIf="question.type == 0">
                            <b>Doğru : </b><input type="checkbox" [checked]="question.trueFalseAnswer" onclick="return false"
                                [readOnly]="true" />
                        </div>
                        <div *ngIf="question.type == 1">
                            <b>Seçilebilecek Minimum Yanıt : </b>
                            <font>{{question.minNumChoices}}</font>
                            <br />
                            <b>Seçilebilecek Maksimum Yanıt : </b>
                            <font>{{question.maxNumChoices}}</font>
                            <br /><br />
                            <h6>Yanıtlar</h6>
                            <div class="card" style="width: 100%;">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item trueFalseIconItem" *ngFor="let choice of question.choices">
                                        {{choice.label}} <i class="bi" [class.bi-check-lg]="choice.isCorrect"
                                            [class.bi-x-lg]="choice.isCorrect == false"></i></li>
        
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="question.type == 2">
                            <b>Kısa Cevap Yanıtı (Regex) : </b>
                            <font>{{question.shortAnswerRegex}}</font>
                            <br />
                            <b>Kısa Cevap Minimum Eşleşme Skoru : </b>
                            <font>{{question.shortAnwserMinMatchScore}}</font>
                        </div>
                        <div *ngIf="question.type == 3"></div>
                        <div *ngIf="question.type == 4"></div>
                        <div *ngIf="question.type == 5"></div>
                        <br />
                        <a href="#" class="btn btn-secondary btn-sm"
                            [routerLink]="'/lms/questions/'+question.id+'/details'">Soru Detayları</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>