<div *ngIf="sectionDetails">
<h5 style="display: inline-block;">{{'courseManagement.sectionDetails' | translate}}</h5><span style="float: right; cursor: pointer;"
  class="badge bg-warning text-dark"><i class="bi bi-eye-fill"></i> {{'courseManagement.preview' | translate}}</span>
<div class="card" style="width: 100%">
  <div class="card-body">
    <h5 class="card-title"><i
        [class]="sectionDetails.icon !== '' ? getIconClasses(sectionDetails.icon) : 'bi-bi bi-bookmark-fill'"></i>
      {{sectionDetails.name}}</h5><span
      class="badge bg-warning text-dark" style="cursor: pointer;" (click)="updateSection()"><i
        class="bi bi-pencil-fill"></i> {{'courseManagement.editSection' | translate}}</span>
    <hr />
    <!--<h6 style="margin-top: 6px; margin-bottom: 0px;">{{'courseManagement.sectionDescription' | translate}}</h6>
    <p class="card-text" style="margin-bottom: 0px;">{{sectionDetails.section.description}}</p>-->
    <div class="mb-3">
      <label>
        <b>{{'global.icon' | translate}} </b>
        <i [class]="getIconClasses(sectionDetails.icon)"></i>&nbsp;<font style="font-size: 14px;">({{sectionDetails.icon}})</font>
      </label>
    </div>
    <div class="mb-3">
      <label>
        <b>{{'courseManagement.linkedCourseName' | translate}}</b>
      </label>
      <font>
        {{sectionDetails.courseName}}
      </font>
    </div>
    <div class="mb-3">
      <label>
        <b>{{'courseManagement.status' | translate}} : </b>
      </label>
      <font>
        {{getStatus(sectionDetails.status)}}
      </font>
    </div>
    <div class="mb-3">
      <label>
        <b>{{'courseManagement.options' | translate}}</b>
      </label>
      <div *ngFor="let option of outlineOptionsMap">
      <label class="form-check-label" >
        {{option.key}} 
        <input class="form-check-input" type="checkbox" [checked]="optionControl(option.value)" [readOnly]="true" onclick="return false">
      </label>
    </div>
    </div>
    <div class="mb-3" *ngIf="sectionDetails.parentId">
      <label>
        <b>{{'courseManagement.parentSection' | translate}} : </b>
      </label>
      <font>
        {{sectionDetails.parentName}}
        <button routerLink="/lms/courses/section/{{sectionDetails.parentId}}/details" class="btn btn-warning btn-sm">{{'btn.view' | translate}}</button>
      </font>
    </div>

    <div class="mb-3">
      <label>
        <b>{{'global.orderValue' | translate}} : </b>
      </label>
      <font>
        {{sectionDetails.orderValue}}
      </font>
    </div>
  </div>
  <div style="margin-bottom: 5px; margin-top: 0px; margin-right: 5px; text-align: right;">
    &nbsp;<button class="btn btn-primary btn-warning btn-sm" (click)="routeAddContent()"><i class="bi bi-plus-lg"></i> {{'courseManagement.addContent' | translate}}</button>
    &nbsp;<button class="btn btn-primary btn-sm" (click)="goToVersion()"><i class="bi bi-arrow-return-left"></i> Bağlı olduğu sürüm detaylarını görüntüle</button>&nbsp;
    <button class="btn btn-danger btn-sm" (click)="deleteSection()"><i class="bi bi-trash-fill"></i> {{'courseManagement.deleteSection' | translate}}</button>
  </div>
</div>
<br />
<!--<h5>{{'courseManagement.sectionRelatedFiles' | translate}}</h5>
<div class="card" style="width: 100%">
  <ul class="list-group list-group-flush">
    <li class="list-group-item fileGroupItem" *ngFor="let file of sectionDetails.files"><i [class]="file.icon"></i>
      {{file.filename}} <button (click)="downloadFile(file)" style="float: right;"
        class="btn btn-secondary btn-sm">{{'download' | translate}}</button></li>
    <li class="list-group-item"><button class="btn btn-sm btn-primary" style="float: right;"><i class="bi bi-file-earmark-plus-fill"></i> {{'courseManagement.addFile' | translate}}</button>
    </li>
  </ul>
</div>-->

<br/>
<h5>{{'courseManagement.sectionObjectives' | translate}}</h5>
<div class="card" style="width: 100%">
  <ul class="list-group list-group-flush">
    <li class="list-group-item objectiveGroupItem">Yakında eklenecek...</li>

  </ul>
</div>

<br/>

<h5>{{'courseManagement.contents' | translate}}</h5>
<div class="card" style="width: 100%">
  <lib-custom-table [externalAPIUrl]="apiUrl+'api/lms/courses/versions/outline/'+sectionId+'/items/list'">

  </lib-custom-table>
</div>
<br/><br/>
</div>