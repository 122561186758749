import { Injectable } from '@angular/core';
import { GlobalOkCancelEventService } from '../globalOkCancelEvent/global-ok-cancel-event.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormSubmitRedirectService {

  constructor(private globalOkCancelService:GlobalOkCancelEventService,private router:Router,private translateService:TranslateService) { }
  showModal(url:string,id:any,message?:string,okStr?:string,cancelStr?:string){
    this.globalOkCancelService.showOkCancelWindow("USORTA",message ? this.translateService.instant(message) : this.translateService.instant("formSubmit.successMessage"),true,(okStr ?? "btn.redirectDetailsPage"),(cancelStr ?? "btn.gobackForm")).then((x)=>{
      if(x == true){
        if(id != null && id != undefined && id != ""){
          var addr = url.replace("{id}",id);
          this.router.navigate([addr]);
        }else{  
          var addr = url;
          this.router.navigate([addr]);
        }
      }
    })
  }
}
