<div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item" *ngFor="let note of notes">
        <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseNote_'+note.id" aria-expanded="false"
                [attr.aria-controls]="'collapseNote_'+note.id">
                {{note.title ?? "Başlıksız Not"}}
            </button>
        </h2>
        <div [id]="'collapseNote_'+note.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample">
            <div class="accordion-body" [innerHTML]="note.noteContent">

            </div>
            <div class="accordion-footer" style="text-align: right; padding: 5px;">
                <button class="btn btn-sm btn-danger me-1" (click)="deleteNote(courseId,note.id??'')"><i
                        class="bi bi-trash-fill"></i> {{'btn.delete' |translate}}</button>
                <button class="btn btn-sm btn-primary" [disabled]="editMode" (click)="editNote(note)"><i
                        class="bi bi-pencil-fill"></i> {{'btn.update' |translate}}</button>
            </div>
        </div>
    </div>
</div>

<div style="text-align: right; margin-top: 5px;">
    <button class="btn btn-secondary btn-sm" (click)="newNote()">{{'notebook.addNewNote' | translate}}</button>
</div>
<div style="padding:20px 0px;">
    <input [hidden]="editMode == false" id="editNoteTitle" type="text" class="form-control"
        placeholder="{{'noteTitle' | translate}}">
    <hr *ngIf="editMode" />
    <div [hidden]="editMode == false" id="editNoteContent"></div>
    <button *ngIf="editMode && noteEditId != undefined && noteEditId != ''" class="btn btn-sm btn-primary"
        (click)="saveNote()">{{'notebook.save' | translate}}</button>
    <button *ngIf="editMode && (noteEditId == undefined || noteEditId == '')" class="btn btn-sm btn-primary"
        (click)="createNote()">{{'notebook.add' | translate}}</button>
    <button *ngIf="editMode" class="btn btn-sm btn-danger ms-1" (click)="cancelNote()">{{'notebook.cancel' |
        translate}}</button>
</div>