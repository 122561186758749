import { Component, Input, OnInit } from '@angular/core';
import { HostDashboardResponseModel } from '../../../models/DashboardModels/hostDashboardResponseModel';

@Component({
  selector: 'lib-host-dashboard-basic',
  templateUrl: './host-dashboard-basic.component.html',
  styleUrls: ['./host-dashboard-basic.component.css']
})
export class HostDashboardBasicComponent implements OnInit {
  @Input() hostBasic:HostDashboardResponseModel;
  constructor() { }

  ngOnInit(): void {
  }

}
