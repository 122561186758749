import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { CustomFormJsonService } from '../../../services/customFormJson/custom-form-json.service';
import { FeatureType } from '../../../models/SessionTemplateModels/featureType';
import { CustomForm } from '../../../models/CustomFormModels/customForm';
import { FeatureItem } from '../../../models/SessionTemplateModels/featureItem';
import { SessionTemplateService } from '../../../services/session/session-template.service';
import { CustomFormJsonFormModel } from '../../../models/CustomFormJson/customFormJsonFormModel';
import { CustomFormJsonPageModel } from '../../../models/CustomFormJson/customFormJsonPageModel';
import { CustomFormJsonInputAndValidateModel } from '../../../models/CustomFormJson/inputModel';
import { AddSessionFeatureRequest } from '../../../models/SessionApiModels/addSessionFeatureRequest';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-add-feature',
  templateUrl: './session-management-add-feature.component.html',
  styleUrls: ['./session-management-add-feature.component.css']
})
export class SessionManagementAddFeatureComponent implements OnInit {
  @Input() sessionId:string = "";
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private sessionTemplateService:SessionTemplateService,private sessionApiService:SessionApiService,private toastr:ToastrService,private formJsonCreator:CustomFormJsonService,private changeDetector:ChangeDetectorRef) { }
  featureType:FeatureType;
  featureList:{value:string,label:string}[] = [];
  show:boolean = false;
  featureConfiguration:CustomForm;
  loading:boolean = false;
  @Output() GetFeatureItem = new EventEmitter<FeatureItem>();
  ngAfterViewInit(){

  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        if(this.sessionId == ""){
          this.sessionId = param["sessionId"];
        }
      }
    })
    this.getFeatureTypeList();
  }
  getFeatureInfo(id:string){
    this.loading = true;
    this.sessionTemplateService.getFeatureType(id).subscribe({
      
      next:(data)=>{
        this.featureType = data.content;
        console.log(this.featureType);
        this.createCustomFormJson();
        this.show = false;
        this.changeDetector.detectChanges();
        this.show = true;
        this.loading = false;
      },
      error:(err)=>{
        this.loading = false;
        var errorMessage = this.translateService.instant("global.errorOccurred");
        this.toastr.error(errorMessage);
      }
    })
  }
  getFeatureTypeList(){
    this.sessionTemplateService.getFeatureTypesForSelectList().subscribe({
      next:(response)=>{
        console.log(response);
        this.featureList = response.content as {value:string,label:string}[]
        setTimeout(()=>{
          this.getFirstData()
        },100);
      }
    })
  }
  createCustomFormJson(){
    var customFormJsonFormModel:CustomFormJsonFormModel = {action:"#",enctype:"application/json",method:"post",buttons:[{button:"submitButton",localizationKey:"Ekle"}]}
    var customFormPageModel:CustomFormJsonPageModel = {title:"Özellik Parametreleri",layout:"1column",showReset:false};
    var inputAndValidate:CustomFormJsonInputAndValidateModel = {input:{}};
    
    var form = this.formJsonCreator.createCustomFormJson(customFormJsonFormModel,customFormPageModel,[inputAndValidate]);
    form.pages[0].elements = JSON.parse(this.featureType.configurationSchema);
    this.featureConfiguration = form;
    console.log(form);
    
  }
  getData(ev:Event){
    var input = ev.target as HTMLInputElement;
    this.getFeatureInfo(input.value);
  }
  getFirstData(){
    var sel = document.getElementById("featureSelect") as HTMLSelectElement;
    this.getFeatureInfo(sel.value);
  }
  getJsonData(jsonObj:any){
    var sel = document.getElementById("featureSelect") as HTMLSelectElement;
    console.log(jsonObj);
    var featureItem:FeatureItem = {typeId:sel.value,configuration:JSON.stringify(jsonObj)}
    console.log(featureItem);
    //this.GetFeatureItem.emit(featureItem);
    this.addFeatureToSession(this.sessionId,sel.value,this.featureList.find(x=>x.value == sel.value)?.label ?? "undefined",JSON.stringify(jsonObj));
  }
  addFeatureToSession(sessionId:string,typeId:string,name:string,configuration:string){
    var addSessionFeatureReq:AddSessionFeatureRequest = {typeId,name,configuration,sessionId};
    this.sessionApiService.addFeatureToSession(addSessionFeatureReq).subscribe({
      next:(response)=>{
        var addedMessage = this.translateService.instant("sessionManagement.featureAdded");
        this.toastr.success(addedMessage);
      },error:(err)=>{
        var featureAddError = this.translateService.instant("sessionManagement.featureAddError");
        this.toastr.success(featureAddError);
      }
    });
  }

}
