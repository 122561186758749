import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-menu-item-list',
  templateUrl: './menu-item-list.component.html',
  styleUrls: ['./menu-item-list.component.css']
})
export class MenuItemListComponent implements OnInit {
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var menu = param["menuId"];
        this.tableUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/menu/${menu}/items/list`
      }
    })
  }

}
