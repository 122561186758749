import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-virtual-machine-list',
  templateUrl: './virtual-machine-list.component.html',
  styleUrls: ['./virtual-machine-list.component.css']
})
export class VirtualMachineListComponent implements OnInit {
  apiUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    
  }

}
