<div class="detailsMain">
    <table class="courseDetails">
      <tr>
        <td><i class="detailIcon bi bi-clock"></i>
          <font class="detailTitle"> {{'length' | translate}} : </font>
          <font class="detailContent">{{dateParse()}}</font>
        </td>
      </tr>
      <tr>
        <td><i class="detailIcon bi bi-mortarboard-fill"></i>
          <font class="detailTitle"> Kategoriler : </font>
          <font class="detailContent">{{getCategoryName(educationModel.categories)}}</font>
        </td>
      </tr>
      <tr>
        <td><i class="detailIcon bi bi-award"></i>
          <font class="detailTitle"> {{'level' | translate}} : </font>
          <font class="detailContent">{{getEducationLevel()}}</font>
        </td>
      </tr>
    </table>
  </div>