import { FormElement } from './../../models/CustomFormModels/formElement';
import { Directive, Input, Renderer2, ElementRef, Injectable } from '@angular/core';

@Directive({
  selector: '[libAddAttributes]'
})
export class AddAttributesDirective {
  @Input() attributes:{key:string,value:string}[]
  constructor(private renderer:Renderer2,private elementRef:ElementRef) { }
  ngOnInit() {
    this.addAttributes();
  }
  addAttributes() {

    this.attributes.forEach((attr: {key:string,value:string}) => {
            this.renderer.setAttribute(this.elementRef.nativeElement, attr.key, attr.value);
    });

    this.elementRef.nativeElement.removeAttribute('libAddAttributes');
  }
}
