import { Component, OnInit } from '@angular/core';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { AdminManagerService } from '../../../services/admin-manager.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-admin',
  templateUrl: './update-admin.component.html',
  styleUrls: ['./update-admin.component.css']
})
export class UpdateAdminComponent implements OnInit {

  customFormData:CustomForm;
  constructor(private adminManagemenetService:AdminManagerService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.adminManagemenetService.getUpdateUserForm(params['user']).subscribe({
        next:(response)=>{
          this.customFormData = response;
        }
      })
    })

  }

}
