import { Component, Input, OnInit } from '@angular/core';
import { LearnCourseOutlineModel } from '../../../models/LearnModels/learnCourseOutlineModel';

@Component({
  selector: 'lib-course-outline',
  templateUrl: './course-outline.component.html',
  styleUrls: ['./course-outline.component.css']
})
export class CourseOutlineComponent implements OnInit {
  @Input() courseOutline:LearnCourseOutlineModel[];
  constructor() { }

  ngOnInit(): void {
  }

}
