import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-update-course-list',
  templateUrl: './update-course-list.component.html',
  styleUrls: ['./update-course-list.component.css']
})
export class UpdateCourseListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
