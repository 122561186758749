import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-change-service-account-password',
  templateUrl: './change-service-account-password.component.html',
  styleUrls: ['./change-service-account-password.component.css']
})
export class ChangeServiceAccountPasswordComponent implements OnInit {
  changePasswordFormUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.changePasswordFormUrl = environment.apiUrl+ "api/accounts/service-account/"+param['user']+"/changepassword";
      }
    })
  }

}
