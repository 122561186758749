import { Component, OnInit } from '@angular/core';
import { CourseTranslationService } from '../../../services/education/course-translation.service';
import { ActivatedRoute } from '@angular/router';
import { CourseTranslationDetailsModel } from '../../../models/Education/CourseTranslationModels/courseTranslationDetailsModel';
import { CmsImageService } from '../../../services/cmsImages/cms-image.service';

@Component({
  selector: 'lib-course-translation-details',
  templateUrl: './course-translation-details.component.html',
  styleUrls: ['./course-translation-details.component.css']
})
export class CourseTranslationDetailsComponent implements OnInit {
  courseId:string;
  translationId:number;
  translationDetails:CourseTranslationDetailsModel;
  constructor(private activatedRoute:ActivatedRoute,private courseTranslationService:CourseTranslationService,private cmsImageService:CmsImageService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseId = param["courseId"];
        this.translationId = parseInt(param["translationId"]);
        this.getTranslationDetails();
      }
    })
  }
  getTranslationDetails(){
    this.courseTranslationService.courseTranslationDetails(this.courseId,this.translationId).subscribe({
      next:(response)=>{
        this.translationDetails = response.content;
      }
    })
  }
  getImageUrl(id:string | undefined){
    if(id == undefined || id == "" || id == null)
      return;
    return this.cmsImageService.getUrl(id);
  }

}
