<h5>{{'vmCustomization.setRootPassword' | translate}}</h5>
<hr />
<form [formGroup]="setRootPasswordForm">
<div class="mb-3 form-floating">

    <input type="password" class="form-control" [class.is-invalid]="errorControl('password')" id="password" formControlName="password" placeholder="">
    <label for="password" class="form-label">{{'vmCustomization.password' | translate}}</label>
</div>
<div class="mb-3 form-floating">

    <input type="password" class="form-control" id="repassword" [class.is-invalid]="errorControl('repassword')" formControlName="repassword" placeholder="">
    <label for="repassword" class="form-label">{{'vmCustomization.rePassword' | translate}}</label>
</div>
<button class="btn btn-warning" (click)="setRootPassword()">{{'vmCustomization.setPassword' | translate}}</button>
</form>