import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-list-vm-instance-type-mapping',
  templateUrl: './list-vm-instance-type-mapping.component.html',
  styleUrls: ['./list-vm-instance-type-mapping.component.css']
})
export class ListVmInstanceTypeMappingComponent implements OnInit {
  apiUrl = environment.apiUrl;
  vmInstanceTypeId:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmInstanceTypeId = param['instanceTypeId'];
      }
    })
  }

}
