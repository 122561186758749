import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-update-media-content',
  templateUrl: './update-media-content.component.html',
  styleUrls: ['./update-media-content.component.css']
})
export class UpdateMediaContentComponent implements OnInit {
  id:string;
  formUrl:string;
  baseUrl:string = environment.apiUrl;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["mediaId"];
        this.formUrl = this.baseUrl + "api/lms/contents/media/"+this.id+"/update"
      }
    })
  }

}
