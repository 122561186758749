import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { QuestionOptionEnum } from '../../../models/questionManagementModels/questionOptionsEnum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { QuestionService } from '../../../services/questionManager/question.service';
import { QuestionModel } from '../../../models/questionManagementModels/questionModel';
import { UpdateQuestionModel } from '../../../models/questionManagementModels/updateQuestionModel';
import { LocaleService } from '../../../services/locale/locale.service';
import { mode } from 'd3';
declare var $:any;
declare var window:any;
@Component({
  selector: 'lib-update-question',
  templateUrl: './update-question.component.html',
  styleUrls: ['./update-question.component.css']
})
export class UpdateQuestionComponent implements OnInit {
  questionTextFormat:number[];
  loading:boolean;
  questionTextValue:any;
  questionTextDefault:string;
  wrongAnswerInfoFormat:number[];
  wrongAnswerTextValue:any;
  wrongAnswerTextDefault:string;
  questionId:number;
  questionModel:QuestionModel;
  questionForm:FormGroup;
  selectedType:number;
  setList:ListItemModel[] = [];
  selectedSets:string[];
  questionOptions = Object.entries(QuestionOptionEnum).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]}
  }).filter(x=>x.value != 0);
  languages:ListItemModel[] = [];
  loaded:boolean = false;
  constructor(private questionService:QuestionService ,private localeService:LocaleService,private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = param["questionId"];
        this.getLanguages();
      }
    })
  }
  wrongAnswerValueFnc($ev:any){
    this.wrongAnswerTextValue = $ev;
  }
  questionTextValueFnc($ev:any){
    this.questionTextValue = $ev;
  }
  changeQuestionTextFormat($ev:any){
    var el = $ev.target as HTMLSelectElement;
    this.questionTextFormat = [parseInt(el.value)];
  }
  changeWrongAnswerFormat($ev:any){
    var el = $ev.target as HTMLSelectElement;
    this.wrongAnswerInfoFormat = [parseInt(el.value)];
  }
  getQuestion(){
    this.questionService.updateQuestionFields(this.questionId).subscribe({
      next:(response)=>{
        var model = response.content;
        this.questionTextFormat = [model.format];
        console.log(this.questionTextDefault)
        this.questionTextDefault = model.questionText;
        this.wrongAnswerInfoFormat = [model.wrongAnswerInfoFormat];
        this.wrongAnswerTextDefault = model.wrongAnswerInfo;

        var sets:string[] = [];
        model.sets.forEach(a=>sets.push(a.toString()));
        this.selectedSets = sets;
        this.questionModel = model;
        this.loaded = true;
        this.selectedType = this.questionModel.type;
        this.updateQuestionForm();
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getLanguages(){
    this.localeService.getLocalesWithSelectListSchema().subscribe({
      next:(response)=>{
        this.languages = response.content;
        this.getSetList();
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");

      }
    })
  }
  getSetList(){
    this.questionService.getQuestionSetList().subscribe({
      next:(response)=>{
        this.setList = response.content;
        this.getQuestion();
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");

      }
    })
  }
  updateQuestion(){
    console.log(this.questionTextValue());
    this.questionForm.get("questionText")?.setValue(this.questionTextValue());
    this.questionForm.get("wrongAnswerInfo")?.setValue(this.wrongAnswerTextValue());

    if(this.questionForm.valid){
      this.loading = true;
      var updateQuestionModel:UpdateQuestionModel = Object.assign({},this.questionForm.value);
      updateQuestionModel.options = this.optionsValue();
      updateQuestionModel.format = typeof(updateQuestionModel.format) == "string" ? parseInt(updateQuestionModel.format) : updateQuestionModel.format;
      updateQuestionModel.wrongAnswerInfoFormat = typeof(updateQuestionModel.wrongAnswerInfoFormat) == "string" ? parseInt(updateQuestionModel.wrongAnswerInfoFormat) : updateQuestionModel.wrongAnswerInfoFormat;
      var sets:number[] = [];
      window["autocomplete_sets"].forEach((a:string)=>sets.push(parseInt(a as any)));
      updateQuestionModel.sets = sets;
      this.questionService.updateQuestion(updateQuestionModel).subscribe({
        next:(response)=>{
          if(response.success){
            this.loading = false;
            this.toastrService.success("Soru güncellendi");
          }else{
            this.loading = false;
            this.toastrService.error("Bir hata oluştu");
          }

        },error:(err)=>{
          this.loading = false;
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
      this.loading = false;
      console.log(this.questionForm.errors)
    }
  }

  setOptionsCheckbox(optionValue:number){
    setTimeout(()=>{
      this.questionOptions.forEach((option)=>{
        if(optionValue & option.value){
          var check = document.querySelectorAll(`.optionsCheckbox[value='${option.value}']`).item(0) as HTMLInputElement;
          check.checked = true;
        }
      })
    },500)

  }
  optionsValue(){
    var val = 0;
    var checks = document.getElementsByClassName("optionsCheckbox");
    for (let index = 0; index < checks.length; index++) {
      const checkbox = checks[index] as HTMLInputElement;
      if(checkbox.checked){
        val+= parseInt(checkbox.value)
      }
    }
    return val;
  }
  updateQuestionForm(){

      this.questionForm = this.formBuilder.group({
        id:[this.questionModel.id],
        name: [this.questionModel.name,Validators.required],
        questionText: [this.questionModel.questionText,Validators.required],
        format:[this.questionModel.format],
        active:[this.questionModel.active],
        locale:[this.questionModel.locale,Validators.required],
        trueFalseAnswer:[this.questionModel.trueFalseAnswer ?? false],
        shortAnswerRegex:[this.questionModel.shortAnswerRegex ?? ""],
        shortAnwserMinMatchScore:[this.questionModel.shortAnwserMinMatchScore ?? 0],
        minNumChoices:[this.questionModel.minNumChoices ?? 0],
        maxNumChoices:[this.questionModel.maxNumChoices ?? 0],
        wrongAnswerInfo:[this.questionModel.wrongAnswerInfo],
        wrongAnswerInfoFormat:[this.questionModel.wrongAnswerInfoFormat],
        options:[this.questionModel.options],
        sets:[this.questionModel.sets],
        difficulty:[this.questionModel.difficulty]
      })
      this.setOptionsCheckbox(this.questionModel.options);

  }

}
