import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-delete-role-from-user',
  templateUrl: './delete-role-from-user.component.html',
  styleUrls: ['./delete-role-from-user.component.css']
})
export class DeleteRoleFromUserComponent implements OnInit {
  id:string;
  roleId:string;
  user:boolean = false;
  admin:boolean = false;
  service:boolean = false;
  constructor(private accessControlService:AccessControlService,private translateService:TranslateService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        if(param["userId"] != null){
            this.id = param["userId"];
            this.user = true;
            this.admin = false;
            this.service = false;
        }else if(param["adminId"] != null){
            this.id = param["adminId"];
            this.user = false;
            this.admin = true;
            this.service = false;
        }else if(param["serviceId"] != null){
          this.id = param["serviceId"];
          this.user = false;
          this.admin = false;
          this.service = true;
        }
        this.roleId = param["roleId"];
      }
    })
    
  }
  process(){
    var req:Observable<ResponseModel>;
    if(this.user){
        req = this.accessControlService.removeRoleFromUserAccount({roleId:this.roleId,userId:this.id})
    }else if(this.admin){
      req = this.accessControlService.removeRoleFromAdminAccount({roleId:this.roleId,userId:this.id})
    }else if(this.service){
      req = this.accessControlService.removeRoleFromServiceAccount({roleId:this.roleId,userId:this.id})
    }else{
      this.toastrService.error("Kullanıcı tipi alınamadı");
      return;
    }
    req.subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("accessControl.roleRemovedFromUser"));
        
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
