import { Component, Input, OnInit } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { SessionTemplateService } from '../../../services/session/session-template.service';
import { ToastrService } from 'ngx-toastr';
import { SessionItemListItemModel } from '../../../models/SessionApiModels/sessionItemListItemModel';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-list-items',
  templateUrl: './session-management-list-items.component.html',
  styleUrls: ['./session-management-list-items.component.css']
})
export class SessionManagementListItemsComponent implements OnInit {
  @Input() sessionId:string;
  sessionItems:SessionItemListItemModel[];
  itemSources:ListItemModel[] = [];
  itemTypes:ListItemModel[] = [];
  constructor(private translateService:TranslateService,private globalModalService:GlobalModalService, private sessionApiService:SessionApiService,private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getFeatureTypes();
    this.getItems();
  }
  getFeatureTypes(){
    var itemTypes = ["lab","course"];
    itemTypes.forEach((type)=>{
      this.addItemSources(type);
    })
  }
  addItemSources(featureType:string){
    this.sessionTemplateService.getSessionTemplateItemSourceId(featureType).subscribe(
      {
        next:(response)=>{
          this.itemSources.push(...response.content);
        },error:(err)=>{
          var errorMessage = this.translateService.instant("global.errorOccurred");
          this.toastrService.error(errorMessage);
        }
      })
  }
  getSourceName(sourceId:string){
    return this.itemSources.find(x=>x.value == sourceId)?.label ?? sourceId;
  }
  getItems(){
    this.sessionApiService.getItemsFromSession(this.sessionId).subscribe({
      next:(response)=>{
        this.sessionItems = response.content;
      },error:(err)=>{
        var errorMessage = this.translateService.instant("global.errorOccurred");
        this.toastrService.error(errorMessage);
      }
    })
  }
  deleteSessionItem(itemId:number){
    var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:"Delete Item",data:`/modal/sessions/${this.sessionId}/item/${itemId}/delete`,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }
  addNewItem(){
    var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:"Add Item",data:`modal/session/${this.sessionId}/additem`,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }

}
