import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-delete-removable-image',
  templateUrl: './delete-removable-image.component.html',
  styleUrls: ['./delete-removable-image.component.css']
})
export class DeleteRemovableImageComponent implements OnInit {
  id:string;
  apiUrl:string = environment.apiUrl;

  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        console.log(this.id);
      }
    })
  }

}
