import { Component, OnInit } from '@angular/core';
import { VmInstanceTypeMappingsUpdateInfoModel } from '../../../models/vmInstanceTypeMappings/vmInstanceTypeMappingsUpdateInfo';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateVmInstanceMappingModel } from '../../../models/vmInstanceTypeMappings/updateVmInstanceMappingModel';
import { TranslateService } from '@ngx-translate/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';

@Component({
  selector: 'lib-update-vm-instance-type-mapping',
  templateUrl: './update-vm-instance-type-mapping.component.html',
  styleUrls: ['./update-vm-instance-type-mapping.component.css']
})
export class UpdateVmInstanceTypeMappingComponent implements OnInit {
  mappingId:string;
  mappingUpdateInfo:VmInstanceTypeMappingsUpdateInfoModel;
  mappingUpdateForm:FormGroup;
  cpuList:ListItemModel[] = [];
  ramList:ListItemModel[] = [];
  constructor(private translateService:TranslateService,private formBuilder:FormBuilder ,private activatedRoute:ActivatedRoute,private toastrService:ToastrService, private vmInstanceService:VmInstanceService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:async (param)=>{
        this.mappingId = param["mappingId"];
        this.getRamList().then(()=>{
          this.getCpuCountList().then(()=>{
            this.getMappingUpdateInfo().then(()=>{
              this.createForm();
              setTimeout(()=>{
                this.mappingUpdateForm.get("ram")?.setValue(this.mappingUpdateInfo.ram);
                this.mappingUpdateForm.get("cpuCount")?.setValue(this.mappingUpdateInfo.cpuCount);
              },100);
            });
          })
        });

      }
    })
  }
  createForm(){
    this.mappingUpdateForm = this.formBuilder.group({
      active:[this.mappingUpdateInfo.active],
      notes:[this.mappingUpdateInfo.notes],
      priority:[this.mappingUpdateInfo.priority],
      name:[this.mappingUpdateInfo.name,Validators.required],
      typeId:[this.mappingUpdateInfo.typeId,Validators.required],
      ram:[this.mappingUpdateInfo.ram],
      cpuCount:[this.mappingUpdateInfo.cpuCount]
    })
  }
  getRamList() : Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.vmInstanceService.getRamList().subscribe({
        next:(response)=>{
          response.content = response.content.reverse();
          response.content.push({value:null,label:"Not-Selected"});
          response.content = response.content.reverse();
          this.ramList = response.content;
          resolve(true);
        }
      })
    })

  }
  getCpuCountList() : Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.vmInstanceService.getCpuCountList().subscribe({
        next:(response)=>{
          response.content = response.content.reverse();
          response.content.push({value:"0",label:"Not-Selected"});
          response.content = response.content.reverse();
          this.cpuList = response.content;
          resolve(true);
  
        }
      })
    })

  }
  updateMapping(){
    if(this.mappingUpdateForm.valid){
      var data:UpdateVmInstanceMappingModel = Object.assign({},this.mappingUpdateForm.value);
      data.priority = typeof(data.priority) == "string" ? parseInt(data.priority) : data.priority;
      data.cpuCount = typeof(data.cpuCount) == "string" ? parseInt(data.cpuCount) : data.cpuCount;
      if((data.ram as any) == "null"){
        (data.ram as any) = null;
      }
      data.mappingId = this.mappingId;
      this.vmInstanceService.updateInstanceTypeMapping(data).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("global.updateSuccessfull"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }
  getMappingUpdateInfo():Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getInstanceTypeMappingUpdateInfo(this.mappingId).subscribe({
        next:(response)=>{
          this.mappingUpdateInfo = response.content;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })

  }

}
