import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DynamicFormToJsonElementModel } from '../../models/DynamicFormToJsonModels/dynamicFormToJsonElement';
import { DynamicFormToJsonElementTypesModel } from '../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
declare var $: any;

@Component({
  selector: 'lib-dynamic-form-to-json',
  templateUrl: './dynamic-form-to-json.component.html',
  styleUrls: ['./dynamic-form-to-json.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormToJsonComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() formElements: DynamicFormToJsonElementTypesModel[] = [];
  @Input() addWithData: DynamicFormToJsonElementTypesModel[] = [];
  @Input() showDeleteBtn: boolean = false;
  @Input() hideButtons:boolean = false;
  @Input() createObject:boolean = false;
  @Input() reset:boolean[] = [false];
  @Output() dataChanges: EventEmitter<string> = new EventEmitter<string>();
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  elementIds: string[] = [];
  dataJsonStr: string;
  dataJson: any[] = [];
  mainDynamicFormId: string;
  @Input() autoSave: boolean = true;
  ngOnInit(): void {
    this.mainDynamicFormId = this.createId();
    this.addWithDataProcess();
  }
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if(this.reset[0] == true){
      this.reset = [false];
      this.resetForm();
    }
    this.addWithDataProcess();


  }
  createId() {
    return (Math.random() + 1).toString(36).substring(7);
  }
  resetForm(){
    this.elementIds = [];
    this.dataJson = [];
    this.dataJsonStr = "";
    this.addWithData = [];
    var a = document.getElementsByClassName("dynamicFormDataRows_"+this.mainDynamicFormId)[0];
    $(".dynamicFormDataRows_"+this.mainDynamicFormId).html("");
    console.log(a.innerHTML);

  }
  createDataRow(typeName: string) {
    this.createDataInput(typeName);
    if (this.autoSave) {
      this.sendJsonToCaller();
    }
  }
  addWithDataProcess() {
    this.addWithData.forEach((data) => {
      this.createWithData(data.typeName);
      this.addWithData = this.addWithData.filter(x => x != data);
    })
    this.sendJsonToCaller();
  }
  createWithData(typeName: string) {
    var id = this.createId();
    this.elementIds.push(id);
    var dataDiv = document.createElement("div");
    dataDiv.classList.add("data-row-main-div", "data-main-" + id);
    dataDiv.setAttribute("data-id", id);
    document.getElementsByClassName("dynamicFormDataRows_" + this.mainDynamicFormId)[0].appendChild(dataDiv);
    var selectedFormType = this.addWithData.find(x => x.typeName == typeName);
    if (selectedFormType == undefined) {
      throw "Hata";
    }
    selectedFormType.element.forEach(formInput => {
      switch (formInput.valueType) {
        case "string":
          this.createStringInput(id, formInput);
          break;
        case "boolean":
          this.createBoolInput(id, formInput);
          break;
        case "static":
          this.createStaticInput(id, formInput);
          break;
        case "number":
          this.createNumberInput(id, formInput);
          break;
        case "textarea":
          this.createTextareaInput(id, formInput);
          break;
          case "select":
            this.createSelectInput(id,formInput);
      }
    })
    if (this.showDeleteBtn) {
      this.addDeleteBtn(id);
    }

  }
  addDeleteBtn(id: string) {
    var mainDiv = document.getElementsByClassName("data-main-" + id)[0] as HTMLDivElement;
    console.log(id);
    console.log(mainDiv);
    var button = document.createElement("button");
    button.classList.add("btn", "btn-danger", "deleteRow");
    button.setAttribute("data-id", id);
    var icon = document.createElement("i");
    icon.classList.add("bi", "bi-trash-fill");
    button.appendChild(icon);
    mainDiv.append(button);
    button.onclick = () => {
      mainDiv.remove();
      this.elementIds = this.elementIds.filter(x => x != id);
      if (this.autoSave) {
        this.sendJsonToCaller();
      }

    }
  }
  deleteRowListener() {

  }
  createDataInput(typeName: string) {
    var id = this.createId();
    this.elementIds.push(id);
    var dataDiv = document.createElement("div");
    dataDiv.classList.add("data-row-main-div", "data-main-" + id);
    dataDiv.setAttribute("data-id", id);
    document.getElementsByClassName("dynamicFormDataRows_" + this.mainDynamicFormId)[0].appendChild(dataDiv);
    var selectedFormType = this.formElements.find(x => x.typeName == typeName);
    if (selectedFormType == undefined) {
      throw "Hata";
    }
    selectedFormType.element.forEach(formInput => {
      switch (formInput.valueType) {
        case "string":
          this.createStringInput(id, formInput);
          break;
        case "boolean":
          this.createBoolInput(id, formInput);
          break;
        case "static":
          this.createStaticInput(id, formInput);
          break;
        case "number":
          this.createNumberInput(id, formInput);
          break;
        case "textarea":
          this.createTextareaInput(id, formInput);
          break;
        case "select":
          this.createSelectInput(id, formInput);
          break;
      }
    })
    if (this.showDeleteBtn) {
      this.addDeleteBtn(id);
    }
  }
  createSelectInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var selectDiv = document.createElement("div");
    selectDiv.classList.add("data-input-div", "input-div-" + id);
    selectDiv.style.display = "inline-block";
    var stringLabel = document.createElement("label");
    stringLabel.innerText = elementInfo.label;
    var selectInput = document.createElement("select");
    selectInput.setAttribute("data-name", elementInfo.name);
    selectInput.setAttribute("data-type", "string");
    selectInput.setAttribute("data-key", randomId);
    if (elementInfo.subItems) {
      elementInfo.subItems.forEach(item => {
        var opt = document.createElement("option");
        opt.innerText = item.label;
        opt.value = item.value;
        opt.selected = item.selected;
        selectInput.appendChild(opt);
        if(item.selected){
          selectInput.value = item.value;
        }
      })
    }
    if (this.autoSave) {
      selectInput.onkeyup = () => {
        this.sendJsonToCaller();
      }
      selectInput.onchange = () => {
        this.sendJsonToCaller();
      }
    }
    if (elementInfo.defaultValue) {
      selectInput.value = elementInfo.defaultValue
    }
    if (elementInfo.readonly) {
      selectInput.disabled = true;
    }
    selectInput.classList.add("form-select", "data-input", "data-string-input", "string-input-" + id);
    stringLabel.appendChild(selectInput);
    selectDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(selectDiv);
  }
  createStringInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var stringDiv = document.createElement("div");
    stringDiv.classList.add("data-input-div", "input-div-" + id);
    stringDiv.style.display = "inline-block";
    var stringLabel = document.createElement("label");
    stringLabel.innerText = elementInfo.label;
    var stringInput = document.createElement("input");
    if (elementInfo.pattern) {
      stringInput.pattern = elementInfo.pattern;
    }
    stringInput.setAttribute("data-name", elementInfo.name);
    stringInput.setAttribute("data-type", "string");
    stringInput.setAttribute("data-key", randomId);
    if (this.autoSave) {
      stringInput.onkeyup = () => {
        this.sendJsonToCaller();
      }
      stringInput.onchange = () => {
        this.sendJsonToCaller();
      }
    }
    if (elementInfo.defaultValue) {
      stringInput.value = elementInfo.defaultValue
    }
    if (elementInfo.readonly) {
      stringInput.readOnly = true;
    }
    stringInput.type = "text";
    stringInput.classList.add("form-control", "data-input", "data-string-input", "string-input-" + id);
    stringLabel.appendChild(stringInput);
    stringDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(stringDiv);

  }
  createTextareaInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var stringDiv = document.createElement("div");
    stringDiv.classList.add("data-input-div", "input-div-" + id);
    stringDiv.style.display = "block";
    var stringLabel = document.createElement("label");
    stringLabel.innerText = elementInfo.label;
    var stringInput = document.createElement("textarea");
    stringInput.setAttribute("data-name", elementInfo.name);
    stringInput.setAttribute("data-type", "textarea");
    stringInput.setAttribute("data-key", randomId);

    if (this.autoSave) {
      stringInput.onkeyup = () => {
        this.sendJsonToCaller();
      }
      stringInput.onchange = () => {
        this.sendJsonToCaller();
      }
    }
    if (elementInfo.defaultValue) {
      stringInput.value = elementInfo.defaultValue
    }
    if (elementInfo.readonly) {
      stringInput.readOnly = true;
    }
    stringLabel.style.width = "100%";
    stringDiv.style.verticalAlign = "top"
    stringInput.classList.add("form-control", "data-input", "data-string-input", "string-input-" + id);
    stringLabel.appendChild(stringInput);
    stringDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(stringDiv);

  }
  createNumberInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var stringDiv = document.createElement("div");
    stringDiv.classList.add("data-input-div", "input-div-" + id);
    stringDiv.style.display = "inline-block";
    var stringLabel = document.createElement("label");
    stringLabel.innerText = elementInfo.label;
    var stringInput = document.createElement("input");
    stringInput.setAttribute("data-name", elementInfo.name);
    stringInput.setAttribute("data-type", "number");
    stringInput.setAttribute("data-key", randomId);
    if (this.autoSave) {
      stringInput.onkeyup = () => {
        this.sendJsonToCaller();
      }
      stringInput.onchange = () => {
        this.sendJsonToCaller();
      }
    }
    if (elementInfo.defaultValue) {
      stringInput.value = elementInfo.defaultValue;
    }
    if (elementInfo.readonly) {
      stringInput.readOnly = true;
    }
    if (elementInfo.max) {
      stringInput.max = elementInfo.max.toString();
    }
    if (elementInfo.min) {
      stringInput.min = elementInfo.min.toString();
    }

    stringInput.type = "number";
    stringInput.classList.add("form-control", "data-input", "data-string-input", "string-input-" + id);
    stringLabel.appendChild(stringInput);
    stringDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(stringDiv);
  }
  createBoolInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var stringDiv = document.createElement("div");
    stringDiv.classList.add("data-input-div", "input-div-" + id);
    stringDiv.style.display = "inline-block";
    var stringLabel = document.createElement("label");
    stringLabel.innerText = elementInfo.label;
    var selectInput = document.createElement("select");
    selectInput.setAttribute("data-name", elementInfo.name);
    selectInput.classList.add("form-select", "data-input", "data-select-input", "string-input-" + id);
    selectInput.setAttribute("data-key", randomId);
    selectInput.setAttribute("data-type", "boolean");
    if (this.autoSave) {
      selectInput.onchange = () => {
        this.sendJsonToCaller();
      }
    }
    
    var trueOption = document.createElement("option");
    trueOption.value = "true";
    trueOption.innerText = "True";
    var falseOption = document.createElement("option");
    falseOption.value = "false";
    falseOption.innerText = "False";
    if(elementInfo.defaultValue){
      if(elementInfo.defaultValue == "true"){
        trueOption.selected = true;
        selectInput.value = "true";
      }else if(elementInfo.defaultValue == "false"){
        falseOption.selected = true;
        selectInput.value = "false";
      }
    }
    selectInput.appendChild(falseOption);
    selectInput.appendChild(trueOption);

    stringLabel.appendChild(selectInput);
    stringDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(stringDiv);
  }
  createStaticInput(randomId: string, elementInfo: DynamicFormToJsonElementModel) {
    var id = elementInfo.name + "-" + randomId;
    var dataMainDiv = document.getElementsByClassName("data-main-" + randomId)[0];
    var stringDiv = document.createElement("div");
    stringDiv.classList.add("data-input-div", "input-div-" + id);
    stringDiv.style.display = "inline-block";
    var stringLabel = document.createElement("label");
    //stringLabel.innerText = elementInfo.label;
    var hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    if (elementInfo.defaultValue) {
      hiddenInput.value = elementInfo.defaultValue;
    }
    hiddenInput.setAttribute("data-name", elementInfo.name);
    hiddenInput.classList.add("form-control", "data-input", "data-select-input", "hidden-input-" + id);
    hiddenInput.setAttribute("data-key", randomId);
    var trueOption = document.createElement("option");
    trueOption.value = "true";
    trueOption.innerText = "True";
    var falseOption = document.createElement("option");
    falseOption.value = "false";
    falseOption.innerText = "False";
    hiddenInput.appendChild(falseOption);
    hiddenInput.appendChild(trueOption);

    stringLabel.appendChild(hiddenInput);
    stringDiv.appendChild(stringLabel);
    dataMainDiv.appendChild(stringDiv);
  }
  sendJsonToCaller() {
    console.log(this.elementIds)
    this.dataJson = [];
    this.elementIds.forEach(id => {
      let inputElements = document.querySelectorAll('[data-key="' + id + '"]');
      var currentData: any = {};
      inputElements.forEach(inputEl => {
        var inpElement = inputEl as HTMLInputElement;
        switch (inpElement.getAttribute("data-type")) {
          case "string":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value;
            break;
          case "number":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = parseInt(inpElement.value);
            break;
          case "boolean":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value == "true" ? true : false;
            break;
          default:
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value;
            break;
        }

      })
      this.dataJson.push(currentData);
      

    });
    this.dataJsonStr = "";
    if(this.createObject == true){
      var obj:any = {};
      this.dataJson.forEach((data)=>{
        var val:any[] = Object.values(data);
        obj[val[0]] = val[1];
      })
      this.dataJsonStr = JSON.stringify(obj);
    }else{
      this.dataJsonStr = JSON.stringify(this.dataJson);
    }

    console.log(this.dataJsonStr);
    this.dataChanges.emit(this.dataJsonStr);
    this.sendValidity();
  }
  sendValidity() {
    var valid = true;
    this.dataJson = [];
    this.elementIds.forEach(id => {
      let inputElements = document.querySelectorAll('[data-key="' + id + '"]');
      var currentData: any = {};
      inputElements.forEach(inputEl => {
        var inpElement = inputEl as HTMLInputElement;
        switch (inpElement.getAttribute("data-type")) {
          case "string":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value;
            if (inpElement.checkValidity() == false) {
              valid = false;
            }
            break;
          case "number":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = parseInt(inpElement.value);
            break;
          case "boolean":
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value == "true" ? true : false;
            break;
          default:
            currentData[inpElement.getAttribute("data-name") ?? "unknown"] = inpElement.value;
            break;
        }

      })
    })
    this.isValid.emit(valid);
  }

}
