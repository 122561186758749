import { GeneralModule } from 'projects/usorta-library/src/lib/modules/general/general.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { ServiceInterceptor } from 'projects/usorta-library/src/interceptors/service.interceptor';
import { FooterTestService } from 'projects/usorta-library/src/lib/services/footer/footer-test.service';
import { FooterService } from 'projects/usorta-library/src/lib/services/footer/footer.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { TestLoginService } from 'projects/usorta-library/src/lib/services/login/test-login.service';
import { TenantTestService } from 'projects/usorta-library/src/lib/services/tenant/tenant-test.service';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
import { ManageComponent } from './components/manage/manage.component';
import { NavbarService } from 'projects/usorta-library/src/lib/services/navbar/navbar.service';
import { NavbarMainService } from 'projects/usorta-library/src/lib/services/navbar/navbar-main.service';
import { LoginComponent } from './components/login/login.component';
import { FormsComponent } from './components/forms/forms.component';
import { ChartsComponent } from './components/charts/charts.component';
import { ManagementComponent } from './components/management/management.component';
import { CustomLayoutPageComponent } from './components/custom-layout-page/custom-layout-page.component';
import { NgChartsModule } from 'ng2-charts';
import { LayoutEditorComponent } from './components/layout-editor/layout-editor.component';
import { CertificateEditorComponent } from './components/certificate-editor/certificate-editor.component';
import { SigneeEditorComponent } from './components/signee-editor/signee-editor.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UpdateUserComponent } from './components/user-management/updateUser/update-user/update-user.component';
import { CreateUserComponent } from './components/user-management/create-user/create-user.component';
import { ChangeUserPasswordComponent } from './components/user-management/change-user-password/change-user-password.component';
import { SearchUserPipe } from './pipes/search-user.pipe';
import { DeleteUserComponent } from './components/user-management/delete-user/delete-user.component';
import { BlockUserComponent } from './components/user-management/block-user/block-user.component';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { AddFeatureTypeComponent } from './components/add-feature-type/add-feature-type.component';
import { AddSessionTemplateComponent } from './components/add-session-template/add-session-template.component';
import { AddSessionTemplateFeatureComponent } from './components/add-session-template-feature/add-session-template-feature.component';
import { AddSessionTemplateItemComponent } from './components/add-session-template-item/add-session-template-item.component';
import { FeatureTypeTableComponent } from './components/feature-type-table/feature-type-table.component';
import { DeleteFeatureTypeComponent } from './components/feature-type-table/delete-feature-type/delete-feature-type.component';
import { EditFeatureTypeComponent } from './components/feature-type-table/edit-feature-type/edit-feature-type.component';
import { UpdateFeatureTypeComponent } from './components/update-feature-type/update-feature-type.component';
import { AddSessionComponent } from './components/add-session/add-session.component';
import { SessionsComponent } from './components/sessions/sessions.component';
import { SessionTemplatesComponent } from './components/session-templates/session-templates.component';
import { DeleteSessionTemplateComponent } from './components/session-templates/delete-session-template/delete-session-template.component';
import { UpdateSessionTemplateComponent } from './components/session-templates/update-session-template/update-session-template.component';
import { UpdateSessionTemplateFeatureComponent } from './components/session-templates/update-session-template-feature/update-session-template-feature.component';
import { DeleteSessionTemplateFeatureComponent } from './components/session-templates/delete-session-template-feature/delete-session-template-feature.component';
import { DeleteSessionTemplateItemComponent } from './components/session-templates/delete-session-template-item/delete-session-template-item.component';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';
import { AdminManagementComponent } from './components/admin-management/admin-management.component';
import { BlockAdminComponent } from './components/admin-management/block-admin/block-admin.component';
import { ChangeAdminPasswordComponent } from './components/admin-management/change-admin-password/change-admin-password.component';
import { CreateAdminComponent } from './components/admin-management/create-admin/create-admin.component';
import { UpdateAdminComponent } from './components/admin-management/update-admin/update-admin.component';
import { DeleteAdminComponent } from './components/admin-management/delete-admin/delete-admin.component';
import { ServiceAccountsComponent } from './components/service-accounts/service-accounts.component';
import { UpdateServiceAccountComponent } from './components/service-accounts/update-service-account/update-service-account.component';
import { DeleteServiceAccountComponent } from './components/service-accounts/delete-service-account/delete-service-account.component';
import { ChangeServiceAccountPasswordComponent } from './components/service-accounts/change-service-account-password/change-service-account-password.component';
import { CreateServiceAccountComponent } from './components/service-accounts/create-service-account/create-service-account.component';
import { UserDetailsComponent } from './components/user-management/user-details/user-details.component';
import { ServiceAccountDetailsComponent } from './components/service-accounts/service-account-details/service-account-details.component';
import { AdminDetailsComponent } from './components/admin-management/admin-details/admin-details.component';
import { RefreshTokenService } from 'projects/usorta-library/src/lib/services/auth/refresh-token.service';
import { AdminRefreshTokenServiceService } from './services/admin-refresh-token-service.service';
import { TestTypedStrComponent } from './components/test-typed-str/test-typed-str.component';
import { SwitchTranslateModeComponent } from './components/switch-translate-mode/switch-translate-mode.component';
import { CreateMultipleUserComponent } from './components/user-management/create-multiple-user/create-multiple-user.component';
import { SyspanelTranslatePipe } from './pipes/syspanel-translate.pipe';

@NgModule({
  declarations: [
    SyspanelTranslatePipe,
    AppComponent,
    ManageComponent,
    LoginComponent,
    FormsComponent,
    ChartsComponent,
    ManagementComponent,
    CustomLayoutPageComponent,
    LayoutEditorComponent,
    CertificateEditorComponent,
    SigneeEditorComponent,
    UserManagementComponent,
    UpdateUserComponent,
    CreateUserComponent,
    ChangeUserPasswordComponent,
    SearchUserPipe,
    DeleteUserComponent,
    BlockUserComponent,
    AddFeatureTypeComponent,
    AddSessionTemplateComponent,
    AddSessionTemplateFeatureComponent,
    AddSessionTemplateItemComponent,
    FeatureTypeTableComponent,
    DeleteFeatureTypeComponent,
    EditFeatureTypeComponent,
    UpdateFeatureTypeComponent,
    AddSessionComponent,
    SessionsComponent,
    SessionTemplatesComponent,
    DeleteSessionTemplateComponent,
    UpdateSessionTemplateComponent,
    UpdateSessionTemplateFeatureComponent,
    DeleteSessionTemplateFeatureComponent,
    DeleteSessionTemplateItemComponent,
    AdminManagementComponent,
    BlockAdminComponent,
    ChangeAdminPasswordComponent,
    CreateAdminComponent,
    UpdateAdminComponent,
    DeleteAdminComponent,
    ServiceAccountsComponent,
    UpdateServiceAccountComponent,
    DeleteServiceAccountComponent,
    ChangeServiceAccountPasswordComponent,
    CreateServiceAccountComponent,
    UserDetailsComponent,
    ServiceAccountDetailsComponent,
    AdminDetailsComponent,
    TestTypedStrComponent,
    SwitchTranslateModeComponent,
    CreateMultipleUserComponent,
    SyspanelTranslatePipe
  ],
  imports: [
    BrowserModule,
    GeneralModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })
  ],
  providers: [
    SyspanelTranslatePipe,
    TranslateStore,
    {provide:RefreshTokenService,useClass:AdminRefreshTokenServiceService},
    {provide:LoginService,useClass:TestLoginService},
    {provide:HTTP_INTERCEPTORS,useClass:ServiceInterceptor,multi:true},
    {provide:FooterService,useClass:FooterTestService},
    {provide:TenantService,useClass:TenantTestService},
    {provide:NavbarService,useClass:NavbarMainService},
    GlobalModalService,
    GlobalOkCancelEventService,
    SyspanelTranslatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
