import { Component, OnInit } from '@angular/core';
declare var window:any;
@Component({
  selector: 'app-test-typed-str',
  templateUrl: './test-typed-str.component.html',
  styleUrls: ['./test-typed-str.component.css']
})
export class TestTypedStrComponent implements OnInit {
  values:{label:string,value:any}[] = [{label:"Turkey",value:"tr"},{label:"Tunisia",value:"tunisia"},{label:"Taiwan",value:"tw"}];
  constructor() { }

  ngOnInit(): void {

  }
  show(){
    console.log(window["autocomplete_country"])
  }


}
