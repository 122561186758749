import { Component, OnInit } from '@angular/core';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostProviderTypeModel } from '../../../models/HostProviderModels/hostProviderTypeModel';

@Component({
  selector: 'lib-manage-host-provider',
  templateUrl: './manage-host-provider.component.html',
  styleUrls: ['./manage-host-provider.component.css']
})
export class ManageHostProviderComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private hostProviderService:HostProviderService) { }
  typeId:string;
  hostProviderInfo:HostProviderTypeModel;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.typeId = param["typeId"];
        this.hostProviderService.getHostProvider(this.typeId).subscribe({
          next:(response)=>{
            this.hostProviderInfo = response.content;
          },error:(err)=>{
            this.toastrService.error("Bir hata oluştu")
          }
        })
      }
    })
  }

}
