<div class="form-maroon-border formMain form-default-border">
    <div class="uploadLogo">

        <h4>{{'form.uploadLogoTitle' | translate}}</h4><br />
        <lib-cms-image-viewer *ngIf="configurationImageModel && configurationImageModel.logo != ''" [imageId]="configurationImageModel.logo"></lib-cms-image-viewer>
        <br/><button *ngIf="configurationImageModel && configurationImageModel.logo != ''" class="btn btn-danger" (click)="deleteLogoImage()">{{"btn.delete" | translate}}</button>
        <br/><font class="formDescription">{{'form.uploadLogoDescription'}}</font>
        <div class="mb-3">
            <label for="image" class="form-label mainLabel">{{'form.selectLogoImageText' | translate}}</label>
            <input class="form-control" (change)="changeLogoImage($event)" type="file" id="image">
        </div>
        <div class="image-cropper">
            <image-cropper [autoCrop]="true" format="png" output="base64" [imageChangedEvent]="logoImageChangedEvent"
                (imageCropped)="logoImageCropped($event)" [maintainAspectRatio]="false"></image-cropper>
        </div>
        <button class="btn btn-sm btn-primary" (click)="uploadLogo()">{{'btn.uploadLogo' | translate}}</button>
    </div>

    <div class="uploadDefaultCourseImage">
        <h4>{{'form.uploadDefaultCourseImageTitle'}}</h4><br />
        <lib-cms-image-viewer *ngIf="configurationImageModel && configurationImageModel.defaultCourseImage != ''" [imageId]="configurationImageModel.defaultCourseImage"></lib-cms-image-viewer>
        <br/><button *ngIf="configurationImageModel && configurationImageModel.defaultCourseImage != ''" class="btn btn-danger" (click)="deleteDefaultCourseImage()">{{"btn.delete" | translate}}</button>
        <br/><font class="formDescription">{{'form.uploadLogoDescription'}}</font>
        <div class="mb-3">
            <label for="image" class="form-label mainLabel">{{'form.selectDefaultCourseImageText' | translate}}</label>
            <input class="form-control" (change)="changeDefaultCourseImage($event)" type="file" id="image">
        </div>
        <div class="image-cropper">
            <image-cropper [autoCrop]="true" format="png" output="base64"
                [imageChangedEvent]="defaultCourseImageChangedEvent"
                (imageCropped)="defaultCourseImageCropped($event)" [maintainAspectRatio]="false"></image-cropper>
        </div>
        <button class="btn btn-sm btn-primary" (click)="uploadDefaultCourseImage()">{{'btn.uploadDefaultCourseImage' | translate}}</button>

    </div>

</div>