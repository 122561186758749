import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'lib-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit,AfterViewInit {
  @Input() options:any;
  @Input() data:any;
  @Input() showLegend:boolean = true;
  @Output() getBaseChart:EventEmitter<BaseChartDirective> = new EventEmitter<BaseChartDirective>();
  constructor() { }

  @ViewChild(BaseChartDirective) chart?:BaseChartDirective
  ngAfterViewInit(): void {
    console.log("view initialized successfully")
    this.getBaseChart.emit(this.chart);
  }
  ngOnInit(): void {
  }

}
