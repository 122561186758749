<h5>{{'lab.addVmToLab.addNewVirtualMachine' | translate}}</h5>
<hr>
<form>
    <div class="mb-3">
        <label for="identifier" class="form-label">{{'lab.addVmToLab.identifierName' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off"  id="identifier" placeholder="">
      </div>
    <div class="mb-3">
        <label for="name" class="form-label">{{'lab.addVmToLab.name' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off"  id="name" placeholder="">
      </div>
    <div class="mb-3">
        <label for="baseVMId" class="form-label">{{'lab.addVmToLab.virtualMachine' | translate}}</label>
        <select class="form-select" (change)="selectVM($event)" id="baseVMId">
            <option selected disabled [value]="">{{'lab.addVmToLab.selectVirtualMachine' | translate}}</option>
            <option *ngFor="let vm of vmList" [value]="vm.value">{{vm.label}}</option>
        </select>
      </div>
    <div *ngIf="nicList">
        <div *ngFor="let nic of nicList">{{nic.nicName}}
        <select class="form-select" [id]="'network_'+nic.nicName">
            <option selected disabled [value]="">{{'lab.addVmToLab.selectNetwork' | translate}}</option>
            <option *ngFor="let network of networks" [value]="network.value">{{network.label}}</option>
        </select>
        <div class="mb-3">
            <label class="form-label">{{'lab.addVmToLab.ipAddress' | translate}}</label>
            <input type="text" class="form-control" autocomplete="off"  [id]="'ipAddress_'+nic.nicName">
          </div>
        </div>
    </div>
    <div class="mb-3">
        <label for="notes" class="form-label">{{'lab.addVmToLab.notes' | translate}}</label>
        <textarea type="text" class="form-control" autocomplete="off"  id="notes" placeholder=""></textarea>
      </div>
      <button class="btn btn-primary" (click)="addVM()">{{'btn.add' | translate}}</button>
</form>