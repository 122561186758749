import { Component, OnInit } from '@angular/core';
import { EducationService } from '../../../services/education/education.service';
import { ActivatedRoute } from '@angular/router';
import { EducationThemeDetailModel } from '../../../models/Education/educationThemeDetailModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-education-theme-detail',
  templateUrl: './education-theme-detail.component.html',
  styleUrls: ['./education-theme-detail.component.css']
})
export class EducationThemeDetailComponent implements OnInit {
  themeId:number;
  themeDetail:EducationThemeDetailModel;
  constructor(private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.themeId = parseInt(param["themeId"])
        this.getEducationThemeDetail();
      }
    })
  }
  getEducationThemeDetail(){
    this.educationService.getEducationThemeDetail(this.themeId).subscribe({
      next:(response)=>{
        this.themeDetail = response.content;
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
