import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-localized-item-menu',
  templateUrl: './delete-localized-item-menu.component.html',
  styleUrls: ['./delete-localized-item-menu.component.css']
})
export class DeleteLocalizedItemMenuComponent implements OnInit {
  
  localizeditemid:number;
  itemid:number
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private navigationService:NavigationService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.itemid = parseInt(param["itemid"]);
        this.localizeditemid = parseInt(param["lid"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteMenu(){
    this.navigationService.deleteLocalizedMenuItem(this.itemid,this.localizeditemid).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success(this.translateService.instant("cms.localizedmenuitem.deletedSuccessfully"));
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
