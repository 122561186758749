import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.css']
})
export class CreateApplicationComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  formUrl:string = this.baseUrl + "api/cms/admin/appdata/applications/create"
  constructor() { }

  ngOnInit(): void {
  }

}
