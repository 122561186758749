import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class FooterService {

  constructor() { }
  public abstract getFooterMenu():Observable<JSON>;
}
