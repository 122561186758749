import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-localized-menu',
  templateUrl: './delete-localized-menu.component.html',
  styleUrls: ['./delete-localized-menu.component.css']
})
export class DeleteLocalizedMenuComponent implements OnInit {

  menuId:number;
  localizedMenuId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private navigationService:NavigationService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.menuId = parseInt(param["menuId"]);
        this.localizedMenuId = parseInt(param["lid"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteMenu(){
    this.navigationService.deleteLocalizedMenu(this.menuId,this.localizedMenuId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success(this.translateService.instant("cms.localizedmenu.deletedSuccessfully"));
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
