<div class="iconSets formMain form-maroon-border form-default-border" style="min-height: 80vh;">
    <h5>{{'iconSets.iconSetsGridTitle' | translate}}</h5>
    <hr/>
    <div class="iconSets-toolbar">
        <div (click)="addNew()" class="iconSets-toolbar-item ">
            <i class="bi bi-plus-lg success"></i><font> {{'btn.add' | translate}}</font>
        </div>
        <div (click)="updateIconSet()" *ngIf="selectedIconSet != null" class="iconSets-toolbar-item ">
            <i class="bi bi-pencil-fill warning"></i><font> {{'btn.edit' | translate}}</font>
        </div>
        <div (click)="deleteIconSet()" *ngIf="selectedIconSet != null" class="iconSets-toolbar-item ">
            <i  class="bi bi-trash-fill danger"></i><font> {{'btn.delete' | translate}}</font>
        </div>
        <div (click)="routeDetailsPage()" *ngIf="selectedIconSet != null" class="iconSets-toolbar-item ">
            <i  class="bi bi-eye-fill primary"></i><font> {{'btn.details' | translate}}</font>
        </div>
    </div>
    <hr/>
    <div class="iconSets-area" (click)="removeSelectedAttributes($event)">


    <div class="iconSets-content" >
        <div class="iconSet-item" (dblclick)="routeIconsPage(iconSet.id)"  #iconSet (click)="selectIconSet($event,iconSet.id)" *ngFor="let iconSet of iconSets">
            <div  class="iconSet-img-container">
                <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/'+iconSet.iconSetCode+'/icon/default'" />
            </div>
            <div class="iconSet-info">
                <b class="iconSet-iconName">{{iconSet.name}}</b>
                <font class="iconSet-iconCode">{{iconSet.iconSetCode}}</font>
                <span class="badge bg-info text-dark mt-1" *ngIf="iconSet.custom">{{'iconSet.customSetField' | translate}}</span>
                <div style="height: 2px; width: 2px;" *ngIf="iconSet.custom == false"></div>
            </div>
        </div>
    </div>
    <div class="iconSets-details">

    </div>
</div>
</div>
