<h5>{{'lab.updateLab' | translate}}</h5>
<hr>
<form *ngIf="labInfo">
    <div class="mb-3">
        <label for="name" class="form-label">{{'lab.labName' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off" [value]="labInfo.name"  id="name" placeholder="">
      </div>

    <div class="mb-3">
        <label for="code" class="form-label">{{'lab.code' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off" [value]="labInfo.code"  id="code" placeholder="">
      </div>

    <div class="mb-3">
        <label for="tags" class="form-label" >{{'lab.tags' | translate}}</label>
        <input type="text" class="form-control" [value]="labInfo.tags" autocomplete="off" id="tags"  placeholder="">
    </div>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="active" [checked]="labInfo.active">
        <label class="form-check-label" for="flexCheckDefault">
          {{'global.active' | translate}}
        </label>
      </div>

    <div class="mb-3">
        <label for="descriptionFormat" class="form-label mainLabel">{{'global.format' | translate}}</label>
        <select (change)="changeDescriptionFormat($event)" class="form-select" id="descriptionFormat" name="descriptionFormat">
            <option [selected]="defaultDescriptionFormatValue == 0" [value]="0">PlainText</option>
            <option [selected]="defaultDescriptionFormatValue == 1" [value]="1">HTML</option>
            <option [selected]="defaultDescriptionFormatValue == 2" [value]="2">Markdown</option>
            <option [selected]="defaultDescriptionFormatValue == 3" [value]="3">Json</option>
            <option [selected]="defaultDescriptionFormatValue == 4" [value]="4">Xml</option>
            <option [selected]="defaultDescriptionFormatValue == 5" [value]="5">Layout</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="description" class="form-label mainLabel">{{'global.description' | translate}}</label>
        <input type="hidden" class="form-control" id="description" name="description"/>
        <lib-typed-strings [defaultValue]="defaultDescriptionValue" [defaultType]="defaultDescriptionFormatValue" (getValue)="descriptionValueFnc($event)" [defaultType]="0" [inputType]="inputFormats"></lib-typed-strings>
    </div>
    <lib-dynamic-form-to-json [addWithData]="networkDyn" [showDeleteBtn]="true" (isValid)="getValidation($event)" [autoSave]="true" (dataChanges)="saveNetwork($event)" [formElements]="networkSelect"></lib-dynamic-form-to-json>
    <br/>
    <button class="btn btn-primary" (click)="updateLab()">{{'btn.update' | translate}} </button>
</form>
