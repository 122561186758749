<lib-version-details-basic *ngIf="versionDetail" [versionDetail]="versionDetail">

</lib-version-details-basic>
<br/>
<lib-custom-table [externalAPIUrl]="apiUrl+'api/lms/courses/versions/'+versionId+'/outline/list'">

</lib-custom-table>
<!--
<lib-version-details-sections *ngIf="versionDetail" [versionDetail]="versionDetail">
    
</lib-version-details-sections>-->