<div class="mb-3" [id]="'content_autocomplete_'+name">
    <div class="autocomplete">
        <input class="form-control" style="flex:1; margin-right:10px;" [id]="'acinput_'+name" type="text" [name]="name" [placeholder]="placeholder">
        <!--<button (click)="selectValue()" style="margin-left: 10px; border-radius: 12px;" class="btn btn-success btn-sm"><b>{{'btn.add' | translate}}</b></button>-->
    </div>
    <br/>
    <div class="autocomplete-list" [class.miniItems]="showMiniItems">
        <div *ngFor="let sel of selectedValues" class="mb-3" style="padding: 5px;">
            <div  class="selectedItem">
                <b>{{getLabelByValue(sel)}}</b>&nbsp;&nbsp;
                <i style="cursor: pointer;" (click)="removeValue(sel)" class="bi bi-trash-fill"></i>
            </div>

        </div>
    </div>
</div>