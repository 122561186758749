import { Component, OnInit } from '@angular/core';
import { VmInstanceTypeModel } from '../../../models/vmInstanceTypes/vmInstanceTypeModel';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-manage-vm-instance-type',
  templateUrl: './manage-vm-instance-type.component.html',
  styleUrls: ['./manage-vm-instance-type.component.css']
})
export class ManageVmInstanceTypeComponent implements OnInit {
  vmInstance:VmInstanceTypeModel;
  typeId:string;
  constructor(private translateService:TranslateService,private vmInstanceService:VmInstanceService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.typeId = param["typeId"];
        this.getVmInstanceModel();
      }
    })
  }
  getVmInstanceModel(){
    this.vmInstanceService.getVmInstanceTypeInfo(this.typeId).subscribe(
      {
        next:(response)=>{
          this.vmInstance = response.content;
        },
        error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      }
    )
  }

}
