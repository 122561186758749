import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { AddEducationRequestModel } from '../../../models/Education/addEducationRequestModel';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { MonacoService } from '../../../services/monacoService/monaco.service';
declare var monaco: any;
declare var window: any;
declare var $:any;
@Component({
  selector: 'lib-new-education',
  templateUrl: './new-education.component.html',
  styleUrls: ['./new-education.component.css']
})

export class NewEducationComponent implements OnInit {
  educationCategoryList:ListItemModel[] = [];
  themeList:ListItemModel[] = [];
  categoryTreeId:number = 1;
  catalogImageChangedEvent:any = '';
  croppedCatalogImage:any = '';
  croppedCatalogImage64:string | null | undefined = undefined;
  selectedFormat:number = 0;
  detailImageChangedEvent:any = '';
  croppedDetailImage:any = '';
  croppedDetailImage64:string | null | undefined = undefined;
  loading:boolean = false;
  constructor(private monacoEditorService:MonacoService,private globalModalService:GlobalModalService,private sanitizer:DomSanitizer,private toastrService:ToastrService,private educationService:EducationService) { }

  ngOnInit(): void {
    this.initializeEditor();
    this.getEducationCategories();
    this.getEducationThemeList();
    setTimeout(()=>{
      window.initLayoutEditor($('#description'), {});
    },150)

  }
  selectFormat(ev:any){
    this.selectedFormat = parseInt($(ev.target).val())
  }
  initializeEditor(){
    var editorElement = document.getElementById("editOutlineEditor") as HTMLDivElement;
    var monacoEditor: any = null;
    if(typeof(monaco) !== 'undefined'){
      setTimeout(function () {
        monacoEditor = monaco.editor.create(editorElement, {
          language: 'json',
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['outlineEditor'] = monacoEditor;
      }, 50)
    }
  }
  getEducationCategories(){
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next:(response)=>{
        this.educationCategoryList = response.content;
      },error:(err)=>{
        this.toastrService.error("Kategoriler alınamadı")
      }
    })
  }
  getLayoutEditor(){
    var ta = document.getElementById("description-layout-editor") as HTMLTextAreaElement;
  }
  /* Katalog Resmi Kırpma Fonksiyonları */
  changeCatalogImage(ev:Event){
    this.catalogImageChangedEvent = ev;
  }
  catalogImageCropped(event:ImageCroppedEvent){
    this.croppedCatalogImage64 = event.base64;
    this.croppedCatalogImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success("Seçilen bölge kayıt edildi");
  }
  catalogImageLoaded(image:LoadedImage){

  }
  catalogCropperReady(){

  }
  catalogImageLoadFailed(){

  }

  /* Detay Resmi Kırpma Fonksiyonları */
  changeDetailImage(ev:Event){
    this.detailImageChangedEvent = ev;
  }
  detailImageCropped(event:ImageCroppedEvent){
    this.croppedDetailImage64 = event.base64;
    this.croppedDetailImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success("Seçilen bölge kayıt edildi");
  }
  detailImageLoaded(image:LoadedImage){

  }
  detailCropperReady(){

  }
  detailImageLoadFailed(){

  }
  /* Model oluştur */
  createAddEducationModel(){
    var educationCode = (document.getElementById("code") as HTMLInputElement).value;
    var educationName = (document.getElementById("name") as HTMLInputElement).value;
    var isActive = (document.getElementById("active") as HTMLInputElement).checked;
    var descriptionDetail;
    if(this.selectedFormat == 1){
      descriptionDetail = (document.getElementById("description") as HTMLInputElement).value;
    }else{
      descriptionDetail = (document.getElementById("descriptionTextarea") as HTMLInputElement).value;
    }
    var description = (document.getElementById("teaserText") as HTMLInputElement).value;
    var categoryEl = (document.getElementById("categories") as HTMLSelectElement);
    var selectedCategories = Array.from(categoryEl.options).filter(option => option.selected).map(option => parseInt(option.value));
    var level = (document.getElementById("level") as HTMLInputElement).value;
    var length = (document.getElementById("courseLength") as HTMLInputElement).value;
    var descriptionFormat = (document.getElementById("descriptionFormat") as HTMLInputElement).value;
    var locale = (document.getElementById("locale") as HTMLSelectElement).value;
   // var outline = this.monacoEditorService.removeRNChars(window['outlineEditor'].getValue());
   var outline = (document.getElementById("outlineTextarea") as HTMLTextAreaElement).value;
   var themeId = (document.getElementById("theme") as HTMLSelectElement).value;

    var educationModel:AddEducationRequestModel = {
      code: educationCode,
      name: educationName,
      active: isActive,
      image: this.croppedCatalogImage64 ?? "",
      teaserText: description,
      teaserImage: this.croppedDetailImage64 ?? "",
      description: descriptionDetail,
      categories: selectedCategories,
      level: level,
      courseLength: length,
      outline: outline,
      locale: locale,
      descriptionFormat: parseInt(descriptionFormat),
      themeId:parseInt(themeId)
    }
    console.log(educationModel)
    this.educationModelControl(educationModel);
    return educationModel;
  }
  getEducationThemeList(){
    this.educationService.getEducationThemeList().subscribe({
      next:(response)=>{
        this.themeList = response.content;
      }
    })
  }
  educationModelControl(educationModel:AddEducationRequestModel){
    var emptyAllowed = ["outline","themeId","locale","descriptionFormat","image","teaserImage"];
    var educationModelKeys = Object.keys(educationModel);
    educationModelKeys.forEach(key=>{
      var model = educationModel as any;
      if((model[key] === "" || model[key] === undefined) && emptyAllowed.findIndex(x=> x == key) == -1){
        console.log(key);
        var label = (document.querySelector('[for="'+key+'"]') as HTMLLabelElement).innerText;

        this.toastrService.error(`${label} bilgisi boş olamaz`)
        throw "Modeli kontrol edin";
      }
    });
  }
  addEducation(){
    var educationModel = this.createAddEducationModel();
    this.loading = true;
    this.educationService.addEducation(educationModel).subscribe({
      next:(response)=>{
        this.toastrService.success("Eğitim eklendi");
        this.loading = false;
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error("Bir hata oluştu");
      }
    });
  }

  showAddCategoryModal(){
    var modalCreateModel:ModalCreateModel = {
      title: 'Yeni kategori',
      type: ModalTypes.LINK,
      data: `modal/lms/categorytrees/course/${this.categoryTreeId}/leaves/create`,
      helperModals: []
    }
    var modal = this.globalModalService.showModal(modalCreateModel);
    modal.onclose.subscribe({
      next:()=>{
        this.getEducationCategories();
      }
    })
  }
  
}
