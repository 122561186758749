import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomFormJsonFormModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/customFormJsonFormModel';
import { CustomFormJsonPageModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/customFormJsonPageModel';
import { CustomFormJsonInputAndValidateModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/inputModel';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { FormElement } from 'projects/usorta-library/src/lib/models/CustomFormModels/formElement';
import { FeatureItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureItem';
import { FeatureType } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureType';
import { CustomFormJsonService } from 'projects/usorta-library/src/lib/services/customFormJson/custom-form-json.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-add-session-template-feature',
  templateUrl: './add-session-template-feature.component.html',
  styleUrls: ['./add-session-template-feature.component.css']
})
export class AddSessionTemplateFeatureComponent implements OnInit,AfterViewInit {

  constructor(private sessionTemplateService:SessionTemplateService,private toastr:ToastrService,private formJsonCreator:CustomFormJsonService,private changeDetector:ChangeDetectorRef) { }
  featureType:FeatureType;
  featureList:{value:string,label:string}[] = [];
  show:boolean = false;
  featureConfiguration:CustomForm;
  loading:boolean = false;
  @Output() GetFeatureItem = new EventEmitter<FeatureItem>();
  ngAfterViewInit(){

  }
  ngOnInit(): void {
    this.getFeatureTypeList();
  }
  getFeatureInfo(id:string){
    this.loading = true;
    this.sessionTemplateService.getFeatureType(id).subscribe({
      
      next:(data)=>{
        this.featureType = data.content;
        console.log(this.featureType);
        this.createCustomFormJson();
        this.show = false;
        this.changeDetector.detectChanges();
        this.show = true;
        this.loading = false;
      },
      error:(err)=>{
        this.loading = false;
        this.toastr.error("Bir hata oluştu");
      }
    })
  }
  getFeatureTypeList(){
    this.sessionTemplateService.getFeatureTypesForSelectList().subscribe({
      next:(response)=>{
        console.log(response);
        this.featureList = response.content as {value:string,label:string}[]
        setTimeout(()=>{
          this.getFirstData()
        },100);
      }
    })
  }
  createCustomFormJson(){
    var customFormJsonFormModel:CustomFormJsonFormModel = {action:"#",enctype:"application/json",method:"post",buttons:[{button:"submitButton",localizationKey:"Ekle"}]}
    var customFormPageModel:CustomFormJsonPageModel = {title:"Özellik Parametreleri",layout:"1column",showReset:false};
    var inputAndValidate:CustomFormJsonInputAndValidateModel = {input:{}};
    
    var form = this.formJsonCreator.createCustomFormJson(customFormJsonFormModel,customFormPageModel,[inputAndValidate]);
    form.pages[0].elements = JSON.parse(this.featureType.configurationSchema);
    this.featureConfiguration = form;
    console.log(form);
    
  }
  getData(ev:Event){
    var input = ev.target as HTMLInputElement;
    this.getFeatureInfo(input.value);
  }
  getFirstData(){
    var sel = document.getElementById("featureSelect") as HTMLSelectElement;
    this.getFeatureInfo(sel.value);
  }
  getJsonData(jsonObj:any){
    var sel = document.getElementById("featureSelect") as HTMLSelectElement;
    console.log(jsonObj);
    var featureItem:FeatureItem = {typeId:sel.value,configuration:JSON.stringify(jsonObj)}
    console.log(featureItem);
    this.GetFeatureItem.emit(featureItem);
  }

}
