import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EducationModel } from 'projects/usorta-library/src/lib/models/Education/educationModel';
import { VersionDetailsModel } from 'projects/usorta-library/src/lib/models/Education/versionDetailsModel';
import { CmsImageSizes } from 'projects/usorta-library/src/lib/models/Enums/CmsImageSizes';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { CmsImageService } from 'projects/usorta-library/src/lib/services/cmsImages/cms-image.service';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
declare var $:any;
@Component({
  selector: 'lib-version-details-basic',
  templateUrl: './version-details-basic.component.html',
  styleUrls: ['./version-details-basic.component.css']
})
export class VersionDetailsBasicComponent implements OnInit {
  @Input() versionDetail:VersionDetailsModel
  constructor(private cmsImageService:CmsImageService,private globalModalService:GlobalModalService,private translateService:TranslateService, private educationService:EducationService) { }

  ngOnInit(): void {

  }
  getImage(imageId:string){
    return this.cmsImageService.getUrl(imageId,CmsImageSizes.Size_250x150);
  }
  setDescriptionDetail(){
    var ifr = document.createElement("iframe");
    ifr.style.width = "100%";
    ifr.style.height = "100%";
    ifr.src = "about:blank"
    var link = document.createElement("link");
    this.globalModalService.showModal({
      title: "Detay Açıklama",
      type: ModalTypes.HTMLELEMENT,
      data: ifr,
      helperModals: []
    },true);
    link.rel="stylesheet";
    link.href = "/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css";
    ifr.onload = ()=>{
      $(ifr).contents().find("head").get(0).append(link);
      $(ifr).contents().find("body").html(this.versionDetail.description);
    }


  }
  /*dateParse(){
    let kvpMap:Map<string,string> = new Map<string,string>();
    kvpMap.set('M','datetime.month');
    kvpMap.set('w','datetime.week');
    kvpMap.set('d','datetime.day');
    kvpMap.set('h','datetime.hour');
    kvpMap.set('m','datetime.minute');
    let dateString = "";
    var datetime = this.versionDetail.length;
    var reg = datetime.match(/\d+[dhmwM]/g) as any;
    console.log("reg");
    console.log(reg);
    let mSelect = 0;
    let paramlist = ['M','w','d','h','m'];
    for(let i=0;i<paramlist.length;i++){
      reg.forEach((data:string)=>{
        let ends = data[data.length-1];
        if(ends === paramlist[mSelect]){
          console.log(paramlist[mSelect]);
          let langParam = kvpMap.get(paramlist[mSelect]) ?? paramlist[mSelect];
          console.log(data.substring(0,data.length-1)+this.translateService.instant(langParam));
          dateString += data.substring(0,data.length-1)+" "+this.translateService.instant(langParam)+" ";
        }
      })
      mSelect++;
      console.log(mSelect);
    }
    return dateString;
  }*/

}
