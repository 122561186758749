<!--Bu özellik iptal ! -->
<select (change)="onFeatureSelectorChange()" class="form-select" id="featureSelector">
    <option *ngFor="let item of templateSelectList" [value]="item.value">{{item.label}}</option>
</select>
<p class="featureInfo" *ngIf="features && features.length > 0">{{features.length}} özellik içe aktarılacak</p>
<div *ngIf="features">
    <div class="featureConfigurationItem" *ngFor="let feature of features">
        <div style="display: inline-block; flex: 1;">{{getFeatureName(feature.featureType)}}</div><div style="display: inline-block; width: 120px; text-align: right;"><i (click)="showConfiguration(feature)" class="bi bi-eye-fill"></i></div>
    </div>
</div>
<button *ngIf="featureTypeList && templateSelectList" (click)="importFeatures()"  class="btn btn-danger btn-sm" style="margin-top: 8px;">İçe Aktar</button>
