import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { InputFormats } from '../../models/Enums/inputFormatsEnum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
declare var $:any;
declare var window:any;
declare var monaco:any;
@Component({
  selector: 'lib-typed-strings',
  templateUrl: './typed-strings.component.html',
  styleUrls: ['./typed-strings.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TypedStringsComponent implements OnInit,OnChanges {
  uuid:string;
  monacoEditor:any;
  sanitizedValue:SafeHtml;
  currentType:number;
  @Input() inputType:InputFormats[];
  @Input() defaultType:InputFormats = InputFormats.PlainText;
  @Input() defaultValue:string = '';

  @Output() getValue:EventEmitter<any> = new EventEmitter<any>();
  constructor(private sanitizer:DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changed");
    this.createEditor(false);
  }
  ngOnInit(): void {
    
    this.uuid = (Math.random() + 1).toString(36).substring(7);
    if(this.inputType == null || this.inputType == undefined){
      this.inputType = [InputFormats.PlainText];
    }
    if(this.inputType[0] == 5){
      this.sanitizedValue = this.sanitizer.bypassSecurityTrustHtml(this.defaultValue);
    }else{
      this.sanitizedValue = "";
    }

    this.createEditor(true);
    setTimeout(()=>{
      this.getEditorValue();
    },250);

    this.getValue.emit(this.getEditorValue);
  }

  createEditor(useDefault:boolean){
    console.log("create editor calisti");
    if(this.inputType[0] == InputFormats.Html || this.inputType[0] == InputFormats.Json || this.inputType[0] == InputFormats.Xml){

      console.log("monaco created")
      this.createMonacoEditor(useDefault);
    }else if(this.inputType[0] == InputFormats.PlainText || this.inputType[0] == InputFormats.Markdown){
      this.createTextarea(useDefault);
      console.log("textarea created");
    }else if(this.inputType[0] == InputFormats.Layout){
      console.log("layout editor created");
      this.createLayoutEditor(useDefault);
    }else{
      console.log("yanlış işlem")
    }
  }
  createMonacoEditor(useDefault:boolean = false){
    setTimeout(()=>{
      var type = "json";
      if(this.inputType[0] == 1){
        type = "html";
      }
      if(this.inputType[0] == 3){
        type = "json";
      }
      if(this.inputType[0] == 4){
        type = "xml"
      }
      if(window['monacoEditor_'+this.uuid] != null){
        window['monacoEditor_'+this.uuid].getModel().dispose();
      }
      var codeEditor = document.getElementsByClassName("editorMonaco_"+this.uuid)[0] as HTMLDivElement
      this.monacoEditor = monaco.editor.create(codeEditor, {
        language: type,
        value: useDefault == true ? this.defaultValue : "",
        theme: 'vs',
        autoIndent: true,
        tabSize: 2,
        automaticLayout: true
      });
      window['monacoEditor_'+this.uuid] = this.monacoEditor;
    },250)
  }

  createLayoutEditor(useDefault:boolean){

    console.log("create layout editor calistii");
    setTimeout(()=>{
      var txtList = document.getElementsByClassName('editorLayout_'+this.uuid)
      var txt = txtList[0] as HTMLTextAreaElement;
      if(txt != null && useDefault){
        console.log(this.defaultType);
        console.log(this.defaultValue);
        //txt.innerHTML = this.defaultValue;
        //txt.value = this.defaultValue;
        console.log("txt found");
      }
      window.initLayoutEditor($('.editorLayout_'+this.uuid), {});
    },500)
  }

  createTextarea(useDefault:boolean){
    setTimeout(()=>{
      if(useDefault){
        var textarea = document.getElementsByClassName("editorTextarea_"+this.uuid);
        if(textarea != null && textarea.length > 0){
          (textarea[0] as HTMLTextAreaElement).value = this.defaultValue; 
        }
      }else{
        var textarea = document.getElementsByClassName("editorTextarea_"+this.uuid);
        if(textarea != null && textarea.length > 0){
          (textarea[0] as HTMLTextAreaElement).value = ""; 
        }
      }
    },250);
  }


  getEditorValue = () =>{
        var editorVal = "";
        if(this.inputType[0] == 0 || this.inputType[0] == 2){
          var doc = document.getElementsByClassName("editorTextarea_"+this.uuid)[0] as HTMLTextAreaElement;
          editorVal = doc.value;
        }else if(this.inputType[0] == 1 || this.inputType[0] == 3 || this.inputType[0] == 4){
          var val = window['monacoEditor_'+this.uuid].getValue();
          editorVal =  val;
        }else if(this.inputType[0] == 5){
          var layoutVal = document.getElementById("editorLayout_"+this.uuid) as HTMLTextAreaElement;
          editorVal = layoutVal.value;
        }
        //this.getValue.emit(editorVal)
        return editorVal;


  }
}
