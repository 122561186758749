import { Component, Input, OnInit } from '@angular/core';
import { SessionTemplateService } from '../../../services/session/session-template.service';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { SessionTemplateModel } from '../../../models/SessionTemplateModels/sessionTemplateModel';
import { SessionTemplateFeatureModel } from '../../../models/SessionTemplateModels/sessionTemplateFeatureModel';
import { FeatureType } from '../../../models/SessionTemplateModels/featureType';
import { AddSessionFeatureRequest } from '../../../models/SessionApiModels/addSessionFeatureRequest';
import { SessionApiService } from '../../../services/session/session-api.service';

@Component({
  selector: 'lib-import-features',
  templateUrl: './import-features.component.html',
  styleUrls: ['./import-features.component.css']
})
export class ImportFeaturesComponent implements OnInit {
  @Input() sessionId: string;
  templateSelectList: ListItemModel[];
  selectedTemplate: SessionTemplateModel;
  features: SessionTemplateFeatureModel[];
  featureTypeList: ListItemModel[];
  constructor(private sessionTemplateService: SessionTemplateService, private toastrService: ToastrService,private sessionApiService:SessionApiService) { }

  ngOnInit(): void {
    this.getSessionTemplateList();
    this.getFeatureTypesList();
  }
  getSessionTemplateList(){
    this.sessionTemplateService.getSessionTemplateList().subscribe({
      next: (response) => {
        this.templateSelectList = response.content;
        console.log(this.templateSelectList);
      }, error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getFeatureTypesList() {
    this.sessionTemplateService.getFeatureTypesForSelectList().subscribe(
      {
        next: (response) => {
          this.featureTypeList = response.content;
          console.log(this.featureTypeList);
        },
        error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
  }
  onFeatureSelectorChange(){
    this.getSessionTemplate();
  }
  getSessionTemplate() {
    var featureSelectInput = document.getElementById("featureSelector") as HTMLInputElement;
    var id: number = parseInt(featureSelectInput.value);
    this.sessionTemplateService.getSessionTemplate(id).subscribe({
      next: (response) => {
        this.selectedTemplate = response.content;
        this.features = this.selectedTemplate.features;
        console.log(this.features);
        if (this.features.length > 0) {

        } else {
          this.toastrService.error("Bu şablon içinde içe aktarılacak özellik bulunamadı.")
        }
      },
      error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  importFeatures() {
    if (this.features.length > 0) {
      this.features.forEach((feature)=>{
        var featureName = this.featureTypeList.find(x=>x.value == feature.featureType)?.label;
        var addSessionFeatureRequest:AddSessionFeatureRequest = {name:featureName ?? "undefined",configuration:feature.baseConfiguration,sessionId:this.sessionId,typeId:feature.featureType};
        this.sessionApiService.addFeatureToSession(addSessionFeatureRequest).subscribe({
          next:(response)=>{
            this.toastrService.success(`${addSessionFeatureRequest.name} özelliği şablondan içe aktarıldı`);
          },
          error:(err)=>{
            this.toastrService.error(`${addSessionFeatureRequest.name} özelliği eklenirken bir hata oluştu`);
          }
        })
      })
    }
  }
  getFeatureName(featureVal:string){
    return this.featureTypeList.find(x=>x.value == featureVal)?.label ?? "";
  }
  showConfiguration(feature:SessionTemplateFeatureModel){
    
  }

}
