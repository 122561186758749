import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageNoteListItemResponse, BaseVmImageNoteListResponse } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageNoteListResponse';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';

@Component({
  selector: 'lib-manage-base-vm-image-notes',
  templateUrl: './manage-base-vm-image-notes.component.html',
  styleUrls: ['./manage-base-vm-image-notes.component.css']
})
export class ManageBaseVmImageNotesComponent implements OnInit {
  @Input() baseVmImageId:string;
  page:number = 1;
  notes:BaseVmImageNoteListResponse;
  loading:boolean;
  constructor(private baseImageService:BaseVmImageService,private globalModalService:GlobalModalService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getNotes();
  }
  getNotes(){
    this.loading = true;
    this.baseImageService.getNotes(this.baseVmImageId,this.page).subscribe({
      next:(response)=>{
        this.notes = response.content;
        this.loading = false;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        this.loading=false;
      }
    })
  }
  nextPage(){
    if(this.notes.page < this.notes.pageCount){
      this.page = this.notes.page + 1;
      this.getNotes();
    }
  }
  nextPageAvailabe(){
    if(this.notes.page < this.notes.pageCount){
      return true;
    }
    return false;
  }
  previousPageAvailabe(){
    if(this.notes.page > 1){
      return true;
    }
    return false;
  }
  previousPage(){
    if(this.notes.page > 1){
      this.page = this.notes.page - 1;
      this.getNotes();
    }
  }
  deleteNote(id:string){
    var modal = this.globalModalService.showModal({
      data:"/modal/range/vm/basevmimages/notes/"+id+"/delete",
      helperModals:[],
      title:"Delete Note",
      type:ModalTypes.LINK
    });
    /*modal.subscribe({
      next:(response:any)=>{
        this.getNotes();
      }
    })*/
  }
  createNote(){
    var modal = this.globalModalService.showModal({
      data:"/modal/range/vm/basevmimages/"+this.baseVmImageId+"/notes/create",
      helperModals:[],
      title:"Create Note",
      type:ModalTypes.LINK
    });
    modal['onclose'].subscribe({
      next:(response:any)=>{
        this.getNotes();
      }
    })
  }
  updateNote(id:string){
    var modal = this.globalModalService.showModal({
      data:"/modal/range/vm/basevmimages/"+this.baseVmImageId+"/notes/"+id+"/update",
      helperModals:[],
      title:"Update Note",
      type:ModalTypes.LINK
    });
    modal['onclose'].subscribe({
      next:(response:any)=>{
        this.getNotes();
      }
    })
  }

}
