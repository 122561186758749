import { Injectable } from '@angular/core';
declare var monaco:any;
@Injectable({
  providedIn: 'root'
})
export class MonacoService {

  constructor() { }
  newlineOnlyN(str:string){
    var replaced = str.replace(/(\r\n|\n|\r)/gm, '\n');
    return replaced;
  }
  removeRNChars(editorJson:string){
    var replaced = editorJson.replace(/(\r\n|\n|\r)/gm, "");
    return replaced;
  }
  jsonString(json:string){

    var replaced = json.replace(/(\r\n|\n|\r)/gm, "");
    if(replaced != "" && replaced != undefined){
      replaced = JSON.stringify(JSON.parse(replaced));
    }
    return replaced;
  }
  disposeAllModels(){
    monaco.editor.getModels().forEach((model:any)=>{
      model.dispose();
    })
  }
}
