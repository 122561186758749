import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RoleDetailsModel } from 'projects/usorta-library/src/lib/models/AccessControl/roleDetailsModel';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';

@Component({
  selector: 'lib-add-role-to-user',
  templateUrl: './add-role-to-user.component.html',
  styleUrls: ['./add-role-to-user.component.css']
})
export class AddRoleToUserComponent implements OnInit,OnChanges {

  userId:string;
  roleList:ListItemModel[] = [];
  loaded:boolean = false;
  @Input() assignedRoles:RoleDetailsModel[];
  @Output() process:EventEmitter<string> = new EventEmitter<string>();
  constructor(private activatedRoute:ActivatedRoute,private accessControlService:AccessControlService,private toastrService:ToastrService,private translateService:TranslateService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.getRoles();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param["userId"];
        this.getRoles();
      }
    })
  }
  isAssigned(id:string){
    if(this.assignedRoles.findIndex(x=>x.id == id) !== -1){
      return true;
    }
    return false;
  }
  getRoles(){
    this.accessControlService.getUserRoles().subscribe({
      next:(response)=>{
        this.loaded = true;
        response.content.data = response.content.data.filter(x=>this.isAssigned(x.id) == false)
        this.roleList = response.content.data.map((val,index,arr)=>{
          return {label:arr[index].name,value:arr[index].id};
        })
      }
    })
  }
  addRole(roleId:string){
    var roleSelectItem = roleId;
    this.accessControlService.addRoleToUser({roleId:roleSelectItem,userId:this.userId}).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("roleManagement.roleAssingnedSuccessfully"));
        this.process.emit("");
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
