import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';

@Component({
  selector: 'lib-delete-admin-role',
  templateUrl: './delete-admin-role.component.html',
  styleUrls: ['./delete-admin-role.component.css']
})
export class DeleteAdminRoleComponent implements OnInit {

  roleId:string;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private accessControlService:AccessControlService,private translateService:TranslateService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.roleId = param["id"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteRole(){
    this.accessControlService.deleteAdminRole(this.roleId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success(this.translateService.instant("accessControl.roleDeletedSuccessfully"));
        }else{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
