import { Component, OnInit } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ActivatedRoute } from '@angular/router';
import { CustomForm } from '../../../models/CustomFormModels/customForm';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { UpdateSessionRequest } from '../../../models/SessionApiModels/updateSessionRequest';
declare var window: any;
declare var $: any;
declare var monaco: any;

@Component({
  selector: 'lib-session-management-update-session',
  templateUrl: './session-management-update-session.component.html',
  styleUrls: ['./session-management-update-session.component.css']
})

export class SessionManagementUpdateSessionComponent implements OnInit {
  sessionId: string;
  updateForm: CustomForm;
  updateFormGroup: FormGroup;
  requestForm:boolean[] = [true];
  formBottomInput:HTMLElement | undefined = undefined;
  constructor(private toastrService: ToastrService, private activatedRoute: ActivatedRoute, private sessionApiService: SessionApiService) { }

  ngOnInit(): void {
    this.formBottomInput = document.getElementById("updateSessionDiv") as HTMLElement;
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.sessionId = param["sessionId"];
        this.getUpdateForm();
      }
    })
  }
  updateSession($ev:any){
    var scheduledStart = $ev.scheduledStart;
    var scheduledEnd = $ev.scheduledEnd ?? undefined;
    $ev.scheduledStart =  new Date(scheduledStart).toJSON();
    if(scheduledEnd && scheduledEnd !== ""){
      $ev.scheduledEnd = new Date(scheduledEnd).toJSON();
    }else{
      $ev.scheduledEnd = undefined;
    }

    console.log($ev);
    this.sessionApiService.updateSession($ev).subscribe({
      next:(response)=>{
        this.toastrService.success("Oturum başarıyla güncellendi");
      },error:(err)=>{
        this.toastrService.error("Oturum güncellenemedi");
      }
    })
  }
  getFormGroup($ev:FormGroup){
    if($ev == undefined){
      setTimeout(()=>{
        this.requestForm = [true];
      },250)
    }else{
      this.updateFormGroup = $ev;
      this.initializeEditor();
    }

  }
  getUpdateForm() {
    this.sessionApiService.getUpdateSessionForm(this.sessionId).subscribe(
      {
        next: (response) => {
          response.content.pages[0].elements.forEach(element => {
            if (element.name == "scheduledStart") {
              var date = new Date(JSON.parse(element.defaultValue));
              element.defaultValue = this.convertToDateTimeLocalString(date);
            } else if (element.name == "scheduledEnd") {
              if (element.defaultValue != "" && element.defaultValue != null) {
                var date = new Date(JSON.parse(element.defaultValue));
                element.defaultValue = this.convertToDateTimeLocalString(date);
              }
            }
          })
          var val = response.content.pages[0].elements.find(x => x.name == "scheduledStart")?.defaultValue;
          this.updateForm = response.content;
        }
      }
    )
  }
  convertToDateTimeLocalString = (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  }
  newString() {
    const stringValue = {
      fieldName: "field Name",
      fieldValue: "field Value",
      fieldRequired: false,
      valueType: "string"
    }
    this.addValueToJsonEditor(stringValue);

  }
  newBoolean() {
    const boolValue = {
      fieldName: "field Name",
      fieldValue: false,
      valueType: "boolean"
    }
    this.addValueToJsonEditor(boolValue);
  }
  formatCode() {
    window['monacoEditor'].trigger('editor', "editor.action.formatDocument");
  }
  addValueToJsonEditor(data: any) {
    var editorJson = window['monacoEditor'].getValue() !== "" ? window['monacoEditor'].getValue() : "[]";
    var parsedEditorJson: any[] = [];
    try {
      parsedEditorJson = JSON.parse(editorJson);
    } catch (error) {
      this.toastrService.error("Bu işlemi gerçekleştirmek için editör üzerindeki kodun JSON formatında olması gerekli");
      throw "";
    }
    var jsonVal = JSON.parse(JSON.stringify(data));
    parsedEditorJson.push(jsonVal);
    window['monacoEditor'].setValue(JSON.stringify(parsedEditorJson));
    window['monacoEditor'].trigger('editor', "editor.action.formatDocument");
  }
  saveDataJson() {
    var editorJson = window['monacoEditor'].getValue();
    console.log();
    try {
      JSON.parse(editorJson);
      var replaced = editorJson.replace(/(\r\n|\n|\r)/gm, "");
      replaced = JSON.stringify(JSON.parse(replaced));
      this.updateFormGroup.get("data")?.setValue(replaced);
      this.toastrService.success("JSON verisi kayıt edildi, düzenlemeyi kaydet butonu ile değişiklikleri şablona kayıt edebilirsiniz")
    } catch (error) {
      this.toastrService.error("Editördeki json verisi geçerli değil");
    }
  }
  initializeEditor() {
    var jsonEditorEl = document.getElementsByClassName("jsonEditor")[0] as HTMLDivElement;
    var jsondata = this.updateFormGroup.get("data")?.value;
    var monacoEditor: any = null;
    if (typeof monaco !== 'undefined') {
      //codeEditor.outerHTML = '<div class="style-code"></div>';
      setTimeout(function () {
        monacoEditor = monaco.editor.create(jsonEditorEl, {
          language: 'json',
          value: jsondata,
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['monacoEditor'] = monacoEditor;
      }, 50),
        setTimeout(() => {
          monacoEditor.trigger('editor', "editor.action.formatDocument");
          monacoEditor.layout();
          console.log(monacoEditor)
          monacoEditor.onDidChangeModelContent((event: any) => {
            console.log("code changed");
          });
        }, 100)
    }
  }




}
