import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-translate-mode',
  templateUrl: './switch-translate-mode.component.html',
  styleUrls: ['./switch-translate-mode.component.css']
})
export class SwitchTranslateModeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  translateModeOn(){
    localStorage.setItem("devMode","active");
    location.reload();
  }
  translateModeOff(){
    localStorage.removeItem("devMode");
    location.reload();
  }
  showTranslationKeys(){
    localStorage.setItem("showTranslationKey","active");
    location.reload();
  }
  hideTranslationKeys(){
    localStorage.removeItem("showTranslationKey");
    location.reload();
  }

}
