import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  passwordResetForm:FormGroup;
  processing:boolean = false;
  firstname:string|null = null;
  constructor(private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.firstnameControl();
    this.createPasswordResetForm();
  }
  createPasswordResetForm(){
    this.passwordResetForm = this.formBuilder.group({
      username:['',Validators.required]
    })
  }
  firstnameControl(){
    let first = localStorage.getItem("firstname");
    if(first)
      this.firstname = first;
  }

}
