import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ApiUrlService } from './../api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Sanitizer } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticContentService {
  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getStaticContent(page:string):Observable<any>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get(apiUrl+'staticContent/getcontent/'+page);


  }
  getContent(page:string){
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get(apiUrl+'api/cms/page/'+page,{observe:'response',responseType:'text'});

  }
  getBlockContent(block:string){
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get(apiUrl+'api/cms/block/'+block,{observe:'response',responseType:'text'});
  }
}
