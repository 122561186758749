import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-lab-info',
  templateUrl: './lab-info.component.html',
  styleUrls: ['./lab-info.component.css']
})
export class LabInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
