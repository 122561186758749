import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { EducationModel } from 'projects/usorta-library/src/lib/models/Education/educationModel';
import { CmsImageSizes } from 'projects/usorta-library/src/lib/models/Enums/CmsImageSizes';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { ModalCreateModel } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { CmsImageService } from 'projects/usorta-library/src/lib/services/cmsImages/cms-image.service';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';
declare var $:any;
@Component({
  selector: 'lib-education-details-detail-area',
  templateUrl: './education-details-detail-area.component.html',
  styleUrls: ['./education-details-detail-area.component.css']
})
export class EducationDetailsDetailAreaComponent implements OnInit {
  @Input() education:EducationModel;
  apiUrl:string;
  themeListItem:ListItemModel[];
  constructor(private cmsImageService:CmsImageService,private translateService:TranslateService,private globalModalService:GlobalModalService,private router:Router,private globalOkCancelEvent:GlobalOkCancelEventService,private toastrService:ToastrService,private educationService:EducationService) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.getThemeOptions();
    //this.setDescriptionDetail();
  }
  redirect(url:string){

  }
  getImage(imageId:string){
    return this.cmsImageService.getUrl(imageId,CmsImageSizes.Size_250x150);
  }
  setDescriptionDetail(){
    var ifr = document.createElement("iframe");
    ifr.style.width = "100%";
    ifr.style.height = "100%";
    ifr.src = "about:blank"
    var link = document.createElement("link");
    this.globalModalService.showModal({
      title: "Detay Açıklama",
      type: ModalTypes.HTMLELEMENT,
      data: ifr,
      helperModals: []
    },true);
    link.rel="stylesheet";
    link.href = "/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css";
    ifr.onload = ()=>{
      $(ifr).contents().find("head").get(0).append(link);
      $(ifr).contents().find("body").html(this.education.description);
    }


  }
  getThemeOptions(){
    this.educationService.getEducationThemeList().subscribe({
      next:(response)=>{
        this.themeListItem = response.content;
        
      },error:(err)=>{

      }
    })
  }
  getThemeName(themeId:number){
    if(this.themeListItem){
      if(this.themeListItem.find(x=>x.value == themeId) !== null){
        return this.themeListItem.find(x=>x.value == themeId)?.label
      }
    }
    return themeId;
  }
  addSection(){
    var modalCreate:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.addSection"),
      type: ModalTypes.LINK,
      data: "/modal/lms/course/"+this.education.courseId+"/addSection",
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate)
  }
  addSubsection(sectionId:number){
    var modalCreate:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.addSubsection"),
      type: ModalTypes.LINK,
      data: "/modal/lms/course/"+this.education.courseId+"/section/"+sectionId+"/addSubsection",
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate)
  }
  editEducation(){
    this.router.navigate([`/lms/course/${this.education.courseId}/update`]);
  }
  createOutlinePage(){
    var modalCreate:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.newVersion"),
      type: ModalTypes.LINK,
      data: "/modal/lms/course/"+this.education.courseId+"/addOutline",
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate,false,"600px")
  }
  editSection(sectionId:number){
    var modalCreate:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.editSection"),
      type: ModalTypes.LINK,
      data: "/modal/lms/course/"+this.education.courseId+"/section/"+sectionId+"/update",
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate)
  }
  editSubsection(sectionId:number,subsectionId:number){
    var modalCreate:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.editSubsection"),
      type: ModalTypes.LINK,
      data: "modal/lms/course/"+this.education.courseId+"/section/"+sectionId+"/subsection/"+subsectionId+"/update",
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate)
  }
  deleteSection(educationId:string,sectionId:string){
    this.globalOkCancelEvent.showOkCancelWindow(this.translateService.instant("courseManagement.confirmSectionDeletion"),"<font style='font-size:14px;'>"+this.translateService.instant("courseManagement.sectionDeleteMessage")+"</font>").then((result=>{
      if(result == false){

      }else if(result == true){
        this.educationService.deleteEducationSection(sectionId).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("courseManagement.sectionDeleteSuccessful"));
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        });
      }
    }));
  }

  deleteSubSection(educationId:string,sectionId:number,subsectionId:number){
    this.globalOkCancelEvent.showOkCancelWindow(this.translateService.instant("courseManagement.confirmSubectionDeletion"),"<font style='font-size:14px;'>"+this.translateService.instant("courseManagement.subsectionDeleteMessage")+"</font>").then((result=>{
      if(result == false){
        
      }else if(result == true){
        this.educationService.deleteEducationSubSection(educationId,sectionId,subsectionId).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("courseManagement.subsectionDeleteSuccessful"));
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        });
      }
    }));
  }

  routeSectionDetails(sectionId:number){
    this.router.navigate([`lms/course/${this.education.courseId}/section/${sectionId}`])
  }


}
