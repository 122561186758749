import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-vm-session-connections-list',
  templateUrl: './vm-session-connections-list.component.html',
  styleUrls: ['./vm-session-connections-list.component.css']
})
export class VmSessionConnectionsListComponent implements OnInit {
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var sessionId = param["sessionId"];
        this.tableUrl = this.apiUrlService.getApiUrl() + `api/terminal/admin/sessions/${sessionId}/vmlist`
      }
    })
  }

}
