import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-icon',
  templateUrl: './create-icon.component.html',
  styleUrls: ['./create-icon.component.css']
})
export class CreateIconComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute) { }
  actionUrl:string = environment.apiUrl;
  formUrl:string;
  ngOnInit(): void {
    this.createUrl();
  }
  createUrl(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var id = param["setId"];
        this.formUrl = environment.apiUrl+"api/cms/iconsets/"+id+"/icons/create";
      }
    })
  }

}
