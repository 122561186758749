let w = window as any;
export const environment = {
  tenanturl:"test",
  tenantUrl:w['env']['tenanturl'] || "https://localhosst:5501/api/Tenant",
  apiUrl:w['env']['apiurl'] || "https://localhost:5505/gateway/",
  imageApi:w['env']['imageapi'] || "",
  applicationId:w['env']['appId'] || "",
  tenantId:w['env']['tenantId'] || "",
  production: false
};
