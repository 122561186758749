<h5>{{'lab.operatingEnvironment.updateTitle' | translate}}</h5>
<hr />
<form [formGroup]="updateEnvironmentForm" *ngIf="loaded">
    <div class="mb-3">
        <label for="name" class="form-label">{{'lab.operatingEnvironmentName' | translate}}</label>
        <input type="text" class="form-control" id="name" formControlName="name">
    </div>
    <div class="form-check">
        <label class="form-check-label" for="active">
            {{'check.active' | translate}}
        </label>
        <input class="form-check-input" type="checkbox" id="enabled" formControlName="enabled">
    </div>
    <div  class="dataEditor">
        <div class="jsonEditor" style="height: 300px; overflow:hidden;">
    
        </div>
    </div>
</form>
<br/>
<button class="btn btn-primary btn-sm" (click)="updateVMEnvironment()">{{'btn.update' | translate}}</button>
