<h2>{{'sessionManagement' | translate}}</h2><h5><span class="badge bg-secondary">Oturum Adı Gelecek</span></h5>
<br/>
<h5>{{'sessionManagement.overview' | translate}} <button class="btn btn-sm btn-warning" title="Oturumu Güncelle" (click)="showUpdateSessionModal()"><i class="bi bi-pencil-square"></i></button></h5>
<hr>
<div class="sessionInfo">
    <div class="sessionInfoBox">
        Oturum Durumu
    </div>
    <div class="sessionInfoBox">
        Katılımcılar hakkında bilgi, sayısı vb.
    </div>
    <div class="sessionInfoBox">
        
    </div>
    <div class="sessionInfoBox">
        
    </div>
</div>
<lib-session-management-session-members [sessionId]="sessionId"></lib-session-management-session-members>
<lib-session-management-list-features [sessionId]="sessionId"></lib-session-management-list-features>

<!--<lib-session-management-add-feature [sessionId]="sessionId"></lib-session-management-add-feature>-->
<lib-session-management-list-items [sessionId]="sessionId"></lib-session-management-list-items>
<!--<lib-session-management-add-item [sessionId]="sessionId"></lib-session-management-add-item>-->
<br/>