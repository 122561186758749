import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api/api-url.service';
import { DeleteLocalizedFormatStringRequest } from '../../models/LocalizedFormatString/deleteLocalizedFormatStringRequest';
import { HttpClient } from '@angular/common/http';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { LocalizedFormatStringResponse } from '../../models/LocalizedFormatString/localizedFormatStringResponse';
import { ResponseModel } from '../../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class FormatStringService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getFormatStringDetails(id:number){
    var url = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/formatstring/"+id
    return this.httpClient.get<SingleResponseModel<LocalizedFormatStringResponse>>(url);
  }
  deleteFormatString(id:number,req:DeleteLocalizedFormatStringRequest){
    var url = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/formatstring/"+id+"/delete"
    return this.httpClient.delete<ResponseModel>(url);
  }
  getFormatStrings(appId:string,cultureCode:string){
    var url = this.apiUrlService.getApiUrl() + `api/cms/localization/formatstrings/${appId}/${cultureCode}`
    console.log(appId);
    console.log(cultureCode);
    return this.httpClient.get<SingleResponseModel<any>>(url);
  }
}
