import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-delete-session-template-item',
  templateUrl: './delete-session-template-item.component.html',
  styleUrls: ['./delete-session-template-item.component.css']
})
export class DeleteSessionTemplateItemComponent implements OnInit {

  checkedUnderstandCheck:boolean = false;
  itemId:number | null;
  templateId:number | null;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.itemId = param['itemId'];
      this.templateId = param['templateId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  
  deleteTemplateItem(){
    if(this.itemId != null && this.templateId != null){
      this.sessionTemplateService.deleteSessionTemplateItem(this.templateId,this.itemId).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
            this.success = true;
          }else{
            this.toastrService.error(this.translateService.instant("global.deletedError"));
          }
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }

  }

}
