<nav *ngIf="isLogged" class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a *ngIf="siteInfo.showLogoOnNavbar" class="navbar-brand" routerLink="/">
      <img src="{{logo}}" alt="" style="max-height: 45px; object-fit:contain;">
    </a>
    <a *ngIf="siteInfo.showNameOnNavbar" class="navbar-brand" routerLink="/" >{{siteInfo.name}}</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="!menuItems">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">...</span>
      </div>
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="menuItems">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li id="{{item.id}}" [class]="'nav-item '+item.cssClass" style="position: relative;" *ngFor="let item of menuItems" [class.dropdown]="item.subItems != undefined" id="{{item.id}}">
          <a *ngIf="item.subItems != undefined" title="{{item.tooltip}}" style="cursor: pointer;" class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i *ngIf="item.preIcon" [class]="item.preIcon"></i> {{item.title | translate}} <i *ngIf="item.postIcon" [class]="item.postIcon"></i></a>
          <a *ngIf="item.subItems == undefined" title="{{item.tooltip}}" style="cursor: pointer;" class="nav-link" (click)='redirect(item)' ><i *ngIf="item.preIcon" [class]="item.preIcon"></i> {{item.title | translate}} <i *ngIf="item.postIcon" [class]="item.postIcon"></i></a>
          <ul  class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li *ngFor="let subitem of item.subItems" [class]="subitem.cssClass" style="cursor: pointer;" id="{{subitem.id}}"><a class="dropdown-item" title="{{subitem.tooltip}}" (click)='redirect(subitem)'><i *ngIf="subitem.preIcon" [class]="subitem.preIcon"></i> {{subitem.title | translate}} <i *ngIf="subitem.postIcon" [class]="subitem.postIcon"></i></a></li>
          </ul>
        </li>
      </ul>
      <div class="d-flex">
        <!--<button><font><i class="bi bi-person"></i>{{getName()}}</font></button>-->
        <div class="btn-group">
          <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <font style="font-weight: bold;"><i class="bi bi-person"></i> {{getName()}}</font>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><button class="dropdown-item" type="button">{{'myAccount' | translate}}</button></li>
            <li><button class="dropdown-item" type="button" (click)="logout()">{{'logout' | translate}}</button></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
