import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseVmImageService } from '../../../services/baseVmImages/base-vm-image.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-base-vm-image',
  templateUrl: './delete-base-vm-image.component.html',
  styleUrls: ['./delete-base-vm-image.component.css']
})
export class DeleteBaseVmImageComponent implements OnInit {

  baseVmImageId:string;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  loading:boolean = false;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private baseImageService:BaseVmImageService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseVmImageId = param["id"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteImage(){
    var keepChildren = document.getElementById("keepChildren") as HTMLInputElement;
    this.loading = true;
    this.baseImageService.deleteBaseVmImage(this.baseVmImageId).subscribe({next:(response)=>{
      this.toastrService.success(this.translateService.instant("baseVmImage.deletedSuccessfully"));
      this.loading = false;
      this.success = true;
    },error:(err)=>{
      this.loading = false;
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }})
  }

}
