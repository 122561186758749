<div class="terminalChatMain">
  <div *ngIf="selectedUser == null" class="userList">
    <div class="messagesTitle">Sohbet</div>
    <div (click)="selectUser(user)" class="userItem" *ngFor="let user of userList">
      <i class="bi bi-person-circle"></i>
      <font> {{user.displayName}}</font>
    </div>
  </div>
  <div *ngIf="selectedUser != null" class="messageArea">
    <div class="showUsername">
      <b>{{selectedUser.displayName}}</b>
      <i (click)="goUserList()" class="bi bi-x getUserListIcon"></i>
    </div>
    <div>
      <div class="messages">
        <div class="messageItem" [class.yourMessage]="message.your != undefined && message.your == true"
          [class.otherMessage]="message.your == undefined || message.your == false"
          *ngFor="let message of getMessages()">
          <div class="messageTextItem">{{message.message}}</div>
        </div>
      </div>
    </div>
    <div class="sendArea">
      <textarea class="privateMessageTextarea" id="sendTextarea"></textarea>
      <button class="btn btn-sm btn-danger" (click)="clearTextarea()">Temizle</button>
      &nbsp;
      <button class="btn btn-sm btn-success" (click)="sendMessage()">Gönder</button>
    </div>
  </div>
</div>
