import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'lib-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit,AfterViewInit {
  @Input() options:any;
  @Input() data:any;
  @Input() showLegend:boolean = false;
  @Output() getBaseChart:EventEmitter<BaseChartDirective> = new EventEmitter<BaseChartDirective>();
  constructor() { }
  @ViewChild(BaseChartDirective) chart?:BaseChartDirective
  ngAfterViewInit(): void {
    console.log("view initialized successfully")
    this.getBaseChart.emit(this.chart);
  }

  ngOnInit(): void {
  }

}
