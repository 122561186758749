import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HostDashboardChartModel } from '../../../models/DashboardModels/hostDashboardChartModel';
import { HostDashboardService } from '../../../services/hostDashboard/host-dashboard.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration } from 'chart.js';

import { ChartEmitterModel } from '../../../models/CustomChartModels/chartEmitterModel';
import { ChartCreateModel } from '../../../models/CustomChartModels/chartCreateModel';

@Component({
  selector: 'lib-host-dashboard-chart',
  templateUrl: './host-dashboard-chart.component.html',
  styleUrls: ['./host-dashboard-chart.component.css']
})
export class HostDashboardChartComponent implements OnInit,OnChanges {
  @Input() hostDashboardChart:HostDashboardChartModel;
  @Input() chartData:ChartCreateModel;
  pushData:any;
  chart:BaseChartDirective;
  start:boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event:any){
    this.chart.chart?.resize();
  } 
  @Input() chartKey:string;
  @Output() outChart:EventEmitter<ChartEmitterModel> = new EventEmitter<ChartEmitterModel>();
  constructor(private dashboardSvc:HostDashboardService) { }

  ngOnInit(): void {
    this.getChart();
    console.log("calisiyorr -----------------------")
  }
  ngOnChanges(changes:SimpleChanges){

  }

  getChart(){
    /*this.dashboardSvc.getChartData().subscribe({
      next:(response)=>{
        this.chartData = response.content;
        console.log(this.chartData)
      }
    })*/
  }
  getChartEl(baseChart:BaseChartDirective){
    console.log("basechart");

    console.log(baseChart);
    this.chart = baseChart;
    this.sendChartParentComponent(baseChart);
  }
  sendChartParentComponent(baseChart:BaseChartDirective){
    this.outChart.emit({key:this.chartKey,chart:baseChart});
  }

}
