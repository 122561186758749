import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { AddVMToVirtualLab } from 'projects/usorta-library/src/lib/models/VirtualLabModels/addVMToVirtualLabModel';
import { VirtualMachineModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/virtualMachineModel';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';
declare var $:any;
@Component({
  selector: 'lib-add-vm-to-lab',
  templateUrl: './add-vm-to-lab.component.html',
  styleUrls: ['./add-vm-to-lab.component.css']
})
export class AddVmToLabComponent implements OnInit {
  @Output() data:any;
  @Input() labId:string;
  vmList:ListItemModel[];
  vmModel:VirtualMachineModel;
  nicList:any[];
  networks:ListItemModel[];
  constructor(private translateService:TranslateService,private virtualLabService:VirtualLabService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.labId = param["labId"];
        this.getVirtualMachineList();
        this.getNetworks();
      }
    })

  }
  getVirtualMachineList(){
    this.virtualLabService.getVMList().subscribe({
      next:(response)=>{
        console.log(response)
        this.vmList = response.content;

      }
    })
  }
  getNetworks(){
    this.virtualLabService.getNetworks(this.labId).subscribe({
      next:(response)=>{
        this.networks = response.content;
      }
    })
  }
  selectVM(ev:any){
    var value = $(ev.target).val();
    this.getVMInfo(value);
    console.log(value);
  }
  getVMInfo(vmId:string){
    this.virtualLabService.getVirtualMachine(vmId).subscribe({
      next:(response)=>{
        this.vmModel = response.content;
        this.nicList = JSON.parse(this.vmModel.nicCount);
        console.log(this.nicList)
      }
    })
  }
  getNicMappings(){
    try {
      var dataList:{nicName:string,network:string,ipAddress:string}[] = [];
      this.nicList.forEach((nic)=>{
        dataList.push({
          nicName:nic.nicName,
          ipAddress:(document.getElementById("ipAddress_"+nic.nicName) as HTMLInputElement).value,
          network:(document.getElementById("network_"+nic.nicName) as HTMLInputElement).value
        })
      })
      console.log(dataList);
      return dataList;
    }catch{
      throw "";
    }


  }
  addVM(){
  
    var nicMap = this.getNicMappings();
    var identifier = (document.getElementById("identifier") as HTMLInputElement)
    var name = (document.getElementById("name") as HTMLInputElement)
    var baseVMId = (document.getElementById("baseVMId") as HTMLSelectElement)
    var notes = (document.getElementById("notes") as HTMLTextAreaElement)
    var addVmModel:AddVMToVirtualLab = {
      identifier: identifier.value,
      name: name.value,
      baseVMId: baseVMId.value,
      nicMappings: nicMap,
      notes:notes.value
    }
    console.log(addVmModel);
    this.virtualLabService.addVirtualMachineToLab(this.labId,addVmModel).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("lab.addVmToLab.success"));
      },
      error:(err)=>{
        this.toastrService.success(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
