<h5>{{'vmCustomization.changeUserPassword' | translate}}</h5>
<hr/>
<form [formGroup]="changePasswordForm">
    <div class="mb-3 form-floating">

        <input type="text" class="form-control" [class.is-invalid]="errorControl('username')" id="username" placeholder="" formControlName="username">
        <label for="username">{{'username' | translate}}</label>
        <font style="font-size: 13px;">
            <b>*</b>{{"onlyUseLettersAndNumbers" | translate}}
        </font>
    </div>

    <div class="mb-3 form-floating">

        <input type="password" class="form-control" [class.is-invalid]="errorControl('password')" id="password" style="width: calc(100% - 50px); display: inline-block; vertical-align: middle;" formControlName="password" placeholder="">
        <label for="password" class="form-label">Şifre</label>
        <span [title]="'showHidePassword' | translate" style="cursor: pointer; width: 45px; margin-left: 10px; background-color: rgb(255, 255, 255); padding: 8px; border-radius: 5px;" (click)="togglePasswordPreview()"><i [class]="togglePasswordIcon()"></i></span>
    </div>
    <button class="btn btn-warning" (click)="changePassword()">{{'vmCustomization.changePassword' | translate}}</button>
</form>