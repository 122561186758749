import { Component, OnInit } from '@angular/core';
import { AppConfigurationService } from '../../../services/appConfigurations/app-configuration.service';
import { ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsImageModel } from '../../../models/AppSettings/appSettingsImageModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';

@Component({
  selector: 'lib-upload-configuration-image',
  templateUrl: './upload-configuration-image.component.html',
  styleUrls: ['./upload-configuration-image.component.css']
})
export class UploadConfigurationImageComponent implements OnInit {
  tenantId?: string = undefined;
  applicationId?: string = undefined;
  appTenantId?: number = undefined;
  defaultCourseImage?: string;
  defaultCourseImageChangedEvent: any;
  logoImage?: string
  logoImageChangedEvent: any;
  configurationImageModel: AppSettingsImageModel;
  constructor(private globalOkCancelService:GlobalOkCancelEventService,private appConfigurationService: AppConfigurationService, private activatedRoute: ActivatedRoute, private translateService: TranslateService, private toastrService: ToastrService,private httpClient:HttpClient) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.tenantId = param["tenantId"];
        this.applicationId = param["appId"];
        this.appTenantId = param["appTenantId"];
        if (this.appTenantId !== undefined) {
          this.appTenantId = parseInt(param["appTenantId"]);
        }
        console.log(this.tenantId);
        console.log(this.applicationId);
        console.log(this.appTenantId);
      }
    })
    this.getImages();
  }
  getImages(){
    if (this.tenantId != undefined) {
      this.appConfigurationService.getTenantImages(this.tenantId).subscribe({
        next: (response) => {
          this.configurationImageModel = response.content;
        }
      })
    } else if (this.applicationId != undefined) {
      this.appConfigurationService.getApplicationImages(this.applicationId).subscribe({
        next: (response) => {
          this.configurationImageModel = response.content;
        }
      })
    } else if (this.appTenantId !== undefined && this.appTenantId != 0) {
      this.appConfigurationService.getAppTenantImages(this.appTenantId).subscribe({
        next: (response) => {
          this.configurationImageModel = response.content;
        }
      })
    }
  }
  changeLogoImage($ev: any) {
    this.logoImageChangedEvent = $ev;
  }
  logoImageCropped($ev: ImageCroppedEvent) {
    this.logoImage = $ev.base64 as string | undefined;
  }
  changeDefaultCourseImage($ev: any) {
    this.defaultCourseImageChangedEvent = $ev;
  }
  defaultCourseImageCropped($ev: ImageCroppedEvent) {
    this.defaultCourseImage = $ev.base64 as string | undefined;
  }
  uploadLogo() {
    if (this.tenantId != undefined && this.logoImage != null) {
      this.appConfigurationService.uploadTenantConfigurationLogo(this.tenantId, this.logoImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.logoUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })
    } else if (this.appTenantId != undefined && this.logoImage != null) {
      this.appConfigurationService.uploadApplicationTenantConfigurationLogo(this.appTenantId, this.logoImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.logoUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })

    } else if (this.applicationId != undefined && this.logoImage != null) {
      this.appConfigurationService.uploadApplicationConfigurationLogo(this.applicationId, this.logoImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.logoUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })
    } else {
      this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"))
    }
  }

  uploadDefaultCourseImage() {
    if (this.tenantId != undefined && this.defaultCourseImage != null) {
      this.appConfigurationService.uploadTenantConfigurationDefaultCourseImage(this.tenantId, this.defaultCourseImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.defaultCourseImageUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })
    } else if (this.appTenantId != undefined && this.defaultCourseImage != null) {
      this.appConfigurationService.uploadApplicationTenantConfigurationDefaultCourseImage(this.appTenantId, this.defaultCourseImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.defaultCourseImageUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })

    } else if (this.applicationId != undefined && this.defaultCourseImage != null) {
      this.appConfigurationService.uploadApplicationConfigurationDefaultCourseImage(this.applicationId, this.defaultCourseImage).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("appsettings.defaultCourseImageUploadedSuccessfully"));
          this.getImages();
          return;
        }, error: (err) => {
          this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"));
        }
      })
    } else {
      this.toastrService.error(this.translateService.instant("appsettings.anErrorOccurred"))
    }
  }

  deleteLogoImage() {
    this.globalOkCancelService.showOkCancelWindow("configurations.modal.title","configurations.deleteLogoImageModalText").then(x=>{
      if(x == true){
        let request:{id:any} = {id:""};
        let url:string;
        if (this.tenantId != undefined) {
          url = (environment.apiUrl + `api/cms/tenants/${this.tenantId}/configurations/logo/delete`)
          request.id = this.tenantId;
        } else if (this.appTenantId != undefined) {
          url = (environment.apiUrl + `api/cms/apptenants/${this.appTenantId}/configurations/logo/delete`)
          request.id = this.appTenantId;
        } else if (this.applicationId != undefined) {
          url = (environment.apiUrl + `api/cms/applications/${this.applicationId}/configurations/logo/delete`)
          request.id = this.applicationId;
        }else{
          this.toastrService.error("configurations.deleteLogoImageError")
          return;
        }
        console.log(this.applicationId);
        console.log(url);
        this.httpClient.delete(url,{body:request}).subscribe({
          next:(response)=>{
            this.toastrService.success("configurations.logoImageDeleted");
            this.getImages();
          },error:(err)=>{
            this.toastrService.error("configurations.deleteLogoImageError")
          }
        })
      }
    })

  }
  deleteDefaultCourseImage() {
    this.globalOkCancelService.showOkCancelWindow("configurations.modal.title","configurations.deleteLogoImageModalText").then(x=>{
      if(x == true){
        var request:{id:any} = {id:""};
        var url:string = "";
        if (this.tenantId != undefined) {
          url = environment.apiUrl + `api/cms/tenants/${this.tenantId}/configurations/courseimage/delete`
          request.id = this.tenantId;
        } else if (this.appTenantId != undefined) {
          url = environment.apiUrl + `api/cms/apptenants/${this.appTenantId}/configurations/courseimage/delete`
          request.id = this.appTenantId;
        } else if (this.applicationId != undefined) {
          url = environment.apiUrl + `api/cms/applications/${this.applicationId}/configurations/courseimage/delete`
          request.id = this.applicationId;
        }else{
          this.toastrService.error("configurations.deleteLogoImageError")
          return;
        }
        this.httpClient.delete(url,{body:request}).subscribe({
          next:(response)=>{
            this.toastrService.success("configurations.logoImageDeleted");
            this.getImages();
          },error:(err)=>{
            this.toastrService.error("configurations.deleteLogoImageError")
          }
        })
      }
    })

  }



}
