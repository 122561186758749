<div *ngIf="devModeActive" class="helper-bar">

    <div class="collapse-toggler" data-bs-toggle="collapse" href="#collapseExample">
        <i class="bi bi-translate" style="font-size: 20px; color: white;"></i>
        <b style="font-size: 20px; color: white;"> Çeviri Yönetim Modu</b>
    </div>
    <div class="collapse" id="collapseExample">
        <div class="card card-body">
            <!--<div class="keys">
                <div class="key" *ngFor="let key of translationKeys">{{key}}</div>
            </div>-->
        </div>
      </div>


</div>