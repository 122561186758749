import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { VmEnvironmentInfoModel } from '../../../models/VirtualLabModels/vmEnvironmentInfoModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-vm-environment-details',
  templateUrl: './vm-environment-details.component.html',
  styleUrls: ['./vm-environment-details.component.css']
})
export class VmEnvironmentDetailsComponent implements OnInit {

  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }
  hostId:string;
  data:VmEnvironmentInfoModel;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.hostId = param["hostId"];
        this.getVmEnvironmentInfo();
      }
    })
  }
  getVmEnvironmentInfo(){
    this.virtualLabService.getVmEnvironmentInfo(this.hostId).subscribe({
      next:(response)=>{
        this.data = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
