import { ResponseModel } from './../../models/responseModel';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from './../../models/userInfo';
import { environment } from './../../../environments/environment.prod';
import { LoginModel } from './../../models/loginModel';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseTokenModel } from '../../models/responseTokenModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TestLoginService implements LoginService{

  constructor(private httpClient:HttpClient,private router:Router) { }
  
  public refreshExpirationControl(): boolean {
    if(sessionStorage.getItem("refresh_token")){

      let expiration = sessionStorage.getItem("refresh_token_expiration");
      if(expiration != null){
        let exp = new Date(expiration);
        if(exp.getTime() > new Date().getTime()){
          return true;
        }
      }
    }
    return false;
  }
  public verifyPassword(loginModel: LoginModel): Observable<ResponseModel>
  {
    let apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + "verifypassword", loginModel);
  }
  public logout(): void
  {
    sessionStorage.clear();
    this.router.navigate(['/login']);

  }
  public isLoggedControlWithExpiration(): boolean
  {
    if(sessionStorage.getItem("token")){

      let expiration = sessionStorage.getItem("token_expiration");
      if(expiration != null){
        let exp = new Date(expiration);
        if(exp.getTime() > new Date().getTime()){
          return true;
        }
      }
    }
    return false;

  }
  public getUsernameForVerifyPassword(): Observable<string>
  {
    throw new Error('Method not implemented.');
  }
  getUserInfo(isAdmin=false): Observable<SingleResponseModel<UserInfo>>
  {
    let apiUrl = environment.apiUrl;
    if(isAdmin == false){
      return this.httpClient.get<SingleResponseModel<UserInfo>>(apiUrl+"api/accounts/user/me");
    }else{
      return this.httpClient.get<SingleResponseModel<UserInfo>>(apiUrl+"api/accounts/admin/me");
    }

  }
  login(loginModel:LoginModel,isAdmin=false): Observable<SingleResponseModel<ResponseTokenModel>>
  {
    let apiUrl = environment.apiUrl;
    let loginUrl = isAdmin ? "login/admin" : "login/user"; 
    return this.httpClient.post<SingleResponseModel<ResponseTokenModel>>(apiUrl+loginUrl,loginModel);
  }

}
