import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-popout-message',
  templateUrl: './popout-message.component.html',
  styleUrls: ['./popout-message.component.css']
})

export class PopoutMessageComponent implements OnInit {
  @Input() mainWindow:Window;
  @Input() popOutWindow:Window;
  constructor() { }

  ngOnInit(): void {
  }
  windowFocus(){
    this.popOutWindow.focus();
  }
  windowClose(){
    this.popOutWindow.close();
  }

}
