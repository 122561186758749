import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-course-translation-list',
  templateUrl: './course-translation-list.component.html',
  styleUrls: ['./course-translation-list.component.css']
})
export class CourseTranslationListComponent implements OnInit {
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.tableUrl = this.apiUrlService.getApiUrl() + `api/lms/courses/${param["courseId"]}/translations`
      }
    })
  }

}
