import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
declare var window: any;
declare var $: any;
@Component({
  selector: 'lib-lms-video-player',
  templateUrl: './lms-video-player.component.html',
  styleUrls: ['./lms-video-player.component.css']
})
export class LmsVideoPlayerComponent implements OnInit, AfterViewInit {
  currentVideo: any;
  apiUrl:string = environment.apiUrl;
  constructor() { }
  ngAfterViewInit(): void {
    var videoDiv = document.getElementById("videoContainer");
    $("#videoContainer").videoPlayer(
      {
        requiredcontent: function(ev:any,param:any){
          console.log(ev);
          console.log(param);
        }
      }
    );
    // this.currentVideo = window.createVideoPlayer(videoDiv,{videoSrc:"https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"})
    // this.requireContentListener();
    console.log(this.currentVideo);
  }

  ngOnInit(): void {
  }
  play() {
    this.currentVideo.play();
  }
  pause() {
    this.currentVideo.pause();
  }
  dispose() {
    this.currentVideo.dispose();
  }
  requireContentListener() {
    var videoElement = this.currentVideo.videoEl;
    videoElement.addEventListener("requiredContent", function (content: any) {
      console.log(content);
    })
    console.log(this.currentVideo);
  }

}
