<div class="mainDiv">
    <div>
        <b class="detailTitle">{{'vmhosts.hostName' | translate}} :</b><font class="detailValue">{{data.name}}</font>
    </div>
    <div>
        <b class="detailTitle">{{'vmhosts.type' | translate}} : </b><font class="detailValue">{{data.type}}</font>
    </div>
    <div>
        <b class="detailTitle">{{'vmhosts.enabled' | translate}} : </b>

            <input class="form-check-input" [checked]="data.enabled" onclick="return false" [readOnly]="true" type="checkbox" value="" id="flexCheckDefault">
    </div>
    <div>
        <b class="detailTitle">{{'vmhosts.status' | translate}} : </b><font class="detailValue">{{data.status}}</font>
    </div>
    <div *ngIf="data.data">
        <b class="detailTitle">{{'vmhosts.data' | translate}} : </b><pre class="detailValue">{{data.data}}</pre>
    </div>

    <div *ngIf="data.tags">
        <b class="detailTitle">{{'vmhosts.tags' | translate}} : </b><font class="detailValue">{{data.tags}}</font>
    </div>





</div>
