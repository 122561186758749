import { environment } from './../../../environments/environment.prod';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from './tenant.service';
import { TenantResponseModel } from '../../models/TenantModels/CmsTenant/tenantResponseModel';
import { ApiUrlService } from '../api/api-url.service';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ResponseModel } from '../../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class TenantTestService implements TenantService{

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  public getCSS(): Observable<any>
  {
    var tenantUrl = environment.tenantUrl;
    return this.httpClient.get(tenantUrl+"getcss",{responseType:'text'});
  }
  getAppsettings(appId:string,tenantId?:string): Observable<SingleResponseModel<JSON>>
  {
    var apiUrl = this.apiUrlService.getApiUrl();
    var query = "?appId="+appId;
    if(tenantId != undefined){
      query+="&tenantId="+tenantId;
    }
    return this.httpClient.get<SingleResponseModel<JSON>>(apiUrl+"api/cms/applicationsettings"+query);
  }
  getTenantResponse(id:string):Observable<SingleResponseModel<TenantResponseModel>> {
    var apiUrl:string = this.apiUrlService.getApiUrl() + `api/cms/admin/multitenancy/tenant/${id}`
    return this.httpClient.get<SingleResponseModel<TenantResponseModel>>(apiUrl);
  }
  deleteTenant(id:string):Observable<ResponseModel>{
    var apiUrl:string = this.apiUrlService.getApiUrl() + `api/cms/admin/multitenancy/tenant/${id}/delete`
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:{id:id}});
  }
}
