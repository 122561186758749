import { Component, OnInit } from '@angular/core';
import { InstallerImageDetailsModel } from '../../../models/VirtualLabModels/installerImageDetailsModel';
import { ActivatedRoute } from '@angular/router';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'lib-installer-image-details',
  templateUrl: './installer-image-details.component.html',
  styleUrls: ['./installer-image-details.component.css']
})
export class InstallerImageDetailsComponent implements OnInit {
  imageDetails:InstallerImageDetailsModel;
  imageId:string;
  downloading:boolean = false;
  downloaded:number;
  total:number;
  constructor(private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      {
        next:(param)=>{
          this.imageId = param["imageId"];
          this.getDetails();
        }
      })
  }
  getDetails(){
    this.virtualLabService.getInstallerImageDetails(this.imageId).subscribe({
      next:(response)=>{
        this.imageDetails = response.content;
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }
  mathFloor(downloaded:number){
    return Math.floor(downloaded);
  }
  downloadFile(){
    fetch("",)
    this.virtualLabService.downloadInstallerImageFile(this.imageDetails.id).subscribe({
      next:(response)=>{
        if(response.type == HttpEventType.DownloadProgress){
          this.downloading = true;
          this.downloaded = response.loaded;
          this.total = response.total ?? 0;
          
        }else if(response.type == HttpEventType.Response){
          console.log(response.body)
          let blob = new Blob([response.body as any])
          var blobUrl = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = blobUrl;
          link.download = this.imageDetails.filename;
          link.innerHTML = "Click here to download the file";
          link.style.display = "block";
          document.body.appendChild(link); 
          link.click();
          this.downloading = false;
        }
        

      },error:(err)=>{
        this.downloading = false;
        this.toastrService.error("An error occurred");
      }
    });
  }


}
