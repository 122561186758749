import { Component, OnInit } from '@angular/core';
import { UserManagerService } from '../../services/user-manager.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  constructor(private translateService:TranslateService, private userManager:UserManagerService,private toastrService:ToastrService,private router:Router) { }
  responseData:any;
  fields:any;
  actions:any;
  loading:boolean = false;
  filterFullName:string = ""
  ngOnInit(): void {
    this.getUsers();
    this.fields = [{field:"username",text:"Kullanıcı Adı"},{field:"firstName",text:"Ad"},{field:"lastName",text:"Soyad"},{field:"email",text:"E-Posta"},{field:"enabled",text:"Aktiflik Durumu"}];
    this.actions = [{id:"update",text:"Düzenle",clickOperation:this.routeUserUpdate},{id:"delete",text:"Sil",clickOperation:this.deleteUser},{id:"block",text:"Blokla",clickOperation:this.blockUser,ifShow:this.isBlocked},{id:"unblock",text:"Blok Kaldır",clickOperation:this.unblockUser,ifShow:this.isUnBlocked},{id:"changepassword",text:"Şifre Değiştir",clickOperation:this.routeChangePassword}];
  }
  updateUser = (data:any) =>{
    alert(data);
    console.log(data);
  }
  isBlocked(data:any):boolean {
    return data.enabled ? true : false;
  }
  changeFilterFullName(ev:any){
    this.filterFullName = ev.target.value;
  }
  isUnBlocked(data:any):boolean {
    return data.enabled ? false : true;
  }
  deleteUser:any = (data:any) => {
    this.userManager.deleteUserById(data.id).subscribe({
      next:(response)=>{
        console.log(response);
        this.toastrService.info(response.success == true ?  this.translateService.instant("global.successful") : this.translateService.instant("global.unsuccessful"));
      }
    })
  }
  blockUser = (data:any) => {
    this.userManager.blockUser(data.id).subscribe({
      next:(response)=>{
        this.toastrService.info(response.success == true ? this.translateService.instant("global.successful") : this.translateService.instant("global.unsuccessful"));
        this.getUsers();
      }
    })
  }
  unblockUser = (data:any) => {
    this.userManager.unblockUser(data.id).subscribe({
      next:(response)=>{
        this.toastrService.info(response.success == true ? this.translateService.instant("global.successful") : this.translateService.instant("global.unsuccessful"));
        this.getUsers();
      }
    })
  }
  getUsers(){
    this.loading = true;
    this.userManager.getUsers().subscribe({
      next:(data)=>{
        this.loading = false;
        this.responseData = data.content;
        console.log(data)
      },
      error:()=>{
        this.loading = false;
      }
    })
  }
  routeUserUpdate = (data:any) =>{
    this.router.navigate(["/user/"+data.id+"/update"])
  }
  routeChangePassword = (data:any) => {
    this.router.navigate(["/user/"+data.id+"/changepassword"]);
  }

  }

