import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-session-templates',
  templateUrl: './session-templates.component.html',
  styleUrls: ['./session-templates.component.css']
})
export class SessionTemplatesComponent implements OnInit {
  sessionTemplatesUrl:string = environment.apiUrl+"api/v2/sessions/templates";
  constructor() { }
  
  ngOnInit(): void {
  }

}
