<h5>{{'vmCustomization.installPackages' | translate}}</h5>
<hr/>
<form [formGroup]="installPackagesForm">
<div class="mb-3 form-floating">

    <input type="text" [class.is-invalid]="errorControl('packageName')" class="form-control" id="packageName" formControlName="packageName" placeholder="">
    <label for="packageName" class="form-label">{{'vmCustomization.packageName' | translate}}</label>
    <font style="font-size: 13px;">
        <b>*</b>{{'vmCustomization.packagesSeperateComma' | translate}}
    </font>
</div>

<div class="mb-3 form-floating">

    <input type="text" class="form-control" id="extraArgs" formControlName="extraArgs" placeholder="">
    <label for="extraArgs" class="form-label">{{'vmCustomization.extraArguments' | translate}}</label>
    <font style="font-size: 13px;">
        <b>*</b>{{'vmCustomization.extraArgumentsDesc' | translate}}
    </font>
</div>
<button (click)="addInstallPackagesCustomization()" class="btn btn-warning">{{'btn.addCustomization' | translate}}</button>
</form>