import { Component, OnInit } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChunkUploaderService } from '../../../services/chunkUploader/chunk-uploader.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-upload-installer-image',
  templateUrl: './upload-installer-image.component.html',
  styleUrls: ['./upload-installer-image.component.css']
})
export class UploadInstallerImageComponent implements OnInit {
  imageId:string;
  uploading:boolean = false;
  fileItem:any;
  totalChunks:number = 0;
  uploadedChunks:number = 0;
  percentage:string;
  constructor(private chunkUploader:ChunkUploaderService,private virtualLabService:VirtualLabService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.imageId = param["imageId"];
      }
    })
  }
  handleFile($ev:any){
    if($ev.target && $ev.target.files && $ev.target.files[0]){
      this.fileItem = $ev.target.files[0];
    }

  }
  uploadInstallerImage(){
    console.log("tiklandi");
    console.log(this.fileItem);
    if(this.fileItem){
      this.uploading = true;
      console.log("upload calisti");
      this.chunkUploader.getProgress().subscribe({
        next:(response)=>{
          console.log(response.totalCount + " uploaded : "+ response.uploaded);
          this.totalChunks = response.totalCount;
          this.uploadedChunks = response.uploaded
          this.percentage = Math.ceil((this.uploadedChunks*100)/this.totalChunks)+"%";
          console.log(this.percentage);
        }
      })
      this.chunkUploader.uploadFileWithChunks(this.fileItem,environment.apiUrl+"api/range/vm/installerimages/"+this.imageId+"/uploadchunked",1024 * 1024 * 20,{redirect:"/range/vm/installerimages/"+this.imageId+"/details"}).then(()=>{this.uploading = false});
      //this.chunkUploader.uploadFileWithChunks(this.fileItem,"https://localhost:44303/"+"api/v2/range/vm/installerimages/"+this.imageId+"/uploadchunked",1024 * 1024).then(()=>{this.uploading = false});
    }else{
      this.uploading = false;
      this.toastrService.error("Please select a installer image file");
    }
  }

}
