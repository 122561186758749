import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { QuestionResponseListStyleConst } from '../../../models/examManagementModels/questionResponseListStyleConst';
import { ExamOptionsConst } from '../../../models/examManagementModels/examOptionsConst';
import { QuestionService } from '../../../services/questionManager/question.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddExamModel } from '../../../models/examManagementModels/addExamModel';
import { ExamManagementService } from '../../../services/exam/exam-management.service';

@Component({
  selector: 'lib-add-exam',
  templateUrl: './add-exam.component.html',
  styleUrls: ['./add-exam.component.css']
})
export class AddExamComponent implements OnInit {
  locales: ListItemModel[];
  responseItemStyle: { key: string, value: number }[];
  loading: boolean = false;
  options: { key: string, value: number }[];
  addExamForm: FormGroup
  constructor(private examManagementService: ExamManagementService, private questionService: QuestionService, private formBuilder: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.responseItemStyle = Object.entries(QuestionResponseListStyleConst).map((val, index, arr) => {
      return { key: arr[index][0], value: arr[index][1] };
    })
    this.options = Object.entries(ExamOptionsConst).map((val, index, arr) => {
      return { key: arr[index][0], value: arr[index][1] };
    })
    this.questionService.getLanguageList().subscribe({
      next: (response) => {
        this.locales = response.content;
        this.createForm();
      }
    })
  }
  createForm() {
    this.addExamForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: [""],
      active: [false],
      locale: ["tr-tr"],
      maxPoints: [100, Validators.required],
      passingPts: [0],
      passingPct: [0],

    })
  }
  createExam() {
    if (this.addExamForm.valid) {
      var addExamModel: AddExamModel = Object.assign({}, this.addExamForm.value);
      addExamModel.responseItemStyle = this.getResponseItemCheck();
      addExamModel.options = this.getOptions();
      this.loading = true;
      this.examManagementService.addExam(addExamModel).subscribe({
        next: (response) => {
          this.loading = false;
          this.toastrService.success("Sınav eklendi");
        }, error: (err) => {
          this.loading = false;
          this.toastrService.error("Sınav eklenemedi");
        }
      })

    } else {
      this.toastrService.error("Formu kontrol edin");
    }
  }
  getResponseItemCheck() {
    var num = 0;
    var items = document.getElementsByClassName("responseItemCheck");
    for (let index = 0; index < items.length; index++) {
      const element = items[index] as HTMLInputElement;
      if (element.checked) {
        num += parseInt(element.value);
      }

    }
    return num;
  }
  getOptions() {
    var num = 0;
    var items = document.getElementsByClassName("optionsCheck");
    for (let index = 0; index < items.length; index++) {
      const element = items[index] as HTMLInputElement;
      if (element.checked) {
        num += parseInt(element.value);
      }

    }
    return num;
  }

}
