import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-service-accounts',
  templateUrl: './service-accounts.component.html',
  styleUrls: ['./service-accounts.component.css']
})
export class ServiceAccountsComponent implements OnInit {
  tableUrl:string
  constructor() { }

  ngOnInit(): void {
    this.tableUrl = environment.apiUrl + "api/accounts/service-accounts/table";
  }

}
