<div class="main">
    <div class="trashIcon">
        <i class="bi bi-trash-fill trashIcon"></i>
    </div>
    <div class="alert alert-info" role="alert">
            {{'accessControl.roleDeleteMessage' | translate}}
    </div>
    <input type="checkbox" (change)="checkChanged($event)" id="understandCheck" name="understandCheck"/><label for="understandCheck">{{'global.understandCheckboxMessage' | translate}}</label>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="deleteRole()" [disabled]="checkedUnderstandCheck == false"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
