import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VideoTimelineService } from '../../services/videoTimeline/video-timeline.service';
import { VideoTimelineModel, VideoTimelineRowModel } from '../../models/Timeline/videoTimelineModel';
import { InteractiveVideoContentModel, InteractiveVideoContentTypes, InteractiveVideoModel, InteractiveVideoQuestionAnswerModel, InteractiveVideoQuestionModel, InteractiveVideoSectionModel } from '../../models/Timeline/interactiveVideoModel';
import { GlobalModalService } from '../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
declare var $: any;
@Component({
  selector: 'lib-video-timeline-editor',
  templateUrl: './video-timeline-editor.component.html',
  styleUrls: ['./video-timeline-editor.component.css']
})
export class VideoTimelineEditorComponent implements OnInit {
  @Input() videoTimeline: VideoTimelineModel;
  sections: VideoTimelineRowModel;
  contents: VideoTimelineRowModel[];
  interactiveSections: InteractiveVideoSectionModel[] = [];
  interactiveContents: InteractiveVideoContentModel[] = [];
  selectedItemId: string;
  questionText: string;
  answers: string[] = ["", "", "", ""];
  btnOk:boolean = false;
  @ViewChild("setAnswerForm") setAnswerForm: ElementRef<HTMLDivElement>;
  constructor(private timelineService: VideoTimelineService, private globalModalService: GlobalModalService) { }

  ngOnInit(): void {
    this.sections = this.videoTimeline.rows[0];
    this.contents = this.videoTimeline.rows.slice(1, this.videoTimeline.rows.length);
    console.log(this.sections);
    console.log(this.contents)
    this.sections.keyframes.forEach(section => {
      this.interactiveSections.push({
        id: section.id,
        begin: section.begin,
        end: section.end ?? 0,
        sectionName: ""
      })
    })
    this.contents.forEach(row => {
      row.keyframes.forEach(content => {
        this.interactiveContents.push({
          id: content.id,
          begin: content.begin,
          end: content.end,
          contentType: InteractiveVideoContentTypes.QUESTION
        })
      })
    })
  }

  setContentType(id: string, $ev: any) {
    var val = parseInt($($ev.target).val());
    if (val == 0) {
      var interactiveContent = this.interactiveContents.find(x => x.id == id);
      if (interactiveContent != null) {
        interactiveContent.contentType = InteractiveVideoContentTypes.QUESTION;
        this.setQuestion(id);
      }
    }
  }

  setQuestion(id: string,edit:boolean | undefined = undefined) {
    this.btnOk = false;
    console.log(this.answers);
    if(edit != true){
      this.answers = ["","","",""];
      $("#questionTextarea").val("")
      $("#answer1").val("")
      $("#answer2").val("")
      $("#answer3").val("")
      $("#answer4").val("")
    }
    this.selectedItemId = id;
    var modal = this.globalModalService.showModal({
      data: this.setAnswerForm.nativeElement,
      helperModals: [],
      title: "",
      type: ModalTypes.HTMLELEMENT
    })
    modal.onclose.subscribe((response:any)=>{
      var contentModel = this.interactiveContents.find(x=>x.id == id);
      if(contentModel == null){
        return;
      }
      console.log(contentModel);
      if(edit == true && this.btnOk == false){
        return;
      }
      var correctAnswer = parseInt($('input[name="correct"]:checked').val());
      var interactiveAnswers:InteractiveVideoQuestionAnswerModel[] = [];
      console.log(this.answers.length);
      for (let index = 0; index < this.answers.length; index++) {
        const answer = this.answers[index];
        interactiveAnswers.push({
          id:this.generateGuid(),
          correct:correctAnswer == index ? true : false,
          text:answer
        })
      }
      var question:InteractiveVideoQuestionModel = {
        answers:interactiveAnswers,
        id:this.generateGuid(),
        text:this.questionText
      }
      contentModel.data = JSON.stringify(question);
    })
  }
  setSection(id:string,$ev:any){
    var section = this.interactiveSections.find(x=>x.id == id);
    if(section != null){
      section.sectionName = $($ev.target).val();
    }
  }
  setAnswer(index: number, $ev: any) {
    this.answers[index] = $($ev.target).val();
  }
  changeQuestion($ev:any){
    this.questionText = $($ev.target).val();
  }
  generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  btnOkProcess(){
    this.btnOk = true;
  }
  editContent(id: string) {
    var globalThis = this;
    this.selectedItemId = id;
    var selectedContent = this.interactiveContents.find(x => x.id == id);
    if (selectedContent != null) {
      switch (selectedContent.contentType) {
        case InteractiveVideoContentTypes.QUESTION:
          var questionData = JSON.parse(selectedContent.data ?? "") as InteractiveVideoQuestionModel;
          $("#questionTextarea").val(questionData.text)
          if(this.answers[0] == undefined){
            this.answers[0] = "";
          }
          if(this.answers[1] == undefined){
            this.answers[1] = "";
          }
          if(this.answers[2] == undefined){
            this.answers[2] = "";
          }
          if(this.answers[3] == undefined){
            this.answers[3] = "";
          }
          console.log(questionData);
          $("#answer1").val(questionData.answers[0].text)
          $("#answer2").val(questionData.answers[1].text)
          $("#answer3").val(questionData.answers[2].text)
          $("#answer4").val(questionData.answers[3].text)
          var correctAnswer = questionData.answers.findIndex(x=>x.correct == true);
          if(correctAnswer != -1){
            ($("[name=correct]").get(correctAnswer) as HTMLInputElement).checked = true;
          }
          this.questionText = questionData.text;
          this.answers = [];
          questionData.answers.forEach(answer=>{
            this.answers.push(answer.text);
          })
          this.setQuestion(id,true);
          break;
      }
    }

  }
  completed(){
    var interactiveVideoModel:InteractiveVideoModel = {
      contents:this.interactiveContents,
      sections:this.interactiveSections,
      id:this.generateGuid()
    }
    console.log(interactiveVideoModel);
  }

}
