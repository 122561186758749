import { FormGroup } from '@angular/forms';
import { FormPage } from './../../models/CustomFormModels/formPage';
import { CustomForm } from './../../models/CustomFormModels/customForm';
import { HttpClient } from '@angular/common/http';
import { StructureTableCommandsModel } from './../../models/CustomTableModels/structureTableCommandsModel';
import { Router } from '@angular/router';
import { FormResponseObject } from './../../models/CustomFormModels/formResponseObject';
import { StructureFieldsModel } from './../../models/CustomTableModels/structureFieldsModel';
import { ToastrService } from 'ngx-toastr';
import { CustomTableService } from './../../services/customTable/custom-table.service';
import { CustomTableMainModel } from './../../models/CustomTableModels/customTableMainModel';

import { Component, Input, OnInit, Sanitizer, SecurityContext, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { StructureRowCommandsModel } from '../../models/CustomTableModels/structureRowCommandsModel';
import { PagerModeEnum } from '../../models/CustomTableModels/PagerModeEnum';
import { last, Observable } from 'rxjs';
import { FormElement } from '../../models/CustomFormModels/formElement';
import { ModalCreateModel } from '../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../services/globalModal/global-modal.service';
import { CustomTablePagerDataModel } from '../../models/CustomTableModels/customTablePagerDataModel';
import { FormattedDatePipe } from 'projects/usorta-library/src/pipes/formatted-date.pipe';
import { DatePipe } from '@angular/common';
declare var $:any;
@Component({
  selector: 'lib-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class CustomTableComponent implements OnInit {
  @Input() tableName = "";
  @Input() externalAPIUrl:string = "";
  loading:boolean = true;
  pageLoading:number = 0;
  customTableMain:CustomTableMainModel|null = null;
  customStyle = "color:red; font-size:20px; font-family:sans-serif;"
  popups = new Map<string,any>();
  modalData:string = "";
  modalLoader:boolean = true;
  pages:number[] = [];
  oldPageData:Map<number,any[]> = new Map<number,any[]>();
  pageData:Map<number,any[]> = new Map<number,any[]>();
  availabePages:number[] = [];
  shownPage:number = 1;
  lastResponse:CustomTableMainModel;
  totalPage:number;
  ascendingIcon = "bi bi-arrow-up";
  descendingIcon = "bi bi-arrow-down";
  sortParams:Map<string,string> = new Map<string,string>();
  defaultSortParams:Map<string,string> = new Map<string,string>();
  searchParams:string | null = null;
  searchOptions:CustomForm | null;
  searchOptionsElements:FormElement[];
  tableModal = document.getElementsByClassName("table-modal")[0];
  globalModalObject:ModalCreateModel;
  apiTableResponse:CustomTableMainModel;
  constructor(private globalModalService:GlobalModalService, private customTableService:CustomTableService,private toastrService:ToastrService,private sanitizer:DomSanitizer,private router:Router,private httpClient:HttpClient) { }
  ngOnChanges(changes:SimpleChanges){
    console.log("**************************");
    console.log(changes);
    if(changes['tableName'] !== undefined){
      this.externalAPIUrl = "";
      this.customTableService.externalUrl = "";
    }
    this.getTable();
  }
  ngOnInit(): void {
    console.log("global External Api");
    console.log(this.externalAPIUrl);

    this.getTable();

  }

  getTable(page?:number):Promise<boolean>{
    if(this.externalAPIUrl != ""){
      this.customTableService.externalUrl = this.externalAPIUrl;
    }
    return new Promise((resolve)=>{
      if(this.pageLoading != 0){
        resolve(false);
      }
      this.pageLoading = page ?? 1;
      let request:Observable<CustomTableMainModel> = new Observable<CustomTableMainModel>();
      let queryString = "";
      if(page){
        queryString = "page="+page;
      }else{
        queryString = "page=1";
        this.shownPage = 1;
      }
      if(this.sortParams.size > 0){
        let sp = this.lastResponse.interaction.sortOptions.sortParameter
        let sortParamName = sp != undefined && sp != ""? sp : "sortBy";
        queryString = this.createQueryParamsFromMap(sortParamName,this.sortParams,queryString);
      }
      if(this.searchParams != null && this.searchParams != ""){
        queryString += this.searchParams;
      }

      console.log("Query Params : "+queryString);
      request = this.customTableService.getTable(this.tableName,queryString);
      request.subscribe({
        next:(response)=>{
          console.log(response);
          var req = response as any;
          if(req['content'] != null){
            response = req['content'] as CustomTableMainModel;
          }
          if((response === null || response.data.length == 0) && this.lastResponse != null){
            resolve(false);
            this.loading = false;
            this.pageLoading = 0;
            throw "";
          }
          this.customTableMain = response;
          this.lastResponse = response;
          this.apiTableResponse = Object.assign({},response);
          /*if(response['pagerData'] == undefined){
            //var pager:CustomTablePagerDataModel = {currentPage:1,nextPageLabel:"Sonraki Sayfa",prevPageLabel:"Önceki Sayfa",pagerMode:PagerModeEnum.FULL,pageCount:response.data.length,totalCount:response.data.length,pageSize:response.data.length,pageParam:"page"}
            //response['pagerData'] = pager;
          }*/
          /*let pageCount = Math.ceil(response.pagerData.totalCount / response.pagerData.pageCount);
          for(let i=1;i<=pageCount;i++){
            this.pages[i-1] = i;
          } */

          let pageCount = 0;
          if(this.lastResponse.pagerData === null){
            pageCount = this.lastResponse.data.length;
            var pd:CustomTablePagerDataModel = {
              pagerMode: PagerModeEnum.FULL,
              totalCount: this.lastResponse.data.length,
              pageSize: this.lastResponse.data.length,
              pageCount: 1,
              currentPage: 1,
              nextPageLabel: '>',
              prevPageLabel: '<',
              pageParam: 'page'
            }
            this.lastResponse.pagerData = pd;
          }else{
            pageCount = this.lastResponse.pagerData.pageCount ?? this.lastResponse.data.length;
            this.updatePagesArray();
          }
          if(this.lastResponse.pagerData.currentPage != page && page !== undefined && page > 1){
            this.loading = false;
            this.pageLoading = 0;
            resolve(false);
            return;
          }
          console.log("pageCount = ",pageCount);
          this.totalPage = response.pagerData.totalCount / response.pagerData.pageSize;
          let donguSay = Math.ceil(pageCount / response.pagerData.pageSize);
          let dPage = 0;
          for(let i=0;i<donguSay;i++){
            dPage += 1;
            //console.log(dPage);
            let currentData:any[] = [];
            for(let j=0;j<response.pagerData.pageSize;j++){
              //console.log(j + " = "+ ((dPage-1)*response.pagerData.pageSize)+j)
              if(response.data[((dPage-1)*response.pagerData.pageSize)+j] != null){
                currentData[j] = response.data[((dPage-1)*response.pagerData.pageSize)+j];
              }

            }
            //console.log("set Ok");
            //console.log(dPage);
            //console.log(currentData);
            this.pageData.set(response.pagerData.currentPage+(dPage-1),currentData)
            let pageIsAvailabe = this.availabePages.find(x=>x==response.pagerData.currentPage+(dPage-1));
            if(pageIsAvailabe == undefined){
              this.availabePages.push(response.pagerData.currentPage+(dPage-1));
              console.log(this.availabePages);
            }
          }
          this.loading = false;
          this.pageLoading = 0;
          this.updateCSS();
          this.updateSortParams();
          this.updateSearchOptions();
          this.oldPageData = Object.assign(this.pageData);
          resolve(true);

        },
        error:(err)=>{
          //this.toastrService.error("Bir hata oluştu");
          this.loading = false;
          this.pageLoading = 0;
          resolve(false);
        }
      })
    })

  }
  getSearchForm(csForm:FormGroup){
    let params = "";
    for (const field in csForm.controls) {
      if(csForm.get(field)?.value && csForm.get(field)?.value != "" && csForm.get(field)?.value != undefined && csForm.get(field)?.value != ","){
        params = params != "?" ? params+"&" : params;
        params+=field+"="+csForm.get(field)?.value;
      }
    }
    if(params != ""){
      this.searchParams = params;
      //this.pages = [];
      this.availabePages = [];
      this.pageData = new Map<number,any[]>();
      this.getTable();

    }else if(params == "" && (this.searchParams != "" && this.searchParams != null)){
      this.searchParams = params;
      //this.pages = [];
      this.availabePages = [];
      this.pageData = new Map<number,any[]>();
      this.getTable();
    }

    console.log(params)
  }
  updateSearchOptions(){
    if(this.lastResponse["interaction"] !== null){
      if(this.lastResponse.interaction.searchOptions && this.lastResponse.interaction.searchOptions.fields && this.lastResponse.interaction.searchOptions.fields.length > 0){
        console.log("---------------------------------");
        
        this.searchOptionsElements = this.lastResponse.interaction.searchOptions.fields;
        let formPage:FormPage[] = [{title:"",requireValidation:false,elements:this.searchOptionsElements,layout:"3column",showReset:false}];
  
        let createdObject:CustomForm = {method:"get",action:"#",enctype:"",submitPerPage:false,pages:formPage,pageNavigation:"bottom",buttons:[{button:"submitButton",localizationKey:"btn.filter"}]};
        //let createdObject:CustomForm = {method:"GET",action:"#",enctype:"",submitPerPage:false,pages:formPage,pageNavigation:"bottom"};
        this.searchOptions = {...createdObject}
        console.log(this.searchOptions)
        //this.searchOptions =
  
      }
    }


  }
  changeSort(field:StructureFieldsModel){
    if(field.sortable){
      if(this.lastResponse.interaction.sortOptions.allowSortOnMulti == false){
        this.sortParams.forEach((val,key,map)=>{
          if(key != field.name){
            this.sortParams.delete(key);
          }
        })
      }
      let currentFieldSortParam = this.sortParams.get(field.name);
      if(currentFieldSortParam != undefined){
        let sortNow = currentFieldSortParam == "A" ? "D" : "A";
        this.sortParams.set(field.name,sortNow);
      }else{
        var defaultSort = this.defaultSortParams.get(field.name);
        let sortNow = defaultSort == "A" ? "D" : "A";
        this.sortParams.set(field.name,sortNow);
      }
    }
    this.pages = [];
    this.availabePages = [];
    this.pageData = new Map<number,any[]>();
    this.getTable();

  }
  showPageLoader(page:number){
    if(this.pageLoading == page){
      return true;
    }
    return false;
  }
  updateSortParams(){
    if(this.lastResponse != undefined &&
      this.lastResponse.interaction != undefined &&
       this.lastResponse.interaction.sortOptions != undefined &&
        this.lastResponse.interaction.sortOptions.defaultSort != undefined &&
        this.lastResponse.interaction.sortOptions.defaultSort != ""){
   var opts = this.lastResponse.interaction.sortOptions.defaultSort;
   var splittedOpts = opts.split(",");
   splittedOpts.forEach(splittedOpt =>{
     var sp = splittedOpt.split(":")
     sp[0] = sp[0].trim();
     sp[1] = sp[1].trim();
     this.defaultSortParams.set(sp[0],sp[1]);
   })
  }
    if(this.lastResponse != undefined &&
       this.lastResponse.interaction != undefined &&
        this.lastResponse.interaction.sortOptions != undefined &&
         this.lastResponse.interaction.sortOptions.currentSort != undefined &&
         this.lastResponse.interaction.sortOptions.currentSort != ""){
    var opts = this.lastResponse.interaction.sortOptions.currentSort;
    var splittedOpts = opts.split(",");
    splittedOpts.forEach(splittedOpt =>{
      var sp = splittedOpt.split(":")
      sp[0] = sp[0].trim();
      sp[1] = sp[1].trim();
      this.sortParams.set(sp[0],sp[1]);
    })
    }


  }
  getSortParams(field:StructureFieldsModel){
    if(field.sortable == true){
      var sp = this.sortParams.get(field.name);
      var defsp = this.defaultSortParams.get(field.name);
      if(sp != undefined){
        if(sp == "A"){
          return this.ascendingIcon;
        }else{
          return this.descendingIcon;
        }
      }else if(defsp != undefined){
        if(this.sortParams.size == 0){
          if(defsp == "A"){
            return this.ascendingIcon;
          }else{
            return this.descendingIcon;
          }
        }

      }

    }
    return "";
  }
  createQueryParamsFromMap(paramName:string,params:Map<string,string>,oldQueryString?:string){
    let param = "";
    if(oldQueryString){
      param=oldQueryString+"&";
    }
    param = param + paramName+"=";
    let counter = 0;
    params.forEach((val,key)=>{
      if(counter != 0){
        param+=",";
      }
      counter = 1;
      param+=key+":"+val;
    })
    return param;
  }
  updateCSS(){
    let headSel = document.getElementsByTagName("head");
    let head = headSel[headSel.length-1];
    var styles = document.createElement("style");
    styles.innerText = this.lastResponse.styleData.rawStyle;
    head.appendChild(styles);
  }
  updatePagesArray(){
    if(this.lastResponse.pagerData.pagerMode == PagerModeEnum.FULL){
      let pageCount = Math.ceil(this.lastResponse.pagerData.totalCount / this.lastResponse.pagerData.pageSize);
      for(let i=1;i<=pageCount;i++){
        this.pages[i-1] = i;
      }
    }else if(this.lastResponse.pagerData.pagerMode == PagerModeEnum.COMPACT){
      let pageCount = Math.ceil(this.lastResponse.pagerData.totalCount / this.lastResponse.pagerData.pageSize);
      if(this.shownPage == 1){
        this.pages = [];
        this.pages[0] = 1;
        for(let i=2;i<6;i++){
          if(pageCount > i+1){
            this.pages[i-1] = i;
          }
        }
      }else if(this.shownPage == 2){
        this.pages = [];
        this.pages[0] = 1;
        this.pages[1] = 2;
        for(let i=3;i<6;i++){
          if(pageCount > i+1){
            this.pages[i-1] = i;
          }
        }
      }else if(this.shownPage > 2){
        let maxPage = Math.ceil(this.lastResponse.pagerData.totalCount / this.lastResponse.pagerData.pageSize);
        this.pages = [];
        if(maxPage >= this.shownPage+2){
          this.pages[0] = this.shownPage-2;
          this.pages[1] = this.shownPage-1;
          this.pages[2] = this.shownPage;
          this.pages[3] = this.shownPage+1;
          this.pages[4] = this.shownPage+2;
        }else if(maxPage >= this.shownPage+1){
          this.pages[0] = this.shownPage-3;
          this.pages[1] = this.shownPage-2;
          this.pages[2] = this.shownPage-1;
          this.pages[3] = this.shownPage;
          this.pages[4] = this.shownPage+1;
        }else if(maxPage == this.shownPage){
          this.pages[0] = this.shownPage-4;
          this.pages[1] = this.shownPage-3;
          this.pages[2] = this.shownPage-2;
          this.pages[3] = this.shownPage-1;
          this.pages[4] = this.shownPage;
        }

      }
    }

  }
  showPageData(){
    return this.pageData.size !=0 ? this.pageData.get(this.shownPage) : this.oldPageData.get(this.shownPage);
  }
  changePage(page:number){
    if(this.availabePages.find(x=>x==page) != undefined){
      this.shownPage = page;
      console.log(this.shownPage);
      console.log("Sayfa No")
    }else{
      this.getTable(page).then(x=>{
        if(x==true){
          this.changePage(page);
        }else{
          //alert("Bir hata oldu");
        }
      });
    }
    this.updatePagesArray();

  }
  nextPageIcon(){
    if(this.lastResponse.pagerData.pagerMode != PagerModeEnum.PREV_NEXT_ONLY && this.lastResponse.pagerData.totalCount != null){
    let maxPage = this.lastResponse.pagerData.totalCount / this.lastResponse.pagerData.pageSize;
    if(maxPage > this.shownPage){
      return "pointer";
    }else{
      return "not-allowed";
    }
    }else{
      return "pointer";
    }
  }
  prevPageIcon(){
    if(this.shownPage > 1){
      return "pointer";
    }else{
      return "not-allowed";
    }
  }
  nextPage(){
    if(this.lastResponse.pagerData.pagerMode != PagerModeEnum.PREV_NEXT_ONLY && this.lastResponse.pagerData.totalCount != null){
      let maxPage = this.lastResponse.pagerData.totalCount / this.lastResponse.pagerData.pageSize;
      if(maxPage > this.shownPage){
        this.changePage(this.shownPage+1);
      }
    }else{
      this.changePage(this.shownPage+1);
    }


  }
  prevPage(){
    if(this.shownPage > 1){
      this.changePage(this.shownPage-1);
    }
  }
  createProgress(num:number){
    var mainDiv = document.createElement("div");
    mainDiv.classList.add("progress");
    var progressDiv:HTMLDivElement = document.createElement("div");

    progressDiv.classList.add("progress-bar");
    progressDiv.setAttribute("role","progressbar");
    var result = num+"%";

    progressDiv.style.width = result;
    progressDiv.setAttribute("aria-valuenow",num.toString());
    progressDiv.setAttribute("aria-valuemin","0");
    progressDiv.setAttribute("aria-valuemax","100");
    mainDiv.appendChild(progressDiv);
    return this.sanitizer.bypassSecurityTrustHtml(mainDiv.outerHTML) ?? "";
  }
  getTableData($event:any,field:StructureFieldsModel,tableData:any){
    /*
    DateTime,
    DateOnly,
    TimeOnly,
    Integer,
    Decimal,
    Currency/Money,
    Percentage (misal 0.25 geldiyse 25% basmak),
    Progress (0-100 arası int değeri progress bar olarak gösterme)
    */

   let data;
   let readedData = tableData[field.name];
   let dataType = field.dataType.toLocaleLowerCase();
   if(dataType == "string" || dataType == undefined){
    data = readedData;
   }else if(dataType == "percentage"){
    data = '<font>'+readedData*100+"%"+'</font>';

   }else if(dataType == "progress"){
    data = this.createProgress(readedData);
    //console.log(data)
   }else if(dataType == "boolean"){
    data = readedData ? '<i class="bi bi-check-circle text-success"></i>' : '<i class="bi bi-slash-circle text-danger"></i>';
   }else if(dataType == "date"){
    if(readedData != null){
      
      var date = new Date(readedData);
      var datetime = new FormattedDatePipe("en-US").transform(date,"dt");
      
      //data = date.toLocaleDateString() +" "+ date.toLocaleTimeString(); 
      data = datetime;
    }else{
      data = ""
    }

  }else if(dataType == "dateonly"){
    if(readedData != null){
      var date = new Date(readedData);
      var datetime = new FormattedDatePipe("en-US").transform(date,"d");
      
      //data = date.toLocaleDateString() +" "+ date.toLocaleTimeString(); 
      data = datetime;
    }else{
      data = "";
    }

  }else if(dataType == "timeonly"){
    if(readedData != null){
      var date = new Date(readedData);
      var datetime = new FormattedDatePipe("en-US").transform(date,"t");
      
      //data = date.toLocaleDateString() +" "+ date.toLocaleTimeString(); 
      data = datetime;
    }else{
      data = "";
    }

  }
  else{
    data = readedData;
   }

   return data;
  }
  openFieldActionLink(tableData:any,field:StructureFieldsModel){
    let link = field.actionLink;
    if(link != null && link != ""){
      if(tableData != null){
        let objectKeys = Object.keys(tableData);
        objectKeys.forEach(objectKey=>{
          link = link.replace("{"+objectKey+"}",tableData[objectKey]);
        })
        if(link.startsWith("https://") || link.startsWith("http://")){
          window.location.href = link;
        }else{
          this.router.navigate([link]);
        }

      }
    }


  }
  fetchFilteredData(res:FormResponseObject){
    console.log("veri geldi");
    if(res.success){

    }
  }

  openLink(data:any,command:StructureRowCommandsModel | StructureTableCommandsModel){
    let link = command.url;
    if(data != null){
      let objectKeys = Object.keys(data);
      objectKeys.forEach(objectKey=>{
        link = link.replace("{"+objectKey+"}",data[objectKey]);
      })

    }

    console.log(link);
    var isExternal:boolean = false;
    if(link.startsWith('https') ||link.startsWith('http')){
      isExternal = true;
    }
    let popupName = "";
    var target = command.linkTarget.startsWith("&") ? "_popup" : command.linkTarget;
    console.log(target)
    if(target == "_popup"){
      popupName = command.linkTarget.substring(1);
    }
    switch(target){
      case "_self":
        if(isExternal){
          window.open(link,"_self");
        }else{
          this.router.navigate([link]);
        }
        break;
      case "_blank":
        window.open(link,"_blank");
        break;
      case "_modal":
        this.modalLoader = true;
        let globalModal:ModalCreateModel = {data:link,title:"USORTA",type:ModalTypes.LINK,helperModals:[{id:"modals",data:"test",title:"title"}]};
        var modal = this.globalModalService.showModal(globalModal) as any;
        modal.onclose.subscribe({
          next:(observer:any)=>{
            console.log("kapandı");
          }
        })
        /*let iframe = document.createElement("iframe");
        iframe.src = link;
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.style.overflow = "hidden";
        iframe.style.overflowY = "hidden";
        iframe.style.display = "inline-block";
        let modalDataElement:HTMLDivElement = document.getElementById("modalData") as HTMLDivElement;
        modalDataElement.innerHTML = "";
        modalDataElement?.appendChild(iframe);
        let hiddenBtn = document.createElement('button');
        hiddenBtn.setAttribute("data-bs-target","#staticBackdrop");
        hiddenBtn.setAttribute("data-bs-toggle","modal");
        hiddenBtn.style.display = "none";
        hiddenBtn.innerHTML = "open modal"
        document.getElementsByTagName("body")[0].append(hiddenBtn);
        hiddenBtn.click();
        iframe.onload = () => {
          var ifBody = $(iframe).contents().find("body").get(0);
          ifBody.addEventListener("click",(ev:Event)=> {
            if($(ev.target).hasClass("modal-close-btn")){
              $(".table-modal").find(".table-modal-x").get(0).click();
            }

          })
        }*/
        break;
        case "_wideModal":
          this.modalLoader = true;
          
          let globalModal1:ModalCreateModel = {data:link,title:"USORTA",type:ModalTypes.LINK,helperModals:[{id:"modals",data:"test",title:"title"}]};
          var modal = this.globalModalService.showModal(globalModal1,true,"95%");
          modal.onclose.subscribe({
            next:(observer:any)=>{
              console.log("kapandı");
            }
          })
          break;
      case "_popup":
        this.popups.set(popupName,open(link,popupName,"width=500,height=500"));
        this.popups.get(popupName).focus();
        console.log("popup");
        break;
      default:

    }
  }
  getPageData(url:string):Promise<string>{
    console.log("calisti");
    return new Promise<string>(resolve=>{
      this.httpClient.get(url,{observe:"body", responseType:"text"}).subscribe({
        next:(response)=>{
          console.log(response);
          resolve(response);
        },
        error:(err)=>{
          resolve(err);
        }
      })
      resolve('');
    })

  }

}
