import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-outline-management-panel',
  templateUrl: './outline-management-panel.component.html',
  styleUrls: ['./outline-management-panel.component.css']
})
export class OutlineManagementPanelComponent implements OnInit {
  leftDrag:boolean = false;
  rightDrag:boolean = false;
  previewId:string[] =[];
  selectedContent:string[] = [];
  versionId:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
          this.versionId = param["version"]; 
        }
    })
  }
  outlinePreview(id:string){
    this.previewId = [];
    this.previewId.push(id);
    console.log(id)
  }
  startDrag(position:string){
    if(position == "left"){
      this.leftDrag = true;
      this.rightDrag = false;
    }else{
      this.rightDrag = true;
      this.leftDrag = false;
    }
  }
  stopDrag(){
    this.leftDrag = false;
    this.rightDrag = false;
  }
  dragProcess(event:MouseEvent){
    var page = document.getElementById("area-container");
    var leftArea = document.getElementById("left-area");
    var centerArea = document.getElementById("center-area");
    var rightArea = document.getElementById("right-area");
    if(this.leftDrag){
      var clientX = event.clientX;
      if(leftArea && page){
        
        leftArea.style.width = clientX+"px";
        console.log("calisiyor")
      }

    }else if(this.rightDrag){
      console.log("rdrag")
      var clientX = event.clientX;
      if(rightArea && page){
        var pageX = page.clientWidth || page.offsetWidth;
        console.log(pageX);
        rightArea.style.width = (pageX-clientX)+"px";
      }
    }
  }

setSelectedContent(id:string){
  this.selectedContent = [];
  this.selectedContent.push(id);
}
updatePreviewArea(){
  var tempId = this.previewId[0];
  this.previewId = [];
  setTimeout(()=>{
    this.previewId = [tempId];
  },50)

}
}
