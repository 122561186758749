import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CategoryTreeOptionsFlag } from 'projects/usorta-library/src/lib/models/Education/Enum/categoryTreeFlags';
import { AddCategoryTreeModel } from 'projects/usorta-library/src/lib/models/Education/addCategoryTreeModel';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';

@Component({
  selector: 'lib-add-category-tree',
  templateUrl: './add-category-tree.component.html',
  styleUrls: ['./add-category-tree.component.css']
})
export class AddCategoryTreeComponent implements OnInit {
  addCategoryTreeForm:FormGroup;
  loading:boolean = false;
  categoryTreeOptions = Object.entries(CategoryTreeOptionsFlag).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]}
  }).filter(x=>x.value != 0);
  constructor(private educationService:EducationService,private formBuilder:FormBuilder,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.createCategoryTreeForm();
  }
  createCategoryTreeForm(){
    this.addCategoryTreeForm = this.formBuilder.group({
      name:["",Validators.required],
      description:[""],
      active:[false]
    })
  }
  optionsValue(){
    var val = 0;
    var checks = document.getElementsByClassName("optionsCheckbox");
    for (let index = 0; index < checks.length; index++) {
      const checkbox = checks[index] as HTMLInputElement;
      if(checkbox.checked){
        val+= parseInt(checkbox.value)
      }
    }
    return val;
  }
  addCategoryTree(){
    if(this.addCategoryTreeForm.valid){
      var addCategoryTreeModel:AddCategoryTreeModel = Object.assign({},this.addCategoryTreeForm.value);
      addCategoryTreeModel.options = this.optionsValue();
      console.log(addCategoryTreeModel);
      this.loading = true;
      this.educationService.createCategoryTree(addCategoryTreeModel).subscribe({
        next:(response)=>{
          console.log(response);
          this.toastrService.success("categoryTrees.addedSuccessfully");
          this.loading = false;
        },error:(err)=>{
          this.loading = false;
          this.toastrService.error("global.errorOccurred");
        }
      })
    }else{
      this.loading = false;
      this.toastrService.error("global.pleaseCheckForm");
    }
  }

}
