<div *ngIf="questionDetails" class="formMain form-default-border form-maroon-border">
    <h5 style="display: inline-block; margin-right: 5px;">Temel Soru Detayları</h5><span style="cursor: pointer;" [routerLink]="'/lms/question/'+questionId+'/update'" class="badge bg-warning text-dark"><i class="bi bi-pencil-square"></i> Soruyu Düzenle</span>
    <hr>
    <div class="basicDetailsContainer">
        <div class="basicDetailItem">

            <b>Soru Adı : </b>
            <font>{{questionDetails.name}}</font>

        </div>

        <div class="basicDetailItem">

            <b>Tip : </b>
            <font>{{questionTypes[questionDetails.type].key}}</font>

        </div>

        <div class="basicDetailItem">

            <b>Soru Metni : </b>
            <lib-preview-text [allowHtml]="true" [text]="questionDetails.questionText"></lib-preview-text>

        </div>

        <div class="basicDetailItem" *ngIf="questionFormat">

            <b>Format : </b>
            <font>{{questionFormat[questionDetails.format].key}}</font>

        </div>

        <div class="basicDetailItem">

            <b>Kategori : </b>
            <font> - </font>

        </div>

        <div class="basicDetailItem">

            <b>Dil : </b>
            <font>{{questionDetails.locale}}</font>

        </div>

        <div class="basicDetailItem">

            <b>Zorluk Derecesi : </b>
            <font>{{questionDetails.difficulty}}</font>

        </div>

        <div class="basicDetailItem">

            <b>Aktiflik : </b>
            <input type="checkbox" [readOnly]="true" onclick="return false" [checked]="questionDetails.active" />

        </div>

        <div class="basicDetailItem">

            <b>Soru Kümeleri : </b>
            <font *ngFor="let set of questionDetails.sets | keyvalue"><b><span class="badge bg-secondary">{{set.value}}</span></b>&nbsp;</font>

        </div>

        <div class="basicDetailItem">

            <b>Yanlış Cevap Açıklaması : </b>
            <lib-preview-text [allowHtml]="true" [text]="questionDetails.wrongAnswerInfo"></lib-preview-text>

        </div>

        <div class="basicDetailItem" *ngIf="questionFormat">

            <b>Yanlış Cevap Formatı : </b>
            <font>{{questionFormat[questionDetails.wrongAnswerInfoFormat].key}}</font>

        </div>
        <div class="basicDetailItem" *ngIf="questionOptions">
            <b>Seçenekler </b>
            <div class="options">
                <label class="optionsLabel" *ngFor="let option of questionOptions">
                    <input type="checkbox"
                        [checked]="optionChecker(option.value)" [disabled]="optionChecker(option.value) == false" onclick="return false"
                        [readOnly]="true">{{option.key}}</label>
            </div>
        </div>
    </div>
</div>
<div *ngIf="questionDetails" class="formMain form-default-border form-maroon-border">
    <h5>Soru Tipine Dayalı Özellikler</h5>
    <hr>
    <div class="questionTypeDetailsContainer">
        <div class="questionTypeDetail trueFalse" *ngIf="questionDetails.type == 0">
            <b style="vertical-align: text-bottom;">Doğruluk : </b><input type="checkbox" [readOnly]="true" onclick="return false" [checked]="questionDetails.trueFalseAnswer">
        </div>
        <div class="questionTypeDetail multipleChoices" *ngIf="questionDetails.type == 1">
            <div class="typeDetailItem">

                <b>Seçilebilecek Minimum Seçenek Sayısı : </b>
                <font>{{questionDetails.minNumChoices}}</font>
    
            </div>
            <div class="typeDetailItem">

                <b>Seçilebilecek Maksimum Seçenek Sayısı : </b>
                <font>{{questionDetails.maxNumChoices}}</font>
    
            </div>
            <hr>
            <lib-custom-table *ngIf="tableUrl" [externalAPIUrl]="tableUrl"></lib-custom-table>
        </div>
        <div class="questionTypeDetail shortAnswer" *ngIf="questionDetails.type == 2">
            <div class="typeDetailItem">

                <b>Kısa Soru Yanıtı (Regex) : </b>
                <font>{{questionDetails.shortAnswerRegex}}</font>
    
            </div>

            <div class="typeDetailItem">

                <b>Minimum Eşleşme Skoru : </b>
                <font>{{questionDetails.shortAnwserMinMatchScore}}</font>
    
            </div>
        </div>
    </div>
</div>
<div class="questionTranslations" *ngIf="translationsTable">
    <lib-custom-table *ngIf="translationsTable" [externalAPIUrl]="translationsTable"></lib-custom-table>
</div>