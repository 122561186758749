import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-vm-environment-list',
  templateUrl: './vm-environment-list.component.html',
  styleUrls: ['./vm-environment-list.component.css']
})
export class VmEnvironmentListComponent implements OnInit {
  apiUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
  }

}
