import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionApiService } from '../../../services/session/session-api.service';
import { AddSessionMemberRequest, AddSessionMemberRequestUserInfo } from '../../../models/SessionApiModels/addSessionMemberRequest';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-session-management-add-member-to-session',
  templateUrl: './session-management-add-member-to-session.component.html',
  styleUrls: ['./session-management-add-member-to-session.component.css']
})
export class SessionManagementAddMemberToSessionComponent implements OnInit {
  memberAddForm:FormGroup;
  sessionId:string;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder,private sessionApiService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.createForm();
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.sessionId = param['sessionId'];
      }
    })

  }
  createForm(){
    this.memberAddForm = this.formBuilder.group({
      id: ["",Validators.required],
      displayNameOverride:[],
      roles:[]
    })
  }
  submitForm(){
    if(this.memberAddForm){
      console.log(this.memberAddForm.value)
      var obj:AddSessionMemberRequestUserInfo = Object.assign({},this.memberAddForm.value);
      var postData:AddSessionMemberRequest = {users:[obj]};
      this.sessionApiService.addMemberToSession(this.sessionId,postData).subscribe({
        next:(response)=>{
          this.toastrService.success("Katılımcı eklendi");
        },error:(err)=>{
          this.toastrService.error("Katılımcı eklenemedi");
        }
      })
    }else{
      console.log()
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
