import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ToastrService } from 'ngx-toastr';
import { QuestionTypesConst, QuestionTypesEnum } from '../../../models/questionManagementModels/questionTypesEnum';
import { QuestionDetailModel } from '../../../models/questionManagementModels/questionDetailModel';
import { QuestionFormatConst } from '../../../models/questionManagementModels/questionFormatEnum';
import { QuestionOptionEnum } from '../../../models/questionManagementModels/questionOptionsEnum';
import { QuestionModel } from '../../../models/questionManagementModels/questionModel';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.css']
})
export class QuestionDetailsComponent implements OnInit,OnChanges {
  questionId:number;
  questionDetails:QuestionModel;
  questionTypes:{key:string,value:number}[];
  questionOptions:{key:string,value:number}[];
  questionFormat:{key:string,value:number}[];
  tableUrl:string | undefined;
  translationsTable:string | undefined;
  constructor(private questionService:QuestionService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private apiUrlService:ApiUrlService) { }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.questionTypes = Object.entries(QuestionTypesConst).map((val,index,arr)=>{
      return {key:arr[index][0],value:arr[index][1]}
    });
    this.questionOptions = Object.entries(QuestionOptionEnum).map((val,index,arr)=>{
      return {key:arr[index][0],value:arr[index][1]}
    });
    this.questionFormat   = Object.entries(QuestionFormatConst).map((val,index,arr)=>{
      return {key:arr[index][0],value:arr[index][1]} 
    });
    this.getQuestionId();
  }
  getQuestionId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = parseInt( param["questionId"] )
        this.getQuestionDetails();
        this.tableUrl = "";
        this.translationsTable = "";
        setTimeout(()=>{
          this.tableUrl = this.apiUrlService.getApiUrl() + "api/lms/assessment/questions/"+this.questionId+"/options";
          this.translationsTable = this.apiUrlService.getApiUrl() + "api/lms/assessment/questions/"+this.questionId+"/translations";
        },250);

      }
    })
  }
  getQuestionDetails(){
    this.questionService.getQuestion(this.questionId).subscribe({
      next:(response)=>{
        this.questionDetails = response.content;
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

  getQuestionTypeName(type:number){
    return this.questionTypes.find(x=>x.value == type)?.value ?? type.toString();
  }
  optionChecker(requestedOption:number){
    if(requestedOption == 0 && this.questionDetails.options == 0){
      return true;
    }
    if(this.questionDetails.options & requestedOption){
      return true;
    }
    return false;
  }

}
