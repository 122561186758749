<div class="selectAddType" *ngIf="addTypeSelected == false">

    <div class="neworimage">
        <div class="form-maroon-border form-default-border" style="background-color:white; padding:12px;">
        <h5>{{'setupVmInstance.createNewVMTitle' | translate}}</h5>
        <div class="createNewDiv" (click)="newInstallerImage()"><i class="bi bi-disc-fill"></i> {{'setupVmInstance.createFromScratch' | translate}}</div>
        <br/><br/>
        <div class="selectBaseImageDiv" (click)="selectBaseImage()"><i class="bi bi-pc-display"></i> {{'setupVmInstance.createFromBaseImage' | translate}}</div>
    </div>
    </div>
</div>
<lib-custom-form [class.displayNone]="addTypeSelected == false" [actionApiUrl]="baseUrl" (GetSendingObjects)="getObj($event)" [formTopElement]="externalForm" [formBottomElement]="formBaseBottom"
    [externalAPIUrl]="formUrl"></lib-custom-form>

<div style="display: none;">
    <div #externalForm style="width: 100%;">

        <label class="formLabel" style="width: 100%;">
          <b class="labelColor">Select Host</b>  
            <select #hostElement id="hostId" (change)="changeHost($event)" name="hostId" class="form-select"
                aria-label="Default select example">
                <option *ngFor="let host of hosts" [value]="host.value">{{host.label}}</option>
            </select>
        </label>

        <label class="formLabel" style="width: 100%;">
            <b class="labelColor">Select Instance Type</b>
            <select #instanceTypeElement id="instanceTypeId" name="instanceTypeId" class="form-select"
                aria-label="Default select example">

                <option *ngFor="let instance of instanceTypes" [value]="instance.value">{{instance.label}}</option>

            </select>
        </label>
        <label [class.displayNone]="selectBaseImg == true" class="formLabel" style="width: 100%;">
            <b class="labelColor">Select Installer Image</b>
            <select #instanceTypeElement id="installerImageId" name="installerImageId" class="form-select"
                aria-label="Default select example">

                <option *ngFor="let installerImage of installerImages" [value]="installerImage.value">{{installerImage.label}}</option>

            </select>
        </label>
        <label [class.displayNone]="selectBaseImg == false" class="formLabel" style="width: 100%;">
            <b class="labelColor">Select Base Image</b>
            <select #instanceTypeElement id="baseImageId" name="baseImageId" class="form-select"
                aria-label="Default select example">

                <option *ngFor="let baseImage of baseImages" [value]="baseImage.value">{{baseImage.label}}</option>

            </select>
        </label>
    </div>
    <div #formBaseBottom [class.displayNone]="selectBaseImg == false">
        <label class="formLabel labelColor"><b>Base VM Image Options</b></label>
        <br/>
        <label><input type="checkbox" id="copyBaseVmImageInfo" class="form-check-input"> {{'customform.strings.copyBaseVMImageInfo' | translate}}</label><br/>
        <label><input type="checkbox" id="copyBaseVMImageNotes" class="form-check-input"> {{'customform.strings.copyBaseVMImageNotes' | translate}}</label><br/>
        <label><input type="checkbox" id="copyBaseVMImageObjectives" class="form-check-input"> {{'customform.strings.copyBaseVMImageObjectives' | translate}}</label><br/>
        <label><input type="checkbox" id="copyBaseVMImageCustomization" class="form-check-input"> {{'customform.strings.copyBaseVMImageCustomization' | translate}}</label>
    </div>
</div>