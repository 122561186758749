<div class="terminalsMain" *ngIf="terminalsLoading || usersLoading">
    <div class="lmsSessionId">
        <b>{{'terminal.sessionAssignedTerminals' | translate}}</b>
        <hr/>
    </div>
    <div class="d-flex justify-content-center" >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
</div>

<div class="terminalsMain" *ngIf="users && terminals">
    <div class="lmsSessionId">
        <b>{{'terminal.sessionAssignedTerminals' | translate}}</b>
        <hr/>
    </div>
    <div class="terminalList">
        <div class="terminalItem" *ngFor="let terminal of terminals">
            <div class="vmName"> <i class="bi bi-pc-display"></i> <b> {{terminal.connectionName}}</b></div>
            <div class="users">
                <h6> <i class="bi bi-people-fill"></i> {{'terminal.assignedUsers' | translate}}</h6>
                <div class="userItem" *ngFor="let user of terminal.users">
                    <i class="bi bi-person"></i> {{getUsername(user)}}
                </div>
            </div>
            <div class="activeConnections" *ngIf="terminal.activeConnections.length > 0">
                <h6><i class="bi bi-hdd-network"></i> {{'terminal.connectedUsers' | translate}}</h6>
                <div class="activeConnectionItem" *ngFor="let activeConnection of terminal.activeConnections">
                    <div class="activeConnectionDetail"><div class="activeConnectionUsername"><b>Kullanıcı : </b> {{activeConnection.username}}</div>
                     <div class="activeConnectionShared"><b>{{'terminal.shared' | translate}} : </b>{{activeConnection.shared ? ('terminal.yes' | translate) : ('terminal.no' | translate)}}</div> <div class="activeConnectionStartDate"><b>{{'terminal.connectionTime' | translate}} : </b>{{activeConnection.startDate | date : 'dd.MM.YYYY HH:mm'}}</div></div>
                </div>
            </div>
        </div>
    </div>
</div>
