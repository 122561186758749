import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { CookieManagerService } from './../../services/cookieManager/cookie-manager.service';
import { SendCookiePreferenceItem } from './../../models/CookiePreferences/sendCookiePreferences';
import { CookiePreferencesModel } from './../../models/CookiePreferences/getCookiePreferences';
import { CookiePreferenceListenerService } from './../../services/listener/cookie-preference-listener.service';
import { CookiePreferencesService } from './../../services/cookiePreferences/cookie-preferences.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-cookie-preferences',
  templateUrl: './cookie-preferences.component.html',
  styleUrls: ['./cookie-preferences.component.css']
})
export class CookiePreferencesComponent implements OnInit {
  cookiePreferenceSubsribe:any;
  cookiePreferencesList:CookiePreferencesModel;
  customPreferences:SendCookiePreferenceItem[] = [];
  sendCookiePreferences:SendCookiePreferenceItem[] = [];
  clicked:boolean = false;
  @Input() defaultCustomize:boolean = false;
  @Input() defaultCustomizeItems:SendCookiePreferenceItem[] = [];
  constructor(private cookiePreferences:CookiePreferencesService,private cookieListener:CookiePreferenceListenerService,private cookieManagerService:CookieManagerService,private loginService:LoginService,private toastrService:ToastrService,private domSanitizer:DomSanitizer,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getCookiePreferences();
    console.log("cookies");
    if(this.defaultCustomize){
      this.defaultCustomizeCookies();
    }
  }
  customizeCookies(){
    this.cookiePreferencesList.preferences.forEach(element => {
      this.customPreferences.push({
        id:element.id,
        accepted: element.required ? true : false
      })
    });
  }
  defaultCustomizeCookies(){
    console.log(this.defaultCustomizeItems)
    this.customPreferences = this.defaultCustomizeItems;
  }
  saveCookiePreferences(clicked:boolean = false){
    this.cookiePreferences.saveCookiePreferences(this.customPreferences).subscribe();
    let val = "";
    this.customPreferences.forEach(element => {
      val != "" ? val += ",":"";
      val += element.id+":"+element.accepted;
    });
    this.addPreferencesToCookie(val);
    this.setCookieListener(true);
    if(clicked){
      this.toastrService.success(this.translateService.instant("cookiePreferences.saved"));
    }
  }

  toggle(prefrenceId:string){
    let preference = this.customPreferences.find(x=>x.id == prefrenceId);
    if(preference){
      preference.accepted = !preference.accepted;
    }
  }
  getPreferenceValue(preferenceId:string){
    let preference = this.customPreferences.find(x=>x.id == preferenceId);
    if(preference){
      return preference.accepted;
    }else{
      return false;
    }
  }
  getCookiePreferences(){
    this.cookiePreferences.getCookiePreferences().subscribe(data=>{
      this.cookiePreferencesList = data;
      console.log(data);
      if(this.defaultCustomize){
        this.defaultCustomizeCookies();
        if(this.customPreferences.length == 0){
          this.customizeCookies();
        }
      }
      this.isSaved();
    });
  }
  acceptAll(){
    this.sendCookiePreferences = [];
    this.cookiePreferencesList.preferences.forEach(element => {
      this.sendCookiePreferences.push({
        id:element.id,
        accepted:true
      });
    });
    this.cookiePreferences.saveCookiePreferences(this.sendCookiePreferences).subscribe();
    let val = "";
    this.sendCookiePreferences.forEach(element => {
      val != "" ? val += ",":"";
      val += element.id+":"+element.accepted;
    });
    this.addPreferencesToCookie(val);
    this.setCookieListener(true);
  }
  setCookieListener(state:boolean){
    if(this.cookiePreferenceSubsribe){
      this.cookiePreferenceSubsribe.unsubscribe();
    }
    this.cookiePreferenceSubsribe = this.cookieListener.setCookiePreferences(state).subscribe();
  }
  isSaved(){
    console.log(this.loginService.isLoggedControlWithExpiration())
    if(this.loginService.isLoggedControlWithExpiration() == false){
      if(this.getPreferencesCookieFromBrowser() != null && this.getPreferencesCookieFromBrowser() != ""){
        this.setCookieListener(true);
      }else{
        this.setCookieListener(false);
      }
    }else{
      this.cookiePreferencesList.preferences_saved
    }

  }
  addPreferencesToCookie(value:string){
    let date:Date = new Date();
    date.setFullYear(date.getFullYear()+1);
    this.cookieManagerService.setCookie({
      name: 'cookieprefs',
      value: value,
      expiration: date
    });
  }
  getPreferencesCookieFromBrowser(){
    return this.cookieManagerService.getCookie('cookieprefs');
  }

}
