import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-vm-sessions-list',
  templateUrl: './vm-sessions-list.component.html',
  styleUrls: ['./vm-sessions-list.component.css']
})
export class VmSessionsListComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var groupName = param["groupName"];
        this.tableUrl = this.apiUrlService.getApiUrl() + `api/terminal/admin/sessions/${groupName}/list`
      }
    })

  }

}
