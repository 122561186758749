import { Injectable } from '@angular/core';
import { AddLocaleRequest } from '../../models/Locales/addLocaleRequest';
import { ApiUrlService } from '../api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { UpdateLocaleRequest } from '../../models/Locales/updateLocaleRequest';
import { DeleteLocaleRequest } from '../../models/Locales/deleteLocaleRequest';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { LocaleUpdateDataResponse } from '../../models/Locales/localeUpdateDataResponse';
import { LocaleDetailsResponse } from '../../models/Locales/localeDetailsResponse';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  addLocale(addLocaleRequest:AddLocaleRequest){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/cms/admin/localization/locale/create`,addLocaleRequest);
  }
  updateLocale(localeId:string,updateLocaleRequest:UpdateLocaleRequest){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/cms/admin/localization/locale/${localeId}/update`,updateLocaleRequest);
  }
  deleteLocale(localeId:string,deleteLocaleRequest:DeleteLocaleRequest){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/cms/admin/localization/locale/${localeId}/delete`,{body:deleteLocaleRequest});
  }
  getLocaleUpdateData(localeId:string){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<LocaleUpdateDataResponse>>(`${apiUrl}api/cms/admin/localization/locale/${localeId}/update`);
  }
  getLocaleDetails(localeId:string){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<LocaleDetailsResponse>>(`${apiUrl}api/cms/admin/localization/locale/${localeId}`)
  }
  getLocalesWithSelectListSchema(){
    var apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/cms/admin/localization/locale/selectlist`)
  }
}
