import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
declare var window:any;
@Pipe({
  name: 'formattedDate'
})
export class FormattedDatePipe extends DatePipe implements PipeTransform {

  override transform(value: any, ...args: any[]): any {
    let format = "";
    let dateFormat = "dd/MM/YYYY";
    let timeFormat = "HH:mm";
    let dateTimeFormat = "dd/MM/YYYY HH:mm";
    let shortDateFormat = "dd-MM";
    if(window["dateFormat"] !== undefined){
      dateFormat = window["dateFormat"];
    }
    if(window["timeFormat"] !== undefined){
      timeFormat = window["timeFormat"];
    }
    if(window["dateTimeFormat"] !== undefined){
      dateTimeFormat = window["dateTimeFormat"];
    }
    if(window["shortDateFormat"] !== undefined){
      shortDateFormat = window["shortDateFormat"];
    }
    if(args[0] == "t"){
      format = timeFormat;
    }else if(args[0] == "d"){
      format = dateFormat;
    }else if(args[0] == "sd"){
        format = shortDateFormat;
    }else if(args[0] == "dt"){
      format = dateTimeFormat;
    }else{
      format = dateTimeFormat;
    }

    return super.transform(value,format);
  }

}
