<div class="main">
    <div class="translateIconDiv">
        <i class="bi bi-translate translateIcon"></i>
    </div>
    <div class="alert alert-info" role="alert">
            {{'cms.locales.deleteLocalizedStringMessage' | translate}}
    </div>
    <input type="checkbox" (change)="checkChanged($event)" id="understandCheck" name="understandCheck"/><label for="understandCheck">{{'global.understandCheckboxMessage' | translate}}</label>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="deleteLocalizedString()" [disabled]="checkedUnderstandCheck == false"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
