import { Component, OnInit } from '@angular/core';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { VmInstanceTypeMappingsInfoModel } from '../../../models/vmInstanceTypeMappings/vmInstanceTypeMappingsInfoModel';
import { ActivatedRoute } from '@angular/router';
import { ListItemModel } from '../../../models/ListItem/listItemModel';

@Component({
  selector: 'lib-manage-vm-instance-type-mapping',
  templateUrl: './manage-vm-instance-type-mapping.component.html',
  styleUrls: ['./manage-vm-instance-type-mapping.component.css']
})
export class ManageVmInstanceTypeMappingComponent implements OnInit {
  vmInstanceMappingInfo:VmInstanceTypeMappingsInfoModel;
  mappingId:string;
  ramList:ListItemModel[];
  constructor(private activatedRoute:ActivatedRoute,private vmInstanceService:VmInstanceService) { }

  ngOnInit(): void {
    this.getMappingId();
    this.getInfo();
    this.vmInstanceService.getRamList().subscribe({
      next:(response)=>{
        this.ramList = response.content;
      }
    })
  }
  getRamName(name:string | undefined){
    if(name != undefined){
      return this.ramList.find(x=>x.value == name)?.label ?? "-";
    }
    return "-";
  }
  getMappingId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.mappingId = param["mappingId"];
      }
    })
  }
  getInfo(){
    this.vmInstanceService.getInstanceTypeMappingInfo(this.mappingId).subscribe({
      next:(response)=>{
        this.vmInstanceMappingInfo = response.content;
      }
    })
  }

}
