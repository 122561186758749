import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-list-questions-by-question-set-id',
  templateUrl: './list-questions-by-question-set-id.component.html',
  styleUrls: ['./list-questions-by-question-set-id.component.css']
})
export class ListQuestionsByQuestionSetIdComponent implements OnInit {
  questionSetId:number;
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getQuestionSetId();
    this.tableUrl = this.apiUrlService.getApiUrl()+`api/lms/assessment/questions?questionSet=${this.questionSetId}`
  }
  getQuestionSetId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionSetId = parseInt(param["setId"]);
      }
    })
  }

}
