import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usernameFilter'
})
export class UsernameFilterPipe implements PipeTransform {

  transform(value: any[], username:string): any[] {
    return (username == null || username == "") ? value : value.filter(x=>((x.username)as string).toLocaleLowerCase().includes(username.toLocaleLowerCase()));
  }

}
