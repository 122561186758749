import { Component, OnInit } from '@angular/core';
import { MediaContentService } from '../../../services/mediaContent/media-content.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MediaContentDetailsResponse } from '../../../models/MediaContent/mediaContentDetailsResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-media-content-details',
  templateUrl: './media-content-details.component.html',
  styleUrls: ['./media-content-details.component.css']
})
export class MediaContentDetailsComponent implements OnInit {
  id:string;
  mediaContent:MediaContentDetailsResponse;
  constructor(private mediaContentService:MediaContentService,private translateService:TranslateService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        this.mediaContentService.getMediaContentDetails(this.id).subscribe({
          next:(response)=>{
            this.mediaContent = response.content;
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }
    })
  }

}
