import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostDashboardService } from '../../../services/hostDashboard/host-dashboard.service';
import { HostDashboardResponseModel } from '../../../models/DashboardModels/hostDashboardResponseModel';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartEmitterModel } from '../../../models/CustomChartModels/chartEmitterModel';
import { ChartConfiguration } from 'chart.js';
import { HostDashboardSwitchEmitterModel } from '../../../models/DashboardModels/hostDashboardSwitchEmitterModel';
import { ChartCreateModel } from '../../../models/CustomChartModels/chartCreateModel';

@Component({
  selector: 'lib-host-dashboard',
  templateUrl: './host-dashboard.component.html',
  styleUrls: ['./host-dashboard.component.css']
})
export class HostDashboardComponent implements OnInit {
  hostId: string;
  dashboardResponse: HostDashboardResponseModel;

  createChartModels:any = Array();
  baseCharts: ChartEmitterModel[] = [];
  switches: HostDashboardSwitchEmitterModel[] = [];
  constructor(private translateService: TranslateService, private toastrService: ToastrService, private activatedRoute: ActivatedRoute, private hostDashboardService: HostDashboardService) { }

  ngOnInit(): void {
    this.baseCharts = [];
    this.switches = [];
    this.getHostId();
    this.getDashboardResponse();
  }
  getHostId() {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.hostId = param["hostId"];
      }
    })
  }
  getDashboardResponse() {
    this.hostDashboardService.getDashboard(this.hostId).subscribe({
      next: (response) => {
        response.content.charts.forEach((chart)=>{
          if(chart.type == 'line'){
            this.createChartModels[chart.key] =  this.createLineChartModel(chart.max,chart.min);
          }else if(chart.type == 'pie'){
            this.createChartModels[chart.key] = this.createPieChartModel();
          }
        })
        this.dashboardResponse = response.content;
      }, error: (err) => {
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  createLineChartModel(max?:number,min?:number){
    var model:ChartCreateModel =
    {
      data: {
        datasets: [{ data: [] }],
        labels: []
      },
      options: {
        aspectRatio:"2",
        scales: {
          y: {
            max: max ?? undefined,
            min: min ?? undefined
          }
        }
      }
    }
    return model;
  }
  createPieChartModel(){
    var model:ChartCreateModel =
    {
      data: {
        datasets: [{
          data: [], backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
        'rgb(111, 255, 86)',
        'rgb(86, 246, 255)',
        'rgb(255, 86, 250)'
      ]
        }],
        labels: []
      },
      options: {
        aspectRatio:"2",
        scales: {
        }
      }
    }
    return model;
  }
  saveChart(chart: ChartEmitterModel) {
    console.log(chart.key + " kayıt edildi")
    this.baseCharts.push(chart);
    var chartInitializer = this.dashboardResponse.charts.find(x=>x.key == chart.key);
    if(chartInitializer && chartInitializer.data){
      chartInitializer.data = chartInitializer.data.sort(function(a,b){return new Date(a.time).getTime() - new Date(b.time).getTime()});
      chartInitializer.data.forEach(initData=>{
        console.log(initData);
        console.log();
        this.addDataToChart(chart.key,new Date(initData.time).toUTCString(),initData.label ?? new Date(initData.time).toLocaleTimeString(),initData.value);
      })
    }
  }
  changeCounter(key: string, data: number) {
    var counterInitializer = this.dashboardResponse.counters.find(x => x.key == key);
    if (counterInitializer) {
      counterInitializer.value = data;
    }
  }
  changeSwitch(key: string, data: boolean) {
    var switchInitializer = this.switches.find(x => x.key == key);
    if (switchInitializer) {
      switchInitializer.switchCheck.checked = data;
      switchInitializer.switchCheck.value = "defined";
    }
  }
  getSwitchRef(sw: HostDashboardSwitchEmitterModel) {
    this.switches.push(sw);
  }
  getRandomNumber() {
    var randomNumber = Math.floor(Math.random() * 99 + 1);
    console.log(randomNumber);
    return randomNumber;
  }
  addRandomData(key: string) {

    this.addDataToChart(key,new Date().toUTCString(),new Date().toLocaleTimeString(),Math.round(Math.random()*100));

  }
  addDataToChart(key:string,time:string,label:string,data:number){
    var chartData = this.baseCharts.find(x => x.key == key)?.chart;
    var chartInitializer = this.dashboardResponse.charts.find(x=>x.key == key);
    if (chartData != null) {
      var configuration = (chartData as unknown) as ChartConfiguration;
      console.log(configuration);
      var cdata = configuration.data.datasets[0].data;
      var chartLabels = configuration.data.labels;
      var chrtlbl = chartLabels as any;
      if(chrtlbl['time'] == undefined){
        chrtlbl['time'] = new Array();
      }
      if(chartLabels != undefined && chartLabels.length > 0){
        var maxTimeSpan = chartInitializer?.maxDataSpan ?? 300;
        while(true){
          var currentDate = new Date(new Date().toUTCString()).getTime();
          console.log(JSON.stringify(new Date()));
          console.log(chrtlbl['time']);
          var valueDate = new Date(chrtlbl['time'][0] as string).getTime();
          console.log(currentDate);
          console.log(valueDate);
          console.log(currentDate-valueDate);
          if((currentDate-valueDate) > maxTimeSpan*1000){
            chartLabels.shift();
            cdata.shift();
            chrtlbl.time.shift();
          }else{
            break;
          }
        }
      }
      /*if (chartLabels != undefined && chartLabels?.length > 10) {
        var removeCount = chartLabels.length - 10;
        for (var i = 0; i < removeCount; i++) {
          chartLabels?.shift();
          cdata.shift();
        }
      }*/
      cdata.push(data);
      chartLabels?.push(label);
      chrtlbl['time'].push(new Date(time).toUTCString());

      console.log(cdata);
      console.log(chartLabels);
      chartData.update();
    }
  }

}
