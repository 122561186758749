<div class="formMain form-default-border form-maroon-border">
  <div class="form-title-div ">
    <font class="form-title-content">{{'installerImages.uploadImageTitle' | translate}}</font>
  </div>
  <div class="mb-3">
      <label for="formFile" class="form-label">{{'installerImages.selectAndUploadFile' | translate}}</label>
      <input class="form-control" (change)="handleFile($event)" type="file" id="formFile">
    </div>
    <button class="btn btn-secondary" [disabled]="uploading" (click)="uploadInstallerImage()"><span *ngIf="uploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'btn.upload' | translate}}</button>
    <br/><br/>
    <div class="progress" *ngIf="uploading != false">
      <div class="progress-bar" role="progressbar" [style.width]="percentage"  [attr.aria-valuenow]="2" aria-valuemin="0" [attr.aria-valuemax]="5"></div>
    </div>
</div>

