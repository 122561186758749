import { Component, OnInit } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChunkUploaderService } from '../../../services/chunkUploader/chunk-uploader.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';

@Component({
  selector: 'lib-upload-base-vm-image',
  templateUrl: './upload-base-vm-image.component.html',
  styleUrls: ['./upload-base-vm-image.component.css']
})
export class UploadBaseVmImageComponent implements OnInit {

  baseImageId:string;
  uploading:boolean = false;
  baseImageFile:any;
  totalChunks:number = 0;
  uploadedChunks:number = 0;
  percentage:string;
  constructor(private chunkUploader:ChunkUploaderService,private virtualLabService:VirtualLabService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseImageId = param["id"];
      }
    })
  }
  setFile($ev:any){
    this.baseImageFile = $ev.target.files[0];
  }
  uploadBaseVmImage(){
    //var fileElement = document.getElementById("formFile") as HTMLInputElement;
    //var formData = new FormData();
    if(this.baseImageFile){
      //formData.set("file",this.baseImageFile);
      this.uploading = true;
      /*this.virtualLabService.uploadBaseVmImage(this.baseImageId,formData).subscribe({
        next:(response)=>{
          this.uploading = false;
          this.toastrService.success("File uploaded successfully");
        },error:(err)=>{
          this.toastrService.error("An error occurred");
          this.uploading = false;
        }
      });*/
      this.chunkUploader.getProgress().subscribe({
        next:(response)=>{
          this.totalChunks = response.totalCount;
          this.uploadedChunks = response.uploaded
          this.percentage = Math.ceil((this.uploadedChunks*100)/this.totalChunks)+"%";
        }
      })
      this.chunkUploader.uploadFileWithChunks(this.baseImageFile,environment.apiUrl+`api/range/vm/basevmimage/${this.baseImageId}/upload`,1024 * 1024 * 20,{redirect:"/range/vm/basevmimages/"+this.baseImageId+"/details"}).then(()=>{
        this.uploading = false;
        
      });

    }else{
      this.uploading = false;
      this.toastrService.error("Please select a installer image file");
    }

  }

}
