<div class="main" *ngIf="success == false">
    <div class="alert alert-info" role="alert">
        {{'courseManagement.deleteCategoryMessage' | translate}}
    </div>
    <div class="mb-3">
        <label class="form-check-label">Alt kategorileri koru : <input class="form-check-input" type="checkbox" checked id="keepChildren"/></label>
    </div>
    <input type="checkbox" (change)="checkChanged($event)" id="understandCheck" name="understandCheck"/><label for="understandCheck">{{'global.understandCheckboxMessage' | translate}}</label>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="deleteCategory()" [disabled]="checkedUnderstandCheck == false"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>

<div class="successDiv" *ngIf="success">
    <div class="successContent">
        <div class="successMessage">
            <i class="bi bi-check-circle-fill checkIcon"></i><br/>
            <font class="checkMessage">{{'global.deletedSuccessfully' | translate}}</font>
        </div>
        <br/>
        <br/>
        <div class="successButtons">
            <button [disabled]="loading" class="btn btn-danger btn-sm modal-close-btn">{{'btn.close' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
        </div>

    </div>
</div>