import { Component, OnInit } from '@angular/core';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { UserManagerService } from '../../../services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.css']
})
export class ChangeUserPasswordComponent implements OnInit {

  constructor(private userManagementService:UserManagerService,private activatedRoute:ActivatedRoute) { }
  customFormData: CustomForm;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.userManagementService.getChangeUserPasswordForm(params['user']).subscribe({
        next:(response)=>{
          this.customFormData = response;
        }
      })
    })
  }

}
