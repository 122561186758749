import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate {
  constructor(private loginService:LoginService,private router:Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.loginService.isLoggedControlWithExpiration() || this.loginService.refreshExpirationControl() == true){
      return true;
    }else{
      var path = window.location.pathname;
      if(path !== "" && path !== "/"){
        sessionStorage.setItem("redirectAfterLogin", path);
      }else{
        sessionStorage.removeItem("redirectAfterLogin");
      }
      this.router.navigate(['/login']);
      return false;
    }

  }

}
