<div class="format-viewer">
    <div class="plaintext" *ngIf="format == 0" [innerText]="content">

    </div>
    <div class="html" *ngIf="format == 1 || format == 5" [innerHTML]="content">

    </div>
    <div class="markdown" *ngIf="format == 2">
        <markdown [data]="content"></markdown>
    </div>
    <div class="json" *ngIf="format == 3">
        
    </div>
    <div class="xml" *ngIf="format == 4"></div>
</div>
