import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-installer-image-list',
  templateUrl: './installer-image-list.component.html',
  styleUrls: ['./installer-image-list.component.css']
})
export class InstallerImageListComponent implements OnInit {
  apiUrl = environment.apiUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
