import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageInfoRevisionsListResponse } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageInfoListResponse';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';

@Component({
  selector: 'lib-list-base-vm-image-info-revisions',
  templateUrl: './list-base-vm-image-info-revisions.component.html',
  styleUrls: ['./list-base-vm-image-info-revisions.component.css']
})
export class ListBaseVmImageInfoRevisionsComponent implements OnInit {
  baseVmId:string;
  locale:string;
  revisions:BaseVmImageInfoRevisionsListResponse;
  constructor(private translateService:TranslateService,private baseVmImageService:BaseVmImageService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private globalModalService:GlobalModalService,private globalOkCancelEvent:GlobalOkCancelEventService) { }

  ngOnInit(): void {
    this.getBaseVmId();
    this.locale = this.translateService.currentLang;
    this.getRevisionList();
  }
  getBaseVmId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseVmId = param["vmImageId"];
      }
    })
  }
  getRevisionList(){
    this.baseVmImageService.listBaseVmImageInfoRevision(this.baseVmId,this.locale).subscribe({
      next:(response)=>{
        this.revisions = response.content;
      },
      error:(err)=>{
        this.toastrService.error(this.translateService.instant("baseVmImages.info.getRevisionListError"));
      }
    })
  }
  getRevisionContent(revisionId:string){
    this.globalModalService.showModal({
      data:"modal/range/vm/basevmimages/info/revisions/"+revisionId+"/content",
      helperModals:[],
      title:"Revision Content",
      type:ModalTypes.LINK
    },true);
  }
  restoreRevision(revisionId:string){
    var modal = this.globalModalService.showModal({
      data:"modal/range/vm/basevmimages/"+this.baseVmId+"/info/revisions/"+revisionId+"/restore",
      helperModals:[],
      title:"Restore Revision",
      type:ModalTypes.LINK
    },false);
    modal['onclose'].subscribe({
      next:(response:any)=>{
        this.getRevisionList();
      }
    })
  }
  deleteRevision(revisionId:string){
    this.globalOkCancelEvent.showOkCancelWindow("Delete Revision",this.translateService.instant("baseVmImages.revisions.deleteMessage"),true,undefined,undefined,true,this.translateService.instant("baseVmImages.revisions.deleteConfirmMessage")).then(x=>{
      if(x){
          this.baseVmImageService.deleteBaseVmImageRevision({vmImageId:this.revisions.vmImageId,revisionId:revisionId}).subscribe({
            next:(response)=>{
              this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
              this.getRevisionList();
            },error:(err)=>{
              this.toastrService.error(this.translateService.instant("global.deleteError"));
            }
          })
      }
    })
  }

}
