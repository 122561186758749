import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-course-seat-access-item',
  templateUrl: './delete-course-seat-access-item.component.html',
  styleUrls: ['./delete-course-seat-access-item.component.css']
})
export class DeleteCourseSeatAccessItemComponent implements OnInit {
  itemId:string;
  url:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.itemId = param["id"];
        this.url = "api/lms/courses/seats/access/"+this.itemId+"/delete";
      }
    })
  }

}
