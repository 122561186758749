import { Component, OnInit } from '@angular/core';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ActivatedRoute } from '@angular/router';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { VirtualLabModel } from '../../../models/VirtualLabModels/virtualLabModel';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-manage-lab',
  templateUrl: './manage-lab.component.html',
  styleUrls: ['./manage-lab.component.css']
})
export class ManageLabComponent implements OnInit {
  labId:string
  lab:VirtualLabModel;
  tags:string[] = [];
  tableUrl:string;
  constructor(private translateService:TranslateService,private virtualLabService:VirtualLabService,private activatedRoute:ActivatedRoute,private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(params)=>{
        this.labId = params["labId"];
        this.tableUrl = environment.apiUrl + `api/range/labblueprints/${this.labId}/labobjectives/list`
        this.getLabInfo();
      }
    })
  }
  getLabInfo(){
    this.virtualLabService.getVirtualLabInfo(this.labId).subscribe({
      next:(response)=>{
        this.lab = response.content;
        this.tags = this.lab.tags.split(",");
      }
    })
  }
  showAddVmModal(){
    this.globalModalService.showModal({
      title: this.translateService.instant("lab.addVirtualMachineToLab"),
      type: ModalTypes.LINK,
      data: `modal/lab/${this.labId}/addVm`,
      helperModals: []
    },true);
  }
  showAddCriteriaModal(){
    this.globalModalService.showModal({
      title: this.translateService.instant("lab.addControlCriteriaToLab"),
      type: ModalTypes.LINK,
      data: `modal/lab/${this.labId}/addControlCriteria`,
      helperModals: []
    },true);
  }

}
