import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.css']
})
export class AdminManagementComponent implements OnInit {
  adminsTable:string = environment.apiUrl+"api/accounts/admins/table";
  constructor() { }

  ngOnInit(): void {
  }

}
