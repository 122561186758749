import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizedStringResponse } from '../../../models/LocalizedString/localizedStringResponse';
import { LocalizedStringService } from '../../../services/localizedString/localized-string.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-localized-string',
  templateUrl: './localized-string.component.html',
  styleUrls: ['./localized-string.component.css']
})
export class LocalizedStringComponent implements OnInit {
  localizedStringId:number;
  localizedStringResponse:LocalizedStringResponse
  allowHtml:boolean = false;
  tableUrl:string;
  constructor(private localizedStringService:LocalizedStringService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getLocalizedStringId();
    this.getDetails();
  }
  getLocalizedStringId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.localizedStringId = param["id"];
        this.tableUrl = environment.apiUrl + `api/cms/admin/localization/localizedstring/${this.localizedStringId}/others/list`
      }
    })
  }
  getDetails(){
    this.localizedStringService.getLocalizedStringDetails(this.localizedStringId).subscribe({
      next:(response)=>{
        this.localizedStringResponse = response.content;
        if(this.localizedStringResponse.format == 5 || this.localizedStringResponse.format == 1){
          this.allowHtml = true;
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred")
      }
    })
  }

}
