import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api/api-url.service';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { BaseVMImageDetailModel } from '../../models/BaseVMImages/baseVmImageDetailModel';
import { BaseVmImageNoteListItemResponse, BaseVmImageNoteListResponse } from '../../models/BaseVMImages/baseVmImageNoteListResponse';
import { BaseVmImageGetInfoResponse } from '../../models/BaseVMImages/baseVmImageGetInfoResponse';
import { BaseVmImageCreateNoteRequest } from '../../models/BaseVMImages/baseVmImageCreateNoteRequest';
import { ResponseModel } from '../../models/responseModel';
import { BaseVmImageUpdateNoteRequest } from '../../models/BaseVMImages/baseVmImageUpdateNoteRequest';
import { BaseVmImageInfoRevisionsListResponse } from '../../models/BaseVMImages/baseVmImageInfoListResponse';
import { BaseVmImageUpdateInfoRequest } from '../../models/BaseVMImages/baseVmImageUpdateInfoRequest';
import { BaseVmImageDeleteInfoRevisionRequest } from '../../models/BaseVMImages/baseVmImageDeleteInfoRevision';
import { BaseVmImageRestoreInfoRevisionRequest } from '../../models/BaseVMImages/baseVmImageRestoreInfoRevisionRequest';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';

@Injectable({
  providedIn: 'root'
})
export class BaseVmImageService {

  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) { }
  deleteBaseVmImage(baseVmImageId: string) {
    return this.httpClient.delete(this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages/" + baseVmImageId + "/delete", { body: { id: baseVmImageId } })
  }
  getBaseVmImageDetails(id: string) {
    return this.httpClient.get<SingleResponseModel<BaseVMImageDetailModel>>(this.apiUrlService.getApiUrl() + `api/range/vm/basevmimages/${id}/details`)
  }
  getBaseVmImageOptions(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>(this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages/options");
  }
  getNotes(baseVmId: string, page: number = 1) {
    return this.httpClient.get<SingleResponseModel<BaseVmImageNoteListResponse>>(this.apiUrlService.getApiUrl() + "api/range/basevmimages/" + baseVmId + "/notes/list?page=" + page)
  }
  createNote(createNoteRequest:BaseVmImageCreateNoteRequest) {
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+createNoteRequest.vmImageId+"/notes/create",createNoteRequest);
  }
  getUpdateNoteFields(vmImageId:string,noteId:string){
    return this.httpClient.get<SingleResponseModel<BaseVmImageUpdateNoteRequest>>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+vmImageId+"/notes/"+noteId+"/update");
  }
  updateNote(updateNoteRequest:BaseVmImageUpdateNoteRequest) {
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+updateNoteRequest.vmImageId+"/notes/"+updateNoteRequest.noteId+"/update",updateNoteRequest)
  }
  /* Global Delete Component kullanılacak */
  /*deleteNote() {
    return this.httpClient.delete<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/notes/"++"/delete")
  }*/
  listBaseVmImageInfoRevision(baseVmId:string,locale:string) {
    return this.httpClient.get<SingleResponseModel<BaseVmImageInfoRevisionsListResponse>>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+baseVmId+"/info/revisions?locale="+locale);
  }
  updateBaseVmImageInfoRevision(updateRevisionRequest:BaseVmImageUpdateInfoRequest) {
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+updateRevisionRequest.vmImageId+"/info/update",updateRevisionRequest);
  }
  deleteBaseVmImageRevision(deleteRevisionRequest:BaseVmImageDeleteInfoRevisionRequest) {
    return this.httpClient.delete<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+deleteRevisionRequest.vmImageId+"/info/revisions/"+deleteRevisionRequest.revisionId+"/delete",{body:deleteRevisionRequest});
  }
  restoreBaseVmImageInfoRevision(restoreRevisionRequest:BaseVmImageRestoreInfoRevisionRequest) {
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/"+restoreRevisionRequest.vmImageId+"/info/revisions/"+restoreRevisionRequest.revisionId+"/restore",restoreRevisionRequest);
  }
  getInfo(baseVmId: string, locale: string) {
    return this.httpClient.get<SingleResponseModel<BaseVmImageGetInfoResponse>>(this.apiUrlService.getApiUrl() + "api/range/basevmimages/" + baseVmId + "/info?locale=" + locale)
  }
  getRevision(revisionId:string){
    return this.httpClient.get<SingleResponseModel<BaseVmImageGetInfoResponse>>(this.apiUrlService.getApiUrl()+"api/range/basevmimages/info/revisions/"+revisionId);
  }

}
