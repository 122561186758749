import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-media-content',
  templateUrl: './delete-media-content.component.html',
  styleUrls: ['./delete-media-content.component.css']
})
export class DeleteMediaContentComponent implements OnInit {
  id:string;
  deleteUrl:string;
  deleteMessageKey:string = "mediaContent.deleteMessage";
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        this.deleteUrl = "api/lms/contents/media/"+this.id+"/delete";
      }
    })
  }

}
