import { Component, OnInit } from '@angular/core';
import { InputFormats } from '../../../models/Enums/inputFormatsEnum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { AddCourseTranslationModel } from '../../../models/Education/CourseTranslationModels/addCourseTranslationModel';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { CourseTranslationService } from '../../../services/education/course-translation.service';
import { EducationService } from '../../../services/education/education.service';
import { LocaleService } from '../../../services/locale/locale.service';
import { UpdateCourseTranslationModel } from '../../../models/Education/CourseTranslationModels/updateCourseTranslationModel';

@Component({
  selector: 'lib-update-course-translation',
  templateUrl: './update-course-translation.component.html',
  styleUrls: ['./update-course-translation.component.css']
})
export class UpdateCourseTranslationComponent implements OnInit {
  defaultDescription:string;
  defaultType:number;
  imageChangedEvent:any;
  teaserImageChangedEvent:any;
  descriptionInputTypes:number[] = [InputFormats.PlainText];
  getDescriptionFunc:any;
  themeList:ListItemModel[];
  languageList:ListItemModel[];
  image?:string | undefined;
  teaserImage?:string | undefined;
  updateCourseTranslationForm:FormGroup;
  loaded:boolean;
  courseId:string;
  translationId:number;
  baseThemeId:number | undefined;
  fields:UpdateCourseTranslationModel;
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private toastrService:ToastrService,private localeService:LocaleService,private educationService:EducationService,private formBuilder:FormBuilder,private courseTranslationService:CourseTranslationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseId = param["courseId"];
        this.translationId = parseInt(param["translationId"]);
      }
    })
    this.getLanguages().then(()=>{
      this.getThemes().then(()=>{
        this.getFields().then(()=>{

          this.loaded = true;
          this.buildAddCourseTranslationForm();
        })

      })
    });
  }
  getFields():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.courseTranslationService.getCourseTranslationUpdateFields(this.courseId,this.translationId).subscribe({
        next:(response)=>{
          this.fields = response.content;
          this.defaultDescription = response.content.description;
          this.defaultType = response.content.descriptionFormat;
          this.descriptionInputTypes =  [this.defaultType];
          console.log(this.fields);
          console.log("www")
          console.log(this.translationId);
          resolve(true);
        },
        error:(err)=>{
          resolve(false);
        }
      })
    })

  }
  getCourseDetails():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.educationService.getEducation(this.courseId).subscribe({
        next:(response)=>{
          this.baseThemeId = response.content.themeId;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })
  }
  buildAddCourseTranslationForm(){
    this.updateCourseTranslationForm = this.formBuilder.group({
      localeId:[this.fields.localeId,Validators.required],
      primaryVersionId:[this.fields.primaryVersionId],
      initialVersionId:[this.fields.initialVersionId],
      themeId:[this.fields.themeId ?? ''],
      name:[this.fields.name,Validators.required],
      teaserText:[this.fields.teaserText,Validators.required],
      active:[this.fields.active],
      descriptionFormat:[this.fields.descriptionFormat],
      deleteImage:[false],
      deleteTeaserImage:[false]
    })
  }
  getLanguages():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.localeService.getLocalesWithSelectListSchema().subscribe({
        next:(response)=>{
          this.languageList = response.content;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })

  }
  getThemes():Promise<boolean>{
    return new Promise((resolve,reject)=>{
      this.educationService.getEducationThemeList().subscribe({
        next:(response)=>{
          this.themeList = response.content;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })

  }
  changeDescriptionFormat($ev:any){
    var el = $ev.target as HTMLInputElement;
    this.descriptionInputTypes = [parseInt(el.value)]
  }
  getDescriptionValueEvent($ev:any){
    this.getDescriptionFunc = $ev;
  }
  changeImage($ev:any){
    this.imageChangedEvent = $ev;
  }
  imageCropped($ev:ImageCroppedEvent){
    this.image = $ev.base64 as string | undefined;
  }
  imageLoaded($ev:any){

  }
  changeTeaserImage($ev:any){
    this.teaserImageChangedEvent = $ev;
  }
  teaserImageCropped($ev:ImageCroppedEvent){
    this.teaserImage = $ev.base64 as string | undefined;
  }
  teaserImageLoaded($ev:any){

  }
  updateTranslation(){
    if(this.updateCourseTranslationForm.valid){
      var values:UpdateCourseTranslationModel = Object.assign({},this.updateCourseTranslationForm.value);
      values.image = this.image;
      values.teaserImage = this.teaserImage;
      values.id = this.translationId;
      values.primaryVersionId = values.primaryVersionId == "" ? undefined : values.primaryVersionId;
      values.initialVersionId = values.initialVersionId == "" ? undefined : values.initialVersionId;
      values.description = this.getDescriptionFunc();
      values.descriptionFormat = typeof(values.descriptionFormat) == "string" ? parseInt(values.descriptionFormat) : values.descriptionFormat;
      if(values.themeId as any != "" && values.themeId != undefined){
        values.themeId = typeof(values.themeId) == "string" ? parseInt(values.themeId) : values.themeId;
      }else{
        values.themeId = undefined
      }

      console.log(values);
      this.courseTranslationService.updateCourseTranslation(this.courseId,this.translationId,values).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("courseManagement.translationUpdatedSuccessfully"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }

}
