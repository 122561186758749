import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseContentService } from '../../../services/education/course-content.service';
import { CourseOutlineItemRowModel } from '../../../models/CourseContentModels/courseOutlineItemRowModel';
import { SectionIconServiceService } from '../../../services/education/section-icon-service.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';

@Component({
  selector: 'lib-outline-item-panel',
  templateUrl: './outline-item-panel.component.html',
  styleUrls: ['./outline-item-panel.component.css']
})
export class OutlineItemPanelComponent implements OnInit {
  @Input() outlineId:string;
  @Input() courseId:string;
  @Input() versionId:string;
  outlineList:CourseOutlineItemRowModel[];
  automaticSave:boolean = false;
  dragSelector:boolean = false;
  handSelector:boolean = true;
  movementMode:boolean = false;
  moveType:string = "addAfter";
  loadingOutlines:boolean = false;
  saving:boolean = false;
  render:boolean = true;
  selectedItem:string = "";
  moveQueue:{movingItem:string,targetId:string,moveType:string}[] = [];
  @Output() setPreview:EventEmitter<string> = new EventEmitter<string>();
  constructor(private courseContentService:CourseContentService,private globalModalService:GlobalModalService,private globalOkCancelService:GlobalOkCancelEventService,private outlineIconService:SectionIconServiceService,private translateService:TranslateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getOutlineItems();
  }
  changeAutomaticSave(){
    var automaticSaveInput = document.getElementById("automatic-save-checkbox") as HTMLInputElement;
    this.automaticSave = automaticSaveInput.checked;
  }
  reRender(){
    this.render = false;
    setTimeout(()=>{
      this.render = true;
    },250)
  }
  selectItem(name:string){
    switch(name){
      case "drag":
        this.dragSelector = true;
        this.handSelector = false;
        break;
      case "hand":
        this.handSelector = true;
        this.dragSelector = false;
      }
  }
  getOutlineItems(){
    this.loadingOutlines = true;
    this.courseContentService.getCourseOutlineItems(this.versionId).subscribe({
      next:(response=>{
        var tempData = response.content.data;
        tempData.forEach(item=>{
          item.icon = this.outlineIconService.getIconClasses(item.icon);
        })
        this.outlineList = tempData;
        this.loadingOutlines = false;
      }
    ),error:(err)=>{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      this.loadingOutlines = false;
    }
    })
  }
  outlineItemDragStart($ev:DragEvent){
    this.movementMode = true;
    $ev.dataTransfer?.setData("moveitem",($ev.target as HTMLDivElement).id)
  }
  outlineItemDragEnd($ev:DragEvent){
    this.movementMode = false;
  }
  childLevelArr(num:number){
    var arr = [];
    for(let i = 0;i<num;i++){
      arr.push(i);
    }
    return arr;
  }
  moveItem($ev:DragEvent,$this:HTMLDivElement){
    var draggingItem = $ev.dataTransfer?.getData("moveitem") ?? "";
    var targetItem = ($ev.target as HTMLDivElement);
    console.log($this);
    console.log(draggingItem + " moved to "+$this.id);
    if(draggingItem == $this.id){
      return;
    }
    this.localMove(draggingItem,$this.id,this.moveType);
    /*this.loadingOutlines = true;

    })*/
  }
  runMoveProcess(){
    var el =this.moveQueue.shift();
    if(el != null){
      this.saving = true;
      this.moveProcess(el.movingItem,el.targetId,el.moveType);
    
    }
  }
  moveProcess(draggingItem:string,targetItem:string,moveType:string){
    this.courseContentService.moveCourseOutlineItems(draggingItem,targetItem,this.moveType).subscribe({
      next:(response)=>{
        //this.toastrService.success(this.translateService.instant("outlineItemPanel.outlineItemMoved"));
        this.loadingOutlines = false;
    //  this.getOutlineItems();
        var el =this.moveQueue.shift();
        if(el != null){
          this.moveProcess(el.movingItem,el.targetId,el.moveType);
        }else{
          this.getOutlineItems();
          this.saving = false;
        }
      },error:(err)=>{
        this.loadingOutlines = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        this.saving = false;
      }
    })
    
  }
  moveUp(childs:CourseOutlineItemRowModel[],indexChange:number,levelChange?:number){
    
  }
  moveDown(childs:CourseOutlineItemRowModel[],indexChange:number,levelChange?:number){

  }
  getIndexAndChilds(id:string){
    var item = this.outlineList.find(x=>x.id == id);
    if(item == null){
      return;
    }
    var index = this.outlineList.findIndex(x=>x.id == id);
    var childCount = 0;
    var childs:CourseOutlineItemRowModel[] = [];
    while(true){
      if(this.outlineList[index+1+childCount] === undefined){
        break;
      }
      if(this.outlineList[index+1+childCount].level > item.level){
        childCount +=1;
        childs.push(this.outlineList[index+1+childCount]);
      }else{
        break;
      }
    }
    return {childs:childs,index:index,item:item};
  }
  localMove(dragging:string,target:string,moveType:string){
    console.log(...this.outlineList);
    if(this.outlineList.length == 0){
      return;
    }
    var targetItem = this.outlineList.find(x=>x.id == target)
    var draggingItem = this.outlineList.find(x=>x.id == dragging);
    if(targetItem == null || draggingItem == null){
      return;
    }
    var targetItemIndex = this.outlineList.findIndex(x=>x.id == targetItem?.id);
    var draggingItemIndex = this.outlineList.findIndex(x=>x.id == draggingItem?.id);
    var targetItemChildCount = 0;
    var draggingItemChildCount = 0;
    var targetItemChilds:CourseOutlineItemRowModel[] = [];
    var draggingItemChilds:CourseOutlineItemRowModel[] = [];

    while(true){
      if(this.outlineList[targetItemIndex+1+targetItemChildCount] === undefined){
        break;
      }
      if(this.outlineList[targetItemIndex+1+targetItemChildCount].level > targetItem.level){

        targetItemChildCount +=1;
        targetItemChilds.push(this.outlineList[targetItemIndex+targetItemChildCount]);
        
      }else{
        break;
      }
    }
    console.log(targetItemChilds);
    while(true){
      if(this.outlineList[draggingItemIndex+1+draggingItemChildCount] === undefined){
        break;
      }
      if(this.outlineList[draggingItemIndex+1+draggingItemChildCount].level > draggingItem.level){
        draggingItemChildCount +=1;
        draggingItemChilds.push(this.outlineList[draggingItemIndex+draggingItemChildCount])

      }else{
        break;
      }
    }
    if(moveType == "addAfter"){
      var levelChange = targetItem.level - draggingItem.level; 
      var movingItems = [draggingItem,...draggingItemChilds];
      this.outlineList.splice(draggingItemIndex,1+draggingItemChildCount);
      console.log(movingItems)
      
      var targetInfo = this.getIndexAndChilds(targetItem.id);
      if(targetInfo == undefined){
        return;
      }
      movingItems.forEach(i=>{
        i.level += levelChange;
      })
      var newTargetIndex = targetInfo?.index;
      var newTargetChildCount = targetInfo?.childs.length;
      this.outlineList.splice(newTargetIndex+1+newTargetChildCount,0,...movingItems);

      this.moveQueue.push({
        targetId:target,
        movingItem:dragging,
        moveType:"addAfter"
      })
    }else if(moveType == "child"){
      var levelChange = (targetItem.level - draggingItem.level)+1;
      var movingItems = [draggingItem,...draggingItemChilds];
      this.outlineList.splice(draggingItemIndex,1+draggingItemChildCount);
      movingItems.forEach(item=>{
        item.level += levelChange;
      })
      var targetInfo = this.getIndexAndChilds(targetItem.id);
      if(targetInfo == undefined){
        return;
      }
      var newTargetIndex = targetInfo?.index;
      var newTargetChildCount = targetInfo?.childs.length;
      this.outlineList.splice(newTargetIndex+1+newTargetChildCount,0,...movingItems);
      this.moveQueue.push({
        targetId:target,
        movingItem:dragging,
        moveType:"child"
      })
      try {
        var automaticSaveInput = document.getElementById("automatic-save-checkbox") as HTMLInputElement;
        if(automaticSaveInput.checked == true){
          this.runMoveProcess();
        }
      }catch{

      }

      
    }
    console.log(...this.outlineList);
  }
  setPreviewItem(id:string){
    this.selectedItem = id;
    this.setPreview.emit(id);
  }
  deleteOutlineItem(id:string){
    var title = this.translateService.instant("outlineItemPanel.deleteOutlineItem");
    var deleteMessage = this.translateService.instant("outlineItemPanel.deleteOutlineMessage");
    this.globalOkCancelService.showOkCancelWindow(title,deleteMessage,true,undefined,undefined,true,'outlineItemPanel.deleteCheckboxMessage').then(x=>{
      if(x == true){
        this.courseContentService.deleteCourseOutlineItem(id).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("outlineItemPanel.outlineItemSuccessfullyDeleted"));
            this.getOutlineItems();
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }else{

      }
    })
  }
  editOutlineItem(id:string){
    var modal = this.globalModalService.showModal({
      data:"/modal/lms/courses/outline/"+id+"/update",
      helperModals:[],
      title:this.translateService.instant("outlinePanel.updateOutlineModalTitle"),
      type:ModalTypes.LINK
    },false)
    console.log(modal);
    modal["onclose"].subscribe({
      next:(ev:any)=>{
        this.getOutlineItems();
      }
    })
  }
  addNew(){
    var modal = this.globalModalService.showModal({
      data:"/modal/lms/courses/version/"+this.versionId+"/addSection",
      helperModals:[],
      title:this.translateService.instant("outlinePanel.addNewOutlineModalTitle"),
      type:ModalTypes.LINK
    },false)
    console.log(modal);
    modal["onclose"].subscribe({
      next:(ev:any)=>{
        this.getOutlineItems();
      }
    })
  }
  allowDrop($ev:DragEvent){
      $ev.preventDefault();
  }
  setMoveType(type:string){
    if(type == "child"){
      this.moveType = "child";
    }
    if(type == "addAfter"){
      this.moveType = "addAfter";
    }
  }

}
