import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale/locale.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocaleDetailsResponse } from '../../../models/Locales/localeDetailsResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-locale-details',
  templateUrl: './locale-details.component.html',
  styleUrls: ['./locale-details.component.css']
})
export class LocaleDetailsComponent implements OnInit {
  localeId:string;
  localeData:LocaleDetailsResponse;
  loading:boolean = false;
  constructor(private localeService:LocaleService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getLocaleId();
    this.loading = true;
    this.localeService.getLocaleDetails(this.localeId).subscribe({
      next:(response)=>{
        this.localeData = response.content;
        console.log(response.content);
        this.loading = false;
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
        this.loading = false;
      }
    })
  }
  getLocaleId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.localeId = param['locale'];
      }
    })
  }

}
