import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-question-option',
  templateUrl: './delete-question-option.component.html',
  styleUrls: ['./delete-question-option.component.css']
})
export class DeleteQuestionOptionComponent implements OnInit {

  questionId:number;
  optionId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private questionService:QuestionService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.optionId = parseInt(param["optionId"]);
        this.questionId = parseInt(param["questionId"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteQuestion(){
    this.questionService.deleteQuestionOption(this.questionId,this.optionId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success(this.translateService.instant("questionOption.questionOptionDeletedSuccessfully"));
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
