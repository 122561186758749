import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { VmInstanceTypeUpdateInfoModel } from '../../../models/vmInstanceTypes/vmInstanceUpdateInfoModel';
import { ActivatedRoute } from '@angular/router';
import { VmInstanceTypeUpdateModel } from '../../../models/vmInstanceTypes/vmInstanceUpdateModel';

@Component({
  selector: 'lib-update-vm-instance-type',
  templateUrl: './update-vm-instance-type.component.html',
  styleUrls: ['./update-vm-instance-type.component.css']
})
export class UpdateVmInstanceTypeComponent implements OnInit {
  ramList: ListItemModel[];
  cpuList: ListItemModel[];
  typeId: string;
  vmInstanceTypeInfo: VmInstanceTypeUpdateInfoModel;
  updateVmInstanceForm: FormGroup;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder, private toastrService: ToastrService, private vmInstanceTypeService: VmInstanceService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.typeId = param["typeId"];
        this.prepareForm();
      }
    })
  }
  createForm() {
    this.updateVmInstanceForm = this.formBuilder.group({
      name: [this.vmInstanceTypeInfo.name ?? "", Validators.required],
      description: [this.vmInstanceTypeInfo.description ?? ""],
      tags: [this.vmInstanceTypeInfo.tags ?? ""],
      active: [this.vmInstanceTypeInfo.active ?? false],
      ram: [this.vmInstanceTypeInfo.ram ?? null],
      cpuCount: [this.vmInstanceTypeInfo.cpuCount ?? null]
    })
  }
  getRamList(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.vmInstanceTypeService.getRamList().subscribe({
        next: (response) => {
          this.ramList = response.content;
          resolve(true);
        }, error: (err) => {
          resolve(false);
        }
      })
    })
  }

  getCpuCountList(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.vmInstanceTypeService.getCpuCountList().subscribe({
        next: (response) => {
          this.cpuList = response.content;
          resolve(true);
        }, error: (err) => {
          resolve(false);
        }
      })
    })
  }
  getUpdateInfo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.vmInstanceTypeService.getVmInstanceTypeUpdateInfo(this.typeId).subscribe({
        next: (response) => {
          this.vmInstanceTypeInfo = response.content;
          console.log(this.vmInstanceTypeInfo);
          resolve(true);
        }, error: (err) => {
          resolve(false);
        }
      })
    })
  }
  async prepareForm(){
    await this.getRamList();
    await this.getCpuCountList();
    await this.getUpdateInfo();
    this.createForm();
  }
  updateInstanceType() {
    if(this.updateVmInstanceForm.valid){
      var data:VmInstanceTypeUpdateModel = Object.assign({},this.updateVmInstanceForm.value);
      data.instanceTypeId = this.typeId;
      data.cpuCount = typeof(data.cpuCount) === "string" ? parseInt(data.cpuCount) : data.cpuCount;
      this.vmInstanceTypeService.updateVmInstanceType(data).subscribe({
        next:(response)=>{
          this.toastrService.success("Başarıyla güncellendi");
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
