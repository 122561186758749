import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-update-tenant',
  templateUrl: './update-tenant.component.html',
  styleUrls: ['./update-tenant.component.css']
})
export class UpdateTenantComponent implements OnInit {

  formUrl:string;
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/multitenancy/tenant/"+param["id"]+"/update"
      }
    })

  }

}
