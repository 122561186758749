<h2>Kullanıcı Yönetimi</h2>
<br/>
<button class="btn btn-secondary" routerLink="/user/create">{{'userManagement.addNewUser' | translate}}</button>
<br/><br/>
<div *ngIf="loading" class="d-flex justify-content-center">
    <div class="spinner-grow text-dark" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
<div class="input-group mb-3">
  <span class="input-group-text" id="fullnameInput"><i class="bi bi-person-circle"></i> <font>&nbsp;{{'userManagement.filterByFullName' | translate}}</font></span>
  <input id="filterFullNameInput" (keyup)="changeFilterFullName($event)" type="text" class="form-control" [placeholder]="('global.fullName' | translate)" aria-label="fullname" aria-describedby="fullnameInput">
</div>
<lib-basic-table *ngIf="responseData != null && responseData.length > 0 && fields != null && loading == false" [actions]="actions" [fields]="fields" [tableData]="responseData | searchUser:filterFullName">
</lib-basic-table>