<form class="formMain form-default-border form-maroon-border" [formGroup]="addLocaleForm" *ngIf="addLocaleForm">
    <div class="form-title-div">
        <font class="form-title-content">Yeni Dil Ekle</font>
    </div>
    <div class="mb-3">
        <label for="id" class="form-label mainLabel">Id</label>
        <input type="text" formControlName="id" class="form-control" id="id" aria-describedby="id">
    </div>
    <div class="mb-3">
        <label for="name" class="form-label mainLabel">Name</label>
        <input type="text" formControlName="name" class="form-control" id="name" aria-describedby="name">
    </div>
    <div class="mb-3">
        <label for="cultureCode" class="form-label mainLabel">Culture Code</label>
        <input type="text" formControlName="cultureCode" class="form-control" id="cultureCode"
            aria-describedby="cultureCode">
    </div>
    <div class="mb-3">
        <label for="iso639P1" class="form-label mainLabel">Iso 639P1</label>
        <input type="text" formControlName="iso639P1" class="form-control" id="iso639P1" aria-describedby="iso639P1">
    </div>
    <div class="mb-3">
        <label for="iso639P2" class="form-label mainLabel">Iso 639P2</label>
        <input type="text" formControlName="iso639P2" class="form-control" id="iso639P2" aria-describedby="iso639P2">
    </div>
    <div class="mb-3">
        <label for="wildcardLocaleId" class="form-label mainLabel">Wildcard Locale Id</label>
        <input type="text" formControlName="wildcardLocaleId" class="form-control" id="wildcardLocaleId"
            aria-describedby="wildcardLocaleId">
    </div>
    <!--<div class="mb-3">
        <label for="wildcardLocaleId" class="form-label">Wildcard Locale</label>
        <select class="form-select" formControlName="wildcardLocaleId" aria-label="">
            <option *ngFor="let locale of locales" [value]="locale.value">{{locale.label}}</option>
          </select>
    </div>-->
    <div class="mb-3">
        <label for="parentLocaleId" class="form-label mainLabel">Parent Locale</label>
        <select class="form-select" formControlName="parentLocaleId" aria-label="">
            <option [value]="''">None</option>
            <option *ngFor="let locale of locales" [value]="locale.value">{{locale.label}}</option>
        </select>
    </div>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="active" value="" id="active">
        <label class="form-check-label mainLabel" for="active">
            Active
        </label>
    </div>
    <div style="text-align: right;">
        <button class="btn btn-primary btn-sm" onclick="return false" (click)="submitForm()">Dil Ekle</button>
    </div>

</form>