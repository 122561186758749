import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateHostProviderTypeRequestModel } from '../../models/HostProviderModels/createHostProviderTypeRequest';
import { UpdateHostProviderTypeRequestModel } from '../../models/HostProviderModels/updateHostProviderTypeRequest';
import { DeleteHostProviderTypeRequestModel } from '../../models/HostProviderModels/deleteHostProviderTypeRequest';
import { ResponseModel } from '../../models/responseModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { HostProviderTypeUpdateInfoModel } from '../../models/HostProviderModels/hostProviderTypeUpdateInfoModel';
import { HostProviderTypeModel } from '../../models/HostProviderModels/hostProviderTypeModel';

@Injectable({
  providedIn: 'root'
})
export class HostProviderService {

  constructor(private httpClient:HttpClient) { }
  createHostProviderType(createModel:CreateHostProviderTypeRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/providertypes/create`,createModel);
  }
  updateHostProviderType(updateModel:UpdateHostProviderTypeRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/providertypes/${updateModel.typeId}/update`,updateModel);
  }
  deleteHostProviderType(deleteModel:DeleteHostProviderTypeRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/range/providertypes/${deleteModel.typeId}/delete`,{body:deleteModel});
  }
  getHostProviderTypeUpdateInfoModel(typeId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<HostProviderTypeUpdateInfoModel>>(apiUrl+"api/range/providertypes/"+typeId+"/update");
  }
  getHostProvider(typeId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<HostProviderTypeModel>>(`${apiUrl}api/range/providertypes/${typeId}`);
  }
}
