import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-localized-format-string-list',
  templateUrl: './localized-format-string-list.component.html',
  styleUrls: ['./localized-format-string-list.component.css']
})
export class LocalizedFormatStringListComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrl:ApiUrlService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrl.getApiUrl() + "api/cms/admin/localization/formatstring/list"
  }

}
