<div *ngIf="vmInstanceMappingInfo" class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'vminstancetypes.vmInstanceMappingManagementTitle' | translate}}</font>
    </div>

    <div class="mb-3">
        <b class="title">{{'vminstancetypes.baseInstanceType' | translate}} : </b><font class="val">{{vmInstanceMappingInfo.baseInstanceTypeName}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.active' | translate}} : </b><font class="val">{{vmInstanceMappingInfo.active}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.notes' | translate}} : </b><font class="val">{{vmInstanceMappingInfo.notes}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.priority' | translate}} : </b><font class="val">{{vmInstanceMappingInfo.priority}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.ram' | translate}} : </b><font class="val">{{getRamName(vmInstanceMappingInfo.ram)}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.cpuCount' | translate}} : </b><font class="val">{{vmInstanceMappingInfo.cpuCount}}</font>
    </div>
</div>