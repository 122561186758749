import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-removable-image',
  templateUrl: './create-removable-image.component.html',
  styleUrls: ['./create-removable-image.component.css']
})
export class CreateRemovableImageComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  formUrl:string = environment.apiUrl + "api/range/vm/removableimages/create"
  constructor() { }

  ngOnInit(): void {
  }

}
