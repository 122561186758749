import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { SetupHostService } from '../../../services/setupHost/setup-host.service';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ToastrService } from 'ngx-toastr';
import { SetupVmInstanceService } from '../../../services/setupVmInstance/setup-vm-instance.service';
import { Router } from '@angular/router';
import { BaseVmImageService } from '../../../services/baseVmImages/base-vm-image.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-create-setup-vm-instance',
  templateUrl: './create-setup-vm-instance.component.html',
  styleUrls: ['./create-setup-vm-instance.component.css']
})
export class CreateSetupVmInstanceComponent implements OnInit {
  formUrl:string = this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/create"
  baseUrl:string = this.apiUrlService.getApiUrl();
  instanceTypes:ListItemModel[];
  baseImages:ListItemModel[];
  installerImages:ListItemModel[];
  hosts:ListItemModel[];
  addTypeSelected:boolean = false;
  selectBaseImg:boolean = false;
  @ViewChild("hostElement") hostElement:ElementRef;
  @ViewChild("instanceTypeElement") instanceTypeElement:ElementRef;
  @ViewChild("externalForm") externalForm:ElementRef;

  constructor(private translateService:TranslateService,private toastrService:ToastrService,private router:Router,private setupVmInstanceService:SetupVmInstanceService,private apiUrlService:ApiUrlService,private setupHostService:SetupHostService,private instanceTypeService:VmInstanceService,private baseVmImageService:BaseVmImageService,private virtualLabService:VirtualLabService) { }

  ngOnInit(): void {
    this.setupHostService.getSetupHostOptions().subscribe({
      next:(response)=>{
        this.hosts = response.content;
        this.instanceTypeService.getVmInstanceTypesWithProviderTypeAndHost(null,response.content[0].value).subscribe({
          next:(response)=>{
            this.instanceTypes = response.content;
          },
          error:(err)=>{
            this.toastrService.error("Bir hata oluştu");
          }
        });
        
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
    this.getInstallerImages();
    this.getBaseImages();
  }
  getInstallerImages(){
    this.virtualLabService.getInstallerImageOptions().subscribe({
      next:(response)=>{
        this.installerImages = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getBaseImages(){
    this.baseVmImageService.getBaseVmImageOptions().subscribe({
      next:(response)=>{
        this.baseImages = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  selectBaseImage(){
    /*var baseImgInput = document.getElementById("neworimg_baseimg") as HTMLInputElement;
    if(baseImgInput != undefined){
      if(baseImgInput.checked == true){
        this.selectBaseImg = true;
      }else{
        this.selectBaseImg = false;
      }
    }*/
   this.addTypeSelected = true;
   this.selectBaseImg = true;

  }
  newInstallerImage(){
    /*var newImgInput = document.getElementById("neworimg_new") as HTMLInputElement;
    if(newImgInput != undefined){
      if(newImgInput.checked == true){
        this.selectBaseImg = false;
      }else{
        this.selectBaseImg = true;
      }
    }*/
   this.addTypeSelected = true;
   this.selectBaseImg = false;
  }
  changeHost($ev:any){
    this.instanceTypeService.getVmInstanceTypesWithProviderTypeAndHost(null,$ev.target.value).subscribe({
      next:(response)=>{
        this.instanceTypes = response.content;
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    });
  }
  getObj($ev:any){
    console.log($ev);
    if(this.selectBaseImg){
      $ev.installerImageId = null;
      $ev.baseVMImageId = (document.getElementById("baseImageId") as HTMLSelectElement).value;
    }else{
      $ev.baseVMImageId = null;
      $ev.installerImageId = (document.getElementById("installerImageId") as HTMLSelectElement).value;
    }
    var hostValue = this.hostElement.nativeElement.value;
    var instanceTypeValue = this.instanceTypeElement.nativeElement.value;
    $ev.instanceTypeId = instanceTypeValue;
    $ev.hostId = hostValue;
    //baseimg checks
    if(this.selectBaseImg == false){
      $ev.copyBaseVMImageInfo = false;
      $ev.copyBaseVMImageNotes = false;
      $ev.copyBaseVMImageObjectives = false;
      $ev.copyBaseVMImageCustomization = false;
    }else{
      $ev.copyBaseVMImageInfo = (document.getElementById("copyBaseVmImageInfo") as HTMLInputElement).checked;
      $ev.copyBaseVMImageNotes = (document.getElementById("copyBaseVMImageNotes") as HTMLInputElement).checked;
      $ev.copyBaseVMImageObjectives = (document.getElementById("copyBaseVMImageObjectives") as HTMLInputElement).checked;
      $ev.copyBaseVMImageCustomization = (document.getElementById("copyBaseVMImageCustomization") as HTMLInputElement).checked;
    }

    if($ev.diskSize == ""){
      $ev.diskSize = null;
    }
    if(($ev.installerImageId == null && $ev.baseVMImageId == null) ||($ev.installerImageId == "" && $ev.baseVMImageId == "")){
      this.toastrService.error("Installer Image ya da Base VM Image seçilmelidir");
      return;
    }
    this.setupVmInstanceService.createSetupVmInstance($ev).subscribe({
      next:(response)=>{
        this.toastrService.success("Setup VM Instance created successfully");
        this.router.navigate(["/range/vm/setupinstance/"+response.content+"/manage"]);
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })

  }
  

}
