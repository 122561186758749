import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api/api-url.service';
import { AddCourseTranslationModel } from '../../models/Education/CourseTranslationModels/addCourseTranslationModel';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { UpdateCourseTranslationModel } from '../../models/Education/CourseTranslationModels/updateCourseTranslationModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { CourseTranslationDetailsModel } from '../../models/Education/CourseTranslationModels/courseTranslationDetailsModel';

@Injectable({
  providedIn: 'root'
})
export class CourseTranslationService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  addCourseTranslation(courseId:string,addCourseTranslationModel:AddCourseTranslationModel){
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl() + `api/lms/courses/${courseId}/translations/create`,addCourseTranslationModel)
  }
  updateCourseTranslation(courseId:string,translationId:number,updateTranslationModel:UpdateCourseTranslationModel){
    return this.httpClient.post<ResponseModel>(this.apiUrlService.getApiUrl() + `api/lms/courses/${courseId}/translations/${translationId}/update`,updateTranslationModel)
  }
  deleteCourseTranslation(courseId:string,translationId:number){
    var deleteModel = {id:translationId}
    return this.httpClient.delete<ResponseModel>(this.apiUrlService.getApiUrl() + `api/lms/courses/${courseId}/translations/${translationId}/delete`,{body:deleteModel});
  }
  courseTranslationDetails(courseId:string,translationId:number){
    return this.httpClient.get<SingleResponseModel<CourseTranslationDetailsModel>>(this.apiUrlService.getApiUrl() + `api/lms/courses/${courseId}/translations/${translationId}`)
  }
  getCourseTranslationUpdateFields(courseId:string,translationId:number){
    return this.httpClient.get<SingleResponseModel<UpdateCourseTranslationModel>>(this.apiUrlService.getApiUrl() + `api/lms/courses/${courseId}/translations/${translationId}/update`)
  }
}
