import { Component, OnInit } from '@angular/core';
import { RemovableImageService } from '../../../services/removableImages/removable-image.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RemovableImageResponse } from '../../../models/RemovableImages/removableImageResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-removable-image-details',
  templateUrl: './removable-image-details.component.html',
  styleUrls: ['./removable-image-details.component.css']
})
export class RemovableImageDetailsComponent implements OnInit {
  id:string;
  removableImage:RemovableImageResponse;
  constructor(private removableImageService:RemovableImageService,private translateService:TranslateService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }
  round(num:number){
    return Math.round(num);
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        this.removableImageService.getRemovableImageDetails(this.id).subscribe({
          next:(response)=>{
            this.removableImage = response.content;
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }
    })
  }

}
