import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-setup-vm-instance-update-specs',
  templateUrl: './setup-vm-instance-update-specs.component.html',
  styleUrls: ['./setup-vm-instance-update-specs.component.css']
})
export class SetupVmInstanceUpdateSpecsComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = environment.apiUrl+`api/range/vm/setupvminstances/${param["vmId"]}/updatespecs`
      }
    })
  }

}
