import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-menu-item',
  templateUrl: './delete-menu-item.component.html',
  styleUrls: ['./delete-menu-item.component.css']
})
export class DeleteMenuItemComponent implements OnInit {

  menuId:number;
  itemid:number
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private navigationService:NavigationService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.menuId = parseInt(param["mid"]);
        this.itemid = parseInt(param["id"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteMenu(){
    this.navigationService.deleteMenuItem(this.menuId,this.itemid).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Menu item successfully deleted");
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
