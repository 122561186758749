import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-vm-connections-list',
  templateUrl: './vm-connections-list.component.html',
  styleUrls: ['./vm-connections-list.component.css']
})
export class VmConnectionsListComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl()+"api/terminal/admin/connections/table"
  }

}
