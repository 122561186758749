import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-update-nic-from-setup-vm-instance',
  templateUrl: './update-nic-from-setup-vm-instance.component.html',
  styleUrls: ['./update-nic-from-setup-vm-instance.component.css']
})
export class UpdateNicFromSetupVmInstanceComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = environment.apiUrl+`api/range/vm/setupvminstances/${param["vmId"]}/nics/${param["nicId"]}/update`
      }
    })
  }

}
