<div class="selected-content-container">
    <div *ngIf="details" class="panel-title">{{'outlineEditor.selectedContentDetails' | translate}}</div>
    <div *ngIf="details" class="selected-content-details">
        <div class="detail">
            <b style="font-size: 14px;">{{'outlineEditor.name' | translate}} : </b><font>{{details.name}}</font>
        </div>
        <div class="detail">
            <b style="font-size: 14px;">{{'outlineEditor.type' | translate}} : </b><font>{{details.type}}</font>
        </div>
        <div class="detail">
            <b style="font-size: 14px;">{{'outlineEditor.orderValue' | translate}} : </b><font>{{details.orderValue}}</font>
        </div>
        <div *ngIf="details.type == 'HtmlContent'" class="detail">
            <b style="font-size: 14px;">{{'outlineEditor.contentFormat' | translate}} : </b><font>{{getFormat(details.contentFormat)}}</font>
        </div>
    </div>
    <div *ngIf="selectedContentId" class="panel-title">{{'outlineEditor.manageSelectedContent' | translate}}</div>
    <div *ngIf="selectedContentId" class="selected-contents-actions">
        <div class="action-container">
            <b class="button-label">{{'btn.editContent' | translate}} </b><button (click)="openContentEditPanel()" class="edit-btn btn btn-sm btn-secondary"><i class="bi bi-pencil-fill"></i></button>
        </div>
        <div class="action-container">
            <b class="button-label">{{'btn.deleteContent' | translate}} </b> <button (click)="openContentDeletePanel()" class="delete-btn btn btn-sm btn-danger"><i class="bi bi-trash-fill"></i></button>
        </div>
        <div class="action-container" *ngIf="details.mediaId != null">
            <b class="button-label">{{'btn.viewContent' | translate}} </b><button (click)="viewContentPanel()" class="edit-btn btn btn-sm btn-secondary"><i class="bi bi-play-fill"></i></button>
        </div>    
    </div>
</div>
