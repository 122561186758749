import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ResponseModel } from '../../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTenantService {

  constructor(private httpClient:HttpClient) { }
  deleteApplicationTenant(tenantId: string,appTenantId:number) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(apiUrl + "api/cms/admin/multitenancy/tenant/"+tenantId+"/applications/"+appTenantId+"/delete", { body: { id: appTenantId } });
  }
}
