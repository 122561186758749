import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserListenerService {
  private loggedUserStatusSource = new BehaviorSubject<string>('false');
  public loggedUserStatus$ = this.loggedUserStatusSource.asObservable();
  constructor(private loginService:LoginService) {
    if(loginService.isLoggedControlWithExpiration()){
      this.getLoggedUserStatus('true');
    }
    this.loggedUserStatus$.subscribe(status => window.localStorage.setItem("loggedUserStatus",status));
   }
  getLoggedUserStatus(state:string):Observable<string>{
    let ts = window.localStorage.getItem("loggedUserStatus");
    ts = state;
    this.loggedUserStatusSource.next(ts);
    return this.loggedUserStatus$;
  }
}
