<!--<lib-custom-form [formId]="'createSessionTemplateFeature'"></lib-custom-form>-->
<select id="featureSelect" (change)="getData($event)" class="form-select">
    <option *ngFor="let featureType of featureList" [value]="featureType.value">{{featureType.label}}</option>
</select>
<lib-custom-form [searchForm]="true" *ngIf="featureConfiguration && show == true && loading == false" (GetSendingObjects)="getJsonData($event)" [externalFormData]="featureConfiguration"></lib-custom-form>
<br/>
<div class="clearfix" *ngIf="loading">
    <div class="spinner-border float-end" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>