import { Component, OnInit } from '@angular/core';
import { AdminManagerService } from '../../../services/admin-manager.service';
import { ActivatedRoute } from '@angular/router';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';

@Component({
  selector: 'app-change-admin-password',
  templateUrl: './change-admin-password.component.html',
  styleUrls: ['./change-admin-password.component.css']
})
export class ChangeAdminPasswordComponent implements OnInit {

  constructor(private adminManagementService:AdminManagerService,private activatedRoute:ActivatedRoute) { }
  customFormData: CustomForm;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.adminManagementService.getChangeUserPasswordForm(params['user']).subscribe({
        next:(response)=>{
          this.customFormData = response;
        }
      })
    })
  }

}
