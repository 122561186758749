<div class="revision-restore-container">
    <div class="revision-restore-message">
        {{'baseVmImage.revision.restore.restoreMessage' | translate}}
    </div>
    <div class="rollback-input">
        <label>{{'baseVmImage.revision.restore.rollback' | translate}}</label>
        <input class="form-check-input" type="checkbox" id="rollback"/>
    </div>
    <button (click)="restore()" class="restore-btn btn-sm btn-warning btn">{{'btn.restoreRevision' | translate}}</button>
    
</div>
