<div class="main" *ngIf="success == false">
    <div class="alert alert-info" role="alert">
       Bu işlem seçtiğiniz kursu silecektir. Onaylıyor musunuz ?
    </div>
    <input type="checkbox" (change)="checkChanged($event)" id="understandCheck" name="understandCheck"/><label for="understandCheck">{{'global.understandCheckboxMessage' | translate}}</label>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="deleteCourse()" [disabled]="checkedUnderstandCheck == false"><i class="bi bi-trash"></i> Sil</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
<div class="successDiv" *ngIf="success">
    <div class="successContent">
        <div class="successMessage">
            <i class="bi bi-check-circle-fill checkIcon"></i><br/>
            <font class="checkMessage">{{'global.deletedSuccessfully' | translate}}</font>
        </div>
        <br/>
        <br/>
        <div class="successButtons">
            <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.close' | translate}}</button>
        </div>

    </div>
</div>