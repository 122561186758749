import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';
import { ApiUrlService } from 'projects/usorta-library/src/lib/services/api/api-url.service';
import { Observable } from 'rxjs';
import { ActiveUserSessionsResponse } from '../models/activeSessionsResponse';
import { SingleResponseModel } from 'projects/usorta-library/src/lib/models/singleResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountManagerService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  deleteServiceAccount(userId:string):Observable<ResponseModel>{
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(apiUrl+"api/accounts/service-account/"+userId+"/delete",{body:{userId:userId}});
  }
  getServiceSessions(userId:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<ActiveUserSessionsResponse>>(`${apiUrl}api/accounts/service-accounts/${userId}/sessions`)
  }
  getServiceAccount(userId:string){
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<any>>(`${apiUrl}api/accounts/service-account/${userId}`)
  }
}
