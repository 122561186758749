<h4>Locale Details</h4>
<hr>
<div *ngIf="loading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
<div *ngIf="localeData" style="padding-bottom: 20px;">
<div class="mb-3">
    <b>Id :</b><font>{{localeData.id}}</font>
</div>
<div class="mb-3">
    <b>Name :</b><font>{{localeData.name}}</font>
</div>
<div class="mb-3">
    <b>Culture Code :</b><font>{{localeData.cultureCode}}</font>
</div>
<div class="mb-3">
    <b>Active :</b><font>{{localeData.active}}</font>
</div>
<div class="mb-3">
    <b>Iso 639P1 :</b><font>{{localeData.iso639P1 ?? 'Not Set'}}</font>
</div>
<div class="mb-3">
    <b>Iso 639P2 :</b><font>{{localeData.iso639P2 ?? 'Not Set'}}</font>
</div>
<div class="mb-3">
    <b>Wildcard Locale Id :</b><font>{{localeData.wildcardLocaleId}}</font>
</div>
<div class="mb-3">
    <b>Parent Locale :</b><font>{{localeData.parentLocale ?? 'Not Set'}}</font>
</div>
</div>
