<h5>Labda Bulunan Sanal Makineyi Güncelle</h5>
<hr>
<form *ngIf="vmInfo && networks">
    <div class="mb-3">
        <label for="identifier" class="form-label">Tanımlayıcı Ad</label>
        <input type="text" class="form-control" [value]="vmInfo.identifier" autocomplete="off"  id="identifier" placeholder="">
      </div>
    <div class="mb-3">
        <label for="name" class="form-label">Ad</label>
        <input type="text" class="form-control" [value]="vmInfo.name"  autocomplete="off"  id="name" placeholder="">
      </div>
    <div class="mb-3">
        <label for="baseVMId"><b>Seçilen Sanal Makine :</b> {{vmInfo.baseVMId}}</label>
      </div>
    <div *ngIf="vmInfo && networks">
        <div *ngFor="let nic of vmInfo.nicMappings">{{nic.nicName}}
        <select class="form-select" [id]="'network_'+nic.nicName">
            <option disabled [value]="">Network Seçin</option>
            <option *ngFor="let network of networks" [selected]="network.value == nic.network" [value]="network.value">{{network.label}}</option>
        </select>
        <div class="mb-3">
            <label class="form-label">IP Adresi</label>
            <input type="text" class="form-control" autocomplete="off" [value]="nic.ipAddress" [id]="'ipAddress_'+nic.nicName">
          </div>
        </div>
    </div>
    <div class="mb-3">
        <label for="identifier" class="form-label">Notlar</label>
        <textarea type="text" class="form-control" [value]="vmInfo.notes" autocomplete="off"  id="notes" placeholder=""></textarea>
      </div>
      <button class="btn btn-primary" (click)="updateVm()">Güncelle</button>
</form>
