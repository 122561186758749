<h5>{{'sessionManagement.members' | translate}} <button class="btn btn-sm btn-warning" [title]="'sessionManagement.addNewMember' | translate" (click)="addNewMember()"><i
            class="bi bi-plus-lg"></i></button></h5>
<hr>
<div class="members">
    <div class="memberItem" *ngFor="let member of members">
        <div class="memberol">
            <div class="memberRow">
                <div class="left"><i class="bi bi-person-circle"></i> {{member.displayName}}</div>
                <div class="right"><span style="vertical-align: text-bottom;" class="badge rounded-pill bg-dark">{{getStatusText(member.status)}}<div
                            [class]="'userStatus '+getStatusClass(member.status)"></div></span>&nbsp;<i
                        (click)="removeSessionMember(member.memberId)" class="bi bi-trash-fill trash"></i></div>
            </div>
            <div class="memberRow"></div>
        </div>
    </div>
</div>