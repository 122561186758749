<div class="formMain form-maroon-border form-default-border">
    <h4>{{'accessControl.roleDetails' | translate}}</h4>
    <div class="mb-3">
        <div class="listItem">
            <b>{{'accessControl.roleId' | translate}} : </b><font class="itemText">{{details.id}}</font>
        </div>
        <div class="listItem">
            <b>{{'accessControl.roleName' | translate}} : </b><font class="itemText">{{details.name}}</font>
        </div>
        <div class="listItem">
            <b>{{'accessControl.active' | translate}} : </b><input type="checkbox" class="form-check-input" onclick="return false" [checked]="details.active"/>
        </div>
    </div>
</div>
<lib-custom-table [externalAPIUrl]="tableUrl"></lib-custom-table>
