<lib-custom-form [formBottomElement]="formBottomInputs" [formId]="'createSessionTemplate'" (formResponseOut)="getResponse($event)" [getFormInput]="requestForm" (GetFormGroup)="getFormGroup($event)"></lib-custom-form>
<div id="formBottomInputs">
    <div class="form-title-div">
        <font class="form-subtitle-content">{{'addSessionTemplate.addFeatureForSessionTemplate' | translate}}</font>
    </div>

<app-add-session-template-feature (GetFeatureItem)="saveFeature($event)">  </app-add-session-template-feature>
<div class="form-title-div">
    <font class="form-subtitle-content" *ngIf="featureItems.length > 0">{{'addSessionTemplate.featureList' | translate}}</font>
</div>

<pre style="white-space: pre-line;" *ngFor="let featureItem of featureItems">
    {{featureItem.typeId}} - {{featureItem.configuration}}
</pre>
<br/>
<app-add-session-template-item (templateItemData)="getTemplateItemType($event)">

</app-add-session-template-item>


<pre style="white-space: pre-line;" *ngFor="let templateItem of templateItems">
    {{templateItem.itemType}} - {{templateItem.itemSourceId}}
</pre>


<br/>
<div class="form-title-div">
    <font class="form-subtitle-content">{{'addSessionTemplate.addSessionParameters' | translate}}</font>
</div>
<lib-dynamic-form-to-json *ngIf="elementList.length > 0" [formElements]="elementList" (dataChanges)="getDataChanges($event)"></lib-dynamic-form-to-json>
</div>
<br/><br/>