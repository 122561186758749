import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-feature-type-table',
  templateUrl: './feature-type-table.component.html',
  styleUrls: ['./feature-type-table.component.css']
})
export class FeatureTypeTableComponent implements OnInit {
  featureTypesTableUrl = environment.apiUrl+"api/v2/sessions/featuretypes"
  constructor() { }

  ngOnInit(): void {
  }

}
