import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-get-localized-string-table-with-key',
  templateUrl: './get-localized-string-table-with-key.component.html',
  styleUrls: ['./get-localized-string-table-with-key.component.css']
})
export class GetLocalizedStringTableWithKeyComponent implements OnInit {
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe({
      next:(params)=>{
        this.tableUrl = environment.apiUrl + "api/cms/admin/localization/localizedstring/tablewithkey?key="+params["key"];
      }
    })
  }

}
