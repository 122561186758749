<div class="update-info-container formMain form-maroon-border form-default-border">
<h5>{{'baseVmImage.updateInfoForm' | translate}}</h5>

<div style="display:none;" *ngIf="loaded == true" class="inputFormats">
    <label>{{'baseVmImage.updateInfoForm.format' | translate}}</label>
    <select  (change)="changeFormat($event)" class="form-select" id="format" name="format">
        <!--<option [selected]="format[0] == 0" [value]="0">PlainText</option>
        <option [selected]="format[0] == 1" [value]="1">HTML</option>
        <option [selected]="format[0] == 2" [value]="2">Markdown</option>
        <option [selected]="format[0] == 3" [value]="3">Json</option>
        <option [selected]="format[0] == 4" [value]="4">Xml</option>-->
        <option [selected]="true" [value]="5">Layout</option>
    </select>
</div>
<div class="createRevision">
    <label for="createRevisionInput" style="margin-right: 3px;">{{'baseVmImage.updateInfoForm.createRevision' | translate}}</label> <input (change)="createRevisionChange()" id="createRevisionInput" type="checkbox" class="form-check-input"/>
    <br/>
    <label *ngIf="createRevision">{{'baseVmImage.updateInfoForm.revisionName' | translate}}</label>
    <input *ngIf="createRevision" class="form-control" type="text" id="revisionName" />
</div>
<br/>
<lib-typed-strings *ngIf="loaded == true" [defaultValue]="currentInfo ? currentInfo.content : ''" (getValue)="getValueFnc($event)" [inputType]="format"></lib-typed-strings>
<hr/>
<button (click)="update()" class="btn btn-sm btn-primary">{{'btn.update' | translate}}</button>
</div>