import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserManagerService } from 'projects/management-panel/src/app/services/user-manager.service';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';

@Component({
  selector: 'lib-add-selected-role-to-users',
  templateUrl: './add-selected-role-to-users.component.html',
  styleUrls: ['./add-selected-role-to-users.component.css']
})
export class AddSelectedRoleToUsersComponent implements OnInit {
  users:any[];
  roleId:string;
  filter:string;
  constructor(private userManagementService:UserManagerService,private translateService:TranslateService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private accessControlSvc:AccessControlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.roleId = param["roleId"];
      }
    })
    this.getUsers();
  }
  changeFilter(ev:any){
    var filterInput = ev.target as HTMLInputElement;
    if(filterInput != null){
      this.filter = filterInput.value;
    }
  }
  getUsers(){
    this.userManagementService.getUsers().subscribe({
      next:(response)=>{
        this.users = response.content;
      }
    })
  }
  addRole(userId:string){
this.accessControlSvc.addRoleToUser({roleId:this.roleId,userId:userId}).subscribe({
  next:(response)=>{
    this.toastrService.success(this.translateService.instant("accessControl.addedRoleToUser"));
  },error:(err)=>{
    this.toastrService.error(this.translateService.instant("accessControl.errorOccurred"));
  }
})
  }

}
