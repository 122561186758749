import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { VersionDetailsModel } from '../../models/Education/versionDetailsModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { EducationVersionUpdateModel } from '../../models/Education/educationVersionUpdateInfoModel';
import { ResponseModel } from '../../models/responseModel';
import { EducationVersionUpdateRequestModel } from '../../models/Education/educationVersionUpdateRequestModel';

@Injectable({
  providedIn: 'root'
})
export class EducationVersionService {

  constructor(private httpClient:HttpClient) { }
  /*getVersionDetails(educationId:string,versionId:number){
    return this.httpClient.get<SingleResponseModel<VersionDetailsModel>>("/assets/version-example.json");
  }*/
  getVersionDetail(versionId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<any>>(`${apiUrl}api/lms/courses/versions/${versionId}`)
  }
  getVersionUpdateInfo(versionId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<EducationVersionUpdateModel>>(`${apiUrl}api/lms/courses/versions/${versionId}/update`)
  }
  updateVersion(model:EducationVersionUpdateRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/versions/${model.versionId}/update`,model);
  }
}
