import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterListenerService {
  private footerStatusSource = new BehaviorSubject<string>('false');
  public footerStatus$ = this.footerStatusSource.asObservable();

  private footerMenuStatusSource = new BehaviorSubject<string>('false');
  public footerMenuStatus$ = this.footerMenuStatusSource.asObservable();

  constructor() {
    this.footerStatus$.subscribe(status => window.localStorage.setItem("footerStatus",status));
    this.footerMenuStatus$.subscribe(status => window.localStorage.setItem("footerMenuStatus",status));
   }
  getFooterStatus(state:string):Observable<string>{
    let ts = window.localStorage.getItem("footerStatus");
    ts = state;
    this.footerStatusSource.next(ts);
    return this.footerStatus$;
  }
  getFooterMenuStatus(state:string):Observable<string>{
    let ts = window.localStorage.getItem("footerMenuStatus");
    ts = state;
    this.footerMenuStatusSource.next(ts);
    return this.footerMenuStatus$;
  }
}
