import { Component, OnInit } from '@angular/core';
import { EducationService } from '../../../services/education/education.service';
import { EducationOutlineModel } from '../../../models/Education/educationOutline';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UpdateEducationSectionRequestModel } from '../../../models/Education/updateEducationSectionRequestModel';
import { TranslateService } from '@ngx-translate/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { EducationOutlineUpdateModel } from '../../../models/Education/educationOutlineUpdateModel';
import { UpdateSectionRequestModel } from '../../../models/Education/updateSectionModel';

@Component({
  selector: 'lib-update-education-section',
  templateUrl: './update-education-section.component.html',
  styleUrls: ['./update-education-section.component.css']
})
export class UpdateEducationSectionComponent implements OnInit {
  sectionId:string;
  versionId:string;
  sectionsList:ListItemModel[];
  currentSection:EducationOutlineUpdateModel;
  loading:boolean = false;
  constructor(private translateService:TranslateService,private activatedRoute: ActivatedRoute, private educationService: EducationService, private toastrService: ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.sectionId = param["sectionId"]
        this.getSection();
      }
    })

  }
  getList(){
    this.educationService.getSectionListItem(this.versionId).subscribe({
      next:(response)=>{
        this.sectionsList = response.content;
        setTimeout(()=>{
          this.prepareOptions()
        },50);
      }
    })
  }
  getSection(){
    this.educationService.getEducationOutlineUpdateInfo(this.sectionId).subscribe({
      next:(response)=>{
        this.currentSection = response.content;
        this.versionId = response.content.versionId;
        this.getList();
      }
    })
  }
  prepareOptions(){
    var checks = document.getElementsByClassName("optionsClass")
    for (let index = 0; index < checks.length; index++) {
      const element = checks[index] as HTMLInputElement;
      if(this.currentSection.options & parseInt(element.value)){
        element.checked = true;
      }
    }
  }
  getOptionsVal(){
    var opt = 0;
    var checks = document.getElementsByClassName("optionsClass")
    for (let index = 0; index < checks.length; index++) {
      const element = checks[index] as HTMLInputElement;
      if(element.checked){
        opt+=parseInt(element.value);
      }
      
    }
    return opt;
  }
  updateSection(){
    var sectionName = (document.getElementById("sectionName") as HTMLInputElement).value;
    var preIcon = (document.getElementById("preIcon") as HTMLSelectElement).value;
    var orderValue = parseInt((document.getElementById("orderValue") as HTMLSelectElement).value);
    var status = parseInt((document.getElementById("status") as HTMLSelectElement).value);
    var parentId = (document.getElementById("parentId") as HTMLSelectElement).value;
    if(sectionName.trim() == ""){
      this.toastrService.error(this.translateService.instant("courseManagement.courseNameCannotBeEmpty"));
      throw "Hata";
    }
    var addSectionReq:UpdateSectionRequestModel = {
      parentId: parentId == "null" ? undefined : parentId,

      name: sectionName,
      icon: parseInt(preIcon),
      options: this.getOptionsVal(),
      status: status,
      orderValue: orderValue,
      itemId: this.currentSection.itemId
    }
    this.loading = true;
    this.educationService.updateEducationSection(this.currentSection.itemId,addSectionReq).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("Bölüm güncellendi"));
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("Bir hata oluştu"));
      }
    })
  }
}
