import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-education-themes-table',
  templateUrl: './education-themes-table.component.html',
  styleUrls: ['./education-themes-table.component.css']
})
export class EducationThemesTableComponent implements OnInit {
  apiUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
  }

}
