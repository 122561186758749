<div class="">
    <h4 style="border-bottom: 1px solid black; display: inline-block;"> {{'accessControl.unassignedRoles' | translate}}</h4>
    <div class="mb-3">
        
        <!--<select id="roleSelectItem" *ngIf="loaded" class="form-select">
            <option *ngFor="let role of roleList" [value]="role.value">
                {{role.label}}
            </option>
        </select>-->
        <div class="roleList">
            <div class="roleItem" *ngFor="let role of roleList">
                <ul class="list-group">
                    <li class="list-group-item mb-3">{{role.label}} &nbsp; <i (click)="addRole(role.value)" style="cursor: pointer; color: rgb(54, 148, 0); font-size: 18px; float: right;" class="bi bi-plus-lg"></i></li>
                </ul>
            </div>
        </div>
        <br/>
        
      </div>
</div>
