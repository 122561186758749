import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-localized-item-menu-list',
  templateUrl: './localized-item-menu-list.component.html',
  styleUrls: ['./localized-item-menu-list.component.css']
})
export class LocalizedItemMenuListComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param=>{
        this.tableUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/menuitem/"+param["itemid"]+"/localize/list"
      })
    })
  }

}
