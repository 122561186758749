<form class="formMain form-default-border form-maroon-border" [formGroup]="addCategoryTreeForm">
  <div class="form-title-div">
    <font class="form-title-content">{{"categoryTrees.addNewCategoryTree" | translate}}</font>
</div>
  <div class="mb-3">
    <label for="name" class="form-label mainLabel">{{"categoryTrees.categoryTreeName" | translate}}</label>
    <input type="text" class="form-control" id="name" formControlName="name" placeholder="">
  </div>
<div class="mb-3">
    <label for="description" class="form-label mainLabel">{{"global.description" | translate}}</label>
    <textarea type="text" formControlName="description" class="form-control" id="description" placeholder=""></textarea>
  </div>
  <div class="form-check">
    <label class="form-check-label mainLabel" for="active">
      {{"global.active" | translate}}
      </label>
    <input class="form-check-input" formControlName="active" type="checkbox" value="" id="active">
  </div>
  <div class="optionsMainDiv mb-3">
    <b>{{"categoryTrees.options" | translate}} </b>
    <div *ngFor="let option of categoryTreeOptions">
        <label class="form-check-label optionsLabel mainLabel"> <input class="form-check-input optionsCheckbox"
                type="checkbox" [value]="option.value" /> {{option.key}} </label>
    </div>

</div>
<div style="text-align: right;">
  <button [disabled]="loading" class="btn btn-sm btn-success" (click)="addCategoryTree()">{{"btn.add" | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
</div>

</form>
