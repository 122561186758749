<div class="mb-3">
    <label for="name" class="form-label">{{'hostprovidertype.name' | translate}}</label>
    <input type="email" class="form-control" id="name" placeholder="">
  </div>
  <div class="mb-3">
    <label for="serviceClassName" class="form-label">{{'hostprovidertype.serviceclassname' | translate}}</label>
    <input type="email" class="form-control" id="serviceClassName" placeholder="">
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="enabled">
    <label class="form-check-label" for="enabled">
      {{'hostprovidertype.enabled' | translate}}
    </label>
  </div>
  <div class="mb-3">
    <label for="description" class="form-label">{{'hostprovidertype.description' | translate}}</label>
    <textarea class="form-control" id="description" rows="3"></textarea>
  </div>
  <div class="mb-3">
    <div class="">{{'hostprovidertype.editorFormat' | translate}}</div>
    <div class="">
    <button [disabled]="editorType == 'json'" (click)="changeEditorLanguage('json')" class="btn btn-primary btn-sm">JSON</button>
    <button [disabled]="editorType == 'yaml'" (click)="changeEditorLanguage('yaml')" class="btn btn-primary ms-2 btn-sm">YML</button>
    <button [disabled]="editorType == 'xml'" (click)="changeEditorLanguage('xml')" class="btn btn-primary ms-2 btn-sm">XML</button>
    
  </div>
    
    <label for="defaultConfiguration" class="form-label">{{'hostprovidertype.defaultConfiguration' | translate}}</label>
    <div style="height: 300px;" class="defaultConfigurationMonaco" id="defaultConfiguration">

    </div>
  </div>
  <div class="mb-3">
    <button class="btn btn-primary btn-sm" (click)="updateHostProviderType()">{{'btn.update' | translate}}</button>
  </div>
