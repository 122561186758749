<div class="formMain form-default-border form-maroon-border">
    <h4 style="border-bottom:1px solid black; display: inline-block;">{{'accessControl.assignedRoles' | translate}}</h4>
    <br/>
    <button (click)="assignRolePage()" class="mb-3 btn btn-primary btn-sm">{{'btn.assignRole' | translate}}</button>
    <div class="roleList">
        <div class="roleItem" *ngFor="let role of assignedRoles">
            <ul class="list-group">
                <li class="list-group-item mb-3">{{role.name}} &nbsp; <i (click)="removeRole(role.id)" style="cursor: pointer; color: red; font-size: 18px; float: right;" class="bi bi-trash-fill"></i></li>
            </ul>
        </div>
    </div>
    <lib-add-role-to-service (process)="refresh()" *ngIf="assignedRoles" [assignedRoles]="assignedRoles"></lib-add-role-to-service>
</div>