import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TenantResponseModel } from '../../models/TenantModels/CmsTenant/tenantResponseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ResponseModel } from '../../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export abstract class TenantService {

  constructor() { }
  public abstract getAppsettings(appId:string,tenantId?:string): Observable<SingleResponseModel<JSON>>;
  public abstract getCSS():Observable<any>;
  public abstract getTenantResponse(id:string):Observable<SingleResponseModel<TenantResponseModel>>
  public abstract deleteTenant(id:string):Observable<ResponseModel>
}
