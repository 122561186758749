<form class="formMain form-default-border form-maroon-border" *ngIf="parentsLoaded" [formGroup]="createQuestionSetForm">
  <div class="form-title-div">
    <font class="form-title-content">{{'questions.createQuestionSetTitle' | translate}}</font>
</div>
  <div class="mb-3">
    <label for="name" class="form-label mainLabel">{{'questionSet.questionSetName' | translate}}</label>
    <input type="text" class="form-control" id="name" name="name" formControlName="name">
  </div>
  <div class="mb-3">
    <label class="mainLabel">{{'global.selectImage'}}</label><br/>
    <input type="file" (change)="setImage($event)">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [autoCrop]="true" (imageCropped)="croppedImage($event)" [output]="'base64'" [maintainAspectRatio]="false" ></image-cropper>
  </div>

  <div class="mb-3">
    <input type="hidden" class="form-control" id="image" name="image" formControlName="image">
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="enabled" name="enabled" formControlName="enabled">
    <label class="form-check-label mainLabel" for="enabled">
      {{'global.active' | translate}}
    </label>
  </div>
  <div class="mb-3">
    <label for="color" class="form-label mainLabel" style="vertical-align: middle;">{{'questionSet.color' | translate}} : </label>
    <input style="vertical-align: middle;" type="color" id="color" name="color" formControlName="color">
  </div>
  <div class="mb-3">
    <label for="parentId" class="form-label mainLabel">{{'questionSet.parent' | translate}}</label>
    <select class="form-control" id="parentId" name="parentId" formControlName="parentId">
      <option [value]="null">Not Selected</option>
      <option *ngFor="let parent of parents" [value]="parent.value">{{parent.label}}</option>
    </select>
  </div>

<div style="text-align: right;">
  <button (click)="addQuestionSet()" onclick="return false" class="btn btn-success btn-sm" style="float: right;">{{'btn.add' | translate}}</button>
</div>
<br/>
</form>
