<div class="dashboardMain" *ngIf="dashboardResponse">
    <h3>Host Dashboard</h3>
    <hr />
    <lib-host-dashboard-basic style="display:block;" class="mb-5" [hostBasic]="dashboardResponse"
        [id]="dashboardResponse.hostId"></lib-host-dashboard-basic>

    <div class="row mb-5 counters">
        <lib-host-dashboard-counter class="col-lg-2 col-md-4 col-sm-6 col-xs-12"
            *ngFor="let counter of dashboardResponse.counters" [hostDashobardCounter]="counter"
            [id]="counter.key"></lib-host-dashboard-counter>
    </div>
    <div class="row">
        <button class="btn btn-success col-md-4" (click)="changeCounter('c1',getRandomNumber())">Change (Counter
            1)</button>
        <button class="btn btn-success col-md-4" (click)="changeCounter('c2',getRandomNumber())">Change (Counter 2)</button>
    </div>
    <div class="row mb-5 switches">
        <lib-host-dashboard-switch (outSwitch)="getSwitchRef($event)" class="col-lg-2 col-md-3" *ngFor="let switch of dashboardResponse.switches"
            [hostDashboardSwitch]="switch" [id]="switch.key"></lib-host-dashboard-switch>

    </div>
    <div class="row">
        <button class="btn btn-danger col-md-6" (click)="changeSwitch('swicthkey1',false)">Change (Switch 1
            false)</button>
        <button class="btn btn-success col-md-6" (click)="changeSwitch('swicthkey1',true)">Change (Swtich 1 true)</button>
        <button class="btn btn-danger col-md-6" (click)="changeSwitch('swicthkey2',false)">Change (Switch 2
            false)</button>
        <button class="btn btn-success col-md-6" (click)="changeSwitch('swicthkey2',true)">Change (Swtich 2 true)</button>
        <button class="btn btn-danger col-md-6" (click)="changeSwitch('swicthkey3',false)">Change (Switch 3
            false)</button>
        <button class="btn btn-success col-md-6" (click)="changeSwitch('swicthkey3',true)">Change (Swtich 3 true)</button>
    </div>
    <div class="row mb-5">
        <lib-host-dashboard-chart class="col-md-6" *ngFor="let chart of dashboardResponse.charts" [chartKey]="chart.key"
            (outChart)="saveChart($event)" [chartData]="createChartModels[chart.key]" [hostDashboardChart]="chart">

        </lib-host-dashboard-chart>
        <div class="row">
            <button class="btn btn-success col-md-4" (click)="addRandomData('key1')">Ekle (Chart 1)</button>
            <button class="btn btn-success col-md-4" (click)="addRandomData('key2')">Ekle (Chart 2)</button>
            <button class="btn btn-success col-md-4" (click)="addRandomData('key3')">Ekle (Chart 3)</button>
        </div>

    </div>

</div>