<div class="themeDetailMain" *ngIf="themeDetail">
    <h5>Tema Detayları</h5>
    <hr>
    <div class="themeBasic">
    <div class="mb-3">
        <b>Tema Adı : </b>
        <font>{{themeDetail.name}}</font>
    </div>
    <div class="mb-3">
        <b>ThemeKey : </b>
        <font>{{themeDetail.themeKey}}</font>
    </div>
    <div class="mb-3">
        <b>Aktif : </b><input type="checkbox" [checked]="themeDetail.active" onclick="return false" [readOnly]="true">
    </div>
</div>
    <div class="mb-3 themeDetailDiv">
        <b>Styles</b><hr>
        <pre>{{themeDetail.styles}}</pre>
    </div>
    <div class="mb-3 themeDetailDiv">
        <b>Raw Styles</b><hr>
        <pre>{{themeDetail.rawStyle}}</pre>
    </div>
    <div class="mb-3 themeDetailDiv">
        <b>ThemeData</b><hr>
        <pre>{{themeDetail.themeData}}</pre>
    </div>
    <div class="mb-3 themeDetailDiv">
        <b>Scripts</b><hr>
        <pre>{{themeDetail.scripts}}</pre>
    </div>
    <div class="mb-3 themeDetailDiv">
        <b>TextOverrides</b><hr>
        <pre>{{themeDetail.textOverrides}}</pre>
    </div>
</div>
