<div class="vm-info-container">
    <div class="info-actions">
    <button class="btn btn-sm btn-secondary editBtn" (click)="editInfo()"><i class="bi bi-pencil-fill" ></i> {{'btn.edit' | translate}}</button>    
    <button (click)="manageInfo()" class="btn btn-sm btn-warning manageBtn"><i class="bi bi-pencil-fill" ></i> {{'btn.manage' | translate}}</button>    
</div>
    
    <!--<div *ngIf="info && info.content" class="vm-info" [innerHTML]="info.content">    </div>-->


    <div class="d-flex justify-content-center" *ngIf="loading">
        <br/>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    <lib-format-viewer *ngIf="info && info.content" [content]="info.content" [format]="info.format"></lib-format-viewer>
</div>
