<div class="formMain form-maroon-border form-default-border">
    <h3>{{'mediaContent.detailsPageTitle' | translate}}</h3>
    <hr>
    <div class="mediaInfoItem">
        <b class="mediaInfoTitle">{{'mediaContentDetails.name' | translate}} : </b>
        <font class="mediaInfoValue">{{mediaContent.name}}</font>
    </div>
    <div class="mediaInfoItem" *ngIf="mediaContent.description != ''">
        <b class="mediaInfoTitle">{{'mediaContentDetails.description' | translate}} : </b>
        <font class="mediaInfoValue">{{mediaContent.description}}</font>
    </div>
    <div class="mediaInfoItem">
        <b class="mediaInfoTitle">{{'mediaContentDetails.mediaType' | translate}} : </b>
        <font class="mediaInfoValue">{{mediaContent.mediaType}}</font>
    </div>
    <div class="mediaInfoItem">
        <b class="mediaInfoTitle">{{'mediaContentDetails.status' | translate}} : </b>
        <font class="mediaInfoValue">{{mediaContent.status}}</font>
    </div>
    <div class="mediaInfoItem" *ngIf="mediaContent.filename != ''">
        <b class="mediaInfoTitle">{{'mediaContentDetails.filename' | translate}} : </b>
        <font class="mediaInfoValue">{{mediaContent.filename}}</font>
    </div>
    <div class="mediaInfoItem" *ngIf="mediaContent.status == 'Ready'">
        <b class="mediaInfoTitle">{{'mediaContentDetails.preview' | translate}}</b>
        <br/>
        <lib-cms-media-viewer [mediaId]="mediaContent.id"></lib-cms-media-viewer>
    </div>
</div>
