import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { VmInstanceTypeMappingsInfoModel } from '../../../models/vmInstanceTypeMappings/vmInstanceTypeMappingsInfoModel';

@Component({
  selector: 'lib-vm-instance-type-mapping-note',
  templateUrl: './vm-instance-type-mapping-note.component.html',
  styleUrls: ['./vm-instance-type-mapping-note.component.css']
})
export class VmInstanceTypeMappingNoteComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private vmInstanceService:VmInstanceService) { }
  mappingId:string;
  mappingInfo:VmInstanceTypeMappingsInfoModel;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.mappingId = param["mappingId"];
        this.getMappingInfo();
      }
    })
  }
  getMappingInfo(){
    this.vmInstanceService.getInstanceTypeMappingInfo(this.mappingId).subscribe({
      next:(response)=>{
        this.mappingInfo = response.content;
      }
    })
  }

}
