import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CustomFormJsonFormModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/customFormJsonFormModel';
import { CustomFormJsonPageModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/customFormJsonPageModel';
import { CustomFormJsonInputAndValidateModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/inputModel';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { FormElement } from 'projects/usorta-library/src/lib/models/CustomFormModels/formElement';
import { UpdateSessionTemplateFeatureRequest } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/updateSessionTemplateFeatureRequest';
import { CustomFormJsonService } from 'projects/usorta-library/src/lib/services/customFormJson/custom-form-json.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-update-session-template-feature',
  templateUrl: './update-session-template-feature.component.html',
  styleUrls: ['./update-session-template-feature.component.css']
})
export class UpdateSessionTemplateFeatureComponent implements OnInit {
  templateId:number;
  featureId:number;
  apiUrl:string;
  updateFormElements:FormElement[];
  formData:CustomForm;
  updateFeatureUrl:string;
  formBottomInput:HTMLElement;
  constructor(private translateService:TranslateService, private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private templateService:SessionTemplateService,private customFormJson:CustomFormJsonService) { }

  ngOnInit(): void {
    this.formBottomInput = document.getElementById("deactiveFeatureDiv") as HTMLElement;
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.templateId = param["templateId"];
        this.featureId = param["featureId"];
        //this.updateFeatureUrl = `${environment.apiUrl}api/v2/sessions/templates/${this.templateId}/features/${this.featureId}/update`;
        //this.apiUrl = `https://localhost:61140/api/v2/sessions/templates/${this.templateId}/features/${this.featureId}/form`
        this.getSessionTemplateFeatureUpdateForm().then((val)=>{
          var CFJFormModel:CustomFormJsonFormModel = {action:"#",enctype:"application/json",method:"post"};
          var CFJPageModel:CustomFormJsonPageModel = {layout:"1column",showReset:false,title:this.translateService.instant("sessionTemplate.updateFeature")};
          var CFJElement:CustomFormJsonInputAndValidateModel = {input:{}};
          var CFJCreatedForm = this.customFormJson.createCustomFormJson(CFJFormModel,CFJPageModel,[CFJElement]);
          CFJCreatedForm.pages[0].elements = this.updateFormElements;
          this.formData = CFJCreatedForm;
        });
      }
    })
  }
  getFormJson(updateFeatureForm:any){
    console.log(updateFeatureForm);
    var enabledFeature:boolean = (document.getElementById("deactiveFeature") as HTMLInputElement).checked ? false : true;
    var request:UpdateSessionTemplateFeatureRequest = {configuration:JSON.stringify(updateFeatureForm),enabled:enabledFeature,templateFeatureId:this.featureId,templateId:this.templateId};
    this.templateService.updateSessionTemplateFeature(request).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("global.updateSuccessfull"));
      },
      error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.updateError"))
      }

    })
  }
  getSessionTemplateFeatureUpdateForm():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.templateService.getSessionTemplateFeatureUpdateElements(this.templateId,this.featureId).subscribe({
        next:(response)=>{
          this.updateFormElements = response.content;
          resolve(true);
        },error:(err)=>{
          resolve(false);
        }
      })
    })
  }

}
