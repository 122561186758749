<!--<h3>{{'sessionTemplate.basicUpdate' | translate}}</h3>-->
<div class="formMain form-default-border form-maroon-border">
<div *ngIf="sessionTemplateUpdateFormData">
    <lib-custom-form [formBottomElement]="formBottomElement" [searchForm]="true" *ngIf="sessionTemplateUpdateFormData" (formResponseOut)="setFormResponse($event)" [externalFormData]="sessionTemplateUpdateFormData" (GetFormGroup)="getFormGroup($event)" [getFormInput]="requestFormGroup"></lib-custom-form>

</div>
<div #formBottomElement>
<div id="updateTemplateBottom">
    <button (click)="newString()" class="btn btn-warning btn-sm">{{'addStringValue' | translate}}</button> &nbsp;
    <button (click)="newBoolean()" class="btn btn-warning btn-sm">{{'addBooleanValue' | translate}}</button> &nbsp;
    <button (click)="formatCode()" class="btn btn-info btn-sm">{{'editCode' | translate}}</button> &nbsp;
    <br/><br/>
    <div  class="dataEditor">
        <div class="jsonEditor" style="height: 300px; overflow:hidden;">

        </div>
    </div>
    <br/>
    <button (click)="saveDataJson()" class="btn btn-success btn-sm">{{'saveJsonValue' | translate}}</button>
</div>
<br/>
<!--<h5>{{'sessionTemplate.updatefeatureanditems' | translate}}</h5>
<hr>-->
<h5>{{'sessionTemplate.relatedItems' | translate}}</h5>
<div *ngIf="sessionTemplate && itemResponse == false">
    <ul class="list-group">
        <li *ngFor="let item of sessionTemplate.items" class="list-group-item d-flex justify-content-between align-items-start">
            <i class="bi bi-box"></i>
            <div class="ms-2 me-auto">

            <div class="fw-bold">{{item.itemType}} - {{getFeatureSourceIdName(item.itemType,item.itemSourceId)}}</div>

          </div>
          <button class="btn btn-sm btn-danger" *ngIf="item.id" (click)="showDeleteTemplateItemModal(item.id)"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button> &nbsp;
        </li>
    </ul>
</div>
<br/><br/>
<div style="min-height: 200px;">
    <h6>{{'sessionManagement.addNewItem' | translate}}</h6>
    
    <app-add-session-template-item (templateItemData)="addItemFromForm($event)"></app-add-session-template-item>
</div>
<br/>
<h5>{{'sessionTemplate.relatedFeatures' | translate}}</h5>
<div *ngIf="sessionTemplate && featureNameList">
    <ul class="list-group">
        <li *ngFor="let feature of sessionTemplate.features" class="list-group-item d-flex justify-content-between align-items-start">
            <i class="bi bi-box"></i>
            <div class="ms-2 me-auto">

            <div class="fw-bold">{{getFeatureNameByFeatureId(feature.featureType)}}</div>

          </div>
          <button class="btn btn-sm btn-danger" (click)="showDeleteTemplateFeatureModal(feature.id)"><i class="bi bi-trash"></i> {{'btn.delete' | translate}}</button> &nbsp;
          <button class="btn btn-sm btn-warning" (click)="showUpdateTemplateFeatureModal(feature.id)"><i class="bi bi-pencil-square"></i> {{'btn.update' | translate}}</button>
        </li>
    </ul>
</div>
<br/><br/>
<div style="min-height: 400px;">
    <h6>{{'sessionManagement.addNewFeature' | translate}}</h6>
    <app-add-session-template-feature (GetFeatureItem)="addFeatureFromForm($event)"></app-add-session-template-feature>
</div>

<br/>
</div>
</div>