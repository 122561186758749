import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-localized-menu-details',
  templateUrl: './localized-menu-details.component.html',
  styleUrls: ['./localized-menu-details.component.css']
})
export class LocalizedMenuDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
