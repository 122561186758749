import { Component, OnInit } from '@angular/core';
import { LabItemGridModel } from '../../../models/VirtualLabModels/labItemGridModel';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LabSummaryResponse } from '../../../models/VirtualLabModels/labSummaryResponse';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'lib-lab-dashboard',
  templateUrl: './lab-dashboard.component.html',
  styleUrls: ['./lab-dashboard.component.css']
})
export class LabDashboardComponent implements OnInit {
  sharedLabs:LabItemGridModel[];
  labDrafts:LabItemGridModel[];
  labBlueprints:LabItemGridModel[];
  apiUrl:string = environment.apiUrl;
  selectedItem:string|null;
  selectedType:string|null;
  summary:LabSummaryResponse;
  constructor(private toastrService:ToastrService,private virtualLabService:VirtualLabService,private translateService:TranslateService,private globalModalService:GlobalModalService,private globalOkCancelEvent:GlobalOkCancelEventService,private router:Router) { }

  ngOnInit(): void {
    this.getSharedLabs();
    this.getDrafts();
    this.getBlueprints();
  }
  getSharedLabs(){
    this.virtualLabService.getSharedLabs().subscribe({
      next:(response)=>{
        this.sharedLabs = response.content;
        console.log(response)
      }
    })
  }
  getDrafts(){
    this.virtualLabService.getMyLabDrafts().subscribe({
      next:(response)=>{
        this.labDrafts = response.content;
        console.log(response)
      }
    })
  }
  getBlueprints(){
    this.virtualLabService.getMyLabBlueprints().subscribe({
      next:(response)=>{
        this.labBlueprints = response.content;
        console.log(response)
      }
    })
  }
  selectLab(item:any,type:string){
    $(".lab-item.selected").removeClass("selected");
    
    $(item).addClass("selected");
    this.selectedItem = $(item).get(0).getAttribute("data-id");
    console.log(this.selectedItem);
    this.selectedType = type;
    if(type == "draft"){
      this.getDraftSummary();
    }
  }
  removeAll(ev:MouseEvent){
    if($(ev.target).hasClass("labs-container")){
      $(".lab-item.selected").removeClass("selected");
      this.selectedItem = null;
      this.selectedType = null;
    }
  }
  cloneDraftLab(){
    this.globalModalService.showModal({
      data:"modal/range/labs/drafts/"+this.selectedItem+"/clone",
      helperModals:[],
      title:"Clone Draft Lab",
      type:ModalTypes.LINK
    })
  }
  cloneBlueprintLab(){
    this.globalModalService.showModal({
      data:"modal/range/labs/blueprints/"+this.selectedItem+"/clone",
      helperModals:[],
      title:"Clone Blueprint Lab",
      type:ModalTypes.LINK
    })
  }
  cloneSharedLab(){

  }
  createDraftLab(){
    this.globalModalService.showModal({
      data:"modal/range/labs/create",
      helperModals:[],
      title:"Create Blueprint Lab",
      type:ModalTypes.LINK
    })
  }
  deleteDraftItem(){
    this.globalOkCancelEvent.showOkCancelWindow(this.translateService.instant("labDashboard.deleteLabDraftTitle"),this.translateService.instant("labDashboard.deleteLabDraftMessage"),true,undefined,undefined,false,undefined).then(x=>{
      if(x == true && this.selectedItem != null){
        this.virtualLabService.deleteLabDraft(this.selectedItem).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("labDashboard.labDraftdeletedSuccessfully"));
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }
    });
  }
  openWorkbench(){ //draft lab için workbench sayfasını açar
    this.router.navigate(["/range/labs/drafts/"+this.selectedItem+"/workbench"]);
  }
  viewMyLab(){
    this.router.navigate(["/range/labs/blueprints/my/"+this.selectedItem+"/manage"]);
  }
  viewSharedLab(){
    this.router.navigate(["/range/labs/blueprints/shared/"+this.selectedItem+"/manage"]);
  }
  deleteBlueprint(){

  }
  switchTab(type:string){
    $(".lab-item.selected").removeClass("selected");
    this.selectedItem = null;
    this.selectedType = null;
  }
  getDraftSummary(){
    if(this.selectedItem != null){
      this.virtualLabService.getLabDraftSummary(this.selectedItem).subscribe({
        next:(response)=>{
          this.summary = response.content;
          console.log(this.summary);
        }
      })
    }
  }
  getBlueprintSummary(){
    if(this.selectedItem != null){
      this.virtualLabService.getLabBlueprintSummary(this.selectedItem).subscribe({
        next:(response)=>{
          this.summary = response.content;
        }
      })
    }
  }

}
