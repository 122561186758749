import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Observable, Subscriber } from 'rxjs';
import { GlobalOkCancelEventService } from '../globalOkCancelEvent/global-ok-cancel-event.service';
import { Router } from '@angular/router';
import { RefreshTokenService } from '../auth/refresh-token.service';
declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class ChunkUploaderService {

  constructor(private globalOkCancelService:GlobalOkCancelEventService,private httpClient: HttpClient, private toastrService: ToastrService,private router:Router,private refreshTokenService:RefreshTokenService) { }
  getProgress(): Observable<{ totalCount: number, uploaded: number }> {
    return new Observable((observer) => {
      window['file_upload_observer'] = observer;
    });
  }
  async uploadFileWithChunks(file: File, endpoint: string, chunkSize: number,extras:any = {}) {
    var baseEndpoint = endpoint;
    var redirect = extras["redirect"] !== undefined ? extras["redirect"] : "";
    window["upload_error"] = false;
    let start = 0;
    let totalChunkSize = Math.ceil(file.size / chunkSize);
    console.log(totalChunkSize);
    for (let i = 1; i <= totalChunkSize; i++) {
      var fileName = i == 1 ? file.name : undefined;
      var filePart = file.slice(start, start + chunkSize);
      start += chunkSize;

      if(i == totalChunkSize && i == 1){
        endpoint += "?EndWrite=1&Begin=1"
      }
      else if (i == totalChunkSize) {
        endpoint += "?EndWrite=1";
      }else if(i == 1){
        endpoint += "?Begin=1"
      }else{
        endpoint = baseEndpoint;
      }
      if(!window["upload_error"]){
        await this.uploadChunk(filePart, endpoint, 3, fileName).then();
      }

      if (window['file_upload_observer'] != null) {
        var observer = window['file_upload_observer'] as Subscriber<{
          totalCount: number;
          uploaded: number;
        }>;
        observer.next({ totalCount: totalChunkSize, uploaded: i });
        if(window["upload_error"]){
          observer.next({ totalCount: 0, uploaded: 0 });
          this.globalOkCancelService.showOkCancelWindow("Dosya Yükle","Yükleme başarısız oldu",false,"btn.retry").then(()=>{

          })
          return;
        }
      }
    }
    if(!window['upload_error']){
      this.toastrService.success("File uploaded successfully");
      this.globalOkCancelService.showOkCancelWindow("Yükleme başarılı","Dosya sunucuya başarıyla yüklendi",false).then((x)=>{
        if(x==true){
          if(redirect !== undefined){
            this.router.navigate([redirect]);
          }
        }
      })
    }

  }
  private async uploadChunk(chunk: any, endpoint: string, retries: number = 3, name: string | undefined = undefined) {
    await this.refreshTokenService.renewIfExpired();
    var formData = new FormData();
    formData.set("file", chunk, name);
    var headers = new Headers();
    headers.set("Authorization", "Bearer " + sessionStorage.getItem("token"));
    await fetch(endpoint, {
      method: 'POST',
      body: formData,
      headers: headers,

    }).then(async (response)=>{
      if(!response.ok){
        if (retries > 0) {
          await this.uploadChunk(chunk, endpoint, retries - 1);
          console.log("tekrar deneniyor")
        } else {
          this.toastrService.error('Chunk is not uploaded');
          window["upload_error"] = true;
          return;
          
        }
      }
    })
    
    .catch(async (err)=>{
      if (retries > 0) {
        await this.uploadChunk(chunk, endpoint, retries - 1);
        console.log("tekrar deneniyor")
      } else {
        this.toastrService.error('Chunk is not uploaded');
        window["upload_error"] = true;
        return;
      }
    });

  }
}
