import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddExamModel } from '../../models/examManagementModels/addExamModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ResponseModel } from '../../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class ExamManagementService {

  constructor(private httpClient:HttpClient) { }
  addExam(addExamModel:AddExamModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lms/exams/add`,addExamModel);
  }
}
