import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { SetupVmInstanceSpecsResponse } from 'projects/usorta-library/src/lib/models/setupVMInstanceModels/setupVmInstanceSpecsResponse';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { SetupVmInstanceService } from 'projects/usorta-library/src/lib/services/setupVmInstance/setup-vm-instance.service';

@Component({
  selector: 'lib-manage-setup-instance-details',
  templateUrl: './manage-setup-instance-details.component.html',
  styleUrls: ['./manage-setup-instance-details.component.css']
})
export class ManageSetupInstanceDetailsComponent implements OnInit {
  @Input() setupInstanceId:string;
  vmSpecs:SetupVmInstanceSpecsResponse;
  constructor(private setupVmInstanceService:SetupVmInstanceService,private globalModalService:GlobalModalService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.setupVmInstanceService.getSetupVmInstanceSpecs(this.setupInstanceId).subscribe({
      next:(response)=>{
        this.vmSpecs = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  updateSpecsModal(){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/updatespecs`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.updateSpecsModalTitle"),
      type:ModalTypes.LINK
    })
  }
  addDiskModal(){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/disks/add`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.addDiskModalTitle"),
      type:ModalTypes.LINK
    })
  }
  addNicModal(){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/nics/add`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.addNicModalTitle"),
      type:ModalTypes.LINK
    })
  }
  addRemovableImageModal(){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/removableimages/add`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.addRemovableImageModalTitle"),
      type:ModalTypes.LINK
    })
  }
  updateDiskModal(diskId:string){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/disks/${diskId}/update`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.updateDiskModalTitle"),
      type:ModalTypes.LINK
    })
  }
  updateNicModal(nicId:string){
    this.globalModalService.showModal({
      data:`/modal/range/vm/setupinstance/${this.setupInstanceId}/nics/${nicId}/update`,
      helperModals:[],
      title:this.translateService.instant("setupVmInstance.updateNicModalTitle"),
      type:ModalTypes.LINK
    })
  }


}
