<div *ngIf="versionId" class="outline-management-container">
    <div class="main-toolbar">
        <div class="toolbar-item">

        </div>
    </div>
    <div id="area-container" (mousemove)="dragProcess($event)" (mouseup)="stopDrag()" class="area-container">
        <div id="left-area" class="area-main left-area">
            <lib-outline-item-panel (setPreview)="outlinePreview($event)" [versionId]="versionId"></lib-outline-item-panel>
        </div>
        <div (mousedown)="startDrag('left')"  class="slider-main slider-left"></div>
        <div id="center-area" class="area-main center-area">
            <lib-outline-item-preview (sendSelectedContent)="setSelectedContent($event)" [previewOutlineId]="previewId"></lib-outline-item-preview>
        </div>
        <div (mousedown)="startDrag('right')" class="slider-main slider-right"></div>
        <div id="right-area" class="area-main right-area">
            <div class="sub-area right-top-area">
                <lib-manage-selected-content-panel (updateContents)="updatePreviewArea()" [selectedItem]="selectedContent"></lib-manage-selected-content-panel>
            </div>
            <div class="sub-area right-bottom-area">
                <lib-objective-management-panel></lib-objective-management-panel>
            </div>
        </div>
    </div>
    <br/><br/>

</div>