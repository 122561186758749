<lib-preloader *ngIf="showTenantLoader || languageLoaded == false || (menuLoaded == false && isModal == false)"></lib-preloader>
<!--<lib-custom-navbar *ngIf="showTenantLoader == false"></lib-custom-navbar>-->
<lib-translate-mode-helper *ngIf="languageLoaded == true && showTenantLoader == false"></lib-translate-mode-helper>
<div style="min-height: calc(100vh - 170px); padding: 0px; margin: 0px;">
  <div *ngIf="showMenu && menuItems" class="mobileShowMenu d-md-none d-lg-none d-xl-none">
    <div class="mobileMenu">
      <img *ngIf="tenantInfoModel && logo" [src]="logo" />
      <button *ngIf="menuItems" class="showMobileNavbar" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu"
        aria-controls="mobileMenu">
        <i class="bi bi-list"></i>
      </button>
    </div>
  </div>
  <div *ngIf="showMenu && menuItems" class="row" style="margin-right: 0px;">
    <div class="col col-md-2 d-none d-md-block">
      <div style="max-width: 250px; position: relative;">
        <lib-custom-menu *ngIf="menuItems" [externalData]="menuItems"></lib-custom-menu>
      </div>
    </div>
    <div class="col-md-10 col-sm-12 col-xs-12">
      <div class="cookiePreferencesSelector" *ngIf="showTenantLoader == false">
        <div class="cookiePreferencesBox">
          <lib-cookie-preferences *ngIf="showCookiePreferencesBox"></lib-cookie-preferences>
        </div>

      </div>
      <div *ngIf="showTenantLoader == false && languageLoaded == true" class="container-fluid containerProperties">
        <router-outlet></router-outlet>

      </div>
    </div>
  </div>

  <div *ngIf="!showMenu">

    <div class="cookiePreferencesSelector" *ngIf="showTenantLoader == false">
      <div class="cookiePreferencesBox">
        <lib-cookie-preferences *ngIf="showCookiePreferencesBox"></lib-cookie-preferences>
      </div>

    </div>
    <div *ngIf="showTenantLoader == false" class="container-fluid containerProperties">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>




<lib-footer *ngIf="showTenantLoader == false && showFooter == true"></lib-footer>
<div class="ref" #modalRef></div>

<!--Mobil ekranlarda menünün açılması için kullanılacak offcanvas-->


<div class="offcanvas offcanvas-top" tabindex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel">
  <button type="button" class="btn-close btn-close-white text-reset offcanvasCloseBtn" data-bs-dismiss="offcanvas"
    aria-label="Close"></button>
  <div class="offcanvas-body offcanvasBody">
    <lib-custom-menu *ngIf="menuItems" [externalData]="menuItems"></lib-custom-menu>
  </div>
</div>