import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { EducationSubOutlineModel } from '../../../models/Education/educationOutline';
import { UpdateEducationSubSectionRequestModel } from '../../../models/Education/updateEducationSubSectionModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-update-education-subsection',
  templateUrl: './update-education-subsection.component.html',
  styleUrls: ['./update-education-subsection.component.css']
})
export class UpdateEducationSubsectionComponent implements OnInit {
  educationId:string;
  sectionId:number;
  subsectionId:number;
  subsection:EducationSubOutlineModel;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.educationId = param["educationId"];
        this.sectionId = parseInt(param["sectionId"]);
        this.subsectionId = parseInt(param["subsectionId"]);
        this.getSubsection();
      }
    })
  }
  getSubsection(){
    this.educationService.getEducationSubSection(this.educationId,this.sectionId,this.subsectionId).subscribe({
      next:(response)=>{
        this.subsection = response.content;
        this.initializeInputs();
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  initializeInputs(){
(document.getElementById("subsectionName") as HTMLInputElement).value = this.subsection.name;
(document.getElementById("subsectionDescription") as HTMLInputElement).value = this.subsection.description;
(document.getElementById("preIcon") as HTMLInputElement).value = this.subsection.preIcon;
(document.getElementById("postIcon") as HTMLInputElement).value = this.subsection.postIcon;
  }
  updateSubsection(){
    var subsectionName = (document.getElementById("subsectionName") as HTMLInputElement).value;
    var subsectionDescription = (document.getElementById("subsectionDescription") as HTMLInputElement).value;
    var preIcon = (document.getElementById("preIcon") as HTMLInputElement).value;
    var postIcon = (document.getElementById("postIcon") as HTMLInputElement).value;
    var updateSubsectionModel:UpdateEducationSubSectionRequestModel = {
      educationId: this.educationId,
      sectionId: this.sectionId,
      subSectionId: this.subsectionId,
      subSectionName: subsectionName,
      subSectionDescription: subsectionDescription,
      preIcon: preIcon,
      postIcon: postIcon
    }
    if(subsectionName == "" || subsectionName == null){
      this.toastrService.error(this.translateService.instant("courseManagement.subSectionNameCannotBeEmpty"));
      throw "Subsection Name cannot be blank";
    }
    this.educationService.updateEducationSubsection(this.educationId,this.sectionId,this.subsectionId,updateSubsectionModel).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("courseManagement.subsectionUpdated"));
      },
      error:(err)=>{
        this.toastrService.error(this.translateService.instant("courseManagement.subsectionUpdateError"));
      }
    })
  }

}
