import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { SingleResponseModel } from 'projects/usorta-library/src/lib/models/singleResponseModel';
import { ApiUrlService } from 'projects/usorta-library/src/lib/services/api/api-url.service';
import { Observable } from 'rxjs';
import { ActiveUserSessionsResponse } from '../models/activeSessionsResponse';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';
import { ResponseTokenModel } from 'projects/usorta-library/src/lib/models/responseTokenModel';
import { text } from 'd3';
import { ListResponseModel } from 'projects/usorta-library/src/lib/models/listResponseModel';
import { UserRoleResponse } from '../models/userRoleResponse';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {
  //keycloakApiUrl:string = "http://cybrng:3010/";
  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  
  public getUsers():Observable<any>{
    return this.httpClient.get<any>(environment.apiUrl+"api/accounts/users");
  }
  public getUserById(userId:string):Observable<any>{
    return this.httpClient.get<any>(environment.apiUrl+"api/accounts/user/"+userId);
  }
  public deleteUserById(userId:string):Observable<any>{
    return this.httpClient.delete(environment.apiUrl+"api/accounts/user/"+userId+"/delete");
  }
  public blockUser(userId:string):Observable<any>{
    return this.httpClient.post(environment.apiUrl+"api/accounts/user/"+userId+"/block",{userId:userId});
  }
  public unblockUser(userId:string):Observable<any>{
    return this.httpClient.post(environment.apiUrl+"api/accounts/user/"+userId+"/unblock",{userId:userId});
  }
  createMultipleUsers(formData:FormData,csv:boolean,xlsx:boolean,blob:boolean){
    let url:string = "";
    if(csv){
      url = environment.apiUrl + "api/accounts/user/createwithcsv"
      //url = "https://localhost:7103/api/v2/karacuha/accounts/user/createwithcsv"
    }
    else if(xlsx){
      url = environment.apiUrl + "api/accounts/user/createwithxlsx"
      //url = "https://localhost:7103/api/v2/karacuha/accounts/user/createwithxlsx"
    }else{
      throw "";
    }
    if(blob){
      return this.httpClient.post(url,formData,{
        observe:'response',
        responseType:"blob"
      })
    }else{
      return this.httpClient.post(url,formData,{
        observe:'response'
      })
    }

  }
  getChangeUserPasswordForm(userId:string):Observable<CustomForm>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/user/"+userId+"/changepassword";
    return this.httpClient.get<CustomForm>(formRequestAddr);
  }
  getUpdateUserForm(userId:string):Observable<CustomForm>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/user/"+userId+"/update";
    return this.httpClient.get<CustomForm>(formRequestAddr);
  }
  getCreateUserForm():Observable<SingleResponseModel<CustomForm>>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/user/create";
    return this.httpClient.get<SingleResponseModel<CustomForm>>(formRequestAddr);
  }
  getUserSessions(userId:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<ActiveUserSessionsResponse>>(`${apiUrl}api/accounts/users/${userId}/sessions`)
  }
  logout(sessionId:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/accounts/user/sessions/${sessionId}/logout`,{sessionId:sessionId})
  }
  userRefreshToken(refreshToken:string){
    var apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.post<SingleResponseModel<ResponseTokenModel>>(`${apiUrl}api/accounts/user/refresh-token`,{jwt:refreshToken});
  }
  getUserRealmRoles(){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.get<ListResponseModel<UserRoleResponse>>(`${apiUrl}api/accounts/roles`);
  }
}
