import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-delete-item',
  templateUrl: './session-management-delete-item.component.html',
  styleUrls: ['./session-management-delete-item.component.css']
})
export class SessionManagementDeleteItemComponent implements OnInit {

  checkedUnderstandCheck:boolean = false;
  itemId:number | null;
  sessionId:string | null;
  success:boolean = false;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private sessionService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.itemId = param['itemId'];
      this.sessionId = param['sessionId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  
  deleteItemFromSession(){
    if(this.itemId != null && this.sessionId != null){
      this.sessionService.deleteItemFromSession(this.sessionId,this.itemId).subscribe({
        next:(response)=>{
          if(response.success){
            var successMessage = this.translateService.instant("sessionManagement.itemRemoved");
            this.toastrService.success(successMessage);
            this.success = true;
          }else{
            var errorMessage = this.translateService.instant("sessionManagement.itemRemovedError");
            this.toastrService.error(errorMessage);
          }
        }
      })
    }else{
      var errorMessage = this.translateService.instant("sessionManagement.itemRemovedError")
      this.toastrService.error(errorMessage);
    }

  }
}
