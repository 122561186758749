import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-add-locale-filter-to-course-list',
  templateUrl: './add-locale-filter-to-course-list.component.html',
  styleUrls: ['./add-locale-filter-to-course-list.component.css']
})
export class AddLocaleFilterToCourseListComponent implements OnInit {

  apiUrl:string = this.apiUrlService.getApiUrl();
  formUrl:string;
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = this.apiUrlService.getApiUrl() + "api/lms/courselist/" + param["courseList"] + "/filters/addform/locale"
      }
    }
    )
  }

}
