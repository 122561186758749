import { Component, OnInit } from '@angular/core';
import { DynamicFormToJsonElementTypesModel } from '../../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { CreateVirtualMachineModel } from '../../../models/VirtualLabModels/createVirtualMachine';
import { ActivatedRoute } from '@angular/router';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';

@Component({
  selector: 'lib-add-virtual-machine',
  templateUrl: './add-virtual-machine.component.html',
  styleUrls: ['./add-virtual-machine.component.css']
})
export class AddVirtualMachineComponent implements OnInit {
  elementList: DynamicFormToJsonElementTypesModel[] = [];
  nicElementList:DynamicFormToJsonElementTypesModel[] = [];
  vmenvironments:ListItemModel[];
  vmimages:ListItemModel[];
  ramList:ListItemModel[];
  cpuList:ListItemModel[];
  selectedEnvironment:string;
  instanceTypeId:string;
  instanceTypes:ListItemModel[] = [];
  providers:ListItemModel[] = [];
  hosts:ListItemModel[] = [];
  showImage:boolean = false;
  loading = false;
  diskJson:string;
  nicJson:string;
  envParam:string;
  imgParam:string;
  showSelects:boolean = false;
  customSelect:boolean = false;
  allowCustomSelect:boolean = false;
  constructor(private vmInstanceService:VmInstanceService,private virtualLabService:VirtualLabService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }
  createElementList() {
    this.elementList.push({ typeName: "disk", typeText: "Yeni Disk Ekle", element: [{ name: "diskName", label: "Disk Adı", valueType: "string" }, { name: "diskSize", min: 0, max: 1000, label: "Disk Boyutu (GB)", valueType: "number" }] });
  }
  createNicElementList(){
    this.nicElementList.push({ typeName: "nic", typeText: "Yeni Ağ Kartı Ekle", element: [{ name: "nicName", label: "Tanımlayıcı Ad", valueType: "string" }, { name: "settings", label: "Ayarlamalar", valueType: "textarea" }] });
  }
  ngOnInit(): void {
    this.initializeInstanceTypes();
    this.getRamList();
    this.getCPUList();
    this.createElementList();
    this.createNicElementList();
    this.getEnvironments();
    this.getParameters();
  }
  getEnvironments(){
    this.virtualLabService.getEnvironmentListItem().subscribe({
      next:(response)=>{
        this.vmenvironments = response.content;
        this.changeSelectedEnvironment();
      },error:()=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getParameters(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        if(param["env"] != undefined){
          this.envParam = param["env"];
          if(param["img"] != undefined){
            this.imgParam = param["img"];
          }
        }
      }
    })
  }
  async initializeInstanceTypes(){
    await this.getProviderList();
    await this.getHostList(this.providers[0].value);
    this.getInstanceTypes(this.providers[0].value);
  }
  changeSelectedEnvironment(){
    var envSelect = "";
    if(this.envParam == undefined){
      envSelect = (document.getElementById("vmenvironment") as HTMLInputElement).value;
    }else{
      envSelect = this.envParam;
    }

    this.selectedEnvironment = envSelect;
    this.changeImageList();
  }
  changeImageList(){
    this.getImageList(this.selectedEnvironment);
  }
  saveDisks(ev:any){
    this.diskJson = ev;
  }
  saveNic(ev:any){
    this.nicJson = ev;
  }
  getImageList(environmentId:string){
    console.log(environmentId);
    this.showImage = false;
    this.loading = true;
    this.virtualLabService.getVMImageListItem(environmentId).subscribe({
      next:(response)=>{
        this.vmimages = response.content;
        this.showImage = true;
        this.loading = false;
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
        this.loading = false;
      }
    })
  }
  getRamList(){
    this.virtualLabService.getRamList().subscribe({
      next:(response)=>{
        this.ramList = response.content;
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getCPUList(){
    this.virtualLabService.getCPUList().subscribe({
      next:(response)=>{
        this.cpuList = response.content;
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  changeProvider(){
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    this.getHostList(providerSelect.value);
    this.getInstanceTypes(providerSelect.value);
  }
  getProviderList():Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getProviderList().subscribe({
        next:(response)=>{
          this.providers = response.content;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }
  getHostList(provider:string):Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getHostList(provider).subscribe({
        next:(response)=>{
          this.hosts = response.content;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }
  changeHost(){
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    var hostSelect = document.getElementById("hostId") as HTMLInputElement;
    this.getInstanceTypes(providerSelect.value,hostSelect.value);
  }
  getInstanceTypes(provider:string,host:string|undefined|null=null):Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getVmInstanceTypesWithProviderTypeAndHost(provider,host).subscribe({
        next:(response)=>{
          this.instanceTypes = response.content;
          this.instanceTypeId = response.content[0].value
          var hostSelect = document.getElementById("instanceType") as HTMLSelectElement;
          hostSelect.value = response.content[0].value;
          this.showSelects = true;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }
  changeInstanceType(){
    var hostSelect = document.getElementById("instanceType") as HTMLInputElement;
    this.instanceTypeId = hostSelect.value;
  }
  changeCustomSelectSwitch($event:any){
    this.customSelect = ($event.target as HTMLInputElement).checked;
  }
  addVm(){
    var name = document.getElementById("name") as HTMLInputElement; 
    var vmenvironment:any = {};
    if(this.envParam != undefined){
      vmenvironment['value'] = this.envParam
    }else{
      vmenvironment = document.getElementById("vmenvironment") as HTMLSelectElement;
    }
    var vmimage:any = {};
    if(this.envParam != undefined){
      vmimage['value'] = this.imgParam;
    }else{
      vmimage = document.getElementById("vmimage") as HTMLSelectElement;
    }
    var ram = document.getElementById("ram") as HTMLSelectElement;
    var cpuCount = document.getElementById("cpuCount") as HTMLSelectElement;
    var nic = this.nicJson;
    var hostVal = (document.getElementById("hostId") as HTMLSelectElement).value;
    var disks = this.diskJson;
    var addVmValue:CreateVirtualMachineModel = {
      providerId:(document.getElementById("providerType") as HTMLSelectElement).value,
      hostId: hostVal == "undefined" ? undefined : hostVal,
      name: name.value,
      vmInstanceTypeId :this.customSelect ? "custom" : this.instanceTypeId,
      ram: ram.value,
      cpuCount: parseInt(cpuCount.value.toString()),
      disks: disks,
      nicCount: nic,
      environment: vmenvironment.value,
      image: vmimage.value
    }
    var notEmpty = ["name","vmInstanceTypeId","disks","nicCount","image"];
    console.log(addVmValue);
    notEmpty.forEach(item=>{
      var a = addVmValue as any;
      if(a[item] == null ||a[item] == ""){
        this.toastrService.error("Formu kontrol edin");
        throw("");
      }
    })
    this.virtualLabService.createVirtualMachine(addVmValue).subscribe({
      next:(response)=>{
        this.toastrService.success("Sanal makine oluşturuldu");
      },error:(err)=>{
        this.toastrService.error("Sanal makine oluşturulamadı");
      }
    })

  }

}
