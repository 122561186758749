import { Injectable } from '@angular/core';
import { RemoteClientUserModel } from '../../models/RemoteClientModels/remoteClientUserModel';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RemoteClientSessionModel } from '../../models/RemoteClientModels/remoteClientSessionModel';
import { GuacamoleSessionIdModel } from '../../models/RemoteClientModels/guacamoleSessionIdModel';
import { GuacamoleSharedConnectionDto } from '../../models/RemoteClientModels/guacamoleSharedConnectionDto';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GuacamoleTokenRequestModel } from '../../models/GuacamoleModels/guacamoleTokenRequestModel';
import { GuacamoleTokenResponseModel } from '../../models/GuacamoleModels/guacamoleTokenResponseModel';
import { ActiveConnectionItemModel, ConnectionProviderPagerModel } from '../../models/GuacamoleModels/activeConnectionItemModel';
import { GuacamoleSessionTerminalsModel } from '../../models/GuacamoleModels/guacamoleSessionTerminalsModel';
import { GuacamoleVmConnectionsModel } from '../../models/GuacamoleModels/guacamoleVmConnectionsModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ListResponseModel } from '../../models/listResponseModel';

@Injectable({
  providedIn: 'root'
})
export class GuacamoleService {

  constructor(private httpClient:HttpClient) { }
  getGuacamoleUserToken(remoteClientUserModel:RemoteClientUserModel){
    let apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(apiUrl+"api/terminal-auth/Authenticator/CreateUserConnectionToken",remoteClientUserModel);
  }
  getGuacamoleSessionToken(remoteClientSessionModel:RemoteClientSessionModel){
    let apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(apiUrl+"api/terminal-auth/Authenticator/CreateSessionConnectionToken",remoteClientSessionModel);
  }
  getGuacamoleSharedUserToken(remoteClientUserModel:RemoteClientUserModel){
    let apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(apiUrl+"api/terminal-auth/Authenticator/CreateUserSharedConnectionToken",remoteClientUserModel);
  }
  getGuacamoleSharedSessionToken(remoteClientSessionModel:RemoteClientSessionModel){
    let apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(apiUrl+"api/terminal-auth/Authenticator/CreateSessionSharedConnectionToken",remoteClientSessionModel);
  }
  getGuacamoleSessionIdWithSessionName(sessionName:string){
    let apiUrl = environment.apiUrl;
    //return this.httpClient.get<GuacamoleSessionIdModel>("https://localhost:7001/api/User/GetGuacamoleSession?sessionName="+sessionName);
    return this.httpClient.get<SingleResponseModel<GuacamoleSessionIdModel>>(apiUrl+"api/terminal/User/GetGuacamoleSession?sessionName="+sessionName);
  }
  getGuacamoleSharedConnections(sessionName:string){
    let apiUrl = environment.apiUrl;
    //return this.httpClient.get<GuacamoleSharedConnectionDto[]>("https://localhost:7001/api/User/GetSharedConnections?sessionName="+sessionName);
    return this.httpClient.get<ListResponseModel<GuacamoleSharedConnectionDto>>(apiUrl+"api/terminal/User/GetSharedConnections?sessionName="+sessionName);
  }
  getMyMachines(token:string){
    console.log("calisti")
    let apiUrl = environment.apiUrl;
    const httpHeaders = new HttpHeaders({'Authorization':'Bearer '+token});
    return this.httpClient.get<ListResponseModel<string>>(apiUrl+"api/terminal/User/getmyconnections",{headers:httpHeaders,observe:"body"})
    //return this.httpClient.get<string[]>("https://localhost:7001/api/User/getmyconnections",{headers:httpHeaders,observe:"body"})
  }
  getGuacamoleAuthToken(authenticateRequestModel:GuacamoleTokenRequestModel) : Observable<GuacamoleTokenResponseModel>{
    let body = new URLSearchParams();
    body.set("data",authenticateRequestModel.data);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    var url = "guacamole/";
    return this.httpClient.post<GuacamoleTokenResponseModel>(url+"api/tokens",body.toString(),options);
  }
  getActiveConnections(onlyActive:boolean = true,last24hr:boolean = false,username:string|null=null,page:number=1):Observable<ConnectionProviderPagerModel<ActiveConnectionItemModel[]>> {
    var apiUrl = environment.apiUrl;
    var params:string = `?onlyActive=${onlyActive}&last24hr=${last24hr}&page=${page}`;
    if(username != null){
      params+=`&username=${username}`;
    }
    
    return this.httpClient.get<ConnectionProviderPagerModel<ActiveConnectionItemModel[]>>(apiUrl+"api/terminal/Admin/activeconnection/list"+params);
  }
  getTerminalDetailsByLmsSessionId(sessionId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<GuacamoleSessionTerminalsModel>(apiUrl+"api/terminal/Admin/session/"+sessionId+"/details");
  }
  getConnectedUsersWithVmNames(vmNames:string[] = []){
    var vmNameList = (JSON.parse(localStorage.getItem("clients") ?? "[]"))
    if(vmNames.length == 0){
      vmNames = vmNameList;
    }
    var joinedVmNames = vmNames.join(",");
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<GuacamoleVmConnectionsModel[]>(`${apiUrl}api/terminal/User/GetActiveConnectionsByVmName?terminalNames=${joinedVmNames}`)
  }
}
