import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-course-seat-details',
  templateUrl: './course-seat-details.component.html',
  styleUrls: ['./course-seat-details.component.css']
})
export class CourseSeatDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
