import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { IconDetailsResponse } from '../../../models/Icons/iconDetailsResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { IconService } from '../../../services/icons/icon.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';

@Component({
  selector: 'lib-icon-details',
  templateUrl: './icon-details.component.html',
  styleUrls: ['./icon-details.component.css']
})
export class IconDetailsComponent implements OnInit {
  apiUrl:string = environment.apiUrl;
  iconDetails:IconDetailsResponse;
  id:number;
  constructor(private toastrService:ToastrService,private globalOkCancelEventService:GlobalOkCancelEventService,private router:Router,private activatedRoute:ActivatedRoute,private translateService:TranslateService,private iconService:IconService,private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        this.getIconDetails();
      }
    })
  }
  getIconDetails(){
    this.iconService.getIconDetails(this.id).subscribe({
      next:(response)=>{
        this.iconDetails = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  deleteIcon(){
    this.globalOkCancelEventService.showOkCancelWindow(this.translateService.instant("icons.deleteIconTitle"),'<div>'+this.translateService.instant("icons.deleteIconMessage")+'</div>',true,undefined,undefined,true,this.translateService.instant("icons.deleteConfirmationMessage")).then(x=>{
      if(x == true){
        if(this.iconDetails.id != null){
          this.iconService.deleteIcon(this.iconDetails.id).subscribe({
            next:(response)=>{
              this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
              this.returnIconsPage();
            },error:(err)=>{
              this.toastrService.error(this.translateService.instant("global.errorOccurred"));
            }
          })
        }

      }
    })
  }
  returnIconsPage(){
    this.router.navigate(["/cms/iconsets/"+this.iconDetails.iconSetId+"/icons/grid"])
  }
  routeIconDetails(id:number){
    this.router.navigate(["/cms/iconsets/icons/"+id+"/details"]);
  }
  updateIcon(){
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/icons/"+this.iconDetails.id+"/update",
      helperModals:[],
      title:this.translateService.instant("icons.updateIconTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconDetails();
      }
    })
  }
  

}
