import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-manage-base-vm-image-objectives',
  templateUrl: './manage-base-vm-image-objectives.component.html',
  styleUrls: ['./manage-base-vm-image-objectives.component.css']
})
export class ManageBaseVmImageObjectivesComponent implements OnInit {
  objectives: {id:string,name:string,description:string}[];
  constructor() { }

  ngOnInit(): void {
    this.objectives = [
      {
        id:"obj1",
        name: "Objective 1",
        description: "Objective 1 description"
      },
      {
        id:"obj2",
        name: "Objective 2",
        description: "Objective 2 description"
      },
      {
        id:"obj3",
        name: "Objective 3",
        description: "Objective 3 description"
      }
    ]
  }

}
