<h5>{{'sessionManagement.relatedItems' | translate}} <button class="btn btn-sm btn-warning" title="Yeni Item Ekle" (click)="addNewItem()"><i class="bi bi-plus-lg"></i></button></h5>
<hr>
<div class="itemsMain" *ngIf="sessionItems">

    <div class="itemContent" *ngFor="let sessionItem of sessionItems">
        <div class="itemRow">
            <div class="itemInfo">
                <i class="bi bi-egg-fill" style="color: rgb(153, 153, 255);"></i> <b> {{getSourceName(sessionItem.itemSourceId)}}</b> <font style="font-style: italic;"> ({{sessionItem.itemType}})</font>
            </div>
            <div class="itemActions">
                <i (click)="deleteSessionItem(sessionItem.id)" style="font-size: 22px; color: red;" class="bi bi-trash-fill"></i>
            </div>
        </div>
    </div>

</div>
