<h5>{{'lab.controlCriteria.updateControlCriteria'  | translate}}</h5>
<hr>
<form *ngIf="controlCriteriaForm" [formGroup]="controlCriteriaForm">
<div class="mb-3">
  <label for="title" class="form-label">{{'lab.controlCriteria.title' | translate}}</label>
  <input type="text" class="form-control" formControlName="title" autocomplete="off" id="title" placeholder="">
</div>
<div class="mb-3">
  <label for="summary" class="form-label">{{'lab.controlCriteria.shortDescription' | translate}}</label>
  <input type="text" class="form-control" formControlName="summary" autocomplete="off" id="summary" placeholder="">
</div>
<div class="mb-3">
  <label for="instructionsFormat"  class="form-label">{{'lab.controlCriteria.instructionFormat' | translate}}</label>
  <select class="form-select" (change)="changeLang()" formControlName="instructionsFormat" id="instructionsFormat" name="instructionsFormat">
    <option [value]="0">PlainText</option>
    <option [value]="1">HTML</option>
    <option [value]="2">Markdown</option>
  </select>

</div>
<div class="mb-3">
  <div class="d-grid gap-2">
    <button class="btn btn-secondary" (click)="initializeMonaco()" data-bs-toggle="modal" data-bs-target="#editorModal" type="button"> {{'lab.controlCriteria.updateInstruction' | translate}} </button>
  </div>
</div>
<div class="mb-3">
  <label for="orderValue" class="form-label">{{'lab.controlCriteria.orderValue' | translate}}</label>
  <input type="number" min="0" formControlName="orderValue" class="form-control" autocomplete="off" id="orderValue" placeholder="">
</div>

<div class="mb-3">
  <label for="identifier" class="form-label">{{'lab.controlCriteria.type' | translate}}</label>
  <select type="text" (change)="changeType($event)" class="form-select" formControlName="type" autocomplete="off" id="type" placeholder="">
    <option [value]=null disabled selected>{{'lab.controlCriteria.selectType' | translate}}</option>
    <option [value]="'textProof'">Text Proof</option>
    <option [value]="'createFile'">File Create</option>
    <option [value]="'fileContents'">File Contents</option>
    <option [value]="'userLogin'">User Login</option>
  </select>
</div>
<div class="mb-3">
  <lib-dynamic-form-to-json [hideButtons]="true" (dataChanges)="onParamsChange($event)" [showDeleteBtn]="false" [reset]="resetForm" [addWithData]="addParam"
    [formElements]="criteriaList" [autoSave]="true"></lib-dynamic-form-to-json>
</div>
<button class="btn btn-primary btn-sm" (click)="updateControlCriteria()">Güncelle</button>
</form>

<div class="modal fade" id="editorModal" tabindex="-1" aria-labelledby="editorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editorModalLabel">{{'lab.controlCriteria.updateInstruction' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div style="height: 500px; width: 100%;" class="controlMonaco">

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'btn.close' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="saveInstructions()">{{'btn.save' | translate}}</button>
      </div>
    </div>
  </div>
</div>