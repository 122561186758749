import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-category-tree-list',
  templateUrl: './category-tree-list.component.html',
  styleUrls: ['./category-tree-list.component.css']
})
export class CategoryTreeListComponent implements OnInit {
  apiUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
  }

}
