import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalCreateModel } from '../../models/ModalManagementModels/modalCreateModel';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
import { HelperModalItem } from '../../models/ModalManagementModels/helperModalItem';
import { Observable, Subscriber } from 'rxjs';
declare var $: any;
declare var bootstrap:any;
@Component({
  selector: 'lib-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.css']
})

export class GlobalModalComponent implements OnInit {
  @Input() modalCreateData: ModalCreateModel;
  @Input() width: string;
  @Input() wide: boolean = false;
  componentData: ModalCreateModel = { data: null, helperModals: [], title: "", type: 0 };
  @Input() open: boolean = false;
  @Output() eventSender: EventEmitter<{ type: string, data: any }> = new EventEmitter<{ type: string, data: any }>(undefined)
  mainContent: Element;
  mainModal: Element;
  acceptedList: string[] = [];
  sanitizedData: SafeHtml;
  helperModals: HelperModalItem[] = [];
  subscriber:Subscriber<any>;
  constructor(private sanitizer: DomSanitizer) { }
  ngOnChanges(changes: SimpleChanges) {
    if (this.modalCreateData !== undefined && this.modalCreateData.data !== undefined) {
      this.componentData.data = this.modalCreateData.data;
      while (this.componentData.helperModals?.length > 0) {
        this.componentData.helperModals.pop();
      }

      this.componentData.helperModals.push(...this.modalCreateData.helperModals);
      this.componentData.title = this.modalCreateData.title;
      this.componentData.type = this.modalCreateData.type;
      if (this.modalCreateData !== undefined) {
        this.open = false;
        setTimeout(() => {
          this.sanitizedData = this.sanitizer.bypassSecurityTrustHtml(this.modalCreateData.data);
        }, 250)
        setTimeout(() => {
          this.open = true;
        }, 500)
        setTimeout(() => {
          this.show();
          (document.getElementById("gotoMain") as HTMLButtonElement).click();
        }, 750);
      }
    }
  }
  onCloseFnc(){
    if(this.subscriber){
      this.subscriber.next(true);
    }

  }
  closeEvent = new Observable<any>((observer)=>{
    this.subscriber = observer;
  })
  ngOnInit(): void {
    if (this.modalCreateData !== undefined && this.modalCreateData.data !== undefined) {
      this.componentData.data = this.modalCreateData.data;
      while (this.componentData.helperModals?.length > 0) {
        this.componentData.helperModals.pop();
      }

      this.componentData.helperModals.push(...this.modalCreateData.helperModals);
      this.componentData.title = this.modalCreateData.title;
      this.componentData.type = this.modalCreateData.type;
      if (this.modalCreateData !== undefined) {
        this.open = false;
        setTimeout(() => {
          this.sanitizedData = this.sanitizer.bypassSecurityTrustHtml(this.modalCreateData.data);
        }, 50)
        setTimeout(() => {
          this.open = true;
        }, 100)
        setTimeout(() => {
          this.show();
          (document.getElementById("gotoMain") as HTMLButtonElement).click();
        }, 150);
      }
    }

  }
  modalData() {
    return this.modalCreateData;
  }
  show() {
    //alert("test");
    switch (this.modalCreateData.type) {
      case ModalTypes.CUSTOMHTML:
        this.mainContent = document.getElementsByClassName("globalModal-content")[0];
        this.mainContent.innerHTML = "";
        this.mainContent.innerHTML = this.modalCreateData.data;
        this.mainModal = document.getElementsByClassName("mainModal")[0];
        this.initializeData();
        break;
      case ModalTypes.HTMLELEMENT:
        this.mainModal = document.getElementsByClassName("mainModal")[0];
        this.mainContent = document.getElementsByClassName("globalModal-content")[0];
        this.mainContent.innerHTML = "";
        this.mainContent.appendChild(this.modalCreateData.data);
        this.initializeData();
        break;
      case ModalTypes.LINK:
        let modalFrame = document.createElement('iframe');
        modalFrame.src = this.modalCreateData.data;
        modalFrame.width = "100%";
        modalFrame.height = "100%"
        modalFrame.style.overflow = "hidden";
        modalFrame.style.overflowY = "hidden";
        modalFrame.style.display = "inline-block";
        document.getElementsByClassName("globalModal-content")[0].innerHTML = "";
        document.getElementsByClassName("globalModal-content")[0].appendChild(modalFrame);
        modalFrame.onload = () => {
          this.mainContent = modalFrame;
          this.mainContent = $(this.mainContent).contents().find("body").get(0)
          this.initializeData();
          setTimeout(() => {

          }, 500);
        }
        break;
    }
  }
  private initializeData() {
    let contentElements = this.mainContent.getElementsByTagName("*");
    let allElements = Array.prototype.slice.call(contentElements);
    allElements.forEach((el) => {
      console.log("------------------")

      if ($(el).hasClass("acceptRequired")) {
        el.addEventListener("click", (e: any) => {
          e.preventDefault();
          let requiredHelper = $(el).attr("data-helper");
          console.log(this.mainModal.getElementsByClassName("helperModalBtn-" + requiredHelper));
          (this.mainModal.getElementsByClassName("helperModalBtn-" + requiredHelper)[0] as HTMLButtonElement).click();
        })
      }

      if ($(el).hasClass("requireCheck")) {
        let elem = el as HTMLButtonElement;
        elem.disabled = true;
      }
    })
    this.mainContent.addEventListener("click", (ev: Event) => {
      if ($(ev.target).hasClass("modal-close-btn")) {
        (document.getElementsByClassName("globalModal-close-x")[0] as HTMLButtonElement).click();
      }
    })
  }
  getDescendantNodes(elm: Element, all: any[] = []) {
    for (let index = 0; index < $(elm).find("*").length; index++) {
      const element = $(elm).find("*").get(index);
      all.push(element);
      this.getDescendantNodes(element, all);
    }
    return 1;
  }
  accept(id: string) {
    this.acceptedList = this.acceptedList.filter(x => x != id);
    this.acceptedList.push(id);
    $(this.mainModal).find("#gotoMain").get(0).click();
    this.acceptControl();
  }
  cancel(id: string) {
    this.acceptedList = this.acceptedList.filter(x => x != id);
    $(this.mainModal).find("#gotoMain").get(0).click();
    this.acceptControl();
  }

  acceptControl() {
    let checkElements = this.mainContent.querySelectorAll(".acceptRequired");
    checkElements.forEach((el) => {
      let requiredId = $(el).attr("data-helper");
      let length = this.acceptedList.filter(x => x == requiredId).length;
      let elem = el as HTMLInputElement;
      if (length > 0) {
        elem.checked = true;
      } else {
        elem.checked = false;
      }

    })
    let buttonElements = document.getElementsByClassName("globalModal-content")[0].querySelectorAll(".requireCheck");

    buttonElements.forEach((el) => {
      let requiredId: string = $(el).attr("data-check");
      let requiredIds: string[] = requiredId.split(',');
      let idCount = requiredIds.length;
      let acceptedCount = 0;
      requiredIds.forEach((requiredId: string) => {
        if (this.acceptedList.indexOf(requiredId) != -1) {
          acceptedCount += 1;
        }
      })
      if (idCount == acceptedCount) {
        let btnEl = el as HTMLButtonElement;
        btnEl.disabled = false;
      } else {
        let btnEl = el as HTMLButtonElement;
        btnEl.disabled = true;
      }
    })
  }

}
