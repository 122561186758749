import { Component, OnInit } from '@angular/core';
import { CourseContentFlags } from '../../../models/CourseContentModels/courseContentFlags';
import { CourseContentService } from '../../../services/education/course-content.service';
import { Observable } from 'rxjs';
import { ListResponseModel } from '../../../models/listResponseModel';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AddContentToSectionRequestModel } from '../../../models/CourseContentModels/addContentToSectionRequestModel';
import { MediaContentService } from '../../../services/mediaContent/media-content.service';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
declare var $:any;
declare var window:any;
@Component({
  selector: 'lib-add-course-content',
  templateUrl: './add-course-content.component.html',
  styleUrls: ['./add-course-content.component.css']
})
export class AddCourseContentComponent implements OnInit {
  flags:{flag:string,value:number}[] = [];
  sectionId:string;
  courseId:string;
  flagNumber = 0;
  selectedType:string = "";
  selectItemList:ListItemModel[] = [];
  loading:boolean = false;
  requestSending:boolean = false;

  constructor(private globalModalService:GlobalModalService,private courseContentService:CourseContentService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private mediaContentService:MediaContentService) { }

  ngOnInit(): void {
    this.prepareFlagSelector();
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.sectionId = param["sectionId"];

      }
    })

  }
  createLayoutEditor(){
    window.initLayoutEditor($("#htmlLayoutEditor"));
  }
  changeType(event:any){
    var contentType = $(event.target).val()
    console.log(contentType)
    switch(contentType){
      case "html":
        this.selectedType = contentType;
        setTimeout(()=>{
          this.createLayoutEditor();
        },150);

        break;
      case "media":
        var request = this.mediaContentService.getMediaContentOptions();
        this.requestSender(request,contentType);
      /*case "audio":
        var request = this.courseContentService.getAudioList();
        this.requestSender(request,contentType);*/

        break;
      case "image":
        var request = this.courseContentService.getImageList();
        this.requestSender(request,contentType);


        break;
      /*case "video":
        var request = this.courseContentService.getVideoList();
        this.requestSender(request,contentType);*/

        break;
      case "file":
        var request = this.courseContentService.getFileList();
        this.requestSender(request,contentType);

        break;
      case "exam":
        var request = this.courseContentService.getExamList();
        this.requestSender(request,contentType);

        break;
      case "question":
        var request = this.courseContentService.getQuestionList();
        this.requestSender(request,contentType);

        break;
      case "random-question":
        var request = this.courseContentService.getRandomQuestionList();
        this.requestSender(request,contentType);

        break;
      case "poll":
        var request = this.courseContentService.getPollList();
        this.requestSender(request,contentType);

        break;
    }
  }
  prepareFlagSelector(){
    var temp:{flag:string,value:number}[] = [];
    var keys = Object.entries(CourseContentFlags);
    keys.forEach((val)=>{
      temp.push({flag:val[0],value:val[1]});
    })
    this.flags = temp;
    console.log(this.flags);
  }
  requestSender(req:Observable<ListResponseModel<ListItemModel>>,entityName:string){
    this.selectedType = "";
    this.loading = true;

    req.subscribe({
      next:(response)=>{
        this.loading = false;
        
        this.selectItemList = response.content;
        this.selectedType = entityName;
      },error:()=>{
        this.loading = false;
        this.toastrService.error("Veriler getirilemedi");
      }
    })
  }
  getValue():string{
    if(this.selectedType == "html"){
      return $("#htmlLayoutEditor").val();
    }else if(this.selectedType == "media"){
      var mediaInput = document.getElementById("mediaSelectInput") as HTMLSelectElement;
      return mediaInput.value;
    }else{
      //var selectInput = this.selectedType+"SelectInput";
      //var selectedInput = document.getElementById(selectInput) as HTMLInputElement;
      //return selectedInput.value;
      var input = document.getElementById("contentId") as HTMLInputElement;
      return input.value;
    }
  }
  getFlagNumber():number{
    var flag = 0;
    var flagCheckList = document.getElementsByClassName("optionsFlag");
    for (let index = 0; index < flagCheckList.length; index++) {
      const element = flagCheckList[index] as HTMLInputElement;
      if(element.checked){
        flag+=parseInt(element.value);
      }
      
    }
    return flag;
  }
  typeToInt(){
    switch(this.selectedType){
      case "html":
        return 0;
      case "file":
        return 1;
      case "exam":
        return 2;
      case "poll":
        return 3;
      case "question":
        return 4;
      case "random-question":
        return 5;
      case "audio":
        return 6;
      case "image":
        return 6;
      case "video":
        return 6;
      case "media":
        return 6;
    }
    return 0;
  }
  createMediaItem(){
    this.globalModalService.showModal({
      data:"/modal/lms/media-content/create",
      helperModals:[],
      title:"Create Media Item",
      type:ModalTypes.LINK
    })
  }
  createContent(){
    try {
      var addContentModel:AddContentToSectionRequestModel = {
        outlineItemId: this.sectionId,
        type: this.typeToInt() as number,
        content: this.typeToInt() == 0 ? this.getValue() : undefined,
        options: this.getFlagNumber(),
        orderValue: parseInt((document.getElementById("sortValueInput") as HTMLInputElement).value ?? "0"),
        name: ((document.getElementById("title") as HTMLInputElement).value ?? ""),
        contentFormat: this.typeToInt() == 0 ? 0 : undefined,
        examId: this.typeToInt() == 2 ? this.getValue() : undefined,
        surveyId: this.typeToInt() == 3 ? this.getValue() : undefined,
        mediaId: this.typeToInt() == 6 ? this.getValue() : undefined,
        questionId: this.typeToInt() == 4 ? this.getValue() : undefined,
        questionQueryId: this.typeToInt() == 5 ? this.getValue() : undefined,
        externalToolId: this.typeToInt() == 1 ? this.getValue() : undefined
      }
      this.requestSending = true;
      this.courseContentService.addCourseContent(addContentModel).subscribe({
        next:(response)=>{
          this.requestSending = false;
          this.toastrService.success("İçerik başarıyla eklendi");
        },
        error:(err)=>{
          this.requestSending = false;
          this.toastrService.error("İçerik eklenemedi");
        }
      })
      console.log(addContentModel);
    }catch{
      this.toastrService.error("Bir hata oluştu");
    }

  }

}
