import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { AddSectionToEducationRequestModel } from '../../../models/Education/addSectionToEducationRequestModel';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-add-section-to-education',
  templateUrl: './add-section-to-education.component.html',
  styleUrls: ['./add-section-to-education.component.css']
})
export class AddSectionToEducationComponent implements OnInit {
  educationId:string;
  constructor(private translateService:TranslateService ,private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getEducationId();
  }
  getEducationId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.educationId = param["educationId"]
      }
    })
  }
  addSection(){
    var sectionName = (document.getElementById("sectionName") as HTMLInputElement).value;
    var sectionDescription = (document.getElementById("sectionDescription") as HTMLInputElement).value;
    var preIcon = (document.getElementById("preIcon") as HTMLInputElement).value;
    var postIcon = (document.getElementById("postIcon") as HTMLInputElement).value;
    if(sectionName.trim() == ""){
      this.toastrService.error(this.translateService.instant("courseManagement.courseNameCannotBeEmpty"));
      throw "Hata";
    }
    var addSectionReq:AddSectionToEducationRequestModel = {
      educationId: this.educationId,
      sectionName: sectionName,
      sectionDescription: sectionDescription,
      preIcon: preIcon,
      postIcon: postIcon
    }
    this.educationService.addSectionToEducation(addSectionReq).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("courseManagement.sectionAdded"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("courseManagement.sectionAddError"));
      }
    })
  }

}
