import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantListenerService {
  private tenantStatusSource = new BehaviorSubject<string>('false');
  public tenantStatus$ = this.tenantStatusSource.asObservable();
  constructor() {
    this.tenantStatus$.subscribe(status => window.localStorage.setItem("tenantStatus",status));
   }
  getTenantStatus(state:string):Observable<string>{
    let ts = window.localStorage.getItem("tenantStatus");
    ts = state;
    this.tenantStatusSource.next(ts);
    return this.tenantStatus$;
  }

}
