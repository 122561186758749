import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryTreeOptionsFlag } from 'projects/usorta-library/src/lib/models/Education/Enum/categoryTreeFlags';
import { CategoryTreeModel } from 'projects/usorta-library/src/lib/models/Education/categoryTreeModel';
import { UpdateCategoryTreeModel } from 'projects/usorta-library/src/lib/models/Education/updateCategoryTreeModel';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';

@Component({
  selector: 'lib-update-category-tree',
  templateUrl: './update-category-tree.component.html',
  styleUrls: ['./update-category-tree.component.css']
})
export class UpdateCategoryTreeComponent implements OnInit {
  updateCategoryTreeForm:FormGroup;
  categoryTree:CategoryTreeModel;
  categoryTreeId:number = 1;
  loading:boolean = false;
  categoryTreeOptions = Object.entries(CategoryTreeOptionsFlag).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]}
  }).filter(x=>x.value != 0);
  constructor(private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder,private educationService:EducationService,private toastrService:ToastrService) { }
  createCategoryTreeForm(){
    this.updateCategoryTreeForm = this.formBuilder.group({
      name:[this.categoryTree.name,Validators.required],
      description:[this.categoryTree.description],
      active:[this.categoryTree.active]
    })
  }
  getCategoryTree(){
    this.educationService.getCategoryTree(this.categoryTreeId).subscribe({
      next:(response)=>{
        this.categoryTree = response.content;
        this.createCategoryTreeForm();
        setTimeout(()=>{
          this.initializeChecks();
        },250)
      },error:()=>{
        this.toastrService.error("Kategori ağacı getirilemedi");
      }
    })
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.categoryTreeId = parseInt(param["categoryTreeId"]);
        this.getCategoryTree();
      }
    })
  }
  optionsValue(){
    var val = 0;
    var checks = document.getElementsByClassName("optionsCheckbox");
    for (let index = 0; index < checks.length; index++) {
      const checkbox = checks[index] as HTMLInputElement;
      if(checkbox.checked){
        val+= parseInt(checkbox.value)
      }
    }
    return val;
  }
  initializeChecks(){
    console.log("calisti")
    var optionChecks = document.getElementsByName("options");
    console.log(optionChecks);
    optionChecks.forEach(check => {
      var option = check as HTMLInputElement;
      var optionVal = option.value;
      console.log(optionVal);
      this.categoryTreeOptions.forEach((ctopt)=>{
        if(this.categoryTree.options & ctopt.value){
          console.log("ctopt.val : "+ctopt.value)
          if(parseInt(optionVal) == ctopt.value){
            option.checked = true;
          }
        }
      })

    })
  }
  updateCategoryTree(){
    if(this.updateCategoryTreeForm.valid){
      var updateCategoryTreeModel:UpdateCategoryTreeModel = Object.assign({},this.updateCategoryTreeForm.value);
      updateCategoryTreeModel.options = this.optionsValue();
      updateCategoryTreeModel.treeId = this.categoryTreeId;
      console.log(updateCategoryTreeModel);
      this.loading = true;
      this.educationService.updateCategoryTree(this.categoryTreeId,updateCategoryTreeModel).subscribe({
        next:(response)=>{
          console.log(response);
          this.loading = false;
          this.toastrService.success("Kategori ağacı güncellendi");
        },error:(err)=>{
          this.loading = false;
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.loading = false;
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
