import { Injectable } from '@angular/core';
import { CustomFormJsonTypesEnum } from '../../models/CustomFormJson/customFormJsonTypeEnum';
import { CheckInputModel, CustomFormJsonInputAndValidateModel, RadioInputModel, TextInputModel, TextareaInputModel } from '../../models/CustomFormJson/inputModel';
import { CustomFormJsonValidationModel } from '../../models/CustomFormJson/customFormJsonValidationModel';
import { FormElement } from '../../models/CustomFormModels/formElement';
import { FormPage } from '../../models/CustomFormModels/formPage';
import { CustomForm } from '../../models/CustomFormModels/customForm';
import { CustomFormJsonPageModel } from '../../models/CustomFormJson/customFormJsonPageModel';
import { CustomFormJsonFormModel } from '../../models/CustomFormJson/customFormJsonFormModel';

@Injectable({
  providedIn: 'root'
})
export class CustomFormJsonService {

  constructor() { }
  
  createCustomFormJson(customFormModel:CustomFormJsonFormModel,customFormPageModel:CustomFormJsonPageModel,inputAndValidate:CustomFormJsonInputAndValidateModel[]){
    var customForm:CustomForm = {
      method: customFormModel.method,
      action: customFormModel.action,
      enctype: customFormModel.enctype,
      submitPerPage: false,
      pages: [],
      buttons: customFormModel.buttons,
      pageNavigation: 'bottom'
    }
    customForm.pages.push(this.createPage(customFormPageModel,inputAndValidate));
    return customForm;
  }
  createPage(customFormPageModel:CustomFormJsonPageModel,inputAndValidate:CustomFormJsonInputAndValidateModel[]){
    var page:FormPage = {
      title: customFormPageModel.title,
      requireValidation: false,
      elements: [],
      layout: customFormPageModel.layout,
      showReset: customFormPageModel.showReset
    };
    page.elements = this.createElements(inputAndValidate);
    return page;
  }
  createElements(inputAndValidate:CustomFormJsonInputAndValidateModel[]):FormElement[] {
    var elements:FormElement[] = [];
    inputAndValidate.forEach((x)=>{
      elements.push(this.createInput(x));
    })
    return elements;
  }
  createInput(inputAndValidate:CustomFormJsonInputAndValidateModel){
    var inputModel = inputAndValidate.input;
    var validationModel = inputAndValidate.validation;
   var input:FormElement | undefined = undefined;
   switch(inputModel.type){
    case 'text':
      input = this.createTextInput(inputModel);
      break;
    case 'checkbox':
      input = this.createCheckboxInput(inputModel);
      break;
    case 'radio':
      input = this.createRadioInput(inputModel);
      break;
    case 'textarea':
      input = this.createTextareaInput(inputModel);
      break;
   }
   if(validationModel != null && input != undefined){
    input.validationRules = validationModel;
   }
   return input as FormElement;
  }
  createTextInput(inputModel:TextInputModel){
    var textInput:FormElement = {
      type: 'text',
      attributes: [],
      autocomplete: false,
      name: inputModel.name,
      disabled: false,
      valueType: 'string',
      defaultValue: inputModel.defaultValue ?? "",
      placeholder: '',
      cssClasses: 'form-control',
      description : inputModel.description ?? "",
      label: inputModel.label ?? ""
    }
    return textInput;
  }
  createCheckboxInput(inputModel:CheckInputModel){
    var checkInput:FormElement = {
      type: 'checkbox',
      name: inputModel.name,
      disabled: false,
      attributes: [],
      valueType: 'string',
      cssClasses:'form-check-input',
      defaultValue: '',
      placeholder: '',
      autocomplete: false,
      label:inputModel.label,
      description:inputModel.description,
      options:inputModel.options
    }
    return checkInput;
  }
  createRadioInput(inputModel:RadioInputModel){
    var radioInput:FormElement = {
      type: 'radio',
      name: inputModel.name,
      disabled: false,
      attributes: [],
      valueType: '',
      defaultValue: '',
      placeholder: '',
      autocomplete: false,
      label:inputModel.label,
      description:inputModel.description,
      options:inputModel.options
    }
    return radioInput;
  }
  createTextareaInput(inputModel:TextareaInputModel){
    var textareaInput:FormElement = {
      type:'textarea',
      name:inputModel.name,
      disabled:false,
      attributes:[],
      valueType:'',
      defaultValue:inputModel.defaultValue ?? "",
      placeholder:'',
      autocomplete:false,
      label:inputModel.label,
      description:inputModel.description
    }
    return textareaInput;
  }
}
