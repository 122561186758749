import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-update-menu-item',
  templateUrl: './update-menu-item.component.html',
  styleUrls: ['./update-menu-item.component.css']
})
export class UpdateMenuItemComponent implements OnInit {
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        let menuId = param["menuId"];
        let menuItemId = param["menuItemId"];
        this.formUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/menu/${menuId}/items/${menuItemId}/update`
      }
    })
  }

}
