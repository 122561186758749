import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageGetInfoResponse } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageGetInfoResponse';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';

@Component({
  selector: 'lib-manage-base-vm-image-infos',
  templateUrl: './manage-base-vm-image-infos.component.html',
  styleUrls: ['./manage-base-vm-image-infos.component.css']
})
export class ManageBaseVmImageInfosComponent implements OnInit {
  @Input() baseVmImageId:string;
  locale:string;
  info:BaseVmImageGetInfoResponse;
  loading:boolean;
  constructor(private router:Router,private globalModalService:GlobalModalService,private baseImageService:BaseVmImageService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getInfo();
  }
  editInfo(){
    var modal =this.globalModalService.showModal({
      data:"/modal/range/vm/basevmimages/"+this.baseVmImageId+"/info/update",
      helperModals:[],
      title:"Update Info",
      type:ModalTypes.LINK
    },true)
    modal['onclose'].subscribe({
      next:(response:any)=>{
        this.getInfo();
      }
    })
  }
  manageInfo(){
    var modal =this.globalModalService.showModal({
      data:"/modal/range/vm/basevmimages/"+this.baseVmImageId+"/info/revisions",
      helperModals:[],
      title:"Revisions",
      type:ModalTypes.LINK
    },true)
    modal['onclose'].subscribe({
      next:(response:any)=>{
        this.getInfo();
      }
    })
  }
  getInfo(){
    this.loading = true;
    this.baseImageService.getInfo(this.baseVmImageId,this.translateService.currentLang).subscribe({
      next:(response)=>{
        this.info = response.content;
        this.loading = false;
      },error:(err)=>{
        this.loading = false
        //this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
