<h5>Yeni VM Çalışma Ortamı</h5>
<hr>
<form [formGroup]="createEnvironmentForm">
<div class="mb-3">
    <label for="name" class="form-label">{{'vmhosts.hostName' | translate}}</label>
    <input type="text" class="form-control" id="name" formControlName="name">
  </div>
<div class="mb-3">
    <label for="tags" class="form-label">{{'vmhosts.tags' | translate}}</label>
    <input type="text" class="form-control" id="tags" formControlName="tags">
  </div>
  <div class="mb-3">
    <label for="provider" class="form-label">{{'vmhosts.providerType' | translate}}</label>
    <select formControlName="typeId" class="form-select" id="typeId">
        <option *ngFor="let provider of providers" [value]="provider.value">{{provider.label}}</option>
    </select>
  </div>
  <div class="form-check">
    <label class="form-check-label" for="enabled">
      {{'check.active' | translate}} 
    </label>
    <input class="form-check-input" type="checkbox" id="enabled" formControlName="enabled">

  </div>
</form>
<br/>
<label class="form-label">{{'global.configuration' | translate}}</label>
  <lib-dynamic-form-to-json *ngIf="loaded == true" [createObject]="true" (dataChanges)="getJsonData($event)" [formElements]="elementList" [autoSave]="true" >

  </lib-dynamic-form-to-json>
  <br/>
  <button class="btn btn-primary btn-sm" (click)="createEnvironment()">{{'global.create' | translate}}</button>