import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-question-set',
  templateUrl: './delete-question-set.component.html',
  styleUrls: ['./delete-question-set.component.css']
})
export class DeleteQuestionSetComponent implements OnInit {
  questionSetId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService ,private questionService:QuestionService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionSetId = parseInt(param["id"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteQuestionSet(){
    this.questionService.deleteQuestionSet(this.questionSetId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success(this.translateService.instant("questionSet.questionSetDeletedSuccessfully"));
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }
  
  

}
