import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-questions-table',
  templateUrl: './questions-table.component.html',
  styleUrls: ['./questions-table.component.css']
})
export class QuestionsTableComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl() + "api/lms/assessment/questions"
  }

}
