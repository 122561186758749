import { Component, Input, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { AddEducationCategoryRequestModel } from '../../../models/Education/addEducationCategoryRequestModel';
import { UpdateEducationCategoryRequestModel } from '../../../models/Education/updateEducationCategoryRequestModel';
import { EducationCategoryModel } from '../../../models/Education/educationCategoryModel';
import { ActivatedRoute } from '@angular/router';
import { CourseCategoryFlags } from '../../../models/Education/Enum/courseCategoryFlags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-update-education-category',
  templateUrl: './update-education-category.component.html',
  styleUrls: ['./update-education-category.component.css']
})
export class UpdateEducationCategoryComponent implements OnInit {
  categoryId:number;
  categoryTreeId:number = 1;
  options: { name: string, value: number }[] = [{ name: "CourseCategoryFlags_Testing", value: 1 }]
  pCategoryList: ListItemModel[] = [];
  categoryForm: FormGroup;
  category:EducationCategoryModel;
  showForm:boolean = false;
  loading:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute, private toastrService: ToastrService, private educationService: EducationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.categoryId = parseInt(param["categoryId"]);
        this.categoryTreeId = parseInt(param["categoryTreeId"]);
        this.getCategories();

      }
    })

  }
  updateCategoryForm() {
    this.showForm = true;
    console.log("kategori bilgileri");
    console.log(this.category);
    this.categoryForm = this.formBuilder.group({
      leafId:[this.category.id],
      parentId: [this.category.parentId],
      name: [this.category.name, Validators.required],
      active: [this.category.active],
      description: [this.category.description],
      color: [this.category.color, Validators.required],
      options: [0],
      orderValue: [this.category.orderValue]
    })
  }
  getCurrentCategory(){
    this.educationService.getCategory(this.categoryId).subscribe({
      next:(response)=>{
        this.category = response.content;
        this.updateCategoryForm();
        console.log("initializeCheck--------------------------")
        setTimeout(()=>{
          this.initializeChecks();
        },50)

      },
      error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  initializeChecks(){
    console.log("calisti")
    var optionChecks = document.getElementsByName("options");
    console.log(optionChecks);
    optionChecks.forEach(check => {
      var option = check as HTMLInputElement;
      var optionVal = option.value;
      console.log(optionVal);
      if(this.category.options & CourseCategoryFlags.CourseCategoryFlags_Testing){
        if(parseInt(optionVal) == CourseCategoryFlags.CourseCategoryFlags_Testing){
          option.checked = true;
        }
      }
    })
  }
  getCategories() {
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next: (categories => {
        this.pCategoryList = categories.content;
        this.getCurrentCategory();
      })
      ,error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  updateEducationCategory() {
    if (this.categoryForm.valid) {
      var addCategoryRequest: UpdateEducationCategoryRequestModel = Object.assign({}, this.categoryForm.value);
      if((addCategoryRequest.parentId as any) == "null"){
        (addCategoryRequest.parentId as any) = null
      }else{
        addCategoryRequest.parentId = parseInt((addCategoryRequest.parentId as any));
      }
      addCategoryRequest.options = this.getFlagValue();
      addCategoryRequest.treeId = this.categoryTreeId;
      this.loading = true;
      this.educationService.updateEducationCategory(this.categoryTreeId,this.categoryId,addCategoryRequest).subscribe({
        next: (response) => {
          this.loading = false;
          this.toastrService.success(this.translateService.instant("Kategori güncellendi"));
        }, error: (err) => {
          this.loading = false;
          this.toastrService.error(this.translateService.instant("Kategori güncellenemedi"));
        }
      },)

    } else {
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }
  getFlagValue() {
    var flag = 0;
    var optionChecks = document.getElementsByName("options");
    optionChecks.forEach(check => {
      var option = check as HTMLInputElement;
      if (option.checked) {
        flag = flag | parseInt(option.value)
      }
    })
    return flag;
  }

}
