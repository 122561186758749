<div class="formMain form-default-border form-maroon-border">
  <div class="form-title-div">
      <font class="form-title-content">{{'vmInstanceTypeMappings.updateFormTitle' | translate}}</font>
  </div>
  <form *ngIf="mappingUpdateInfo" [formGroup]="mappingUpdateForm">
    <div class="mb-3">
      <label for="name" class="form-label">{{'vminstancetypes.name' | translate}}</label>
      <input formControlName="name" type="text" class="form-control" id="priority">
    </div>
    <div class="mb-3">
      <label for="typeId" class="form-label">{{'vminstancetypes.typeId' | translate}}</label>
      <input formControlName="typeId" type="text" class="form-control" id="typeId">
    </div>
    <div class="form-check">
      <input formControlName="active" class="form-check-input" type="checkbox" value="" id="active">
      <label class="form-check-label" for="active">
        {{'vminstancetypes.active' | translate}}
      </label>
    </div>
    <div class="mb-3">
      <label for="notes" class="form-label">{{'vminstancetypes.notes' | translate}}</label>
      <textarea formControlName="notes" class="form-control" id="notes" rows="3"></textarea>
    </div>
    <div class="mb-3">
      <label for="priority" class="form-label">{{'vminstancetypes.priority' | translate}}</label>
      <input formControlName="priority" type="number" class="form-control" id="priority">
    </div>
    <div class="mb-3">
      <label for="ram" class="form-label">{{'vminstancetypes.ram' | translate}}</label>
      <select formControlName="ram" class="form-select" id="ram" aria-label="">
          <option *ngFor="let ram of ramList" [value]="ram.value">{{ram.label}}</option>
      </select>
  </div>
  <div class="mb-3">
      <label for="cpuCount" class="form-label">{{'vminstancetypes.cpuCount' | translate}}</label>
      <select type="number" formControlName="cpuCount" class="form-select" id="cpuCount" aria-label="">

          <option *ngFor="let cpu of cpuList" [value]="cpu.value">{{cpu.label}}</option>

          </select>
  </div>
    <button class="btn btn-primary" (click)="updateMapping()">{{'btn.update' | translate}}</button>
  </form>
</div>