import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Sanitizer, SimpleChanges } from '@angular/core';
import { CourseContentService } from '../../../services/education/course-content.service';
import { OutlineContentsViewResponse } from '../../../models/CourseContentModels/outlineContentsViewResponse';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { CourseContentDetailsModel } from '../../../models/CourseContentModels/courseContentDetailsModel';
import { CourseService } from '../../../services/course/course.service';
import { EducationService } from '../../../services/education/education.service';
import { EducationOutlineModel } from '../../../models/Education/educationOutline';

@Component({
  selector: 'lib-outline-item-preview',
  templateUrl: './outline-item-preview.component.html',
  styleUrls: ['./outline-item-preview.component.css']
})
export class OutlineItemPreviewComponent implements OnInit,OnChanges {
  @Input() previewOutlineId:string[];
  @Output() sendSelectedContent:EventEmitter<string> = new EventEmitter<string>();
  currentPreview:string|undefined = undefined;
  contentView:OutlineContentsViewResponse;
  selectedContentId:string = "";
  loading:boolean = false;
  outlineItemDetails:EducationOutlineModel;
  constructor(private globalModalService:GlobalModalService,private courseContentService:CourseContentService,private sanitizer:DomSanitizer,private toastrService:ToastrService,private translateService:TranslateService,private educationService:EducationService) { }
  ngOnChanges(changes: SimpleChanges): void {

    if(this.currentPreview == undefined || this.previewOutlineId[0] != this.currentPreview){
      this.currentPreview = this.previewOutlineId[0];
    }
    console.log(this.currentPreview);
    this.getContents();
    this.getCourseDetails();
  }
  getCourseDetails(){
    this.educationService.getSectionDetails(this.previewOutlineId[0]).subscribe({
      next:(response)=>{
        this.outlineItemDetails = response.content;
      },error:(err)=>{

      }
    })
  }
  selectContent(id:string){
    this.sendSelectedContent.emit(id);
    this.selectedContentId = id;
  }
  getContents(){
    if(this.currentPreview != undefined){
      this.loading = true;
      this.courseContentService.getCourseOutlineContentView(this.currentPreview).subscribe({
        next:(response)=>{
          this.loading = false;
          this.contentView = response.content;
          this.contentView.contents.forEach(content=>{
            content.data =this.sanitizer.bypassSecurityTrustHtml(content.data as string);
          })
          console.log(this.contentView);
        },error:(err)=>{
          this.loading = false;
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })

    }

  }
  ngOnInit(): void {
    console.log("initialize");
  }
  addNewContent(){
    if(this.currentPreview != null && this.currentPreview != ""){
      var modal = this.globalModalService.showModal({
        data:"/modal/lms/courses/section/"+this.currentPreview+"/addContent",
        helperModals:[],
        title:"",
        type:ModalTypes.LINK
      },true)
      modal["onclose"].subscribe({
        next:()=>{
          this.getContents();
        }
      })
    }
  }
  sendToTop(id:string){
    this.courseContentService.moveCourseContent(id,"top").subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("outlinePanel.contentMoved"));
        this.getContents();
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  sendToBottom(id:string){
    this.courseContentService.moveCourseContent(id,"bottom").subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("outlinePanel.contentMoved"));
        this.getContents();
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
