<div class="questionHiddenContainer" style="display:none;">
    <div #setAnswerForm class="questionContainer formMain form-maroon-border form-default-border">
        <div class="mb-3">
            <label class="form-label" style="font-weight: bold;">{{'videoTimelineEditor.questionTextarea' | translate}}</label>
            <textarea id="questionTextarea" (keyup)="changeQuestion($event)" class="form-control"></textarea>
        </div>
        <b>{{'videoTimelineEditor.setAnswers'}}</b>
        <div class="mb-3 input-group">
            <input type="text" id="answer1" class="form-control" (keyup)="setAnswer(0,$event)">
            <div class="input-group-text">
            <input type="radio" name="correct" class="form-check-input" value="0"></div>
        </div>
        <div class="mb-3 input-group">
            <input type="text" id="answer2" class="form-control" (keyup)="setAnswer(1,$event)">
            <div class="input-group-text">
            <input type="radio" name="correct" class="form-check-input" value="1"></div>
        </div>
        <div class="mb-3 input-group">
            <input type="text" id="answer3" class="form-control" (keyup)="setAnswer(2,$event)">
            <div class="input-group-text">
            <input type="radio" name="correct"  class="form-check-input" value="2"></div>
        </div>
        <div class="mb-3 input-group">
            <input type="text" id="answer4" class="form-control" (keyup)="setAnswer(3,$event)">
            <div class="input-group-text">
            <input type="radio" name="correct" class="form-check-input" value="3"></div>
        </div>
        <button class="btn btn-primary btn-sm modal-close-btn" (click)="btnOkProcess()">{{'btn.ok' | translate}}</button>
    </div>
</div>
<div class="items">
    <div class="sectionItem" *ngFor="let section of sections.keyframes">
        <b>{{"sectionTempName" | translate}} : </b>
        <font>{{section.name}}</font>
        <div class="mb-3">
            <input class="form-control" type="text" (keydown)="setSection(section.id,$event)" [id]="'section_'+section.id">
        </div>
    </div>
    <div class="contentItem" *ngFor="let content of contents">
        <div class="keyframeItem" *ngFor="let keyframe of content.keyframes">
            <b>{{"contentTempName" | translate}} : </b>
            <font>{{keyframe.name}}</font>
            <div class="mb-3">
                <div class="input-group mb-3">
                    <select class="form-select" (change)="setContentType(keyframe.id,$event)" [id]="'contentType_'+keyframe.id">
                        <option [value]="-1" [disabled]="true">NOT-SELECTED</option>
                        <option [value]="0">QUESTION</option>
                    </select>
                    
                    <button style="margin-left: 5px;" class="btn btn-warning btn-sm" (click)="editContent(keyframe.id)"><i class="bi bi-pencil-fill"></i></button>
                </div>

            </div>
        </div>
    </div>
    <button class="btn btn-primary btn-sm" (click)="completed()">{{'btn.ok' | translate}}</button>
</div>