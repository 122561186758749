import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseTokenModel } from 'projects/usorta-library/src/lib/models/responseTokenModel';
import { SingleResponseModel } from 'projects/usorta-library/src/lib/models/singleResponseModel';
import { ApiUrlService } from 'projects/usorta-library/src/lib/services/api/api-url.service';
import { RefreshTokenService } from 'projects/usorta-library/src/lib/services/auth/refresh-token.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminRefreshTokenServiceService implements RefreshTokenService {

  constructor(private httpBackend: HttpBackend, private apiUrlService: ApiUrlService,private loginService:LoginService) { }
  public renewIfExpired(): Promise<boolean> {
    return new Promise<boolean>((resolve,reject)=>{
      if(this.loginService.isLoggedControlWithExpiration() == false && this.loginService.refreshExpirationControl() == true){
        var httpClient = new HttpClient(this.httpBackend);
        let apiUrl: string = this.apiUrlService.getApiUrl();
        var refreshToken = sessionStorage.getItem("refresh_token") ?? "";
        var req = httpClient.post<SingleResponseModel<ResponseTokenModel>>(`${apiUrl}api/accounts/admin/refresh-token`, { "refreshToken": refreshToken });
        req.subscribe({
          next:(response)=>{
            var expiry = new Date();
            expiry.setSeconds(expiry.getSeconds()+response.content.expires_in);
            var refreshExpiry = new Date();
            refreshExpiry.setSeconds(expiry.getSeconds()+response.content.refresh_expires_in).toLocaleString()
            sessionStorage.setItem("token",response.content.access_token);
            sessionStorage.setItem("token_expiration",expiry.toJSON());
            sessionStorage.setItem("refresh_token",response.content.refresh_token);
            sessionStorage.setItem("refresh_token_expiration",refreshExpiry.toJSON());
            resolve(true);
          },
          error:(err)=>{
            resolve(false);
          }
        })
      }else{
        resolve(true);
      }
    })
  }

  public getTokenWithRefreshToken(refresh: string): Observable<SingleResponseModel<ResponseTokenModel>> {
    var httpClient = new HttpClient(this.httpBackend);
    let apiUrl: string = this.apiUrlService.getApiUrl();
    return httpClient.post<SingleResponseModel<ResponseTokenModel>>(`${apiUrl}api/accounts/admin/refresh-token`, { "refreshToken": refresh });
  }
}
