import { Injectable } from '@angular/core';
import { AddEducationRequestModel } from '../../models/Education/addEducationRequestModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { EducationModel } from '../../models/Education/educationModel';
import { UpdateEducationRequestModel } from '../../models/Education/updateEducationRequestModel';
import { AddEducationCategoryRequestModel } from '../../models/Education/addEducationCategoryRequestModel';
import { AddSectionToEducationRequestModel } from '../../models/Education/addSectionToEducationRequestModel';
import { AddSubsectionToSectionRequestModel } from '../../models/Education/addSubsectionToSectionRequestModel';
import { AddOutlineToEducationRequestModel } from '../../models/Education/addOutlineToEducationRequestModel';
import { UpdateEducationSectionRequestModel } from '../../models/Education/updateEducationSectionRequestModel';
import { EducationOutlineModel, EducationSubOutlineModel } from '../../models/Education/educationOutline';
import { UpdateEducationSubSectionRequestModel } from '../../models/Education/updateEducationSubSectionModel';
import { EducationCategoryModel } from '../../models/Education/educationCategoryModel';
import { EducationThemeModel } from '../../models/Education/educationThemeModel';
import { SectionDetailsModel } from '../../models/Education/sectionDetailsModel';
import { AddEducationVersionRequestModel } from '../../models/Education/addEducationVersionRequestModel';
import { AddCategoryTreeModel } from '../../models/Education/addCategoryTreeModel';
import { UpdateEducationCategoryRequestModel } from '../../models/Education/updateEducationCategoryRequestModel';
import { UpdateCategoryTreeModel } from '../../models/Education/updateCategoryTreeModel';
import { CategoryTreeModel } from '../../models/Education/categoryTreeModel';
import { AddCourseThemeModel } from '../../models/Education/addCourseThemeModel';
import { UpdateCourseThemeModel } from '../../models/Education/updateCourseThemeModel';
import { ApiUrlService } from '../api/api-url.service';
import { EducationOutlineUpdateModel } from '../../models/Education/educationOutlineUpdateModel';
import { UpdateSectionRequestModel } from '../../models/Education/updateSectionModel';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(private httpClient:HttpClient) { }
  getCategories(categoryTreeId:number){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<ListResponseModel<ListItemModel>>(apiUrl+"educations/getcategories");
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/lms/categorytrees/course/${categoryTreeId}/leaves/optionlist`);
  }
  addEducation(educationModel:AddEducationRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl+"api/lms/courses/create",educationModel);
  }
  addEducationVersion(educationModel:AddEducationVersionRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl+"api/lms/courses/"+educationModel.courseId+"/versions/create",educationModel);
  }
  updateEducation(educationModel:UpdateEducationRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/${educationModel.courseId}/update`,educationModel);
  }
  getEducation(educationId:string){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<SingleResponseModel<EducationModel>>(apiUrl+"educations/"+educationCode);
    return this.httpClient.get<SingleResponseModel<EducationModel>>(`${apiUrl}api/lms/courses/${educationId}/update`);
  }
  addEducationCategory(categoryTreeId:number,addEducationCategoryRequestModel:AddEducationCategoryRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/categorytrees/course/${categoryTreeId}/leaves/create`,addEducationCategoryRequestModel);
  }
  getEducationOutlineUpdateInfo(sectionId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<EducationOutlineUpdateModel>>(`${apiUrl}api/lms/courses/versions/outline/${sectionId}/update`);
  }
  updateEducationCategory(treeId:number,leafId:number,updateEducationCategoryRequestModel:UpdateEducationCategoryRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/categorytrees/course/${treeId}/leaves/${leafId}/update`,updateEducationCategoryRequestModel);
  }
  updateCategoryTree(treeId:number,updateModel:UpdateCategoryTreeModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/categorytrees/course/${treeId}/update`,updateModel);
  }
  getCategoryTree(treeId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<CategoryTreeModel>>(`${apiUrl}api/lms/categorytrees/course/${treeId}/update`);
  }
  addSectionToEducation(addSectionToEducation:AddSectionToEducationRequestModel){
    var apiUrl = environment.apiUrl;
    var educationId = addSectionToEducation.educationId;
    return this.httpClient.post<ResponseModel>(`${apiUrl}educations/${educationId}/addSection`,addSectionToEducation);
  }
  addSubsectionToSection(educationId:string,addSubsection:AddSubsectionToSectionRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/versions/${addSubsection.versionId}/outline/createitem`,addSubsection);
  }
  addOutlineToEducation(educationId:string,outline:AddOutlineToEducationRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}educations/${educationId}/addOutline`,outline);
  }
  updateEducationSection(sectionId:string,sectionUpdateModel:UpdateSectionRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/versions/outline/${sectionId}/update`,sectionUpdateModel);

  }
  updateEducationSubsection(educationId:string,sectionId:number,subSectionId:number,subsectionUpdateModel:UpdateEducationSubSectionRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}educations/${educationId}/sections/${sectionId}/subsections/${subSectionId}/update`,subsectionUpdateModel);
  }
  deleteEducation(courseId:string){
    var apiUrl = environment.apiUrl;
    var body = {courseId:courseId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/courses/${courseId}/delete`,{body:body})
  }
  getEducationSection(sectionId:string){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<SingleResponseModel<EducationOutlineModel>>(`${apiUrl}educations/${educationId}/sections/${sectionId}`)
    return this.httpClient.get<SingleResponseModel<EducationOutlineModel>>(`${apiUrl}api/lms/courses/versions/outline/${sectionId}/update`);
  }
  getEducationSubSection(educationId:string,sectionId:number,subsectionId:number){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<SingleResponseModel<EducationSubOutlineModel>>(`${apiUrl}educations/${educationId}/sections/${sectionId}/subsection/${subsectionId}`);
    return this.httpClient.get<SingleResponseModel<EducationSubOutlineModel>>(`/assets/subsection.json`);
  }
  deleteEducationSection(sectionId:string){
    var apiUrl = environment.apiUrl;
    var body = {itemId:sectionId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/courses/versions/outline/${sectionId}/delete`,{body:body});
  }
  deleteEducationSubSection(educationId:string,sectionId:number,subsectionId:number){
    var apiUrl = environment.apiUrl;
    var body = {subsectionId:subsectionId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}educations/${educationId}/sections/${sectionId}/subsection/${subsectionId}/delete`,{body:body})
  }
  getCategory(categoryId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<EducationCategoryModel>>(`${apiUrl}api/lms/categorytrees/course/leaves/${categoryId}/update`);
  }
  deleteCategory(categoryId:number,categoryTreeId:number,keepChildren:boolean = true){
    var apiUrl = environment.apiUrl;
    var body = {leafId:categoryId,treeId:categoryTreeId,keepChildren:keepChildren};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/categorytrees/course/${categoryTreeId}/leaves/${categoryId}/delete`,{body:body});
  }
  deleteCategoryTree(categoryTreeId:number){
    var apiUrl = environment.apiUrl;
    var body = {treeId:categoryTreeId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/categorytrees/course/${categoryTreeId}/delete`,{body:body});
  }
  createEducationTheme(/*formData:FormData*/model:AddCourseThemeModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/themes/create`,model);
  }
  deleteTheme(themeId:number){
    var apiUrl = environment.apiUrl;
    var body = {themeId:themeId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/courses/themes/${themeId}/delete`,{body:body});
  }
  getEducationTheme(themeId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<EducationThemeModel>>(`${apiUrl}api/lms/courses/themes/${themeId}/update`);
  }
  getEducationThemeDetail(themeId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<any>>(`${apiUrl}api/lms/courses/themes/${themeId}`)
  }
  getEducationThemeList(){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/lms/courses/themes/optionlist`)
  }
  deleteEducationThemeFile(themeId:number,fileId:number){
    var apiUrl = environment.apiUrl;
    var body = {id:fileId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}/educations/themes/${themeId}/files/${fileId}/delete`,{body:body});
  }
  /*deleteEducationTheme(themeId:number){
    var apiUrl = environment.apiUrl;
    var body = {id:themeId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}/educations/themes/${themeId}/delete`,{body:body});
  }*/
  updateEducationTheme(themeId:number,model:UpdateCourseThemeModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/courses/themes/${themeId}/update`,model);
  }
  getSectionDetails(sectionId:string){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<SingleResponseModel<EducationOutlineModel>>(`${apiUrl}educations/${educationId}/sections/${sectionId}`)
    return this.httpClient.get<SingleResponseModel<EducationOutlineModel>>(`${apiUrl}api/lms/courses/versions/outline/${sectionId}`);
  }
  getSectionListItem(versionId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/lms/courses/versions/${versionId}/outline/options`);
  }
  createCategoryTree(model:AddCategoryTreeModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post(`${apiUrl}api/lms/categorytrees/course/create`,model);
  }
  getCategoryTreeTable(){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get(`${apiUrl}api/lms/categorytrees/course/list`);
  }
  getCategoryTable(categoryTreeId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get(`${apiUrl}api/lms/categorytrees/course/${categoryTreeId}/leaves/list`)
  }
}
