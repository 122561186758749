import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateQuestionOptionModel } from '../../../models/questionManagementModels/updateQuestionChoiceModel';
import { QuestionUpdateFields } from '../../../models/questionManagementModels/questionDetailModel';

@Component({
  selector: 'lib-update-question-option',
  templateUrl: './update-question-option.component.html',
  styleUrls: ['./update-question-option.component.css']
})
export class UpdateQuestionOptionComponent implements OnInit {
  questionId:number;
  optionId:number;
  optionForm:FormGroup;
  questionOption:QuestionUpdateFields;
  optionTextFormat:number[];
  optionTextValue:any;
  optionTextDefault:string;
  wrongAnswerTextFormat:number[];
  wrongAnswerTextValue:any;
  wrongAnswerTextDefault:string;
  constructor(private toastrService:ToastrService  ,private formBuilder:FormBuilder,private activatedRoute:ActivatedRoute,private questionService:QuestionService) { }

  ngOnInit(): void {
    this.getParams();
  }
  getOptionTextFnc($ev:any){
    this.optionTextValue = $ev;
  }
  changeOptionFormat($ev:any){
    var el = ($ev.target) as HTMLInputElement
    this.optionTextFormat = [parseInt(el.value)];
  }
  changeWrongAnswerInfoFormat($ev:any){
    var el = ($ev.target) as HTMLInputElement
    this.wrongAnswerTextFormat = [parseInt(el.value)];
  }
  getWrongAnswerInfoTextFnc($ev:any){
    this.wrongAnswerTextValue = $ev;
  }
  getParams(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = param["questionId"];
        this.optionId = param["optionId"];
        this.getQuestionOption(this.questionId,this.optionId);
      }
    })
  }
  getQuestionOption(questionId:number,optionId:number){
    this.questionService.getQuestionOptionUpdateFields(questionId,optionId).subscribe({
      next:(response)=>{
        this.questionOption = response.content;
        this.wrongAnswerTextFormat = [this.questionOption.wrongAnswerInfoFormat];
        this.optionTextFormat = [this.questionOption.format];
        this.optionTextDefault = this.questionOption.label;
        this.wrongAnswerTextDefault = this.questionOption.wrongAnswerInfo ?? ""
        this.createUpdateOptionForm();
      }
    })
  }
  createUpdateOptionForm(){
    this.optionForm = this.formBuilder.group({
      id:[this.questionOption.id],
      value:[this.questionOption.value,Validators.required],
      label:[this.questionOption.label,Validators.required],
      format:[this.questionOption.format,Validators.required],
      active:[this.questionOption.active],
      optionPrefix:[this.questionOption.optionPrefix ?? ""],
      orderValue:[this.questionOption.orderValue],
      isCorrect:[this.questionOption.isCorrect],
      scoreFactor:[this.questionOption.scoreFactor],
      wrongAnswerInfo:[this.questionOption.wrongAnswerInfo ?? ""],
      wrongAnswerInfoFormat:[this.questionOption.wrongAnswerInfoFormat ?? 0]
    })
  }
  updateOption(){
    console.log(this.optionTextValue());
    this.optionForm.get("label")?.setValue(this.optionTextValue());
    this.optionForm.get("wrongAnswerInfo")?.setValue(this.wrongAnswerTextValue());
    if(this.optionForm.valid){
      var optionUpdateModel:UpdateQuestionOptionModel = Object.assign({},this.optionForm.value);
      optionUpdateModel.wrongAnswerInfoFormat = typeof(optionUpdateModel.wrongAnswerInfoFormat) == "string" ? parseInt(optionUpdateModel.wrongAnswerInfoFormat) : optionUpdateModel.wrongAnswerInfoFormat
      optionUpdateModel.format = typeof(optionUpdateModel.format) == "string" ? parseInt(optionUpdateModel.format) : optionUpdateModel.format
      optionUpdateModel.orderValue = typeof(optionUpdateModel.orderValue) == "string" ? parseInt(optionUpdateModel.orderValue) : optionUpdateModel.orderValue
      console.log(optionUpdateModel);
      this.questionService.updateQuestionOption(this.questionId,this.optionId,optionUpdateModel).subscribe({
        next:(response)=>{
          this.toastrService.success("Seçenek başarılı bir şekilde güncellendi");
        },error:(err)=>{
          this.toastrService.error("Seçenek güncellenirken bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Lütfen formu kontrol edin");
    }
  }

}
