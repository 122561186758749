<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div ">
      <font class="form-title-content">{{'mediaContents.uploadMediaTitle' | translate}}</font>
    </div>
    <div class="mb-3">
      <label for="formFile" class="form-label">{{'mediaContents.selectAndUploadMediaFile' | translate}}</label>
      <input class="form-control" (change)="setFile($event)" type="file" id="formFile">
    </div>
    <button class="btn btn-secondary" [disabled]="uploading" (click)="uploadMedia()"><span *ngIf="uploading"
        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'btn.upload' |
      translate}}</button>
    <br /><br />
    <div class="progress" *ngIf="uploading == true">
      <div class="progress-bar" role="progressbar" [style.width]="percentage" [attr.aria-valuenow]="2" aria-valuemin="0"
        [attr.aria-valuemax]="5"></div>
    </div>
  </div>