<div class="formMain form-maroon-border form-default-border">
  <h5>{{'labs.dashboardTitle' | translate}}</h5>
  <div class="mainContainer">
    <div class="tabContainer">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="draft-tab" (click)="switchTab('draft')" data-bs-toggle="tab" data-bs-target="#draft" type="button"
            role="tab" aria-controls="draft" aria-selected="true">{{'labs.draft' | translate}}</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="mylabs-tab" (click)="switchTab('mylabs')" data-bs-toggle="tab" data-bs-target="#mylabs" type="button"
            role="tab" aria-controls="mylabs"  aria-selected="false">{{'labs.myLabs' | translate}}</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="sharedlabs-tab" (click)="switchTab('shared')" data-bs-toggle="tab" data-bs-target="#sharedlabs" type="button"
            role="tab" aria-controls="sharedlabs" aria-selected="false">{{'labs.sharedLabs' | translate}}</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="draft" role="tabpanel" aria-labelledby="draft-tab">
          <br />
          <div class="toolbar-container draft-toolbar">

            <div (click)="createDraftLab()" class="toolbar-item ">
              <i class="bi bi-plus-lg primary"></i>
              <font> {{'btn.createDraftLab' | translate}}</font>
            </div>
            <div (click)="cloneDraftLab()" *ngIf="selectedItem != null" class="toolbar-item ">
              <i class="bi bi-copy success"></i>
              <font> {{'btn.cloneDraftLab' | translate}}</font>
            </div>

            <div (click)="deleteDraftItem()" *ngIf="selectedItem != null" class="toolbar-item ">
              <i class="bi bi-trash-fill danger"></i>
              <font> {{'btn.delete' | translate}}</font>
            </div>
            <div (click)="openWorkbench()" *ngIf="selectedItem != null" class="toolbar-item ">
              <i class="bi bi-eye-fill warning"></i>
              <font> {{'btn.openWorkbench' | translate}}</font>
            </div>

          </div>
          <div class="labs-container" (click)="removeAll($event)">
            <div class="lab-item" #ref (click)="selectLab(ref,'draft')" [attr.data-id]="draft.labId"
              *ngFor="let draft of labDrafts">

              <div class="lab-img">
                <img [src]="apiUrl+'api/cms/iconsets/virtual-lab/icon/default'"
                  style="width:80px;height: auto; object-fit: contain;">
              </div>
              <br />
              <div class="lab-body">
                <div class="lab-name">
                  <b class="lab-name-text">{{draft.name}}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="mylabs" role="tabpanel" aria-labelledby="profile-tab">mylabs</div>
        <div class="tab-pane fade" id="sharedlabs" role="tabpanel" aria-labelledby="contact-tab">sharedlabs</div>
      </div>
    </div>

    <div class="lab-detail-container">
      <h4>{{'labDashboard.labDetailsTitle' |translate}}</h4>
      <div class="detailsContainer" *ngIf="summary && selectedItem != null">
        <div class="detail-item"><b class="lab-details-field-title">{{'labDetails.name'}} : </b>
          <font class="lab-details-field-value">{{summary.name}}</font>
        </div>
        <div *ngIf="summary.description" class="detail-item"><b class="lab-details-field-title">{{'labDetails.description'}} : </b>
          <font class="lab-details-field-value">{{summary.description}}</font>
        </div>
        <div *ngIf="summary.tags" class="detail-item"><b class="lab-details-field-title">{{'labDetails.tags'}} : </b>
          <font class="lab-details-field-value">{{summary.tags}}</font>
        </div>
        <div class="detail-item"><b class="lab-details-field-title">{{'labDetails.vmCount'}} : </b>
          <font class="lab-details-field-value">{{summary.vmCount}}</font>
        </div>
        <div class="detail-item"><b class="lab-details-field-title">{{'labDetails.networkCount'}} : </b>
          <font class="lab-details-field-value">{{summary.networkCount}}</font>
        </div>
        <div *ngIf="summary.images && summary.images.length > 0" class="detail-item"><b class="lab-details-field-title">{{'labDetails.images'}} : </b>
          <ul>
            <li *ngFor="let image of summary.images">
              {{image}}
            </li>
          </ul>
        </div>
        <div class="vmList" *ngIf="summary.vms">
          <b class="lab-details-field-title">{{'labDetails.vmList' | translate}}</b>
          <table class="table table-dark table-hover">
            <thead>
              <tr>
                <th scope="col">{{'labDetails.vmList.name' | translate}}</th>
                <th scope="col">{{'labDetails.vmList.osInfo' | translate}}</th>
                <th scope="col">{{'labDetails.vmList.instanceType' | translate}}</th>
                <th scope="col">{{'labDetails.vmList.options' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let vm of summary.vms">
                <th>{{vm.name}}</th>
                <td>{{vm.osInfo}}</td>
                <td>{{vm.instanceType}}</td>
                <td>{{vm.options}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="networkList" *ngIf="summary.networks">
          <b class="lab-details-field-title">{{'labDetails.networkList' | translate}}</b>
          <table class="table table-dark table-hover">
            <thead>
              <tr>
                <th scope="col">{{'labDetails.network.name' | translate}}</th>
                <th scope="col">{{'labDetails.vmList.clientCount' | translate}}</th>
                <th scope="col">{{'labDetails.vmList.type' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let net of summary.networks">
                <th>{{net.name}}</th>
                <td>{{net.clientCount}}</td>
                <td>{{net.type}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>