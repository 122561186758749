import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.css']
})
export class CreateMenuComponent implements OnInit {
  formUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.formUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/menu/create";
  }

}
