import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { MediaContentDetailsResponse } from '../../models/MediaContent/mediaContentDetailsResponse';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';

@Injectable({
  providedIn: 'root'
})
export class MediaContentService {

  constructor(private httpClient:HttpClient,private activatedRoute:ActivatedRoute) { }
  getMediaContentDetails(mediaid:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<MediaContentDetailsResponse>>(apiUrl+"api/lms/contents/media/"+mediaid+"/details");
  }
  getMediaContentOptions(){
    var apiUrl = environment.apiUrl + "api/lms/contents/media/options";
    return this.httpClient.get<ListResponseModel<ListItemModel>>(apiUrl);
  }
  getMediaAccessToken(){
    var apiUrl = environment.apiUrl + "api/cms/filestore/media/tokens/accesstoken";
    return this.httpClient.get<SingleResponseModel<string>>(apiUrl);
  }
}
