import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseVmImageService } from '../../../services/baseVmImages/base-vm-image.service';
import { BaseVMImageDetailModel } from '../../../models/BaseVMImages/baseVmImageDetailModel';

@Component({
  selector: 'lib-base-vm-image-detail',
  templateUrl: './base-vm-image-detail.component.html',
  styleUrls: ['./base-vm-image-detail.component.css']
})
export class BaseVmImageDetailComponent implements OnInit {
  baseVmImageDetails:BaseVMImageDetailModel;
  constructor(private activatedRoute:ActivatedRoute,private baseVmImageService:BaseVmImageService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var id = param["id"];
        this.baseVmImageService.getBaseVmImageDetails(id).subscribe({
          next:(response)=>{
            this.baseVmImageDetails = response.content;
          }
        });
      }
    })
  }

}
