<h4></h4>
<form
class="formMain form-default-border form-maroon-border" [formGroup]="updateLocaleForm" *ngIf="updateLocaleForm">
<div class="form-title-div">
    <font class="form-title-content">{{'locale.updateLocaleTitle' | translate}}</font>
</div>
    <div class="mb-3">
        <label *ngIf="false" for="id" class="form-label mainLabel">{{'locale.id' | translate}}</label>
        <input type="hidden" formControlName="id" class="form-control" id="id" aria-describedby="id">
    </div>
    <div class="mb-3">
        <label for="name" class="form-label mainLabel">{{'locale.name' | translate}}</label>
        <input type="text" formControlName="name" class="form-control" id="name" aria-describedby="name">
    </div>
    <div class="mb-3">
        <label for="cultureCode" class="form-label mainLabel">{{'locale.cultureCode' | translate}}</label>
        <input type="text" formControlName="cultureCode" class="form-control" id="cultureCode" aria-describedby="cultureCode">
    </div>
    <div class="mb-3">
        <label for="iso639P1" class="form-label mainLabel">{{'locale.iso639p1' | translate}}</label>
        <input type="text" formControlName="iso639P1" class="form-control" id="iso639P1" aria-describedby="iso639P1">
    </div>
    <div class="mb-3">
        <label for="iso639P2" class="form-label mainLabel">{{'locale.iso639p2' | translate}}</label>
        <input type="text" formControlName="iso639P2" class="form-control" id="iso639P2" aria-describedby="iso639P2">
    </div>
    <div class="mb-3">
        <label for="wildcardLocaleId" class="form-label mainLabel">{{'locale.wildcardLocaleId' | translate}}</label>
        <input type="text" formControlName="wildcardLocaleId" class="form-control" id="wildcardLocaleId" aria-describedby="wildcardLocaleId">
    </div>
    <!--<div class="mb-3">
        <label for="wildcardLocaleId" class="form-label">Wildcard Locale</label>
        <select class="form-select" formControlName="wildcardLocaleId" aria-label="">
            <option *ngFor="let locale of locales" [value]="locale.value">{{locale.label}}</option>
          </select>
    </div>-->
    <div class="mb-3">
        <label for="parentLocaleId" class="form-label mainLabel">{{'locale.parentLocale' | translate}}</label>
        <select class="form-select" formControlName="parentLocaleId" aria-label="">
            <option [selected]="localeUpdateData.parentLocaleId == null || localeUpdateData.parentLocaleId == ''" [value]="''">None</option>
            <option *ngFor="let locale of locales" [value]="locale.value">{{locale.label}}</option>
          </select>
    </div>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="active" value="" id="active">
        <label class="form-check-label mainLabel" for="active">
            {{'global.active' | translate}}
        </label>
      </div>
      <div style="text-align: right;">
        <button class="btn btn-primary btn-sm" (click)="submitForm()">{{'btn.update' | translate}}</button>
    </div>

</form>
