import { WebRTCModel } from './../../models/WebRTC/webRTCModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { Toast, ToastrService } from 'ngx-toastr';
import { GlobalModalService } from '../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../models/ModalManagementModels/modalCreateModel';
import { TranslateService } from '@ngx-translate/core';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
import { VideoPartService } from '../../services/videoParts/video-part.service';
import { Observable } from 'rxjs';
declare var bootstrap:any;
@Component({
  selector: 'lib-rtc-video',
  templateUrl: './rtc-video.component.html',
  styleUrls: ['./rtc-video.component.css'],
})

export class RtcVideoComponent implements OnInit {
  @Input() sessionHubObject: any;
  sessionHub: SignalR.HubConnection;
  status: boolean = false;
  @Input() sessionName: string;
  @Input() instructor: boolean; //kullanıcının eğitmen olup olmadığı bilgisine göre video başlatma seçenekleri olacak.
  public connections: Map<string, RTCPeerConnection> = new Map<string,RTCPeerConnection>(); //viewerlerin bağlantıları burada olacak
  public senderConnections:Map<string,RTCPeerConnection> = new Map<string,RTCPeerConnection>(); //sender kullanıcının bağlantıları burada tutulacak
  public allowVideo: boolean = true;
  public sender: boolean = false; //kullanıcının aktif olarak aktardığı bir veri varsa true yoksa false
  public viewer: boolean = false; //bu iptal edilecek. bir kullanıcı hem gönderen hem alıcı olabilir.
  public senderId:string = "";
  senderStream: MediaStream;
  senderOtherStream:MediaStream;
  screenRecord:MediaStream;
  senderPeer: RTCPeerConnection;
  rtcStunUrl:string = "";
  rtcTurnUrl:string = "";
  rtcCredential:string = "";
  rtcUsername:string="";
  isClicked:boolean = false;
  serverInfoAccess:boolean = false;
  microphoneEnabled=true;
  cameraEnabled=true;
  streamPeers:number = 0;
  screenMedia:MediaStream;
  screenMediaId:string = "";
  handlerModal:boolean = false;
  queue:number = 0;
  cameraRecordUuid:string;
  screenRecordUuid:string;
  cameraRecordQuery:{file:any,order:string}[] = [];
  screenRecordQuery:{file:any,order:string}[] = [];
  mediaRecorder:MediaRecorder;
  cameraRecorderOrder = 0;
  cameraRecordTransferQueue:number = 0;
  screenRecorderOrder = 0;
  screenRecordTransferQueue:number = 0;
  screenMediaRecorder:MediaRecorder;
  @Output() secondaryStream = new EventEmitter<{user:string,stream:MediaStream}>();
  @Output() closedStream = new EventEmitter<string>();
  constructor(private videoPartService:VideoPartService, private toastrService: ToastrService,private globalModalService:GlobalModalService,private translateService:TranslateService) {}
  configuration: RTCConfiguration = {iceServers:[]};
  ngOnInit(): void {
    document.addEventListener('click',()=>{
      this.isClicked = true;
    })
    if (this.sessionHubControl()) {
      this.serverInfoRequest();
      this.serverInfoResponse().then(()=>{
        if (this.instructor) {
          this.handleMessages();
          setTimeout(()=>{
            this.requestVideoAllUsers();
          },100);

        }else{
          this.handleMessages();
          setTimeout(()=>{
            this.requestVideoAllUsers();
          },100);
        }
      });
    }
  }
  emitSecondaryStream(userId:string,ms:MediaStream){
    this.secondaryStream.emit({user:userId,stream:ms});
  }
  serverInfoRequest(){
    if(this.serverInfoAccess == false){
      setTimeout(()=>{
        if(this.serverInfoAccess == false){
          this.sessionHub.invoke("WebRTCGetServer");
          this.serverInfoRequest();
          console.log("calistii")
        }
      },1000)
    }


  }
  serverInfoResponse():Promise<boolean>{
    return new Promise((resolve,reject)=>{
      this.sessionHub.on('Webrtc.Server',(res)=>{
        console.log(res);
        this.serverInfoAccess = true;
        this.rtcCredential = res.credential;
        this.rtcStunUrl = res.stunAddress;
        this.rtcTurnUrl = res.turnAddress;
        this.rtcUsername = res.username;
        console.log(this.rtcStunUrl);
        console.log(this.rtcTurnUrl);
        var iceServer:RTCIceServer = {urls:[this.rtcStunUrl,this.rtcTurnUrl],username:this.rtcUsername,credential:this.rtcCredential};
        this.configuration.iceServers?.push(iceServer);
        console.log(this.configuration);
        resolve(true);
      })
    })

  }
  sessionHubControl() {
    if (this.sessionHubObject == undefined || this.sessionHubObject == null) {
      this.toastrService.error('Oturum Hatası', 'WebRTC Video');
      return false;
    } else {
      this.sessionHub = this.sessionHubObject as SignalR.HubConnection;
      return true;
    }
  }
  requestVideoAllUsers(){
    this.sendConnectionRequestToAll({type:'requestStream',data:{}});
  }
  requestVideoSpecifyUser(userId:string){
    this.sendConnectionRequestWithUserId(userId,{type:'requestStream',data:{}});
  }
  sendStartedStreamMessage(){
    this.sendConnectionRequestToAll({type:'streamStarted',data:{}});
  }
  senderSetStream(userId:string,peer:RTCPeerConnection){
    this.senderConnections.set(userId,peer);
  }
  senderGetStream(userId:string){
    return this.senderConnections.get(userId)
  }
  receiverSetStream(userId:string,peer:RTCPeerConnection){
    this.connections.set(userId,peer);
  }
  receiverGetStream(userId:string){
    return this.connections.get(userId)
  }
  async senderInitVideo() {
    if(this.cameraRecordTransferQueue > 0 || this.screenRecordTransferQueue > 0){
      this.toastrService.error("Önceki akış kayıt ediliyor. Lütfen bekleyiniz");
      return;
    }
    await navigator.mediaDevices.getUserMedia({ video: true,audio:true }).then(stream=>{
      this.senderStream = stream;
    }).catch((err)=>{
      this.toastrService.error("Kamera erişimi sağlanamadı, sitenin kamera izninin olduğundan ve kameranın başka bir program tarafından kullanılmadığından emin olun");
      throw "Error";
      return;
    });
    this.sender = true;

    this.senderOtherStream = await navigator.mediaDevices.getUserMedia({ video: true,audio:true });
    let senderVideoEl = document.getElementById('senderVideo') as HTMLVideoElement;
    senderVideoEl.srcObject = this.senderStream;
    senderVideoEl.volume = 0;
    this.cameraEnabled = true;
    this.microphoneEnabled = true;
    this.sendStartedStreamMessage();
    this.startSaveCameraStream()

  }

  //isteği gönderen kullanıcıya akış başlatan fonksiyon
  async sendStreamToUser(userId:string){
    var currentPeer = await this.createPeer(true,userId);
    this.senderSetStream(userId,currentPeer);
    var offer = await currentPeer.createOffer({offerToReceiveAudio:false,offerToReceiveVideo:false});
    await currentPeer.setLocalDescription(offer);
    this.sendConnectionRequestWithUserId(userId,{type:"offer",data:offer})
  }
  getRecordUuid(recordType:number){
    this.videoPartService.getKeyForSendVideoPartToServer(this.sessionName,recordType).subscribe({
      next:(response:any)=>{
        if(recordType == 0){
          this.cameraRecordUuid = response.content.recordKey;
          setTimeout(()=>{
            while(this.cameraRecordQuery.length > 0){
              let request = this.cameraRecordQuery.pop();
              if(request != undefined){
                this.sendCameraPartToServer(request);
              }

            }
          },4000)

        }else if(recordType == 1){
          this.screenRecordUuid = response.content.recordKey;
          setTimeout(()=>{
            while(this.cameraRecordQuery.length > 0){
              let request = this.screenRecordQuery.pop();
              if(request != undefined){
                this.sendScreenPartToServer(request);
              }

            }
          },4000)
        }
      },
      error:(err)=>{
        this.toastrService.error("Video kaydı gerçekleştirilemiyor");
      }
    })
  }
  sendCameraPartToServer(data:{file:any,order:string}){
    var fd = new FormData();
    fd.append("customUUID",this.cameraRecordUuid)
    fd.append("order",data.order);
    fd.append("part",data.file);
    fd.append("sessionId",this.sessionName);
    this.cameraRecordTransferQueue+=1;
    let request = this.videoPartService.sendVideoPartToServer(fd,this.sessionName);
    request.subscribe({
      next:(response)=>{
        this.cameraRecordTransferQueue-=1;
      },error:(err)=>{
        this.cameraRecordTransferQueue-=1;
      }
    });
  }
  sendScreenPartToServer(data:{file:any,order:string}){
    var fd = new FormData();
    fd.append("customUUID",this.screenRecordUuid)
    fd.append("order",data.order);
    fd.append("part",data.file);
    fd.append("sessionId",this.sessionName);
    this.screenRecordTransferQueue +=1;
    let request = this.videoPartService.sendVideoPartToServer(fd,this.sessionName);
    request.subscribe({
      next:(response)=>{
        this.screenRecordTransferQueue-=1;
      },error:(err)=>{
        this.screenRecordTransferQueue-=1;
      }
    });
  }
  async startSaveCameraStream(){
    this.getRecordUuid(0);
    this.cameraRecorderOrder =  0;
    const mediaRecordOptions:MediaRecorderOptions = {
      mimeType:'video/webm'
    }
    this.mediaRecorder = new MediaRecorder(this.senderStream,mediaRecordOptions);
    this.mediaRecorder.start(2000);
    this.mediaRecorder.ondataavailable = (event) => 
    {
      console.log(event);
      if(event.data && event.data.size > 0){
        var reader = new FileReader();
        reader.readAsDataURL(event.data);
        reader.onloadend = (ev) =>{
          var fd = new FormData();
          fd.append("customUUID",this.cameraRecordUuid)
          fd.append("order",this.cameraRecorderOrder.toString());
          fd.append("part",event.data);
          fd.append("sessionId",this.sessionName);
          let request = this.videoPartService.sendVideoPartToServer(fd,this.sessionName);
          if(this.cameraRecordUuid == null ||this.cameraRecordUuid == ""){
            this.cameraRecordQuery.push({file:event.data,order:this.cameraRecorderOrder.toString()});
          }else{
          request.subscribe({
            next:(resp)=>{
              console.log(resp);
            }
          });
          }

          this.cameraRecorderOrder += 1;

        }
      }
    };
  }
  async StartSaveScreenStream(screenStream:MediaStream){
    this.getRecordUuid(1);
    this.screenRecorderOrder =  0;
    const mediaRecordOptions:MediaRecorderOptions = {
      mimeType:'video/webm'
    }
    this.screenMediaRecorder = new MediaRecorder(screenStream,mediaRecordOptions);
    this.screenMediaRecorder.start(2000);
    this.screenMediaRecorder.ondataavailable = (event) => 
    {
      console.log(event);
      if(event.data && event.data.size > 0){
        var reader = new FileReader();
        reader.readAsDataURL(event.data);
        reader.onloadend = (ev) =>{
          var fd = new FormData();
          fd.append("customUUID",this.screenRecordUuid)
          fd.append("order",this.screenRecorderOrder.toString());
          fd.append("part",event.data);
          fd.append("sessionId",this.sessionName);
          let request = this.videoPartService.sendVideoPartToServer(fd,this.sessionName);
          if(this.screenRecordUuid == null ||this.screenRecordUuid == ""){
            this.screenRecordQuery.push({file:event.data,order:this.screenRecorderOrder.toString()});
          }else{
          request.subscribe({
            next:(resp)=>{
              console.log(resp);
            }
          });
          }
          this.screenRecorderOrder += 1;

        }
      }
      
    };
  }
  async stopSaveScreenStream(){
    this.videoPartService.endVideoPart(this.sessionName,this.screenRecordUuid).subscribe();
    this.screenRecordUuid = "";
    this.screenRecordQuery = [];
    if(this.screenMediaRecorder !== undefined){
      this.screenMediaRecorder.ondataavailable = () => {};
    }


  }
  async stopSaveCameraStream(){
    console.log(this.sessionName);
    console.log(this.cameraRecordUuid);
    this.videoPartService.endVideoPart(this.sessionName,this.cameraRecordUuid).subscribe();
    this.cameraRecordUuid = "";
    this.cameraRecordQuery = [];
    this.mediaRecorder.ondataavailable = () => {};
  }

  getPrimaryVideoContainer(userId:string){
    return document.getElementById("container_rtc_"+userId);
  }
  getSecondaryVideoContainer(userId:string){
    return document.getElementById("container_rtc_other_"+userId);
  }
  changeMediaAccess(userId:string,videoAccess:boolean,microphoneAccess:boolean){
    var mediaAccessDiv = document.createElement('div');
    mediaAccessDiv.id = "mediaAccessIcons_"+userId;
    mediaAccessDiv.style.position = "absolute";
    mediaAccessDiv.style.right = "0px";
    mediaAccessDiv.style.top = "0px";
    mediaAccessDiv.style.width = "50px";
    mediaAccessDiv.style.display = "flex";
    mediaAccessDiv.style.flexDirection = "row";
    mediaAccessDiv.style.flexWrap = "nowrap";
    mediaAccessDiv.style.justifyContent = "center";
    mediaAccessDiv.style.alignItems = "center";
    mediaAccessDiv.style.height = "30px";
    mediaAccessDiv.style.borderRadius = "0px 10px 0px 0px";
    mediaAccessDiv.style.border = "2px solid blue";
    mediaAccessDiv.style.backgroundColor = "#000000";
    var microphoneIcon = document.createElement('i');
    microphoneIcon.style.color = "white";
    microphoneAccess ? microphoneIcon.classList.add('bi','bi-mic-fill') : microphoneIcon.classList.add('bi','bi-mic-mute-fill') ;
    var cameraIcon = document.createElement('i');
    cameraIcon.style.color = "white";
    videoAccess ? cameraIcon.classList.add('bi','bi-camera-video-fill') : cameraIcon.classList.add('bi','bi-camera-video-off-fill');
    var mediaAccessElement = document.getElementById("mediaAccessIcons_"+userId);
    if(mediaAccessElement !== null){
      mediaAccessElement.remove();
    }
    if(videoAccess == false || microphoneAccess == false){
      mediaAccessDiv.appendChild(microphoneIcon);
      mediaAccessDiv.appendChild(cameraIcon);
      var videoElement = this.getPrimaryVideoContainer(userId) as HTMLVideoElement;
      videoElement.appendChild(mediaAccessDiv);
    }


  }
  addVideoPopOutItem(userId:string,stream:MediaStream){
    var popOutItem = document.createElement('i');
    popOutItem.style.position = "absolute";
    popOutItem.id = "rtc_popout_"+userId;
    popOutItem.style.left = "0px";
    popOutItem.style.left = "0px";
    popOutItem.style.color = "#ffffff"
    popOutItem.style.padding = "5px";
    popOutItem.style.background = "#000000"
    popOutItem.style.borderRadius = "10px 0px 0px 0px";
    popOutItem.style.border = "2px solid blue";
    popOutItem.classList.add("bi", "bi-window-plus");
    popOutItem.style.fontSize = "20px";
    popOutItem.addEventListener('click',()=>{
      let userVideoPopOut = window.open("/popout","","width=900, height=800")
      var userVideo = document.createElement('video');
      userVideoPopOut?.addEventListener("load",()=>{
        var idPrefix = "rtc_popout_";
        userVideo.id = idPrefix+userId;
        userVideo.style.width = "100%";
        userVideo.style.borderRadius = "15px";
        userVideo.style.border = "2px solid blue";
        userVideo.autoplay = true;
        userVideo.srcObject = new MediaStream(stream);
        userVideoPopOut?.document.body.appendChild(userVideo);
        setTimeout(()=>{
          userVideo.play();
        },1000);
      })





    })
    return popOutItem;
  }
  async addVideoElement(userId:string,stream:MediaStream,seconary:boolean){
    var userVideoContainer = document.createElement('div');
    var containerPrefix = !seconary ? "container_rtc_" : "container_rtc_other_";
    userVideoContainer.id = containerPrefix+userId;
    userVideoContainer.style.position = "relative";
    userVideoContainer.style.borderRadius = "15px";
    userVideoContainer.style.width="100%";
    userVideoContainer.style.maxHeight = "max-content"
    var userVideo = document.createElement('video');
    var idPrefix = !seconary ? "rtc_" : "rtc_other_";
    userVideo.id = idPrefix+userId;
    userVideo.style.width = "100%";
    userVideo.style.borderRadius = "15px";
    userVideo.style.border = "2px solid blue";
    userVideo.autoplay = true;
    userVideo.srcObject = stream;
    userVideo.onplaying = () =>{
      userVideoContainer.appendChild(userVideo);
      userVideoContainer.appendChild(this.addVideoPopOutItem(userId,stream));
      document.getElementsByClassName('videoList')[0].append(userVideoContainer);
    }
    console.log("userVideo Src ---------------------------------");
    userVideo.onabort = () => {
      alert("aborted");
    }
    userVideo.onended = () => {
      alert("video ended");
    }
    userVideo.onwaiting = () => {
      alert("video waiting");
    }
    

  }
  async createPeer(send:boolean,userId:string){
    var peerConnection = new RTCPeerConnection(this.configuration);
    peerConnection.onicecandidate = (event) =>{
      if(event.candidate){
        this.sendConnectionRequestWithUserId(userId,{type:'candidate',data:event.candidate?.toJSON()})
      }
    }
    //kullanıcı gönderici ise videoyu bağlantıya ekle
    if(send){

      const stream = this.senderStream;
      const otherStream = this.senderOtherStream;
      var videoTrack = stream.getVideoTracks()[0];
      var audioTrack = stream.getAudioTracks()[0];
      peerConnection.addTrack(videoTrack,stream);
      peerConnection.addTrack(audioTrack,stream);
      var otherVideo = otherStream.getVideoTracks()[0]
      peerConnection.addTrack(otherVideo,otherStream)
      /* Ekran paylaşımı yapılıyorsa ikincil videoyu screenMedia ile değiştir. */
      if(this.screenMediaId != ""){
        if(this.screenMedia != null){
          peerConnection.getSenders().forEach((sender)=>{
            if(sender.track?.id == otherVideo.id){
              sender.replaceTrack(this.screenMedia.getVideoTracks()[0])
            }
          })
        }

      }
      otherVideo.onended = (event) =>{
        console.log("track ended");
      }
    }
    //kullanıcı alıcı ise videoyu al ve receiverVideo id li elemente ekle
    if(!send){
      var remoteStream = new MediaStream();
      var remoteOtherStream = new MediaStream();
      //(document.getElementById('receiverVideo') as HTMLVideoElement).srcObject = remoteStream;
      this.addVideoElement(userId,remoteStream,false)
      //this.addVideoElement(userId,remoteOtherStream,true)
      this.streamPeers = 0;
      peerConnection.ontrack = (event)=>{
        console.log(event)
        event.streams[0].getTracks().forEach((track)=>{
          if(this.streamPeers < 4){
            remoteStream.addTrack(track);
          }else{
            remoteOtherStream.addTrack(track);
            track.onended = (event) =>{
              console.log("track ended");
            }
            this.emitSecondaryStream(userId,remoteOtherStream);
          }
          this.streamPeers+=1;
          console.log(this.streamPeers);
          console.log("r1");
          console.log(remoteStream.getTracks());
          console.log("r2");
          console.log(remoteOtherStream.getTracks());
        })
        event.streams[0].getTracks()[0]
        console.log(event.streams[0].getTracks())
      }
      peerConnection.onnegotiationneeded = (event)=>{
        console.log("negotiation need");
      }
    }
    this.handleStates(userId,peerConnection);
    return peerConnection;
  }
  async setScreenStream(){
    var success:boolean = true;
    await navigator.mediaDevices.getDisplayMedia({video:true}).then((stream)=>{
      this.screenMedia = stream;
    }).catch((err)=>{
      success = false;
      this.toastrService.error("Bir hata oluştu");
      throw "Error";
      return;
    });
    
    /*Tam ekran kısıtlaması uygulamak istenirse yorum satırındaki kodu aktifleştir. (Firefoxta çalışmıyor)*/
    /*let surface = this.screenMedia.getVideoTracks()[0].getSettings() as any;
    console.log(surface);
    if(surface.displaySurface !== undefined && surface.displaySurface == "monitor"){
      success = true;
    }else if(surface.displaySurface === undefined){
      success = true;
    }else{
      this.screenMedia.getTracks().forEach((track)=>{
        track.stop();
      })
    }*/

    /* this.senderOtherStream.getTracks().forEach((track)=>{
      this.senderStream.getTracks().forEach((track)=>{
      })
    })*/

    if(success){

      this.senderConnections.forEach((peer)=>{
        peer.getSenders().forEach((transceiver)=>{
          if(transceiver.track?.id == this.senderOtherStream.getVideoTracks()[0].id){
            transceiver.replaceTrack(this.screenMedia.getVideoTracks()[0]);

          }
        })
      })
      this.screenMediaId = this.screenMedia.getVideoTracks()[0].id;
      this.StartSaveScreenStream(this.screenMedia);
    }else{
      this.toastrService.error("Paylaşım gerçekleştirilemedi");
      //this.toastrService.error("Yalnızca tam ekran paylaşımı yapılabilir")
    }

  }
  async stopScreenStream(){
    this.senderConnections.forEach((peer)=>{
      peer.getSenders().forEach((transceiver)=>{
        if(transceiver.track?.id == this.screenMediaId){
          transceiver.replaceTrack(this.senderOtherStream.getVideoTracks()[0]);

        }
      })
    })
    this.screenMediaId = "";
    this.stopSaveScreenStream();
    this.screenMedia.getTracks().forEach((track)=>{
      track.stop();
    })
  }
  async webrtcMediaAccessChange(){
    this.sessionHub.invoke("WebRTCBroadcast",this.sessionName,{type:"webrtcMediaAccessChanged",data:{microphone:this.microphoneEnabled,camera:this.cameraEnabled}});
  }
  async handleOffer(userId:string,data:WebRTCModel){
    this.status = true;
    var currentPeer = await this.createPeer(false,userId);
    this.receiverSetStream(userId,currentPeer)
    await currentPeer.setRemoteDescription(new RTCSessionDescription(data.data));
    const answer = await currentPeer.createAnswer();
    await currentPeer.setLocalDescription(answer);
    this.sendConnectionRequestWithUserId(userId,{type:"answer",data:answer})
    this.decreaseQueueTime();
  }
  async handleAnswer(userId:string,data:WebRTCModel){
    var peer = this.senderGetStream(userId);
    if(peer != undefined){
      await peer.setRemoteDescription(new RTCSessionDescription(data.data));
    }

  }
  async handleCandidate(userId:string,received:WebRTCModel){
    var rec = this.receiverGetStream(userId);
    var send = this.senderGetStream(userId);
    if(rec != undefined){
      rec.addIceCandidate(new RTCIceCandidate(received.data));
    }
    if(send != undefined){
      send.addIceCandidate(new RTCIceCandidate(received.data));
    }

  }

  sendConnectionRequestToAll(data: any) {
    this.sessionHub.invoke('WebRTCBroadcast', this.sessionName, data);
  }
  sendConnectionRequestWithUserId(userId: string, data: any) {
    this.sessionHub.invoke('WebRTCSingle', userId, data);
  }
  async handleStates(userId:string,peerConnection:RTCPeerConnection){
    peerConnection.onconnectionstatechange = () => {
      console.log(peerConnection.connectionState);
      if(peerConnection.connectionState == "disconnected"){
        document.getElementById('container_rtc_'+userId)?.remove();
        document.getElementById('container_rtc_other_'+userId)?.remove();
      }
    }
  }
  async stopSending(){
    this.senderStream.getTracks().forEach((track)=>{
      track.enabled = false;
      track.stop();
    })
    this.senderOtherStream.getTracks().forEach((track)=>{
      track.enabled = false;
      track.stop();
    })
    this.sender = false;
    this.senderConnections.forEach((peer)=>{
      peer.close();
    })
    this.senderConnections.clear();
    if(this.connections.size == 0){
      this.status = false;
    }
    this.sessionHub.invoke("WebRTCBroadcast",this.sessionName, {type:"streamClosed",data:null});
    this.stopSaveCameraStream();
    if(this.screenRecordUuid !== "" && this.screenRecordUuid != null){
      this.stopSaveScreenStream();
    }

  }
  async toggleVideo(){
    this.senderStream.getVideoTracks().forEach(vid=>{
      vid.enabled = !vid.enabled;
      this.cameraEnabled = vid.enabled;
    })
    this.senderOtherStream.getVideoTracks().forEach(vid=>{
      vid.enabled = !vid.enabled;
      this.cameraEnabled = vid.enabled;
    })
    this.webrtcMediaAccessChange();
  }
  async toggleMicrophone(){
    this.senderStream.getAudioTracks().forEach(aud=>{
      aud.enabled = !aud.enabled;
      this.microphoneEnabled = aud.enabled;
    })
    this.senderOtherStream.getAudioTracks().forEach(aud=>{
      aud.enabled = !aud.enabled;
      this.microphoneEnabled = aud.enabled;
    })
    this.webrtcMediaAccessChange();

  }
  async waitAClick():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      let me = this;
      setTimeout(()=>{
        if(me.isClicked){
          setTimeout(()=>{
            resolve(true);
          },this.queue)
          this.queue+=1000;
          console.log("tıklandı");
        }else{
          resolve(me.waitAClick());
        }
      },1000)
    })
  }
  emitClosedStream(userId:string){
    this.closedStream.emit(userId);
  }
  closeStream(userId:string){
    document.getElementById('container_rtc_'+userId)?.remove();
    document.getElementById('container_rtc_other_'+userId)?.remove();
    var connection = this.connections.get(userId);
    if(connection){
      connection.onconnectionstatechange = (ev) => {};
    }
    this.emitClosedStream(userId);
    this.connections.delete(userId);
    console.log(this.connections)
    console.log("rtc closed")
    if(this.connections.size == 0){
      this.status = false;
    }
  }
  peerControl(){

      var peerObj = this.connections.entries();
      var obj = [...peerObj];
      console.log(obj)

  }
  popOutVideo(){
    
  }
  decreaseQueueTime(){
    if(this.queue - 1000 <= 0){
      this.queue = 0;
    }else{
      this.queue-=1000;
    }
  }
  async handleMessages() {
    this.sessionHub.on('WebRTCMessage', (userId:string, data:WebRTCModel) => {
      if(data.type == "offer"){
        console.log("akış teklifi alındı")
        this.viewer = true;
        if(this.isClicked == false){
          if(this.handlerModal == false){
            var myModal = new bootstrap.Modal(document.getElementById('rtcModal'));
            myModal.show();
            //let modalMessage = this.translateService.instant("webRtcWaitClickModal");
            //let modalData:ModalCreateModel = {data:'<p>'+modalMessage+'</p>',helperModals:[],title:"WebRTC Video",type:ModalTypes.CUSTOMHTML};
            //this.globalModalService.showModal(modalData);
            this.handlerModal = true;
          }
          this.waitAClick().then(()=>{
            setTimeout(()=>{this.handleOffer(userId,data);},500);
            console.log("clicked");
            console.log("offer received "+userId);
          });
        }else{
          setTimeout(()=>{this.handleOffer(userId,data);},this.queue);
          this.queue+=1000;
        }

      }else if(data.type == "answer"){
        console.log("akış yanıtı alındı")
        this.handleAnswer(userId,data);
      }else if(data.type == 'candidate'){
        console.log("akış için candidate")
        this.handleCandidate(userId,data);
      }else if(data.type == 'requestStream'){
        console.log("kullanıcı akış istedi");
        if(this.sender == true){
          console.log("akış gönderildi")
          this.sendStreamToUser(userId);
        }
      //Kullanıcının mevcut bir akış olması halinde akışı istemesi için gerekli type
      }else if(data.type == 'streamStarted'){
        console.log("akış başladı");
        //Göndericinin akış başlattığını bildirmesi için gerekli type
        this.requestVideoSpecifyUser(userId);
      }else if(data.type == 'streamClosed'){
        this.closeStream(userId);
      }else if(data.type == 'webrtcMediaAccessChanged'){
        this.changeMediaAccess(userId,data.data.camera,data.data.microphone);
      }

    });
  }
  generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
