import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-update-base-vm-image',
  templateUrl: './update-base-vm-image.component.html',
  styleUrls: ['./update-base-vm-image.component.css']
})
export class UpdateBaseVmImageComponent implements OnInit {
  id:string;
  formUrl:string;
  baseApi = this.apiUrlService.getApiUrl();
  constructor(private apiUrlService:ApiUrlService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["id"];
        this.formUrl = this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages/"+this.id+"/update"
      }
    })
  }

}
