import { Component, OnInit } from '@angular/core';
import { ChunkUploaderService } from '../../../services/chunkUploader/chunk-uploader.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-upload-removable-image',
  templateUrl: './upload-removable-image.component.html',
  styleUrls: ['./upload-removable-image.component.css']
})
export class UploadRemovableImageComponent implements OnInit {
  removableMediaId:string;
  uploading:boolean = false;
  removableMediaFile:any;
  totalChunks:number = 0;
  uploadedChunks:number = 0;
  percentage:string;
  constructor(private chunkUploader:ChunkUploaderService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.removableMediaId = param["id"];
      }
    })
  }
  setFile($ev:any){
    this.removableMediaFile = $ev.target.files[0];
  }
  uploadRemovableImage(){
    if(this.removableMediaFile){
      //formData.set("file",this.baseImageFile);
      this.uploading = true;
      /*this.virtualLabService.uploadBaseVmImage(this.baseImageId,formData).subscribe({
        next:(response)=>{
          this.uploading = false;
          this.toastrService.success("File uploaded successfully");
        },error:(err)=>{
          this.toastrService.error("An error occurred");
          this.uploading = false;
        }
      });*/
      this.chunkUploader.getProgress().subscribe({
        next:(response)=>{
          this.totalChunks = response.totalCount;
          this.uploadedChunks = response.uploaded
          this.percentage = Math.ceil((this.uploadedChunks*100)/this.totalChunks)+"%";
        }
      })
      this.chunkUploader.uploadFileWithChunks(this.removableMediaFile,environment.apiUrl+`api/range/vm/removableimages/${this.removableMediaId}/upload`,1024 * 1024 * 20,{redirect:"/range/vm/removableimages/"+this.removableMediaId+"/details"}).then(()=>{
        this.uploading = false;
        
      });

    }else{
      this.uploading = false;
      this.toastrService.error("Please select a removable image file");
    }
  }

}
