<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.updateVersion' | translate}}</font>
    </div>
<div *ngIf="versionUpdateModel">
<div class="mb-3">
    <label for="codeSuffix" class="form-label mainLabel">Code Suffix</label>
    <input type="text" class="form-control" [value]="versionUpdateModel.codeSuffix" id="codeSuffix" name="codeSuffix"
        [placeholder]="'Code Suffix'">
</div>
<div class="mb-3">
    <label for="educationName" class="form-label mainLabel">Sürüm Adı</label>
    <input type="text" class="form-control" [value]="versionUpdateModel.name" id="educationName" name="educationName"
        [placeholder]="'Sürüm Adı'">
</div>
<div class="mb-3">
    <div class="form-check">
        <input class="form-check-input" [checked]="versionUpdateModel.active" type="checkbox" value="" name="isActive" id="isActive">
        <label class="form-check-label mainLabel"  for="isActive">
            Aktif
        </label>
    </div>
</div>
<div [class.displayNone]="versionUpdateModel.image == null" class="mb-3">
    <b class="mainLabel">Sürüm Resmi</b><br/>
    <img [src]="getImageUrl(versionUpdateModel.image ?? '')">
</div>
<div [class.displayNone]="versionUpdateModel.image == null" class="mb-3">
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" name="deleteImage" id="deleteImage">
        <label class="form-check-label mainLabel" for="deleteImage">
            Resmi sil
        </label>
    </div>
</div>
<div class="mb-3">
    <label for="catalogImage" class="form-label mainLabel">{{'courseManagement.catalogImage' | translate}}</label>
    <input class="form-control" (change)="changeCatalogImage($event)" type="file" id="catalogImage">
</div>
<image-cropper [output]="'base64'" [imageChangedEvent]="catalogImageChangedEvent" format="png"
    (imageCropped)="catalogImageCropped($event)" (imageLoaded)="catalogImageLoaded($event)"
    (cropperReady)="catalogCropperReady()" (loadImageFailed)="catalogImageLoadFailed()" [cropperStaticHeight]="180"
    [cropperStaticWidth]="150"></image-cropper>



    <div [class.displayNone]="versionUpdateModel.teaserImage == null" class="mb-3">
        <b class="mainLabel">Teaser Resmi</b><br/>
        <img [src]="getImageUrl(versionUpdateModel.teaserImage ?? '')">
    </div>
    <div [class.displayNone]="versionUpdateModel.teaserImage == null" class="mb-3">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" name="deleteTeaserImage" id="deleteTeaserImage">
            <label class="form-check-label mainLabel" for="deleteTeaserImage">
                Teaser Resmini sil
            </label>
        </div>
    </div>
<div class="mb-3">
    <label for="detailPageImage" class="form-label mainLabel">{{'courseManagement.detailImage' | translate}}</label>
    <input class="form-control" (change)="changeDetailImage($event)" type="file" id="detailPageImage">
</div>
<image-cropper [output]="'base64'" [autoCrop]="true" [imageChangedEvent]="detailImageChangedEvent" format="png"
    (imageCropped)="detailImageCropped($event)" (imageLoaded)="detailImageLoaded($event)"
    (cropperReady)="detailCropperReady()" (loadImageFailed)="detailImageLoadFailed()" [cropperStaticHeight]="180"
    [cropperStaticWidth]="150"></image-cropper>

<div class="mb-3">
    <label for="description" class="form-label mainLabel">{{'courseManagement.courseDescription' | translate}}</label>
    <textarea [value]="versionUpdateModel.teaserText" class="form-control" id="description" name="description"
        [placeholder]="'courseManagement.courseDescription' | translate"></textarea>
</div>
<label for="descriptionDetail" class="form-label mainLabel">{{'courseManagement.courseDetailedDescription' | translate}}</label>

<textarea [id]="'descriptionDetail'" name="descriptionDetail" class="description-layout-editor" id="1"
    data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    autocomplete="off" data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;">
{{versionUpdateModel.description}}</textarea>

<label for="theme" class="form-label mainLabel">Tema</label><br />
<div class="mb-3 input-group">

    <select class="form-control form-select" id="theme" name="theme">
        <option [selected]="versionUpdateModel.themeId == null">Tema Ekleme</option>
        <option *ngFor="let theme of themeList" [selected]="versionUpdateModel.themeId == theme.value" [value]="theme.value">{{theme.label}}</option>
    </select>
</div>
<div class="mb-3">
    <label for="maxScore" class="mainLabel">Maksimum Skor</label>
    <input [value]="versionUpdateModel.maxScore" class="form-control" type="number" value="0" min="0" id="maxScore" />
</div>
<div class="mb-3">
    <label for="maxScore" class="mainLabel">Geçer Puan</label>
    <input [value]="versionUpdateModel.passingScore" class="form-control" type="number" value="0" min="0" id="passingScore" />
</div>
<div class="mb-3">
    <label for="maxScore" class="mainLabel">Geçer Yüzde</label>
    <input [value]="versionUpdateModel.passingPct" class="form-control" type="number" value="0" min="0" id="passingPct" />
</div>
<div style="text-align: right;">
    <button [disabled]="loading" class="btn btn-sm btn-primary" (click)="updateEducation()">{{'btn.update' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
</div>

</div>
</div>