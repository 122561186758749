import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserManagerService } from 'projects/management-panel/src/app/services/user-manager.service';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {
  customFormData:CustomForm;
  constructor(private userManagementService:UserManagerService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.userManagementService.getUpdateUserForm(params['user']).subscribe({
        next:(response)=>{
          this.customFormData = response;
        }
      })
    })

  }

}
