import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormToJsonElementTypesModel } from '../../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { CreateVMEnvironmentModel } from '../../../models/VirtualLabModels/createVMEnvironment';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';

@Component({
  selector: 'lib-create-vm-environment',
  templateUrl: './create-vm-environment.component.html',
  styleUrls: ['./create-vm-environment.component.css']
})
export class CreateVmEnvironmentComponent implements OnInit {
  fieldNameText:string;
  fieldValueText:string;
  fieldRequiredText:string;
  fieldTypeText:string;
  loaded:boolean = false;
  elementList:DynamicFormToJsonElementTypesModel[] = [];
  configurationJson:string;
  createEnvironmentForm:FormGroup;
  providers:ListItemModel[] = [];
  constructor(private vmInstanceService:VmInstanceService,private toastrService:ToastrService,private formBuilder:FormBuilder,  private translateService:TranslateService, private virtualLabService:VirtualLabService) { }

  ngOnInit(): void {
    this.getFieldTranslations();
    this.createForm();
    this.getProviderTypes();
  }
  createForm(){
    this.createEnvironmentForm = this.formBuilder.group({
      name:["",Validators.required],
      enabled:[false],
      typeId:["kvm",Validators.required],
      tags:[""]
    })
  }
  getFieldTranslations(){
    this.translateService.get("fields.parameterName").subscribe({
      next:(response)=>{
        console.log(response);
        this.fieldNameText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.parameterValue").subscribe({
      next:(response)=>{
        this.fieldValueText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.required").subscribe({
      next:(response)=>{
        this.fieldRequiredText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.valueType").subscribe({
      next:(response)=>{
        this.fieldTypeText = response;
        this.fieldsControl();
      }
    })
  }
  fieldsControl(){
    if(this.loaded){
      return;
    }

    setTimeout(()=>{
      if(this.fieldNameText != "" && this.fieldRequiredText != "" && this.fieldTypeText != "" && this.fieldValueText != ""){
        if(this.loaded){
          return;
        }
        this.elementList.push({typeName:"stringVal",typeText:"String",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"string"}]});
        this.elementList.push({typeName:"booleanVal",typeText:"Boolean",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"boolean"}]});
        this.loaded = true;
      }
    },500);

  }
  getJsonData(ev:any){
    console.log(ev);
    this.configurationJson = ev;
  }
  createEnvironment(){
    if(this.createEnvironmentForm.valid){
      var createEnvModel:CreateVMEnvironmentModel = Object.assign({},this.createEnvironmentForm.value);
      createEnvModel.data = this.configurationJson;
      this.virtualLabService.createVMEnvironment(createEnvModel).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmhosts.hostCreated"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }
  getProviderTypes(){
    this.vmInstanceService.getProviderList().subscribe({
      next:(response)=>{
        this.providers = response.content;
      },error:(err)=>{

      }
    })
  }

}
