import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-icon-viewer',
  templateUrl: './icon-viewer.component.html',
  styleUrls: ['./icon-viewer.component.css']
})
export class IconViewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
