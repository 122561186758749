import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
declare var window: any;
@Component({
  selector: 'lib-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteInputComponent implements OnInit, AfterViewInit {
  @Input() name: string = "";
  @Input() placeholder: string = "";
  @Input() values: { label: string, value: any }[]
  @Input() selectedValues: any[] = [];
  @Input() showMiniItems:boolean = false;
  @Input() showToast:boolean = false;
  constructor(private toastrService:ToastrService,private translateService:TranslateService) { }
  ngAfterViewInit(): void {
    var el = document.getElementById('acinput_' + this.name);
    this.autocomplete(el, this.values)
  }

  ngOnInit(): void {
    window["autocomplete_" + this.name] = this.selectedValues;
  }
  selectValue(value:string) {
    //var el = document.getElementById('acinput_' + this.name) as HTMLInputElement;
    //var value = el.value;
    if(this.showToast){
      if(this.selectedValues.find(x=>x == value) == undefined){
        this.toastrService.info(this.translateService.instant("autocompleteinput.items.added",{name:this.getLabelByValue(value)}))
      }
    }

    this.selectedValues.push(value);
 
    this.selectedValues = [...new Set(this.selectedValues)];
    
    this.syncWindow();
  }
  removeValue(value: any) {
    if(this.showToast){
      this.toastrService.info(this.translateService.instant("autocompleteinput.items.deleted",{name:this.getLabelByValue(value)}))
    }
    
    this.selectedValues = this.selectedValues.filter(x => x != value);
    this.syncWindow();
  }
  syncWindow() {
    window["autocomplete_" + this.name] = this.selectedValues;
  }
  getLabelByValue(val:string){
    return this.values.find(x=>x.value == val)?.label;
  }
  autocomplete(inp: any, arr: { label: string, value: any }[]) {
    let valueSelectFnc = (val:string) => this.selectValue(val);
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus: any;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e: any): any {
      var a, b, i, val = inp.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false; }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", inp.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      inp.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (arr[i]["label"].substring(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + arr[i]["label"].substring(0, val.length) + "</strong>";
          b.innerHTML += arr[i]['label'].substring(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' data-value='" + arr[i]["value"] + "' value='" + arr[i]["label"] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            //inp.value = this.getElementsByTagName("input")[0].value;
            inp.value = "";
            var chValue = this.getElementsByTagName("input")[0].getAttribute("data-value");
            valueSelectFnc(chValue ?? "")
            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e: any) {
      var x = document.getElementById(inp.id + "autocomplete-list") as any;
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    });
    function addActive(x: any): any {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = (x.length - 1);
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x: any): any {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt?: any): any {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items") as any;
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }

}
