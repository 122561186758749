import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-setup-lab',
  templateUrl: './create-setup-lab.component.html',
  styleUrls: ['./create-setup-lab.component.css']
})
export class CreateSetupLabComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl:string = environment.apiUrl + "api/range/lab/drafts/create"
  constructor() { }

  ngOnInit(): void {
  }

}
