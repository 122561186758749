import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CourseContentService } from '../../../services/education/course-content.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-course-content',
  templateUrl: './delete-course-content.component.html',
  styleUrls: ['./delete-course-content.component.css']
})
export class DeleteCourseContentComponent implements OnInit {

  contentId:string;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private courseContentService:CourseContentService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.contentId = param["contentId"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteCourse(){
    this.courseContentService.deleteCourseContent(this.contentId).subscribe({next:(response)=>{
      this.toastrService.success("İçerik başarıyla silindi");
    },error:(err)=>{
      this.toastrService.error("İçerik silinemedi");
    }})
  }

}
