<div class="dashboard-container formMain form-maroon-border form-default-border" >
    <h5>{{'setupVmInstance.dashboard' | translate}}</h5>
    <div *ngIf="messageDismiss == false" class="alert alert-info alert-dismissible fade show" role="alert" style="max-width: 700px;">
        <strong>Bilgi</strong> Bu sayfada vmleri yönetebilirsiniz.
        <button type="button" class="btn-close" (click)="dismiss()" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    <div class="toolbar">
        <div class="toolbar-createNew toolbar-item" (click)="createNewVm()">
            <i style="color: green;" class="bi bi-plus-circle-fill toolbar-icon"></i><font class="toolbar-text"> {{'setupVmInstance.createNewVm' | translate}}</font>
        </div>
        <div class="toolbar-createNew toolbar-item" (click)="deleteVm()">
            <i style="color: red;" class="bi bi-dash-circle-fill toolbar-icon" ></i><font class="toolbar-text"> {{'setupVmInstance.deleteSelectedVm' | translate}}</font>
        </div>
    </div>
    <div class="vm-area">
        <div class="vm-list">
            <div class="vm-list-item" [id]="'vmItem_'+vm.id" [attr.vmId]="vm.id" #el (click)="vmItemClick(el)" *ngFor="let vm of vmList">
                <img class="os-type-logo" *ngIf="vm.osType != ''" [src]="getVmIcon(vm.osType)">
                <i class="bi bi-display displayIcon" *ngIf="vm.osType == ''"></i>
                <br/>
                <b class="os-name">{{vm.name}}</b><br/>
                <b class="os-type">{{vm.osType}}</b><br/>   
                <b class="os-status">{{vm.status}}</b>
            </div>
        </div>
        <div class="vm-specs">
             <h5 style="text-align: center;"><i class="bi bi-info-circle-fill"></i> {{'VM Summary' | translate}}</h5>
             <div style="text-align: center; margin-top: 15px;"><font style="font-size: 13px;" *ngIf="selectedItem == '' || selectedItem == null">Detaylarını görüntülemek için bir VM seçin</font></div>
             
             <div *ngIf="summaryLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div *ngIf="summaryLoading == false && (selectedItem != '' && selectedItem != null)">
                <div class="spec-item">
                    <b class="spec-field">{{'vmSummary.vmName' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.name}}</font>
                </div>
                <div class="spec-item">
                    <b class="spec-field">{{'vmSummary.osType' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.osType}}</font>
                </div>
                <div class="spec-item">
                    <b class="spec-field">{{'vmSummary.osDetails' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.osInfo}}</font>
                </div>
                <div class="spec-item">
                    <b class="spec-field">{{'vmSummary.arch' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.arch}}</font>
                </div>
                <b>{{'vmSummary.resourseSpecs' | translate}}</b>
                <div class="spec-item">
                    <b class="spec-field">{{'vmSummary.processorInfo' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.cpuCountHint}}</font>
                </div>
                <div class="spec-item" *ngIf="setupInstanceSummary.memoryHint != null">
                    <b class="spec-field">{{'vmSummary.memoryInfo' | translate}} : </b><font class="spec-value">{{setupInstanceSummary.memoryHint}}</font>
                    
                </div>
                <b *ngIf="setupInstanceSummary.disks != null && setupInstanceSummary.disks.length > 0">{{'vmSummary.diskConfiguration' | translate}}</b>
                <div style="overflow: auto;" class="spec-item" *ngIf="setupInstanceSummary.disks != null && setupInstanceSummary.disks.length > 0" >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{'vmSummary.diskId' | translate }}</th>
                                <th>{{'vmSummary.diskName' | translate }} </th>
                                <th>{{'vmSummary.format' | translate }}</th>
                                <th>{{'vmSummary.capacity' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let disk of setupInstanceSummary.disks">
                                <td>{{disk.diskId}}</td>
                                <td>{{disk.name}}</td>
                                <td>{{disk.format}}</td>
                                <td>{{disk.capacity}}GB</td>
                            </tr>
                        </tbody>

                    </table>
                    
                </div>

                <b *ngIf="setupInstanceSummary.removableMedia != null &&  setupInstanceSummary.removableMedia.length > 0">{{'vmSpecs.removeableMediaList' | translate}}</b>
                <div style="overflow: auto;" *ngIf="setupInstanceSummary.removableMedia != null && setupInstanceSummary.removableMedia.length > 0" class="spec-item" >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{'vmSpecs.removeableMediaId' | translate }}</th>
                                <th>{{'vmSpecs.removeableMediaName' | translate }}</th>
                                <th>{{'vmSpecs.capacity' | translate }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rm of setupInstanceSummary.removableMedia">
                                <td>{{rm.mediaId}}</td>
                                <td>{{rm.name}}</td>
                                <td>{{rm.capacityInGigs}}GB</td>
                            </tr>
                        </tbody>

                    </table>
                    
                </div>


                <b *ngIf="setupInstanceSummary.nics != null && setupInstanceSummary.nics.length > 0">{{'vmSpecs.networkConfiguration' | translate}}</b>
                <div style="overflow: auto;" class="spec-item" *ngIf="setupInstanceSummary.nics != null && setupInstanceSummary.nics.length > 0" >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{'vmSpecs.nicId' | translate }}</th>
                                <th>{{'vmSpecs.nicName' | translate }} </th>
                                <th>{{'vmSpecs.nicType' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let nic of setupInstanceSummary.nics">
                                <td>{{nic.nicId}}</td>
                                <td>{{nic.name}}</td>
                                <td>{{nic.deviceType}}</td>
                            </tr>
                        </tbody>

                    </table>
                    
                </div>
              </div>
              <div class="vm-actions" *ngIf="setupInstanceSummary != null && setupInstanceSummary.id != null && selectedItem != null && selectedItem != ''">
                <button class="btn btn-secondary btn-sm" [routerLink]="'/range/vm/setupinstance/'+setupInstanceSummary.id+'/manage'"><i class="bi bi-wrench"></i> {{'btn.manageVm' | translate}}</button>
                &nbsp;<button class="btn btn-warning btn-sm" (click)="openClonePage()"><i class="bi bi-copy"></i> {{'btn.cloneVm' | translate}}</button>
                &nbsp;<button class="btn btn-success btn-sm" (click)="startVm()"><i class="bi bi-play-fill"></i> {{'btn.startVm' | translate}}</button>
                &nbsp;<button class="btn btn-info btn-sm" (click)="restartVm()"><i class="bi bi-arrow-clockwise"></i> {{'btn.restartVm' | translate}}</button>
                &nbsp;<button class="btn btn-danger btn-sm" (click)="shutdownVm()"><i class="bi bi-stop-fill"></i> {{'btn.shutdownVm' | translate}}</button>
                &nbsp;<button class="btn btn-danger btn-sm" (click)="openPublishPage()"><i class="bi bi-share"></i> {{'btn.publishVm' | translate}}</button>
              
                </div>
            </div>
    </div>
</div>
