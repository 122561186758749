import { Component, Input, OnInit } from '@angular/core';
import { CmsImageService } from '../../services/cmsImages/cms-image.service';
import { CmsImageSizes } from '../../models/Enums/CmsImageSizes';

@Component({
  selector: 'lib-cms-image-viewer',
  templateUrl: './cms-image-viewer.component.html',
  styleUrls: ['./cms-image-viewer.component.css']
})
export class CmsImageViewerComponent implements OnInit {
  @Input() imageId:string
  @Input() size:CmsImageSizes | undefined = undefined;
  imageUrl:string;
  constructor(private cmsImageService:CmsImageService) { }

  ngOnInit(): void {
    this.imageUrl = this.cmsImageService.getUrl(this.imageId,this.size);
  }

}
