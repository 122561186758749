import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { HostDashboardResponseModel } from '../../models/DashboardModels/hostDashboardResponseModel';

@Injectable({
  providedIn: 'root'
})
export class HostDashboardService {

  constructor(private httpClient:HttpClient) { }
  getDashboard(id:string){
    var apiUrl = environment.apiUrl;
    //return this.httpClient.get<SingleResponseModel<HostDashboardResponseModel>>(`${apiUrl}api/range/hosts/${id}/dashboard`);
    return this.httpClient.get<SingleResponseModel<HostDashboardResponseModel>>(`/assets/fakeJson/hostDashboard.json`);
  }
  getChartData(){
    return this.httpClient.get<SingleResponseModel<HostDashboardResponseModel>>(`/assets/fakeJson/chart.json`);
  }
}
