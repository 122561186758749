<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.addSection' | translate}}</font>
    </div>
    <div class="mb-3">
        <label for="sectionName" class="form-label mainLabel">{{'courseManagement.sectionName' | translate}}*</label>
        <input type="text" class="form-control" id="sectionName" name="sectionName" placeholder="">
    </div>

    <div class="mb-3">
        <label for="sectionDescription" class="form-label mainLabel">{{'courseManagement.sectionDescription' |
            translate}}</label>
        <input type="text" class="form-control" id="sectionDescription" name="sectionDescription" placeholder="">
    </div>

    <div class="mb-3">
        <label for="preIcon" class="form-label mainLabel">{{'coureManagement.preIcon' | translate}}</label>
        <input type="text" class="form-control" id="preIcon" name="preIcon" placeholder="">
    </div>

    <div class="mb-3">
        <label for="postIcon" class="form-label mainLabel">{{'courseManagement.postIcon' | translate}}</label>
        <input type="text" class="form-control" id="postIcon" name="postIcon" placeholder="">
    </div>
    <div style="text-align: right;">
        <button class="btn  btn-sm btn-primary" (click)="addSection()">{{'btn.add' | translate}}</button>
    </div>


</div>