import { Component, OnInit } from '@angular/core';
import { UserManagerService } from '../../../services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  constructor(private userManagementService: UserManagerService, private activatedRoute: ActivatedRoute) { }
  customFormData: CustomForm;
  ngOnInit(): void {

    this.userManagementService.getCreateUserForm().subscribe({
      next: (response) => {
        this.customFormData = response.content;
      }
    })

  }

}
