<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.addSection' | translate}}</font>
    </div>


    <div class="mb-3">
        <label class="mainLabel">Ana Bölüm</label>
        <select class="form-select" id="parentId">
            <option [value]=null>Bu bölümü ana bölüm olarak ayarla</option>
            <option *ngFor="let section of sectionsList" [value]="section.value">{{section.label}}</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="sectionName" class="form-label mainLabel">{{'courseManagement.subsectionName' | translate}}*</label>
        <input type="text" class="form-control" id="sectionName" name="sectionName" placeholder="">
    </div>
    <div class="mb-3">
        <label for="sectionDescription" class="form-label mainLabel">{{'courseManagement.subsectionDescription' |
            translate}}</label>
        <input type="text" class="form-control" id="sectionDescription" name="sectionDescription" placeholder="">
    </div>

    <div class="mb-3">
        <label for="preIcon" class="form-label mainLabel">{{'coureManagement.preIcon' | translate}}</label>
        <select id="preIcon" class="form-select">
            <option [value]="0">Section</option>
            <option [value]="1">Page</option>
            <option [value]="2">File</option>
            <option [value]="3">Video</option>
            <option [value]="4">Image</option>
            <option [value]="5">Audio</option>
            <option [value]="6">SlideShow</option>
            <option [value]="7">Assessment</option>
            <option [value]="8">Survey</option>
        </select>
    </div>
    <div class="mb-3">
        <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="0" /> None</label><br />
        <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="1" />
            Milestone</label><br />
        <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="2" />
            Optional</label><br />
    </div>
    <div class="mb-3">
        <label class="form-label mainLabel">Sıralama Değeri</label>
        <input class="form-control" type="number" id="orderValue" min="0" />
    </div>
    <div class="mb-3">
        <label for="preIcon" class="form-label mainLabel">Durum</label>
        <select id="status" class="form-select">
            <option [value]="0">Draft</option>
            <option [value]="1">Published</option>
            <option [value]="2">Editing</option>
        </select>
    </div>
    <div style="text-align: right;">
        <button [disabled]="loading" class="btn  btn-sm btn-primary" (click)="addSection()">{{'btn.add' | translate}}
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span></button>
    </div>
</div>