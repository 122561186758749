<div *ngIf="hostProviderInfo" class="vmInstanceDetails">
    <div style="display: flex;">
        <div style="flex:1;">
            <h5>Host Provider Yönetim</h5>
        </div>

    </div>

    <hr style="margin-bottom: 8px; margin-top: 4px;"/>
    <div class="mb-3">
        <b class="title">Name : </b><font class="val">{{hostProviderInfo.name}}</font>
    </div>
    <div class="mb-3">
        <b class="title">Service Class Name : </b><font class="val">{{hostProviderInfo.serviceClassName}}</font>
    </div>
    <div class="mb-3">
        <b class="title">Enabled  : </b><font class="val">{{hostProviderInfo.enabled}}</font>
    </div>
    <div class="mb-3">
        <b class="title">Description: </b><font class="val">{{hostProviderInfo.description}}</font>
    </div>
    <div class="mb-3">
        <b class="title">Default Configuration</b><pre class="val">{{hostProviderInfo.defaultConfiguration}}</pre>
    </div>

</div>
