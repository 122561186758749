import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserManagerService } from '../../../services/user-manager.service';
import { UserRoleResponse } from '../../../models/userRoleResponse';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
declare var window:any;
@Component({
  selector: 'app-create-multiple-user',
  templateUrl: './create-multiple-user.component.html',
  styleUrls: ['./create-multiple-user.component.css']
})
export class CreateMultipleUserComponent implements OnInit {
  formData:FormData = new FormData();
  csv:boolean = false;
  xlsx:boolean = false;
  result:any;
  realmRoles:ListItemModel[];
  loading:boolean;
  apiUrl:string = environment.apiUrl;
  constructor(private toastrService:ToastrService,private translateService:TranslateService,private userManagerService:UserManagerService) { }

  ngOnInit(): void {
    this.getRoles();
  }
  changeFile(ev:any){
    var fileEl = ev.target as HTMLInputElement;
    if(fileEl.files != null && fileEl.files[0] != null){
      var uplFile = fileEl.files[0];
      var filename = uplFile.name;
      let last_dot = filename.lastIndexOf('.')
      let ext = filename.slice(last_dot + 1)
      let name = filename.slice(0, last_dot)
      if(ext.toLocaleLowerCase() == "csv"){
        this.csv = true;
        this.xlsx = false;
      }else if(ext.toLocaleLowerCase() == "xlsx"){
        this.xlsx = true;
        this.csv = false;
      }else{
        this.toastrService.error(this.translateService.instant("userManagement.onlyXlsxAndCsvExtensionsAllowed"));
        throw "Only XLSX and Csv Extensions Allowed";
      }
      this.formData.set("file",uplFile);
    }

  }
  getRoles(){
    this.userManagerService.getUserRealmRoles().subscribe({
      next:(response)=>{
        this.realmRoles = response.content.map((val,index,arr)=>{
          return {label:val.name,value:val.id}
        });
      }
    })
  }
  submit(){
    this.loading = true;
    if(this.csv == false && this.xlsx == false){
      this.toastrService.error("userManagement.pleaseSelectAFile");
      return;
    }
    var roles = window["autocomplete_realmRoles"] as string[];
    console.log(roles)
    var setPasswordVal = (document.getElementById("setPassword") as HTMLInputElement).value;
    var exportVal = (document.getElementById("export") as HTMLInputElement).checked;
    var ignoreFirstRowVal = (document.getElementById("ignoreFirstRow") as HTMLInputElement).checked;
    var cultureCodeVal = (document.getElementById("cultureCode") as HTMLInputElement).value;
    this.formData.set("setPassword",setPasswordVal);
    this.formData.set("cultureCode",cultureCodeVal);
    this.formData.set("ignoreFirstRow",ignoreFirstRowVal == true ? "true" : "false");
    this.formData.set("export",exportVal == true ? "true" : "false");
    this.formData.delete("roles");
    roles.forEach(role=>{
      this.formData.append("roles",role);
    })
    this.userManagerService.createMultipleUsers(this.formData,this.csv,this.xlsx,exportVal).subscribe({
      next:(httpRes)=>{
        if(httpRes.ok){
          this.toastrService.success("userManagement.requestCompleted")
          this.loading = false;
        }else{
          this.loading = false;
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
        var contentType = httpRes.headers.get("Content-Type");
        if(contentType != "application/json" && exportVal == true){
          //download file
          var blob = httpRes.body as Blob;
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style","display:none;");
          a.href = url;
          a.download = "users.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }else{
          this.result = (httpRes.body as any).content;

        }
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })

  }

}
