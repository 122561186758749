<lib-custom-form [formBottomElement]="formBottomInputs" [formId]="'featureType'" [getFormInput]="requestForm" (GetFormGroup)="getFormGroup($event)" (formResponseOut)="getResponse($event)"></lib-custom-form>
<div id="formInputs">
<button class="btn btn-warning" (click)="createNewConfiguration()" style="margin-right: 5px;">{{'addFeatureType.addNewValue' | translate}}</button>
<button class="btn btn-warning" (click)="openJsonEditor()">{{'addFeatureType.jsonEditor'}}</button>
<br/><br/>
<div class="configurations" id="configurations">

</div>
<br/>
<button (click)="saveJsonValue()" class="btn btn-success">{{'addFeatureType.saveValues'}}</button>
</div>