import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { ApiUrlService } from '../api/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class SetupHostService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getSetupHostOptions(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>(this.apiUrlService.getApiUrl()+"api/range/setuphosts/options");
  }
}
