import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-add-seat-access',
  templateUrl: './add-seat-access.component.html',
  styleUrls: ['./add-seat-access.component.css']
})
export class AddSeatAccessComponent implements OnInit {
  type:string;
  seatId:string;
  baseUrl = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.type = param["type"];
        this.seatId = param["seatId"];
        this.formUrl = this.baseUrl + `api/lms/courses/seats/${this.seatId}/access/${this.type}`
      }
    })
  }

}
