import { Injectable } from '@angular/core';

import { ApiUrlService } from '../api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { LocalizedStringResponse } from '../../models/LocalizedString/localizedStringResponse';
import { DeleteLocalizedStringRequest } from '../../models/LocalizedString/deleteLocalizedStringRequest';

@Injectable({
  providedIn: 'root'
})
export class LocalizedStringService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  createLocalizedString(req:any){
    var reqUrl = this.apiUrlService.getApiUrl()+"api/cms/admin/localization/localizedstring/create"
    return this.httpClient.post<ResponseModel>(reqUrl,req);
  }
  updateLocalizedString(id:number,req:any){
    var reqUrl = this.apiUrlService.getApiUrl()+`api/cms/admin/localization/localizedstring/${id}/update`
    return this.httpClient.post<ResponseModel>(reqUrl,req);
  }
  getLocalizedStringDetails(id:number){
    var reqUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/localization/localizedstring/${id}`;
    return this.httpClient.get<SingleResponseModel<LocalizedStringResponse>>(reqUrl);
  }
  deleteLocalizedString(id:number,req:DeleteLocalizedStringRequest){
    var reqUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/localization/localizedstring/${id}/delete`;
    return this.httpClient.delete<ResponseModel>(reqUrl);
  }
}
