import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';

@Component({
  selector: 'lib-delete-category-tree',
  templateUrl: './delete-category-tree.component.html',
  styleUrls: ['./delete-category-tree.component.css']
})
export class DeleteCategoryTreeComponent implements OnInit {

  treeId:number;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.treeId = parseInt(param["treeId"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteCategoryTree(){
    this.educationService.deleteCategoryTree(this.treeId).subscribe({next:(response)=>{
      this.toastrService.success("Kategori ağacı başarıyla silindi");
    },error:(err)=>{
      this.toastrService.error("Kategori ağacı silinemedi");
    }})
  }

}
