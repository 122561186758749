<div style="display:none;" class="hiddenInput">
    <div #modalInput class="modalInput">
        <div class="mb-3 formMain form-default-border form-maroon-border">
            <h4 *ngIf="selectedKeyframeType == 0" style="border-bottom: 1px solid black;">{{'videoTimeline.sectionName' | translate}}</h4>
            <h4 *ngIf="selectedKeyframeType == 1 || selectedKeyframeType == 2" style="border-bottom: 1px solid black;">{{'videoTimeline.contentName' | translate}}</h4>

            <input id="inputTextEl" (change)="inputChangeFnc($event)" type="text" class="form-control" />
            <font *ngIf="selectedKeyframeType == 1 || selectedKeyframeType == 2" class="addContentDescription">
                Eklenecek içeriğe geçici bir ad tanımlayabilirsiniz.<br/>
                İçerik tanımlamaları sonraki sayfada yapılacaktır.
            </font>
            <br/>
            <button (click)="acceptInputFnc()" class="btn btn-sm btn-primary modal-close-btn">Onayla</button>
        </div>
    </div>

</div>

<!--<div class="outline" >
  <div class="outline-header" id="outline-header"></div>
  <div class="outline-scroll-container" id="outline-scroll-container" (wheel)="outlineMouseWheel($event)">
    <div class="outline-items" id="outline-container"></div>
  </div>
</div>-->
<div class="formMain form-maroon-border form-default-border" *ngIf="editor == false">
    <h4 style="border-bottom: 1px solid black;">{{'videoTimeline.videoContentAndSectionEditor' | translate}}</h4>
<div class="video" style="text-align: center; ">
    <video id="activeVideo" style="max-width: 70%; height: auto; width: max-content;" (pause)="onPauseClick()" (loadeddata)="videoLoaded()"
        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"></video>
</div>
<div class="selectedKeyframeInfo" *ngIf="selectedKeyframeInfo" >
    <div class="selectedKeyframeInfoTitle"> <b>{{'videoTimeline.lastSelectedKeyframeInfo' | translate}}</b></div>
    <div class="selectedKeyframeInfoText">
        <div >
           {{selectedKeyframeInfo.group}} : {{selectedKeyframeInfo.begin}} - {{selectedKeyframeInfo.end}}
        </div>
    </div>
</div>
<div class="action-button-container">
    <!--<div class="action-button">
        <button class="btn btn-sm btn-secondary" (click)="addRow()">Add Content</button>
    </div>-->
    <div class="action-button">
        <button class="btn btn-sm btn-secondary" (click)="addRowWithSingleKeyframe()">Add Question</button>
    </div>
    <div class="action-button ms-3">
        <button class="btn btn-sm btn-secondary" (click)="addSection()">Add Section</button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="removeKeyframe()">Remove Selected Keyframe</button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="onPlayClick()">Play</button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="onPauseClick()">Pause</button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="getResult()">Get Keyframes Json</button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="zoomIn()"><i class="bi bi-zoom-in"></i></button>
    </div>
    <div class="ms-3 action-button">
        <button class="btn btn-sm btn-secondary" (click)="zoomOut()"><i class="bi bi-zoom-out"></i></button>
    </div>
</div>
<div id="timeline" style="height: 400px; width:100%">

</div>
<pre style="white-space: pre-line;" id="result">

</pre>
<button class="btn btn-sm btn-warning" (click)="applyContents()" style="float: right;">{{'btn.continue' | translate}}</button>
<br/><br/>
</div>

<div class="formMain form-maroon-border form-default-border" *ngIf="editor == true">
    <h4 style="border-bottom: 1px solid black;">{{'videoTimeline.videoContentAndSectionEditor' | translate}}</h4>
    <div class="mb-3">
        <lib-video-timeline-editor [videoTimeline]="videoTimeline"></lib-video-timeline-editor>    
    </div>
</div>