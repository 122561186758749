import { Component, OnInit } from '@angular/core';


declare var global:any;
declare var $:any;
declare var window:any;
declare var jQuery:any;
@Component({
  selector: 'app-layout-editor',
  templateUrl: './layout-editor.component.html',
  styleUrls: ['./layout-editor.component.css','../../../assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css','../../../assets/usortaEditor/src/lib/lms-layout-editor/css/certificate-editor.css']
})
export class LayoutEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.initLayoutEditor($('.layout-editor'), {});
  }

}
