<lib-custom-form [formBottomElement]="editorEl" (GetSendingObjects)="getSubmitData($event)"  (FormInputChanged)="formInputChanged($event)" [getFormInput]="formInput" (GetFormGroup)="initializeFormData($event)"  [externalFormData]="formData" *ngIf="formData && editorEl" ></lib-custom-form>
<div class="valueEditor mb-3">
    <textarea *ngIf="inputFormat == 0 || inputFormat == 2" class="editorTextarea form-control" style="width: 100%;"></textarea>
    <div class="editorMonaco" *ngIf="inputFormat == 1 || inputFormat == 3 || inputFormat == 4" style="height: 500px;">

    </div>
    <div *ngIf="inputFormat == 5" class="layout-editor-container">
        <textarea  class="editorLayout" id="editorLayout"
        data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
        autocomplete="off" data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;">
        </textarea>
    </div>

</div>