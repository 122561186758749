<div class="form-maroon-border form-default-border formMain" *ngIf="translationDetails">
    <div class="mb-3">
        <b>{{'courseTranslation.name'}} : </b>
        <font>{{translationDetails.name}} </font>
    </div>
    <div class="mb-3"> <b>{{'courseTranslation.courseName'}} : </b>
        <font>{{translationDetails.courseName}}</font>
    </div>

    <div class="mb-3" *ngIf="translationDetails.teaserImage != null && translationDetails.teaserImage != ''">
        <b>{{'courseTranslation.teaserImage'}}</b><br/>
        <img [src]="getImageUrl(translationDetails.teaserImage)">
    </div>
    <div class="mb-3"><b>{{'courseTranslation.teaserText'}} : </b>
        <font>{{translationDetails.teaserText}}</font>
    </div>

    <div class="mb-3" *ngIf="translationDetails.image != null && translationDetails.image != ''">
        <b>{{'courseTranslation.image'}}</b><br/>
        <img [src]="getImageUrl(translationDetails.image)">
    </div>
    <div class="mb-3"><b>{{'courseTranslation.description'}} : </b><lib-preview-text [allowHtml]="true"
            [text]="translationDetails.description"></lib-preview-text>
    </div>
    <div class="mb-3">
        <div class="mb-3"> <b>{{'courseTranslation.primaryVersionId'}} : </b>
            <font>{{translationDetails.primaryVersionId}}</font>
        </div>
    </div>
    <div class="mb-3">
        <div class="mb-3"> <b>{{'courseTranslation.initialVersionId'}} : </b>
            <font>{{translationDetails.initialVersionId}}</font>
        </div>
    </div>
</div>