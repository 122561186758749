import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-delete-feature-type',
  templateUrl: './delete-feature-type.component.html',
  styleUrls: ['./delete-feature-type.component.css']
})
export class DeleteFeatureTypeComponent implements OnInit {
  checkedUnderstandCheck:boolean = false;
  featureTypeId:string | null;
  success:boolean = false;
  constructor(private translateService:TranslateService ,private activatedRoute:ActivatedRoute,private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.featureTypeId = param['featureTypeId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  
  deleteFeatureType(){
    if(this.featureTypeId != null){
      this.sessionTemplateService.deleteFeatureType(this.featureTypeId).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
            this.success = true;
          }else{
            this.toastrService.error(this.translateService.instant("global.deletedError"));
          }
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }

  }

}
