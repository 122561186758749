<div class="vmDetails">
    <div *ngIf="!vmModel" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div *ngIf="vmModel" class="details">
        <h5>{{'lab.virtualMachineDetails' | translate}}</h5>
        <div class="detailItem">
            <b class="detailTitle">{{'lab.virtualMachine.name' | translate}} : </b><font class="detailValue">{{vmModel.name}}</font>
        </div>
        <div class="detailItem">
            <b class="detailTitle">{{'lab.virtualMachine.processorCoreCount' | translate}} : </b><font class="detailValue">{{vmModel.cpuCount}}</font>
        </div>
        <div class="detailItem">
            <b class="detailTitle">{{'lab.virtualMachine.operatingEnvironment' | translate}} : </b><font class="detailValue" *ngIf="environments">{{getEnvironmentString(vmModel.environment)}}</font>
        </div>
        <div class="detailItem">
            <b class="detailTitle">{{'lab.virtualMachine.ramInfo' | translate}} : </b><font class="detailValue" *ngIf="ramList">{{getRamString(vmModel.ram)}}</font>
        </div>
        <div class="detailItem">
            <b class="detailTitle">{{'lab.virtualMachine.image' | translate}} : </b><font class="detailValue" *ngIf="imageList">{{getImageString(vmModel.image)}}</font>
        </div>
        <div class="detailItem">
            <b>{{'lab.virtualMachine.diskList' | translate}}</b>
            <div class="diskItem" *ngFor="let disk of diskList">
                <i class="bi bi-hdd-fill diskIcon"></i>&nbsp; <font class="diskName">{{disk.diskName}} </font> | <font class="diskSize"> {{disk.diskSize}} GB</font>
            </div>
        </div>
        <div class="detailItem">
            <b>{{'lab.virtualMachine.networkCards' | translate}}</b>
            <div class="nicItem" *ngFor="let nic of nicList">
                <i class="bi bi-hdd-network-fill"></i>&nbsp; <font class="diskName">{{nic.nicName}} </font><font class="diskSize"> {{nic.settings}}</font>
            </div>
        </div>
    </div>
</div>

<div class="customizationMain">
    <h5>{{'lab.virtualMachine.customizations' | translate}}</h5>
    <hr>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openSetRootPasswordModal()">{{'lab.virtualMachine.setRootPassword' | translate}}</button>
    </div>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openAddNewUserModal()">{{'lab.virtualMachine.createNewUser' | translate}}</button>
    </div>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openChangePasswordModal()">{{'lab.virtualMachine.changeUserPassword' | translate}}</button>
    </div>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openInstallPackagesModal()">{{'lab.virtualMachine.installPackages' | translate}}</button>
    </div>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openCopyFilesModal()">{{'lab.virtualMachine.copyFiles' | translate}}</button>
    </div>
    <div class="customizationItem">
        <button class="btn btn-secondary" (click)="openRunScriptsModal()">{{'lab.virtualMachine.runScripts' | translate}}</button>
    </div>
</div>
<br /><br />
<pre>
    VM tanımını baz alan diğer tanımlar tablosu, kullanıldığı labların tablosu eklenecek
</pre>