import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { SectionDetailsFileModel, SectionDetailsModel } from '../../../models/Education/sectionDetailsModel';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { EducationOutlineModel } from '../../../models/Education/educationOutline';
import { SectionIconServiceService } from '../../../services/education/section-icon-service.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { OutlineStatusEnum } from '../../../models/Education/Enum/outlineStatus';
import { OutlineOptionsFlags } from '../../../models/Education/Enum/outlineOptionsFlags';

@Component({
  selector: 'lib-education-section-details',
  templateUrl: './education-section-details.component.html',
  styleUrls: ['./education-section-details.component.css']
})
export class EducationSectionDetailsComponent implements OnInit {
  courseId: string;
  apiUrl:string;
  sectionId: string;
  sectionDetails: EducationOutlineModel;
  outlineOptionsMap:{key:string,value:number}[];
  constructor(private sectionIconService:SectionIconServiceService,private globalOkCancelService:GlobalOkCancelEventService,private activatedRoute: ActivatedRoute,private router:Router ,private globalModalService:GlobalModalService, private educationService: EducationService, private toastrService: ToastrService) { }
  
  ngOnInit(): void {
     this.outlineOptionsMap = Object.entries(OutlineOptionsFlags).map((val,index,arr)=>{
      return {key:arr[index][0],value:arr[index][1]}
     })
    this.apiUrl = environment.apiUrl;
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.courseId = param["courseId"];
        this.sectionId = param["sectionId"];
        this.getSectionInfo();
      }
    })
  }
  optionControl(num:number){
    if(this.sectionDetails.options == 0 && num == 0){
      return true;
    }
    if(this.sectionDetails.options & num){
      return true;
    }
    return false;
  }
  getIconClasses(iconName:string){
    return this.sectionIconService.getIconClasses(iconName);
  }
  getSectionInfo() {
    this.educationService.getSectionDetails(this.sectionId).subscribe({
      next: (response) => {
        this.sectionDetails = response.content;
      }, error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  downloadFile(file: SectionDetailsFileModel) {
    var aButton = document.createElement('a');
    aButton.href = file.url;
    aButton.target = '_blank';
    aButton.download = file.filename;
    aButton.click();
  }
  updateSection(){

      var modalCreate:ModalCreateModel = {
        title: "Mevcut bölümü düzenle",
        type: ModalTypes.LINK,
        data: "modal/lms/courses/section/"+this.sectionId+"/update",
        helperModals: []
      }
      this.globalModalService.showModal(modalCreate)
  }
  goToVersion(){
    this.router.navigate([`/lms/courses/version/${this.sectionDetails.courseVersionId}/details`])
  }
  getStatus(status:number){
    return Object.entries(OutlineStatusEnum).find(x=>x[1] == status)?.[0];
  }
  deleteSection(){
    this.globalOkCancelService.showOkCancelWindow("Bölümü Sil",`${this.sectionDetails.name} adlı bölüm silinecek. Onaylıyor musunuz ?`).then((r)=>{
      if(r == true){
        this.educationService.deleteEducationSection(this.sectionId).subscribe({
          next:(response)=>{
            this.toastrService.success("Bölüm başarıyla silindii");
          },error:(err)=>{
            this.toastrService.error("Bölüm silinemedi");
          }
        });
      }
    })
  }
  addNewContent(){
    this.router.navigate([`lms/courses/section/${this.sectionId}/addContent`])
  }

  routeAddContent(){
    this.router.navigate([`lms/courses/section/${this.sectionId}/addContent`])
  }

}
