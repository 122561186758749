import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-lab-workbench',
  templateUrl: './lab-workbench.component.html',
  styleUrls: ['./lab-workbench.component.css']
})
export class LabWorkbenchComponent implements OnInit {
  isDraft:boolean = false;
  isShared:boolean = false;
  isMyLab:boolean = false;
  id:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var draftId = param["draftId"];
        var sharedId = param["sharedId"];
        var mylabId = param["mylabId"];
        if(draftId != null){
          this.isDraft = true;
          this.id = draftId;
        }else if(sharedId != null){
          this.isShared = true;
          this.id = sharedId;
        }else if(mylabId != null){
          this.isMyLab = true;
          this.id = mylabId;
        }
      }
    })
  }

}
