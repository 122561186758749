import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormToJsonElementTypesModel } from 'projects/usorta-library/src/lib/models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { FeatureItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureItem';
import { SessionTemplateItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/sessionTemplateItem';

@Component({
  selector: 'app-add-session-template',
  templateUrl: './add-session-template.component.html',
  styleUrls: ['./add-session-template.component.css']
})
export class AddSessionTemplateComponent implements OnInit {
  elementList:DynamicFormToJsonElementTypesModel[] = [];
  requestForm:boolean[] = [false];
  currentJson:string = "[]";
  featureItems:FeatureItem[] = [];
  templateItems:SessionTemplateItem[] = [];
  formResponse:any = null;
  formBottomInputs:HTMLElement;
  fieldNameText:string  = "";
  fieldValueText:string = "";
  fieldRequiredText:string = "";
  fieldTypeText:string = "";
  loaded:boolean = false;
  constructor(private translateService:TranslateService) { }

  ngOnInit() {
    
    this.formBottomInputs = document.getElementById("formBottomInputs") as HTMLElement;
    this.getFieldTranslations();
    
  }
  ngAfterViewInit(){


  }
  getFieldTranslations(){
    this.translateService.get("fields.parameterName").subscribe({
      next:(response)=>{
        console.log(response);
        this.fieldNameText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.parameterValue").subscribe({
      next:(response)=>{
        this.fieldValueText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.required").subscribe({
      next:(response)=>{
        this.fieldRequiredText = response;
        this.fieldsControl();
      }
    })
    this.translateService.get("fields.valueType").subscribe({
      next:(response)=>{
        this.fieldTypeText = response;
        this.fieldsControl();
      }
    })
  }
  fieldsControl(){
    if(this.loaded){
      return;
    }

    setTimeout(()=>{
      if(this.fieldNameText != "" && this.fieldRequiredText != "" && this.fieldTypeText != "" && this.fieldValueText != ""){
        if(this.loaded){
          return;
        }
        this.elementList.push({typeName:"stringVal",typeText:"String",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"string"},{name:"fieldRequired",label:this.fieldRequiredText,valueType:"boolean"},{name:"valueType",label:this.fieldTypeText,valueType:"static",defaultValue:"string"}]});
        this.elementList.push({typeName:"booleanVal",typeText:"Boolean",element:[{name:"fieldName",label:this.fieldNameText,valueType:"string"},{name:"fieldValue",label:this.fieldValueText,valueType:"boolean"},{name:"valueType",label:this.fieldTypeText,valueType:"static",defaultValue:"boolean"}]});
        this.loaded = true;
      }
    },500);

  }
  getDataChanges(event:string){
    this.currentJson = event;
    this.requestForm = [true];
  }
  getFormGroup(event:FormGroup){
    event.get("data")?.setValue(this.currentJson);
    event.get("features")?.setValue(this.featureItems);
    event.get("items")?.setValue(this.templateItems);
  }
  saveFeature(ev:any){
    this.featureItems = this.featureItems.filter(x=>x.typeId != ev.typeId);
    this.featureItems.push(ev);
    this.requestForm = [true];

  }
  getTemplateItemType($ev:SessionTemplateItem){
    this.templateItems.push($ev);
    this.requestForm = [true];
  }
  getResponse($ev:any){
    this.formResponse = true;
  }

}
