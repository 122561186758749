import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../../services/tenant/tenant.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-tenant',
  templateUrl: './delete-tenant.component.html',
  styleUrls: ['./delete-tenant.component.css']
})
export class DeleteTenantComponent implements OnInit {

  tenantId:string;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private tenantService:TenantService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.tenantId = param["id"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteLocalizedString(){
    this.tenantService.deleteTenant(this.tenantId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Tenant successfully deleted");
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
