import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-base-vm-images-table',
  templateUrl: './base-vm-images-table.component.html',
  styleUrls: ['./base-vm-images-table.component.css']
})
export class BaseVmImagesTableComponent implements OnInit {
  tableUrl:string = this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages"
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
  }

}
