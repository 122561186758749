import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { CourseTranslationService } from '../../../services/education/course-translation.service';

@Component({
  selector: 'lib-delete-course-translation',
  templateUrl: './delete-course-translation.component.html',
  styleUrls: ['./delete-course-translation.component.css']
})
export class DeleteCourseTranslationComponent implements OnInit {

  courseId:string;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  translationId:number;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private courseTranslationService:CourseTranslationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseId = param["courseId"];
        this.translationId = parseInt(param["translationId"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteCourseTranslation(){
    this.courseTranslationService.deleteCourseTranslation(this.courseId,this.translationId).subscribe({next:(response)=>{
      this.toastrService.success(this.translateService.instant("courseTranslations.deletedSuccessfully"));
    },error:(err)=>{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }})
  }

}
