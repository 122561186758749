import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { FormSubmitRedirectService } from '../../../services/formSubmitRedirect/form-submit-redirect.service';
import { LocalizedStringService } from '../../../services/localizedString/localized-string.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-update-localized-string-with-key',
  templateUrl: './update-localized-string-with-key.component.html',
  styleUrls: ['./update-localized-string-with-key.component.css']
})
export class UpdateLocalizedStringWithKeyComponent implements OnInit {

  localizedStringId:number = 0;
  formData:any;
  formInput:boolean[] = [false];
  editorEl:any;
  monacoEditor:any;
  inputFormat:number;
  localizedStringFormGroup:FormGroup;
  localizedStringKey:string;
  constructor(private translateService:TranslateService,private formSubmitRedirect:FormSubmitRedirectService,private activatedRoute:ActivatedRoute, private httpClient:HttpClient,private apiUrlService:ApiUrlService,private toastrService:ToastrService,private localizedStringService:LocalizedStringService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe({
      next:(param)=>{
        this.localizedStringKey = param["key"]
      }
    })
    var appId = environment.applicationId;
    var tenantId = environment.tenantId;
    var locale = this.translateService.currentLang;
    var qs = "?";
    if(this.localizedStringKey !== null){
      qs += "key="+this.localizedStringKey;
    }
    if(appId !== null){
      qs += "&appId="+appId;
    }
    if(locale){
      qs += "&locale="+locale;
    }
    if(tenantId){
      qs += "&tenant="+tenantId;
    }
    this.editorEl = document.getElementsByClassName("valueEditor")[0] as HTMLElement;
    this.httpClient.get(this.apiUrlService.getApiUrl()+`api/cms/admin/localization/localizedstring/updatewithkey${qs}`).subscribe({
      next:(response:any)=>{
        this.localizedStringId = response.content.action;
        response.content.action = "#";
        this.formData = response.content;
        setTimeout(()=>{
          this.formInput = [true];
        },250)
      }
    })
  }
  initializeFormData($ev:FormGroup){
    console.log("Form bilgisi")
    console.log($ev);
    this.localizedStringFormGroup = $ev;
    var formatValue = $ev.get("format")?.value;
    var defaultValue = $ev.get("value")?.value;
    this.inputFormat = parseInt(formatValue);
    console.log(this.inputFormat)
    if(this.inputFormat == 0 || this.inputFormat == 2){
      setTimeout(()=>{
        (document.getElementsByClassName("editorTextarea")[0] as HTMLTextAreaElement).value = defaultValue;
      },150)

    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
      this.createMonaco(defaultValue);
    }else if(this.inputFormat == 5){
      this.createLayoutEditor(defaultValue);
    }
  }
  getSubmitData($ev:any){
    $ev.value = this.getValue();
    $ev.format = parseInt($ev.format);
    $ev['id'] = this.localizedStringId;
    $ev.tenantId = $ev.tenantId == "" ? null : $ev.tenantId; 
    this.localizedStringService.updateLocalizedString(this.localizedStringId,$ev).subscribe({
      next:(response)=>{
        console.log($ev);
        this.toastrService.success("Localized string updated");
        this.formSubmitRedirect.showModal("/localization/localizedstring/list",null,"localizedString.updatedSuccessfully","btn.goLocalizedStringList");
        this.translateService.reloadLang(this.translateService.currentLang);
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }
  formInputChanged($ev:any){
    console.log($ev);
    if($ev.name == "format"){
      this.inputFormat = parseInt($ev.value);
    }
    if(this.inputFormat == 0 || this.inputFormat == 2){

    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
     this.createMonaco();
    }else if(this.inputFormat == 5){
     this.createLayoutEditor();
    }
  }
  createMonaco(defaultValue:string = ""){
    setTimeout(()=>{
      var type = "json";
      if(this.inputFormat == 1){
        type = "html";
      }
      if(this.inputFormat == 3){
        type = "json";
      }
      if(this.inputFormat == 4){
        type = "xml"
      }
      if(window['monacoEditor'] != null){
        window['monacoEditor'].getModel().dispose();
      }
      var codeEditor = document.getElementsByClassName("editorMonaco")[0] as HTMLDivElement
      this.monacoEditor = monaco.editor.create(codeEditor, {
        language: type,
        value: defaultValue,
        theme: 'vs',
        autoIndent: true,
        tabSize: 2,
        automaticLayout: true
      });
      window['monacoEditor'] = this.monacoEditor;
    },250)

  }
  createLayoutEditor(defaultValue:string|null =null ){

    setTimeout(()=>{
      if(defaultValue != null){
        var layoutTextarea = document.getElementById("editorLayout") as HTMLTextAreaElement;
        layoutTextarea.innerHTML = defaultValue;
      }
      window.initLayoutEditor($('.editorLayout'), {});
    },500)

  }
  getValue(){
    if(this.inputFormat == 0 || this.inputFormat == 2){
      var doc = document.getElementsByClassName("editorTextarea")[0] as HTMLTextAreaElement;
      return doc.value;
    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
      var val = window['monacoEditor'].getValue();
      return val;
    }else if(this.inputFormat == 5){
      var layoutVal = document.getElementById("editorLayout") as HTMLTextAreaElement;
      return layoutVal.value;

    }
  }

}
