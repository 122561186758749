import { ChangeDetectionStrategy } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { ModalCreateModel } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { AddSessionTemplateFeatureRequestModel } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/addTemplateFeatureRequestModel';
import { AddSessionTemplateItemRequestModel } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/addTemplateItemRequestModel';
import { FeatureItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureItem';
import { FeatureType } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureType';
import { SessionTemplateItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/sessionTemplateItem';
import { SessionTemplateModel } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/sessionTemplateModel';
import { CustomFormService } from 'projects/usorta-library/src/lib/services/customForm/custom-form.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';
declare var monaco: any;
declare var window: any;
@Component({
  selector: 'app-update-session-template',
  templateUrl: './update-session-template.component.html',
  styleUrls: ['./update-session-template.component.css']
})
export class UpdateSessionTemplateComponent implements OnInit {
  templateId: number;
  sessionTemplate: SessionTemplateModel;
  requestFormGroup: boolean[] = [false];
  sessionTemplateUpdateFormData: CustomForm;
  initializedForm: boolean = false;
  sessionTemplateUpdateFormGroup: FormGroup;
  features: ListItemModel[];
  featureNameList: ListItemModel[];
  featureItems: Map<string, ListItemModel[]> = new Map<string, ListItemModel[]>();
  formResponse:boolean = false;
  itemResponse:boolean = false;
  featureResponse:boolean = false;
  formInputBottom:HTMLElement | undefined = undefined;
  @ViewChild("formBottomElement") formBottomElement:ElementRef;
  constructor(private translateService:TranslateService, private activatedRoute: ActivatedRoute, private sessionTemplateService: SessionTemplateService, private toastrService: ToastrService, private customFormService: CustomFormService, private globalModal: GlobalModalService) { }

  ngOnInit(): void {
    this.formInputBottom = document.getElementById("updateTemplateBottom") as HTMLElement;
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.templateId = param["templateId"];
        this.getFormData();
        this.getFeatures();
      }
    })

  }
  setFormResponse($ev:any){
    if($ev.success){
      this.formResponse = true;
    }
  }
  updateFeaturesList($ev:any){
    if($ev.success){
      this.getSessionTemplate();
    }
  }
  updateItemsList($ev:any){
    if($ev.success){
      this.getSessionTemplate();
    }
  }
  getSessionTemplate() {
    this.sessionTemplateService.getSessionTemplate(this.templateId).subscribe({
      next: (response) => {
        this.sessionTemplate = response.content;
        console.log(this.sessionTemplate);
        var arr: string[] = [];
        response.content.items.forEach((item) => {
          arr.push(item.itemType);
        })
        arr = [...new Set(arr)];
        arr.forEach((itemType) => {
          this.getFeatureItems(itemType);
        })
        this.initForm();
      }, error: (err) => {
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getFormData() {
    this.customFormService.externalUrl = "";
    this.customFormService.getForm("updateSessionTemplate").subscribe({
      next: (response) => {
        response.action = response.action.replace("[id]", this.templateId.toString());
        this.sessionTemplateUpdateFormData = response;
        setTimeout(() => {
          this.requestFormGroup = [true];
        }, 250)
      },
      error: (err) => {
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    });
  }
  getFormGroup($ev: FormGroup) {
    if ($ev == undefined) {
      setTimeout(() => {
        this.requestFormGroup = [true];
      }, 250)
    } else {
      this.sessionTemplateUpdateFormGroup = $ev;
      if (this.initializedForm == false) {
        this.getSessionTemplate();
      }

    }
  }
  newString() {
    const stringValue = {
      fieldName: "field Name",
      fieldValue: "field Value",
      fieldRequired: false,
      valueType: "string"
    }
    this.addValueToJsonEditor(stringValue);

  }
  newBoolean() {
    const boolValue = {
      fieldName: "field Name",
      fieldValue: false,
      valueType: "boolean"
    }
    this.addValueToJsonEditor(boolValue);
  }
  formatCode() {
    window['monacoEditor'].trigger('editor', "editor.action.formatDocument");
  }
  addValueToJsonEditor(data: any) {
    var editorJson = window['monacoEditor'].getValue() !== "" ? window['monacoEditor'].getValue() : "[]";
    var parsedEditorJson: any[] = [];
    try {
      parsedEditorJson = JSON.parse(editorJson);
    } catch (error) {
      this.toastrService.error(this.translateService.instant("sessionTemplate.jsonVerifyError"));
      throw "";
    }
    var jsonVal = JSON.parse(JSON.stringify(data));
    parsedEditorJson.push(jsonVal);
    window['monacoEditor'].setValue(JSON.stringify(parsedEditorJson));
    window['monacoEditor'].trigger('editor', "editor.action.formatDocument");
  }
  saveDataJson() {
    var editorJson = window['monacoEditor'].getValue();
    console.log();
    try {
      JSON.parse(editorJson);
      var replaced = editorJson.replace(/(\r\n|\n|\r)/gm, "");
      replaced = JSON.stringify(JSON.parse(replaced));
      this.sessionTemplateUpdateFormGroup.get("data")?.setValue(replaced);
      this.toastrService.success(this.translateService.instant("sessionTemplate.saveTemplateMessage"))
    } catch (error) {
      this.toastrService.error(this.translateService.instant("global.jsonVerifyError"));
    }
  }
  initForm() {
    this.sessionTemplateUpdateFormGroup.get("id")?.setValue(this.sessionTemplate.id);
    this.sessionTemplateUpdateFormGroup.get("name")?.setValue(this.sessionTemplate.name);
    this.sessionTemplateUpdateFormGroup.get("data")?.setValue(this.sessionTemplate.data);
    var jsonEditorEl = document.getElementsByClassName("jsonEditor")[0] as HTMLDivElement;
    var jsondata = this.sessionTemplate.data;
    var monacoEditor: any = null;
    if (typeof monaco !== 'undefined') {
      //codeEditor.outerHTML = '<div class="style-code"></div>';
      setTimeout(function () {
        monacoEditor = monaco.editor.create(jsonEditorEl, {
          language: 'json',
          value: jsondata,
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['monacoEditor'] = monacoEditor;
      }, 50),
        setTimeout(() => {
          monacoEditor.trigger('editor', "editor.action.formatDocument");
          monacoEditor.layout();
          console.log(monacoEditor)
          monacoEditor.onDidChangeModelContent((event: any) => {
            console.log("code changed");
          });
        }, 100)

    }
    this.initializedForm = true;
  }
  getFeatures() {
    this.sessionTemplateService.getFeatureTypesForSelectList().subscribe({
      next: (response) => {
        this.featureNameList = response.content as ListItemModel[];
      }
    })
  }
  getFeatureNameByFeatureId(featureId: string) {
    return this.featureNameList.find(x => x.value == featureId)?.label;
  }
  /* getFeatureTypes(){
     this.sessionTemplateService.getFeatureTypesForSelectList().subscribe({
       next:(response)=>{
         this.featureTypeList = response.content;
         console.log(response.content)
         this.getFeatureItems(response.content[0].value);
       },error:(err)=>{
         this.toastrService.error("Feature Items alınırken bir hata oluştu");
       }
     })
   }*/
  getFeatureItems(featureType: string) {
    this.sessionTemplateService.getSessionTemplateItemSourceId(featureType).subscribe({
      next: (response) => {
        this.featureItems.set(featureType, response.content)
      }, error: () => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  showDeleteTemplateFeatureModal(id: number) {
    var modalData: ModalCreateModel = { type: ModalTypes.LINK, title: "USORTA", data: `modal/session-templates/${this.templateId}/features/${id}/delete`, helperModals: [] }
    this.globalModal.showModal(modalData);
  }
  showDeleteTemplateItemModal(id: number) {
    var modalData: ModalCreateModel = { type: ModalTypes.LINK, title: "USORTA", data: `modal/session-templates/${this.templateId}/items/${id}/delete`, helperModals: [] }
    this.globalModal.showModal(modalData);
  }
  getFeatureSourceIdName(type: string, sourceId: string) {
    if (this.featureItems.has(type)) {
      return this.featureItems.get(type)?.find(x => x.value == sourceId)?.label;
    }
    return "";
  }
  addFeatureFromForm($ev: FeatureItem) {
    var addFeatureReq: AddSessionTemplateFeatureRequestModel = { configuration: $ev.configuration, typeId: $ev.typeId, templateId: this.templateId };
    this.sessionTemplateService.addSessionTemplateFeature(addFeatureReq).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrService.success(this.translateService.instant("sessionManagement.featureAdded"));
          this.updateFeaturesList(response);
        } else {
          this.toastrService.error(this.translateService.instant("sessionManagement.featureAddError"));
        }
      },
      error: () => {
        this.toastrService.error(this.translateService.instant("sessionManagement.featureAddError"));
      }
    })
  }
  addItemFromForm($ev: SessionTemplateItem) {
    var addItemReq: AddSessionTemplateItemRequestModel = {
      templateId: this.templateId,
      data: $ev.data,
      dependsOnFeatures: $ev.dependsOnFeatures ?? "",
      itemSourceId: $ev.itemSourceId,
      itemType: $ev.itemType
    }
    this.sessionTemplateService.addSessionTemplateItem(addItemReq).subscribe({
      next: (response) => {
        if (response.success) {
          this.updateItemsList(response);
          this.toastrService.success(this.translateService.instant("sessionManagement.itemAdded"));
        } else {
          this.toastrService.error(this.translateService.instant("sessionManagement.itemAddedError"));
        }
      },
      error: () => {
        this.toastrService.error(this.translateService.instant("sessionManagement.itemAddedError"));
      }
    })
  }
  showUpdateTemplateFeatureModal(featureId:number){
    var modalData: ModalCreateModel = { type: ModalTypes.LINK, title: "USORTA", data: `modal/session-templates/${this.templateId}/features/${featureId}/update`, helperModals: [] }
    this.globalModal.showModal(modalData);
  }


}
