import { Component, OnInit } from '@angular/core';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ActivatedRoute } from '@angular/router';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { VirtualMachineModel } from '../../../models/VirtualLabModels/virtualMachineModel';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-virtual-machine-details',
  templateUrl: './virtual-machine-details.component.html',
  styleUrls: ['./virtual-machine-details.component.css']
})
export class VirtualMachineDetailsComponent implements OnInit {

  constructor(private translateService:TranslateService,private toastrService:ToastrService,private globalModalService:GlobalModalService,private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService) { }
  vmId:string;
  vmModel:VirtualMachineModel;
  environments:ListItemModel[];
  ramList:ListItemModel[];
  diskList:{diskName:string,diskSize:number}[];
  nicList:{nicName:string,settings:string}[];
  imageList:ListItemModel[];
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
        this.getVm();
        this.getEnvironments();
        this.getRamList();
      }
    })
  }
  getVm(){
    this.virtualLabService.getVirtualMachine(this.vmId).subscribe({
      next:(response)=>{
        this.vmModel = response.content;
        console.log(this.vmModel);
        this.diskList = JSON.parse(this.vmModel.disks);
        this.nicList = JSON.parse(this.vmModel.nicCount);
        this.getImageList(response.content.environment);
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getImageList(val:string){
    this.virtualLabService.getVMImageListItem(this.vmModel.environment).subscribe({
      next:(response)=>{
        this.imageList = response.content;
      }
    })
  }
  getImageString(val:string){
    return this.imageList.find(x=>x.value == val)?.label ?? val;
  }
  getRamString(val:string | undefined){
    return this.ramList.find(x=>x.value==val)?.label ?? val;
  }
  getEnvironmentString(val:string){
    return this.environments.find(x=>x.value==val)?.label ?? val;
  }
  getEnvironments(){
    this.virtualLabService.getEnvironmentListItem().subscribe({
      next:(response)=>{
        this.environments = response.content;
      }
    });
  }
  getRamList(){
    this.virtualLabService.getRamList().subscribe({
      next:(response)=>{
        this.ramList = response.content;
      }
    })
  }
  getList(){
    
  }
  openSetRootPasswordModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/setrootpassword`,helperModals:[],title:"",type:ModalTypes.LINK
    })
  }
  openAddNewUserModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/adduser`,helperModals:[],title:"",type:ModalTypes.LINK
    })
  }
  openChangePasswordModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/changepassword`,helperModals:[],title:"",type:ModalTypes.LINK
    })
  }
  openInstallPackagesModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/installpackages`,helperModals:[],title:"",type:ModalTypes.LINK
    })
  }
  openCopyFilesModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/copyfiles`,helperModals:[],title:"",type:ModalTypes.LINK
    })
  }
  openRunScriptsModal(){
    this.globalModalService.showModal({
      data:`modal/lab/vm/${this.vmId}/customization/runscripts`,helperModals:[],title:"",type:ModalTypes.LINK
    },true)
  }

}
