import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HostDashboardSwitchModel } from '../../../models/DashboardModels/hostDashboardSwitchModel';

import { HostDashboardSwitchEmitterModel } from '../../../models/DashboardModels/hostDashboardSwitchEmitterModel';
declare var bootstrap:any;
function popoverstart(){
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    console.log(popoverTriggerList);
    return new bootstrap.Popover(popoverTriggerEl)

  })
}
@Component({
  selector: 'lib-host-dashboard-switch',
  templateUrl: './host-dashboard-switch.component.html',
  styleUrls: ['./host-dashboard-switch.component.css']
})
export class HostDashboardSwitchComponent implements OnInit,AfterViewInit {
  @Input() hostDashboardSwitch:HostDashboardSwitchModel;
  @ViewChild('switchRef') switchRef:ElementRef<HTMLInputElement>;
  @Output() outSwitch:EventEmitter<HostDashboardSwitchEmitterModel> = new EventEmitter<HostDashboardSwitchEmitterModel>();
  constructor() { }
  ngAfterViewInit(): void {
    console.log("switch ref ---")
    console.log(this.switchRef);
    this.sendSwitchRef();
    if(this.hostDashboardSwitch.value == null){
      this.switchRef.nativeElement.value = "not-defined";
    }

    this.switchRef.nativeElement.onchange = () =>{
      this.switchRef.nativeElement.value = "defined";
    }
  }
  sendSwitchRef(){
    this.outSwitch.emit({key:this.hostDashboardSwitch.key,switchCheck:this.switchRef.nativeElement})
  }

  ngOnInit(): void {
    setTimeout(()=>{
      popoverstart();
    },500)
  }

}
