import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChunkUploaderService } from '../../../services/chunkUploader/chunk-uploader.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-upload-media-content',
  templateUrl: './upload-media-content.component.html',
  styleUrls: ['./upload-media-content.component.css']
})
export class UploadMediaContentComponent implements OnInit {
  mediaId:string;
  uploading:boolean = false;
  mediaFile:any;
  totalChunks:number = 0;
  uploadedChunks:number = 0;
  percentage:string;
  constructor(private activatedRoute:ActivatedRoute,private chunkUploaderService:ChunkUploaderService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(params)=>{
        this.mediaId = params["id"];
      }
    })
  }
  setFile($ev:any){
    this.mediaFile = $ev.target.files[0];
  }
  uploadMedia(){
    //var fileElement = document.getElementById("formFile") as HTMLInputElement;
    //var formData = new FormData();
    if(this.mediaFile){
      //formData.set("file",this.baseImageFile);
      this.uploading = true;
      /*this.virtualLabService.uploadBaseVmImage(this.baseImageId,formData).subscribe({
        next:(response)=>{
          this.uploading = false;
          this.toastrService.success("File uploaded successfully");
        },error:(err)=>{
          this.toastrService.error("An error occurred");
          this.uploading = false;
        }
      });*/
      this.chunkUploaderService.getProgress().subscribe({
        next:(response)=>{
          this.totalChunks = response.totalCount;
          this.uploadedChunks = response.uploaded
          this.percentage = Math.ceil((this.uploadedChunks*100)/this.totalChunks)+"%";
        }
      })
      //environment.apiUrl+`api/lms/contents/media/
      this.chunkUploaderService.uploadFileWithChunks(this.mediaFile,environment.apiUrl+`api/lms/contents/media/${this.mediaId}/upload`,1024 * 1024 * 20,{redirect:"/lms/media-contents/"+this.mediaId+"/details"}).then(()=>{
        this.uploading = false;
        
      });

    }else{
      this.uploading = false;
      this.toastrService.error("Please select a installer image file");
    }

  }

}
