import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-polar-area-chart',
  templateUrl: './polar-area-chart.component.html',
  styleUrls: ['./polar-area-chart.component.css']
})
export class PolarAreaChartComponent implements OnInit {
  @Input() options:any;
  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
  }

}
