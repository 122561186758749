import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SectionIconServiceService {

  constructor() { }
  getIconClasses(iconName:string){
    iconName = iconName.toLowerCase();
    switch(iconName){
      case "section":
        return "bi bi-bookmark-fill"
        break;
      case "page":
        return "bi bi-file-earmark-fill"
        break;
      case "file":
        return "bi bi-file-earmark-text-fill"
        break;
      case "video":
        return "bi bi-film"
        break;
      case "image":
        return "bi bi-card-image"
        break;
      case "audio":
        return "bi bi-volume-up-fill"
        break;
      case "slideshow":
        return "bi bi-file-easel-fill"
        break;
      case "survey":
        return "bi bi-question-octagon-fill"
        break;
    }
    return "";
  }

}
