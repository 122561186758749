import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseVmImageGetInfoResponse } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageGetInfoResponse';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';

@Component({
  selector: 'lib-get-base-vm-image-info-revision',
  templateUrl: './get-base-vm-image-info-revision.component.html',
  styleUrls: ['./get-base-vm-image-info-revision.component.css']
})
export class GetBaseVmImageInfoRevisionComponent implements OnInit {
  revisionId:string;
  revisionInfo:BaseVmImageGetInfoResponse;
  constructor(private activatedRoute:ActivatedRoute,private baseVmImageService:BaseVmImageService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.revisionId = param["revisionId"];
        this.getRevision();
      }

    })
  }
  getRevision(){
    this.baseVmImageService.getRevision(this.revisionId).subscribe({
      next:(response)=>{
        this.revisionInfo = response.content;
      }
    })
  }

}
