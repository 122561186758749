import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateService } from '../../services/usorta/date.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.css']
})
export class BasicTableComponent implements OnInit,OnChanges {
  @Input() tableData:any = [];
  @Input() fields:any = [];
  @Input() actions:any = [];
  render:boolean = true;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    //this.render = false;
    //setTimeout(()=>{
     // this.render = true;
    //},100);
  }

  ngOnInit(): void {
    console.log(this.tableData);
  }
  writeData(data:any,type:string|null = null){
    if(type !== null){
      switch(type) {
        case "date":
          if(data){
            try {
              var date = new Date(data);
              return date.toLocaleDateString() + " "+date.toLocaleTimeString();
            }catch{
              return ""
            }

          }

      }
    }else{
      return data;
    }
  }

}
