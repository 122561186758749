<div *ngIf="baseVmImageDetails" class="formMain form-maroon-border form-default-border">
    <h5>{{'baseVmImage.detailsPage' | translate}}</h5>
    <hr>
    <div class="item mb-3">
        <b class="mainLabel">{{'name' | translate}} : </b><font>{{baseVmImageDetails.name}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'osType' | translate}} : </b><font>{{baseVmImageDetails.osType}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'osInfo' | translate}} : </b><font>{{baseVmImageDetails.osInfo}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'flavor' | translate}} : </b><font>{{baseVmImageDetails.flavor}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'architecture' | translate}} : </b><font>{{baseVmImageDetails.arch}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'format' | translate}} : </b><font>{{baseVmImageDetails.format}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'filename' | translate}} : </b><font>{{baseVmImageDetails.filename}}</font>
    </div>
    <div class="item mb-3">
        <b class="mainLabel">{{'filesize' | translate}} : </b><font>{{baseVmImageDetails.filesize}}</font>
    </div>

</div>