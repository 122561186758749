import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-user-role',
  templateUrl: './create-user-role.component.html',
  styleUrls: ['./create-user-role.component.css']
})
export class CreateUserRoleComponent implements OnInit {
  formUrl:string;
  baseUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.baseUrl = environment.apiUrl;
    this.formUrl = this.baseUrl + "api/acl/user/roles/create";
  }

}
