import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-customize-install-packages',
  templateUrl: './customize-install-packages.component.html',
  styleUrls: ['./customize-install-packages.component.css']
})
export class CustomizeInstallPackagesComponent implements OnInit {
  installPackagesForm:FormGroup
  vmId:string;
  constructor(private translateService:TranslateService ,private formBuilder:FormBuilder,private toastrService:ToastrService,private virtualLabService:VirtualLabService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.createForm();
  }
  createForm(){
    this.installPackagesForm = this.formBuilder.group({
      packageName:["",Validators.required],
      extraArgs:[""]
    })
  }
  errorControl(name:string){
    if(this.installPackagesForm.get(name)?.errors){
      return true;
    }
    return false;
  }
  addInstallPackagesCustomization(){
    if(this.installPackagesForm.valid){
      var formValues = this.installPackagesForm.value;
      var addCustomizationModel:VMCustomization = {
        type: 'install-package',
        args: JSON.stringify(formValues)
      }
      this.virtualLabService.addCustomizationToVm(this.vmId,addCustomizationModel).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmCustomization.addedParameter"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }

}
