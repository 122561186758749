<div class="card" *ngIf="currentCategory">
    <div class="card-header">
      {{'courseManagement.categoryInfo' | translate}}
    </div>
    <div class="card-body">
      <h5 class="card-title" style="text-decoration: underline;">{{currentCategory.name}}</h5>
      <div class="card-text">
        <div class="label">
            {{'courseManagement.categoryColor' | translate}}
        </div>
        <div class="value">
            <div class="showColor" [style.backgroundColor]="currentCategory.color"></div>
        </div>
      </div>
      <!--<div class="card-text">
        <div class="label">
            {{'courseManagement.options' | translate}}
        </div>
        <div class="value">
            <ol>
              <li *ngFor="let flag of categoryFlags">{{flag}}</li>
            </ol>
        </div>
      </div>-->
      <div class="card-text">
        <div class="label">
            {{'courseManagement.parentCategory' | translate}}
        </div>
        <div class="value">
            {{getCategoryNameByCategoryId(currentCategory.parentId)}}
        </div>
      </div>
      <div class="card-text">
        <div class="label">
            {{'courseManagement.active' | translate}}
        </div>
        <div class="value">
            <input type="checkbox" [checked]="currentCategory.active" [readOnly]="true" onclick="return false"/>
        </div>
      </div>
      <br>
      <div class="actions">
        <button class="btn btn-secondary btn-sm" (click)="showUpdateModal()"><i class="bi bi-pencil-square"></i> {{'btn.edit' | translate}}</button>&nbsp;
        <button class="btn btn-secondary btn-sm" (click)="deleteCategory()"><i class="bi bi-pencil-square"></i> {{'btn.delete' | translate}}</button>&nbsp;
        <button [routerLink]="'/lms/categorytrees/course/'+categoryTreeId+'/categories'" class="btn btn-primary btn-sm"><i class="bi bi-pencil-square"></i> {{'courseManagement.returnCategoryList' | translate}}</button>
      </div>
    </div>
  </div>
  <!-- todo: Kategori adı çeviri tablosu eklenecek... -->