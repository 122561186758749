import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconService } from '../../../services/icons/icon.service';
import { IconSetDetailsResponse } from '../../../models/Icons/iconSetDetailsResponse';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';

@Component({
  selector: 'lib-icon-set-details',
  templateUrl: './icon-set-details.component.html',
  styleUrls: ['./icon-set-details.component.css']
})
export class IconSetDetailsComponent implements OnInit {
  iconSetId: string;
  iconSetDetails: IconSetDetailsResponse;
  apiUrl:string = environment.apiUrl;
  constructor(private translateService:TranslateService,private toastrService:ToastrService,private activatedRoute: ActivatedRoute, private iconService: IconService,private router:Router,private globalModalService:GlobalModalService,private globalOkCancelEvent:GlobalOkCancelEventService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.iconSetId = param["id"];
        this.getIconSetDetails();
      }
    })
  }
  getIconSetDetails(){
    this.iconService.getIconSetDetails(this.iconSetId).subscribe({
      next:(response)=>{
        this.iconSetDetails = response.content;
      }
    })
  }
  routeIconSetDetails(id:string){
    this.router.navigate(["/cms/iconsets/"+id+"/details"]);
  }
  deleteIconSet(){
    if(this.iconSetId == null ||this.iconSetId == ''){
      this.toastrService.error(this.translateService.instant("iconSets.pleaseSelectIconSet"));
      return;
    }
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/"+this.iconSetId+"/delete",
      helperModals:[],
      title:this.translateService.instant("iconSets.deleteIconSetTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconSetDetails();
      }
    })
  }
  returnIconSetsPage(){
    this.router.navigate(["/cms/iconsets/grid"]);
  }
  updateIconSet(){
    if(this.iconSetId == null ||this.iconSetId == ''){
      this.toastrService.error(this.translateService.instant("iconSets.pleaseSelectIconSet"));
      return;
    }
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/"+this.iconSetId+"/update",
      helperModals:[],
      title:this.translateService.instant("iconSets.addNewTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconSetDetails();
      }
    })
  }

}
