<h5>{{'vmcustomization.addNewUser' | translate}}</h5>
<hr />
<form [formGroup]="addNewUserForm">

    <div class="mb-3 form-floating">

        <input type="text" class="form-control" [class.is-invalid]="errorControl('username')" id="username" placeholder="" formControlName="username" value="test">
        <label for="username">{{"username" | translate}}</label>
        <font style="font-size: 13px;">
            <b>*</b>{{"onlyUseLettersAndNumbers" | translate}}
        </font>
    </div>

    <div class="mb-3 form-floating">

        <input type="password" class="form-control" id="password" style="width: calc(100% - 50px); display: inline-block;" formControlName="password" placeholder="">
        <label for="password" class="form-label">{{"password" | translate}}</label>
        <span [title]="'showHidePassword' | translate" style="cursor: pointer; width: 45px; margin-left: 10px; background-color: rgb(255, 255, 255); padding: 8px; border-radius: 5px;" (click)="togglePasswordPreview()"><i [class]="togglePasswordIcon()"></i></span>
    </div>

    <div class="mb-3 form-floating">

        <input type="text" class="form-control" id="homeDir" formControlName="homeDir" placeholder="">
        <label for="homeDir" class="form-label">{{'vmCustomization.homeDir' | translate}}</label>
    </div>

    <div class="mb-3 form-floating">

        <input type="text" class="form-control" id="groups" formControlName="groups" placeholder="group1, group2, group3">
        <label for="groups" class="form-label">{{'vmCustomization.userGroups' | translate}}</label>
        <font style="font-size: 13px;">
            <b>*</b>{{'vmCustomization.groupsSeperateComma' | translate}}
        </font>
    </div>

    <div class="mb-3">
        <div class="form-check">
            <label class="form-check-label" for="systemUser">
                {{'vmCustomization.assignSystemUser' | translate}}
            </label>
            <input class="form-check-input" type="checkbox" formControlName="systemUser" id="systemUser">
        </div>
    </div>

    <div class="mb-3 form-floating">

        <input type="text" class="form-control" id="shell" formControlName="shell" placeholder="">
        <label for="shell" class="form-label">{{'vmCustomization.shell' | translate}}</label>
    </div>
    <button (click)="addUser()" class="btn btn-warning">{{'vmCustomization.create' | translate}}</button>
</form>