import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ResponseModel } from '../../models/responseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ApplicationDetailsModel } from '../../models/Applications/applicationDetailsModel';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private httpClient: HttpClient) { }
  deleteApplication(appId: string) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(apiUrl + "api/cms/admin/appdata/applications/" + appId + "/delete", { body: { id: appId } });
  }
  getApplicationDetails(appId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<ApplicationDetailsModel>>(apiUrl + "api/cms/admin/appdata/applications/"+appId+"/details")
  }
}
