<div *ngIf="vmInstance" class="formMain form-default-border form-maroon-border">
    <div style="display: flex;">
        <div style="flex:1;">
            <h5>{{'vminstancetypes.virtualMachineInstanceType' | translate}}</h5>
        </div>
        <div style="width: 400px;text-align: right; padding: 0px; margin-right: 15px; display: flex; flex-direction: row;">
            <button class="btn btn-warning btn-sm" [routerLink]="'/instancetypes/'+typeId+'/mappings/create'">{{'vminstancetypes.addnewmapping' | translate}}</button>
            <button class="btn btn-warning btn-sm ms-2" [routerLink]="'/instancetypes/'+typeId+'/mappings'">{{'vminstancetypes.mappings' | translate}}</button>
        </div>

    </div>

    <hr style="margin-bottom: 8px; margin-top: 4px;"/>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.typeId' | translate}} : </b><font class="val">{{vmInstance.typeId}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.name' | translate}} : </b><font class="val">{{vmInstance.name}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.description' | translate}} : </b><font class="val">{{vmInstance.description}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.tags' | translate}} : </b><font class="val">{{vmInstance.tags}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.active' | translate}} : </b><font class="val">{{vmInstance.active}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.ram' | translate}} : </b><font class="val">{{vmInstance.ram}}</font>
    </div>
    <div class="mb-3">
        <b class="title">{{'vminstancetypes.cpuCount' | translate}} : </b><font class="val">{{vmInstance.cpuCount}}</font>
    </div>

</div>
