import { Component, Input, OnInit } from '@angular/core';
import { QuestionDetailModel } from '../../../models/questionManagementModels/questionDetailModel';
import { QuestionTypesConst } from '../../../models/questionManagementModels/questionTypesEnum';

@Component({
  selector: 'lib-show-question',
  templateUrl: './show-question.component.html',
  styleUrls: ['./show-question.component.css']
})
export class ShowQuestionComponent implements OnInit {
  @Input() questionModels:QuestionDetailModel[];
  questionTypes:{key:string,value:number}[];
  questionModelsLeft:QuestionDetailModel[];
  questionModelsRight:QuestionDetailModel[];
  constructor() { }

  ngOnInit(): void {
    this.questionTypes = Object.entries(QuestionTypesConst).map((val,index,arr)=>{
      return {key:arr[index][0],value:arr[index][1]}
    });
    this.chunkArray(this.questionModels,2);

  }
  chunkArray(arr:QuestionDetailModel[],n:number){
    var chunkLength = Math.max(arr.length/n ,1);
    var chunks = [];
    for (var i = 0; i < n; i++) {
        if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
    }
    this.questionModelsLeft = chunks[0];
    this.questionModelsRight = chunks[1];
    return chunks; 
}

}
