import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormatStringService } from '../../../services/localizedFormatString/format-string.service';
import { LocalizedFormatStringResponse } from '../../../models/LocalizedFormatString/localizedFormatStringResponse';

@Component({
  selector: 'lib-localized-format-string-details',
  templateUrl: './localized-format-string-details.component.html',
  styleUrls: ['./localized-format-string-details.component.css']
})
export class LocalizedFormatStringDetailsComponent implements OnInit {
  formatStringId:number;
  formatString:LocalizedFormatStringResponse
  constructor(private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private formatStringService:FormatStringService) { }
  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formatStringId = param["id"];
        this.getDetails();
      }
    })
  }
  getDetails(){
    this.formatStringService.getFormatStringDetails(this.formatStringId).subscribe({
      next:(response)=>{
        this.formatString = response.content;
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
