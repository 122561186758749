import { ResponseModel } from './../../models/responseModel';
import { UserInfo } from './../../models/userInfo';
import { LoginModel } from './../../models/loginModel';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseTokenModel } from '../../models/responseTokenModel';
import { SingleResponseModel } from '../../models/singleResponseModel';

@Injectable({
  providedIn: 'root'
})
export abstract class LoginService {

  public abstract login(loginModel:LoginModel,isAdmin:boolean):Observable<SingleResponseModel<ResponseTokenModel>>;
  public abstract getUserInfo(isAdmin:boolean):Observable<SingleResponseModel<UserInfo>>;
  public abstract getUsernameForVerifyPassword():Observable<string>;
  public abstract isLoggedControlWithExpiration():boolean;
  public abstract refreshExpirationControl():boolean;
  public abstract logout():void;
  public abstract verifyPassword(loginModel:LoginModel):Observable<ResponseModel>;
}
