<!--<div class="specs" *ngIf="specs">
    <h5>{{'manageSetupInstance.vmSpecs'}}</h5>
    <div class="spec-item">
        <b class="field-name">{{'manageSetupInstance.instanceTypeName' | translate}} : </b>
        <font class="field-value">{{specs.instanceTypeName}}</font>
    </div>
    <div class="spec-item">
        <b class="field-name">{{'manageSetupInstance.cpuCountHint' | translate}} : </b>
        <font class="field-value">{{specs.cpuCountHint}}</font>
    </div>
    <div class="spec-item" *ngIf="specs.memoryHint">
        <b class="field-name">{{'manageSetupInstance.memoryHint' | translate}} : </b>
        <font class="field-value">{{specs.memoryHint}}</font>
    </div>
    <div class="spec-item">
        <b class="field-name">{{'manageSetupInstance.setupHostName' | translate}} : </b>
        <font class="field-value">{{specs.setupHostName}}</font>
    </div>
    <div class="spec-item">
        <b class="field-name">{{'manageSetupInstance.nicCount' | translate}} : </b>
        <font class="field-value">{{specs.nicCount}}</font>
    </div>
    <div class="spec-item">
        <b class="field-name">{{'manageSetupInstance.disks' | translate}} : </b>
        <div class="alert alert-info" role="alert" *ngIf="specs.disks.length == 0">
            Disk not found
          </div>
        <div class="disk-item" *ngFor="let disk of specs.disks">
            <div class="disk-info">
                <b class="disk-field-name">{{'manageSetupInstance.disks.name' | translate}} : </b>
                <font class="disk-field-value">{{disk.name}}</font>
            </div>
            <div class="disk-info">
                <b class="disk-field-name">{{'manageSetupInstance.disks.capacity' | translate}}</b>
                <font class="disk-field-value">{{disk.capacityInGigs}} GB</font>
            </div>
            <div class="disk-info">
                <b class="disk-field-name">{{'manageSetupInstance.disks.deviceName' | translate}}</b>
                <font class="disk-field-value">{{disk.deviceName}}</font>
            </div>
            <div class="disk-info">
                <b class="disk-field-name">{{'manageSetupInstance.disks.active' | translate}} : </b>
                <input type="checkbox" [checked]="disk.active" onclick="return false" class="form-check-input">
            </div>
            <div class="disk-info">
                <b class="disk-field-name">{{'manageSetupInstance.disks.primary' | translate}} : </b>
                <input type="checkbox" [checked]="disk.primary" onclick="return false" class="form-check-input">
            </div>
        </div>
    </div>

    <div class="spec-item">

        <b class="field-name">{{'manageSetupInstance.nics' | translate}}</b>
        <div class="alert alert-info" role="alert" *ngIf="specs.nics.length == 0">
            Nic not found
          </div>
        <div class="nic-item" *ngFor="let nic of specs.nics">
            <div class="nic-info">
                <b class="nic-field-name">{{'manageSetupInstance.disks.name' | translate}}</b>
                <font class="nic-field-value">{{nic.name}}</font>
            </div>
            <div class="nic-info" *ngIf="nic.deviceType">
                <b class="nic-field-name">{{'manageSetupInstance.nics.deviceType' | translate}}</b>
                <font class="nic-field-value">{{nic.deviceType}}</font>
            </div>
            <div class="nic-info">
                <b class="nic-field-name">{{'manageSetupInstance.nics.attached' | translate}}</b>
                <input type="checkbox" [checked]="nic.attached" onclick="return false" class="form-check-input">
            </div>
        </div>
    </div>
    
</div>-->
<div class="vm-specs" >
    <h5>{{'manageSetupInstance.vmSpecs'}} <button class="btn btn-sm btn-warning" (click)="updateSpecsModal()"><i class="bi bi-pencil-fill"></i></button></h5>
    <div class="specs-toolbar">
        <!--<div><font class="specs-toolbar-title">{{'setupVmInstances.specsToolbarTitle' | translate}}</font></div>-->
        <div class="toolbar-item" (click)="addDiskModal()" [title]="'setupVmInstances.addDiskTitle' | translate">
            <i class="bi bi-hdd-fill" style="margin-right: 4px;"></i><i class="bi bi-plus-lg"></i>
        </div>
        <div class="toolbar-item" (click)="addNicModal()" [title]="'setupVmInstances.addNicTitle' | translate">
            <i class="bi bi-pci-card" style="margin-right: 4px;"></i><i class="bi bi-plus-lg"></i>
        </div>
        <div class="toolbar-item" (click)="addRemovableImageModal()" [title]="'setupVmInstances.addRemovableImageTitle' | translate">
            <i class="bi bi-disc-fill" style="margin-right: 4px;"></i><i class="bi bi-plus-lg"></i>
        </div>
    </div>
    
     <div *ngIf="vmSpecs">
       <div class="spec-item">
           <b class="spec-field">{{'vmSpecs.vmName' | translate}} : </b><font class="spec-value">{{vmSpecs.name}}</font>
       </div>
       <div class="spec-item">
           <b class="spec-field">{{'vmSpecs.osType' | translate}} : </b><font class="spec-value">{{vmSpecs.osType}}</font>
       </div>
       <div class="spec-item">
           <b class="spec-field">{{'vmSpecs.osDetails' | translate}} : </b><font class="spec-value">{{vmSpecs.osInfo}}</font>
       </div>
       <div class="spec-item">
           <b class="spec-field">{{'vmSpecs.arch' | translate}} : </b><font class="spec-value">{{vmSpecs.arch}}</font>
       </div>
       <b>{{'vmSpecs.resourseSpecs' | translate}}</b>
       <div class="spec-item">
           <b class="spec-field">{{'vmSpecs.processorInfo' | translate}} : </b><font class="spec-value">{{vmSpecs.cpuCountHint}}</font>
       </div>
       <div class="spec-item" *ngIf="vmSpecs.memoryHint != null">
           <b class="spec-field">{{'vmSpecs.memoryInfo' | translate}} : </b><font class="spec-value">{{vmSpecs.memoryHint}}</font>
           
       </div>
       <b *ngIf="vmSpecs.disks != null && vmSpecs.disks.length > 0">{{'vmSpecs.diskConfiguration' | translate}}</b>
       <div style="overflow: auto;" class="spec-item" *ngIf="vmSpecs.disks != null && vmSpecs.disks.length > 0" >
           <table class="table">
               <thead>
                   <tr>
                       <th>{{'vmSpecs.diskId' | translate }}</th>
                       <th>{{'vmSpecs.diskName' | translate }} </th>
                       <th>{{'vmSpecs.format' | translate }}</th>
                       <th>{{'vmSpecs.capacity' | translate }}</th>
                       <th>{{'vmSpecs.primary' | translate }}</th>
                       <th></th>
                   </tr>
               </thead>
               <tbody>
                   <tr *ngFor="let disk of vmSpecs.disks">
                       <td>{{disk.diskId}}</td>
                       <td>{{disk.name}}</td>
                       <td>{{disk.format}}</td>
                       <td>{{disk.capacityInGigs}}GB</td>
                       <td>{{disk.primary}}</td>
                       <td><button class="btn btn-sm btn-warning" (click)="updateDiskModal(disk.diskId)"><i class="bi bi-pencil-fill"></i></button></td>
                   </tr>
               </tbody>

           </table>
           
       </div>

       <b *ngIf="vmSpecs.removableMedia != null &&  vmSpecs.removableMedia.length > 0">{{'vmSpecs.removeableMediaList' | translate}}</b>
       <div style="overflow: auto;" *ngIf="vmSpecs.removableMedia != null && vmSpecs.removableMedia.length > 0" class="spec-item" >
           <table class="table">
               <thead>
                   <tr>
                       <th>{{'vmSpecs.removeableMediaId' | translate }}</th>
                       <th>{{'vmSpecs.removeableMediaName' | translate }}</th>
                       <th>{{'vmSpecs.capacity' | translate }} </th>
                       <th>{{'vmSpecs.attached' | translate }}</th>
                   </tr>
               </thead>
               <tbody>
                   <tr *ngFor="let rm of vmSpecs.removableMedia">
                       <td>{{rm.mediaId}}</td>
                       <td>{{rm.name}}</td>
                       <td>{{rm.capacityInGigs}}GB</td>
                       <td>{{rm.attached}}</td>
                   </tr>
               </tbody>

           </table>
           
       </div>


       <b *ngIf="vmSpecs.nics != null && vmSpecs.nics.length > 0">{{'vmSpecs.networkConfiguration' | translate}}</b>
       <div style="overflow: auto;" class="spec-item" *ngIf="vmSpecs.nics != null && vmSpecs.nics.length > 0" >
           <table class="table">
               <thead>
                   <tr>
                       <th>{{'vmSpecs.nicId' | translate }}</th>
                       <th>{{'vmSpecs.nicName' | translate }} </th>
                       <th>{{'vmSpecs.nicType' | translate }}</th>
                       <th></th>
                   </tr>
               </thead>
               <tbody>
                   <tr *ngFor="let nic of vmSpecs.nics">
                       <td>{{nic.nicId}}</td>
                       <td>{{nic.name}}</td>
                       <td>{{nic.deviceType}}</td>
                       <td><button class="btn btn-sm btn-warning" (click)="updateNicModal(nic.nicId)"><i class="bi bi-pencil-fill"></i></button></td>
                   </tr>
               </tbody>

           </table>
           
       </div>
     </div>
