import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  @Input() options:any;
  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
  }

}
