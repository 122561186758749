import { Injectable } from '@angular/core';
import { FeatureType } from '../../models/SessionTemplateModels/featureType';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SessionTemplateModel } from '../../models/SessionTemplateModels/sessionTemplateModel';
import { AddSessionTemplateFeatureRequestModel } from '../../models/SessionTemplateModels/addTemplateFeatureRequestModel';
import { AddSessionTemplateItemRequestModel } from '../../models/SessionTemplateModels/addTemplateItemRequestModel';
import { FormElement } from '../../models/CustomFormModels/formElement';
import { UpdateSessionTemplateRequestModel } from '../../models/SessionTemplateModels/updateSessionTemplateRequestModel';
import { UpdateSessionTemplateFeatureRequest } from '../../models/SessionTemplateModels/updateSessionTemplateFeatureRequest';
import { CreateSessionModel } from '../../models/SessionApiModels/createSessionModel';

@Injectable({
  providedIn: 'root'
})
export class SessionTemplateService {

  constructor(private httpClient:HttpClient) { }
  getFeatureType(id:string):Observable<SingleResponseModel<FeatureType>>{
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<FeatureType>>(apiUrl+"api/v2/sessions/featuretypes/"+id);
  }
  deleteFeatureType(id:string):Observable<ResponseModel>{
    var body = {id:id};
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/v2/sessions/featuretypes/${id}/delete`,{body:body});
  }
  getFeatureTypesForSelectList():Observable<ListResponseModel<ListItemModel>>
  {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(apiUrl+"api/v2/sessions/featuretypes/optionList");
  }
  getSessionTemplateItemSourceId(itemType:string){
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/${itemType}.json`);
  }
  getSessionTemplate(id:number){
    return this.httpClient.get<SingleResponseModel<SessionTemplateModel>>(`${environment.apiUrl}api/v2/sessions/templates/${id}`)
  }
  getSessionTemplateList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${environment.apiUrl}api/v2/sessions/templates/optionlist`);
  }
  deleteSessionTemplate(id:string){
    var body = {id:id};
    return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/templates/${id}/delete`,{body:body});
  }
  deleteSessionTemplateFeature(templateId:number,featureId:number){
    var body = {id:featureId};
    return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/templates/${templateId}/features/${featureId}/delete`,{body:body});
  }
  deleteSessionTemplateItem(templateId:number,itemId:number){
    var body = {id:itemId};
    return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/templates/${templateId}/items/${itemId}/delete`,{body:body});
  }
  addSessionTemplateFeature(addSessionTemplateFeatureRequest:AddSessionTemplateFeatureRequestModel){
    return this.httpClient.post<ResponseModel>(`${environment.apiUrl}api/v2/sessions/templates/${addSessionTemplateFeatureRequest.templateId}/addfeature`,addSessionTemplateFeatureRequest);
  }
  addSessionTemplateItem(addSessionTemplateItemRequest:AddSessionTemplateItemRequestModel){
    return this.httpClient.post<ResponseModel>(`${environment.apiUrl}api/v2/sessions/templates/${addSessionTemplateItemRequest.templateId}/additem`,addSessionTemplateItemRequest);
  }
  getSessionTemplateFeatureUpdateElements(templateId:number,featureId:number){
    return this.httpClient.get<ListResponseModel<FormElement>>(`${environment.apiUrl}api/v2/sessions/templates/${templateId}/features/${featureId}/form`);
  }
  updateSessionTemplateFeature(updateSessionTemplateFeatureRequest:UpdateSessionTemplateFeatureRequest){
    return this.httpClient.post(`${environment.apiUrl}api/v2/sessions/templates/${updateSessionTemplateFeatureRequest.templateId}/features/${updateSessionTemplateFeatureRequest.templateFeatureId}/update`,updateSessionTemplateFeatureRequest);
  }

}
