<div class="preloaderDiv">

</div>
<div class="spinnerDiv">
  <div *ngIf="loading" class="spinner spinner-border text-light" role="status">
    <span class="visually-hidden">...</span>
  </div>

  <div *ngIf="error" class="text-light" role="status">
    <div style="text-align: center; font-size:150px">
      <i class="bi bi-exclamation-diamond"></i>
    </div>
   <!--
    Buradaki hata mesajı tenant sunucusuna erişilemediği zaman görüntülenecektir.
    O yüzden burayı translate pipe ile gösteremiyoruz.
    Onun yerine yerel olarak JSON dosyasından anahtar değer çeken bir pipe yazdım.
   -->
   {{'preloaderError' | localTranslate}}
  </div>
</div>
