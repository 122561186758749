<div class="switchItem">
    <div class="switchLabel">
        <div class="switchLabelText" [title]="hostDashboardSwitch.label">
            {{hostDashboardSwitch.label}}
        </div>

        <i data-bs-container="body" *ngIf="hostDashboardSwitch.info != undefined && hostDashboardSwitch.info != ''" style="color: rgb(88, 141, 44); cursor: pointer;" class="bi bi-question-circle-fill" data-bs-toggle="popover" attr.data-bs-content="{{hostDashboardSwitch.info}}"></i>
    </div>
    <div class="switch-light" *ngIf="hostDashboardSwitch.displayType == 'light'">
        <label class="sw-light">
            <input class="sw-light-input" [checked]="hostDashboardSwitch.value ?? null" #switchRef [disabled]="hostDashboardSwitch.allowUserControl == false" type="checkbox">
            <span [class.userActionsDisabled]="hostDashboardSwitch.allowUserControl == false" [class.nullSwitch]="switchRef.value == 'not-defined'" class="sw-light-span"></span>
        </label>

    </div>
    <div class="switch-flip" *ngIf="hostDashboardSwitch.displayType == 'flip-switch'">

    <input class="tgl tgl-skewed" #switchRef [checked]="hostDashboardSwitch.value ?? null" [disabled]="hostDashboardSwitch.allowUserControl == false" [id]="'sw_'+hostDashboardSwitch.key" type="checkbox"/>
    <label class="tgl-btn" [class.userActionsDisabled]="hostDashboardSwitch.allowUserControl == false"  [class.nullSwitch]="switchRef.value == 'not-defined'" data-tg-off="OFF" data-tg-on="ON" [for]="'sw_'+hostDashboardSwitch.key"></label>

    </div>
    <div class="switch-slide" *ngIf="hostDashboardSwitch.displayType == 'slide-switch'">
        <label class="switch">
            <input type="checkbox" #switchRef [checked]="hostDashboardSwitch.value ?? null" [disabled]="hostDashboardSwitch.allowUserControl == false" [id]="'sw_'+hostDashboardSwitch.key">
            <span [class.userActionsDisabled]="hostDashboardSwitch.allowUserControl == false" [class.nullSwitch]="switchRef.value == 'not-defined'" class="slider round"></span>
          </label>
    </div>
</div>
