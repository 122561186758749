import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUser'
})
export class SearchUserPipe implements PipeTransform {

  transform(value: any[], fullName:string): any[] {
    return fullName == null ? value : value.filter(x=>((x.firstName+" "+x.lastName)as string).toLocaleLowerCase().includes(fullName.toLocaleLowerCase()));
  }

}
