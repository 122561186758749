import { FormResponseObject } from './../../models/CustomFormModels/formResponseObject';
import { Observable } from 'rxjs';
import { ApiUrlService } from './../api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomForm } from '../../models/CustomFormModels/customForm';

@Injectable({
  providedIn: 'root'
})
export class CustomFormService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  public externalUrl = "";
  getForm(formId:string):Observable<CustomForm>{
    let apiUrl = "";
    if(this.externalUrl != "" && this.externalUrl != undefined){
      apiUrl = this.externalUrl;
      console.log("formUrl"+apiUrl)
      return this.httpClient.get<CustomForm>(apiUrl);
    }else{
      apiUrl = this.apiUrlService.getApiUrl();
      return this.httpClient.get<CustomForm>(apiUrl+"cms/form/"+formId);
    }


  }
  formPostRequest(url:string,data:any,headers:any | undefined | null):Observable<FormResponseObject>{
    if(headers){
      return this.httpClient.post<FormResponseObject>(url,data,{headers:headers});
    }
    return this.httpClient.post<FormResponseObject>(url,data);
  }
}
