<lib-custom-form [formTopElement]="formTopInput" [formBottomElement]="formBottomInput" [externalAPIUrl]="''" [externalFormData]="null" [formId]="'createSession'" (GetSendingObjects)="postData($event)" (GetFormGroup)="getFormGroup($event)"
    [getFormInput]="requestForm"></lib-custom-form>

<div id="formTopEl">
    <b class="mainLabel">{{'addSession.useTemplate' | translate}}</b>
    <select class="form-select" id="templateSelect">
        <option *ngFor="let template of templateList" [value]="template.value">{{template.label}}</option>
    </select>
    <br/>

</div>
<div id="formBottomEl">
    <b class="mainLabel">{{'addSession.addSessionData' | translate}}</b>
    <lib-dynamic-form-to-json *ngIf="addSessionFormGroup" [formElements]="elementList" [autoSave]="true"
        (dataChanges)="onDynamicFormDataChange($event)"></lib-dynamic-form-to-json>
        <br/>
</div>
