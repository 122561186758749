import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ModalCreateModel } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  modalCreate:ModalCreateModel;
  modalCreate2:ModalCreateModel;
  usersTable = environment.apiUrl+'api/accounts/users/table';
  constructor(private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    //this.modalCreate2 = {title:"Merhaba",data:'/modal/user/create',type:ModalTypes.LINK,helperModals:[{id:"1modal",data:"test"},{id:"2modal",data:"test2"}]}
    let testDiv = document.createElement('div');
    testDiv.innerHTML = '<input type="checkbox" class="acceptRequired" data-helper="3modal"/><div><button class="btn btn-danger requireCheck" data-check="3modal" >Test</button></div>'
    this.modalCreate2 = {title:"Merhaba 2",data:testDiv,type:ModalTypes.HTMLELEMENT,helperModals:[{id:"3modal",data:"test 3"},{id:"4modal",data:"test 4"}]}
    
  }
  
  changeModal2() {
    this.modalCreate = {...this.modalCreate2};
  }
  showModal(){
    let modal = this.globalModalService.showModal(this.modalCreate2);
  }

}
