import { Injectable } from '@angular/core';
import { CreateQuestionSetModel } from '../../models/questionManagementModels/createQuestionSet';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CreateQuestionModel } from '../../models/questionManagementModels/createQuestionModel';
import { CreateQuestionOptionModel } from '../../models/questionManagementModels/createQuestionOptionModel';
import { ResponseModel } from '../../models/responseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { ListResponseModel } from '../../models/listResponseModel';
import {  QuestionDetailModel, QuestionUpdateFields } from '../../models/questionManagementModels/questionDetailModel';
import { QuestionSetModel } from '../../models/questionManagementModels/questionSetModel';
import { UpdateQuestionSetModel } from '../../models/questionManagementModels/updateQuestionSetModel';
import { QuestionModel } from '../../models/questionManagementModels/questionModel';
import { UpdateQuestionModel } from '../../models/questionManagementModels/updateQuestionModel';
import { QuestionSetDetail } from '../../models/questionManagementModels/questionSetDetail';
import { UpdateQuestionOptionModel } from '../../models/questionManagementModels/updateQuestionChoiceModel';
import { UpdateQuestionFieldModel } from '../../models/questionManagementModels/updateQuestionFieldModel';
import { QuestionOptionDetailsModel } from '../../models/questionManagementModels/questionOptionDetailsModel';
import { CreateQuestionTranslationModel } from '../../models/questionManagementModels/createQuestionTranslationModel';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private httpClient: HttpClient) { }
  createQuestionSet(questionSetModel: CreateQuestionSetModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questionsets/create`, questionSetModel);
  }
  createQuestion(createQuestionModel: CreateQuestionModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questions/create`, createQuestionModel);
  }
  addQuestionTranslation(questionId:number,createQuestionTranslationModel:CreateQuestionTranslationModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${questionId}/translations/create`,createQuestionTranslationModel);
  }
  addOptionToQuestion(createQuestionOptionModel: CreateQuestionOptionModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${createQuestionOptionModel.questionId}/options/create`, createQuestionOptionModel);
  }
  getLanguageList() {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/fakeJson/languages.json")
  }
  getQuestionSetList(topLevel:boolean = false) {
    var apiUrl = environment.apiUrl;
    var query = "";
    if(topLevel){
      query = "?TopLevel=true"
    }
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/lms/assessment/questionsets/optionlist${query}`)
  }
  getQuestionDetail(questionId: number) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionDetailModel>>("/assets/fakeJson/questionExample.json");
  }
  getQuestion(questionId: number) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionModel>>(`${apiUrl}api/lms/assessment/questions/${questionId}`);
  }
  getQuestionSetUpdateFields(questionSetId: number) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionSetModel>>(`${apiUrl}api/lms/assessment/questionsets/${questionSetId}/update`);
  }
  updateQuestionSet(updateQuestionSetModel: UpdateQuestionSetModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questionsets/${updateQuestionSetModel.id}/update`, updateQuestionSetModel);
  }
  deleteQuestionSet(id:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/assessment/questionsets/${id}/delete`,{body:{id:id}});
  }
  deleteQuestion(id:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${id}/delete`,{body:{id:id}});
  }
  updateQuestion(updateQuestionModel: UpdateQuestionModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${updateQuestionModel.id}/update`, updateQuestionModel);
  }
  updateQuestionFields(id:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<UpdateQuestionFieldModel>>(`${apiUrl}api/lms/assessment/questions/${id}/update`);
  }
  getQuestionSetWithContent(questionSetId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionSetDetail>>("/assets/fakeJson/questionSetDetails.json");
  }
  getQuestionOptionUpdateFields(questionId:number,optionId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionUpdateFields>>(`${apiUrl}api/lms/assessment/questions/${questionId}/options/${optionId}/update`);
  }
  updateQuestionOption(questionId:number,optionId:number,updateModel:UpdateQuestionOptionModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${questionId}/options/${optionId}/update`,updateModel);
  }
  deleteQuestionOption(questionId:number,optionId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/assessment/questions/${questionId}/options/${optionId}/delete`,{body:{id:optionId}})
  }
  getQuestionOptionDetails(questionId:number,optionId:number){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<QuestionOptionDetailsModel>>(`${apiUrl}api/lms/assessment/questions/${questionId}/options/${optionId}`)
  }
} 
