<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'vmInstanceTypeMappings.createFormTitle' | translate}}</font>
    </div>
<div *ngIf="providers" class="mb-3">
    <form [formGroup]="createMappingForm">
        <div class="mb-3">
            <label for="providerType" class="form-label">{{'vminstancetypes.providerType' | translate}}</label>
            <select (change)="changeProvider()" id="providerType" class="form-select"
                aria-label="Default select example">
                <option *ngFor="let provider of providers" [value]="provider.value">{{provider.label}}</option>
            </select>
            <label for="hostId" class="form-label">{{'vminstancetypes.host' | translate}}</label>
            <select (change)="changeHost()" id="hostId" class="form-select" aria-label="Default select example">
                <option [value]="'null'">Not Selected</option>
                <option *ngFor="let host of hosts" [value]="host.value">{{host.label}}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="name" class="form-label">{{'vminstancetypes.name' | translate}}</label>
            <input formControlName="name" type="text" class="form-control" id="name">
        </div>
        <div class="mb-3">
            <label for="typeId" class="form-label">{{'vminstancetypes.typeId' | translate}}</label>
            <input formControlName="typeId" type="text" class="form-control" id="typeId">
        </div>
        <div class="form-check mb-3">
            <input formControlName="active" class="form-check-input" type="checkbox" value="" id="active">
            <label class="form-check-label" for="active">
                {{'vminstancetypes.active' | translate}}
            </label>
        </div>
        <div class="mb-3">
            <label for="notes" class="form-label">{{'vminstancetypes.notes' | translate}}</label>
            <textarea formControlName="notes" class="form-control" id="notes" rows="3"></textarea>
        </div>
        <div class="mb-3">
            <label for="priority" class="form-label">{{'vminstancetypes.priority' | translate}}</label>
            <input formControlName="priority" type="number" class="form-control" [value]="0" id="priority">
        </div>
        <div class="mb-3">
            <label for="ram" class="form-label">{{'vminstancetypes.ram' | translate}}</label>
            <select formControlName="ram" class="form-select" id="ram" aria-label="">
                <option *ngFor="let ram of ramList" [value]="ram.value">{{ram.label}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="cpuCount" class="form-label">{{'vminstancetypes.cpuCount' | translate}}</label>
            <select type="number" formControlName="cpuCount" class="form-select" id="cpuCount" aria-label="">

                <option *ngFor="let cpu of cpuList" [value]="cpu.value">{{cpu.label}}</option>

                </select>
        </div>
        <button (click)="addMapping()" class="btn btn-primary addBtn">{{'btn.add' | translate}}</button>
    </form>
</div>
</div>