import { Component, Input, OnInit } from '@angular/core';
import { MediaContentService } from '../../../services/mediaContent/media-content.service';
import { MediaContentDetailsResponse } from '../../../models/MediaContent/mediaContentDetailsResponse';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-cms-media-viewer',
  templateUrl: './cms-media-viewer.component.html',
  styleUrls: ['./cms-media-viewer.component.css']
})
export class CmsMediaViewerComponent implements OnInit {
  fullscreen:boolean = false;
  @Input() mediaId:string;
  mediaDetails:MediaContentDetailsResponse;
  mediaAccessToken:string;
  mediaSrc:string | null;
  constructor(private mediaContentService:MediaContentService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      if(this.mediaId == null){
        this.mediaId = param["mediaId"];
      }

    })
    this.getMediaDetails();
  }
  mediaError($ev:Event){
    this.mediaSrc = null;
    this.getMediaDetails();
  }
  getMediaDetails(){
    this.mediaContentService.getMediaContentDetails(this.mediaId).subscribe({
      next:(response)=>{
        this.mediaContentService.getMediaAccessToken().subscribe({
          next:(tokenResponse)=>{
            var mediaAccessToken = tokenResponse.content;
            this.mediaDetails = response.content;
            this.mediaSrc = environment.apiUrl + "api/cms/filestore/media/"+this.mediaDetails.cmsId+"?token="+mediaAccessToken;
          }
        })


      }
    })
  }

}
