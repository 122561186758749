import { LayoutModel } from './../../models/Layout/LayoutModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private httpClient:HttpClient) { }
  getLayout(layout:string){
    return this.httpClient.get<LayoutModel>("http://cybrng:3003/api/v2/test/Layout/getLayout?layout="+layout)
  }
}
