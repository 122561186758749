import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-delete-base-vm-image-note',
  templateUrl: './delete-base-vm-image-note.component.html',
  styleUrls: ['./delete-base-vm-image-note.component.css']
})
export class DeleteBaseVmImageNoteComponent implements OnInit {
  noteId:string;
  url:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.noteId = param["noteId"];
        this.url = "api/range/basevmimages/notes/"+this.noteId+"/delete"
      }
    })
  }

}
