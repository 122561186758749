export const QuestionFormatConst = {
    PlainText:0,
    HTML:1,
    Markdown:2,
    Json:3,
    Xml:4,
    Layout:5
}
export enum QuestionFormatEnum {
    PlainText,
    HTML,
    Markdown,
    Json,
    Xml,
    Layout
}