import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api/api-url.service';
import { ResponseModel } from '../../models/responseModel';
import { DeleteMenuRequest } from '../../models/Navigation/deleteMenuRequest';
import { DeleteMenuItemRequest } from '../../models/Navigation/deleteMenuItemRequest';
import { DeleteLocalizedMenuRequest } from '../../models/Navigation/deleteLocalizedMenuRequest';
import { DeleteLocalizedMenuItemRequest } from '../../models/Navigation/deleteLocalizedMenuItemRequest';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { MenuItemResponse } from '../../models/Navigation/menuItemResponse';
import { MenuItems } from '../../models/menu/menuItems';
import { LocalizedMenuItemResponse } from '../../models/Navigation/localizedMenuItemResponse';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private apiUrlService:ApiUrlService,private httpClient:HttpClient) { }
  deleteMenu(id:number){
    var apiUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/menu/"+id+"/delete";
    var req:DeleteMenuRequest = {id : id};
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:req});
  }
  deleteMenuItem(menuid:number,itemid:number){
    var apiUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/menu/"+menuid+"/items/"+itemid+"/delete";
    var req:DeleteMenuItemRequest = {id : itemid,menuId:menuid};
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:req});
  }
  deleteLocalizedMenu(menuid:number,localizedmenuid:number){
    var apiUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/menu/"+menuid+"/localize/"+localizedmenuid+"/delete"
    var req:DeleteLocalizedMenuRequest = {id : localizedmenuid};
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:req});
  }
  deleteLocalizedMenuItem(itemid:number,localizeditemid:number){
    var apiUrl:string = this.apiUrlService.getApiUrl() + "api/cms/admin/menuitem/"+itemid+"/localize/"+localizeditemid+"/delete"
    var req:DeleteLocalizedMenuItemRequest = {id : localizeditemid};
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:req});
  }
  getLocalizedMenuItemDetails(menuitemid:number,localizeditemid:number){
    var apiUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/menuitem/${menuitemid}/localize/${localizeditemid}/details`
    return this.httpClient.get<SingleResponseModel<LocalizedMenuItemResponse>>(apiUrl);
  }
  getMenu(id:number){
    var apiUrl = this.apiUrlService.getApiUrl() + "api/cms/menu/"+id;
    return this.httpClient.get<SingleResponseModel<any>>(apiUrl);
  }
  getMenuByName(name:string,applicationId:string,tenantId:string|null=null){
    var queryStr = "";
    if(tenantId != null && tenantId.trim() != ""){
      queryStr = "?tenantId="+tenantId
    }
    var apiUrl = this.apiUrlService.getApiUrl() + `api/cms/menu/getbyname/${applicationId}/${name}${queryStr}`;
    return this.httpClient.get<SingleResponseModel<any>>(apiUrl);
  }
  getMenuItemDetails(id:number){
    var apiUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/menuitem/${id}/details`;
    return this.httpClient.get<SingleResponseModel<MenuItemResponse>>(apiUrl);
  }
}
