<table *ngIf="render" class="table table-light table-hover">
    <thead>
        <tr>
          <th *ngFor="let field of fields" scope="col">{{field.text}}</th>
          <th *ngIf="actions && actions.length > 0" attr.colspan="{{actions.length}}">İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tableData">
          <td *ngFor="let field of fields">{{writeData(data[field.field],field.type ?? null)}}</td>
          <td *ngIf="actions && actions.length > 0"><div *ngFor="let action of actions" style="display:inline-block; margin:3px;"><button class="btn btn-sm btn-secondary" (click)="action.clickOperation(data)" *ngIf="(action.ifShow == null) || (action.ifShow(data))">{{action.text}}</button></div></td>
        </tr>
      </tbody>
</table>