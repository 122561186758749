import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuItemResponse } from '../../../models/Navigation/menuItemResponse';
import { LocalizedMenuItemResponse } from '../../../models/Navigation/localizedMenuItemResponse';

@Component({
  selector: 'lib-localized-item-menu-details',
  templateUrl: './localized-item-menu-details.component.html',
  styleUrls: ['./localized-item-menu-details.component.css']
})
export class LocalizedItemMenuDetailsComponent implements OnInit {
  localizedMenuItemId: number
  menuItemId: number
  localizedItemDetails: LocalizedMenuItemResponse
  constructor(private navigationService: NavigationService, private activatedRoute: ActivatedRoute, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.menuItemId = param["menuitemid"];
        this.localizedMenuItemId = param["localizeditemid"];
        this.getData();
      }
    })
  }
  getData() {
    this.navigationService.getLocalizedMenuItemDetails(this.menuItemId, this.localizedMenuItemId).subscribe({
      next: (response) => {
        this.localizedItemDetails = response.content;
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
