<h5>Soru Kümesi Detayları</h5>
<hr>
<div class="questionSetInfo" *ngIf="questionSetDetail">
    <div class="item"><b>Soru Kümesi Adı : </b> <font>{{questionSetDetail.name}}</font></div>
    <div class="item"><b>Aktif : </b> <input type="checkbox" [readOnly]="true" onclick="return false" [checked]="questionSetDetail.active"/></div>
</div>

<lib-show-question *ngIf="questionSetDetail" [questionModels]="questionSetDetail.questions"></lib-show-question>


