<div class="icon-details formMain form-default-border form-maroon-border" *ngIf="iconSetDetails">
    <h5>{{'iconSets.iconSetDetailsTitle' | translate}}</h5>
    <hr/>
    <div class="iconSets-toolbar">
        <div (click)="returnIconSetsPage()" class="iconSets-toolbar-item ">
            <i class="bi bi-boxes primary"></i><font> {{'btn.returnIconSetsPage' | translate}}</font>
        </div>
        <div (click)="updateIconSet()" class="iconSets-toolbar-item ">
            <i class="bi bi-pencil-fill warning"></i><font> {{'btn.edit' | translate}}</font>
        </div>
        <div (click)="deleteIconSet()" class="iconSets-toolbar-item ">
            <i  class="bi bi-trash-fill danger"></i><font> {{'btn.delete' | translate}}</font>
        </div>
    </div>
    <hr/>
    <div class="details-area">
        <div><b class="detailTitle">{{'iconSet.name' | translate}} : </b><font class="detailValue">{{iconSetDetails.name}}</font></div>
        <div><b class="detailTitle">{{'iconSet.iconSetCode' | translate}} : </b><font class="detailValue">{{iconSetDetails.iconSetCode}}</font></div>
        <div *ngIf="iconSetDetails.applicationId"><b class="detailTitle">{{'iconSet.applicationId' | translate}} : </b><font class="detailValue">{{iconSetDetails.applicationId}}</font></div>
        <div *ngIf="iconSetDetails.tenantId"><b class="detailTitle">{{'iconSet.tenantId' | translate}} : </b><font class="detailValue">{{iconSetDetails.tenantId}}</font></div>
    </div>
    <div class="alternative-iconSets" *ngIf="iconSetDetails.alternatives.length > 0">

        <h5>{{'iconSets.alternativesTitle' | translate}}</h5>
        <hr>
        <div class="iconSet-item" (click)="routeIconSetDetails(iconSet.id)" *ngFor="let iconSet of iconSetDetails.alternatives">
            <div class="iconSet-img-container">
                <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/'+iconSet.iconSetCode+'/icon/default'" />
            </div>
            <div class="iconSet-info">
                <b class="iconSet-iconName">{{iconSet.name}}</b>
                <font class="iconSet-iconCode">{{iconSet.iconSetCode}}</font>
            </div>
        </div>
    </div>
</div>
