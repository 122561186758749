import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { LocaleService } from '../../../services/locale/locale.service';
import { CustomTableMainModel } from '../../../models/CustomTableModels/customTableMainModel';

@Component({
  selector: 'lib-locales-table',
  templateUrl: './locales-table.component.html',
  styleUrls: ['./locales-table.component.css']
})
export class LocalesTableComponent implements OnInit {
  tableUrl:string;
  tableData:CustomTableMainModel;
  constructor(private apiUrlService:ApiUrlService,private localeService:LocaleService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/locale/list";
    
  }

}
