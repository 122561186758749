import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItems } from '../../models/menu/menuItems';

@Injectable({
  providedIn: 'root'
})
export class CustomMenuService {
  dataUrl:string = "";
  constructor(private httpClient:HttpClient) { }
  getMenu():Observable<MenuItems[]>{
    return this.httpClient.get<MenuItems[]>(this.dataUrl);
  }

}
