import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { AddSubsectionToSectionRequestModel } from '../../../models/Education/addSubsectionToSectionRequestModel';
import { TranslateService } from '@ngx-translate/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';

@Component({
  selector: 'lib-add-subsection-to-section',
  templateUrl: './add-subsection-to-section.component.html',
  styleUrls: ['./add-subsection-to-section.component.css']
})
export class AddSubsectionToSectionComponent implements OnInit {
  sectionId:string;
  versionId:string;
  loading:boolean = false;
  sectionsList:ListItemModel[];
  constructor(private translateService:TranslateService ,private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getEducationAndSectionId();
  }
  getList(){
    this.educationService.getSectionListItem(this.versionId).subscribe({
      next:(response)=>{
        console.log("resp")
        console.log(response.content)
        this.sectionsList = response.content;
      }
    })
  }
  getEducationAndSectionId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.versionId = param["versionId"];
        this.getList();
      }
    })
  }
  getOptionsVal(){
    var opt = 0;
    var checks = document.getElementsByClassName("optionsClass")
    for (let index = 0; index < checks.length; index++) {
      const element = checks[index] as HTMLInputElement;
      if(element.checked){
        opt+=parseInt(element.value);
      }
      
    }
    return opt;
  }
  addSection(){
    var sectionName = (document.getElementById("sectionName") as HTMLInputElement).value;
    var sectionDescription = (document.getElementById("sectionDescription") as HTMLInputElement).value;
    var preIcon = (document.getElementById("preIcon") as HTMLSelectElement).value;
    var orderValue = parseInt((document.getElementById("orderValue") as HTMLSelectElement).value);
    var status = parseInt((document.getElementById("status") as HTMLSelectElement).value);
    var parentId = (document.getElementById("parentId") as HTMLSelectElement).value;
    if(sectionName.trim() == ""){
      this.toastrService.error(this.translateService.instant("courseManagement.courseNameCannotBeEmpty"));
      throw "Hata";
    }
    var addSectionReq:AddSubsectionToSectionRequestModel = {
      parentId: parentId == "null" ? undefined : parentId,
      versionId: this.versionId,
      name: sectionName,
      icon: parseInt(preIcon),
      options: this.getOptionsVal(),
      status: status,
      orderValue: orderValue
    }
    this.loading = true;
    this.educationService.addSubsectionToSection(this.versionId,addSectionReq).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("courseManagement.sectionAdded"));
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("courseManagement.sectionAddError"));
      }
    })
  }

}
