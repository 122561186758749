import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { DynamicFormToJsonElementTypesModel } from '../../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { VirtualLabModel } from '../../../models/VirtualLabModels/virtualLabModel';
import { VirtualLabNetwork, VirtualLabNetworkSpec } from '../../../models/VirtualLabModels/createVirtualLabModel';
import { UpdateVirtualLabModel } from '../../../models/VirtualLabModels/updateVirtualLabModel';
import { InputFormats } from '../../../models/Enums/inputFormatsEnum';
import { VirtualLabUpdateData } from '../../../models/VirtualLabModels/virtualLabUpdateData';

@Component({
  selector: 'lib-update-lab',
  templateUrl: './update-lab.component.html',
  styleUrls: ['./update-lab.component.css']
})
export class UpdateLabComponent implements OnInit {
  validNetworks:boolean;
  networks:any[] = [];
  networkSelect:DynamicFormToJsonElementTypesModel[] = [];
  labId:string;
  labInfo:VirtualLabUpdateData;
  networkDyn:DynamicFormToJsonElementTypesModel[] = [];
  descriptionFormat:number = 0;
  inputFormats:InputFormats[] = [InputFormats.PlainText]
  descriptionValue:any;
  loading:boolean = false;
  defaultDescriptionValue:string;
  defaultDescriptionFormatValue:number;
  constructor(private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(params)=>{
        this.labId = params["labId"];
        this.getLabInfo();
        this.createNetworkDynamicFormSelect();
        //this.getProviders();
      }
    })

  }
  getLabInfo(){
    this.virtualLabService.getVirtualLabUpdateData(this.labId).subscribe({
      next:(response)=>{
        this.defaultDescriptionValue = response.content.description;
        this.inputFormats = [response.content.descriptionFormat];
        this.descriptionFormat = response.content.descriptionFormat;
        this.defaultDescriptionFormatValue = response.content.descriptionFormat;
        this.labInfo = response.content;

        setTimeout(()=>{
          this.addNetworksToForm();
        },500)

      },
      error:()=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  changeDescriptionFormat($event:any){

    var value = $event.target.value;
    this.inputFormats = [parseInt(value)];
    this.descriptionFormat = parseInt(value);
  }
  descriptionValueFnc($event:any){
    this.descriptionValue = $event;
  }
  updateLab(){
    if(!this.validNetworks){
      this.toastrService.error("Ağ verilerini kontrol edin");
      return;
    }
    var name = document.getElementById("name") as HTMLInputElement;
    var tags = document.getElementById("tags") as HTMLInputElement;
    var code = document.getElementById("code") as HTMLInputElement;
    var isActive = document.getElementById("active") as HTMLInputElement;
    var networkList:VirtualLabNetwork[] = [];
    this.networks.forEach(network=>{
      var spec:VirtualLabNetworkSpec = {
        networkAddress: network.networkAddress,
        subnetMask: network.subnetMask,
        dhcpEnabled: network.dhcpEnabled,
        dhcpStart: network.dhcpStart,
        dhcpEnd: network.dhcpEnd
      }
      var netModel:VirtualLabNetwork = {
        identifier: network.identifier,
        name: network.name,
        networkSpec: spec,
        shared:network.shared
      }
      
      networkList.push(netModel);
    })
    var vlabModel:UpdateVirtualLabModel = {
      id:this.labInfo.id,
      name: name.value,
      tags: tags.value,
      active: isActive.checked,
      networks: networkList,
      code: code.value,
      description: this.descriptionValue(),
      descriptionFormat: this.descriptionFormat

    }
    console.log(vlabModel);
    this.virtualLabService.updateLab(this.labId,vlabModel).subscribe({
      next:(response)=>{
        this.toastrService.success("Lab güncellendi");
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu")
      }
    })
  }
  addNetworksToForm(){
    var forms:DynamicFormToJsonElementTypesModel[] = [];
    this.labInfo.networks.forEach(network=>{
      forms.push({
        typeName: 'network_'+network.identifier,
        typeText: 'Yeni Ağ Ekle',
        element: [
          {name:"identifier",valueType:"string",label:"Tanımlayıcı Ad",pattern:"^[A-Za-z0-9]*$",defaultValue:network.identifier},
          {name:"name",valueType:"string",label:"Ağ Adı",defaultValue:network.name},
          {name:"shared",valueType:"boolean",label:"Paylaşılan",defaultValue:network.shared ? "true" : "false"},
          {name:"networkAddress",valueType:"string",defaultValue:network.networkSpec.networkAddress,label:"Network Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
          {name:"subnetMask",valueType:"string",label:"Alt Ağ Maskesi",defaultValue:network.networkSpec.subnetMask},
          {name:"dhcpEnabled",valueType:"boolean",label:"DHCP Aktif",defaultValue:network.networkSpec.dhcpEnabled ? "true" : "false"},
          {name:"dhcpStart",valueType:"string",defaultValue:network.networkSpec.dhcpStart,label:"DHCP Başlangıç Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
          {name:"dhcpEnd",valueType:"string",defaultValue:network.networkSpec.dhcpEnd,label:"DHCP Bitiş Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
        ]
      })
    })
    this.networkDyn = forms;
  }
  getValidation(ev:boolean){
    this.validNetworks = ev;
  }
  createNetworkDynamicFormSelect(){
    var item:DynamicFormToJsonElementTypesModel = {
      typeName: 'network',
      typeText: 'Yeni Ağ Ekle',
      element: [
        {name:"identifier",valueType:"string",label:"Tanımlayıcı Ad",pattern:"^[A-Za-z0-9]*$"},
        {name:"name",valueType:"string",label:"Ağ Adı"},
        {name:"shared",valueType:"boolean",label:"Paylaşılan"},
        {name:"networkAddress",valueType:"string",label:"Network Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
        {name:"subnetMask",valueType:"string",label:"Alt Ağ Maskesi"},
        {name:"dhcpEnabled",valueType:"boolean",label:"DHCP Aktif"},
        {name:"dhcpStart",valueType:"string",label:"DHCP Başlangıç Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
        {name:"dhcpEnd",valueType:"string",label:"DHCP Bitiş Adresi",pattern:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"},
      ]
    }
    this.networkSelect.push(item);
  }
  saveNetwork(ev:any){
    this.networks = JSON.parse(ev);
  }

}
