import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lib-connected-from-other',
  templateUrl: './connected-from-other.component.html',
  styleUrls: ['./connected-from-other.component.css']
})
export class ConnectedFromOtherComponent implements OnInit {
  session:string;
  constructor(private router:Router,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(response)=>{

       this.session = response['sessionId'];
      }
    })
  }
  goToSession(){
    this.router.navigate(['/session/'+this.session]);
  }

}
