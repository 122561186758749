<form class="formMain form-default-border form-maroon-border" [formGroup]="categoryForm">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.addNewCategory' | translate}}</font>
    </div>
    <label class="title mainLabel" for="parentId">{{'courseManagement.parentCategory' | translate}}</label>
    <select class="form-select" [value]="''" id="parentId" name="parentId" formControlName="parentId">
        <option [value]="null" selected="true">{{'courseManagement.noSelection' | translate}}</option>
        <option *ngFor="let category of pCategoryList" [value]="category.value">{{category.label}}</option>
    </select>
    <br /><br />
    <label class="title mainLabel" for="name">{{'courseManagement.categoryName' | translate}}</label> <input class="form-control form-control"
        id="name" formControlName="name" type="text" [placeholder]="'courseManagement.categoryName' | translate"
        aria-label="Kategori adı">
    <br />
    <label class="title mainLabel" for="description">{{'courseManagement.categoryDescription' | translate}}</label> <textarea class="form-control form-control"
        id="description" formControlName="description" type="text" [placeholder]="'courseManagement.categoryDescription' | translate"
        aria-label="Kategori adı"></textarea>
    <br />
    <label for="active" class="mainLabel"><input type="checkbox" id="isActive" name="active" formControlName="active" />
        {{'sessionManagement.active' | translate}}</label>
    <br /><br />
    <label class="title mainLabel" for="color">{{'courseManagement.categoryColor' | translate}}</label><br /><input type="color" id="color" name="color"
        formControlName="color">
    <br /><br />
    <label class="title mainLabel" for="options">{{'courseManagement.options' | translate}}</label>
    <br />
    <label *ngFor="let option of options" style="display: block;" class="mainLabel"><input type="checkbox" formControlName="options"
            name="options" id="options" [value]="option.value">{{option.name}}</label>
    <br />
    <label class="title mainLabel" for="sortValue">{{'courseManagement.priority' | translate}}</label>
    <input class="form-control" type="number" id="orderValue" formControlName="orderValue" name="orderValue" [value]="0" />
    <br/>
    <div style="text-align: right;">
        <button class="btn btn-primary btn-sm" [disabled]="loading" (click)="addEducationCategory()">{{'btn.add' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
    </div>
</form>

