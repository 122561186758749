<h4>Format String Details</h4>
<hr>
<div *ngIf="formatString">
    <div class="mb-3">
        <b>Format Identifier : </b><font>{{formatString.formatIdentifier}}</font>
    </div>
    <div class="mb-3">
        <b>Format String : </b><font>{{formatString.formatString}}</font>
    </div>
    <div class="mb-3">
        <b>DataType : </b><font>{{formatString.dataType}}</font>
    </div>
    <div class="mb-3">
        <b>Locale : </b><font>{{formatString.locale}}</font>
    </div>
    <div class="mb-3">
        <b>Application : </b><font>{{formatString.application}}</font>
    </div>
    <div class="mb-3">
        <b>Tenant : </b><font>{{formatString.tenant}}</font>
    </div>
    <div class="mb-3">
        <b>Active : </b><font>{{formatString.active}}</font>
    </div>
</div>
