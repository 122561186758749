import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-update-icon-set',
  templateUrl: './update-icon-set.component.html',
  styleUrls: ['./update-icon-set.component.css']
})
export class UpdateIconSetComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = environment.apiUrl + "api/cms/iconsets/"+param["setId"]+"/update"
      }
    })
  }

}
