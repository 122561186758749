import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class RangeSocketService {
  private hubConnection: SignalR.HubConnection;
  constructor() { }
  private startConnection() {
    return new Promise<boolean>((resolve, reject) => {
      var token = (sessionStorage.getItem("token") != null ? sessionStorage.getItem("token") : localStorage.getItem("token")) ?? "";
      if (token) {

        this.hubConnection = new SignalR.HubConnectionBuilder().withUrl("/vms", { accessTokenFactory: () => token }).build();
        this.hubConnection.start().then(x => { window["rangesocket"] = this.hubConnection; resolve(true);
        }).catch(x => { window["rangesocket"] = null; resolve(false); });
      }
    })
  }
  public getHub(): Promise<SignalR.HubConnection> {
    return new Promise<SignalR.HubConnection>((resolve, reject) => {
      if (window["rangesocket"] === undefined || window["rangesocket"] == null) {
        this.startConnection().then(x=>{
          if(x==true){
            resolve(window["rangesocket"]);
          }else{
            throw "Websocket Error"
          }
        })
      } else {
        resolve(window["rangesocket"]);
      }
    })

  }
  public resetHub() {
    return new Promise<boolean>((resolve,reject)=>{
      try {
        if(window["rangesocket"] != null){
          var socketHub = (window["rangesocket"] as SignalR.HubConnection)
          if(socketHub.state == SignalR.HubConnectionState.Connected){
            socketHub.stop();
            window["rangesocket"] = null;
          }
        }
      }catch{
        window["rangesocket"] = null;
      }
      this.startConnection().then(x=>{
        resolve(x);
      }).catch(x=>resolve(false));
    })


  }
}
