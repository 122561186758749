import { Component, Input, OnInit } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { SessionMemberItem } from '../../../models/SessionApiModels/sessionMemberItem';
import { ToastrService } from 'ngx-toastr';
import { SessionMemberStatusEnum } from '../../../models/Enums/sessionMemberStatus';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-session-members',
  templateUrl: './session-management-session-members.component.html',
  styleUrls: ['./session-management-session-members.component.css']
})
export class SessionManagementSessionMembersComponent implements OnInit {
  members:SessionMemberItem[];
  @Input() sessionId:string;
  constructor(private translateService:TranslateService, private globalModalService:GlobalModalService,private sessionApiService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getMembers();
  }
  getMembers(){
    this.sessionApiService.getMembersInSession(this.sessionId).subscribe({
      next:(response)=>{
        this.members = response.content;
      },error:(()=>{
        this.toastrService.error("Oturumda bulunan katılımcılar alınırken hata oluştu");
      })
    })
  }
  getStatusText(status:SessionMemberStatusEnum){
    switch(status){
      case SessionMemberStatusEnum.Offline:
        return "Çevrimdışı";
        break;
      case SessionMemberStatusEnum.Away:
        return "Uzakta";
        break;
      case SessionMemberStatusEnum.Idle:
        return "Boşta";  
        break;
      case SessionMemberStatusEnum.DND:
        return "Rahatsız Etmeyin";
        break;
      case SessionMemberStatusEnum.Online:
        return "Çevrimiçi";
        break;
    }
  }
  getStatusClass(status:SessionMemberStatusEnum){
    switch(status){
      case SessionMemberStatusEnum.Offline:
        return "offline";
        break;
      case SessionMemberStatusEnum.Away:
        return "away";
        break;
      case SessionMemberStatusEnum.Idle:
        return "idle";  
        break;
      case SessionMemberStatusEnum.DND:
        return "dnd";
        break;
      case SessionMemberStatusEnum.Online:
        return "online";
        break;
    }
  }
  addNewMember(){

      var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:this.translateService.instant('sessionManagement.sessionMember.btn.addNewMemberToSession'),data:`/modal/session/${this.sessionId}/addMember`,helperModals:[]};
      this.globalModalService.showModal(modalCreate);

  }
  removeSessionMember(userId:number){
    var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:this.translateService.instant('sessionManagement.sessionMember.btn.removeMemberFromSession'),data:`/modal/session/${this.sessionId}/member/${userId}/remove`,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }

}
