<div class="mb-3">
    <label for="providerType" class="form-label">Provider Type</label>
    <select (change)="changeProvider()" id="providerType" class="form-select"
        aria-label="Default select example">
        <option *ngFor="let provider of providers" [value]="provider.value">{{provider.label}}</option>
    </select>
    <label for="hostId" class="form-label">Host</label>
    <select (change)="changeHost()" id="hostId" class="form-select" aria-label="Default select example">
        <option value=undefined>Not Selected</option>
        <option *ngFor="let host of hosts" [value]="host.value">{{host.label}}</option>
    </select>
</div>
<div class="mb-3">
    <label for="name" class="form-label">Sanal Makine Adı</label>
    <input type="text" class="form-control" id="name">
</div>
<div class="mb-3" *ngIf="envParam == undefined">
    <label for="vmenvironment" class="form-label">Sanal Makine Çalıştırma Ortamı</label>
    <select id="vmenvironment" (change)="changeSelectedEnvironment()" class="form-select"
        aria-label="Default select example">
        <option *ngFor="let env of vmenvironments" [value]="env.value">{{env.label}}</option>
    </select>
</div>
<div *ngIf="loading && imgParam == undefined">
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>
<div class="mb-3"  *ngIf="showImage && imgParam == undefined">
    <label for="vmimage" class="form-label">İmaj Seç</label>
    <select id="vmimage" class="form-select" aria-label="Default select example">
        <option *ngFor="let img of vmimages" [value]="img.value">{{img.label}}</option>
    </select>
</div>
<div class="form-check form-switch">
    <input [disabled]="allowCustomSelect == false" (change)="changeCustomSelectSwitch($event)" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
    <label class="form-check-label" for="flexSwitchCheckDefault">Custom RAM & CPU Count</label>
  </div>
<div class="mb-3" [class.displayNone]="showSelects == false || customSelect == true">
    <label for="instanceType" class="form-label">Instance Type</label>
    <select (change)="changeInstanceType()" id="instanceType" class="form-select"
        aria-label="Default select example">
        <option *ngFor="let instanceType of instanceTypes" [value]="instanceType.value">{{instanceType.label}}
        </option>
    </select>
</div>
<div class="mb-3" [class.displayNone]="showSelects == false || customSelect == false">
    <label for="ram" class="form-label">Ram</label>
    <select id="ram" class="form-select" aria-label="Default select example">
        <option *ngFor="let ram of ramList" [value]="ram.value">{{ram.label}}</option>
    </select>
</div>

<div class="mb-3" [class.displayNone]="showSelects == false || customSelect == false">
    <label for="cpuCount" class="form-label">İşlemci Sayısı</label>
    <select id="cpuCount" class="form-select" aria-label="Default select example">
        <option *ngFor="let cpu of cpuList" [value]="cpu.value">{{cpu.label}}</option>
    </select>
</div>
<h5>Diskler</h5>
<lib-dynamic-form-to-json *ngIf="elementList" (dataChanges)="saveDisks($event)" [autoSave]="true"
    [formElements]="elementList">

</lib-dynamic-form-to-json>
<hr>
<h5>Ağ Kartları</h5>
<lib-dynamic-form-to-json *ngIf="nicElementList" (dataChanges)="saveNic($event)" [autoSave]="true"
    [formElements]="nicElementList">

</lib-dynamic-form-to-json>
<br/>
<button class="btn btn-primary btn-sm" (click)="addVm()">Ekle</button>