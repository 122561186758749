import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { RoleDetailsModel } from 'projects/usorta-library/src/lib/models/AccessControl/roleDetailsModel';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';

@Component({
  selector: 'lib-service-role-details',
  templateUrl: './service-role-details.component.html',
  styleUrls: ['./service-role-details.component.css']
})
export class ServiceRoleDetailsComponent implements OnInit {

  roleId:string;
  details:RoleDetailsModel
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private accessControlService:AccessControlService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.roleId = param["id"];
        this.tableUrl = environment.apiUrl + "api/acl/service-accounts/roles/"+this.roleId+"/users"
        this.accessControlService.serviceAccountRoleDetails(this.roleId).subscribe({
          next:(response)=>{
            this.details = response.content;
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }
    })
  }

}
