import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageCreateNoteRequest } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageCreateNoteRequest';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
declare var $:any;
@Component({
  selector: 'lib-create-base-vm-image-note',
  templateUrl: './create-base-vm-image-note.component.html',
  styleUrls: ['./create-base-vm-image-note.component.css']
})

export class CreateBaseVmImageNoteComponent implements OnInit {
  baseImageId:string;
  addNoteRequest:BaseVmImageCreateNoteRequest;
  constructor(private activatedRoute:ActivatedRoute,private baseVmImageService:BaseVmImageService,private translateService:TranslateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseImageId = param["vmimgid"];
        this.runSummernote();
      }
    })
  }
  runSummernote(){
    $(document).ready(function() {
      $('#summernote').summernote({
        height:"250px"
      });
    });
  }
  createNote(){
    var titleEl = document.getElementById("note-title") as HTMLInputElement;
    var title = titleEl.value;
    var content = $('#summernote').summernote('code');
    if(content == null || content == ""){
      this.toastrService.error(this.translateService.instant("baseVmImage.note.contentCannotBeEmpty"));
      return;
    }
    this.addNoteRequest = {
      content:content,
      vmImageId:this.baseImageId,
      title:title
    };
    this.baseVmImageService.createNote(this.addNoteRequest).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("baseVmImage.note.addedSuccessfully"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    });
  }

}
