import { Component, OnInit } from '@angular/core';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-delete-feature',
  templateUrl: './session-management-delete-feature.component.html',
  styleUrls: ['./session-management-delete-feature.component.css']
})
export class SessionManagementDeleteFeatureComponent implements OnInit {

  checkedUnderstandCheck:boolean = false;
  featureId:number | null;
  sessionId:string | null;
  success:boolean = false;
  constructor(private translateService:TranslateService ,private activatedRoute:ActivatedRoute,private sessionService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.featureId = param['featureId'];
      this.sessionId = param['sessionId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  
  deleteFeatureFromSession(){
    if(this.featureId != null && this.sessionId != null){
      this.sessionService.deleteFeatureFromSession(this.sessionId,this.featureId).subscribe({
        next:(response)=>{
          if(response.success){
            var successMessage = this.translateService.instant("sessionManagement.featureRemoved");
            this.toastrService.success(successMessage);
            this.success = true;
          }else{
            var errorMessage = this.translateService.instant("sessionManagement.featureRemovedError");
            this.toastrService.error(errorMessage);
          }
        }
      })
    }else{
      var errorMessage = this.translateService.instant("sessionManagement.featureRemovedError");
      this.toastrService.error(errorMessage);
    }

  }

}
