import { Component, OnInit } from '@angular/core';
import { AdminManagerService } from '../../../services/admin-manager.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-admin',
  templateUrl: './delete-admin.component.html',
  styleUrls: ['./delete-admin.component.css']
})
export class DeleteAdminComponent implements OnInit {

  username:string = "";
  checkedUnderstandCheck:boolean = false;
  selectedUser:any;
  userId:string;
  constructor(private adminManagementService:AdminManagerService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }
  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param['userId'];
        this.getUserInfo();
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteUser(){
    this.adminManagementService.deleteUserById(this.userId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Kullanıcı silindi");
        }else{
          this.toastrService.error("Kullanıcı silinemedi");
        }
      }
    })
  }
  getUserInfo(){
    this.adminManagementService.getUserById(this.userId).subscribe({
      next:(response)=>{

        this.selectedUser = response.content;
      }
    })
  }

}
