<div class="rtcButtons">
  <!--<button class="btn btn-sm btn-info liveBtn" (click)="senderInitVideo()" *ngIf="sender == false"><i class="bi bi-collection-play-fill"></i> {{"startStream" | translate}}</button>
  <button class="btn btn-sm btn-danger liveBtn" (click)="stopSending()" *ngIf="sender == true"><i class="bi bi-collection-play-fill"></i> {{"stopStream" | translate}}</button>
  <button class="btn btn-sm btn-secondary liveBtn" (click)="setScreenStream()" *ngIf="sender == true && screenMediaId == ''"><i class="bi bi-display"></i> {{"startScreenSharing" | translate}}</button>
  <button class="btn btn-sm btn-warning liveBtn" (click)="stopScreenStream()" *ngIf="sender == true && screenMediaId != ''"><i class="bi bi-display"></i> {{"stopScreenSharing" | translate}}</button>-->
  
  <i (click)="senderInitVideo()" *ngIf="sender == false" class="bi bi-broadcast rtcActionIcon startStreamIcon" [title]="'startStream' | translate"></i>
  <i (click)="stopSending()" *ngIf="sender == true" class="bi bi-broadcast rtcActionIcon stopStreamIcon" [title]="'stopStream' | translate"></i>
  
  <i *ngIf="sender" (click)="toggleMicrophone()" [class.greenColor]="microphoneEnabled" [class.redColor]="!microphoneEnabled" [title]="microphoneEnabled? 'Mikrofonu kapat' : 'Mikrofonu Aç'" class="bi bi-mic-fill rtcActionIcon"></i>
  <i *ngIf="sender" (click)="toggleVideo()" [class.greenColor]="cameraEnabled" [class.redColor]="!cameraEnabled" [title]="cameraEnabled ? 'Kamerayı kapat' : 'Kamerayı Aç'" class="bi bi-camera-video-fill rtcActionIcon"></i>

  <i (click)="setScreenStream()" *ngIf="sender == true && screenMediaId == ''" class="bi bi-display rtcActionIcon startScreenSharingIcon" [title]="'startScreenSharing' | translate"></i>
  <i (click)="stopScreenStream()" *ngIf="sender == true && screenMediaId != ''" class="bi bi-display rtcActionIcon stopScreenSharingIcon" [title]="'stopScreenSharing' | translate"></i>
  <!--<button class="btn btn-sm btn-warning liveBtn" (click)="peerControl()" ><i class="bi bi-display"></i> PeerCtrl</button>-->
  </div>
<div *ngIf="status == false && sender==false" style="width: 100%; position: relative;">
<img src="/assets/images/videoicon.jpg" style="width:100%; border-radius: 20px;">

</div>

<div style="width: 100%; position: relative;">
  <div style="position: relative;">
  <video class="senderVideoEl" id="senderVideo" autoplay style="width: 100%;" *ngIf="sender==true"></video>
  <!--<i *ngIf="sender" (click)="toggleMicrophone()" [class.greenColor]="microphoneEnabled" [class.redColor]="!microphoneEnabled" class="bi bi-mic-fill" style="position:absolute; right:10px; bottom:5px; font-size:20px;"></i>
  <i *ngIf="sender" (click)="toggleVideo()" [class.greenColor]="cameraEnabled" [class.redColor]="!cameraEnabled" class="bi bi-camera-video-fill" style="position:absolute; right:45px; bottom:5px; font-size:20px;"></i>-->
  </div>
  <div class="videoList">

</div>
</div>
<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="rtcModal" tabindex="-1" aria-labelledby="rtcModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rtcModalLabel">WebRTC Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        {{'webRtcWaitClickModal' | translate}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'close' | translate}}</button>
      </div>
    </div>
  </div>
</div>
