import { TenantListenerService } from './../../services/listener/tenant-listener.service';
import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../services/tenant/tenant.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css']
})
export class PreloaderComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;
  constructor(private tenantListenerService: TenantListenerService, private tenantService: TenantService) { }
  subscribe: any;
  ngOnInit(): void {
    this.tenantService.getAppsettings(environment.applicationId,environment.tenantId ?? undefined).subscribe({
      next: (response) => {
        localStorage.setItem("tenantInfo", JSON.stringify(response.content));
        this.change();
      }, error: (err) => {
        this.loading = false;
        this.error = true;
      }
    })
  }
  change() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
    this.subscribe = this.tenantListenerService.getTenantStatus('true').subscribe();
  }

}
