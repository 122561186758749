import { MermaidAPI } from 'ngx-markdown';
import { StaticContentService } from './../../services/staticContent/static-content.service';
import { Component, OnInit, Input, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import mermaid from 'mermaid'
import clipboardJS from 'clipboard'

@Component({
  selector: 'lib-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css'],
})
export class StaticContentComponent implements OnInit {
  constructor(private staticContentService: StaticContentService,private sanitizer:DomSanitizer) {}
  @Input() requestPage: string;
  content: string | null;
  contentType: string | null;
  allowedTags: string[] = ['a', 'p','div', 'iframe',',html','body','head','h1','h2','h3','h4','br','center','script'];
  markdown:string |undefined;
  //Başlık Yapıları
  pageTitle:string|null;
  pageLastUpdate:string|null;
  pageAuthor:string|null;
  public options: MermaidAPI.Config = {
    fontFamily: '"trebuchet ms", verdana, arial, sans-serif',
    logLevel: MermaidAPI.LogLevel.Info,
    theme: MermaidAPI.Theme.Dark,
    gantt: { axisFormat: '%m/%d/%Y' },
  };
  ngOnInit(): void {
    this.getContent();
  }
  getContent() {
    this.staticContentService
      .getContent(this.requestPage)
      .subscribe((response) => {
        this.contentType = response.headers.get('Content-Type');
        this.pageTitle = response.headers.get('X-Page-Title');
        if(this.contentType == 'text/html'){
          this.content = this.sanitizeHtml(response.body);
        }else if(this.contentType == 'text/plain'){
          this.content = this.toPlainText(response.body);
        } else if(this.contentType == 'text/markdown'){
          this.markdown = this.sanitizeHtml(response.body);
        }
      });
      if(this.pageTitle){
        document.title = this.pageTitle;
      }
  }

  toPlainText(responseText:string |null):string{
    return responseText ? responseText : '';
  }
  sanitizeHtml(htmlContent: string | null):string {
    let sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, htmlContent);
    sanitizedContent = this.removeNotAllowedTags(sanitizedContent ? sanitizedContent : '');
    return sanitizedContent ? sanitizedContent : '';
  }
  removeNotAllowedTags(html: string) {
    let tags = this.allowedTags;
    let div = document.createElement('div');
    div.innerHTML = html;
    div.querySelectorAll('*').forEach((element) => {
      if (!tags.includes(element.tagName.toLowerCase())) {
        element.remove();
      }
      console.log(element.tagName);
    });
    return div.innerHTML;
  }
}
