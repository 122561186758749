import { Component, OnInit } from '@angular/core';
import { CreateHostProviderTypeRequestModel } from '../../../models/HostProviderModels/createHostProviderTypeRequest';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ToastrService } from 'ngx-toastr';
import { MonacoService } from '../../../services/monacoService/monaco.service';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '../../../services/formService/forms.service';
declare var $:any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-create-host-provider-type',
  templateUrl: './create-host-provider-type.component.html',
  styleUrls: ['./create-host-provider-type.component.css']
})
export class CreateHostProviderTypeComponent implements OnInit {

  constructor(private formsService:FormsService,private translateService:TranslateService,private hostProviderService:HostProviderService,private toastrService:ToastrService,private monacoService:MonacoService) { }
  editorType:string = "json";
  ngOnInit(): void {
    this.initializeDefaultConfigurationEditor();
    console.log(monaco.languages.getLanguages())
  }
  changeEditorLanguage(lang:string){
    this.editorType = lang;
    monaco.editor.setModelLanguage(window["defaultConfigurationEditor"].getModel(),lang);
  }
  initializeDefaultConfigurationEditor(){
    this.monacoService.disposeAllModels();
    console.log(monaco.editor.getModels());
    var defaultConfiguration = document.getElementsByClassName("defaultConfigurationMonaco")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(defaultConfiguration,{
        language:"json",
        minimap:{enabled:false}
      })
      window["defaultConfigurationEditor"] = editor;
    }
  }
  checkErrors(){
   var def = window["defaultConfigurationEditor"].getValue()
    if(def == undefined || def == ""){
      return true;
    }
    switch(this.editorType){
      case "json":
          try {
            var removedRN = this.monacoService.jsonString(def);
            return true;
          }catch{
            this.toastrService.error(this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidJson"));
            throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidJson");
          }
        break;
      case "yaml":
          return true;
        break;
      case "xml":
        try{
          var parser = new DOMParser();
          const doc = parser.parseFromString(def, "application/xml");
          const errorNode = doc.querySelector("parsererror");
          if (errorNode) {
            this.toastrService.error(this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml"));
            throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml");
          }
          return true;
        }catch{
          throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml");
        }

        
        break;
    }
    return false;

  }

  createHostProvider(){
    var name = (document.getElementById("name") as HTMLInputElement).value;
    var serviceClassName = (document.getElementById("serviceClassName") as HTMLInputElement).value;
    var enabled = (document.getElementById("enabled") as HTMLInputElement).checked;
    var description = (document.getElementById("description") as HTMLInputElement).value;
    var defaultConfiguration = window["defaultConfigurationEditor"].getValue();
    this.checkErrors();
    var defaultConfigurationValue = "";
    switch(this.editorType){
      case "json":
        defaultConfigurationValue = this.monacoService.jsonString(defaultConfiguration);
        break;
      case "yaml":
        defaultConfigurationValue = this.monacoService.newlineOnlyN(defaultConfiguration);
        break;
      case "xml":
        defaultConfigurationValue = this.monacoService.newlineOnlyN(defaultConfiguration);
        break;
    }
    console.log(defaultConfigurationValue);
    var createModel:CreateHostProviderTypeRequestModel = {
      enabled:enabled,
      name:name,
      serviceClassName:serviceClassName,
      defaultConfiguration:defaultConfigurationValue,
      description:description
    }
    this.formsService.formValidate(createModel,["name","serviceClassName"]);
    this.hostProviderService.createHostProviderType(createModel).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("global.createdSuccessfully"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
