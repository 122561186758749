import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-delete-base-vm-image-info-revision',
  templateUrl: './delete-base-vm-image-info-revision.component.html',
  styleUrls: ['./delete-base-vm-image-info-revision.component.css']
})
export class DeleteBaseVmImageInfoRevisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
