<div class="toolbar-container">
    <div class="area-title">{{'outlineItemPreviewPanel.area.toolbarTitle' | translate}}</div>
    <div class="toolbar">
        <div class="toolbar-button" [class.disabled]="currentPreview == null || currentPreview == ''"
            (click)="addNewContent()"><i class="bi bi-plus-lg"></i></div>
    </div>
</div>
<h4 style="text-align: left;" *ngIf="outlineItemDetails && outlineItemDetails.name">
    &nbsp;&nbsp;{{outlineItemDetails.name}}</h4>
<hr>
<div *ngIf="loading == true" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div class="content-preview-div previewContainer" *ngIf="contentView && loading == false">
    <div class="preview-item" *ngFor="let item of contentView.contents">
        <div class="move-content-container">
            <div class="move-content-item">
                <button class="btn btn-sm btn-secondary" (click)="sendToTop(item.id)"><i
                        class="bi bi-arrow-up"></i></button>
            </div>
            <div class="move-content-item">
                <button class="btn btn-sm btn-secondary" (click)="sendToBottom(item.id)"><i
                        class="bi bi-arrow-down"></i></button>
            </div>
        </div>
        <div *ngIf="item.type == 0" [draggable]="true" [class.selected]="selectedContentId == item.id"
            (click)="selectContent(item.id)" class="preview-htmlContent preview-baseContent">
            <div [innerHTML]="item.data"></div>
        </div>

        <div *ngIf="item.type == 1" [class.selected]="selectedContentId == item.id"
            class="preview-fileUploadContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 2" [class.selected]="selectedContentId == item.id"
            class="preview-examContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 3" [class.selected]="selectedContentId == item.id"
            class="preview-surveyContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 4" [class.selected]="selectedContentId == item.id"
            class="preview-questionContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 5" [class.selected]="selectedContentId == item.id"
            class="preview-randomQuestionContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 6" (click)="selectContent(item.id)" [class.selected]="selectedContentId == item.id"
            class="preview-mediaContent preview-baseContent">
            <lib-outline-item-media-content-preview [contentId]="item.id"></lib-outline-item-media-content-preview>
        </div>
        <div *ngIf="item.type == 7" [class.selected]="selectedContentId == item.id"
            class="preview-slideshowContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 8" [class.selected]="selectedContentId == item.id"
            class="preview-externalToolContent preview-baseContent">

        </div>
        <div *ngIf="item.type == 9" [class.selected]="selectedContentId == item.id"
            class="preview-externalLmsContent preview-baseContent">

        </div>

    </div>

</div>