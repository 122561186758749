import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuItemResponse } from '../../../models/Navigation/menuItemResponse';

@Component({
  selector: 'lib-menu-item-details',
  templateUrl: './menu-item-details.component.html',
  styleUrls: ['./menu-item-details.component.css']
})
export class MenuItemDetailsComponent implements OnInit {
  itemDetails:MenuItemResponse;
  constructor(private navigationService:NavigationService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var id = parseInt(param["menuitemid"]);
        this.navigationService.getMenuItemDetails(id).subscribe({
          next:(response)=>
          {
            this.itemDetails = response.content;
            
          },error:(err)=>
          {
            this.toastrService.error("An error occurred");
          }
        })
      }
    })
  }

}
