import { Component, OnInit } from '@angular/core';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AddCourseThemeModel } from '../../../models/Education/addCourseThemeModel';
declare var $:any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-create-education-theme',
  templateUrl: './create-education-theme.component.html',
  styleUrls: ['./create-education-theme.component.css']
})
export class CreateEducationThemeComponent implements OnInit {
  loading:boolean = false;
  constructor(private translateService:TranslateService, private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.initializeRawStyle();
      this.initializeStyle();
      this.initializeScripts();
      this.initializeTextoverrides();
      this.initializeThemeData();
    },500)
  }
  initializeRawStyle(){
    var rawStyleDiv = document.getElementsByClassName("themeStyleRaw")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(rawStyleDiv,{
        language:"css",
        minimap:{enabled:false}
      })
      window["rawStyle"] = editor;
    }
  }
  initializeStyle(){
    var themeStyle = document.getElementsByClassName("themeStyle")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeStyle,{
        language:"json",
        minimap:{enabled:false}
      })
      window["themeStyle"] = editor;
    }
  }
  initializeScripts(){
    var themeScripts = document.getElementsByClassName("themeScripts")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeScripts,{
        language:"javascript",
        minimap:{enabled:false}
      })
      window["themeScript"] = editor;
    }
  }
  initializeTextoverrides(){
    var textOverrides = document.getElementsByClassName("themeTextOverrides")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(textOverrides,{
        language:"json",
        minimap:{enabled:false}
      })
      window["themeTextoverrides"] = editor;
    }
  }
  initializeThemeData(){
    var themeData = document.getElementsByClassName("themeData")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeData,{
        language:"json",
        minimap:{enabled:false}
      })
      window["themeData"] = editor;
    }
  }
  createEducationTheme(){
    var formData = new FormData();
    var nameVal = (document.getElementById("name") as HTMLInputElement).value;
    var keyVal = (document.getElementById("key") as HTMLInputElement).value;
    var activeVal = (document.getElementById("active") as HTMLInputElement).checked;
    var themeStyleVal = window["themeStyle"].getValue();
    var themeRawStyleVal = window["rawStyle"].getValue();
    var themeScriptVal = window["themeScript"].getValue();
    var themeTextOverridesVal = window["themeTextoverrides"].getValue();
    var themeDataVal = window["themeData"].getValue();
    var files = (document.getElementById("themeFiles") as HTMLInputElement).files;
    /*formData.append("name",nameVal);
    formData.append("key",keyVal);
    formData.append("active",activeVal ? "true" : "false");
    formData.append("styles",themeStyleVal);
    formData.append("rawStyles",themeRawStyleVal);
    formData.append("scripts",themeScriptVal);
    formData.append("textOverrides",themeTextOverridesVal);
    if(files != null){
      for (let index = 0; index < files.length; index++) {
        var file = files[index];
        formData.append("files[]",file);        
      }
    }
    console.log(formData.getAll("name"));
    console.log(formData.getAll("key"));
    console.log(formData.getAll("active"));
    console.log(formData.getAll("styles"));
    console.log(formData.getAll("rawStyles"));
    console.log(formData.getAll("scripts"));
    console.log(formData.getAll("textOverrides"));
    console.log(formData.getAll("files[]")); */
    var addThemeModel:AddCourseThemeModel = {
      name: nameVal,
      themeKey: keyVal,
      active: activeVal,
      styles: themeStyleVal,
      rawStyle: themeRawStyleVal,
      scripts: themeScriptVal,
      textOverrides: themeTextOverridesVal,
      themeData: themeDataVal
    }
    this.loading = true;
    this.educationService.createEducationTheme(addThemeModel).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("educationTheme.addedSuccessful"));
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
