<ul class="mainList">
    <li [title]="item.title" class="mainListItem" *ngFor="let item of courseOutline">
      <i *ngIf="item.type == undefined" class="sectionIcon bi bi-bookmark-fill"></i>
      <i *ngIf="item.type != undefined" [class]="'sectionIcon '+item.type"></i>
      <font class="mainItemText">{{item.title}}</font>
      <hr style="height: 2px; padding: 0px; margin: 3px;" />
      <ul class="subList" *ngIf="item.children != undefined && item.children.length > 0">
        <li [title]="subItem.title" class="subListItem" *ngFor="let subItem of item.children">
          <i *ngIf="subItem.type == undefined" class="sectionIcon bi bi-bookmark"></i>
          <i *ngIf="subItem.type != undefined" [class]="'sectionIcon '+subItem.type"></i>
          <font>{{subItem.title}}</font>
          <hr style="height: 2px; padding: 0px; margin: 3px;" />
        </li>
      </ul>
    </li>
  </ul>