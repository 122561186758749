import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-vm-instance-type-list',
  templateUrl: './vm-instance-type-list.component.html',
  styleUrls: ['./vm-instance-type-list.component.css']
})
export class VmInstanceTypeListComponent implements OnInit {
  apiUrl:string = environment.apiUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
