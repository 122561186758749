let w = window as any;

export const environment = {

  //tenantUrl:w['env']['tenanturl'] || "https://localhosst:5501/api/Tenant/",

  //apiUrl:w['env']['apiurl'] || "https://localhost:5505/gateway/",
  tenantUrl:w['env']['tenanturl'] || "unknown",
  apiUrl:w['env']['apiurl'] || "unknown",
  imageApi:w['env']['imageapi'] || "",
  applicationId:w['env']['appId'] || null,
  tenantId:w['env']['tenantId'] || null,
  production: true
};
