import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-manage-base-vm-image-remote-connection',
  templateUrl: './manage-base-vm-image-remote-connection.component.html',
  styleUrls: ['./manage-base-vm-image-remote-connection.component.css']
})
export class ManageBaseVmImageRemoteConnectionComponent implements OnInit {
  jwt:string;
  vmName:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.jwt = param["jwt"];
        this.vmName = param["vmName"];
      }
    })
  }

}
