<h4>{{'questionOption.details.pagetitle'}}</h4>
<hr>
<div>
<div class="mb-3">
    <b>{{'questionOption.details.value'}} : </b><font>{{details.value}}</font>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.label'}}</b><lib-preview-text [allowHtml]="true" [text]="details.label"></lib-preview-text>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.active'}} : </b><font><input type="checkbox" [checked]="details.active" [disabled]="details.active == false" onclick="return false"/></font>
</div>
<div class="mb-3" *ngIf="details.optionPrefix">
    <b>{{'questionOption.details.optionPrefix'}} : </b><font>{{details.optionPrefix}}</font>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.isCorrect'}} : </b><font>{{details.isCorrect}}</font>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.scoreFactor'}} : </b><font>{{details.scoreFactor}}</font>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.orderValue'}} : </b><font>{{details.orderValue}}</font>
</div>
<div class="mb-3">
    <b>{{'questionOption.details.wrongAnswerInfo'}} : </b><lib-preview-text [allowHtml]="true" [text]="details.wrongAnswerInfo"></lib-preview-text>
</div>


</div>

