import { SendCookiePreferenceItem } from './../../models/CookiePreferences/sendCookiePreferences';
import { CookieManagerService } from './../cookieManager/cookie-manager.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { CookiePreferencesModel } from './../../models/CookiePreferences/getCookiePreferences';
import { ApiUrlService } from './../api/api-url.service';
import { map, observable, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiePreferencesService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService,private loginService:LoginService,private cookieManagerService:CookieManagerService) { }

  cookiePreferencesSaved():Observable<boolean>{
    if(this.loginService.isLoggedControlWithExpiration() == false){
      console.log("notloggedd");
      if(this.cookieManagerService.getCookie("cookieprefs") != null && this.cookieManagerService.getCookie("cookieprefs") != ""){
        return new Observable<boolean>(observer => {
          observer.next(true);
          observer.complete();
        })
      }else{
        return new Observable<boolean>(observer => {
          observer.next(false);
          observer.complete();
        })
      }
    }else{
      let apiUrl = this.apiUrlService.getApiUrl();
      let preferences = this.httpClient.get<CookiePreferencesModel>(apiUrl+'privacy/get-cookie-preferences');
      console.log("getpreferencess");
      return preferences.pipe(map(data=>{
        if(data.preferences_saved == true){
          let val = "";
          data.preferences.forEach(element => {
            val != "" ? val += ",":"";
            val += element.id+":"+element.accepted;
          });
          console.log("Cerezlere eklendi");
          console.log(val);
          this.addPreferencesToCookie(val);
          return true;
        }else{
          if(this.cookieManagerService.getCookie("cookieprefs") != null && this.cookieManagerService.getCookie("cookieprefs") != ""){
            let pf = this.cookiePreferenceParser(this.cookieManagerService.getCookie("cookieprefs"));
            this.saveCookiePreferences(pf).subscribe();
            return true;
          }
          return false;
        }
      }));
    }

    //let apiUrl = this.apiUrlService.getApiUrl();
    //let preferences = this.httpClient.get<CookiePreferencesModel>(apiUrl+'api/privacy/get-cookie-preferences');
  }
  /*acceptAllCookies():Observable<boolean>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<boolean>(apiUrl+'api/privacy/accept-all-cookies');
  }*/
  getCookiePreferences():Observable<CookiePreferencesModel>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<CookiePreferencesModel>(apiUrl+'privacy/get-cookie-preferences');
  }
  saveCookiePreferences(preferences:SendCookiePreferenceItem[]):Observable<boolean>{
    console.log("saved")
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.post<boolean>(apiUrl+'privacy/save-cookie-preferences',preferences);
  }
  cookiePreferenceParser(cookieprefs:string):SendCookiePreferenceItem[]{
    let cookieprefsArray = cookieprefs.split(",");
    let sendArray:SendCookiePreferenceItem[] = [];
    cookieprefsArray.forEach(element => {
      let prefskeyvalue = element.split(":");
      sendArray.push({
        id: prefskeyvalue[0],
        accepted: prefskeyvalue[1] == "true" ? true : false
      });
    }
    );
    return sendArray;
  }
  addPreferencesToCookie(value:string){
    let date:Date = new Date();
    date.setFullYear(date.getFullYear()+1);
    this.cookieManagerService.setCookie({
      name: 'cookieprefs',
      value: value,
      expiration: date
    });
  }

}
