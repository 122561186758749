import { Injectable } from '@angular/core';
import { CreateSessionModel } from '../../models/SessionApiModels/createSessionModel';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { AddSessionFeatureRequest } from '../../models/SessionApiModels/addSessionFeatureRequest';
import { ResponseModel } from '../../models/responseModel';
import { ListResponseModel } from '../../models/listResponseModel';
import { SessionFeatureListItem } from '../../models/SessionApiModels/sessionFeatureListItem';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { AddSessionItemRequest } from '../../models/SessionApiModels/addSessionItemRequest';
import { SessionItemListItemModel } from '../../models/SessionApiModels/sessionItemListItemModel';
import { UpdateSessionRequest } from '../../models/SessionApiModels/updateSessionRequest';
import { CustomForm } from '../../models/CustomFormModels/customForm';
import { SessionMemberItem } from '../../models/SessionApiModels/sessionMemberItem';
import { RemoveMembersRequestModel } from '../../models/SessionApiModels/removeMembersRequestModel';
import { AddSessionMemberRequest } from '../../models/SessionApiModels/addSessionMemberRequest';
@Injectable({
  providedIn: 'root'
})
export class SessionApiService {

  constructor(private httpClient: HttpClient) { }
  createSession(createSessionRequest: CreateSessionModel) {
    //return this.httpClient.post(`${environment.apiUrl}api/v2/sessions/create`, createSessionRequest);
    return this.httpClient.post<SingleResponseModel<string>>(`${environment.apiUrl}api/v2/sessions/create`, createSessionRequest);
  }
  addFeatureToSession(addSessionFeatureRequest: AddSessionFeatureRequest) {
    return this.httpClient.post(`${environment.apiUrl}api/v2/sessions/${addSessionFeatureRequest.sessionId}/addfeature`, addSessionFeatureRequest);
    //return this.httpClient.post(`https://localhost:61140/api/v2/sessions/${addSessionFeatureRequest.sessionId}/addfeature`, addSessionFeatureRequest);
  }
  addItemToSession(addSessionItemRequest:AddSessionItemRequest){
    //return this.httpClient.post(`${environment.apiUrl}api/v2/sessions/${addSessionItemRequest.sessionId}/additem`, addSessionItemRequest);
    return this.httpClient.post<ResponseModel>(`${environment.apiUrl}api/v2/sessions/${addSessionItemRequest.sessionId}/additem`, addSessionItemRequest);
  }
  deleteFeatureFromSession(sessionId:string,featureId:number){
    var req = {id:featureId};
    //return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/${sessionId}/features/${featureId}/delete`,{body:req});
    return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/${sessionId}/features/${featureId}/delete`,{body:req});
  }
  deleteItemFromSession(sessionId:string,itemId:number){
    var req = {id:itemId};
    return this.httpClient.delete<ResponseModel>(`${environment.apiUrl}api/v2/sessions/${sessionId}/items/${itemId}/delete`,{body:req});
  }
  getFeaturesFromSession(sessionId:string){
    return this.httpClient.get<ListResponseModel<SessionFeatureListItem>>(`${environment.apiUrl}api/v2/sessions/${sessionId}/features`);
  }
  getItemsFromSession(sessionId:string){
    return this.httpClient.get<ListResponseModel<SessionItemListItemModel>>(`${environment.apiUrl}api/v2/sessions/${sessionId}/items`)
  }
  updateSession(updateSessionRequest:UpdateSessionRequest){
    return this.httpClient.post<ResponseModel>(`${environment.apiUrl}api/v2/sessions/${updateSessionRequest.id}/update`,updateSessionRequest);
  }
  getUpdateSessionForm(sessionId:string){
    return this.httpClient.get<SingleResponseModel<CustomForm>>(`${environment.apiUrl}api/v2/sessions/${sessionId}/form`);
  }
  addMemberToSession(sessionId:string,addMemberRequestModel:AddSessionMemberRequest){
    return this.httpClient.post<ResponseModel>(`https://localhost:61140/api/v2/sessions/${sessionId}/members/add`,addMemberRequestModel);
  }
  removeMembersFromSession(sessionId:string,removeMembersRequestModel:RemoveMembersRequestModel){
    return this.httpClient.post<ResponseModel>(`https://localhost:61140/api/v2/sessions/${sessionId}/members/remove`,removeMembersRequestModel);
  }
  getMembersInSession(sessionId:string){
    //return this.httpClient.get<ListResponseModel<SessionMemberItem>>(`${environment.apiUrl}api/v2/sessions/${sessionId}/members`);
    return this.httpClient.get<ListResponseModel<SessionMemberItem>>(`https://localhost:61140/api/v2/sessions/${sessionId}/members`);
  }
}
