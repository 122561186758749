import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-education',
  templateUrl: './delete-education.component.html',
  styleUrls: ['./delete-education.component.css']
})
export class DeleteEducationComponent implements OnInit {

  courseId:string;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseId = param["courseId"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteCourse(){
    this.educationService.deleteEducation(this.courseId).subscribe({next:(response)=>{
      this.toastrService.success("Kurs başarıyla silindi");
    },error:(err)=>{
      this.toastrService.error("Kurs silinemedi");
    }})
  }

}
