import { Component, OnInit, ViewEncapsulation } from '@angular/core';
declare var window:any;
declare var $:any;
declare var jQuery:any;
@Component({
  selector: 'app-certificate-editor',
  templateUrl: './certificate-editor.component.html',
  styleUrls: ['./certificate-editor.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class CertificateEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.initCertificateEditor($('.certificate-editor'), {});
  }

}
