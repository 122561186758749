import { CookiePreferenceListenerService } from './../../services/listener/cookie-preference-listener.service';
import { CookiePreferencesService } from './../../services/cookiePreferences/cookie-preferences.service';
import { UserInfo } from './../../models/userInfo';
import { LoggedUserListenerService } from './../../services/listener/logged-user-listener.service';
import { TenantInfoModel } from './../../models/TenantModels/tenantInfoModel';
import { TranslateService } from '@ngx-translate/core';
import { TestLoginService } from './../../services/login/test-login.service';
import { LoginModel } from './../../models/loginModel';
import { LoginService } from './../../services/login/login.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsImageService } from '../../services/cmsImages/cms-image.service';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @Input() route:string;
  @Input() admin:boolean;
  loadingLogo:boolean = true;
  loginForm:UntypedFormGroup;
  loginProcess:boolean = false;
  tenantInfoModel:TenantInfoModel;
  loginSubscribe:any;
  verifyPage:boolean = false;
  checkedRememberMe:boolean;
  cookiePreferenceSubsribe:any;
  availabeRedirectAfterLogin:boolean = false;
  logo:string;
  constructor(private cmsImageService:CmsImageService,private loginService:LoginService,private formBuilder:FormBuilder,private translateService:TranslateService,private toastrService:ToastrService,private router:Router,private loggedUserListener:LoggedUserListenerService,private activatedRoute:ActivatedRoute,private cookiePreferences:CookiePreferencesService,private cookieListener:CookiePreferenceListenerService) {}

  ngOnInit(): void {
    this.createLoginForm();
    this.getTenantInfo();
    this.redirectIsAvailabe();
    this.activatedRoute.params.subscribe(params=>{
      if(params["type"]=="verify"){
        this.verifyPage = true;
      }
    });
    };
    createLoginForm(){
      this.loginForm = this.formBuilder.group({
        username:['',Validators.required],
        password:['',Validators.required],
        rememberMe:[false]
      })
    }
    redirectIsAvailabe(){
      if(sessionStorage.getItem("redirectAfterLogin") !== null){
        this.availabeRedirectAfterLogin = true;
      }else{
        this.availabeRedirectAfterLogin = false;
      }
    }
    changeAccount(){
      localStorage.removeItem("username");
      localStorage.removeItem("firstname");
    }
    knownUser(){
      if(this.verifyPage){
        let uname = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");
        this.loginForm.value.username = uname;
        return true;
      }
      if(localStorage.getItem('username') && localStorage.getItem('firstname')){
        this.loginForm.value.username = localStorage.getItem('username');
        return true;
      }else{
        return false;
      }
    }
    getFirstname(){
      if(this.verifyPage){
        return sessionStorage.getItem("firstname") ? sessionStorage.getItem("firstname") : localStorage.getItem("firstname");
      }
      return localStorage.getItem('firstname');
    }
    logoOnLoad(){
      this.loadingLogo = false;
    }
    login(isAdmin:boolean){
      //let clientName = isAdmin ? "testclient" : "jitsiapp";
      let clientName = isAdmin ? "service-client" : "service-client";
      let loginModel:LoginModel = {username:"",password:"",clientName:clientName};
      loginModel.username = this.loginForm.value.username;
      loginModel.password = this.loginForm.value.password;
      this.loginProcess = true;
      this.loginService.login(loginModel,isAdmin).subscribe(response=>{
        if(response.success){
          var expiry = new Date();
          expiry.setSeconds(expiry.getSeconds()+response.content.expires_in);
          var refreshExpiry = new Date();
          refreshExpiry.setSeconds(expiry.getSeconds()+response.content.refresh_expires_in).toLocaleString()
          sessionStorage.setItem("token",response.content.access_token);
          sessionStorage.setItem("token_expiration",expiry.toJSON());
          sessionStorage.setItem("refresh_token",response.content.refresh_token);
          sessionStorage.setItem("refresh_token_expiration",refreshExpiry.toJSON());

          this.toastrService.success(this.translateService.instant("loginSuccessRedirectingMainPage"),"",{progressBar:true,progressAnimation:"increasing",timeOut:1000});
          setTimeout(()=>{
            this.getUserInfo();
            this.updateLoggedUserStatus();
            var redirectPath = sessionStorage.getItem("redirectAfterLogin");
            if(redirectPath == null){
              this.router.navigate(["/"]);
              sessionStorage.removeItem("redirectAfterLogin");
            }else{
              this.router.navigate([redirectPath]);
              sessionStorage.removeItem("redirectAfterLogin");
            }

            let cookieResponse = this.cookiePreferences.cookiePreferencesSaved();
            cookieResponse.subscribe(response=>{
              this.setCookieListener(response);
            })
          },1000);
        }else{
          this.toastrService.error(this.translateService.instant("loginFailed"));
        }
        this.loginProcess = false;
      },error=>{
        this.loginProcess = false;
        this.toastrService.error(this.translateService.instant("loginFailed"));
      });

    }
    verifyPassword(){
      let loginModel:LoginModel = {username:"",password:"",clientName:""};
      loginModel.username = this.loginForm.value.username;
      loginModel.password = this.loginForm.value.password;
      this.loginProcess = true;
      this.loginService.verifyPassword(loginModel).subscribe(response=>{
        if(response.success){
          this.toastrService.success("Parola doğrulandı");
        }else{
          this.toastrService.error("Parola doğrulanamadı");
        }
        this.loginProcess = false;
      })
    }
    getUserInfo(){
      this.loginService.getUserInfo(this.admin).subscribe(response=>{
        if(response.success){
          sessionStorage.setItem("firstname",response.content.firstName);
          sessionStorage.setItem("username",response.content.username);
          if(this.checkedRememberMe){
          localStorage.setItem("firstname",response.content.firstName);
          localStorage.setItem("username",response.content.username);
          }
        }
      }
      )
    }
    getTenantInfo(){
      var tenantInfo = localStorage.getItem("tenantInfo");
      if(tenantInfo){
        this.tenantInfoModel = JSON.parse(tenantInfo).applicationConfiguration;

        if(!this.tenantInfoModel.logo.includes("/")){
          this.logo = this.cmsImageService.getUrl(this.tenantInfoModel.logo);
        }else{
          this.logo = this.tenantInfoModel.logo;
        }

        console.log(this.tenantInfoModel)
      }
    }
    updateLoggedUserStatus(){
      if(this.loginSubscribe){
        this.loginSubscribe.unsubscribe();
      }
      this.loginSubscribe = this.loggedUserListener.getLoggedUserStatus('true').subscribe();
    }
    setCookieListener(state:boolean){
      if(this.cookiePreferenceSubsribe){
        this.cookiePreferenceSubsribe.unsubscribe();
      }
      this.cookiePreferenceSubsribe = this.cookieListener.setCookiePreferences(state).subscribe();
    }
  }

