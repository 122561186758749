import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-course-seat',
  templateUrl: './create-course-seat.component.html',
  styleUrls: ['./create-course-seat.component.css']
})
export class CreateCourseSeatComponent implements OnInit {
  baseApiUrl = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.formUrl = this.baseApiUrl + "api/lms/courses/seats/create";
  }

}
