<div class="container-fluid" *ngIf="education">
    <div class="row">
        <div class="col-9">
            <div class="row">
                <div class="col-12">
                    <lib-education-details-detail-area [education]="education"></lib-education-details-detail-area>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12">
                    <lib-education-details-basic [categoryTreeId]="categoryTreeId" [educationModel]="education"></lib-education-details-basic>
                </div>
            </div>
        </div>

    </div>
</div>