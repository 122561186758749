import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { EducationCategoryModel } from '../../../models/Education/educationCategoryModel';
import { EducationService } from '../../../services/education/education.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { CourseCategoryFlagsConst } from '../../../models/Education/Enum/courseCategoryFlags';

@Component({
  selector: 'lib-education-category-details',
  templateUrl: './education-category-details.component.html',
  styleUrls: ['./education-category-details.component.css']
})
export class EducationCategoryDetailsComponent implements OnInit {
  categoryId: number;
  categoriesListItem: ListItemModel[] = [];
  currentCategory: EducationCategoryModel;
  categoryTreeId:number = 1;
  categoryFlags:{key:string,value:number}[] = Object.entries(CourseCategoryFlagsConst).map((val,index,arr)=>{
    return {key:val[0],value:val[1]};
  })
  activeFlags:string[];
  constructor(private educationService: EducationService, private activatedRoute: ActivatedRoute, private toastrService: ToastrService,private globalModalService:GlobalModalService,private globalOkCancel:GlobalOkCancelEventService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.categoryId = parseInt(param["categoryId"]);
        this.getCategories();
        this.getCategory();
      }
    })
  }
  /*writeOptions(){
    this.categoryFlags.forEach((flag=>{
      console.log(flag);
      console.log(this.currentCategory);
      if((this.currentCategory.options & flag.value) && flag.value != 0){
        this.activeFlags.push(flag.key);
      }
    }))
  }*/
  getCategories() {
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next: (response) => {
        this.categoriesListItem = response.content;

      }, error: (err) => {
        this.toastrService.error("Kategoriler alınırken bir hata oluştu");
      }
    })
  }
  getCategory() {
    this.educationService.getCategory(this.categoryId).subscribe({
      next: (response) => {
        this.currentCategory = response.content;
      }, error: (err) => {
        this.toastrService.error("Kategori alınırken bir hata oluştu");
      }
    })
  }
  getCategoryNameByCategoryId(categoryId: number) {
    var name = this.categoriesListItem.find(x => x.value == categoryId)?.label;
    return name ?? "-";
  }
  showUpdateModal(){
    var modalCreate:ModalCreateModel = {
      title: "Kategoriyi Düzenle",
      type: ModalTypes.LINK,
      data: `/modal/lms/categorytrees/course/${this.categoryTreeId}/leaves/${this.categoryId}/update`,
      helperModals: []
    }
    this.globalModalService.showModal(modalCreate);
  }
  deleteCategory(){
    this.globalOkCancel.showOkCancelWindow("Kategoriyi Sil","Seçtiğiniz kategori silinecek. Onaylıyor musunuz ?").then((r)=>{
      if(r == true){
        this.educationService.deleteCategory(this.categoryId,this.categoryTreeId,true).subscribe({
          next:(response)=>{
            this.toastrService.success("Kategori silindi");
          },error:(err)=>{
            this.toastrService.error("Kategori silinemedi");
          }
        })
      }else{
        
      }
    })
  }

}
