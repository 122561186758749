import { Component, Input, OnInit } from '@angular/core';
import { VersionDetailsModel } from 'projects/usorta-library/src/lib/models/Education/versionDetailsModel';

@Component({
  selector: 'lib-version-details-sections',
  templateUrl: './version-details-sections.component.html',
  styleUrls: ['./version-details-sections.component.css']
})
export class VersionDetailsSectionsComponent implements OnInit {
  @Input() versionDetail:VersionDetailsModel;
  constructor() { }

  ngOnInit(): void {
  }

}
