import { DomSanitizer } from '@angular/platform-browser';
import { StaticContentService } from './../../../services/staticContent/static-content.service';
import {
  Component,
  Input,
  OnInit,
  Sanitizer,
  SecurityContext,
} from '@angular/core';
import { MermaidAPI } from 'ngx-markdown';
@Component({
  selector: 'lib-custom-block',
  templateUrl: './custom-block.component.html',
  styleUrls: ['./custom-block.component.css'],
})
export class CustomBlockComponent implements OnInit {
  @Input() blockKey: string;
  @Input() externalContent: string = '';
  @Input() externalType: string = 'text/html';
  content: string | null;
  contentType: string | null;
  allowedTags: string[] = ['br','a', 'p', 'div', 'iframe', 'font', 'head', 'style', 'body', 'i', 'table', 'ul', 'li', 'ol', 'textarea', 'input','h1','h2','h3','h4','h5'];
  markdown: string | undefined;
  constructor(
    private staticContentService: StaticContentService,
    private sanitizer: DomSanitizer
  ) {}
  public options: MermaidAPI.Config = {
    fontFamily: '"trebuchet ms", verdana, arial, sans-serif',
    logLevel: MermaidAPI.LogLevel.Info,
    theme: MermaidAPI.Theme.Dark,
    gantt: { axisFormat: '%m/%d/%Y' },
  };
  ngOnInit(): void {
    this.getBlockContent(this.blockKey);
  }
  getBlockContent(block: string) {
    if (this.externalContent == '') {
      this.staticContentService.getBlockContent(block).subscribe((response) => {
        this.contentType = response.headers.get('Content-Type');
        if (this.contentType == 'text/html') {
          this.content = this.sanitizeHtml(response.body);
        } else if (this.contentType == 'text/plain') {
          this.content = this.toPlainText(response.body);
        } else if (this.contentType == 'text/markdown') {
          this.markdown = this.sanitizeHtml(response.body);
        }
      });
    }else{
      this.content = this.externalContent;
      this.contentType = this.externalType == "" ? "text/html" : this.externalType
      if (this.contentType == 'text/html') {
        this.content = this.sanitizeHtml(this.content);
      } else if (this.contentType == 'text/plain') {
        this.content = this.toPlainText(this.content);
      } else if (this.contentType == 'text/markdown') {
        this.markdown = this.sanitizeHtml(this.content);
      }
    }
  }
  toPlainText(responseText: string | null): string {
    return responseText ? responseText : '';
  }
  sanitizeHtml(htmlContent: string | null): string {
    let sanitizedContent = this.sanitizer.sanitize(
      SecurityContext.HTML,
      htmlContent
    );
    sanitizedContent = this.removeNotAllowedTags(
      sanitizedContent ? sanitizedContent : ''
    );
    return sanitizedContent ? sanitizedContent : '';
  }
  removeNotAllowedTags(html: string) {
    let tags = this.allowedTags;
    let div = document.createElement('div');
    div.innerHTML = html;
    div.querySelectorAll('*').forEach((element) => {
      if (!tags.includes(element.tagName.toLowerCase())) {
        element.remove();
      }
      console.log(element.tagName);
    });
    return div.innerHTML;
  }
}
