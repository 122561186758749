import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import localLanguage from "projects/usorta-library/src/_files/localMessages.json";

@Pipe({
  name: 'localTranslate',
})
export class LocalTranslatePipe implements PipeTransform {
  transform(key: string, ...args: unknown[]): string {
    let defaultLanguage = "tr";
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;
    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1){
      shortLang = shortLang.split('-')[0];
    }
    if (shortLang.indexOf('_') !== -1){
      shortLang = shortLang.split('_')[0];
    }
    let jsonStr = JSON.stringify(localLanguage);
    let local;
    if(JSON.parse(jsonStr).hasOwnProperty(shortLang)){
      local = JSON.parse(jsonStr)[shortLang][key];
    }else{
      local = JSON.parse(jsonStr)[defaultLanguage][key];
    }
    return local;
  }
}
