import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserManagerService } from '../../../services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminManagerService } from '../../../services/admin-manager.service';

@Component({
  selector: 'app-block-admin',
  templateUrl: './block-admin.component.html',
  styleUrls: ['./block-admin.component.css']
})
export class BlockAdminComponent implements OnInit {
  username:string = "";
  checkedUnderstandCheck:boolean = false;
  selectedUser:any;
  userId:string;
  constructor(private translateService:TranslateService, private adminManagementService:AdminManagerService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param['userId'];
        this.getUserInfo();
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  getUserInfo(){
    this.adminManagementService.getUserById(this.userId).subscribe({
      next:(response)=>{
        this.selectedUser = response.content;
        console.log(this.selectedUser)
      }
    })
  }
  blockUser = (data:any) => {
    this.adminManagementService.blockUser(data.id).subscribe({
      next:(response)=>{
        this.toastrService.info(response.success == true ? this.translateService.instant("global.successful") : this.translateService.instant("global.unsuccessful"));
      }
    })
  }
  unblockUser = (data:any) => {
    this.adminManagementService.unblockUser(data.id).subscribe({
      next:(response)=>{
        this.toastrService.info(response.success == true ? this.translateService.instant("global.successful") : this.translateService.instant("global.unsuccessful"));
      }
    })
  }
  toggleBlock(){
    if(!this.selectedUser.blocked){
      this.blockUser({id:this.userId});
    }else{
      this.unblockUser({id:this.userId});
    }
  }

}
