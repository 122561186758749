import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { ResponseTokenModel } from '../../models/responseTokenModel';

@Injectable({
  providedIn: 'root'
})
export  abstract class RefreshTokenService {

  public abstract getTokenWithRefreshToken(refresh:string):Observable<SingleResponseModel<ResponseTokenModel>>
  public abstract renewIfExpired():Promise<boolean>;
}
