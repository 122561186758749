<div *ngIf="locales && responseItemStyle && options">
<form [formGroup]="addExamForm">
    <div class="mb-3">
        <label for="name" class="form-label">{{'exam.examName' | translate}}</label>
        <input type="text" class="form-control" id="name" formControlName="name">
    </div>
    <div class="mb-3">
        <label for="description" class="form-label">{{'exam.description' | translate}}</label>
        <input type="text" class="form-control" id="description" formControlName="description">
    </div>
    <div class="mb-3">
        <label for="active" class="form-check-label">{{'exam.active' | translate}} : </label><input type="checkbox" class="form-check-input" id="active"
        formControlName="active"/>

    </div>
    <div class="mb-3">
        <label for="locale" class="form-label">{{'exam.language' | translate}}</label>
        <select id="locale" class="form-select" formControlName="locale">
            <option *ngFor="let locale of locales" [value]="locale.value">{{locale.label}}</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="maxPoints" class="form-label">{{'exam.maxPoint' | translate}}</label>
        <input type="number" class="form-control" id="maxPoints" formControlName="maxPoints">
    </div>
    <div class="mb-3">
        <label for="passingPts" class="form-label">{{'exam.passingPoint' | translate}}</label>
        <input type="number" class="form-control" id="passingPts" formControlName="passingPts">
    </div>
    <div class="mb-3">
        <label for="passingPct" class="form-label">{{'exam.passingPct' | translate}}</label>
        <input type="number" class="form-control" id="passingPct" formControlName="passingPct">
    </div>
    <label>Response Item Style</label>
    <div class="mb-3">

        <div class="form-check" style="width: 45%; display: inline-block;" *ngFor="let item of responseItemStyle">
            <input class="form-check-input" type="checkbox" [value]="item.value" class="responseItemCheck">
            <label class="form-check-label" for="flexCheckDefault">
              {{item.key}}
            </label>
          </div>
    </div>
    <br/>
    <label>{{'exam.options' | translate}}</label>
    <div class="mb-3">

        <div class="form-check" style="width: 45%; display: inline-block;" *ngFor="let item of options">
            <input class="form-check-input" type="checkbox" [value]="item.value" class="optionsCheck">
            <label class="form-check-label" for="flexCheckDefault">
              {{item.key}}
            </label>
          </div>
    </div>
    <button [disabled]="loading" class="btn btn-primary btn-sm" (click)="createExam()">{{'exam.addExam' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
    <br>
</form>
</div>