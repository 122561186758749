import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-localized-menu-list',
  templateUrl: './localized-menu-list.component.html',
  styleUrls: ['./localized-menu-list.component.css']
})
export class LocalizedMenuListComponent implements OnInit {
  tableUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var menuId = param["menuid"];
        this.tableUrl = this.apiUrlService.getApiUrl() + `api/cms/admin/menu/${menuId}/localize/list`
      }
    })
  }

}
