<div class="forgotPasswordContainer">
  <div class="forgotPasswordMainDiv">
    <button class="btn btn-secondary" routerLink="/login"><i class="bi bi-arrow-left"></i> {{'goBack' | translate}}</button>
    <img src="/assets/usorta.png" style="width: 100%;">
    <h4>{{'forgotPassword' | translate}}</h4>
    <form [formGroup]="passwordResetForm">
      <div class="form-group">
       <!-- <label for="username">{{'username' | translate}}</label>-->
        <input type="text" class="form-control" id="username" placeholder="{{'username' | translate}}"
          formControlName="username">
      </div>
    </form>
    <button type="submit" class="btn btn-danger sendBtn" *ngIf="processing == false"> {{'sendResetPasswordCode' | translate}}</button>
    <button type="submit" class="btn btn-danger sendBtn disabled" *ngIf="processing == true"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></button>
  </div>
</div>
