import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-course-seat',
  templateUrl: './delete-course-seat.component.html',
  styleUrls: ['./delete-course-seat.component.css']
})
export class DeleteCourseSeatComponent implements OnInit {
  itemId:string;
  url:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.itemId = param["id"];
        this.url = "api/lms/courses/seats/"+this.itemId+"/delete"
      }
    })
  }

}
