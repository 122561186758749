import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-admin-role',
  templateUrl: './create-admin-role.component.html',
  styleUrls: ['./create-admin-role.component.css']
})
export class CreateAdminRoleComponent implements OnInit {
  formUrl:string;
  baseUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.baseUrl = environment.apiUrl;
    this.formUrl = this.baseUrl + "api/acl/admin/roles/create";
  }

}
