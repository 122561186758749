
<div class="addOutlineEditorMain">
    <div class="outlineEditor" id="outlineEditor" style="height: 300px; overflow:hidden;">

    </div>
</div>
<textarea class="form-control" style="height: 350px;">
</textarea>
<br/>
<button class="btn btn-sm btn-primary" (click)="addOutline()">{{'btn.add' | translate}}</button>
