import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { RemoveMembersRequestModel } from '../../../models/SessionApiModels/removeMembersRequestModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-remove-member-from-session',
  templateUrl: './session-management-remove-member-from-session.component.html',
  styleUrls: ['./session-management-remove-member-from-session.component.css']
})
export class SessionManagementRemoveMemberFromSessionComponent implements OnInit {

  userId:string | null;
  sessionId:string | null;
  success:boolean = false;
  constructor(private translateService:TranslateService,private globalModalService:GlobalModalService,private activatedRoute:ActivatedRoute,private sessionService:SessionApiService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.userId = param['userId'];
      this.sessionId = param['sessionId'];
    })
  }
  
  removeMemberFromSession(){
    if(this.userId != null && this.sessionId != null){
      var user:RemoveMembersRequestModel = {users:[parseInt(this.userId)]};
      this.sessionService.removeMembersFromSession(this.sessionId,user).subscribe({
        next:(response)=>{
          if(response.success){
            var successMessage = this.translateService.instant("sessionManagement.memberRemoved");
            this.toastrService.success(successMessage);
            this.success = true;
          }else{
            var errorMessage = this.translateService.instant("sessionManagement.memberRemovedError");
            this.toastrService.error(errorMessage);
          }
        }
      })
    }else{
      var anyErrorMessage = this.translateService.instant("global.errorOccurred");
      this.toastrService.error(anyErrorMessage);
    }

  }


}
