import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit {
  @Input() options:any;
  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
  }

}
