import { Component, Input, OnInit } from '@angular/core';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';

@Component({
  selector: 'lib-session-management-page',
  templateUrl: './session-management-page.component.html',
  styleUrls: ['./session-management-page.component.css']
})
export class SessionManagementPageComponent implements OnInit {
  @Input() sessionId:string;
  constructor(private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
  }
  showUpdateSessionModal(){
      var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:"Add Item",data:`modal/session/${this.sessionId}/update`,helperModals:[]};
      this.globalModalService.showModal(modalCreate,true);
  }

}
