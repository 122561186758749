<div class="detail-area formMain form-maroon-border form-default-border">
    <h5>{{'icons.iconDetailsTitle' | translate}}</h5>
    <hr />
    <div class="iconSets-toolbar">
        <div (click)="returnIconsPage()" class="iconSets-toolbar-item ">
            <i class="bi bi-boxes primary"></i><font> {{'btn.returnIconsPage' | translate}}</font>
        </div>
        <div (click)="updateIcon()" class="iconSets-toolbar-item ">
            <i class="bi bi-pencil-fill warning"></i><font> {{'btn.edit' | translate}}</font>
        </div>
        <div class="iconSets-toolbar-item" (click)="deleteIcon()">
            <i class="bi bi-trash-fill danger"></i><font> {{'btn.delete' | translate}}</font>
        </div>
    </div>
    <hr/>
    <div *ngIf="iconDetails">
        <div><b>{{'icons.name' | translate}} : </b>
            <font>{{iconDetails.name}}</font>
        </div>
        <div><b>{{'icons.iconCode' | translate}} : </b>
            <font>{{iconDetails.iconCode}}</font>
        </div>

        <div *ngIf="iconDetails.applicationId"><b>{{'icons.applicationId' | translate}} : </b>
            <font>{{iconDetails.applicationId}}</font>
        </div>
        <div *ngIf="iconDetails.tenantId"><b>{{'icons.tenantId' | translate}} : </b>
            <font>{{iconDetails.tenantId}}</font>
        </div>
        <div *ngIf="iconDetails.locale"><b>{{'icons.locale' | translate}} : </b>
            <font>{{iconDetails.locale}}</font>
        </div>
        <b>{{'icons.iconPreview' | translate}}</b>
        <div class="iconSet-item">
            <div class="iconSet-img-container" style="width: 100%;">
                <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/icon/'+iconDetails.id"
                    style="max-width:200px; width: 100%;" />
            </div>
        </div>
    </div>
    <br>
    <div class="alternative-icons" *ngIf="iconDetails.alternatives.length > 0">

        <h5>{{'icons.alternatives' | translate}}</h5>
        <hr>
        <div class="iconSet-item" style="cursor:pointer" (click)="routeIconDetails(icon.id)" *ngFor="let icon of iconDetails.alternatives">
            <div class="iconSet-img-container">
                <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/icon/'+icon.id" />
            </div>
            <br/>
            <div class="iconSet-info">
                <b class="iconSet-iconName">{{icon.name}}</b>
                <font class="iconSet-iconCode">{{icon.iconCode}}</font>
            </div>
        </div>
    </div>
</div>