import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';

@Component({
  selector: 'lib-restore-base-vm-image-info-revision',
  templateUrl: './restore-base-vm-image-info-revision.component.html',
  styleUrls: ['./restore-base-vm-image-info-revision.component.css']
})
export class RestoreBaseVmImageInfoRevisionComponent implements OnInit {
  baseVmId:string;
  revisionId:string;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private baseVmImageService:BaseVmImageService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseVmId = param["baseVmId"];
        this.revisionId = param["revisionId"]
      }
    })
  }
  restore(){
    var rollback = (document.getElementById("rollback") as HTMLInputElement).checked;
    this.baseVmImageService.restoreBaseVmImageInfoRevision({
      revisionId:this.revisionId,
      rollback:rollback,
      vmImageId:this.baseVmId
    }).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("baseVmImage.revision.restoredSuccessfully"));
      },error:(err)=>{
        this.toastrService.success(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
