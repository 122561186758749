import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale/locale.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-locale',
  templateUrl: './delete-locale.component.html',
  styleUrls: ['./delete-locale.component.css']
})
export class DeleteLocaleComponent implements OnInit {
  localeId:string;
  checkedUnderstandCheck:boolean = false;
  constructor(private localeService:LocaleService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.localeId = param["locale"];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteLocale(){
    this.localeService.deleteLocale(this.localeId,{id:this.localeId}).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Dil silindi");
        }else{
          this.toastrService.error("Dil silinemedi");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
