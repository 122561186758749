import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { LocalizedStringService } from '../../../services/localizedString/localized-string.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormSubmitRedirectService } from '../../../services/formSubmitRedirect/form-submit-redirect.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;
declare var window:any;
declare var monaco:any;
@Component({
  selector: 'lib-create-localized-string',
  templateUrl: './create-localized-string.component.html',
  styleUrls: ['./create-localized-string.component.css']
})
export class CreateLocalizedStringComponent implements OnInit {
  formData:any;
  formInput:boolean[] = [false];
  editorEl:any;
  loading:boolean = false;
  monacoEditor:any;
  inputFormat:number;
  localizedStringFormGroup:FormGroup;
  preKey:string | null = null;
  constructor(private translateService:TranslateService,private formSubmitRedirect:FormSubmitRedirectService,private activatedRoute:ActivatedRoute,private httpClient:HttpClient,private apiUrlService:ApiUrlService,private toastrService:ToastrService,private localizedStringService:LocalizedStringService) { }

  ngOnInit(): void {
    let key = null;
    let appId = environment.applicationId;
    let tenantId = environment.tenantId;
    let currentLang = this.translateService.currentLang;
    this.editorEl = document.getElementsByClassName("valueEditor")[0] as HTMLElement;
    this.activatedRoute.queryParams.subscribe({
      next:(param)=>{
        key = param["key"];
        this.preKey = key;
      }
    })
    let qs = "?";
    
    if(key != null){
      qs += "key="+key;
      if(appId !== undefined && appId != null && appId != ""){
        qs += "&app="+appId
      }
      if(tenantId !== undefined && tenantId != null && tenantId != ""){
        qs += "&tenant="+tenantId
      }
      if(currentLang !== undefined && currentLang != null && currentLang != ""){
        qs += "&locale="+currentLang;
      }
    }

    
    this.httpClient.get(this.apiUrlService.getApiUrl()+"api/cms/admin/localization/localizedstring/create"+qs).subscribe({
      next:(response:any)=>{
        this.formData = response.content;
        setTimeout(()=>{
          this.formInput = [true];
        },250)
      }
    })
  }
  initializeFormData($ev:FormGroup){

    console.log("Form bilgisi")
    console.log($ev);
    this.localizedStringFormGroup = $ev;
    var formatValue = $ev.get("format")?.value
    this.inputFormat = parseInt(formatValue);
    if(this.inputFormat == 0 || this.inputFormat == 2){

    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
      this.createMonaco();
    }else if(this.inputFormat == 5){
      this.createLayoutEditor();
    }
  }
  getSubmitData($ev:any){
    if(this.loading == true){
      this.toastrService.error("global.pleaseWaitOtherRequest");
      return;
    }
    this.loading = true;
    $ev.value = this.getValue();
    $ev.format = parseInt($ev.format);
    $ev.tenantId = $ev.tenantId == "" ? null : $ev.tenantId; 
    this.localizedStringService.createLocalizedString($ev).subscribe({
      next:(response)=>{
        this.loading = false;
        console.log($ev);
        this.toastrService.success("Localized String added");
        if(this.preKey == null){
          this.formSubmitRedirect.showModal("/localization/localizedstring/list",null,"localizedString.addedSuccessfully","btn.goLocalizedStringList");
          this.translateService.reloadLang(this.translateService.currentLang);
        }

      },error:(err)=>{
        this.loading = false;
        this.toastrService.error("An error occurred");
      }
    })
  }
  formInputChanged($ev:any){
    console.log($ev);
    if($ev.name == "format"){
      this.inputFormat = parseInt($ev.value);
    }
    if(this.inputFormat == 0 || this.inputFormat == 2){

    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
      this.createMonaco();
    }else if(this.inputFormat == 5){
      this.createLayoutEditor();
    }
  }
  createMonaco(){
    setTimeout(()=>{
      var type = "json";
      if(this.inputFormat == 1){
        type = "html";
      }
      if(this.inputFormat == 3){
        type = "json";
      }
      if(this.inputFormat == 4){
        type = "xml"
      }
      if(window['monacoEditor'] != null){
        window['monacoEditor'].getModel().dispose();
      }
      var codeEditor = document.getElementsByClassName("editorMonaco")[0] as HTMLDivElement
      this.monacoEditor = monaco.editor.create(codeEditor, {
        language: type,
        value: "",
        theme: 'vs',
        autoIndent: true,
        tabSize: 2,
        automaticLayout: true
      });
      window['monacoEditor'] = this.monacoEditor;
    },250)

  }
  createLayoutEditor(){
    setTimeout(()=>{
      window.initLayoutEditor($('.editorLayout'), {});
    },500)

  }
  getValue(){
    if(this.inputFormat == 0 || this.inputFormat == 2){
      var doc = document.getElementsByClassName("editorTextarea")[0] as HTMLTextAreaElement;
      return doc.value;
    }else if(this.inputFormat == 1 || this.inputFormat == 3 || this.inputFormat == 4){
      var val = window['monacoEditor'].getValue();
      return val;
    }else if(this.inputFormat == 5){
      var layoutVal = document.getElementById("editorLayout") as HTMLTextAreaElement;
      return layoutVal.value;

    }
  }
}
