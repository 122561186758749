export const QuestionTypesConst = {
    TrueFalse:0,
    MultipleChoice:1,
    ShortAnswer:2,
    OpenAnswer:3,
    RangeSelect:4,
    Pairing:5,
    Custom:6
}
export enum QuestionTypesEnum {
    "TrueFalse",
    "MultipleChoice",
    "ShortAnswer",
    "OpenAnswer",
    "RangeSelect",
    "Pairing",
    "Custom"
}