import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';

@Component({
  selector: 'lib-customize-change-password',
  templateUrl: './customize-change-password.component.html',
  styleUrls: ['./customize-change-password.component.css']
})
export class CustomizeChangePasswordComponent implements OnInit {
  changePasswordForm:FormGroup;
  vmId:string;
  constructor(private translateService:TranslateService,private formBuilder:FormBuilder,private toastrService:ToastrService,private virtualLabService:VirtualLabService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
        this.createForm();
      }
    })

  }
  createForm(){
    this.changePasswordForm = this.formBuilder.group({
      username:["",[Validators.required,Validators.pattern("^[a-zA-Z1-9]*$")]],
      password:["",Validators.required],
    })
  }
  errorControl(name:string){
    if(this.changePasswordForm.get(name)?.errors){
      return true;
    }
    return false;
  }
  togglePasswordPreview(){
    var inp = document.getElementById("password") as HTMLInputElement;
    inp.type = inp.type == "password" ? "text" : "password"
  }
  togglePasswordIcon(){
    var inp = document.getElementById("password") as HTMLInputElement;
    return inp.type == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"
  }
  changePassword(){
    if(this.changePasswordForm.valid){
      var formValues = this.changePasswordForm.value;
      var customizationValue:VMCustomization = {
        type: 'change-password',
        args: JSON.stringify(formValues)
      }
      this.virtualLabService.addCustomizationToVm(this.vmId,customizationValue).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmCustomization.addedParameter"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }

}
