import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-create-application-configuration',
  templateUrl: './create-application-configuration.component.html',
  styleUrls: ['./create-application-configuration.component.css']
})
export class CreateApplicationConfigurationComponent implements OnInit {
  id:string;
  baseUrl:string;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.id = param["appId"];
        this.baseUrl = environment.apiUrl;
        this.formUrl = this.baseUrl + "api/cms/applications/"+this.id+"/configurations/create"
      }
    })
  }

}
