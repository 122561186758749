import { Component, OnInit } from '@angular/core';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ToastrService } from 'ngx-toastr';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
//TranslateService eklenecek
@Component({
  selector: 'lib-delete-host-provider-type',
  templateUrl: './delete-host-provider-type.component.html',
  styleUrls: ['./delete-host-provider-type.component.css']
})
export class DeleteHostProviderTypeComponent implements OnInit {

  hostProviderTypeId:string | null;
  success:boolean = false;
  confirm1:boolean = false;
  confirm2:boolean = false;
  constructor(private okCancelSrv:GlobalOkCancelEventService,private translateService:TranslateService ,private activatedRoute:ActivatedRoute,private hostProviderService:HostProviderService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.hostProviderTypeId = param["typeId"];
    })
  }
  checkChanged(confirmNo:number,ev:Event){

    let target = ev.target as HTMLInputElement;
    if(confirmNo == 1){
      this.confirm1 = target.checked;
    }else if(confirmNo == 2){
      this.confirm2 = target.checked;
    }

  }

  conf1(){
    var conf1message = this.translateService.instant("hostprovidertype.removeConfirm1");
    this.okCancelSrv.showOkCancelWindow(this.translateService.instant("global.confirmDeletion"),"<b>"+conf1message+"</b>").then(result=>{
      this.confirm1 = result as boolean;
      (document.getElementById("confirm1") as HTMLInputElement).checked = this.confirm1;
    });
  }
  conf2(){
    var conf2message = this.translateService.instant("hostprovidertype.removeConfirm2");
    this.okCancelSrv.showOkCancelWindow(this.translateService.instant("global.confirmDeletion"),"<b>"+conf2message+"</b>").then(result=>{
      this.confirm2 = result as boolean;
      (document.getElementById("confirm2") as HTMLInputElement).checked = this.confirm2;
    });
  }
  
  deleteHostProviderType(){
    if(this.hostProviderTypeId){
      this.hostProviderService.deleteHostProviderType({confirm1:this.confirm1,confirm2:this.confirm2,typeId:this.hostProviderTypeId}).subscribe({
        next:(response)=>{
          if(response.success){
            var successMessage = this.translateService.instant("global.deletedSuccessfully");
            this.toastrService.success(successMessage);
            this.success = true;
          }else{
            var errorMessage = this.translateService.instant("global.deletedError");
            this.toastrService.error(errorMessage);
          }
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          
        }
      })
    }else{
      var errorMessage = this.translateService.instant("Bir hata oluştu");
      this.toastrService.error(errorMessage);
    }

  }

}
