<div class="preferencesBox" *ngIf="cookiePreferencesList">
<div *ngIf="defaultCustomize == false"><i class="bi bi-question-diamond" style="font-size: 40px;"></i></div>
<div *ngIf="defaultCustomize == true" style="text-align: center;"><i class="bi bi-question-diamond" style="font-size: 60px;"></i><br/><br/></div>
<font class="preferencesTitle">{{'cookiePreferences.title' | translate}}</font>
<div class="preferencesDescription" [innerHTML]="'cookiePreferences.descrtiption' | translate | sanitizedHtml"></div>
<br/>
<div class="buttonsDiv" *ngIf="customPreferences.length == 0">
  <button class="acceptAll" (click)="acceptAll()">
    {{'cookiePreferences.acceptAll' | translate}}
  </button>
  <button class="customizeCookies" (click)="customizeCookies()">
    {{'cookiePreferences.customizeCookies' | translate}}
  </button>
</div>

<div class="customizeDiv" *ngIf="customPreferences.length > 0">
  <div *ngFor="let preference of cookiePreferencesList.preferences" class="preferenceItem">
    <font class="preferenceName">{{preference.name}}</font>
    <div style="display:inline-block" *ngIf="preference.required">
      <i class="bi bi-toggle-on togglerequirebtn"></i>
    </div>
    <div style="display:inline-block" *ngIf="!preference.required">
      <i class="bi bi-toggle-on togglebtn" (click)="toggle(preference.id)" *ngIf="getPreferenceValue(preference.id)"></i>
      <i class="bi bi-toggle-off togglebtn" (click)="toggle(preference.id)" *ngIf="!getPreferenceValue(preference.id)"></i>
    </div>

    <font class="preferenceDescription"></font>

  </div>
  <button class="customizeAccept" (click)="saveCookiePreferences(true)">
    {{'cookiePreferences.accept' | translate}}
  </button>
</div>

</div>
