<div class="formMain form-default-border form-maroon-border" *ngIf="educationModel">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.updateCourseTitle' | translate}}</font>
    </div>
    <div class="mb-3">
        <label for="code" class="form-label mainLabel">{{'courseManagement.courseCode' | translate}}</label>
        <input type="text" class="form-control" id="code" name="code"
            [placeholder]="'courseManagement.courseCode' | translate">
    </div>
    <div class="mb-3">
        <label for="name" class="form-label mainLabel">{{'courseManagement.courseName' | translate}}</label>
        <input type="text" class="form-control" id="name" name="name"
            [placeholder]="'courseManagement.courseName' | translate">
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input class="form-check-input" [checked]="educationModel.active" type="checkbox" value="" name="active"
                id="active">
            <label class="form-check-label mainLabel" for="active">
                {{'courseManagement.enableCourse' | translate}}
            </label>
        </div>
    </div>
    <div class="mb-3" *ngIf="educationModel.image">
        <font class="mainLabel">Kurs Resmi</font>
        <img [src]="getImageUrl(educationModel.image)" />
    </div>
    <div class="mb-3" [class.displayNone]="educationModel.image == null">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" name="deleteImage" id="deleteImage">
            <label class="form-check-label mainLabel" for="deleteImage">
                Kurs Resmini Sil
            </label>
        </div>
    </div>
    <div class="mb-3">
        <label for="image" class="form-label mainLabel">{{'courseManagement.catalogImage' | translate}}</label>
        <input class="form-control" (change)="changeCatalogImage($event)" type="file" id="image">
    </div>
    <image-cropper [output]="'base64'" [imageChangedEvent]="catalogImageChangedEvent" format="png"
        (imageCropped)="catalogImageCropped($event)" (imageLoaded)="catalogImageLoaded($event)"
        (cropperReady)="catalogCropperReady()" (loadImageFailed)="catalogImageLoadFailed()" [cropperStaticHeight]="150"
        [cropperStaticWidth]="250"></image-cropper>

    <div class="mb-3" *ngIf="educationModel.teaserImage">
        <font class="mainLabel">Teaser Resmi</font>
        <img [src]="getImageUrl(educationModel.teaserImage)" />
    </div>
    <div class="mb-3" [class.displayNone]="educationModel.teaserImage == null">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" name="deleteTeaserImage" id="deleteTeaserImage">
            <label class="form-check-label mainLabel" for="deleteTeaserImage">
                Teaser Resmini Sil
            </label>
        </div>
    </div>
    <div class="mb-3">
        <label for="teaserImage" class="form-label mainLabel">{{'courseManagement.detailImage' | translate}}</label>
        <input class="form-control" (change)="changeDetailImage($event)" type="file" id="teaserImage">
    </div>
    <image-cropper [output]="'base64'" [autoCrop]="true" [imageChangedEvent]="detailImageChangedEvent" format="png"
        (imageCropped)="detailImageCropped($event)" (imageLoaded)="detailImageLoaded($event)"
        (cropperReady)="detailCropperReady()" (loadImageFailed)="detailImageLoadFailed()" [cropperStaticHeight]="695"
        [cropperStaticWidth]="1043"></image-cropper>

    <div class="mb-3">
        <label for="teaserText" class="form-label mainLabel">{{'courseManagement.courseDescription' |
            translate}}</label>
        <textarea class="form-control" id="teaserText" name="teaserText"
            [placeholder]="'courseManagement.courseDescription' | translate"></textarea>
    </div>

    <div class="mt-3 mb-3">
        <label for="descriptionFormat" class="form-label mainLabel">Detaylı Açıklama Formatı</label>
        <select class="form-select" (change)="selectFormat($event)" id="descriptionFormat" name="descriptionFormat">
            <option [selected]="currentFormat == 0" [value]="0">PlainText</option>
            <option [selected]="currentFormat == 1" [value]="1">HTML</option>
            <option [selected]="currentFormat == 2" [value]="2">Markdown</option>
        </select>
    </div>
    <div class="mb-3" [class.displayNone]="selectedFormat !== 1">

        <label for="" class="form-label mainLabel">{{'courseManagement.courseDetailedDescription' | translate}}</label>

        <textarea *ngIf="currentFormat == 1" [id]="'description'" name="description" class="description-layout-editor"
            id="1"
            data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            autocomplete="off"
            data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;">{{educationModel.description}}</textarea>
        <textarea *ngIf="currentFormat != 1" [id]="'description'" name="description" class="description-layout-editor"
            id="1"
            data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            autocomplete="off"
            data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;"></textarea>

    </div>
    <div class="mb-3" [class.displayNone]="selectedFormat == 1">
        <label for="" class="form-label mainLabel">{{'courseManagement.courseDetailedDescription' | translate}}</label>
        <textarea class="form-control" id="descriptionTextarea" style="height: 250px;"></textarea>
    </div>


    <label for="categories" class="form-label mainLabel">{{'courseManagement.category' | translate}}</label><br />
    <div class="mb-3 input-group">

        <select class="form-control form-select" id="categories" multiple name="categories">
            <option *ngFor="let category of educationCategoryList" [value]="category.value">{{category.label}}</option>
        </select>
        <button class="btn btn-outline-secondary" type="button" id="button-addon2"
            (click)="showAddCategoryModal()">{{'courseManagement.createNew' | translate}}</button>
    </div>
    <div class="mb-3">
        <label for="level" class="form-label mainLabel">{{'courseManagement.courseLevel' | translate}}</label>
        <select class="form-control form-select" id="level" name="level">
            <option [value]="0">{{'courseLevel.Beginner' | translate}}</option>
            <option [value]="1">{{'courseLevel.Intermediate' | translate}}</option>
            <option [value]="2">{{'courseLevel.Advanced' | translate}}</option>
        </select>
    </div>
    <label for="theme" class="form-label mainLabel">Tema</label><br />
    <div class="mb-3 input-group">

        <select class="form-control form-select" id="theme" name="theme">
            <option selected>Tema Ekleme</option>
            <option *ngFor="let theme of themeList" [value]="theme.value">{{theme.label}}</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="courseLength" class="form-label mainLabel">{{'courseManagement.courseLength' | translate}}</label>
        <input type="text" class="form-control" id="courseLength" name="courseLength"
            [placeholder]="'courseManagement.courseLength' | translate">
    </div>
    <div style="text-align: right;">
        <button [disabled]="loading" class="btn btn-sm btn-primary" (click)="updateEducation()">{{'btn.update' |
            translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span></button>
    </div>

</div>