import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-delete-education-category',
  templateUrl: './delete-education-category.component.html',
  styleUrls: ['./delete-education-category.component.css']
})
export class DeleteEducationCategoryComponent implements OnInit {
  categoryId:number;
  categoryTreeId:number;
  checkedUnderstandCheck:boolean = false;
  success:boolean = false;
  loading:boolean = false;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private educationService:EducationService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.categoryId = parseInt(param["categoryId"]);
        this.categoryTreeId = parseInt(param["categoryTreeId"])
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteCategory(){
    var keepChildren = document.getElementById("keepChildren") as HTMLInputElement;
    this.loading = true;
    this.educationService.deleteCategory(this.categoryId,this.categoryTreeId,keepChildren.checked).subscribe({next:(response)=>{
      this.toastrService.success(this.translateService.instant("courseManagement.deleteCategorySuccessful"));
      this.loading = false;
    },error:(err)=>{
      this.loading = false;
      this.toastrService.error(this.translateService.instant("courseManagement.deleteCategoryError"));
    }})
  }

}
