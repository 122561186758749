<div class="formMain form-default-border form-maroon-border">
    <h5>{{'setupVmInstances.cloneVm' | translate}}</h5>
    <hr />
    <div *ngIf="success == false">
        <div>
            <label class="form-label">{{'setupVmInstances.clone.newVmName'}}</label>
            <input type="text" class="form-control" id="newVmName">
        </div>
        <button class="btn btn-primary btn-sm mt-2" (click)="clone()">{{'btn.clone' | translate}}</button>
    </div>
    <div *ngIf="success == true" style="text-align: center;">
        <i class="bi bi-check-circle-fill" style="font-size: 55px; color:rgb(0, 164, 0)"></i>
        <h3>{{'global.formSuccessMessage' | translate}}</h3>
    </div>
</div>