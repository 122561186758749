import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ListResponseModel } from '../../models/listResponseModel';
import { IconSetGridResponse } from '../../models/Icons/iconSetGridResponse';
import { IconGridResponse } from '../../models/Icons/iconGridResponse';
import { ResponseModel } from '../../models/responseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { IconSetDetailsResponse } from '../../models/Icons/iconSetDetailsResponse';
import { IconDetailsResponse } from '../../models/Icons/iconDetailsResponse';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private httpClient:HttpClient) { }
  getIconSets(){
    var apiUrl = environment.apiUrl + "api/cms/iconsets/grid";
    return this.httpClient.get<ListResponseModel<IconSetGridResponse>>(apiUrl);
  }
  getIconsWithIconSet(iconSetId:string,page:number = 1){
    var apiUrl = environment.apiUrl + "api/cms/iconsets/"+iconSetId+"/icons/grid";
    return this.httpClient.get<ListResponseModel<IconGridResponse>>(apiUrl);
  }
  deleteIconSet(iconSetId:string,confirmation:boolean){
    var apiUrl = environment.apiUrl + "api/cms/iconsets/icons/"+iconSetId+"/delete";
    return this.httpClient.post<ResponseModel>(apiUrl,{
      confirmation:confirmation,
      id:iconSetId
    });
  }
  deleteIcon(id:number){
    var apiUrl = environment.apiUrl + "api/cms/iconsets/icons/"+id+"/delete";
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:{id:id}});
  }
  getIconSetDetails(setId:string){
    var apiUrl = environment.apiUrl + `api/cms/iconsets/${setId}/details`
    return this.httpClient.get<SingleResponseModel<IconSetDetailsResponse>>(apiUrl);
  }
  getIconDetails(iconId:number){
    var apiUrl = environment.apiUrl + `api/cms/iconsets/icons/${iconId}/details`
    return this.httpClient.get<SingleResponseModel<IconDetailsResponse>>(apiUrl);
  }
}
