<div class="main" *ngIf="selectedUser">
    <div class="userIcon">
        <i class="bi bi-person-circle personIcon"></i>
    </div>
    <div class="username">
        {{selectedUser.username}}
    </div>
    <div class="alert alert-info" role="alert">
        {{!selectedUser.blocked == true ? ('userManagement.blockUserMessage' | translate) : ('userManagement.unblockUserMessage' | translate)}}
    </div>
    <input type="checkbox" (change)="checkChanged($event)" id="understandCheck" name="understandCheck"/><label for="understandCheck">{{'global.understandCheckboxMessage' | translate}}</label>
    <br/>
    <hr/>
    <div class="approveOrRejectButton">
        <button class="btn btn-danger btn-sm" (click)="toggleBlock()" [disabled]="checkedUnderstandCheck == false"><i class="bi bi-person-dash"></i> {{!selectedUser.blocked ? ('userManagement.block' | translate) : ('userManagement.unblock') | translate}}</button>&nbsp;&nbsp;
        <button class="btn btn-danger btn-sm modal-close-btn">{{'btn.cancel' | translate}}</button>
    </div>
</div>
