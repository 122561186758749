import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../../services/tenant/tenant.service';
import { TenantResponseModel } from '../../../models/TenantModels/CmsTenant/tenantResponseModel';

@Component({
  selector: 'lib-get-tenant-details',
  templateUrl: './get-tenant-details.component.html',
  styleUrls: ['./get-tenant-details.component.css']
})
export class GetTenantDetailsComponent implements OnInit {
  tenantId:string;
  tenantResponse:TenantResponseModel;
  constructor(private apiUrlService:ApiUrlService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private tenantService:TenantService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.tenantId = param["id"];
        this.tenantService.getTenantResponse(this.tenantId).subscribe({
          next:(response)=>{
            this.tenantResponse = response.content;
          },error:(err)=>{
            this.toastrService.error("An error occurred");
          }
        })
      }
    })
  }

}
