import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-icon-set',
  templateUrl: './delete-icon-set.component.html',
  styleUrls: ['./delete-icon-set.component.css']
})
export class DeleteIconSetComponent implements OnInit {
  itemId:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.itemId = param["id"];
      }
    })
  }

}
