<div class="iconsContainer">
<div class="iconSets formMain form-maroon-border form-default-border" style="min-height: 80vh;">
    <h5>{{'icons.iconsGridTitle' | translate}}</h5>
    <hr/>
    <div class="iconSets-toolbar">
        <div (click)="returnIconsets()" class="iconSets-toolbar-item ">
            <i class="bi bi-boxes primary"></i><font> {{'btn.returnIconsets' | translate}}</font>
        </div>
        <div (click)="addNew()" class="iconSets-toolbar-item ">
            <i class="bi bi-plus-lg success"></i><font> {{'btn.add' | translate}}</font>
        </div>
        <div (click)="updateIcon()" *ngIf="selectedIconId != null" class="iconSets-toolbar-item ">
            <i class="bi bi-pencil-fill warning"></i><font> {{'btn.edit' | translate}}</font>
        </div>
        <div class="iconSets-toolbar-item" (click)="deleteIcon()" *ngIf="selectedIconId != null">
            <i class="bi bi-trash-fill danger"></i><font> {{'btn.delete' | translate}}</font>
        </div>
    </div>
    <hr/>
    <div class="iconSets-area" (click)="removeSelectedAttributes($event)">


    <div class="iconSets-content">
        <div class="iconSet-item" (dblclick)="routeIconDetails(icon.id)"  #iconSet (click)="selectIcon($event,icon.id)" *ngFor="let icon of icons">
            <div class="iconSet-img-container">
                <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/icon/'+icon.id" />
            </div>
            <div class="iconSet-info">
                <b class="iconSet-iconName">{{icon.name}}</b>
                <font class="iconSet-iconCode">{{icon.iconCode}}</font>
            </div>
        </div>
    </div>
    <div class="iconSets-details">

    </div>
</div>
</div>
<div class="icon-details formMain form-maroon-border form-default-border">
    <h5>{{'icons.iconDetailsTitle' | translate}}</h5>
    <hr>
    <div *ngIf="selectedIconId != null && iconDetails != null" class="details">
        <div class="detail-area">
            <div class="iconSet-item">
                <div class="iconSet-img-container" style="width: 100%;">
                    <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/icon/'+selectedIconId" style="max-width:200px; width: 100%;"/>
                </div>
            </div>
            <hr/>
            <div><b>{{'icons.name' | translate}} : </b><font>{{iconDetails.name}}</font></div>
            <div><b>{{'icons.iconCode' | translate}} : </b><font>{{iconDetails.iconCode}}</font></div>
            <div *ngIf="iconDetails.applicationId"><b>{{'icons.applicationId' | translate}}</b><font>{{iconDetails.applicationId}}</font></div>
            <div *ngIf="iconDetails.tenantId"><b>{{'icons.tenantId' | translate}}</b><font>{{iconDetails.tenantId}}</font></div>
            <div *ngIf="iconDetails.locale"><b>{{'icons.locale' | translate}}</b><font>{{iconDetails.locale}}</font></div>
        </div>
        <br>
        <div class="alternative-icons" *ngIf="iconDetails.alternatives.length > 0">

            <h5>{{'icons.alternatives' | translate}}</h5>
            <hr>
            <div class="iconSet-item" (dblclick)="routeIconDetails(icon.id)" *ngFor="let icon of iconDetails.alternatives">
                <div class="iconSet-img-container">
                    <img class="iconSet-img" [src]="apiUrl+'api/cms/iconsets/icon/'+icon.id" />
                </div>
                <div class="iconSet-info">
                    <b class="iconSet-iconName">{{icon.name}}</b>
                    <font class="iconSet-iconCode">{{icon.iconCode}}</font>
                </div>
            </div>
        </div>
    </div>

</div>
</div>