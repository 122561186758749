import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../../services/applications/application.service';
import { ApplicationDetailsModel } from '../../../models/Applications/applicationDetailsModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.css']
})
export class ApplicationDetailsComponent implements OnInit {
  appDetails:ApplicationDetailsModel
  constructor(private activatedRoute:ActivatedRoute, private applicationService:ApplicationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        var id = param["appId"];
        this.applicationService.getApplicationDetails(id).subscribe({
          next:(response)=>{
            this.appDetails = response.content;
          }
        })
      }
    })
  }

}
