import { environment } from './../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NavbarService } from './navbar.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarMainService implements NavbarService {

  constructor(private httpClient:HttpClient) { }
  public getMenuItems(): Observable<JSON>
  {
    var tenantUrl = environment.tenantUrl;
    return this.httpClient.get<any>(tenantUrl+"getmenu");
  }
}
