import { Component, OnInit } from '@angular/core';
import { ServiceAccountManagerService } from '../../../services/service-account-manager.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-service-account',
  templateUrl: './delete-service-account.component.html',
  styleUrls: ['./delete-service-account.component.css']
})
export class DeleteServiceAccountComponent implements OnInit {

  //username:string = "";
  checkedUnderstandCheck:boolean = false;
  //selectedUser:any;
  userId:string;
  constructor(private serviceAccountManager:ServiceAccountManagerService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }
  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param['user'];
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteUser(){
    this.serviceAccountManager.deleteServiceAccount(this.userId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Kullanıcı silindi");
        }else{
          this.toastrService.error("Kullanıcı silinemedi");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu")
      }
    })
  }

}
