import { ChartModel } from './../../models/CustomChartModels/chartModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomChartService {

  constructor(private httpClient:HttpClient) { }
  public externalUrl:string = "";
  getChart():Observable<ChartModel>{
    return this.httpClient.get<ChartModel>(this.externalUrl);
  }
}
