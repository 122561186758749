<div class="create-basevmimage-note formMain form-maroon-border form-default-border">
    <h4>{{'baseVmImage.note.updateForm' | translate}}</h4>
    <div class="title">
        <label>Note Title</label>
        <input type="text" class="form-control" id="note-title" />

    </div>

    <div class="content">
        <label>Note Content</label>
            <div id="summernote" style="height: 250px;">

            </div>

    </div>
    <div class="actions">
        <button class="btn btn-sm btn-primary" (click)="updateNote()">{{'btn.update' | translate}}</button>
    </div>
</div>