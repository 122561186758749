import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { SingleResponseModel } from 'projects/usorta-library/src/lib/models/singleResponseModel';
import { ApiUrlService } from 'projects/usorta-library/src/lib/services/api/api-url.service';
import { Observable } from 'rxjs';
import { ActiveUserSessionsResponse } from '../models/activeSessionsResponse';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';
import { ResponseTokenModel } from 'projects/usorta-library/src/lib/models/responseTokenModel';

@Injectable({
  providedIn: 'root'
})
export class AdminManagerService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  public getUsers():Observable<any>{
    return this.httpClient.get<any>(environment.apiUrl+"api/accounts/admins");
  }
  public getUserById(userId:string):Observable<any>{
    return this.httpClient.get<any>(environment.apiUrl+"api/accounts/admin/"+userId);
  }
  public deleteUserById(userId:string):Observable<any>{
    return this.httpClient.delete(environment.apiUrl+"api/accounts/admin/"+userId+"/delete");
  }
  public blockUser(userId:string):Observable<any>{
    return this.httpClient.post(environment.apiUrl+"api/accounts/admin/"+userId+"/block",{userId:userId});
  }
  public unblockUser(userId:string):Observable<any>{
    return this.httpClient.post(environment.apiUrl+"api/accounts/admin/"+userId+"/unblock",{userId:userId});
  }
  getChangeUserPasswordForm(userId:string):Observable<CustomForm>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/admin/"+userId+"/changepassword";
    return this.httpClient.get<CustomForm>(formRequestAddr);
  }
  getUpdateUserForm(userId:string):Observable<CustomForm>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/admin/"+userId+"/update";
    return this.httpClient.get<CustomForm>(formRequestAddr);
  }
  getCreateAdminForm():Observable<SingleResponseModel<CustomForm>>{
    let apiUrl = this.apiUrlService.getApiUrl();
    let formRequestAddr = apiUrl+"api/accounts/admin/create";
    return this.httpClient.get<SingleResponseModel<CustomForm>>(formRequestAddr);
  }
  getAdminSessions(userId:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.get<SingleResponseModel<ActiveUserSessionsResponse>>(`${apiUrl}api/accounts/admins/${userId}/sessions`)
  }
  logout(sessionId:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/accounts/admin/sessions/${sessionId}/logout`,{sessionId:sessionId})
  }
  adminRefreshToken(refreshToken:string){
    let apiUrl:string = this.apiUrlService.getApiUrl();
    return this.httpClient.post<SingleResponseModel<ResponseTokenModel>>(`${apiUrl}api/accounts/admin/refresh-token`,{jwt:refreshToken});
    
  }
}

