<select id="typeSelect" (change)="changeType($event)" class="form-select">
    <option value="course">Course</option>
    <option value="lab">Lab</option>
</select>
<br/>
<select id="sourceIdSelect" (change)="changeSourceId($event)" class="form-select"  *ngIf="options.length > 0 && loading == false">
    <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
</select>
<div class="clearfix" *ngIf="loading">
    <div class="spinner-border float-end" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
<hr>
<button style="float:right;" class="btn btn-primary btn-sm" (click)="emitData()">{{'btn.add' | translate}}</button>
