import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { DeleteInstallerImageModel } from '../../../models/VirtualLabModels/deleteInstallerImageModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-delete-installer-image',
  templateUrl: './delete-installer-image.component.html',
  styleUrls: ['./delete-installer-image.component.css']
})
export class DeleteInstallerImageComponent implements OnInit {
  imageList:ListItemModel[];
  imageId:string;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder,private toastrService:ToastrService,private virtualLabService:VirtualLabService) { }
  deleteForm:FormGroup;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.imageId = param["imageId"];
      }
    })
    this.virtualLabService.getInstallerImageOptions().subscribe({
      next:(response)=>{
        console.log("imaj listesi")
        console.log(response);
        this.imageList = response.content;
        this.createDeleteForm();
      }
    })
  }
  createDeleteForm(){
    this.deleteForm = this.formBuilder.group({
      moveLinkedVMsTo:[],
      deleteLinkedVMs:[false]
    })
  }
  deleteInstallerImage(){
    if(this.deleteForm.valid){
      var values:DeleteInstallerImageModel = Object.assign({},this.deleteForm.value);
      values.imageId = this.imageId;
      this.virtualLabService.deleteInstallerImage(values).subscribe({
        next:(response)=>{
          this.toastrService.success("Installer Image deleted successfully")
        },
        error:()=>{
          this.toastrService.error("An error occurred");
        }
      })
    }else{
      this.toastrService.error("Please check form data");
    }
  }

}
