import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QuestionService } from '../../../services/questionManager/question.service';
import { CreateQuestionOptionModel } from '../../../models/questionManagementModels/createQuestionOptionModel';

@Component({
  selector: 'lib-create-question-option',
  templateUrl: './create-question-option.component.html',
  styleUrls: ['./create-question-option.component.css']
})
export class CreateQuestionOptionComponent implements OnInit {
  questionId:number;
  optionFormat:number[] = [0];
  optionTextValue:any;
  wrongAnswerInfoFormat:number[] = [0];
  wrongAnswerInfoTextValue:any;
  optionForm:FormGroup;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder,private toastr:ToastrService,private questionService:QuestionService) { }

  ngOnInit(): void {
    this.createOptionForm();
    this.getQuestionId();
  }
  getOptionTextFnc($ev:any){
    this.optionTextValue = $ev;
  }
  changeOptionFormat($ev:any){
    var el = ($ev.target) as HTMLInputElement
    this.optionFormat = [parseInt(el.value)];
  }
  changeWrongAnswerInfoFormat($ev:any){
    var el = ($ev.target) as HTMLInputElement
    this.wrongAnswerInfoFormat = [parseInt(el.value)];
  }
  getWrongAnswerInfoTextFnc($ev:any){
    this.wrongAnswerInfoTextValue = $ev;
  }
  createOptionForm(){
    this.optionForm = this.formBuilder.group({
      questionId:[0],
      value:["",Validators.required],
      label:["",Validators.required],
      format:[0,Validators.required],
      active:[false],
      optionPrefix:[""],
      orderValue:[0],
      isCorrect:[false],
      scoreFactor:[100],
      wrongAnswerInfo:[""],
      wrongAnswerInfoFormat:[0]
    })
  }
  getQuestionId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = parseInt(param["questionId"]);
      }
    })
  }
  createOption(){
    this.optionForm.get("label")?.setValue(this.optionTextValue());
    this.optionForm.get("wrongAnswerInfo")?.setValue(this.wrongAnswerInfoTextValue());
    if(this.optionForm.valid){
      
      var optionValues:CreateQuestionOptionModel = Object.assign({},this.optionForm.value);
      optionValues.wrongAnswerInfoFormat = typeof(optionValues.wrongAnswerInfoFormat) == "string" ? parseInt(optionValues.wrongAnswerInfoFormat) : optionValues.wrongAnswerInfoFormat
      optionValues.format = typeof(optionValues.format) == "string" ? parseInt(optionValues.format) : optionValues.format
      optionValues.orderValue = typeof(optionValues.orderValue) == "string" ? parseInt(optionValues.orderValue) : optionValues.orderValue
      optionValues.questionId = this.questionId;
      this.questionService.addOptionToQuestion(optionValues).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastr.success("Seçenek eklendi");
          }else{
            this.toastr.error("Seçenek eklenemedi");
          }
        },
        error:(err)=>{
          this.toastr.error("Seçenek eklenemedi");
        }
      })
    }else{
      this.toastr.error("Formu kontrol edin");
    }
  }


}
