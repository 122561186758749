import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DynamicFormToJsonElementTypesModel } from 'projects/usorta-library/src/lib/models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { ControlCrtiteriaModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/controlCriteriaModel';
import { UpdateControlCriteriaModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/updateControlCriteriaModel';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';
declare var window:any;
declare var $:any;
declare var monaco:any;
@Component({
  selector: 'lib-update-control-criteria',
  templateUrl: './update-control-criteria.component.html',
  styleUrls: ['./update-control-criteria.component.css']
})
export class UpdateControlCriteriaComponent implements OnInit {
  criteriaList: DynamicFormToJsonElementTypesModel[] = [];
  addParam: DynamicFormToJsonElementTypesModel[] = [];
  resetForm: boolean[] = [false];
  controlCriteriaForm:FormGroup;
  params:string = "";
  instructions:string = "";
  selectedLang:number = 0;
  labId:string;
  crtiteriaId:number;
  criterContent:ControlCrtiteriaModel;
  constructor(private activatedRoute:ActivatedRoute, private formBuilder:FormBuilder,private toastrService:ToastrService,private virtualLabService:VirtualLabService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.labId = param["labId"];
        this.crtiteriaId = parseInt(param["crtiteriaId"]);
        this.getControlCriteria();
      }
    })
  }
  createUpdateControlCriteriaForm(){
    this.controlCriteriaForm = this.formBuilder.group({
      title:[this.criterContent.title,Validators.required],
      summary:[this.criterContent.summary],
      instructionsFormat:[this.criterContent.instructionsFormat],
      orderValue:[this.criterContent.orderValue],
      type:[this.criterContent.type,Validators.required]
    })
  }
  initializeMonaco(){
    if(window["criteriaEditor"] != undefined){
      window["criteriaEditor"].setValue(this.instructions);
      return;
    }
    setTimeout(()=>{
      var lang = "plaintext"
      if(this.selectedLang == 1){
        lang = "html"
      }else if(this.selectedLang == 2){
        lang = "markdown";
      }
      var editorDiv = document.getElementsByClassName("controlMonaco")[0] as HTMLDivElement;
      var editor = monaco.editor.create(editorDiv,{
        value: "",
        language: lang,
        theme: 'vs-light',
      })
      window["criteriaEditor"] = editor;
      window["criteriaEditor"].setValue(this.instructions);
    },500)

  }
  changeLang(){
    this.selectedLang = this.controlCriteriaForm.get("instructionsFormat")?.value;
    if(window["criteriaEditor"] !== undefined){
      console.log("lang changed");
      var lang = "plaintext"
      if(this.selectedLang == 1){
        lang = "html"
      }else if(this.selectedLang == 2){
        lang = "markdown";
      }
      monaco.editor.setModelLanguage(window["criteriaEditor"].getModel(), lang);
    }

  }
  onParamsChange(ev:string){
    var p = ev;
    var js = JSON.parse(p);
    if(js != undefined || js != ""){
      this.params = js[0];
    }else{
      this.params = "";
    }
  }
  getControlCriteria(){
    this.virtualLabService.getControlCriteria(this.labId,this.crtiteriaId).subscribe({
      next:(response)=>{
        this.criterContent = response.content;
        this.instructions = this.criterContent.instructions;
        this.selectedLang = this.criterContent.instructionsFormat;
        this.createCriteriaList();
        this.createUpdateControlCriteriaForm();
        this.changeLang();
        setTimeout(()=>{
          this.initParams(this.criterContent.type);
        },100)

      }
    })
  }
  saveInstructions(){
    var editor = window["criteriaEditor"]
    if(editor !== undefined){
      this.instructions = editor.getValue();
    }
  }
  initParams(type:string){
    var params = this.criterContent.params;
    this.resetForm = [true];
    this.addParam = [];
    setTimeout(()=>{
      switch (type) {
        case "textProof":
  
          this.textProofControl(params);
          break;
        case "createFile":
  
          this.createFileControl(params)
          break;
        case "fileContents":
  
          this.fileContentsControl(params);
          break;
        case "userLogin":
  
          this.userLoginControl(params);
  
          break;
      }
    },50)
  }
  changeType(ev: any) {
    this.resetForm = [true];
    this.addParam = [];
    setTimeout(()=>{
      var type = $(ev.target).val();
      switch (type) {
        case "textProof":
  
          this.textProofControl();
          break;
        case "createFile":
  
          this.createFileControl()
          break;
        case "fileContents":
  
          this.fileContentsControl();
          break;
        case "userLogin":
  
          this.userLoginControl();
  
          break;
      }
    },50)

  }
  textProofControl(params?:any) {
    this.addParam = [
      {
        typeName: "textProof",
        typeText: "Text Proof",
        element: [
          { label: "Değer", name: "value", valueType: "string",defaultValue:params ? params.value : undefined },
          { label: "Regex", name: "isRegex", valueType: "boolean",defaultValue:params ? (params.isRegex ? "true" : "false") : undefined }
        ]
      }
    ]
  }
  createFileControl(params?:any) {
    this.addParam = [
      {
        typeName: "createFile",
        typeText: "Dosya Oluşturma Kontrolü",
        element: [
          { label: "Dosya Konumu", name: "filePath", valueType: "string",defaultValue:params ? params.filePath : undefined },
          { label: "Hedef VM", name: "targetVM", valueType: "string",defaultValue:params ? params.targetVM : undefined }
        ]
      }
    ]
  }
  fileContentsControl(params?:any) {
    this.addParam = [
      {
        typeName: "fileContents",
        typeText: "Dosya İçerik Kontrolü",
        element: [
          { label: "Dosya Konumu", name: "filePath", valueType: "string",defaultValue:params ? params.filePath : undefined },
          { label: "Hedef VM", name: "targetVM", valueType: "string",defaultValue:params ? params.targetVM : undefined },
          { label: "Değer", name: "value", valueType: "string",defaultValue:params ? params.value : undefined },
          {
            label: "Kontrol Modu", name: "checkMode", valueType: "select", subItems: [
              {
                label: "Exact",
                selected: false,
                value: "exact"
              },
              {
                label: "Contains",
                selected: true,
                value: "contains"
              },
              {
                label: "Regex",
                selected: false,
                value: "regex"
              }
            ]
          }
        ]
      }
    ]
  }
  userLoginControl(params?:any) {
    this.addParam = [
      {
        typeName: "userLogin",
        typeText: "Belirtilen Kullanıcı İle Giriş Kontrolü",
        element: [
          { label: "Hedef VM", name: "targetVM", valueType: "string",defaultValue:params ? params.targetVM : undefined },
          { label: "Kullanıcı", name: "user", valueType: "string", defaultValue:params ? params.user : undefined }

        ]
      }
    ]
  }
  createCriteriaList() {
    this.criteriaList.push({
      typeName: "textProof",
      typeText: "Text Proof",
      element: [
        { label: "Değer", name: "value", valueType: "string" },
        { label: "Regex", name: "isRegex", valueType: "boolean" }
      ]
    })
    this.criteriaList.push({
      typeName: "createFile",
      typeText: "Dosya Oluşturma Kontrolü",
      element: [
        { label: "Dosya Konumu", name: "filePath", valueType: "string" },
        { label: "Hedef VM", name: "targetVM", valueType: "string" }
      ]
    })
    this.criteriaList.push({
      typeName: "fileContents",
      typeText: "Dosya İçerik Kontrolü",
      element: [
        { label: "Dosya Konumu", name: "filePath", valueType: "string" },
        { label: "Hedef VM", name: "targetVM", valueType: "string" },
        { label: "Değer", name: "value", valueType: "string" },
        {
          label: "Kontrol Modu", name: "checkMode", valueType: "select", subItems: [
            {
              label: "Exact",
              selected: false,
              value: "exact"
            },
            {
              label: "Contains",
              selected: true,
              value: "contains"
            },
            {
              label: "Regex",
              selected: false,
              value: "regex"
            }
          ]
        }
      ]
    })
    this.criteriaList.push({
      typeName: "userLogin",
      typeText: "Belirtilen Kullanıcı İle Giriş Kontrolü",
      element: [
        { label: "Hedef VM", name: "targetVM", valueType: "string" },
        { label: "Kullanıcı", name: "user", valueType: "string" }

      ]
    })
  }
  updateControlCriteria(){
    if(this.controlCriteriaForm.valid){
      var ccfModel:UpdateControlCriteriaModel = Object.assign({},this.controlCriteriaForm.value);
      ccfModel.params = this.params;
      ccfModel.instructions = this.instructions;
      ccfModel.id = this.crtiteriaId;
      ccfModel.labId = this.labId;
      console.log(ccfModel);
      ccfModel.labId = this.labId;
      this.virtualLabService.updateControlCriteria(this.labId,ccfModel).subscribe({
        next:(response)=>{
          this.toastrService.success("Denetim Kriteri Eklendi");
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
