import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { SessionTemplateItem } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/sessionTemplateItem';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-add-session-template-item',
  templateUrl: './add-session-template-item.component.html',
  styleUrls: ['./add-session-template-item.component.css']
})
export class AddSessionTemplateItemComponent implements OnInit {

  constructor(private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }
  selectedType:string;
  selectedId:string;
  options:ListItemModel[] = [];
  loading:boolean = false;
  @Output() templateItemData:EventEmitter<SessionTemplateItem> = new EventEmitter<SessionTemplateItem>();
  ngOnInit(): void {
    var selectEl = document.getElementById("typeSelect") as HTMLSelectElement;
    this.selectedType = selectEl.value;
    this.getOptions();
  }
  getOptions(){
    this.loading = true;
    this.sessionTemplateService.getSessionTemplateItemSourceId(this.selectedType).subscribe({
      next:(response)=>{
        this.loading = false;
        this.options = response.content;
        console.log(this.options)
        setTimeout(()=>{
          var selectIdElement = document.getElementById("sourceIdSelect") as HTMLSelectElement;
          this.selectedId = selectIdElement.value;
        },20);

      },error:(err)=>{
        this.loading = false;
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  changeType($ev:any){
    this.selectedType = $ev.target.value;
    this.getOptions();
  }
  changeSourceId($ev:any){
    this.selectedId = $ev.target.value;

  }
  emitData(){
    console.log({itemType:this.selectedType,itemSourceId:this.selectedId})
    var sessionTemplateItem:SessionTemplateItem = {
      itemType:this.selectedType,
      itemSourceId:this.selectedId
    };
    this.templateItemData.emit(sessionTemplateItem);
  }

}
