<div>
    <div class="counterItem">
        <div class="counterLabel">
            <div class="counterLabelText">
                {{hostDashobardCounter.label}} 
            </div>

            <i data-bs-container="body" *ngIf="hostDashobardCounter.info != undefined && hostDashobardCounter.info != ''" style="color: rgb(88, 141, 44); cursor: pointer;" class="bi bi-question-circle-fill" data-bs-toggle="popover" attr.data-bs-content="{{hostDashobardCounter.info}}"></i>
        </div>
        
        
        <div class="counterValue">{{hostDashobardCounter.prefix}}<font *ngIf="hostDashobardCounter.displayType == 'percent'">%</font>{{getValue()}}{{hostDashobardCounter.suffix}}</div>
    </div>
</div>

