import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-update-service-role',
  templateUrl: './update-service-role.component.html',
  styleUrls: ['./update-service-role.component.css']
})
export class UpdateServiceRoleComponent implements OnInit {
  formUrl:string;
  baseUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseUrl = environment.apiUrl;
        this.formUrl = this.baseUrl + "api/acl/service/roles/"+param["id"]+"/update";
      }
    })
  }

}
