/*class ILayoutStorageProvider
{
    getLayoutList(callback, errorCallback) {}
    getLayoutData(id, callback, errorCallback) {}
    saveLayoutData(id, name, data, saveCallback, errorCallback) {}
    getEmptyLayoutData()
    {
        return { id:"0", name: "", cssLinks: "", usableCssLinks: "", cssData: "", editorContent: "" };
    }
}

class LayoutLocalStorageProvider extends ILayoutStorageProvider
{
    dataStore = {
        'db':{},
        'store':{}
    };

    constructor(options)
    {
        super(options);
        let me = this;
        let indexedDb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB ||
            window.msIndexedDB;
        Object.defineProperty(window,'indexedDB',{
            value:indexedDb
        });
        window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction ||
            window.msIDBTransaction;
        window.IDBKeyRange = window.IDBKeyRange ||
            window.webkitIDBKeyRange || window.msIDBKeyRange

        if (!window.indexedDB)
        {
            console.error('Your browser does not support IndexDB feature');
            throw new Error('Required feature not supported: IndexDB');
        }
        var request = window.indexedDB.open("savedLayouts", 1);

        request.onerror = function(event) {
            console.log("error: ");
        };

        request.onsuccess = function(event) {
            me.dataStore['db'] = request.result;

        };

        request.onupgradeneeded = function(event) {
            var dbObj = event.target.result;
            var objectStore = dbObj.createObjectStore("layout", {keyPath: "id"});
            me.dataStore['store'] = objectStore;
        }
    }

    _generateRandomId()
    {
        return (Math.random() + 1).toString(36).substring(2);
    }

    getLayoutList(callback, errorCallback)
    {
        let db = this.dataStore['db'];
        let objectStore = db.transaction("layout").objectStore("layout");
        let layoutList = [];
        let req = objectStore.openCursor();
        req.onsuccess = function(event)
        {
            var cursor = event.target.result;

            if (cursor) {
                layoutList.push({id: cursor.value.id, name: cursor.value.name});
                cursor.continue();
            }
            else
            {
                if(typeof(callback) === 'function')
                {
                    callback(layoutList);
                }
            }
        };

        req.onerror = function(event)
        {
            console.error(event);
            if(typeof(errorCallback) === 'function')
            {
                errorCallback(event);
            }
        };
    }

    getLayoutData(id, callback, errorCallback)
    {
        var db = this.dataStore['db'];
        if(db == null){
            if(typeof(errorCallback) === 'function')
            {
                errorCallback('DB error');
            }
            return;
        }
        var transaction = db.transaction(["layout"]);
        var objectStore = transaction.objectStore("layout");
        var request = objectStore.get(id);

        request.onerror = function(event) {
            if(typeof(errorCallback) === 'function')
            {
                errorCallback(event);
            }
        };

        request.onsuccess = function(event) {
            if(typeof(callback) === 'function')
            {
                //let layoutData
                callback(request.result);
            }
        };
    }

    saveLayoutData(id, name, data, saveCallback, errorCallback)
    {
        var db = this.dataStore['db']
        if(db == null){
            return;
        }
        let layoutData = { id: id || this._generateRandomId(), name: name, cssLinks: data.cssLinks,
            usableCssLinks: data.usableCssLinks, cssData: data.cssData, editorContent: data.editorContent };
        var request = db.transaction(["layout"],"readwrite").objectStore("layout").add(layoutData);
        request.onerror = function(event){
            console.log(event)
            if(typeof(errorCallback) === 'function')
            {
                errorCallback(event);
            }
        }
        request.onsuccess = function(event){
            console.log(event);
            if(typeof(saveCallback) === 'function')
            {
                saveCallback(event);
            }
        }
    }
}

window.layoutStorageProviders = {
    'local': LayoutLocalStorageProvider
};
*/
(function ($, global)
{
    "use strict";
    $.widget("lms.usortaEditor", {
        options: {
            'styles': '',
            'usable-style-links': '',
            'layoutStorageProvider': 'local',
            'fileStorageProvider': 'api',
            'layoutStorageProviderOptions': '',
            'fileStorageProviderOptions': { 'global': global },
            'variables': '',
            'placeholderStartChar':'[[',
            'placeholderEndChar':']]',
			      'container-name':'layout-container',
            'toolbarFeatures':'selectBlock,certificateSettings,editStyle,title,1Column,2Column,3Column,image,qrCode,signees,preview'
        },
        previewOptions: {
            'showPreview': false
        },
        certificateSettings: {
            'width': 0,
            'height': 0,
            'background-color': '#ffffff',
            'size': 'initial',
            'position': 'initial',
            'repeat': 'initial',
            'certificateImage': ''
        },
        layoutOptions: [],
        layoutStorageProvider: null,
        fileStorageProvider: null,
        data: {

        },
        fileData: {
            'file': null,
            'type': null
        },
        widgets: {

        },
        movingObject: {
            'selectedElement': null,
            'isMoving': false
        },
        widgetTemplates: {
        },
        dataStore: {
            'db': {},
            'store': {}
        },
        featureMap: {
            'layout-container': 'style,cssClasses,showId',
            'title-bar': 'edit,remove,style,cssClasses,draggableMenu,selectedBlockText,zindex,showId,changeId',
            'one-column': 'edit,remove,style,cssClasses,draggableMenu,selectedBlockText,zindex,showId,changeId',
            'two-column': 'remove,style,cssClasses,selectedBlockText,zindex,showId,changeId',
            'three-column': 'remove,style,cssClasses,selectedBlockText,zindex,showId,changeId',
            'column': 'edit,style,cssClasses,draggableMenu,selectedBlockText,zindex,showId,changeId',
            'image': 'remove,style,cssClasses,draggableMenu,selectedBlockText,zindex,showId,changeId',
            'qr-code': 'remove,draggableMenu,selectedBlockText,zindex,qrSettings,style,cssClasses,showId,changeId',
            'signees':'draggableMenu,selectedBlockText,zindex,style,cssClasses,signeesSettings',
            'signature':'signature,selectedBlockText,zindex,edit,remove,draggableMenu,style,cssClasses',
            'signatureSpan':'selectedBlockText,signatureSpan,style,cssClasses,remove'
        },

        reservedCssClasses: ['selected','signature', 'layout-block', 'title-bar', 'two-column', 'column', 'column-1',
            'column-2', 'column-3', 'three-column', 'one-column', 'image', 'free-position','signees'],
        usableCssClasses: [],
        certificatePlaceholders: [],
        _parsePlaceholders: function ()
        {
            let editor = this;
            let variables = editor.options.variables;
            let placeholders = [];
            console.log(variables);
            variables.split(';').forEach(function (variable)
            {
                console.log(variable)
                let keyValues = variable.split(':');
                let data = { variable: keyValues[0], description: keyValues[1] };
                if (keyValues[0] != undefined && keyValues != '')
                {
                    placeholders.push(data);
                }

            });
            editor.certificatePlaceholders = placeholders;
            console.log(placeholders.length);
        },
        saveEditorState: function ()
        {
            let editor = this;
            editor.layoutStorageProvider.getLayoutList(function (data)
            {
                //console.log(data);
                editor.showLayoutSaveModal(data);
            }, function (err)
            {
                editor.showLayoutSaveModal([]);
            });
        },
        showModalMessage: function (title, text)
        {
            let editor = this;
            let warningEl = document.createElement('div');
            warningEl.innerHTML = "<span>" + text + "</span><br/><br/><button class='btn btn-danger confirmModal'>Close Modal</button>";
            editor.showModal(warningEl, '<b>' + title + '</b>', function (editFrm, modal)
            {
                $(warningEl).on('click', function (btn)
                {
                    if ($(btn.target).hasClass('confirmModal'))
                    {
                        modal.modal('hide');
                        return false;
                    }
                });
            });
        },
        showConfirmationModal: function (title, text, okCallback, cancelCallback)
        {
            let editor = this;
            let warningEl = document.createElement('div');
            warningEl.innerHTML = "<span>" + text + "</span><br/><br/><button class='btn btn-danger confirmModal'>OK</button>" +
                "<button class='btn btn-cancel cancelModal'>Cancel</button>";
            editor.showModal(warningEl, '<b>' + title + '</b>', function (editFrm, modal)
            {
                $(warningEl).on('click', function (btn)
                {
                    if ($(btn.target).hasClass('confirmModal'))
                    {
                        modal.modal('hide');
                        if (typeof (okCallback) === 'function')
                        {
                            okCallback();
                        }
                        return false;
                    }
                    else if ($(btn.target).hasClass('cancelModal'))
                    {
                        modal.modal('hide');
                        if (typeof (cancelCallback) === 'function')
                        {
                            cancelCallback();
                        }
                        return false;
                    }
                });
            });
        },
        showLayoutSaveModal: function (layoutList)
        {
            let editor = this;
            let widgets = editor.widgets;
            let inputEl = document.createElement('div');
            console.log(layoutList);
            inputEl.innerHTML = "<span>Enter a new name to save the current editor contents as a new layout</span>" +
                "<div class='input-group' style='margin-bottom:3px;'>" +
                "<input class='form-control layoutNameInput' type='text' placeholder='Layout name'></div>" +
                "<hr><span>Or select an existing layout to overwrite</span>" +
                "<div class='input-group' style='margin-bottom:3px;'>" +
                "<select class='form-control layoutList'><option value=''>-- New Layout --</option></select></div>" +
                "<button class='btn btn-info confirmModal'>Save Layout</button>";
            editor.showModal(inputEl, '<b>Save Layout</b>', function (mdlForm, modal)
            {
                if (Array.isArray(layoutList))
                {
                    layoutList.forEach(function (item)
                    {
                        $('select.layoutList', mdlForm).append('<option value="' + item.id + '">' + item.name + '</option>');
                    });
                }
                $(inputEl).on('click', function (btn)
                {
                    if ($(btn.target).hasClass('confirmModal'))
                    {
                        let name = $(".layoutNameInput", mdlForm).val();
                        let id = $('.layoutList', mdlForm).val();
                        // saveLayoutData(id, name, data, saveCallback, errorCallback)
                        let layoutStyle = widgets['layoutStyle'].innerHTML;
                        let editorContent = widgets['contentArea'].innerHTML;
                        let cssLinks = editor.options['styles'].split(";");
                        let usableCssLinks = editor.options['usable-style-links'].split(';');

                        let layoutData = {
                            name: name, cssLinks: cssLinks, usableCssLinks: usableCssLinks,
                            cssData: layoutStyle, editorContent: editorContent
                        };

                        editor.layoutStorageProvider.saveLayoutData(id, name, layoutData,
                            function (data)
                            {
                                console.log(data);
                                modal.modal('hide');
                            }, function (err, messages)
                        {
                            modal.modal('hide');
                            let errMsg = 'Error Status: ' + err + '<br>' + $.map(messages, function (m) { m + '<br/>' });
                            editor.showModalMessage("Layout Save Failed", errMsg);
                        });
                        return false;
                    }
                })
            });
        },
        generateRandomId :function(){
            var rd = (Math.random() + 1).toString(36).substring(2);
            return rd;
        },
        assignRandomIdIfHaveLayoutBlockClass: function ()
        {
            let editor = this;
            let contentArea = editor.widgets['contentArea'];
            let layoutBlocks = contentArea.querySelectorAll('.layout-block');
            console.log(layoutBlocks)
            layoutBlocks.forEach(function(layoutBlock){
                let randomId = editor.generateRandomId();
                let blockId = $(layoutBlock).attr("data-block-id");
                console.log(blockId);
                if(blockId == "" || blockId == undefined){
                    layoutBlock.setAttribute('data-block-id',randomId);
                }
            })
            editor.updatePreview();
            editor.updateSelectBlockElement();
        },
        assignIdFromSelectedBlock:function(id){
            let editor = this;
            let contentArea = editor.widgets['contentArea'];
            let selectedElement = contentArea.querySelector('.layout-block.selected');
            $(selectedElement).attr('data-block-id',id);
            editor.updatePreview();
            editor.updateEditorTools();
            editor.updateSelectBlockElement();
        },
        updateSelectBlockElement:function(){
            let editor = this;
            let widgets = editor.widgets;
            let options = editor.options;
            let previewArea = editor.widgets['previewContent'];
            let contentArea = editor.widgets['contentArea'];
            let previewBody = $(previewArea).contents().find('body');
            let layoutBlocks = previewBody.get(0).querySelectorAll('.layout-block');

            $('.layoutBlockSelector').html('');
            let option = document.createElement('option');
            $(option).attr('disabled','disabled');
            $(option).attr('selected','selected');
            option.innerText = '-------------';
            $('.layoutBlockSelector').get(0).appendChild(option);

            let layoutContainerOption = document.createElement('option');
			let containerName = editor.options['container-name'];
            layoutContainerOption.value = containerName;
            layoutContainerOption.innerText = containerName;
            $('.layoutBlockSelector').get(0).appendChild(layoutContainerOption);

            layoutBlocks.forEach(function(block){
                let option = document.createElement('option');
                option.value = $(block).attr('data-block-id');
                option.innerText = $(block).attr('data-block-id');
                $('.layoutBlockSelector').get(0).appendChild(option);
            })

        },
        showRenameModal:function(){
            let div = document.createElement('div');
            let input = document.createElement('input');
            input.placeholder = "Please input a new block id";
            input.classList.add('input-newblockid');
            input.style.height = "30px";
            input.style.fontSize = "17px";
            input.style.padding = "5px";

            let btn = document.createElement('button');
            btn.classList.add('btn','btn-warning','changeBlockIdBtn');
            btn.innerText = "Change Block Id"

            div.appendChild(input);
            div.innerHTML+="<br/><br/>";
            div.appendChild(btn);
            editor.showModal(div, "Change Block Id", function (changeBlockIdFrm, modal){
                btn.addEventListener('click',function(){
                    let newId = $('.input-newblockid').val();
                    editor.assignIdFromSelectedBlock(newId);
                    modal.modal('hide');
                })
            }, function(){});
        },
        remove: function (layoutName)
        {
            var editor = this;
            var db = editor.dataStore['db'];
            if (db == null)
            {
                //alert("DBError");
                return;
            }
            var request = db.transaction(["layout"], "readwrite")
                .objectStore("layout")
                .delete(layoutName);

            request.onsuccess = function (event)
            {
                editor.showModalMessage("Layout Loader", layoutName + "has been deleted");
            };
        },
        _createLayoutLoader(layoutNames, warning = false)
        {
            let editor = this;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];

            $(editorTools).find('.editor-tool-panel').css('display', 'none');
            $(editorTools).find('.layout-loader').remove();
            $(editorTools).find('.certificate-settings-panel').remove();
            var layoutsDiv = document.createElement('div');
            layoutsDiv.classList.add('layout-loader')
            layoutsDiv.innerHTML = '<b style="font-size:17px;"><i class="fas fa-table"></i> Select Layout</b><br/>';
            layoutNames.forEach(function (layoutName)
            {
                var layoutNameDiv = document.createElement("div");
                layoutNameDiv.innerText = layoutName.name;
                layoutNameDiv.classList.add('layout-select-name');
                layoutNameDiv.setAttribute('data-id', layoutName.id);
                layoutsDiv.appendChild(layoutNameDiv);
            });
            editorTools.prepend(layoutsDiv);
            console.log(layoutsDiv)
        },
        _create: function ()
        {
            let editor = this;
            let widgets = editor.widgets;
            let options = this.options;
            options.targetEditor = this.element.data('target');
            let data = this.data;
            if (typeof (options.preloadData) === 'object')
            {

            }
            else
            {
                data['layoutType'] = '';
                data['layoutData'] = {
                    'regions': {},
                    'blocks': {}
                };
            }
			this._setCustomLayoutName();
            this.initializeTemplates();
            this.createWidgets();
            this.bindEventListeners();
            this.syncFromTargetEditor();
            this._parsePlaceholders();
            let certificateHeight = parseInt(widgets['contentArea'].style.height);
            let certificateWidth = parseInt(widgets['contentArea'].style.width);
            this._setWidth(certificateWidth ?? 900);
            this._setHeight(certificateHeight ?? 500);
            /*if(typeof(global.layoutStorageProviders[options.layoutStorageProvider]) === 'function')
            {
                this.layoutStorageProvider = new global.layoutStorageProviders[options.layoutStorageProvider](options.layoutStorageProviderOptions);
                console.log(this.layoutStorageProvider);
            }*/
            if (typeof (global.fileStorageProviders[options.fileStorageProvider]) === 'function')
            {
                this.fileStorageProvider = new global.fileStorageProviders[options.fileStorageProvider](options.fileStorageProviderOptions);
                console.log(this.fileStorageProvider);
            }

        },
		_setCustomLayoutName: function(){
			let editor = this;
			let containerName = editor.options['container-name'];
			editor['reservedCssClasses'].push(containerName);

		},
        _createCertificateSettingsPanel: function ()
        {
            let editor = this;
            let placeholderStartChar = editor.options['placeholderStartChar'];
            let placeholderEndChar = editor.options['placeholderEndChar'];
            let widgets = editor.widgets;
            let templates = editor.widgetTemplates;
            let editorTools = widgets['editorTools'];
            editor.fileData = {
                'file': null,
                'type': null
            }
            $(editorTools).find('.editor-tool-panel').css('display', 'none');
            $(editorTools).find('.layout-loader').remove();
            $(editorTools).find('.certificate-settings-panel').remove();
            let certSettingsPanel = templates['certificateSettingsPanel'].content.firstChild.cloneNode(true);
            let bgSettings = templates['backgroundSettingsPanel'].content.firstChild.cloneNode(true);
            let uploadFilePanel = templates['fileUpload'].content.firstChild.cloneNode(true);
            let valuePlaceholderPanel = templates['valuePlaceholders'].content.firstChild.cloneNode(true);
            valuePlaceholderPanel.innerHTML += '<b>Certificate Placeholders</b>';
            let placeholders = editor.certificatePlaceholders;
            console.log(editor.certificatePlaceholders);
            if (placeholders != undefined && placeholders.length > 0)
            {
                console.log("placeholders okundu")
                placeholders.forEach(function (placeholder)
                {
                    let placeholderDiv = document.createElement('div');
                    let placeholderNameDiv = document.createElement('div');
                    let placeholderDescriptionDiv = document.createElement('div');
                    placeholderDiv.classList.add('certificate-placeholder-div');
                    placeholderNameDiv.classList.add('certificate-placeholder-name-div');
                    placeholderDescriptionDiv.classList.add('certificate-placeholder-description-div');
                    placeholderNameDiv.innerText = placeholderStartChar + placeholder['variable'] + placeholderEndChar;
                    placeholderDescriptionDiv.innerText = placeholder['description'];
                    placeholderDiv.appendChild(placeholderNameDiv);
                    placeholderDiv.appendChild(placeholderDescriptionDiv);
                    valuePlaceholderPanel.appendChild(placeholderDiv);
                })
            }
            certSettingsPanel.appendChild(bgSettings);
            certSettingsPanel.innerHTML += '<hr style="border-top:2px solid black;">'
            certSettingsPanel.appendChild(valuePlaceholderPanel);
            certSettingsPanel.appendChild(uploadFilePanel);
            var certificateHeight = editor.certificateSettings.height;
            var certificateWidth = editor.certificateSettings.width;
            var certificateRepeat = editor.certificateSettings.repeat;
            var certificatePosition = editor.certificateSettings.position;
            var certificateSize = editor.certificateSettings.size;
            editorTools.prepend(certSettingsPanel);
            $(certSettingsPanel).find('.bg-color-selector').val(editor.certificateSettings.backgroundColor)
            console.log($(certSettingsPanel).find('.bg-color-selector'))
            $(certSettingsPanel).find('.cert-width-input').val(certificateWidth);
            $(certSettingsPanel).find('.cert-height-input').val(certificateHeight);
            $(certSettingsPanel).find('#bgRepeat-' + certificateRepeat).attr('selected', 'selected');
            $(certSettingsPanel).find('#bgPosition-' + certificatePosition).attr('selected', 'selected');
            $(certSettingsPanel).find('bg-size-input #bgSize-' + certificateSize).attr('selected', 'selected');


        },
        fileUpload: function (id, filetype, file)
        {
            let editor = this;
            editor.fileStorageProvider.uploadFile(id, filetype, file,
                function (response)
                {
                    editor.showModalMessage("File Upload", response.message)
                }),
                function (err)
                {

                }
        },
        getFiles: function ()
        {
            let editor = this;
            editor.fileStorageProvider.getFiles("1",
                function (response)
                {
                    let imageIcon = document.createElement('i');
                    imageIcon.classList.add('fas', 'fa-image');
                    let fontIcon = document.createElement('i');
                    fontIcon.classList.add('fas', 'fa-font');
                    let filesDiv = document.createElement('div');
                    response.data.forEach(function (data)
                    {
                        console.log(data);
                        if (data.fileType == 'image')
                        {
                            let boldText = document.createElement('b');
                            boldText.innerText = data.fileName;
                            let newDiv = document.createElement('div');
                            newDiv.appendChild(imageIcon);
                            newDiv.innerHTML += "&nbsp; &nbsp;";
                            newDiv.appendChild(boldText);
                            newDiv.innerHTML += "<br/>";
                            filesDiv.appendChild(newDiv);

                        } else if (data.fileType == 'font')
                        {
                            let boldText = document.createElement('b');
                            boldText.innerText = data.fileName;
                            let newDiv = document.createElement('div');
                            newDiv.appendChild(fontIcon);
                            newDiv.innerHTML += "&nbsp; &nbsp;";
                            newDiv.appendChild(boldText);
                            newDiv.innerHTML += "<br/>";
                            filesDiv.appendChild(newDiv);
                        }
                    })
                    console.log(filesDiv);
                    editor.showModalMessage("Certificate Files", filesDiv.outerHTML);
                }, function (err)
            {
                editor.showModalMessage("File Uploader Error", err.message)
            })

        },
        initializeTemplates: function ()
        {
			let editor = this;
      let placeholderStartChar = editor.options['placeholderStartChar'];
      let placeholderEndChar = editor.options['placeholderEndChar'];
            let templates = this.widgetTemplates;
            templates['layoutContent'] = document.createElement('template');
			let containerName = editor.options['container-name'];
            templates['layoutContent'].innerHTML = '<div class="'+containerName+'" data-block-id="layout-container" data-type="layout-container"></div>';
            templates['tSelectLayoutBlock'] = document.createElement('template');
            templates['tSelectLayoutBlock'].innerHTML = '<div><label>Select Block : </label><select class="layoutBlockSelector tool-btn"><option selected disabled>------------</option></select></div>';
            templates['tBtnCertificateSettings'] = document.createElement('template');
            templates['tBtnCertificateSettings'].innerHTML = '<a  class="tool-btn certificate-settings"><span class="fas fa-object-group"></span>Certificate Settings</a>';
            templates['tBtnEditStyle'] = document.createElement('template');
            templates['tBtnEditStyle'].innerHTML = '<a  class="tool-btn edit-style"><span class="fas fa-code"></span>Edit Style</a>';
            templates['tBtnAddTitleBar'] = document.createElement('template');
            templates['tBtnAddTitleBar'].innerHTML = '<a  class="tool-btn add-title-bar"><span class="fas fa-plus"></span>Title Bar</a>';
            templates['tBtnAddOneColBlock'] = document.createElement('template');
            templates['tBtnAddOneColBlock'].innerHTML = '<a  class="tool-btn add-one-col"><span class="fas fa-plus"></span>1-Column Block</a>';
            templates['tBtnAddTwoColBlock'] = document.createElement('template');
            templates['tBtnAddTwoColBlock'].innerHTML = '<a  class="tool-btn add-two-cols"><span class="fas fa-plus"></span>2-Column Block</a>';
            templates['tBtnAddThreeColBlock'] = document.createElement('template');
            templates['tBtnAddThreeColBlock'].innerHTML = '<a  class="tool-btn add-three-cols"><span class="fas fa-plus"></span>3-Column Block</a>';
            templates['tBtnAddImage'] = document.createElement('template');
            templates['tBtnAddImage'].innerHTML = '<a  class="tool-btn add-image"><span class="fas fa-plus"></span>Image</a>';
            templates['tBtnAddQr'] = document.createElement('template');
            templates['tBtnAddQr'].innerHTML = '<a  class="tool-btn add-qr-code"><span class="fas fa-qrcode"></span>QR Code</a>';
            templates['tBtnPreview'] = document.createElement('template');
            templates['tBtnPreview'].innerHTML = '<a  class="tool-btn preview-button"><i class="fa-solid fa-layer-group"></i> Preview</a>';
            templates['tBtnAddSignee'] = document.createElement('template');
            templates['tBtnAddSignee'].innerHTML = '<a  class="tool-btn add-signees-block"><span class="fas fa-signature"></span>Add Signees Block</a>'
            templates['tBtnSave'] = document.createElement('template');
            templates['tBtnSave'].innerHTML = '<a  class="tool-btn layout-save"><i class="fas fa-save"></i> Save</a>';
            templates['tBtnLoad'] = document.createElement('template');
            templates['tBtnLoad'].innerHTML = '<a  class="tool-btn layout-load"><i class="fas fa-refresh"></i> Load</a>';
            templates['editorTools'] = document.createElement('template');
            templates['editorTools'].innerHTML = '<div class="editor-tools">' +
                '<!--<div class="block-selector-panel"><select id="block-selector"><option value="">-- select a block --</option></select></div>-->'
                + '<div class="editor-tool-panel block-selectedBlockText"><b><i class="fa-solid fa-cube"></i> Selected Block</b></div>'
                + '<div class="block-showId editor-tool-panel"><b>Block Id :</b><font class="blockIdText"></font></div>'
                + '<div class="block-changeId editor-tool-panel"><a  class="changeId-btn btn"><span class="fas fa-edit"></span>Change Id</a></div>'
                + '<div class="block-edit editor-tool-panel"><a  class="edit-btn btn"><span class="fas fa-edit"></span>Edit</a></div>'
                + '<div class="block-remove editor-tool-panel"><a  class="remove-btn btn"><span class="fas fa-trash"></span>Remove</a></div>'
                + '<div class="block-draggableMenu editor-tool-panel"><b>Draggable Block Element</b><div class="draggable-element-tool"><i class="fa-solid fa-up-down-left-right"></i><input class="draggable-allowDragCheckbox btn" type="checkbox"/> Allow Drag</div></div>'
                //qr code settings baslangic
                + '<div class="editor-tool-panel block-qrSettings"><b>QR Code Size</b>'
                + '<select class="qr-size-selector">'
                + '<option value="50">50x50</option>'
                + '<option value="75">75x75</option>'
                + '<option value="100">100x100</option>'
                + '<option value="150">150x150</option>'
                + '<option value="200">200x200</option>'
                + '<option value="300">300x300</option>'
                + '</select>'
                + '<br/><b>QR Code Cell Color</b><br/>'
                + '<input type="color" class="qr-code-cell-color-input"/><br/>'
                + '<b>QR Code Empty Cell Color</b><br/>'
                + '<input type="color" class="qr-code-empty-cell-color-input"/><br/>'
                + '<b>QR Code Background Color</b><br/>'
                + '<input type="color" class="qr-code-background-color-input"/>'
                + '</div>'
                //signees settings
                + '<div class="block-signeesSettings editor-tool-panel">'
                +'<!--<b>Signee Count</b>'
                +'<div class="signeeCount"><input class="signeeCount-range" type="range" min="1" max="12" value="1"/></div>'
                +'<b>Signee Type</b>'
                +'<div class="signeeType">'
                +'<label><input type="radio" name="signeeType" value="all"/>All</label>&nbsp;'
                +'<label><input type="radio" name="signeeType" value="main"/>Main</label>&nbsp;'
                +'<label><input type="radio" name="signeeType" value="supporting"/>Supporting</label>'
                +'</div>-->'
                +'<div>'
                +'<b>Signatures</b><br/>'
                +'<button class="btn btn-info btn-sm add-new-signature">Add New Signature</button>'
                +'</div>'
                +'</div>'
                //signature settings
                + '<div class="block-signature editor-tool-panel">'
                +'<div>'
                +'<b>Signature Editor</b>'
                +'<br>'
                +'<button class="btn btn-info btn-sm clone-signature">Clone Signature</button>'
                +'<br/>'

                +'<b>Fields</b><br/>'
                +'<button class="btn btn-sm btn-danger add-field-btn" style="margin-bottom:3px;" data-field="name" >Add Name Field</button><br/>'
                +'<button class="btn btn-sm btn-danger add-field-btn" style="margin-bottom:3px;" data-field="title">Add Title Field</button><br/>'
                +'<button class="btn btn-sm btn-danger add-field-btn" style="margin-bottom:3px;" data-field="signatureUrl">Add Signature Url Field</button><br/>'
                +'<button class="btn btn-sm btn-danger add-field-btn" data-field="logoUrl">Add Logo Url Field</button><br/>'
                +'</div>'
                +'</div>'
                //
                + '<div class="block-signatureSpan editor-tool-panel">'
                +'<b>Signature Editor</b>'
                +'<div style="display:flex;">'
                +'<input type="text" style="flex:1" class="signeeFieldInput" ><button class="btn btn-sm btn-success updateField" style="margin-left:4px;">Update</button>'
                +'</div>'
                +'<div><br/>'
                +'<b>Field Position : </b>'
                +'<button class="btn btn-info field-up" style="margin-right:5px;"><i class="fa fa-arrow-up"></i></button>'
                +'<button class="btn btn-danger field-down"><i class=" fa fa-arrow-down"></i></button>'
                +'</div>'
                +'</div>'
                //qr code settings sonu
                + '<div class="block-zindex editor-tool-panel"><b>Z Index Selector</b><input class="z-index-selector-input" type="range" min="1" max="20" value="1"></div>'
                + '<div class="block-cssClasses editor-tool-panel"><b>Block CSS Classes</b><br/><input style="display:none;" class="block-cssClasses-input"/><div class="block-cssClasses-list"></div>'
                + '<div><input class="add-new-blockClass-input" type="text" value="" placeholder="Class Name"/><button class="add-new-blockClass-button btn btn-sm btn-primary">Add</button><div class="blockClass-autocomplete-list"></div></div>'
                + '<div style="display:none;" class="editor-buttons"><a  class="save-cssClasses-btn btn"><span class="fas fa-save"></span>Save</a><a  class="discard-cssClasses-btn btn"><span class="fas fa-trash"></span>Discard</a></div></div>'
                + '<br/><div class="block-style editor-tool-panel"><b>Custom Style</b><br/><textarea class="block-style-editor" style="display:none;"></textarea><div class="block-style-editor-div"></div>'
                + '<!--<div class="editor-buttons"><a  class="save-style-btn btn"><span class="fas fa-save"></span>Save</a><a  class="discard-style-btn btn"><span class="fas fa-trash"></span>Discard</a></div></div>-->'
                + '</div>';

            templates['previewContent'] = document.createElement('template');
            templates['previewContent'].innerHTML = '<iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" class="preview-frame layout-container-frame"></iframe>';

            templates['certificateSettingsPanel'] = document.createElement('template');
            templates['certificateSettingsPanel'].innerHTML = '<div class="certificate-settings-panel editor-tool-panel"></div>';
            templates['backgroundSettingsPanel'] = document.createElement('template');
            templates['backgroundSettingsPanel'].innerHTML = '<div class="background-settings input-group">'
                + '<label for="bg-width">Certificate Width</label><br/>'
                + '<input type="number" class="cert-wh-input cert-width-input" id="cert-width-input"/><button class="set-width-btn btn btn-info btn-sm">Onayla</button><br/>'
                + '<label for="bg-height">Certificate Height</label><br/>'
                + '<input type="number" class="cert-wh-input cert-height-input" id="cert-height-input"/><button class="set-height-btn btn btn-info btn-sm">Onayla</button><br/>'
                + '<label for="bg-color">Background Color</label><br/>'
                + '<input type="color" class="bg-color-selector" value="#ffffff" id="bg-color"/><br/>'
                + '<label for="background-img">Background Image</label><br/>'
                + '<input class="background-image-input" type="file" id="background-img"/>'
                + '<label for="bg-repeat">Background-Repeat</label><br/>'
                + '<select class="form-select bg-repeat-input" id="bg-repeat">'
                + '<option id="bgRepeat-initial" value="initial">initial</option>'
                + '<option id="bgRepeat-no-repeat" value="no-repeat">no-repeat</option>'
                + '<option id="bgRepeat-repeat" value="repeat">repeat</option>'
                + '<option id="bgRepeat-repeat-x" value="repeat-x">repeat-x</option>'
                + ' <option id="bgRepeat-repeat-y" value="repeat-y">repeat-y</option>'
                + ' <option id="bgRepeat-round" value="round">round</option>'
                + ' <option id="bgRepeat-inherit" value="inherit">inherit</option>'
                + '</select>'
                + '<br/>'
                + '<label for="bg-position">Background-Position</label><br/>'
                + '<select class="form-select bg-position-input" id="bg-position">'
                + '<option id="bgPosition-initial" value="initial">initial</option>'
                + '<option id="bgPosition-center" value="center">center</option>'
                + '<option id="bgPosition-left" value="left">left</option>'
                + '<option id="bgPosition-right" value="right">right</option>'
                + '<option id="bgPosition-bottom" value="bottom">bottom</option>'
                + '<option id="bgPosition-top" value="top">top</option>'
                + '<option id="bgPosition-revert" value="revert">revert</option>'
                + '<option id="bgPosition-unset" value="unset">unset</option>'
                + '</select>'
                + '<br/>'
                + '<label for="bg-size">Background-Size</label><br/>'
                + '<select class="form-select bg-size-input" id="bg-position">'
                + '<option id="bgSize-initial" value="initial">initial</option>'
                + '<option id="bgSize-auto" value="auto">auto</option>'
                + '<option id="bgSize-contain" value="contain">contain</option>'
                + '<option id="bgSize-cover" value="cover">cover</option>'
                + '</select>'
                + '</div>';

            templates['valuePlaceholders'] = document.createElement('template');
            templates['valuePlaceholders'].innerHTML = '<div class="value-placeholders">'

                + '</div>'
            templates['fileUpload'] = document.createElement('template');
            templates['fileUpload'].innerHTML = '<div class="fileUploader">'
                + '<h5>Cerificate File Upload</h5>'
                + '<b>File Type</b><br/>'
                + '<label for="image">'
                + '<input class="upload_file_type_radio" type="radio" id="image" name="upload_file_type" value="image"/>'
                + 'Image</label><br/>'
                + '<label for="font">'
                + '<input class="upload_file_type_radio" type="radio" id="font" name="upload_file_type" value="font"/>'
                + 'Font</label><br/>'
                + '<input class="upload_file_input" type="file">'
                + '<br/>'
                + '<button class="start_upload_button btn btn-success btn-sm">Upload</button>'
                + '<button style="margin-left:5px;" class="get_files_button btn btn-danger btn-sm">Show Files</button>'

                + '</div>'
            // layout elements
            templates['blockTitleBar'] = document.createElement('template');
            templates['blockTitleBar'].innerHTML = '<div class="layout-block title-bar" data-type="title-bar"></div>';
            templates['blockQrCodeItem'] = document.createElement('template');
            templates['blockQrCodeItem'].innerHTML = '<div class="layout-block qr-code-item" data-type="qr-code"><svg xmlns="http://www.w3.org/2000/svg" style="width:50px; height:50px; fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">'
                + '<path d="M2 2h2v2H2V2Z"/>'
                + '<path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>'
                + '<path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>'
                + '<path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>'
                + '<path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>'
                + '</svg></div>';
            templates['block2Column'] = document.createElement('template');
            templates['block2Column'].innerHTML = '<div class="layout-block two-column" data-type="two-column"><div class="layout-block column column-1" data-type="column"></div>'
                + '<div class="layout-block column column-2" data-type="column"></div></div>';
            templates['block3Column'] = document.createElement('template');
            templates['block3Column'].innerHTML = '<div class="layout-block three-column" data-type="three-column"><div class="layout-block column column-1" data-type="column"></div>'
                + '<div class="layout-block column column-2" data-type="column"></div>'
                + '<div class="layout-block column column-3" data-type="column"></div></div>';
            templates['block1Column'] = document.createElement('template');
            templates['block1Column'].innerHTML = '<div class="layout-block one-column" data-type="one-column"></div>';
            templates['blockImage'] = document.createElement('template');
            templates['blockImage'].innerHTML = '<div class="layout-block image" data-type="image"><img /></div>';
            templates['signeesBlock'] = document.createElement('template');
            templates['signeesBlock'].innerHTML = '<div class="layout-block signees" data-type="signees"><div class="layout-block signature" data-signature-number="1" data-name="" data-title="" data-logo-url="" data-signature-url="" data-type="signature" style="background-color:#d5d5d5; width:150px;">'
            +'<span class="signee-name">'+placeholderStartChar+'Name'+placeholderEndChar+'</span><br/>'
            +'<span class="signee-title">'+placeholderStartChar+'Title'+placeholderEndChar+'</span><br/>'
            +'<span class="signee-title">'+placeholderStartChar+'LogoUrl'+placeholderEndChar+'</span><br/>'
            +'<span class="signee-title">'+placeholderStartChar+'SignatureUrl'+placeholderEndChar+'</span><br/>'
            +'</div></div>';
            templates['signatureFieldEditIcon'] = document.createElement('template');
            templates['signatureFieldEditIcon'].innerHTML = '<a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a>';
            templates['signatureItem'] = document.createElement('template');
            templates['signatureItem'].innerHTML = '<div class="layout-block signature" data-type="signature" data-signature-number="1" style="display:inline-block; padding:5px; background-color:#fefade; margin:5px; width:150px;">'
            +'<span style="display:block;" class="layout-block signee-field signee-field-text signee-name" data-type="signatureSpan">'+placeholderStartChar+'Name'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            +'<span style="display:block;" class="layout-block signee-field signee-field-text signee-title" data-type="signatureSpan">'+placeholderStartChar+'Title'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            +'<span style="display:block;" class="layout-block signee-field signee-field-image signee-logo" data-type="signatureSpan">'+placeholderStartChar+'LogoUrl'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            +'<span style="display:block;" class="layout-block signee-field signee-field-image signee-url" data-type="signatureSpan">'+placeholderStartChar+'SignatureUrl'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            +'</div>';
            templates['signature-field-name'] = document.createElement('template');
            templates['signature-field-name'].innerHTML = '<span style="display:block;" class="layout-block signee-field signee-field-text signee-name" data-type="signatureSpan">'+placeholderStartChar+'Name'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            templates['signature-field-title'] = document.createElement('template');
            templates['signature-field-title'].innerHTML = '<span style="display:block;" class="layout-block signee-field signee-field-text signee-title" data-type="signatureSpan">'+placeholderStartChar+'Title'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>';
            templates['signature-field-logo'] = document.createElement('template');
            templates['signature-field-logo'].innerHTML = '<span style="display:block;" class="layout-block signee-field signee-field-image signee-logo" data-type="signatureSpan">'+placeholderStartChar+'LogoUrl'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            templates['signature-field-url'] = document.createElement('template');
            templates['signature-field-url'].innerHTML = '<span style="display:block;" class="layout-block signee-field signee-field-image signee-url" data-type="signatureSpan">'+placeholderStartChar+'SignatureUrl'+placeholderEndChar+' <a class="select-btn" style="display:inline;"><i class="fas fa-pencil"></i></a></span>'
            // Tool UI
            templates['layoutStyleEditor'] = document.createElement('template');
            templates['layoutStyleEditor'].innerHTML = '<div class="style-editor"><div class="editor-area"><textarea id="style-code" class="style-code"></textarea></div>'
                + '<div class="editor-buttons"><a  class="save-btn btn btn-info"><span class="fas fa-save"></span>Save</a><a  style="margin-left:3px;" class="discard-btn btn btn-danger"><span class="fas fa-trash"></span>Discard</a></div></div>';
            templates['layoutBlockContentEditor'] = document.createElement('template');
            templates['layoutBlockContentEditor'].innerHTML = '<div class="content-editor"><div class="editor-area"><textarea class="block-code"></textarea></div>'
                + '<div class="editor-buttons"><a  class="save-btn"><span class="fas fa-save"></span>Save</a><a  class="discard-btn"><span class="fas fa-trash"></span>Discard</a></div></div>';
            templates['blockSelectBtn'] = document.createElement('template');
            templates['blockSelectBtn'].innerHTML = '<a  class="select-btn"><span class="fas fa-cogs"></span></a>';
            templates['addImageForm'] = document.createElement('template');
            templates['addImageForm'].innerHTML = '<div class="image-form">Select Image: <input name="BlockImageFile" type="file"/><br/>'
                + '<div class="image-params"><b>Width: </b><input name="BlockImageWidth"/> &nbsp;&nbsp;<b>Height: </b><input name="BlockImageHeight"/><br/>'
                + '<b>Style: </b><br/><textarea name="BlockImageStyle"></textarea></div>'
                + '<div class="editor-buttons"><a  class="save-btn"><span class="fas fa-save"></span>Save</a><a  class="discard-btn"><span class="fas fa-trash"></span>Discard</a></div>'
                + '</div>';

        },
        loadLayout: function (layoutId)
        {
            let editor = this;
            editor.layoutStorageProvider.getLayoutList(function (data)
            {
                editor._createLayoutLoader(data);
            }, function (err)
            {
                editor.showModalMessage("Error", "Unable to get layout list.");
            });
        },
        loadEditorState: function (layoutData)
        {
            let editor = this;
            let widgets = editor.widgets;
            let SavedEditorState = layoutData;
            widgets['layoutStyle'].innerHTML = SavedEditorState['cssData'] || "";
            widgets['contentArea'].innerHTML = SavedEditorState['editorContent'] || "";
            editor.options['styles'] = (SavedEditorState['cssLinks'] || []).join(';');
            editor.options['usable-style-links'] = (SavedEditorState['usableCssLinks'] || []).join(';');
            editor.updateTargetEditor();
            console.log(SavedEditorState);
        },
        createWidgets: function ()
        {
            let templates = this.widgetTemplates;
            let options = this.options;
            let widgets = this.widgets;
            if (typeof (widgets['ready']) !== 'undefined')
            {
                return;
            }
            let editorPanel = document.createElement('div');
            editorPanel.classList.add('layout-editor');
            if (typeof (options.id) === 'string')
            {
                editorPanel.id = options.id;
            }
            //'toolbarFeatures':'certificateSettings,editStyle,title,1Column,2Column,3Column,image,qrCode,signees,preview'
            let features = options.toolbarFeatures.split(',');
            let editorToolbar = document.createElement('div');
            editorToolbar.classList.add('layout-editor-toolbar');
            let tSelectLayoutBlock = templates['tSelectLayoutBlock'].content.firstChild.cloneNode(true);
            let tBtnCertificateSettings = templates['tBtnCertificateSettings'].content.firstChild.cloneNode(true);
            let tBtnEditStyle = templates['tBtnEditStyle'].content.firstChild.cloneNode(true);

            let tBtnAddTitleBar = templates['tBtnAddTitleBar'].content.firstChild.cloneNode(true);

            let tBtnAddOneColBlock = templates['tBtnAddOneColBlock'].content.firstChild.cloneNode(true);

            let tBtnAddTwoColBlock = templates['tBtnAddTwoColBlock'].content.firstChild.cloneNode(true);

            let tBtnAddThreeColBlock = templates['tBtnAddThreeColBlock'].content.firstChild.cloneNode(true);

            let tBtnAddImage = templates['tBtnAddImage'].content.firstChild.cloneNode(true);

            let tBtnAddQr = templates['tBtnAddQr'].content.firstChild.cloneNode(true);

            let tBtnAddSignee = templates['tBtnAddSignee'].content.firstChild.cloneNode(true);

            let tBtnPreview = templates['tBtnPreview'].content.firstChild.cloneNode(true);
            if(features.indexOf('selectBlock') != -1){

                editorToolbar.appendChild(tSelectLayoutBlock);
            }
            if(features.indexOf('certificateSettings') != -1){

                editorToolbar.appendChild(tBtnCertificateSettings);
            }
            if(features.indexOf('editStyle') != -1){
                editorToolbar.appendChild(tBtnEditStyle);
            }
            if(features.indexOf('title') != -1){
                editorToolbar.appendChild(tBtnAddTitleBar);
            }
            if(features.indexOf('1Column') != -1){
                editorToolbar.appendChild(tBtnAddOneColBlock);
            }
            if(features.indexOf('2Column') != -1){
                editorToolbar.appendChild(tBtnAddTwoColBlock);
            }
            if(features.indexOf('3Column') != -1){
                editorToolbar.appendChild(tBtnAddThreeColBlock);
            }
            if(features.indexOf('image') != -1){
                editorToolbar.appendChild(tBtnAddImage);
            }
            if(features.indexOf('qrCode') != -1){
                editorToolbar.appendChild(tBtnAddQr);
            }
            if(features.indexOf('signees') != -1){
                editorToolbar.appendChild(tBtnAddSignee);
            }
            if(features.indexOf('preview') != -1){
                editorToolbar.appendChild(tBtnPreview);
            }



            let tBtnSaveButton = templates['tBtnSave'].content.firstChild.cloneNode(true);
            //editorToolbar.appendChild(tBtnSaveButton);
            let tBtnLoadButton = templates['tBtnLoad'].content.firstChild.cloneNode(true);
            //editorToolbar.appendChild(tBtnLoadButton);

            let previewFrame = templates['previewContent'].content.firstChild.cloneNode(true);
            let contentPanel = document.createElement('div');
            contentPanel.classList.add('layout-editor-content');
            contentPanel.classList.add('signees-editor-content');
            let contentArea = templates['layoutContent'].content.firstChild.cloneNode(true);
            //contentPanel.appendChild(contentArea);
            contentPanel.appendChild(previewFrame);
            let editorTools = templates['editorTools'].content.firstChild.cloneNode(true);
            contentPanel.appendChild(editorTools);
            let layoutStyle = document.createElement('style');
            layoutStyle.classList.add('layout-style');

            widgets['editorPanel'] = editorPanel;
            widgets['toolbar'] = editorToolbar;
            widgets['content'] = contentPanel;
            widgets['layoutStyle'] = layoutStyle;
            widgets['contentArea'] = contentArea;
            widgets['tBtnEditStyle'] = tBtnEditStyle;
            widgets['tBtnAddTitleBar'] = tBtnAddTitleBar;
            widgets['tBtnAddOneColBlock'] = tBtnAddOneColBlock;
            widgets['tBtnAddTwoColBlock'] = tBtnAddTwoColBlock;
            widgets['tBtnAddThreeColBlock'] = tBtnAddThreeColBlock;
            widgets['tBtnAddImage'] = tBtnAddImage;
            widgets['tBtnCertificateSettings'] = tBtnCertificateSettings;
            widgets['editorTools'] = editorTools;
            widgets['previewContent'] = previewFrame;
            editorPanel.appendChild(editorToolbar);
            editorPanel.appendChild(layoutStyle);
            previewFrame.src = 'about:blank';
            let previewBody = $(previewFrame).contents().find('body');
            previewBody.html('');
            let previewHead = $(previewFrame).contents().find('head');
            previewHead.html('');
            previewBody.css('position', "relative");
            //contentPanelFrame.srcdoc = contentPanel.innerHTML;
            //contentPanelFrame.sandbox = "";
            editorPanel.appendChild(contentPanel);
            //if (frameDoc.document)frameDoc = frameDoc.document;
            //frameDoc.appendChild(contentPanel);
            //contentPanelContainer.contentWindow.document.append(contentPanel);
            //editorPanel.appendChild(contentPanel);
            let targetEditor = $('#' + options.target);
            $(editorPanel).insertAfter(targetEditor);
            targetEditor.hide();
            this.updateEditorTools();
        },
        loadedStyles: false,
        _setWidth: function (width)
        {
            let editor = this;
            let previewArea = editor.widgets['previewContent']
            let previewFrame = editor.widgets['previewContent'];
            let previewBody = $(previewFrame).contents().find('body');
            let contentArea = editor.widgets['contentArea'];
            let requestedWidth = parseInt(width);
            $(previewArea).css('overflow', 'hidden');
            $(previewArea).css('width', requestedWidth + 'px');
            $(previewArea).css('min-width', requestedWidth + 'px');
            $(previewArea).css('flex-grow', 'unset');

            $(previewBody).css('overflow', 'hidden');
            $(previewBody).css('width', requestedWidth + 'px');
            $(contentArea).css('width', requestedWidth + 'px');
            editor.certificateSettings.width = requestedWidth;
        },
        _setHeight: function (height)
        {
            let editor = this;
            let previewArea = editor.widgets['previewContent']
            let previewFrame = editor.widgets['previewContent'];
            let previewBody = $(previewFrame).contents().find('body');
            let contentArea = editor.widgets['contentArea'];
            let requestedHeight = parseInt(height);
            $(previewArea).css('overflow', 'hidden');
            $(previewArea).css('height', requestedHeight + 'px');
            $(previewArea).css('flex-grow', 'unset');

            $(previewBody).css('overflow', 'hidden');
            $(previewBody).css('height', requestedHeight + 'px');
            $(contentArea).css('height', requestedHeight + 'px');
            editor.certificateSettings.height = requestedHeight;

        },
        _changeBackgroundColor: function (colorCode)
        {
            let editor = this;
            let previewFrame = editor.widgets['previewContent'];
			let containerName = editor.options['container-name'];
			let containerClass = '.'+editor.options['container-name'];
            let previewBody = $(previewFrame).contents().find('body').find(containerClass);
            let contentArea = editor.widgets['contentArea'];
            //$(previewArea).css('background-color', colorCode);
            $(previewBody).css('background-color', colorCode);
            $(contentArea).css('background-color', colorCode);
            editor.certificateSettings.backgroundColor = colorCode;
        },
        _changeBackgroundImage: function (img)
        {
            let editor = this;
            let previewFrame = editor.widgets['previewContent'];
			let containerName = editor.options['container-name'];
			let containerClass = '.'+editor.options['container-name'];
            let previewBody = $(previewFrame).contents().find('body').find(containerClass);
            let contentArea = editor.widgets['contentArea'];
            const imgReader = new FileReader();
            console.log(img);
            let dataUrl = imgReader.readAsDataURL(img);
            imgReader.addEventListener("load", function ()
            {
                console.log(imgReader.result);
                //$(previewArea).css('background-image', 'url(' + imgReader.result + ')');
                $(previewBody).css('background-image', 'url(' + imgReader.result + ')');
                $(contentArea).css('background-image', 'url(' + imgReader.result + ')');
                editor.certificateSettings.certificateImage = 'url(' + imgReader.result + ')';
            })
        },
        _changeBackgroundImageRepeat: function (repeatStr)
        {
            let editor = this;
            let previewArea = editor.widgets['previewContent']
            let previewFrame = editor.widgets['previewContent'];
			let containerName = editor.options['container-name'];
			let containerClass = '.'+editor.options['container-name'];
            let previewBody = $(previewFrame).contents().find('body').find(containerClass);
            let contentArea = editor.widgets['contentArea'];
            $(previewArea).css('background-repeat', repeatStr);
            $(previewBody).css('background-repeat', repeatStr);
            $(contentArea).css('background-repeat', repeatStr);
            editor.certificateSettings.repeat = repeatStr;
        },
        _changeBackgroundImagePosition: function (positionStr)
        {
            let editor = this;
            let previewArea = editor.widgets['previewContent'];
            let previewFrame = editor.widgets['previewContent'];
			let containerName = editor.options['container-name'];
			let containerClass = '.'+editor.options['container-name'];
            let previewBody = $(previewFrame).contents().find('body').find(containerClass);
            let contentArea = editor.widgets['contentArea'];
            $(previewArea).css('background-position', positionStr);
            $(previewBody).css('background-position', positionStr);
            $(contentArea).css('background-position', positionStr);
            editor.certificateSettings.position = positionStr;
        },
        _changeBackgroundSize: function (sizeStr)
        {
            let editor = this;
            let previewArea = editor.widgets['previewContent'];
            let previewFrame = editor.widgets['previewContent'];
            let contentArea = editor.widgets['contentArea'];
			let containerName = editor.options['container-name'];
			let containerClass = '.'+editor.options['container-name'];
            let previewBody = $(previewFrame).contents().find('body').find(containerClass);

            $(previewArea).css('background-size', sizeStr);
            $(contentArea).css('background-size', sizeStr);
            $(previewBody).css('background-size', sizeStr);
            editor.certificateSettings.size = sizeStr;
        },
        _changeQrCellColor: function (elm, val)
        {
            $(elm).find('svg').css('color', val);
            $(elm).find('svg').css('fill', val);
            $(elm).find('svg').attr('data-cellcolor', val);
            this.updateTargetEditor();
        },
        _changeQrEmptyCellColor: function (elm, val)
        {
            $(elm).find('svg').css('background-color', val);
            $(elm).find('svg').attr('data-emptycellcolor', val);
            this.updateTargetEditor();
        },
        _changeElementBackgroundColor: function (elm, val)
        {
            $(elm).css('background-color', val);
            $(elm).find('svg').attr('data-backgroundcolor', val);
            this.updateTargetEditor();

        },
        _changeQrSize: function (elm, val)
        {
            $(elm).find('svg').css('width', val);
            $(elm).find('svg').css('height', val);

            this.updateTargetEditor();

        },
        _addField: function(selectedElm,val){
            let editor = this;
            let templates = editor.widgetTemplates;
            if(val == "name"){
                let item = templates['signature-field-name'].content.firstChild.cloneNode(true);
                let curr = selectedElm.querySelectorAll('.signee-name');
                if(curr != undefined && curr.length == 0){
                    selectedElm.append(item);
                }else{
                    editor.showModalMessage('Fields','Name field is already added');
                }
                editor.updateTargetEditor();
            }else if(val == "title"){
                let item = templates['signature-field-title'].content.firstChild.cloneNode(true);
                let curr = selectedElm.querySelectorAll('.signee-title');
                if(curr != undefined && curr.length == 0){
                    selectedElm.append(item);
                }else{
                    editor.showModalMessage('Fields','Title field is already added');
                }
                editor.updateTargetEditor();
            }else if(val == "logoUrl"){
                let item = templates['signature-field-logo'].content.firstChild.cloneNode(true);
                let curr = selectedElm.querySelectorAll('.signee-logo');
                if(curr != undefined && curr.length == 0){
                    selectedElm.append(item);
                }else{
                    editor.showModalMessage('Fields','Signature Logo field already added');
                }
                editor.updateTargetEditor();
            }else if(val == "signatureUrl"){
                let item = templates['signature-field-url'].content.firstChild.cloneNode(true);
                let curr = selectedElm.querySelectorAll('.signee-url');
                if(curr != undefined && curr.length == 0){
                    selectedElm.append(item);
                }else{
                    editor.showModalMessage('Fields','Signature Url field already added');
                }
                editor.updateTargetEditor();
            }
        },
        updatePreview: function ()
        {
            let editor = this;
            let widgets = editor.widgets;
            let options = editor.options;
            let previewOptions = editor.previewOptions;
            previewOptions.showPreview = false;
            let contentArea = editor.widgets['contentArea']
            let previewArea = editor.widgets['previewContent']
            let previewContents = contentArea.outerHTML;
            let styleLinks = options['styles'].split(';')
            let usableStyleLinks = options['usable-style-links'].split(';')
            console.log("loadStyles" + editor.loadedStyles);
            if (editor.loadedStyles == false)
            {
                console.log(styleLinks)
                styleLinks.forEach(function (styleUrl)
                {
                    var cssLink = document.createElement("link");
                    cssLink.href = styleUrl;
                    cssLink.rel = "stylesheet";
                    cssLink.type = "text/css";
                    cssLink.crossOrigin = "anonymous";
                    $(previewArea).contents().find('head').append(cssLink);
                })
                console.log(usableStyleLinks)
                usableStyleLinks.forEach(function (styleUrl)
                {
                    var cssLink = document.createElement("link");
                    cssLink.href = styleUrl;
                    cssLink.rel = "stylesheet";
                    cssLink.type = "text/css";
                    cssLink.crossOrigin = "anonymous";
                    $(previewArea).contents().find('head').append(cssLink);
                })
                var styleLen = $(previewArea).contents().find('head').find('link').length;
                if (styleLen != 0)
                {

                    editor.loadedStyles = true;
                }

            }
            let previewBody = $(previewArea).contents().find('body');

            let previewHead = $(previewArea).contents().find('head');
            let style = $(previewHead).contents().find('style')

            if (style.length == 0)
            {
                style.remove();
            }
            previewHead.append(widgets['layoutStyle']);
            console.log(previewContents);
            previewBody.html(previewContents);

            this.bindIframeEventListeners();
        },
        bindIframeEventListeners: function ()
        {
            // ----------------------- Preview Content Selected Buttons ----------------------------------
            console.log("bind event listeners (iframe)")
            let editor = this;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];
            let contentArea = widgets['contentArea'];
            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            bodyContents.off('click');
            bodyContents.off('click', '.select-btn');
            bodyContents.on('click', '.select-btn', function ()
            {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();

                if (block.hasClass('selected'))
                {
                    block.removeClass('selected');
                }
                else
                {
					let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    bodyContents.find('.layout-block.selected').removeClass('selected');
                    bodyContents.find(containerClass+'.selected').removeClass('selected');
                    block.addClass('selected');
                }
                contentArea = bodyContents[0].firstChild.cloneNode(true);
                widgets['contentArea'] = contentArea;
                editor.updateEditorTools();
                return false;
            })
            let movingObject = this.movingObject;
            let currentMousePos = { x: -1, y: -1 };
            bodyContents.on('mousedown', function (ev)
            {
                let targetObject = null;
                if ($(ev.target).parent().hasClass('layout-block') && $(ev.target).parent().hasClass('free-position'))
                {
                    targetObject = $(ev.target).parent().get(0);
                } else
                {
                    targetObject = ev.target;
                }
                if (movingObject['isMoving'] == false)
                {
                    if (($(targetObject).hasClass('layout-block')) && $(targetObject).hasClass('selected') && $(targetObject).hasClass('free-position'))
                    {
                        if ($(targetObject).css('position') != "absolute")
                        {
                            $(targetObject).css('position', "absolute");
                        }
                        movingObject['isMoving'] = true;
                        movingObject['selectedElement'] = targetObject;

                        let parent = $(targetObject).parent().parent().get(0);
                        currentMousePos = { x: ev.clientX - parent.offsetLeft, y: ev.clientY - parent.offsetTop };
                    }
                }

            })
            bodyContents.on('mouseup', function (ev)
            {
                if (movingObject['isMoving'] == true)
                {
                    editorTools.querySelector('.block-style-editor').value = (movingObject['selectedElement'].getAttribute('style') || "").replace('/[\n\z]+/', '');
                    let widgets = editor.widgets;
                    let contentArea = widgets['contentArea'];
                    let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
                    if(selectedElement === null && contentArea.classList.contains('selected'))
                    {
                        selectedElement = contentArea;
                    }

                    var currentVal = editorTools.querySelector('.block-style-editor').value;
                    selectedElement.setAttribute('style', currentVal)
                    editor.updateEditorTools();
                    movingObject['isMoving'] = false;
                    movingObject['selectedElement'] = null;
                }
            })
            /*bodyContents.on('mouseleave',function(ev){
                if(movingObject['isMoving'] == true){
                    editorTools.querySelector('.block-style-editor').value = (movingObject['selectedElement'].getAttribute('style') || "").replace('/[\n\z]+/', '');
                    let selectedElement = contentArea.querySelector('.layout-block.selected') || editorPanel.querySelector('.layout-container.selected');
                    var currentVal = editorTools.querySelector('.block-style-editor').value;
                    selectedElement.setAttribute('style',currentVal)
                    editor.updateEditorTools();
                    movingObject['isMoving'] = false;
                    movingObject['selectedElement'] = null;
                    console.log("leave")
                }
            })*/


            bodyContents.on('mousemove', function (ev)
            {
                if (movingObject['isMoving'] == true && movingObject['selectedElement'] != null)
                {

                    var posX;
                    var posY;
                    let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    let selectedElement = bodyContents.get(0).querySelector('.layout-block.selected') || bodyContents.get(0).querySelector(containerClass+'.selected');
                    /*if($(selectedElement).hasClass('signature')){
                        console.log("signature var");
                        console.log($(selectedElement).parent().parent().get(0))

                        posX = ev.clientX - $(selectedElement).parent().parent().get(0).scrollLeft;
                        posY = ev.clientY + $(selectedElement).parent().parent().get(0).scrollTop;
                        console.log(posX);
                        console.log(posY);
                    }else{*/
                        let selEl = movingObject['selectedElement'];
                        posX = ev.clientX - $(selEl).parent().parent().get(0).offsetLeft;
                        posY = ev.clientY - $(selEl).parent().parent().get(0).offsetTop;
                        console.log($(selEl).parent().parent().get(0).offsetTop);
                    /*} */


                    if (Math.abs(currentMousePos.x - posX) > 5 || Math.abs(currentMousePos.y - posY) > 5)
                    {
                        //console.log(ev.clientY - previewArea.offsetTop + previewArea.scrollTop)
                        var ch = selectedElement.clientHeight / 2;
                        console.log(ch);
                        var cw = selectedElement.clientWidth / 2;
                        currentMousePos = { x: posX, y: posY };
                        $(movingObject['selectedElement']).css("top", posY - ch - 20);
                        $(movingObject['selectedElement']).css("left", posX - cw);

                    }
                }
            })

            // ----------------------- End Code -----------------------------
        },
        bindEventListeners: function ()
        {
            let editor = this;
            let templates = editor.widgetTemplates;
            let featureMap = editor.featureMap;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];
            let editorPanel = widgets['editorPanel'];
            let contentArea = widgets['contentArea'];

            $(editorTools).on('change', function (event)
            {
                let contentArea = widgets['contentArea'];
                let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
                if(selectedElement === null && contentArea.classList.contains('selected'))
                {
                    selectedElement = contentArea;
                }

                let btn = $(event.target);
                if (btn.hasClass('background-image-input'))
                {
                    editor._changeBackgroundImage(event.target.files[0]);
                } else if (btn.hasClass('bg-repeat-input'))
                {
                    editor._changeBackgroundImageRepeat(btn.val());
                } else if (btn.hasClass('bg-position-input'))
                {
                    editor._changeBackgroundImagePosition(btn.val());
                } else if (btn.hasClass('bg-size-input'))
                {
                    editor._changeBackgroundSize(btn.val());
                } else if (btn.hasClass('z-index-selector-input'))
                {
                    $(selectedElement).css('z-index', parseInt(btn.val()))
                    console.log('selected el')
                    console.log(selectedElement);
                    console.log(btn.val());
                    editor.updateEditorTools();
                    editor.updateTargetEditor();
                } else if (btn.hasClass('qr-size-selector'))
                {
                    editor._changeQrSize(selectedElement, btn.val());
                } else if (btn.hasClass('qr-code-background-color-input'))
                {
                    //Seçilen elementin arkaplan rengini değiştirir
                    editor._changeElementBackgroundColor(selectedElement, btn.val());
                } else if (btn.hasClass('qr-code-empty-cell-color-input'))
                {
                    editor._changeQrEmptyCellColor(selectedElement, btn.val());
                } else if (btn.hasClass('qr-code-cell-color-input'))
                {
                    editor._changeQrCellColor(selectedElement, btn.val());
                } else if (btn.hasClass('upload_file_input'))
                {
                    editor.fileData.file = event.target.files[0];
                } else if (btn.hasClass('upload_file_type_radio'))
                {
                    editor.fileData.type = event.target.value;
                }else if(btn.hasClass('signeeCount-range')){
                    let count = parseInt(btn.val())
                    console.log(count)
                    let sign = $(selectedElement).find('.signature').get(0).outerHTML;
                    let tempSign = document.createElement('template');
                    tempSign.innerHTML = sign;
                    console.log(sign)
                    $(selectedElement).find('.signature').remove();
                    for(let i=0;i<count;i++){
                        let clonedSign = tempSign.content.firstChild.cloneNode(true);
                        $(clonedSign).attr('data-signature-number',(i+1).toString());
                        $(clonedSign).attr('data-block-id',$(selectedElement).attr('data-block-id')+"-s-"+(i+1).toString());
                        selectedElement.appendChild(clonedSign);
                    }
                    console.log(selectedElement);
                    editor.updatePreview();
                }
            })
            $(editorTools).on('input', function (event)
            {
                let contentArea = widgets['contentArea'];
                let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
                if(selectedElement === null && contentArea.classList.contains('selected'))
                {
                    selectedElement = contentArea;
                }

                let btn = $(event.target);
                if (btn.hasClass('bg-color-selector'))
                {
                    //Sertifikanın arkaplan rengini değiştirir.
                    editor._changeBackgroundColor(btn.val());
                }

            })
            $(editorTools).on('keyup', function (keypressEvent)
            {
                let contentArea = widgets['contentArea'];
                if ($(keypressEvent.target).hasClass("add-new-blockClass-input"))
                {
                    var currentInput = $(".add-new-blockClass-input")[0].value;
                    var filtered = editor.usableCssClasses.filter(x => x.startsWith(currentInput));
                    var listDiv = document.createElement("div");
                    listDiv.classList.add("blockClass-autocomplete-sublist");
                    filtered.forEach(function (prediction)
                    {
                        var item = document.createElement("div")
                        item.classList.add("blockClass-autocomplete-item");
                        item.classList.add("btn");
                        var strongItem = document.createElement("strong");
                        var writed = currentInput;
                        var pred = prediction.substring(writed.length);
                        strongItem.innerText = writed;
                        item.appendChild(strongItem);
                        $(item).attr("autocomplete-data", prediction);
                        item.innerHTML += "<font>" + pred + "</font>"
                        listDiv.appendChild(item);
                    })
                    if (currentInput != "")
                    {
                        $(".blockClass-autocomplete-list").html("");
                        $(".blockClass-autocomplete-list").append(listDiv);
                    } else
                    {
                        $(".blockClass-autocomplete-list").html("");
                    }
                    if (filtered.length == 1)
                    {
                        if (currentInput == filtered[0])
                        {
                            $(".blockClass-autocomplete-list").html("");
                        }
                    }

                }
            })

            $(editorTools).on('click', '.editor-tool-panel .btn', function ()
            {

                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];

                let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
                if(selectedElement === null && contentArea.classList.contains('selected'))
                {
                    selectedElement = contentArea;
                }


                if (selectedElement === null && false)
                {
                    editor.updateEditorTools();
                }
                else
                {
                    let elementType = $(selectedElement).data('type');
                    let editorFeatures = (featureMap[elementType] || '').split(',');
                    let templates = editor.widgetTemplates;
                    let btn = $(this);
                    if (btn.hasClass('edit-btn') && editorFeatures.indexOf('edit') !== -1)
                    {
                        let blockContentEditor = templates['layoutBlockContentEditor'].content.firstChild.cloneNode(true);
                        let codeEditor = blockContentEditor.querySelector('textarea');
                        let tmpContent = document.createElement('template');
                        tmpContent.innerHTML = selectedElement.innerHTML;
                        let selectBtns = tmpContent.content.querySelectorAll('.select-btn');
                        for (let i = 0; i < selectBtns.length; i++)
                        {
                            selectBtns[i].remove();
                        }
                        codeEditor.value = tmpContent.innerHTML;

                        editor.showModal(blockContentEditor, 'Edit Block Contents', function (editFrm, modal)
                        {
                            if (typeof (global.initSummernote) === 'function')
                            {
                                global.initSummernote($(codeEditor), {
                                    dialogsInBody: true
                                });
                            }
                            // todo: bind monaco editor
                            $(blockContentEditor).on('click', '.save-btn', function ()
                            {
                                console.log(codeEditor.value);
                                selectedElement.innerHTML = codeEditor.value;
                                var layoutBlocks = selectedElement.querySelectorAll('.layout-block');
                                for (let i = 0; i < layoutBlocks.length; i++)
                                {
                                    if($(layoutBlocks[i]).hasClass('signee-field')){
                                        layoutBlocks[i].append(templates['signatureFieldEditIcon'].content.firstChild.cloneNode(true));
                                    }else{
                                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                    }

                                }
                                selectedElement.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                //layoutStyle.innerHTML = codeEditor.value;
                                modal.modal('hide');
                                editor.updateTargetEditor();
                                return false;
                            });
                            $(blockContentEditor).on('click', '.discard-btn', function ()
                            {
                                modal.modal('hide');
                                return false;
                            });
                        });
                    } else if (btn.hasClass('set-height-btn'))
                    {

                        let heightInputValue = $('.cert-height-input').val();
                        editor._setHeight(heightInputValue);
                        return;
                    }else if(btn.hasClass('add-field-btn')){
                        let btnField = btn.attr('data-field');
                        editor._addField(selectedElement,btnField);
                    } else if(btn.hasClass('updateField')){
                        let currentInput = $(editorTools).find('.signeeFieldInput');
                        let selectIcon = templates['signatureFieldEditIcon'].content.firstChild.cloneNode(true);
                        if($(selectedElement).hasClass("signee-name")){
                            $(selectedElement).attr('data-fieldVal',currentInput.val());
                            selectedElement.innerText = currentInput.val();
                            selectedElement.append(selectIcon);
                            //selectedElement.innerHTML = selectedElement.innerHTML.replace("{{Name}}",currentInput.val());
                        }else if($(selectedElement).hasClass("signee-title")){
                            $(selectedElement).attr('data-fieldVal',currentInput.val());
                            selectedElement.innerText = currentInput.val();
                            selectedElement.append(selectIcon);
                            //selectedElement.innerHTML = selectedElement.innerHTML.replace("{{Title}}",currentInput.val());
                        }else if($(selectedElement).hasClass("signee-logo")){
                            $(selectedElement).attr('data-fieldVal',currentInput.val());
                            selectedElement.innerHTML = "";
                            let createdImg = document.createElement('img');
                            createdImg.src = currentInput.val();
                            createdImg.style.width = "100%";
                            selectedElement.append(createdImg);
                            selectedElement.append(selectIcon);
                            //selectedElement.innerHTML = selectedElement.innerHTML.replace("{{LogoUrl}}",currentInput.val());
                        }else if($(selectedElement).hasClass("signee-url")){
                            $(selectedElement).attr('data-fieldVal',currentInput.val());
                            $(selectedElement).attr('data-fieldVal',currentInput.val());
                            selectedElement.innerHTML = "";
                            let createdImg = document.createElement('img');
                            createdImg.src = currentInput.val();
                            createdImg.style.width = "100%";
                            selectedElement.append(createdImg);
                            selectedElement.append(selectIcon);
                            //selectedElement.innerHTML = selectedElement.innerHTML.replace("{{SignatureUrl}}",currentInput.val());
                        }
                    }else if(btn.hasClass('field-up')){
                        let currentEl = selectedElement.previousElementSibling
                        while($(currentEl).hasClass('signature') == false){
                            if($(currentEl).hasClass('signee-field')){
                                let prev = document.createElement('template');
                                let next = document.createElement('template');
                                prev.innerHTML = selectedElement.outerHTML;
                                next.innerHTML = currentEl.outerHTML;
                                selectedElement.outerHTML = next.innerHTML;
                                currentEl.outerHTML = prev.innerHTML;
                                break;
                            }
                            currentEl = currentEl.previousElementSibling;
                        }
                    }else if(btn.hasClass('field-down')){
                        let currentEl = selectedElement.nextElementSibling
                        while($(currentEl).hasClass('signature') == false){
                            if($(currentEl).hasClass('signee-field')){
                                let prev = document.createElement('template');
                                let next = document.createElement('template');
                                prev.innerHTML = selectedElement.outerHTML;
                                next.innerHTML = currentEl.outerHTML;
                                selectedElement.outerHTML = next.innerHTML;
                                currentEl.outerHTML = prev.innerHTML;
                                break;
                            }
                            currentEl = currentEl.nextElementSibling;
                        }
                    }else if (btn.hasClass('set-width-btn'))
                    {
                        let widthInputValue = $('.cert-width-input').val();
                        editor._setWidth(widthInputValue);
                        return;
                    }else if(btn.hasClass('add-new-signature')){
                        let items = $(selectedElement).find('.signee-items');
                        console.log("------------------")
                        console.log(items);
                        let selectBtn = templates['blockSelectBtn'].content.firstChild.cloneNode(true);
                        let signature = templates['signatureItem'].content.firstChild.cloneNode(true);
                        signature.prepend(selectBtn);
                        items.append(signature);
                    }else if(btn.hasClass('clone-signature')){
                        let temp = document.createElement('template');
                        temp.innerHTML = selectedElement.outerHTML;
                        let signatureList  = $(selectedElement).parent();
                        let clonedEl = temp.content.firstChild.cloneNode(true);
                        signatureList.get(0).appendChild(clonedEl);
                    }
                    else if (btn.hasClass('remove-btn') && editorFeatures.indexOf('remove') !== -1)
                    {
                        selectedElement.remove();
                    }
                    else if (btn.hasClass('save-style-btn') && editorFeatures.indexOf('style') !== -1)
                    {
                        let styleString = editorTools.querySelector('.block-style-editor').value;
                        // todo: css validation

                        selectedElement.setAttribute('style', styleString.replace('/[\n\z]+/', ''));
                        //editor.updateTargetEditor();
                    } else if (btn.hasClass('blockClass-autocomplete-item'))
                    {
                        var autocompleteData = $(btn).attr("autocomplete-data");

                        $(".add-new-blockClass-input")[0].value = autocompleteData;
                        $(".blockClass-autocomplete-list").html("");
                    }
                    else if (btn.hasClass('discard-style-btn') && editorFeatures.indexOf('style') !== -1)
                    {
                        editorTools.querySelector('.block-style-editor').value = selectedElement.getAttribute('style') || '';
                    }
                    else if (btn.hasClass('save-cssClasses-btn') && editorFeatures.indexOf('cssClasses') !== -1)
                    {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        let classString = cssClassEditor.value;

                        let preservedClasses = (cssClassEditor.getAttribute('data-preserved-classes') || '').trim().split(',');
                        selectedElement.setAttribute('class', classString);
                        selectedElement.classList.add(...preservedClasses);
                    }
                    else if (btn.hasClass('discard-cssClasses-btn') && editorFeatures.indexOf('cssClasses') !== -1)
                    {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        cssClassEditor.value = selectedElement.classList.value;

                    } else if (btn.hasClass('delete-class-btn'))
                    {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        let currentClasses = cssClassEditor.value.split(' ');
                        let prevElement = $(btn).prev()
                        currentClasses = currentClasses.filter(p => p.trim() != prevElement[0].innerText.trim());
                        cssClassEditor.value = currentClasses.join(' ');
                        prevElement.remove();
                        $(btn).remove();
                        editor.saveCssClasses();

                        return false;
                    } else if (btn.hasClass("add-new-blockClass-button"))
                    {

                        let addInput = $('.add-new-blockClass-input')[0];
                        if (addInput.value.trim() != "")
                        {
                            var usableCssClasses = editor.usableCssClasses;
                            var filter = usableCssClasses.filter(x => x == addInput.value);
                            if (filter.length > 0)
                            {
                                let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                                cssClassEditor.value += ' ' + addInput.value.trim();
                                let cssClassList = cssClassEditor.value.split(' ')
                                addInput.value = "";
                                editor.saveCssClasses();
                                editor.updateBlockClassList(cssClassList);
                                editor.saveCssClasses();
                                return false;
                            } else
                            {
                                alert("Please check the class name");
                            }

                        }

                    } else if (btn.hasClass('advanced-editor-add-style'))
                    {
                        return false;
                    } else if (btn.hasClass('draggable-allowDragCheckbox'))
                    {
                        console.log("click")
                        $(selectedElement).toggleClass('free-position');
                        bodyContents = $(previewArea).contents().find('body')
                        $(bodyContents).find('.layout-block.selected').toggleClass('free-position');
                        console.log($(bodyContents))
                        return;
                    } else if (btn.hasClass('start_upload_button'))
                    {
                        let fileData = editor.fileData;
                        let file = fileData.file;
                        let type = fileData.type;
                        if (file == null || type == null)
                        {
                            editor.showModalMessage("File Uploader", "Please select file type and file fields");
                            return;
                        }
                        editor.fileUpload("", type, file);
                        return;
                    } else if (btn.hasClass('get_files_button'))
                    {
                        editor.getFiles("");
                        return;
                    }else if(btn.hasClass('changeId-btn')){
                        editor.showRenameModal();
                        return;
                    }
                    editor.updateEditorTools();
                    editor.updateTargetEditor();
                }
                return false;
            });
            $(editorTools).on('click', '.layout-loader .layout-select-name', function (btn)
            {
                let layoutName = btn.target.innerText;
                let layoutId = btn.target.getAttribute('data-id');
                editor.showConfirmationModal("Layout Loader Warning", "If you haven't saved the layout, your changes will be lost.",
                    function ()
                    {
                        editor.layoutStorageProvider.getLayoutData(layoutId, function (data)
                        {
                            if (data)
                            {
                                editor.loadEditorState(data);
                                editor.updateEditorTools();
                                editor.updateTargetEditor();
                            }
                            else
                            {
                                editor.showModalMessage("Layout Loader Error", "Unable to find layout with id:" + layoutId);
                            }
                        }, function (err)
                        {
                            editor.showModal('Layout Loader Error', 'Could not load layout data');
                        })
                    });
            })
            $(editorPanel).on('click', '.select-btn', function ()
            {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();
                if (block.hasClass('selected'))
                {
                    block.removeClass('selected');
                }
                else
                {
                    $('.layout-editor .layout-block.selected').removeClass('selected');
										let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    $('.layout-editor '+containerClass+'.selected').removeClass('selected');
                    block.addClass('selected');
                }
                editor.updateEditorTools();
                return false;
            });
            // ----------------------- Preview Content Selected Buttons ----------------------------------

            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            bodyContents.on('click', '.select-btn', function ()
            {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();

                if (block.hasClass('selected'))
                {
                    block.removeClass('selected');
                }
                else
                {
					let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    $(bodyContents).find('.layout-block.selected').removeClass('selected');
                    $(bodyContents).find(containerClass+'.selected').removeClass('selected');
                    block.addClass('selected');
                }
                contentArea = bodyContents[0].firstChild.cloneNode(true);
                widgets['contentArea'] = contentArea;

                editor.updateEditorTools();
                return false;
            })

            // ----------------------- End Code -----------------------------
            $(editorPanel).on('change','.layout-editor-toolbar .tool-btn',function(ev){
                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];
                let previewArea = widgets['previewContent']
                let btn = $(this);
                console.log(btn.get(0));
                if(btn.hasClass('layoutBlockSelector')){
                    let val = btn.val();
                    let obj = null;
										let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    if(val == containerName){
                        obj = $(contentArea);
                    }else{
                        obj = $(contentArea).find("[data-block-id='"+val+"']");
                    }

                    $(contentArea).find('.layout-block.selected').removeClass('selected');
                    $(contentArea).find(containerClass+'.selected').removeClass('selected');
                    obj.get(0).classList.add('selected');
                    editor.updatePreview();
                    editor.updateEditorTools();
                }
            })
            $(editorPanel).on('click', '.layout-editor-toolbar .tool-btn', function ()
            {
                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];
                let previewArea = widgets['previewContent']

                let btn = $(this);
                if (btn.hasClass('edit-style'))
                {
                    let styleEditor = templates['layoutStyleEditor'].content.firstChild.cloneNode(true);
                    let layoutStyle = widgets['layoutStyle'];
                    let codeEditor = styleEditor.querySelector('textarea');
                    codeEditor.value = layoutStyle.innerHTML;
                    let monacoEditor = null;
                    editor.showModal(styleEditor, 'Edit Layout Style', function (editFrm, modal)
                    {
                        // todo: bind monaco editor
                        if (typeof monaco !== 'undefined')
                        {
                            codeEditor.outerHTML = '<div class="style-code"></div>';
                            setTimeout(function ()
                            {
                                monacoEditor = monaco.editor.create(document.getElementsByClassName('style-code')[0], {
                                    language: 'css',
                                    value: layoutStyle.innerHTML,
                                    theme: 'vs',
                                    autoIndent: true,
                                    tabSize: 2,
                                    automaticLayout: true
                                });
                            }, 50)
                        }

                        $(styleEditor).on('click', '.save-btn', function ()
                        {
                            console.log(codeEditor.value);
                            //layoutStyle.innerHTML = codeEditor.value;
                            if (typeof monaco !== 'undefined')
                            {
                                layoutStyle.innerHTML = monacoEditor.getValue();
                            } else
                            {
                                layoutStyle.innerHTML = codeEditor.value;
                            }
                            modal.modal('hide');
                            editor.updateUsableClassList();
                            editor.updateTargetEditor();
                            //editor.usableCssClasses = parseCss(layoutStyle);
                            //console.log(editor.usableCssClasses);
                            return false;
                        });
                        $(styleEditor).on('click', '.discard-btn', function ()
                        {
                            modal.modal('hide');
                            return false;
                        });
                    });
                } else if (btn.hasClass('certificate-settings'))
                {
                    editor._createCertificateSettingsPanel();
                }
                else if (btn.hasClass('add-title-bar'))
                {
                    let titleBar = templates['blockTitleBar'].content.firstChild.cloneNode(true);
                    titleBar.innerText = 'Enter title here';
                    var layoutBlocks = titleBar.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    titleBar.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(titleBar);
                    editor.updateTargetEditor();
                }
                else if (btn.hasClass('preview-button'))
                {
                    let previewOptions = editor.previewOptions;
                    if (previewOptions.showPreview)
                    {
                        //close preview
                        let previewArea = widgets['previewContent']
                        let bodyContents = $(previewArea).contents().find('body');
                        $(bodyContents).find('.select-btn .fa-cogs').parent().css('display', 'block');
                        $(bodyContents).find('.select-btn .fa-pencil').parent().css('display', 'inline-block');
                        previewOptions.showPreview = false;
                        return;
                        //editor.updatePreview();
                    } else
                    {
                        //show preview
                        let previewArea = widgets['previewContent']
                        let bodyContents = $(previewArea).contents().find('body');
                        $(bodyContents).find('.select-btn').css('display', 'none');
                        previewOptions.showPreview = true;
                        return;
                        //editor.updatePreview();

                    }

                }
                else if (btn.hasClass('layout-save'))
                {
                    editor.saveEditorState();
                }
                else if (btn.hasClass('layout-load'))
                {
                    editor.loadLayout();
                }
                else if (btn.hasClass('add-one-col'))
                {
                    let block = templates['block1Column'].content.firstChild.cloneNode(true);
                    block.innerText = 'Enter content here';
                    var layoutBlocks = block.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(block);
                    editor.updateTargetEditor();
                }
                else if (btn.hasClass('add-two-cols'))
                {
                    let block = templates['block2Column'].content.firstChild.cloneNode(true);
                    block.querySelector('.column-1').innerText = 'Enter content here';
                    block.querySelector('.column-2').innerText = 'Enter content here';
                    var layoutBlocks = block.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(block);
                    editor.updateTargetEditor();
                }
                else if (btn.hasClass('add-three-cols'))
                {
                    let block = templates['block3Column'].content.firstChild.cloneNode(true);
                    block.querySelector('.column-1').innerText = 'Enter content here';
                    block.querySelector('.column-2').innerText = 'Enter content here';
                    block.querySelector('.column-3').innerText = 'Enter content here';
                    var layoutBlocks = block.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(block);
                    editor.updateTargetEditor();
                } else if (btn.hasClass('add-qr-code'))
                {
                    let qrItem = templates['blockQrCodeItem'].content.firstChild.cloneNode(true);
                    var layoutBlocks = qrItem.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    qrItem.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(qrItem);
                    editor.updateTargetEditor();

                }else if(btn.hasClass('add-signees-block')){
                    let signeesBlock = templates['signeesBlock'].content.firstChild.cloneNode(true);
                    var layoutBlocks = signeesBlock.querySelectorAll('.layout-block');
                    for (let i = 0; i < layoutBlocks.length; i++)
                    {
                        layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    }
                    signeesBlock.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                    contentArea.appendChild(signeesBlock);
                    editor.updateTargetEditor();
                }
                else if (btn.hasClass('add-image'))
                {
                    let imageAddForm = templates['addImageForm'].content.firstChild.cloneNode(true);
                    editor.showModal(imageAddForm, 'Add Image', function (iForm, modal)
                    {
                        $(imageAddForm).on('click', '.save-btn', function ()
                        {
                            let imgWidthFld = imageAddForm.querySelector('[name="BlockImageWidth"]');
                            let imgHeightFld = imageAddForm.querySelector('[name="BlockImageHeight"]');
                            let imgStyleFld = imageAddForm.querySelector('[name="BlockImageStyle"]');
                            let imgFld = imageAddForm.querySelector('[name="BlockImageFile"]');
                            if (imgFld.files.length > 0)
                            {
                                let imgFile = imgFld.files[0];
                                const imgReader = new FileReader();
                                //let canvas = document.createElement('canvas');
                                //let ctx = canvas.getContext('2d');
                                imgReader.onload = async (event) =>
                                {
                                    let img = new Image();
                                    img.onload = function ()
                                    {
                                        let ratio = 100 / Math.max(img.width, img.height);
                                        let w = -1;
                                        let h = -1;
                                        let block = templates['blockImage'].content.firstChild.cloneNode(true);
                                        let imgElem = block.querySelector('img');
                                        imgElem.src = event.target.result;
                                        if (!isNaN(parseInt(imgWidthFld.value)))
                                        {
                                            w = parseInt(imgWidthFld.value);
                                        }
                                        if (!isNaN(parseInt(imgHeightFld.value)))
                                        {
                                            h = parseInt(imgHeightFld.value);
                                        }

                                        let imgStyle = '';
                                        if (imgStyleFld.value.trim() !== '')
                                        {
                                            imgStyle += imgStyleFld.value.trim().replace('\n', '').replace('\r', '');
                                        }
                                        if (!imgStyle.endsWith(';'))
                                        {
                                            imgStyle += ';';
                                        }
                                        if (w === -1 && h === -1)
                                        {
                                            w = img.width;
                                            h = img.height;
                                        }
                                        else if (h === -1)
                                        {
                                            h = Math.floor(w * img.width / img.height);
                                        }
                                        else if (w === -1)
                                        {
                                            w = Math.floor(h / (img.width / img.height));
                                        }
                                        imgStyle = 'width:' + w + 'px;height:' + h + 'px;' + imgStyle;
                                        imgElem.setAttribute('style', imgStyle);
                                        var layoutBlocks = block.querySelectorAll('.layout-block');
                                        for (let i = 0; i < layoutBlocks.length; i++)
                                        {
                                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                        }
                                        block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                        contentArea.appendChild(block);
                                        modal.modal('hide');
                                        editor.updateTargetEditor();
                                    };
                                    img.src = event.target.result;
                                    setTimeout(function(){
                                        editor.assignRandomIdIfHaveLayoutBlockClass();
                                    },50);
                                }
                                imgReader.readAsDataURL(imgFile);
                                /*console.log(codeEditor.value);
                                layoutStyle.innerHTML = codeEditor.value;
                                modal.modal('hide');
                                editor.updateTargetEditor();*/
                            }

                            return false;
                        });
                        $(imageAddForm).on('click', '.discard-btn', function ()
                        {
                            modal.modal('hide');
                            return false;
                        });
                    });
                }
                editor.assignRandomIdIfHaveLayoutBlockClass();
                return false;
            });

        },
        updateUsableClassList: function ()
        {
            let editor = this;
            let widgets = editor.widgets;
            let layoutStyle = widgets['layoutStyle'];
            let previewArea = widgets['previewContent']
            let previewHead = $(previewArea).contents().find('head')
            let styles = $(previewHead).find('style')
            //console.log(styles)
            let previewDoc = $(previewArea).contents().get(0);

            let styleSheets = previewDoc.styleSheets;
            editor.usableCssClasses = [];
            var sheetLength = previewDoc.styleSheets.length;
            for (var i = 0; i < sheetLength; i++)
            {

                let styleHref = previewDoc.styleSheets[i].href;
                if (styleHref !== null)
                {
                    styleHref = new URL(styleHref).pathname;
                }

                if (editor.options["usable-style-links"].indexOf(styleHref) !== -1 || styleHref === null)
                {
                    let layoutStyleParse = parseCss(previewDoc.styleSheets[i]);
                    editor.usableCssClasses = editor.usableCssClasses.concat(layoutStyleParse);
                }


            }

            //})
            editor.usableCssClasses = [...new Set(editor.usableCssClasses)];

            return 0;
        },
        saveCssClasses: function ()
        {
            let widgets = this.widgets;
            let editorPanel = widgets['editorPanel'];
            let editorTools = widgets['editorTools'];
            let contentArea = widgets['contentArea'];

            let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
            if(selectedElement === null && contentArea.classList.contains('selected'))
            {
                selectedElement = contentArea;
            }

            if (selectedElement === null)
            {
                editor.updateEditorTools();
            }
            let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
            let classString = cssClassEditor.value;

            let preservedClasses = (cssClassEditor.getAttribute('data-preserved-classes') || '').trim().split(',');
            selectedElement.setAttribute('class', classString);
            selectedElement.classList.add(...preservedClasses);
            this.updateEditorTools();
            this.updateTargetEditor();
        },
        showModal: function (element, title, initCallback, destroyCallback)
        {
            title = title || '';
            var html = '<div id="formModal" class="modal faled" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">'
                + '<div class="modal-dialog modal-dialog-customSize"><div class="modal-content">'
                + '<div class="modal-header">' + title + '<a class="close" data-dismiss="modal">x</a></div>'
                + '<div class="modal-body">'
                + '</div>'
                + '<div class="modal-footer"></div>'
                + '</div></div>'
                + '</div>';
            $("body").append(html);
            $('#formModal .modal-body')[0].appendChild(element);
            if (typeof (initCallback) === 'function')
            {
                initCallback(element, $('#formModal'));
            }
            $("#formModal").modal();
            $("#formModal").modal("show");
            $('#formModal').on('hidden.bs.modal', function (e)
            {
                if (typeof (destroyCallback) === 'function')
                {
                    destroyCallback(element, $('#formModal'));
                }
                $(this).remove();
            });
        },
        updateEditorTools: function ()
        {

            let editor = this;
            let widgets = this.widgets;
            let editorPanel = widgets['editorPanel'];
            $(editorPanel).find('.layout-loader').remove();
            $(editorPanel).find('.certificate-settings-panel').remove();
            let templates = this.widgetTemplates;
            let featureMap = this.featureMap;
            let contentArea = widgets['contentArea'];
            let editorTools = widgets['editorTools'];
            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            $("iframe").contents().find("body").css('height', $("iframe").contents().find("body").get(0).scrollHeight + "px");
            //console.log($("iframe").contents().find("body").get(0));
            let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
            if(selectedElement === null && contentArea.classList.contains('selected'))
            {
                selectedElement = contentArea;
            }

            editor.updateUsableClassList();
            if (selectedElement === null)
            {
                $('.editor-tool-panel', $(editorTools)).hide();
            }
            else
            {
                let tools = $(editorTools);
                let elementType = $(selectedElement).data('type');
                //console.log(selectedElement);
                $('.editor-tool-panel', tools).hide();
                try
                {
                    let editorFeatures = (featureMap[elementType] || '').split(',');
                    for (let i = 0; i < editorFeatures.length; i++)
                    {
                        $('.editor-tool-panel.block-' + editorFeatures[i], tools).show();
                    }
                    if (editorFeatures.indexOf('style') !== -1)
                    {
                        // todo: css tidy
                        $('.block-style-editor', tools).val(selectedElement.getAttribute('style') || '');
                        global.initStyleEditor(selectedElement, $('.block-style-editor-div'), this);
                        editor.updateTargetEditor();
                    }
                    if (editorFeatures.indexOf('zindex') !== -1)
                    {
                        let zIndexValue = $(selectedElement).css('z-index');
                        let zIndex = zIndexValue != '' && zIndexValue != undefined ? zIndexValue : "1";
                        $('.z-index-selector-input').val(zIndex);
                    }
                    if(editorFeatures.indexOf('showId') !== -1){
                        $('.blockIdText').get(0).innerText = $(selectedElement).attr('data-block-id');
                    }
                    if (editorFeatures.indexOf('cssClasses') !== -1)
                    {
                        let selectedElementClassList = (selectedElement.classList.value || '').split(' ');

                        $('.block-cssClasses-input', tools).val(selectedElement.classList.value || '');
                        this.updateBlockClassList(selectedElementClassList);
                        let preservedClasses = [];
                        for (const c of selectedElement.classList)
                        {
                            if (this.reservedCssClasses.indexOf(c) !== -1)
                            {
                                preservedClasses.push(c);
                            }
                        }
                        $('.block-cssClasses-input', tools).attr('data-preserved-classes', preservedClasses.join(','));
                    }
                    if (editorFeatures.indexOf('qrSettings') !== -1)
                    {
                        let cellColor = $(selectedElement).find('svg').attr('data-cellcolor');
                        cellColor = cellColor != '' && cellColor != undefined ? cellColor : '#000000';
                        let backgroundColor = $(selectedElement).find('svg').attr('data-backgroundcolor');
                        backgroundColor = backgroundColor != '' && backgroundColor != undefined ? backgroundColor : '#ffffff';
                        let emptycellColor = $(selectedElement).find('svg').attr('data-emptycellcolor');
                        emptycellColor = emptycellColor != '' && emptycellColor != undefined ? emptycellColor : '#ffffff';
                        let wh = parseInt($(selectedElement).find('svg').css('width'));
                        $('.qr-size-selector option').removeAttr('selected');
                        $('.qr-size-selector').val(wh);
                        $('.qr-size-selector option[value=' + wh + ']').attr("selected", true);
                        $('.qr-code-cell-color-input').val(cellColor);
                        $('.qr-code-empty-cell-color-input').val(emptycellColor);
                        $('.qr-code-background-color-input').val(backgroundColor);
                    }
                    if (editorFeatures.indexOf('draggableMenu') !== -1)
                    {
                        let selectedElementClassList = (selectedElement.classList.value || '').split(' ');
                        let freePos = selectedElementClassList.filter(x => x.trim() == "free-position");
                        if (freePos.length != 0)
                        {
                            $(".draggable-allowDragCheckbox").get(0).checked = true;
                        } else
                        {
                            $(".draggable-allowDragCheckbox").get(0).checked = false;
                        }
                    }
                    if(editorFeatures.indexOf('signatureSpan') !== -1){
                        tools.find('.signeeFieldInput').val($(selectedElement).attr('data-fieldVal') ?? "");
                    }
                }
                catch (e)
                {
                    console.log(e);
                    $('.editor-tool-panel', tools).hide();
                }
            }
        },
        updateBlockClassList: function (classListArray)
        {

            let classListBlock = $('.block-cssClasses-list');
            classListBlock.html('')
            classListArray.forEach(function (className)
            {
                if (className != "free-position")
                {
                    var classInputDiv = document.createElement('div');
                    var classNameText = document.createElement("p");
                    var deleteIconA = document.createElement('a');
                    $(classNameText).css("display", "inline")
                    $(classNameText).css("margin-right", "2px");
                    classNameText.innerText = className;
                    var deleteIcon = document.createElement('i');
                    deleteIconA.classList.add("btn", "delete-class-btn");
                    deleteIcon.classList.add("fas", "fa-trash");
                    deleteIconA.append(deleteIcon);
                    classInputDiv.appendChild(classNameText);
                    classInputDiv.append(deleteIconA);
                    classListBlock.append(classInputDiv);
                }

            })
        },
        updateTargetEditor: function ()
        {
            let editor = this;
            let options = editor.options;
            let widgets = editor.widgets;
            let contentArea = widgets['contentArea'];
            let layoutStyle = widgets['layoutStyle'];
            let targetEditor = document.getElementById(options.target);
            if (targetEditor !== null)
            {
                let tmpContent = document.createElement('template');
                tmpContent.innerHTML = contentArea.outerHTML;
                let selectedElems = tmpContent.content.firstChild.querySelectorAll('.selected');
                for (let i = 0; i < selectedElems.length; i++)
                {
                    selectedElems[i].classList.remove('selected');
                }
                tmpContent.content.firstChild.classList.remove('selected');
                let selectBtns = tmpContent.content.firstChild.querySelectorAll('.select-btn');
                for (let i = 0; i < selectBtns.length; i++)
                {
                    selectBtns[i].remove();
                }
                targetEditor.value = (layoutStyle.outerHTML || '') + '\n\n' + tmpContent.innerHTML;

            }
            this.updatePreview();
        },
        syncFromTargetEditor: function ()
        {
            let editor = this;
            let options = editor.options;
            let targetEditor = document.getElementById(options.target);
            if (targetEditor.value.trim() !== '')
            {
                let widgets = editor.widgets;
                let templates = editor.widgetTemplates;
                let contentArea = widgets['contentArea'];
                let layoutStyle = widgets['layoutStyle'];
                try
                {
                    let initialContent = document.createElement('template');
                    initialContent.innerHTML = targetEditor.value.trim();
                    let layoutStyleElem = initialContent.content.querySelector('style.layout-style');
                    if (layoutStyleElem !== null)
                    {
                        layoutStyle.innerHTML = layoutStyleElem.innerHTML;
                    }
					let containerName = editor.options['container-name'];
					let containerClass = '.'+editor.options['container-name'];
                    let layoutContentElem = initialContent.content.querySelector(containerClass);
                    if (layoutContentElem !== null)
                    {
                        var layoutBlocks = layoutContentElem.querySelectorAll('.layout-block.signees');
                        for (let i = 0; i < layoutBlocks.length; i++)
                        {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        //layoutContentElem.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));

                        contentArea = layoutContentElem.cloneNode(true);
                        widgets['contentArea'] = contentArea;

                        if (widgets['contentArea'].getAttribute('data-type') === null)
                        {
                            widgets['contentArea'].setAttribute('data-type', 'layout-container');
                        }
                    }
                }
                catch
                {
                    console.log('Could not parse existing content.');
                }
            }
            else
            {
                let widgets = editor.widgets;
                let templates = editor.widgetTemplates;
                let contentArea = widgets['contentArea'];
                console.log('empty target');
                contentArea.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
            }
            this.updatePreview();
        }
    });

    global.initEditor = function (target, options)
    {
        if (target.length === 0) return;
        var defOptions = {};
        if (global.uiCulture != null && global.uiCulture !== '')
        {
            defOptions['lang'] = global.uiCulture;
        }
        $.extend(defOptions, options);
        defOptions['target'] = target.attr('id');
        defOptions['id'] = 'layout-editor-' + target.attr('id');
        defOptions['styles'] = target.attr('data-css');
        defOptions['usable-style-links'] = target.attr('data-usable-css');
        defOptions['variables'] = target.attr('data-variables');
        defOptions['container-name'] = target.attr('data-container-name');
        defOptions['toolbarFeatures'] = target.attr('data-toolbar-features');
        if(target.attr('data-placeholderChar') != null && target.attr('data-placeholderChar').indexOf(',') !== -1){
          defOptions['placeholderStartChar'] = target.attr('data-placeholderChar').split(',')[0].trim();
          defOptions['placeholderEndChar'] = target.attr('data-placeholderChar').split(',')[1].trim();
      }
        let layoutStorageProvider = target.data('layout-storage');
        if (layoutStorageProvider != null && typeof (global.layoutStorageProviders[layoutStorageProvider]) === 'function')
        {
            defOptions['layoutStorageProvider'] = layoutStorageProvider;
        }
        let layoutStorageProviderOptions = target.data('layout-storage-options');
        if (layoutStorageProviderOptions != null)
        {
            defOptions['layoutStorageProviderOptions'] = layoutStorageProviderOptions;
        }
        target.usortaEditor(defOptions);
        console.log($('.signees-editor')[0]);
    };

    global.initEditor($('.signees-editor'), {});

})(jQuery, window);
