import { Component, OnInit } from '@angular/core';
import { ApplicationTenantService } from '../../../services/appTenant/application-tenant.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-application-tenant',
  templateUrl: './delete-application-tenant.component.html',
  styleUrls: ['./delete-application-tenant.component.css']
})
export class DeleteApplicationTenantComponent implements OnInit {

  tenantId:string;
  appTenantId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private appTenantService:ApplicationTenantService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.tenantId = param["tenantId"];
        this.appTenantId = parseInt(param["appTenantId"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteApplicationTenant(){
    this.appTenantService.deleteApplicationTenant(this.tenantId,this.appTenantId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Application-Tenant successfully deleted");
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
