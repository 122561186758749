import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-update-localized-format-string',
  templateUrl: './update-localized-format-string.component.html',
  styleUrls: ['./update-localized-format-string.component.css']
})
export class UpdateLocalizedFormatStringComponent implements OnInit {
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/localization/formatstring/"+param["id"]+"/update"
      }
    })
  }

}
