<div class="formMain form-default-border form-maroon-border">
  <div class="form-title-div">
    <font class="form-title-content">{{'educationTheme.addThemeTitle' | translate}}</font>
</div>
    <div class="mb-3">
        <label for="name" class="form-label label mainLabel">{{'educationTheme.name' | translate}}</label>
        <input type="text" class="form-control" id="name" placeholder="name">
      </div>

      <div class="mb-3">
        <label for="key" class="form-label label mainLabel">{{'educationTheme.key' | translate}}</label>
        <input type="text" class="form-control" id="key" placeholder="key">
      </div>

      <div class="mb-3">
        <label for="active" class="form-label label mainLabel">{{'educationTheme.active' | translate}} : <input type="checkbox" class="form-check-input" id="active" placeholder="active"></label>

      </div>
      <p class="label mainLabel">{{'educationTheme.styles' | translate}}</p>
      <div class="themeStyleMain">
        <div class="themeStyle">

        </div>
      </div>
      <br/>
      <p class="label mainLabel">{{'educationTheme.rawStyle' | translate}}</p>
      <div class="themeStyleRawMain">
        <div class="themeStyleRaw">

        </div>
      </div>
      <br/>
      <p class="label mainLabel">{{'educationTheme.scripts' | translate}}</p>
      <div class="themeScriptsMain">
        <div class="themeScripts">

        </div>
      </div>
      <br/>
      <p class="label mainLabel">{{'educationTheme.textOverrides' | translate}}</p>
      <div class="themeTextOverridesMain">
        <div class="themeTextOverrides">

        </div>
      </div>
      <br/>
      <p class="label mainLabel">Tema Verileri</p>
      <div class="themeDataMain">
        <div class="themeData">

        </div>
      </div>
      <br/>
      <div class="mb-3" style="display:none;">
        <label for="themeFiles" class="form-label label mainLabel">{{'educationTheme.files' | translate}}</label>
        <input class="form-control" type="file" id="themeFiles" name="themeFiles" multiple>
      </div>
      <div style="text-align: right;">
        <button [disabled]="loading" class="btn btn-primary btn-sm" (click)="createEducationTheme()">{{'btn.add' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
      </div>
      
      <br/><br/>

</div>
