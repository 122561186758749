import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EducationService } from '../../../services/education/education.service';
import { EducationModel } from '../../../models/Education/educationModel';

@Component({
  selector: 'lib-education-details',
  templateUrl: './education-details.component.html',
  styleUrls: ['./education-details.component.css']
})
export class EducationDetailsComponent implements OnInit {
  educationId:string;
  education:EducationModel;
  categoryTreeId:number = 1;
  constructor(private activatedRoute:ActivatedRoute,private educationService:EducationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.educationId = param["educationId"];
        this.getEducation();
      }
    })
  }
  getEducation(){
    this.educationService.getEducation(this.educationId).subscribe({
      next:(response)=>{
        console.log(response.content)
        this.education = response.content;
      }
    })
  }

}
