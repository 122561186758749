import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VMCustomization } from 'projects/usorta-library/src/lib/models/VirtualLabModels/vmCustomization';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';
declare var monaco:any;
declare var $:any;
declare var window:any;
@Component({
  selector: 'lib-customize-run-scripts',
  templateUrl: './customize-run-scripts.component.html',
  styleUrls: ['./customize-run-scripts.component.css']
})
export class CustomizeRunScriptsComponent implements OnInit {
  vmId:string;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
        this.initializeMonacoEditor();
      }
    })

  }
  initializeMonacoEditor(){
    window["scriptEditor"] = monaco.editor.create(document.getElementsByClassName("monacoScriptEditor")[0],{
      value:"",
      language: "shell",
      minimap:{enabled:false},
      automaticLayout: true
    })
  }
  addScript(){
    var script = window["scriptEditor"].getValue();
    var addCustomizationValue:VMCustomization = {
      type: "script",
      args: script
    }
    console.log(addCustomizationValue);
    this.virtualLabService.addCustomizationToVm(this.vmId,addCustomizationValue).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("vmCustomization.addedParameter"));
      },
      error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
