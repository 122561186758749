<h5>{{'vmCustomization.copyFiles' | translate}}</h5>
<hr/>
<div>
    <div class="mb-3">
        <label for="fileList" class="form-label">{{'vmCustomization.selectFilesForCopy' | translate}}</label>
        <input (change)="prepareFiles()" class="form-control" type="file" id="fileList" multiple>
      </div>
      <hr>
      <h6>{{'vmCustomization.selectedFiles' | translate}}</h6>
      <div class="card mb-3" *ngFor="let file of files">
        <div class="card-body">
          <font>{{file.fileName}}</font><br/>
          <font style="font-size: 13px;"><b>{{'vmCustomization.targetPath' | translate}} </b><input class="form-control form-control-sm" (keyup)="setTargetPath(file,$event)" type="text" placeholder="Hedef Konum Girin" id="targetPath"/></font>
          <font style="font-size: 13px;"><b>{{'vmCustomization.fileName' | translate}} </b><input [value]="file.fileName" class="form-control form-control-sm" (keyup)="setName(file,$event)" type="text" placeholder="Yeni dosya adını girin" id="fileName"/></font>
          <div class="form-check">
            <label class="form-check-label">
                {{'vmCustomization.overwriteFile' | translate}}
              </label>
            <input class="form-check-input" (change)="setOverwrite(file,$event)" type="checkbox" value="" id="flexCheckDefault">
          </div>
        </div>

      </div>
      <button class="btn btn-warning" (click)="addFilesToCustomization()">{{'vmCustomization.addFiles' | translate}}</button>
      <br/>
</div>