import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ResponseModel } from '../../models/responseModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { AppSettingsImageModel } from '../../models/AppSettings/appSettingsImageModel';

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {

  constructor(private httpClient:HttpClient) { }
  uploadApplicationConfigurationLogo(appId:string,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/applications/${appId}/configurations/logo/upload`,{id:appId,image:image});
  }
  uploadApplicationConfigurationDefaultCourseImage(appId:string,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/applications/${appId}/configurations/courseimage/upload`,{id:appId,image:image});
  }
  uploadApplicationTenantConfigurationLogo(appTenantId:number,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/apptenants/${appTenantId}/configurations/logo/upload`,{id:appTenantId,image:image});
  }
  uploadApplicationTenantConfigurationDefaultCourseImage(appTenantId:number,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/apptenants/${appTenantId}/configurations/courseimage/upload`,{id:appTenantId,image:image});
  }
  uploadTenantConfigurationLogo(tenantId:string,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/tenants/${tenantId}/configurations/logo/upload`,{id:tenantId,image:image});
  }
  uploadTenantConfigurationDefaultCourseImage(tenantId:string,image:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(apiUrl + `api/cms/tenants/${tenantId}/configurations/courseimage/upload`,{id:tenantId,image:image});
  }
  getTenantImages(tenantId:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<AppSettingsImageModel>>(apiUrl + `api/cms/tenants/${tenantId}/configurations/images`);
  }
  getAppTenantImages(appTenantId:number){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<AppSettingsImageModel>>(apiUrl + `api/cms/apptenants/${appTenantId}/configurations/images`);
  }
  getApplicationImages(appId:string){
    var apiUrl:string = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<AppSettingsImageModel>>(apiUrl + `api/cms/applications/${appId}/configurations/images`);
  }
}
