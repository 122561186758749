import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SetupVmInstancePublishRequest } from 'projects/usorta-library/src/lib/models/setupVMInstanceModels/setupVmInstancePublishRequest';
import { SetupVmInstancePublishResponse } from 'projects/usorta-library/src/lib/models/setupVMInstanceModels/setupVmInstancePublishResponse';
import { SetupVmInstanceService } from 'projects/usorta-library/src/lib/services/setupVmInstance/setup-vm-instance.service';

@Component({
  selector: 'lib-publish-setup-vm-instance',
  templateUrl: './publish-setup-vm-instance.component.html',
  styleUrls: ['./publish-setup-vm-instance.component.css']
})
export class PublishSetupVmInstanceComponent implements OnInit {
  vmId:string;
  req:SetupVmInstancePublishRequest = {setupVMId:"",nameOverride:undefined}
  publishResponse:SetupVmInstancePublishResponse
  success:boolean = false;
  constructor(private activatedRoute:ActivatedRoute,private setupVmInstanceService:SetupVmInstanceService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.vmId = param["vmId"];
      }
    })
  }
  publish(){
    var nameEl = document.getElementById("newVmName") as HTMLInputElement;
    var name = nameEl.value != "" ? nameEl.value : undefined;
    this.req.nameOverride = name;
    this.req.setupVMId = this.vmId;
    if(this.req.setupVMId == '' || this.req.setupVMId == null){
      this.toastrService.error(this.translateService.instant("global.pleaseCheckFormFields"));
      return;
    }
    this.setupVmInstanceService.publishVm(this.req).subscribe({
      next:(response)=>{
        this.publishResponse = response.content;
        if(this.publishResponse.jobId != null){
          this.toastrService.success(this.translateService.instant("setupVmInstance.publishRequestSuccessfullyMessage"));
          
        }else{
          this.toastrService.success(this.translateService.instant("global.taskCompleted"));
        }
        this.success = true;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
