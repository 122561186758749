import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionOptionDetailsModel } from '../../../models/questionManagementModels/questionOptionDetailsModel';
import { QuestionService } from '../../../services/questionManager/question.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-question-option-details',
  templateUrl: './question-option-details.component.html',
  styleUrls: ['./question-option-details.component.css']
})
export class QuestionOptionDetailsComponent implements OnInit {
  questionId:number;
  optionId:number;
  details:QuestionOptionDetailsModel
  constructor(private activatedRoute:ActivatedRoute,private questionService:QuestionService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionId = param["questionId"];
        this.optionId = param["optionId"];
        this.getDetails();
      }
    })
  }
  getDetails(){
    this.questionService.getQuestionOptionDetails(this.questionId,this.optionId).subscribe({
      next:(response)=>{
        this.details = response.content;
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }

}
