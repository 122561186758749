<div *ngIf="loading && customTableMain == null" class="loadingForm customTableLoading formMain form-default-border form-maroon-border">
<!--<div class="d-flex align-items-center">
  <strong>{{'customTable.loadingTable' | translate}}</strong>
  <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
</div>-->
<h4>{{"customTable.loadingTable" | translate}}</h4>
<div class="loadingSpinner">
  <div class="d-flex justify-content-center">
    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
</div>

<div *ngIf="!loading && customTableMain != null" class="mainTableDiv form-maroon-border form-default-border" style="background-color: white;">
  <div style="margin-bottom: 3px;">
    <font class="form-title-content">{{customTableMain.title | translate}}</font>
    <br />
    <font style="font-size:14px; color:gray;">{{customTableMain.description | translate}}</font>
  </div>
  <div>
    <lib-custom-form *ngIf="searchOptions != null" [searchForm]="true" (GetCsForm)="getSearchForm($event)" [externalFormData]="searchOptions" [showSuccessIcon]="false"
      (formResponseOut)="fetchFilteredData($event)"></lib-custom-form>

  </div>
  <div class="tableCommands">
    <button *ngFor="let tableCommand of customTableMain.structure.tableCommands" [class]="(tableCommand.cssClasses ?? 'btn btn-secondary btn-sm')+' me-2'"
      (click)="openLink(null,tableCommand)"><i [class]="tableCommand.icon"></i> {{tableCommand.label | translate}}</button>
  </div>
  <br /><br />
  <div class="table-responsive" style="text-align: center; position: relative;">
    <div style="background-color: black; opacity: 0.5; width:100%; height: 100%; position: absolute;" *ngIf="pageLoading != 0">

    </div>
    <div class="text-center" style="position: absolute; top: 40%; left: 45%;" *ngIf="pageLoading != 0">
      <div class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <table [style]="customTableMain.styleData.rawStyle" class="table2 table table-light table-hover">

      <thead>
        <tr>
          <th *ngFor="let field of customTableMain.structure.fields" [class.sortField]="field.sortable" (click)="changeSort(field)" scope="col">{{field.title | translate}}<i [class]="getSortParams(field)"></i></th>
          <th *ngFor="let rowcommand of customTableMain.structure.rowCommands"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tableData of showPageData()">
          <td *ngFor="let field of customTableMain.structure.fields"  (click)="openFieldActionLink(tableData,field)" scope="row"
            [innerHTML]="getTableData(this,field,tableData)"></td>
          <td *ngFor="let rowCommand of customTableMain.structure.rowCommands" scope="row"
            (click)="openLink(tableData,rowCommand)"><button [class]="rowCommand.cssClasses"><i [class]="rowCommand.icon"></i><b> {{rowCommand.label | translate}}</b></button></td>
        </tr>
      </tbody>

    </table>
  </div>
  <br /><br />
  <div *ngIf="apiTableResponse && apiTableResponse.pagerData && apiTableResponse.pagerData.pageCount != 0">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item" [style]="'cursor:'+prevPageIcon()+'; user-select:none;'"><a class="page-link" (click)="prevPage()">{{customTableMain.pagerData.prevPageLabel != "" && customTableMain.pagerData.prevPageLabel != null
            ? (customTableMain.pagerData.prevPageLabel | translate) : "<"}}</a></li>
        <li *ngFor="let page of pages" style="cursor:pointer;" class="page-item" [class.active]="shownPage == page"><a class="page-link"
            (click)="changePage(page)">{{page}} <span *ngIf="showPageLoader(page)" style="color:black;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></a></li>
        <li class="page-item" [style]="'cursor:'+nextPageIcon()+'; user-select:none;'"><a class="page-link" (click)="nextPage()">{{customTableMain.pagerData.nextPageLabel != "" && customTableMain.pagerData.nextPageLabel != null
            ? (customTableMain.pagerData.nextPageLabel | translate) : ">"}}</a></li>
      </ul>
    </nav>
  </div>
</div>

<!--<lib-global-modal [modalCreateData]="globalModalObject"></lib-global-modal>-->

<!-- Modals -->

<!-- Modal -->
<!--<div class="modal fade table-modal" id="staticBackdrop" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">USorta Panel</h5>
        <button type="button" class="btn-close table-modal-x" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div id="modalData">


      </div>
      <div class="modal-footer">
        <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Understood</button> 
      </div>
    </div>
  </div>
</div> -->

<!-- Modals End -->
