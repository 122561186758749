import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RoleDetailsModel } from 'projects/usorta-library/src/lib/models/AccessControl/roleDetailsModel';
import { AccessControlService } from 'projects/usorta-library/src/lib/services/access-control/access-control.service';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';

@Component({
  selector: 'lib-get-assigned-service-roles',
  templateUrl: './get-assigned-service-roles.component.html',
  styleUrls: ['./get-assigned-service-roles.component.css']
})
export class GetAssignedServiceRolesComponent implements OnInit {

  userId:string;
  assignedRoles:RoleDetailsModel[];
  @Input() extUserId:string;
  constructor(private globalOkCancelEvent:GlobalOkCancelEventService,private router:Router,private activatedRoute:ActivatedRoute,private translateService:TranslateService,private toastrService:ToastrService,private accessControlService:AccessControlService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.userId = param["userId"] ?? this.extUserId;
        this.getAssignedRoles();
      }
    })
  }
  refresh(){
    this.getAssignedRoles();
  }
  getAssignedRoles(){
    this.accessControlService.getAssignedServiceRoles(this.userId).subscribe({
      next:(response)=>{
        this.assignedRoles = response.content;
      }
    })
  }
  assignRolePage(){
    this.router.navigate(["acl/service-accounts/"+this.userId+"/addrole"])
  }
  removeRole(roleId:string){
    this.globalOkCancelEvent.showOkCancelWindow("Access Management",this.translateService.instant("accessControl.removeRoleFromUserMessage")).then(x=>{
      if(x){
        this.accessControlService.removeRoleFromServiceAccount({roleId:roleId,userId:this.userId}).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("accessControl.removedRoleFromUser"));
            this.refresh();
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          }
        })
      }else{

      }
    })

  }


}
