import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionService } from '../../../services/questionManager/question.service';
import { CreateQuestionSetModel } from '../../../models/questionManagementModels/createQuestionSet';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-create-question-set',
  templateUrl: './create-question-set.component.html',
  styleUrls: ['./create-question-set.component.css']
})
export class CreateQuestionSetComponent implements OnInit {
  imageChangedEvent:any;
  createQuestionSetForm:FormGroup;
  parents:ListItemModel[];
  parentsLoaded:boolean = false;
  loading:boolean;
  constructor(private formBuilder:FormBuilder,private sanitizer:DomSanitizer,private questionService:QuestionService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getParents().then(()=>{
      this.createQuestionSetForm = this.formBuilder.group({
        name : ["",Validators.required],
        enabled:[false],
        image:[null],
        color:["#000000"],
        parentId:[null]
      })
    });


  }
  setImage(ev:any){
    this.imageChangedEvent = ev;
  }
  croppedImage(data:ImageCroppedEvent){
    var img = this.sanitizer.bypassSecurityTrustUrl(data.objectUrl ?? "");
    this.createQuestionSetForm.get("image")?.setValue(data.base64);
  }

  getParents() : Promise<boolean> {
    return new Promise<boolean>((resolve,reject)=>{
      this.questionService.getQuestionSetList(true).subscribe({
        next:(response)=>{
          this.parents = response.content;
          resolve(true);
          this.parentsLoaded = true;
        },error:(err)=>{
          this.toastrService.error("An error occurred");
          resolve(true);
          this.parentsLoaded = true;
        }
      })
    })
  }
  addQuestionSet(){
    if(this.createQuestionSetForm.valid){
      this.loading = true;
      var formValues:CreateQuestionSetModel = Object.assign({},this.createQuestionSetForm.value);
      formValues.parentId = formValues.parentId as any == "null" ? null : parseInt(formValues.parentId as any);
      this.questionService.createQuestionSet(formValues).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success("Soru kümesi eklendi");
            this.loading = false;
            this.clearForm();
          }
        },
        error:(err)=>{
          this.loading = false;
          this.toastrService.error("Soru kümesi eklenemedi");
        }
      })
    }else{
      this.loading = false;
      this.toastrService.error("Form bilgilerini kontrol edin");
    }
  }
  clearForm(){
    this.createQuestionSetForm.reset();
  }

}
