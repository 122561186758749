import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateVmInstanceMappingModel } from '../../../models/vmInstanceTypeMappings/createVmInstanceTypeMappingModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-create-vm-instance-type-mapping',
  templateUrl: './create-vm-instance-type-mapping.component.html',
  styleUrls: ['./create-vm-instance-type-mapping.component.css']
})
export class CreateVmInstanceTypeMappingComponent implements OnInit {
  baseVmInstanceTypeId:string;
  providers:ListItemModel[] = [];
  hosts:ListItemModel[] = [];
  instanceTypes:ListItemModel[] = [];
  createMappingForm:FormGroup;
  selectedProviderType:string;
  selectedHost:string | null;
  ramList:ListItemModel[] = [];
  cpuList:ListItemModel[] = [];
  constructor(private translateService:TranslateService, private formBuilder:FormBuilder,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private vmInstanceService:VmInstanceService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:async (param)=>{
        this.baseVmInstanceTypeId = param["basetypeid"];
        this.getRamList();
        this.getCpuCountList();
        this.createForm();
        await this.prepareForm();


      }
    })
  }
  createForm(){
    this.createMappingForm = this.formBuilder.group({
      //targetInstanceTypeId : [Validators.required],
      name:["",Validators.required],
      typeId:["",Validators.required],
      active:[false],
      notes:[""],
      priority:[0],
      ram:[""],
      cpuCount:[1]
    })
  }
  getProviderList():Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getProviderList().subscribe({
        next:(response)=>{
          this.providers = response.content;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }
  getHostList(provider:string):Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getHostList(provider).subscribe({
        next:(response)=>{
          this.hosts = response.content;
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }
  getRamList(){
    this.vmInstanceService.getRamList().subscribe({
      next:(response)=>{
        response.content = response.content.reverse();
        response.content.push({value:null,label:"Not-Selected"});
        response.content = response.content.reverse();
        this.ramList = response.content;
        
        this.createMappingForm.get("ram")?.setValue(this.ramList[0].value);
      }
    })
  }
  getCpuCountList(){
    this.vmInstanceService.getCpuCountList().subscribe({
      next:(response)=>{
        response.content = response.content.reverse();
        response.content.push({value:"0",label:"Not-Selected"});
        response.content = response.content.reverse();
        this.cpuList = response.content;
        this.createMappingForm.get("cpuCount")?.setValue(this.cpuList[0].value);
      }
    })
  }
  changeProvider(){
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    this.getHostList(providerSelect.value);
    this.selectedProviderType = providerSelect.value;
    this.selectedHost = null;
    //this.getInstanceTypes(providerSelect.value);
  }
  changeHost(){
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    var hostSelect = document.getElementById("hostId") as HTMLInputElement;
    this.selectedHost = hostSelect.value;
    //this.getInstanceTypes(providerSelect.value,hostSelect.value);
  }
  /*getInstanceTypes(provider:string,host:string|undefined|null=null):Promise<boolean>{
    return new Promise((res,rej)=>{
      this.vmInstanceService.getVmInstanceTypesWithProviderTypeAndHost(provider,host).subscribe({
        next:(response)=>{
          this.instanceTypes = response.content;
          this.createMappingForm.get("targetInstanceType")?.setValue(response.content[0].value);
          res(true);
        },error:(err)=>{
          res(false);
        }
      })
    })
  }*/
  addMapping(){
    if(this.createMappingForm.valid){
      var data:CreateVmInstanceMappingModel = Object.assign({},this.createMappingForm.value);
      data.priority = typeof(data.priority) == "string" ? parseInt(data.priority) : data.priority;
      data.cpuCount = typeof(data.cpuCount) == "string" ? parseInt(data.cpuCount) : data.cpuCount;
      if((data.ram as any) == "null"){
        (data.ram as any) = null;
      }
      data.baseInstanceTypeId = this.baseVmInstanceTypeId;
      data.providerTypeId = this.selectedProviderType;
      if(data.hostId == "null"){
        (data.hostId as any) = null;
      }else{
        (data.hostId as any) = this.selectedHost
      }

      console.log(data);
      this.vmInstanceService.addVmInstanceTypeMapping(data).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("global.createdSuccessfully"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }
  async prepareForm(){
    await this.getProviderList();
    await this.getHostList(this.providers[0].value);
    this.selectedHost = null;
    this.selectedProviderType = this.providers[0].value;
    //await this.getInstanceTypes(this.providers[0].value)
    return true;
  }


}
