import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-media-content-list',
  templateUrl: './media-content-list.component.html',
  styleUrls: ['./media-content-list.component.css']
})
export class MediaContentListComponent implements OnInit {
  tableUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.tableUrl = environment.apiUrl + "api/lms/contents/media/list"
  }

}
