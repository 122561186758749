import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-update-service-account',
  templateUrl: './update-service-account.component.html',
  styleUrls: ['./update-service-account.component.css']
})
export class UpdateServiceAccountComponent implements OnInit {
  updateForm:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.updateForm = environment.apiUrl+ "api/accounts/service-account/"+param['user']+"/update";
      }
    })
  }

}
