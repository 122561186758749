import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-add-nic-to-setup-vm-instance',
  templateUrl: './add-nic-to-setup-vm-instance.component.html',
  styleUrls: ['./add-nic-to-setup-vm-instance.component.css']
})
export class AddNicToSetupVmInstanceComponent implements OnInit {
  actionUrl:string = environment.apiUrl;
  formUrl:string;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formUrl = environment.apiUrl+`api/range/vm/setupvminstances/${param["vmId"]}/nics/add`
      }
    })
  }

}
