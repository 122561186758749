import { CustomChartComponent } from './../../../usorta-library/src/lib/components/custom-chart/custom-chart.component';
import { CustomLayoutPageComponent } from './components/custom-layout-page/custom-layout-page.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageComponent } from './components/manage/manage.component';
import { FormsComponent } from './components/forms/forms.component';
import { LayoutEditorComponent } from './components/layout-editor/layout-editor.component';
import { CertificateEditorComponent } from './components/certificate-editor/certificate-editor.component';
import { SigneeEditorComponent } from './components/signee-editor/signee-editor.component';
import { BasicTableComponent } from 'projects/usorta-library/src/lib/components/basic-table/basic-table.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UpdateUserComponent } from './components/user-management/updateUser/update-user/update-user.component';
import { CreateUserComponent } from './components/user-management/create-user/create-user.component';
import { NotLoggedGuard } from 'projects/usorta-library/src/guards/not-logged.guard';
import { LoggedGuard } from 'projects/usorta-library/src/guards/logged.guard';
import { ChangeUserPasswordComponent } from './components/user-management/change-user-password/change-user-password.component';
import { DeleteUserComponent } from './components/user-management/delete-user/delete-user.component';
import { BlockUserComponent } from './components/user-management/block-user/block-user.component';
import { AddFeatureTypeComponent } from './components/add-feature-type/add-feature-type.component';
import { AddSessionTemplateComponent } from './components/add-session-template/add-session-template.component';
import { AddSessionTemplateFeatureComponent } from './components/add-session-template-feature/add-session-template-feature.component';
import { FeatureTypeTableComponent } from './components/feature-type-table/feature-type-table.component';
import { DeleteFeatureTypeComponent } from './components/feature-type-table/delete-feature-type/delete-feature-type.component';
import { UpdateFeatureTypeComponent } from './components/update-feature-type/update-feature-type.component';
import { AddSessionTemplateItemComponent } from './components/add-session-template-item/add-session-template-item.component';
import { SessionsComponent } from './components/sessions/sessions.component';
import { SessionTemplatesComponent } from './components/session-templates/session-templates.component';
import { DeleteSessionTemplateComponent } from './components/session-templates/delete-session-template/delete-session-template.component';
import { UpdateSessionTemplateComponent } from './components/session-templates/update-session-template/update-session-template.component';
import { UpdateSessionTemplateFeatureComponent } from './components/session-templates/update-session-template-feature/update-session-template-feature.component';
import { DeleteSessionTemplateFeatureComponent } from './components/session-templates/delete-session-template-feature/delete-session-template-feature.component';
import { DeleteSessionTemplateItemComponent } from './components/session-templates/delete-session-template-item/delete-session-template-item.component';
import { AddSessionComponent } from './components/add-session/add-session.component';
import { ManagementComponent } from './components/management/management.component';
import { SessionManagementDeleteFeatureComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-delete-feature/session-management-delete-feature.component';
import { SessionManagementDeleteItemComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-delete-item/session-management-delete-item.component';
import { SessionManagementAddFeatureComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-add-feature/session-management-add-feature.component';
import { SessionManagementAddItemComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-add-item/session-management-add-item.component';
import { SessionManagementUpdateSessionComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-update-session/session-management-update-session.component';
import { SessionManagementRemoveMemberFromSessionComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-remove-member-from-session/session-management-remove-member-from-session.component';
import { SessionManagementAddMemberToSessionComponent } from 'projects/usorta-library/src/lib/components/sessionManagementPanel/session-management-add-member-to-session/session-management-add-member-to-session.component';
import { NewEducationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/new-education/new-education.component';
import { UpdateEducationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-education/update-education.component';
import { AddEducationCategoryComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-education-category/add-education-category.component';
import { EducationDetailsComponent } from 'projects/usorta-library/src/lib/components/educationManagement/education-details/education-details.component';
import { AddSectionToEducationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-section-to-education/add-section-to-education.component';
import { AddSubsectionToSectionComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-subsection-to-section/add-subsection-to-section.component';
import { AddOutlineToEducationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-outline-to-education/add-outline-to-education.component';
import { UpdateEducationSectionComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-education-section/update-education-section.component';
import { UpdateEducationSubsectionComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-education-subsection/update-education-subsection.component';
import { UpdateEducationCategoryComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-education-category/update-education-category.component';
import { EducationCategoryDetailsComponent } from 'projects/usorta-library/src/lib/components/educationManagement/education-category-details/education-category-details.component';
import { DeleteEducationCategoryComponent } from 'projects/usorta-library/src/lib/components/educationManagement/delete-education-category/delete-education-category.component';
import { CreateEducationThemeComponent } from 'projects/usorta-library/src/lib/components/educationThemes/create-education-theme/create-education-theme.component';
import { UpdateEducationThemeComponent } from 'projects/usorta-library/src/lib/components/educationThemes/update-education-theme/update-education-theme.component';
import { AddCourseContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/add-course-content/add-course-content.component';
import { EducationSectionDetailsComponent } from 'projects/usorta-library/src/lib/components/educationManagement/education-section-details/education-section-details.component';
import { AddNewVersionComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-new-version/add-new-version.component';
import { VersionDetailsComponent } from 'projects/usorta-library/src/lib/components/educationManagement/version-details/version-details.component';
import { CreateQuestionSetComponent } from 'projects/usorta-library/src/lib/components/questionManagement/create-question-set/create-question-set.component';
import { CreateQuestionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/create-question/create-question.component';
import { CreateQuestionOptionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/create-question-option/create-question-option.component';
import { QuestionDetailsComponent } from 'projects/usorta-library/src/lib/components/questionManagement/question-details/question-details.component';
import { UpdateQuestionSetComponent } from 'projects/usorta-library/src/lib/components/questionManagement/update-question-set/update-question-set.component';
import { UpdateQuestionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/update-question/update-question.component';
import { QuestionSetDetailsComponent } from 'projects/usorta-library/src/lib/components/questionManagement/question-set-details/question-set-details.component';
import { UpdateQuestionOptionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/update-question-option/update-question-option.component';
import { AddExamComponent } from 'projects/usorta-library/src/lib/components/examManagement/add-exam/add-exam.component';
import { CreateVmEnvironmentComponent } from 'projects/usorta-library/src/lib/components/virtualLab/create-vm-environment/create-vm-environment.component';
import { UpdateVmEnvironmentComponent } from 'projects/usorta-library/src/lib/components/virtualLab/update-vm-environment/update-vm-environment.component';
import { DeleteVmEnvironmentComponent } from 'projects/usorta-library/src/lib/components/virtualLab/delete-vm-environment/delete-vm-environment.component';
import { AddVirtualMachineComponent } from 'projects/usorta-library/src/lib/components/virtualLab/add-virtual-machine/add-virtual-machine.component';
import { UpdateVirtualMachineComponent } from 'projects/usorta-library/src/lib/components/virtualLab/update-virtual-machine/update-virtual-machine.component';
import { CustomizeSetRootPasswordComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-set-root-password/customize-set-root-password.component';
import { CustomizeAddNewUserComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-add-new-user/customize-add-new-user.component';
import { CustomizeInstallPackagesComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-install-packages/customize-install-packages.component';
import { CustomizeChangePasswordComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-change-password/customize-change-password.component';
import { CustomizeRunScriptsComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-run-scripts/customize-run-scripts.component';
import { CustomizeCopyFilesComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-customization/customize-copy-files/customize-copy-files.component';
import { VirtualMachineDetailsComponent } from 'projects/usorta-library/src/lib/components/virtualLab/virtual-machine-details/virtual-machine-details.component';
import { CreateLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/create-lab/create-lab.component';
import { ManageLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/manage-lab/manage-lab.component';
import { UpdateLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/update-lab/update-lab.component';
import { UpdateVmInLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/manage-lab/update-vm-in-lab/update-vm-in-lab.component';
import { UpdateControlCriteriaComponent } from 'projects/usorta-library/src/lib/components/virtualLab/manage-lab/update-control-criteria/update-control-criteria.component';
import { AddVmToLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/manage-lab/add-vm-to-lab/add-vm-to-lab.component';
import { AddControlCriteriaComponent } from 'projects/usorta-library/src/lib/components/virtualLab/manage-lab/add-control-criteria/add-control-criteria.component';
import { AddCategoryTreeComponent } from 'projects/usorta-library/src/lib/components/educationManagement/categoryTree/add-category-tree/add-category-tree.component';
import { UpdateCategoryTreeComponent } from 'projects/usorta-library/src/lib/components/educationManagement/categoryTree/update-category-tree/update-category-tree.component';
import { CategoryTreeListComponent } from 'projects/usorta-library/src/lib/components/educationManagement/categoryTree/category-tree-list/category-tree-list.component';
import { EducationCategoryListComponent } from 'projects/usorta-library/src/lib/components/educationManagement/education-category-list/education-category-list.component';
import { CoursesTableComponent } from 'projects/usorta-library/src/lib/components/educationManagement/courses-table/courses-table.component';
import { EducationThemesTableComponent } from 'projects/usorta-library/src/lib/components/educationThemes/education-themes-table/education-themes-table.component';
import { VirtualLabListComponent } from 'projects/usorta-library/src/lib/components/virtualLab/virtual-lab-list/virtual-lab-list.component';
import { VmEnvironmentListComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-environment-list/vm-environment-list.component';
import { VirtualMachineListComponent } from 'projects/usorta-library/src/lib/components/virtualLab/virtual-machine-list/virtual-machine-list.component';
import { EducationThemeDetailComponent } from 'projects/usorta-library/src/lib/components/educationThemes/education-theme-detail/education-theme-detail.component';
import { CourseContentDetailsComponent } from 'projects/usorta-library/src/lib/components/courseContent/course-content-details/course-content-details.component';
import { DeleteCourseContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/delete-course-content/delete-course-content.component';
import { DeleteEducationThemeComponent } from 'projects/usorta-library/src/lib/components/educationThemes/delete-education-theme/delete-education-theme.component';
import { DeleteEducationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/delete-education/delete-education.component';
import { UpdateEducationContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/update-education-content/update-education-content.component';
import { UpdateEducationVersionComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-education-version/update-education-version.component';
import { DeleteCategoryTreeComponent } from 'projects/usorta-library/src/lib/components/educationManagement/categoryTree/delete-category-tree/delete-category-tree.component';
import { CreateInstallerImageComponent } from 'projects/usorta-library/src/lib/components/virtualLab/create-installer-image/create-installer-image.component';
import { UpdateInstallerImageComponent } from 'projects/usorta-library/src/lib/components/virtualLab/update-installer-image/update-installer-image.component';
import { DeleteInstallerImageComponent } from 'projects/usorta-library/src/lib/components/virtualLab/delete-installer-image/delete-installer-image.component';
import { UploadInstallerImageComponent } from 'projects/usorta-library/src/lib/components/virtualLab/upload-installer-image/upload-installer-image.component';
import { InstallerImageListComponent } from 'projects/usorta-library/src/lib/components/virtualLab/installer-image-list/installer-image-list.component';
import { InstallerImageDetailsComponent } from 'projects/usorta-library/src/lib/components/virtualLab/installer-image-details/installer-image-details.component';
import { ActiveConnectionsComponent } from 'projects/usorta-library/src/lib/components/active-connections/active-connections.component';
import { GuacamoleLmsSessionTerminalsComponent } from 'projects/usorta-library/src/lib/components/guacamole-lms-session-terminals/guacamole-lms-session-terminals.component';
import { CreateVmInstanceTypeComponent } from 'projects/usorta-library/src/lib/components/vm-instance-types/create-vm-instance-type/create-vm-instance-type.component';
import { UpdateVmInstanceTypeComponent } from 'projects/usorta-library/src/lib/components/vm-instance-types/update-vm-instance-type/update-vm-instance-type.component';
import { DeleteVmInstanceTypeComponent } from 'projects/usorta-library/src/lib/components/vm-instance-types/delete-vm-instance-type/delete-vm-instance-type.component';
import { ManageVmInstanceTypeComponent } from 'projects/usorta-library/src/lib/components/vm-instance-types/manage-vm-instance-type/manage-vm-instance-type.component';
import { CreateVmInstanceTypeMappingComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/create-vm-instance-type-mapping/create-vm-instance-type-mapping.component';
import { UpdateVmInstanceTypeMappingComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/update-vm-instance-type-mapping/update-vm-instance-type-mapping.component';
import { DeleteVmInstanceTypeMappingComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/delete-vm-instance-type-mapping/delete-vm-instance-type-mapping.component';
import { ManageVmInstanceTypeMappingComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/manage-vm-instance-type-mapping/manage-vm-instance-type-mapping.component';
import { CreateHostProviderTypeComponent } from 'projects/usorta-library/src/lib/components/host-provider-components/create-host-provider-type/create-host-provider-type.component';
import { UpdateHostProviderTypeComponent } from 'projects/usorta-library/src/lib/components/host-provider-components/update-host-provider-type/update-host-provider-type.component';
import { DeleteHostProviderTypeComponent } from 'projects/usorta-library/src/lib/components/host-provider-components/delete-host-provider-type/delete-host-provider-type.component';
import { ManageHostProviderComponent } from 'projects/usorta-library/src/lib/components/host-provider-components/manage-host-provider/manage-host-provider.component';
import { ListHostProviderTypeComponent } from 'projects/usorta-library/src/lib/components/host-provider-components/list-host-provider-type/list-host-provider-type.component';
import { ListVmInstanceTypeMappingComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/list-vm-instance-type-mapping/list-vm-instance-type-mapping.component';
import { VmInstanceTypeListComponent } from 'projects/usorta-library/src/lib/components/vm-instance-types/vm-instance-type-list/vm-instance-type-list.component';
import { VmInstanceTypeMappingNoteComponent } from 'projects/usorta-library/src/lib/components/vm-instance-type-mappings/vm-instance-type-mapping-note/vm-instance-type-mapping-note.component';
import { VmEnvironmentDetailsComponent } from 'projects/usorta-library/src/lib/components/virtualLab/vm-environment-details/vm-environment-details.component';
import { HostDashboardComponent } from 'projects/usorta-library/src/lib/components/host-dashboard/host-dashboard/host-dashboard.component';
import { AdminManagementComponent } from './components/admin-management/admin-management.component';
import { UpdateAdminComponent } from './components/admin-management/update-admin/update-admin.component';
import { CreateAdminComponent } from './components/admin-management/create-admin/create-admin.component';
import { DeleteAdminComponent } from './components/admin-management/delete-admin/delete-admin.component';
import { BlockAdminComponent } from './components/admin-management/block-admin/block-admin.component';
import { ChangeAdminPasswordComponent } from './components/admin-management/change-admin-password/change-admin-password.component';
import { ServiceAccountsComponent } from './components/service-accounts/service-accounts.component';
import { CreateServiceAccountComponent } from './components/service-accounts/create-service-account/create-service-account.component';
import { UpdateServiceAccountComponent } from './components/service-accounts/update-service-account/update-service-account.component';
import { ChangeServiceAccountPasswordComponent } from './components/service-accounts/change-service-account-password/change-service-account-password.component';
import { DeleteServiceAccountComponent } from './components/service-accounts/delete-service-account/delete-service-account.component';
import { ServiceAccountDetailsComponent } from './components/service-accounts/service-account-details/service-account-details.component';
import { UserDetailsComponent } from './components/user-management/user-details/user-details.component';
import { AdminDetailsComponent } from './components/admin-management/admin-details/admin-details.component';
import { AddLocaleComponent } from 'projects/usorta-library/src/lib/components/locales/add-locale/add-locale.component';
import { LocalesTableComponent } from 'projects/usorta-library/src/lib/components/locales/locales-table/locales-table.component';
import { DeleteLocaleComponent } from 'projects/usorta-library/src/lib/components/locales/delete-locale/delete-locale.component';
import { UpdateLocaleComponent } from 'projects/usorta-library/src/lib/components/locales/update-locale/update-locale.component';
import { LocaleDetailsComponent } from 'projects/usorta-library/src/lib/components/locales/locale-details/locale-details.component';
import { CreateLocalizedStringComponent } from 'projects/usorta-library/src/lib/components/localizationString/create-localized-string/create-localized-string.component';
import { UpdateLocalizedStringComponent } from 'projects/usorta-library/src/lib/components/localizationString/update-localized-string/update-localized-string.component';
import { LocalizedStringTableComponent } from 'projects/usorta-library/src/lib/components/localizationString/localized-string-table/localized-string-table.component';
import { LocalizedStringComponent } from 'projects/usorta-library/src/lib/components/localizationString/localized-string/localized-string.component';
import { DeleteLocalizedStringComponent } from 'projects/usorta-library/src/lib/components/localizationString/delete-localized-string/delete-localized-string.component';
import { CreateLocalizedFormatStringComponent } from 'projects/usorta-library/src/lib/components/localizedFormatString/create-localized-format-string/create-localized-format-string.component';
import { LocalizedFormatStringListComponent } from 'projects/usorta-library/src/lib/components/localizedFormatString/localized-format-string-list/localized-format-string-list.component';
import { UpdateLocalizedFormatStringComponent } from 'projects/usorta-library/src/lib/components/localizedFormatString/update-localized-format-string/update-localized-format-string.component';
import { LocalizedFormatStringDetailsComponent } from 'projects/usorta-library/src/lib/components/localizedFormatString/localized-format-string-details/localized-format-string-details.component';
import { DeleteLocalizedFormatStringComponent } from 'projects/usorta-library/src/lib/components/localizedFormatString/delete-localized-format-string/delete-localized-format-string.component';
import { CreateTenantComponent } from 'projects/usorta-library/src/lib/components/tenants/create-tenant/create-tenant.component';
import { DeleteTenantComponent } from 'projects/usorta-library/src/lib/components/tenants/delete-tenant/delete-tenant.component';
import { UpdateTenantComponent } from 'projects/usorta-library/src/lib/components/tenants/update-tenant/update-tenant.component';
import { GetTenantDetailsComponent } from 'projects/usorta-library/src/lib/components/tenants/get-tenant-details/get-tenant-details.component';
import { GetTenantsTableComponent } from 'projects/usorta-library/src/lib/components/tenants/get-tenants-table/get-tenants-table.component';
import { CreateMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/create-menu/create-menu.component';
import { MenuListTableComponent } from 'projects/usorta-library/src/lib/components/navigation/menu-list-table/menu-list-table.component';
import { CreateMenuItemComponent } from 'projects/usorta-library/src/lib/components/navigation/create-menu-item/create-menu-item.component';
import { UpdateMenuItemComponent } from 'projects/usorta-library/src/lib/components/navigation/update-menu-item/update-menu-item.component';
import { UpdateMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/update-menu/update-menu.component';
import { MenuItemListComponent } from 'projects/usorta-library/src/lib/components/navigation/menu-item-list/menu-item-list.component';
import { DeleteMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/delete-menu/delete-menu.component';
import { DeleteMenuItemComponent } from 'projects/usorta-library/src/lib/components/navigation/delete-menu-item/delete-menu-item.component';
import { LocalizedMenuListComponent } from 'projects/usorta-library/src/lib/components/navigation/localized-menu-list/localized-menu-list.component';
import { LocalizedItemMenuListComponent } from 'projects/usorta-library/src/lib/components/navigation/localized-item-menu-list/localized-item-menu-list.component';
import { DeleteLocalizedMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/delete-localized-menu/delete-localized-menu.component';
import { DeleteLocalizedItemMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/delete-localized-item-menu/delete-localized-item-menu.component';
import { UpdateLocalizedItemMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/update-localized-item-menu/update-localized-item-menu.component';
import { UpdateLocalizedMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/update-localized-menu/update-localized-menu.component';
import { CreateLocalizedMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/create-localized-menu/create-localized-menu.component';
import { CreateLocalizedItemMenuComponent } from 'projects/usorta-library/src/lib/components/navigation/create-localized-item-menu/create-localized-item-menu.component';
import { MenuItemDetailsComponent } from 'projects/usorta-library/src/lib/components/navigation/menu-item-details/menu-item-details.component';
import { LocalizedItemMenuDetailsComponent } from 'projects/usorta-library/src/lib/components/navigation/localized-item-menu-details/localized-item-menu-details.component';
import { TestTypedStrComponent } from './components/test-typed-str/test-typed-str.component';
import { QuestionSetListComponent } from 'projects/usorta-library/src/lib/components/questionManagement/question-set-list/question-set-list.component';
import { DeleteQuestionSetComponent } from 'projects/usorta-library/src/lib/components/questionManagement/delete-question-set/delete-question-set.component';
import { QuestionsTableComponent } from 'projects/usorta-library/src/lib/components/questionManagement/questions-table/questions-table.component';
import { DeleteQuestionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/delete-question/delete-question.component';
import { DeleteQuestionOptionComponent } from 'projects/usorta-library/src/lib/components/questionManagement/delete-question-option/delete-question-option.component';
import { QuestionOptionDetailsComponent } from 'projects/usorta-library/src/lib/components/questionManagement/question-option-details/question-option-details.component';
import { AddQuestionTranslationComponent } from 'projects/usorta-library/src/lib/components/questionManagement/add-question-translation/add-question-translation.component';
import { VmSessionGroupsListComponent } from 'projects/usorta-library/src/lib/components/connection-manager/vm-session-groups-list/vm-session-groups-list.component';
import { VmSessionsListComponent } from 'projects/usorta-library/src/lib/components/connection-manager/vm-sessions-list/vm-sessions-list.component';
import { VmSessionConnectionsListComponent } from 'projects/usorta-library/src/lib/components/connection-manager/vm-session-connections-list/vm-session-connections-list.component';
import { VmConnectionsListComponent } from 'projects/usorta-library/src/lib/components/connection-manager/vm-connections-list/vm-connections-list.component';
import { CreateBaseVmImageComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/create-base-vm-image/create-base-vm-image.component';
import { UploadBaseVmImageComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/upload-base-vm-image/upload-base-vm-image.component';
import { CreateSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/create-setup-vm-instance/create-setup-vm-instance.component';
import { UpdateBaseVmImageComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/update-base-vm-image/update-base-vm-image.component';
import { BaseVmImageDetailComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/base-vm-image-detail/base-vm-image-detail.component';
import { BaseVmImagesTableComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/base-vm-images-table/base-vm-images-table.component';
import { DeleteBaseVmImageComponent } from 'projects/usorta-library/src/lib/components/base-vm-images/delete-base-vm-image/delete-base-vm-image.component';
import { ManageSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-setup-vm-instance.component';
import { AddCourseTranslationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/add-course-translation/add-course-translation.component';
import { UpdateCourseTranslationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/update-course-translation/update-course-translation.component';
import { CourseTranslationDetailsComponent } from 'projects/usorta-library/src/lib/components/educationManagement/course-translation-details/course-translation-details.component';
import { DeleteCourseTranslationComponent } from 'projects/usorta-library/src/lib/components/educationManagement/delete-course-translation/delete-course-translation.component';
import { CourseTranslationListComponent } from 'projects/usorta-library/src/lib/components/educationManagement/course-translation-list/course-translation-list.component';
import { ListQuestionsByQuestionSetIdComponent } from 'projects/usorta-library/src/lib/components/questionManagement/list-questions-by-question-set-id/list-questions-by-question-set-id.component';
import { AddCategoryFilterToCourseListComponent } from 'projects/usorta-library/src/lib/components/course-lists/add-category-filter-to-course-list/add-category-filter-to-course-list.component';
import { AddLevelFilterToCourseListComponent } from 'projects/usorta-library/src/lib/components/course-lists/add-level-filter-to-course-list/add-level-filter-to-course-list.component';
import { AddLocaleFilterToCourseListComponent } from 'projects/usorta-library/src/lib/components/course-lists/add-locale-filter-to-course-list/add-locale-filter-to-course-list.component';
import { AddBeginDateFilterToCourseListComponent } from 'projects/usorta-library/src/lib/components/course-lists/add-begin-date-filter-to-course-list/add-begin-date-filter-to-course-list.component';
import { AddEndDateFilterToCourseListComponent } from 'projects/usorta-library/src/lib/components/course-lists/add-end-date-filter-to-course-list/add-end-date-filter-to-course-list.component';
import { CreateApplicationConfigurationComponent } from 'projects/usorta-library/src/lib/components/application-configuration/create-application-configuration/create-application-configuration.component';
import { CreateTenantConfigurationComponent } from 'projects/usorta-library/src/lib/components/application-configuration/create-tenant-configuration/create-tenant-configuration.component';
import { CreateApplicationTenantConfigurationComponent } from 'projects/usorta-library/src/lib/components/application-configuration/create-application-tenant-configuration/create-application-tenant-configuration.component';
import { UploadConfigurationImageComponent } from 'projects/usorta-library/src/lib/components/application-configuration/upload-configuration-image/upload-configuration-image.component';
import { ApplicationTenantListTableComponent } from 'projects/usorta-library/src/lib/components/application-tenants/application-tenant-list-table/application-tenant-list-table.component';
import { CreateApplicationTenantComponent } from 'projects/usorta-library/src/lib/components/application-tenants/create-application-tenant/create-application-tenant.component';
import { CreateApplicationComponent } from 'projects/usorta-library/src/lib/components/applications/create-application/create-application.component';
import { ApplicationListTableComponent } from 'projects/usorta-library/src/lib/components/applications/application-list-table/application-list-table.component';
import { UpdateApplicationComponent } from 'projects/usorta-library/src/lib/components/applications/update-application/update-application.component';
import { DeleteApplicationComponent } from 'projects/usorta-library/src/lib/components/applications/delete-application/delete-application.component';
import { DeleteApplicationTenantComponent } from 'projects/usorta-library/src/lib/components/application-tenants/delete-application-tenant/delete-application-tenant.component';
import { ApplicationDetailsComponent } from 'projects/usorta-library/src/lib/components/applications/application-details/application-details.component';
import { SwitchTranslateModeComponent } from './components/switch-translate-mode/switch-translate-mode.component';
import { UpdateLocalizedStringWithKeyComponent } from 'projects/usorta-library/src/lib/components/localizationString/update-localized-string-with-key/update-localized-string-with-key.component';
import { GetLocalizedStringTableWithKeyComponent } from 'projects/usorta-library/src/lib/components/localizationString/get-localized-string-table-with-key/get-localized-string-table-with-key.component';
import { CreateMultipleUserComponent } from './components/user-management/create-multiple-user/create-multiple-user.component';
import { CreateServiceRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/create-service-role/create-service-role.component';
import { CreateUserRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/create-user-role/create-user-role.component';
import { CreateAdminRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/create-admin-role/create-admin-role.component';
import { UpdateServiceRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/update-service-role/update-service-role.component';
import { UpdateUserRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/update-user-role/update-user-role.component';
import { UpdateAdminRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/update-admin-role/update-admin-role.component';
import { ListServiceRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/list-service-roles/list-service-roles.component';
import { ListUserRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/list-user-roles/list-user-roles.component';
import { ListAdminRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/list-admin-roles/list-admin-roles.component';
import { ServiceRoleDetailsComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/service-role-details/service-role-details.component';
import { UserRoleDetailsComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/user-role-details/user-role-details.component';
import { AdminRoleDetailsComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/admin-role-details/admin-role-details.component';
import { DeleteUserRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/delete-user-role/delete-user-role.component';
import { DeleteAdminRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/delete-admin-role/delete-admin-role.component';
import { DeleteServiceRoleComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/delete-service-role/delete-service-role.component';
import { AddRoleToAdminComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/add-role-to-admin/add-role-to-admin.component';
import { AddRoleToServiceComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/add-role-to-service/add-role-to-service.component';
import { AddRoleToUserComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/add-role-to-user/add-role-to-user.component';
import { GetAssignedUserRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/get-assigned-user-roles/get-assigned-user-roles.component';
import { GetAssignedAdminRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/admin/get-assigned-admin-roles/get-assigned-admin-roles.component';
import { GetAssignedServiceRolesComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/service/get-assigned-service-roles/get-assigned-service-roles.component';
import { DeleteRoleFromUserComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/delete-role-from-user/delete-role-from-user.component';
import { AddSelectedRoleToUsersComponent } from 'projects/usorta-library/src/lib/components/access-control/roles/user/add-selected-role-to-users/add-selected-role-to-users.component';
import { VideoTimelineComponent } from 'projects/usorta-library/src/lib/components/video-timeline/video-timeline.component';
import { LmsVideoPlayerComponent } from 'projects/usorta-library/src/lib/components/lms-video-player/lms-video-player.component';
import { CreateMediaContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/create-media-content/create-media-content.component';
import { UploadMediaContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/upload-media-content/upload-media-content.component';
import { MediaContentListComponent } from 'projects/usorta-library/src/lib/components/courseContent/media-content-list/media-content-list.component';
import { UpdateMediaContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/update-media-content/update-media-content.component';
import { DeleteMediaContentComponent } from 'projects/usorta-library/src/lib/components/courseContent/delete-media-content/delete-media-content.component';
import { MediaContentDetailsComponent } from 'projects/usorta-library/src/lib/components/courseContent/media-content-details/media-content-details.component';
import { OutlineManagementPanelComponent } from 'projects/usorta-library/src/lib/components/outline-management-panel/outline-management-panel.component';
import { CmsMediaViewerComponent } from 'projects/usorta-library/src/lib/components/courseContent/cms-media-viewer/cms-media-viewer.component';
import { CreateCourseSeatComponent } from 'projects/usorta-library/src/lib/components/courseSeats/create-course-seat/create-course-seat.component';
import { AddSeatAccessComponent } from 'projects/usorta-library/src/lib/components/courseSeats/add-seat-access/add-seat-access.component';
import { UpdateCourseSeatComponent } from 'projects/usorta-library/src/lib/components/courseSeats/update-course-seat/update-course-seat.component';
import { ListCourseSeatsComponent } from 'projects/usorta-library/src/lib/components/courseSeats/list-course-seats/list-course-seats.component';
import { DeleteBaseVmImageNoteComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-notes/delete-base-vm-image-note/delete-base-vm-image-note.component';
import { CreateBaseVmImageNoteComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-notes/create-base-vm-image-note/create-base-vm-image-note.component';
import { UpdateBaseVmImageNoteComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-notes/update-base-vm-image-note/update-base-vm-image-note.component';
import { UpdateBaseVmImageInfoComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-infos/update-base-vm-image-info/update-base-vm-image-info.component';
import { ListBaseVmImageInfoRevisionsComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-infos/list-base-vm-image-info-revisions/list-base-vm-image-info-revisions.component';
import { GetBaseVmImageInfoRevisionComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-infos/get-base-vm-image-info-revision/get-base-vm-image-info-revision.component';
import { RestoreBaseVmImageInfoRevisionComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-infos/restore-base-vm-image-info-revision/restore-base-vm-image-info-revision.component';
import { SetupVmInstanceDashboardComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setup-vm-instance-dashboard/setup-vm-instance-dashboard.component';
import { CreateRemovableImageComponent } from 'projects/usorta-library/src/lib/components/removable-images/create-removable-image/create-removable-image.component';
import { UploadRemovableImageComponent } from 'projects/usorta-library/src/lib/components/removable-images/upload-removable-image/upload-removable-image.component';
import { DeleteRemovableImageComponent } from 'projects/usorta-library/src/lib/components/removable-images/delete-removable-image/delete-removable-image.component';
import { UpdateRemovableImageComponent } from 'projects/usorta-library/src/lib/components/removable-images/update-removable-image/update-removable-image.component';
import { ListRemovableImagesComponent } from 'projects/usorta-library/src/lib/components/removable-images/list-removable-images/list-removable-images.component';
import { RemovableImageDetailsComponent } from 'projects/usorta-library/src/lib/components/removable-images/removable-image-details/removable-image-details.component';
import { CloneSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/clone-setup-vm-instance/clone-setup-vm-instance.component';
import { PublishSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/publish-setup-vm-instance/publish-setup-vm-instance.component';
import { SetupVmInstanceUpdateSpecsComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/setup-vm-instance-update-specs/setup-vm-instance-update-specs.component';
import { AddDiskToSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/add-disk-to-setup-vm-instance/add-disk-to-setup-vm-instance.component';
import { AddNicToSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/add-nic-to-setup-vm-instance/add-nic-to-setup-vm-instance.component';
import { UpdateDiskFromSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/update-disk-from-setup-vm-instance/update-disk-from-setup-vm-instance.component';
import { UpdateNicFromSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/update-nic-from-setup-vm-instance/update-nic-from-setup-vm-instance.component';
import { AddRemovableImageToSetupVmInstanceComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/setupVmInstanceModalPages/add-removable-image-to-setup-vm-instance/add-removable-image-to-setup-vm-instance.component';
import { CreateIconSetComponent } from 'projects/usorta-library/src/lib/components/icons/create-icon-set/create-icon-set.component';
import { UpdateIconSetComponent } from 'projects/usorta-library/src/lib/components/icons/update-icon-set/update-icon-set.component';
import { CreateIconComponent } from 'projects/usorta-library/src/lib/components/icons/create-icon/create-icon.component';
import { UpdateIconComponent } from 'projects/usorta-library/src/lib/components/icons/update-icon/update-icon.component';
import { IconSetListComponent } from 'projects/usorta-library/src/lib/components/icons/icon-set-list/icon-set-list.component';
import { IconSetIconListComponent } from 'projects/usorta-library/src/lib/components/icons/icon-set-icon-list/icon-set-icon-list.component';
import { DeleteIconSetComponent } from 'projects/usorta-library/src/lib/components/icons/delete-icon-set/delete-icon-set.component';
import { IconDetailsComponent } from 'projects/usorta-library/src/lib/components/icons/icon-details/icon-details.component';
import { IconSetDetailsComponent } from 'projects/usorta-library/src/lib/components/icons/icon-set-details/icon-set-details.component';
import { LabNetworkGraphComponent } from 'projects/usorta-library/src/lib/components/virtualLab/lab-workbench/lab-network-graph/lab-network-graph.component';
import { LabDashboardComponent } from 'projects/usorta-library/src/lib/components/virtualLab/lab-dashboard/lab-dashboard.component';
import { CreateSetupLabComponent } from 'projects/usorta-library/src/lib/components/virtualLab/create-setup-lab/create-setup-lab.component';
import { CloneLabBlueprintComponent } from 'projects/usorta-library/src/lib/components/virtualLab/clone-lab-blueprint/clone-lab-blueprint.component';
import { CloneLabDraftComponent } from 'projects/usorta-library/src/lib/components/virtualLab/clone-lab-draft/clone-lab-draft.component';
import { LabWorkbenchComponent } from 'projects/usorta-library/src/lib/components/virtualLab/lab-workbench/lab-workbench.component';
import { ManageBaseVmImageRemoteConnectionComponent } from 'projects/usorta-library/src/lib/components/setupVMInstance/manage-setup-vm-instance/manage-base-vm-image-remote-connection/manage-base-vm-image-remote-connection.component';

const routes: Routes = [
  {path:"login",component:LoginComponent,canActivate:[NotLoggedGuard]},
  {path:"",component:ManageComponent,canActivate:[LoggedGuard]},
  {path:"manage/:n",component:ManageComponent,pathMatch:"full"},
  {path:"forms/:formId",component:FormsComponent,pathMatch:"full"},
  {path:"customlayout",component:CustomLayoutPageComponent,pathMatch:"full"},
  {path:"chart",component:CustomChartComponent,pathMatch:"full"},
  {path:"layout",component:LayoutEditorComponent,pathMatch:"full"},
  {path:"certificate",component:CertificateEditorComponent,pathMatch:"full"},
  {path:"signee",component:SigneeEditorComponent,pathMatch:"full"},
  {path:"users",component:ManageComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"userss",component:UserManagementComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/user/:user/update",component:UpdateUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"user/:userId/details",component:UserDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/admin/:user/update",component:UpdateAdminComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"admin/:userId/details",component:AdminDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/user/create",component:CreateUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/admin/create",component:CreateAdminComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"user/create",component:CreateUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"admin/create",component:CreateAdminComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/user/:userId/delete",component:DeleteUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/admin/:userId/delete",component:DeleteAdminComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/user/:userId/block",component:BlockUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/admin/:userId/block",component:BlockAdminComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/user/:user/changepassword",component:ChangeUserPasswordComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/admin/:user/changepassword",component:ChangeAdminPasswordComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"addFeature",component:AddFeatureTypeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"addSessionTemplate",component:AddSessionTemplateComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"addSessionTemplateFeature",component:AddSessionTemplateFeatureComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"featureTypes",component:FeatureTypeTableComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/featureType/update/:featureId",component:UpdateFeatureTypeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/deleteFeatureType/:featureTypeId",component:DeleteFeatureTypeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"addSessionTemplateItem",component:AddSessionTemplateItemComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"sessions",component:SessionsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"session-templates",component:SessionTemplatesComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/session-templates/:sessionTemplateId/delete",component:DeleteSessionTemplateComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"session-templates/update/:templateId",component:UpdateSessionTemplateComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/session-templates/:templateId/features/:featureId/update",component:UpdateSessionTemplateFeatureComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/session-templates/:templateId/features/:featureId/delete",component:DeleteSessionTemplateFeatureComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/session-templates/:templateId/items/:itemId/delete",component:DeleteSessionTemplateItemComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"sessions/create",component:AddSessionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/session-templates/:templateId/features/:featureId/update",component:UpdateSessionTemplateFeatureComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"session/:sessionId",component:ManagementComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/sessions/:sessionId/feature/:featureId/delete",pathMatch:"full",component:SessionManagementDeleteFeatureComponent,canActivate:[LoggedGuard]},
  {path:"modal/sessions/:sessionId/item/:itemId/delete",pathMatch:"full",component:SessionManagementDeleteItemComponent,canActivate:[LoggedGuard]},
  {path:"modal/session/:sessionId/addfeature",pathMatch:"full",component:SessionManagementAddFeatureComponent,canActivate:[LoggedGuard]},
  {path:"modal/session/:sessionId/additem",pathMatch:"full",component:SessionManagementAddItemComponent,canActivate:[LoggedGuard]},
  {path:"modal/session/:sessionId/update",pathMatch:"full",component:SessionManagementUpdateSessionComponent,canActivate:[LoggedGuard]},
  {path:"modal/session/:sessionId/member/:userId/remove",component:SessionManagementRemoveMemberFromSessionComponent,canActivate:[LoggedGuard]},
  {path:"modal/session/:sessionId/addMember",component:SessionManagementAddMemberToSessionComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/new",component:NewEducationComponent,canActivate:[LoggedGuard]},
  {path:"lms/course/:educationId/update",component:UpdateEducationComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/categorytrees/course/:categoryTreeId/leaves/create",component:AddEducationCategoryComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/categorytrees/course/:categoryTreeId/update",component:UpdateCategoryTreeComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/:educationId/details",component:EducationDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/course/:versionId/section/:sectionId/addSubsection",component:AddSubsectionToSectionComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/course/:educationId/addOutline",component:AddOutlineToEducationComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/section/:sectionId/update",component:UpdateEducationSectionComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/outline/:sectionId/update",component:UpdateEducationSectionComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/course/:educationId/section/:sectionId/subsection/:subsectionId/update",component:UpdateEducationSubsectionComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/categorytrees/course/:categoryTreeId/leaves/:categoryId/update",component:UpdateEducationCategoryComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/categories/:categoryId/details",component:EducationCategoryDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/categorytrees/course/:categoryTreeId/leaves/:categoryId/delete",component:DeleteEducationCategoryComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/themes/create",component:CreateEducationThemeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/themes/:themeId/update",component:UpdateEducationThemeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courses/section/:sectionId/details",component:EducationSectionDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courses/version/:versionId/details",component:VersionDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courselists/:courseList/filters/category/add",component:AddCategoryFilterToCourseListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courselists/:courseList/filters/level/add",component:AddLevelFilterToCourseListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courselists/:courseList/filters/locale/add",component:AddLocaleFilterToCourseListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courselists/:courseList/filters/begindate/add",component:AddBeginDateFilterToCourseListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courselists/:courseList/filters/enddate/add",component:AddEndDateFilterToCourseListComponent,canActivate:[LoggedGuard]},
  {path:"lms/questionsets/create",component:CreateQuestionSetComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions/:id/translations/create",component:AddQuestionTranslationComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questionsets",component:QuestionSetListComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/questionset/:id/delete",component:DeleteQuestionSetComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions/create",component:CreateQuestionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/questions/:id/delete",component:DeleteQuestionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions/:questionId/addoption",component:CreateQuestionOptionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions/:questionId/details",component:QuestionDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questionset/:questionSetId/update",component:UpdateQuestionSetComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/question/:questionId/update",component:UpdateQuestionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questionset/:questionSetId/details",component:QuestionSetDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questionset/:setId/questions",component:ListQuestionsByQuestionSetIdComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/question/:questionId/option/:optionId/update",component:UpdateQuestionOptionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions",component:QuestionsTableComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/question/:questionId/option/:optionId/delete",component:DeleteQuestionOptionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/questions/:questionId/option/:optionId/details",component:QuestionOptionDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  
  {path:"lms/exam/add",component:AddExamComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/hosts/add",component:CreateVmEnvironmentComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/hosts/:hostId/update",component:UpdateVmEnvironmentComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/hosts/:hostId/details",component:VmEnvironmentDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/range/hosts/:hostId/delete",component:DeleteVmEnvironmentComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lab/vm/create",component:AddVirtualMachineComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lab/vm/:vmId/update",component:UpdateVirtualMachineComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/setrootpassword",component:CustomizeSetRootPasswordComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/adduser",component:CustomizeAddNewUserComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/installpackages",component:CustomizeInstallPackagesComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/changepassword",component:CustomizeChangePasswordComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/runscripts",component:CustomizeRunScriptsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/vm/:vmId/customization/copyfiles",component:CustomizeCopyFilesComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lab/vm/:vmId/details",component:VirtualMachineDetailsComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/labblueprints/create",component:CreateLabComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/labblueprints/:labId/details",component:ManageLabComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/labblueprints/:labId/update",component:UpdateLabComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"range/labblueprints/:labId/vm/:vmId/update",component:UpdateVmInLabComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lab/:labId/criteria/:crtiteriaId/update",component:UpdateControlCriteriaComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lab/:labId/addVm",component:AddVmToLabComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/labblueprints/:labId/objectives/add",component:AddControlCriteriaComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  
  {path:"lms/categorytrees/course/create",component:AddCategoryTreeComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/categorytrees/course",component:CategoryTreeListComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/categorytrees/course/:treeId/categories",component:EducationCategoryListComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courses",component:CoursesTableComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courses/themes",component:EducationThemesTableComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/course/:courseId/addVersion",component:AddNewVersionComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"lms/courses/section/:sectionId/addContent",component:AddCourseContentComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/section/:sectionId/addContent",component:AddCourseContentComponent,pathMatch:"full",canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/version/:versionId/addSection",component:AddSubsectionToSectionComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/version/:versionId/addSection",component:AddSubsectionToSectionComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/:courseId/translations/:translationId/update",component:UpdateCourseTranslationComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/:courseId/translations/:translationId/details",component:CourseTranslationDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/:courseId/translations/:translationId/delete",component:DeleteCourseTranslationComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/:courseId/translations/create",component:AddCourseTranslationComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/:courseId/translations/list",component:CourseTranslationListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/theme/:themeId/details",component:EducationThemeDetailComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/content/:contentId/details",component:CourseContentDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/content/:contentId/delete",component:DeleteCourseContentComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/themes/:themeId/delete",component:DeleteEducationThemeComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/course/:courseId/delete",component:DeleteEducationComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/content/:contentId/update",component:UpdateEducationContentComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/courses/content/:contentId/update",component:UpdateEducationContentComponent,canActivate:[LoggedGuard]},
  {path:"range/labblueprints/list",component:VirtualLabListComponent,canActivate:[LoggedGuard]},
  {path:"range/hosts",component:VmEnvironmentListComponent,canActivate:[LoggedGuard]},
  {path:"range/vms",component:VirtualMachineListComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/version/:versionId/update",component:UpdateEducationVersionComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/categorytrees/course/:treeId/delete",component:DeleteCategoryTreeComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/installerimages/create",component:CreateInstallerImageComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/create",component:CreateSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/setupinstance/:setupInstanceId/manage",component:ManageSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/notes/:noteId/delete",component:DeleteBaseVmImageNoteComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:vmimgid/notes/create",component:CreateBaseVmImageNoteComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:vmImageId/notes/:noteId/update",component:UpdateBaseVmImageNoteComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:vmImageId/info/update",component:UpdateBaseVmImageInfoComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:vmImageId/info/revisions",component:ListBaseVmImageInfoRevisionsComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/info/revisions/:revisionId/content",component:GetBaseVmImageInfoRevisionComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:baseVmId/info/revisions/:revisionId/restore",component:RestoreBaseVmImageInfoRevisionComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/installerimages/:imageId/update",component:UpdateInstallerImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/installerimages/:imageId/update",component:UpdateInstallerImageComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/installerimages/:imageId/delete",component:DeleteInstallerImageComponent,canActivate:[LoggedGuard]},

  {path:"range/vm/installerimages/:imageId/upload",component:UploadInstallerImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/installerimages/list",component:InstallerImageListComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/installerimages/:imageId/details",component:InstallerImageDetailsComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/basevmimages/create",component:CreateBaseVmImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/basevmimages/:id/upload",component:UploadBaseVmImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/basevmimages/:id/update",component:UpdateBaseVmImageComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/basevmimages/:id/delete",component:DeleteBaseVmImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/basevmimages/:id/details",component:BaseVmImageDetailComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/basevmimages",component:BaseVmImagesTableComponent,canActivate:[LoggedGuard]},

  {path:"vms/activeconnections",component:ActiveConnectionsComponent,canActivate:[LoggedGuard]},
  {path:"connection-manager/sessions",component:VmSessionGroupsListComponent,canActivate:[LoggedGuard]},
  {path:"connection-manager/sessions/:groupName/list",component:VmSessionsListComponent,canActivate:[LoggedGuard]},
  {path:"connection-manager/sessions/:sessionId/vmlist",component:VmSessionConnectionsListComponent,canActivate:[LoggedGuard]},
  {path:"sessions/:lmsSession/terminals",component:GuacamoleLmsSessionTerminalsComponent,canActivate:[LoggedGuard]},
  {path:"connection-manager/connections",component:VmConnectionsListComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/create",component:CreateVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"modal/instancetypes/:typeId/update",component:UpdateVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/:typeId/update",component:UpdateVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/:typeId/delete",component:DeleteVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"modal/instancetypes/:typeId/delete",component:DeleteVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/:typeId/manage",component:ManageVmInstanceTypeComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/:basetypeid/mappings/create",component:CreateVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/mappings/:mappingId/update",component:UpdateVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"modal/instancetypes/mappings/:mappingId/update",component:UpdateVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/mappings/:mappingId/delete",component:DeleteVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"modal/instancetypes/mappings/:mappingId/delete",component:DeleteVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/mappings/:mappingId/manage",component:ManageVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"modal/instancetypes/mappings/:mappingId/notes",component:VmInstanceTypeMappingNoteComponent,canActivate:[LoggedGuard]},
  {path:"instancetypes/:instanceTypeId/mappings",component:ListVmInstanceTypeMappingComponent,canActivate:[LoggedGuard]},
  {path:"range/instancetypes",component:VmInstanceTypeListComponent,canActivate:[LoggedGuard]},
  {path:"labs/hosts/providertypes/create",component:CreateHostProviderTypeComponent,canActivate:[LoggedGuard]},
  {path:"labs/hosts/providertypes/:typeId/update",component:UpdateHostProviderTypeComponent,canActivate:[LoggedGuard]},
  {path:"modal/lab/hosts/providertypes/:typeId/delete",component:DeleteHostProviderTypeComponent,canActivate:[LoggedGuard]},
  {path:"labs/hosts/providertypes/:typeId/manage",component:ManageHostProviderComponent,canActivate:[LoggedGuard]},
  {path:"labs/hosts/providertypes",component:ListHostProviderTypeComponent,canActivate:[LoggedGuard]},
  {path:"range/hosts/:hostId/dashboard",component:HostDashboardComponent,canActivate:[LoggedGuard]},
  {path:"range/setupvminstances/dashboard",component:SetupVmInstanceDashboardComponent,canActivate:[LoggedGuard]},
  {path:"admins",component:AdminManagementComponent,canActivate:[LoggedGuard]},
  {path:"service-accounts",component:ServiceAccountsComponent,canActivate:[LoggedGuard]},
  {path:"service-account/create",component:CreateServiceAccountComponent,canActivate:[LoggedGuard]},
  {path:"modal/service-account/create",component:CreateServiceAccountComponent,canActivate:[LoggedGuard]},
  {path:"modal/service-account/:user/update",component:UpdateServiceAccountComponent,canActivate:[LoggedGuard]},
  {path:"service-account/:userId/details",component:ServiceAccountDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/service-account/:user/changepassword",component:ChangeServiceAccountPasswordComponent,canActivate:[LoggedGuard]},
  {path:"modal/service-account/:user/delete",component:DeleteServiceAccountComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/locale/create",component:AddLocaleComponent,canActivate:[LoggedGuard]},
  {path:"localization/locale/create",component:AddLocaleComponent,canActivate:[LoggedGuard]},
  {path:"localization/locale/list",component:LocalesTableComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/locale/:locale/delete",component:DeleteLocaleComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/locale/:locale/edit",component:UpdateLocaleComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/locale/:locale/details",component:LocaleDetailsComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedstring/create",component:CreateLocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedstring/updatewithkey",component:UpdateLocalizedStringWithKeyComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedstring/create",component:CreateLocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedstring/tablewithkey",component:GetLocalizedStringTableWithKeyComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedstring/:id/update",component:UpdateLocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedstring/:id/update",component:UpdateLocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedstring/list",component:LocalizedStringTableComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedstring/:id/details",component:LocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedstring/:id/delete",component:DeleteLocalizedStringComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedformatstring/create",component:CreateLocalizedFormatStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedformatstring/create",component:CreateLocalizedFormatStringComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedformatstring/:id/update",component:UpdateLocalizedFormatStringComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedformatstring/:id/update",component:UpdateLocalizedFormatStringComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedformatstring/:id/details",component:LocalizedFormatStringDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/localization/localizedformatstring/:id/delete",component:DeleteLocalizedFormatStringComponent,canActivate:[LoggedGuard]},
  {path:"localization/localizedformatstring/list",component:LocalizedFormatStringListComponent,canActivate:[LoggedGuard]},
  {path:"modal/multitenancy/tenant/create",component:CreateTenantComponent,canActivate:[LoggedGuard]},
  {path:"modal/multitenancy/tenant/:id/delete",component:DeleteTenantComponent,canActivate:[LoggedGuard]},
  {path:"modal/multitenancy/tenant/:id/update",component:UpdateTenantComponent,canActivate:[LoggedGuard]},
  {path:"modal/multitenancy/tenant/:id/details",component:GetTenantDetailsComponent,canActivate:[LoggedGuard]},
  {path:"multitenancy/tenant/list",component:GetTenantsTableComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/create",component:CreateMenuComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuId/update",component:UpdateMenuComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/list",component:MenuListTableComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuId/items/add",component:CreateMenuItemComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuId/items/:menuItemId/update",component:UpdateMenuItemComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuId/items/list",component:MenuItemListComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:id/delete",component:DeleteMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:mid/items/:id/delete",component:DeleteMenuItemComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuid/localize/list",component:LocalizedMenuListComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuid/items/:itemid/localize/list",component:LocalizedItemMenuListComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:menuId/localize/:lid/delete",component:DeleteLocalizedMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menuitem/:itemid/localize/:lid/delete",component:DeleteLocalizedItemMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menuitem/:itemid/localize/:lid/update",component:UpdateLocalizedItemMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:menuId/localize/:lid/delete",component:DeleteLocalizedMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:menuId/localize/:localizeId/update",component:UpdateLocalizedMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menu/:menuId/localize/add",component:CreateLocalizedMenuComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menuitem/:itemId/localize/add",component:CreateLocalizedItemMenuComponent,canActivate:[LoggedGuard]},
  {path:"navigation/menu/:menuId/items/:menuitemid/details",component:MenuItemDetailsComponent,canActivate:[LoggedGuard]},
  {path:"modal/navigation/menuitem/:menuitemid/localize/:localizeditemid/details",component:LocalizedItemMenuDetailsComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications/:appId/configurations/create",component:CreateApplicationConfigurationComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications/create",component:CreateApplicationComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications",component:ApplicationListTableComponent,canActivate:[LoggedGuard]},
  {path:"cms/tenants/:tenantId/configurations/create",component:CreateTenantConfigurationComponent,canActivate:[LoggedGuard]},
  {path:"cms/apptenants/:appTenantId/configurations/create",component:CreateApplicationTenantConfigurationComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications/:appId/configurations/images",component:UploadConfigurationImageComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/applications/:appId/delete",component:DeleteApplicationComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/tenants/:tenantId/applications/:appTenantId/delete",component:DeleteApplicationTenantComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications/:appId/update",component:UpdateApplicationComponent,canActivate:[LoggedGuard]},
  {path:"cms/applications/:appId/details",component:ApplicationDetailsComponent,canActivate:[LoggedGuard]},
  {path:"cms/apptenants/:appTenantId/configurations/images",component:UploadConfigurationImageComponent,canActivate:[LoggedGuard]},
  {path:"cms/tenants/:tenantId/configurations/images",component:UploadConfigurationImageComponent,canActivate:[LoggedGuard]},
  {path:"cms/tenants/:tenantId/applications",component:ApplicationTenantListTableComponent,canActivate:[LoggedGuard]},
  {path:"cms/multitenancy/tenant/:tenantId/applications/new",component:CreateApplicationTenantComponent,canActivate:[LoggedGuard]},
  {path:"testtype",component:TestTypedStrComponent},
  {path:"translate-mode",component:SwitchTranslateModeComponent,canActivate:[LoggedGuard]},
  {path:"import-users",component:CreateMultipleUserComponent,canActivate:[LoggedGuard]},
  {path:"acl/service-accounts/roles/create",component:CreateServiceRoleComponent,canActivate:[LoggedGuard]},
  {path:"acl/users/roles/create",component:CreateUserRoleComponent,canActivate:[LoggedGuard]},
  {path:"acl/admins/roles/create",component:CreateAdminRoleComponent,canActivate:[LoggedGuard]},
  
  {path:"acl/service-accounts/roles/:id/update",component:UpdateServiceRoleComponent,canActivate:[LoggedGuard]},
  {path:"acl/users/roles/:id/update",component:UpdateUserRoleComponent,canActivate:[LoggedGuard]},
  {path:"acl/admins/roles/:id/update",component:UpdateAdminRoleComponent,canActivate:[LoggedGuard]},

  {path:"acl/service-accounts/roles/:id/details",component:ServiceRoleDetailsComponent,canActivate:[LoggedGuard]},
  {path:"acl/users/roles/:id/details",component:UserRoleDetailsComponent,canActivate:[LoggedGuard]},
  {path:"acl/admins/roles/:id/details",component:AdminRoleDetailsComponent,canActivate:[LoggedGuard]},

  {path:"modal/acl/service-accounts/roles/:id/delete",component:DeleteServiceRoleComponent,canActivate:[LoggedGuard]},
  {path:"modal/acl/users/roles/:id/delete",component:DeleteUserRoleComponent,canActivate:[LoggedGuard]},
  {path:"modal/acl/admins/roles/:id/delete",component:DeleteAdminRoleComponent,canActivate:[LoggedGuard]},

  {path:"acl/service-accounts/roles",component:ListServiceRolesComponent,canActivate:[LoggedGuard]},
  {path:"acl/users/roles",component:ListUserRolesComponent,canActivate:[LoggedGuard]},
  {path:"acl/admins/roles",component:ListAdminRolesComponent,canActivate:[LoggedGuard]},

  {path:"acl/admins/:userId/addrole",component:AddRoleToAdminComponent,canActivate:[LoggedGuard]},
  {path:"acl/users/:userId/addrole",component:AddRoleToUserComponent,canActivate:[LoggedGuard]},
  {path:"acl/service-accounts/:userId/addrole",component:AddRoleToServiceComponent,canActivate:[LoggedGuard]},

  {path:"acl/users/roles/:roleId/addusers",component:AddSelectedRoleToUsersComponent,canActivate:[LoggedGuard]},
  {path:"modal/acl/users/roles/:roleId/addusers",component:AddSelectedRoleToUsersComponent,canActivate:[LoggedGuard]},

  {path:"modal/acl/user/:userId/roles",component:GetAssignedUserRolesComponent,canActivate:[LoggedGuard]},
  {path:"modal/acl/admin/:userId/roles",component:GetAssignedAdminRolesComponent,canActivate:[LoggedGuard]},
  {path:"modal/acl/service-account/:userId/roles",component:GetAssignedServiceRolesComponent,canActivate:[LoggedGuard]},

  {path:"user/:userId/roles/manage",component:GetAssignedUserRolesComponent,canActivate:[LoggedGuard]},
  {path:"admin/:userId/roles/manage",component:GetAssignedAdminRolesComponent,canActivate:[LoggedGuard]},
  {path:"service/:userId/roles/manage",component:GetAssignedServiceRolesComponent,canActivate:[LoggedGuard]},

  {path:"modal/service/:serviceId/roles/:roleId/delete",component:DeleteRoleFromUserComponent,canActivate:[LoggedGuard]},
  {path:"modal/user/:userId/roles/:roleId/delete",component:DeleteRoleFromUserComponent,canActivate:[LoggedGuard]},
  {path:"modal/admin/:adminId/roles/:roleId/delete",component:DeleteRoleFromUserComponent,canActivate:[LoggedGuard]},

  {path:"video-timeline",component:VideoTimelineComponent,canActivate:[LoggedGuard]},
  {path:"video-player",component:LmsVideoPlayerComponent},

  {path:"lms/media-content/create",component:CreateMediaContentComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/media-content/create",component:CreateMediaContentComponent,canActivate:[LoggedGuard]},
  {path:"lms/media-content/:id/upload",component:UploadMediaContentComponent,canActivate:[LoggedGuard]},
  {path:"lms/media-content/list",component:MediaContentListComponent,canActivate:[LoggedGuard]},
  {path:"lms/media-content/:mediaId/update",component:UpdateMediaContentComponent,canActivate:[LoggedGuard]},
  {path:"modal/lms/media-content/:id/delete",component:DeleteMediaContentComponent,canActivate:[LoggedGuard]},
  {path:"lms/media-content/:id/details",component:MediaContentDetailsComponent,canActivate:[LoggedGuard]},

  {path:"full-page/outline-editor/:version",component:OutlineManagementPanelComponent,canActivate:[LoggedGuard]},
  {path:"full-page/outline-editor/media-item/:mediaId/view",component:CmsMediaViewerComponent,canActivate:[LoggedGuard]},

  {path:"lms/courses/seats/create",component:CreateCourseSeatComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/seats/:seatId/update",component:UpdateCourseSeatComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/seats/list",component:ListCourseSeatsComponent,canActivate:[LoggedGuard]},
  {path:"lms/courses/seats/:seatId/access/:type/add",component:AddSeatAccessComponent,canActivate:[LoggedGuard]},

  {path:"range/vm/removableimages/create",component:CreateRemovableImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/removableimages/:id/upload",component:UploadRemovableImageComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/removableimages/:id/delete",component:DeleteRemovableImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/removableimages/:id/update",component:UpdateRemovableImageComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/removableimages/:id/details",component:RemovableImageDetailsComponent,canActivate:[LoggedGuard]},
  {path:"range/vm/removableimages/list",component:ListRemovableImagesComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/clone",component:CloneSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/publish",component:PublishSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/updatespecs",component:SetupVmInstanceUpdateSpecsComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/disks/add",component:AddDiskToSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/nics/add",component:AddNicToSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/disks/:diskId/update",component:UpdateDiskFromSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/nics/:nicId/update",component:UpdateNicFromSetupVmInstanceComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/vm/setupinstance/:vmId/removableimages/add",component:AddRemovableImageToSetupVmInstanceComponent,canActivate:[LoggedGuard]},

  {path:"modal/cms/iconsets/create",component:CreateIconSetComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/iconsets/:setId/update",component:UpdateIconSetComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/iconsets/:setId/icons/create",component:CreateIconComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/iconsets/icons/:id/update",component:UpdateIconComponent,canActivate:[LoggedGuard]},
  {path:"cms/iconsets/grid",component:IconSetListComponent,canActivate:[LoggedGuard]},
  {path:"cms/iconsets/:id/icons/grid",component:IconSetIconListComponent,canActivate:[LoggedGuard]},
  {path:"modal/cms/iconsets/:id/delete",component:DeleteIconSetComponent,canActivate:[LoggedGuard]},
  {path:"cms/iconsets/icons/:id/details",component:IconDetailsComponent,canActivate:[LoggedGuard]},
  {path:"cms/iconsets/:id/details",component:IconSetDetailsComponent,canActivate:[LoggedGuard]},
  {path:"lab/network",component:LabNetworkGraphComponent,canActivate:[LoggedGuard]},
  {path:"range/labs",component:LabDashboardComponent,canActivate:[LoggedGuard]},
  {path:"range/labs/create",component:CreateSetupLabComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/labs/create",component:CreateSetupLabComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/labs/blueprints/:blueprintId/clone",component:CloneLabBlueprintComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/labs/drafts/:draftId/clone",component:CloneLabDraftComponent,canActivate:[LoggedGuard]},
  {path:"range/labs/drafts/:draftId/workbench",component:LabWorkbenchComponent,canActivate:[LoggedGuard]},
  {path:"range/labs/blueprints/shared/:sharedId/manage",component:LabWorkbenchComponent,canActivate:[LoggedGuard]},
  {path:"range/labs/blueprints/my/:mylabId/manage",component:LabWorkbenchComponent,canActivate:[LoggedGuard]},
  {path:"modal/range/setupvminstances/connect/:jwt/:vmName",component:ManageBaseVmImageRemoteConnectionComponent,canActivate:[LoggedGuard]}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
