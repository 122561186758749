import { RegionLayoutModel } from './../../models/Layout/RegionLayoutModel';
import { CustomLayoutRegionComponent } from './custom-layout-region/custom-layout-region.component';
import { CustomLayoutBlockComponent } from './custom-layout-block/custom-layout-block.component';
import { LayoutService } from './../../services/layout/layout.service';
import { LayoutModel } from './../../models/Layout/LayoutModel';
import { Component, ElementRef, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { BlockLayoutModel } from '../../models/Layout/BlockLayoutModel';

@Component({
  selector: 'lib-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.css']
})
export class CustomLayoutComponent implements OnInit {
  layoutDivElement:HTMLDivElement;
  constructor(private layoutService:LayoutService,public viewContainerRef:ViewContainerRef) { }
  layoutModel:LayoutModel;
  preDefinedLayouts:Map<string,string> = new Map<string,string>();
  @Input() layoutName:string;
  layoutRegions:RegionLayoutModel[] = [];
  layoutBlocks:BlockLayoutModel[] = [];
  layoutBlocksWithRegions:Map<RegionLayoutModel,BlockLayoutModel[]> = new Map<RegionLayoutModel,BlockLayoutModel[]>();
  layoutBlocksWithParentBlocks:Map<BlockLayoutModel,BlockLayoutModel[]> = new Map<BlockLayoutModel,BlockLayoutModel[]>();
  ngOnInit(): void {
    this.preDefinedLayouts.set("1region",'<div><div regionName="region1"></div></div>');
    this.preDefinedLayouts.set("3region",'<div class="container"><div class="row"><div class="col-md-6"><div regionName="region1"></div></div><div class="col-md-6"><div regionName="region2"></div></div></div><div class="row"><div class="col"><div regionName="region3"></div></div></div></div>')
    this.preDefinedLayouts.set("menuRegion",'<div class="row"><div class="col-md-3"><div regionName="menu1" style="position:relative;"></div></div><div class="col-md-9"><div class="row"><div class="col-md-12"><div regionName="region1"></div></div></div></div></div>')
    this.preDefinedLayouts.set("pregion",'<div class="row"><div class="col-md-2"><div class="row"><div class="col-12"><div regionName="menu1" style="position:relative;"></div></div></div></div><div class="col-md-10"><div class="row"><div class="col-12"><div regionName="part1"></div></div></div><div class="row"><div class="col-md-6"><div regionName="part2"></div></div><div class="col-md-6"><div regionName="part3"></div></div></div></div></div>')
    this.layoutDivElement = document.getElementById("layoutElement") as HTMLDivElement;

    this.layoutService.getLayout(this.layoutName).subscribe({
      next:(response)=>{
        this.layoutModel = response;
        this.layoutRegions = response.regions;
        this.layoutBlocks = response.blocks;


        /* Regiona ait olan blokların atanması*/
        this.layoutRegions.forEach(region=>{
          let blockList:BlockLayoutModel[] = [];
          this.layoutBlocks.forEach(block=>{
            if(block.region == region.id){
              blockList.push(block);
            }
          })
          blockList = blockList.sort((a,b)=>a.displayOrder - b.displayOrder);
          console.log(blockList);
          this.layoutBlocksWithRegions.set(region,blockList);
          /*********************************** */
          /* Child blokların atanması*/
          this.layoutBlocks.forEach(parentBlock=>{
            let childBlocks:BlockLayoutModel[] = [];
            this.layoutBlocks.forEach(childBlock=>{
              if(childBlock.parent == parentBlock.id){
                childBlocks.push(childBlock);
              }
            })
            if(childBlocks.length > 0){
              childBlocks = childBlocks.sort((a,b)=>a.displayOrder - b.displayOrder);
              this.layoutBlocksWithParentBlocks.set(parentBlock,childBlocks);
            }
          })
          /*********************************** */
        })
        this.createComponent();
      },
      error:(err)=>{
        alert("Hataa");
      }
    })
  }
  createComponent(){
    this.layoutDivElement.innerHTML =  this.preDefinedLayouts.get(this.layoutModel.layoutType) ?? '<div></div>';
    let layoutElements = this.layoutDivElement.getElementsByTagName("*");
    this.layoutRegions.forEach(region=>{
      var createdRegion = this.createRegion(region);
      for(let i=0;i<layoutElements.length;i++){
        if(layoutElements[i].getAttribute("regionname") == region.id){
          layoutElements[i].appendChild(createdRegion);
        }
      }
    })
  }
  createRegion(regionLayout:RegionLayoutModel){
    var createdDiv = document.createElement("div");
    let attributes = regionLayout.attributes;
    var attrKeys = Object.keys(attributes);
    attrKeys.forEach(attribute=>{
      createdDiv.setAttribute(attribute,attributes[attribute]);
    })
    var blocks = this.layoutBlocksWithRegions.get(regionLayout);
    blocks?.forEach(selectedBlock=>{
      createdDiv.appendChild(this.createBlock(selectedBlock));
    })
    console.log(createdDiv.outerHTML);
    return createdDiv;
  }
  createBlock(blockLayout:BlockLayoutModel){
    console.log("block params");
    console.log(blockLayout.params)
    var createdComponent = this.viewContainerRef.createComponent(CustomLayoutBlockComponent);
    createdComponent.instance.layoutBlock = blockLayout;
    let nativeElement = createdComponent.location.nativeElement as HTMLElement;
    let attributes = blockLayout.attributes;
    var attrkeys = Object.keys(attributes);
    attrkeys.forEach(attribute=>{
      console.log(attribute + " " + attributes[attribute]);
      nativeElement.setAttribute(attribute,attributes[attribute])
    })
    if(this.layoutBlocksWithParentBlocks.has(blockLayout)){
      let childs = this.layoutBlocksWithParentBlocks.get(blockLayout);
      childs?.forEach(child=>{
        let childElement = this.createBlock(child);
        if(blockLayout.contentAfterChildren == true){
          nativeElement.prepend(childElement);

        }else{
          nativeElement.appendChild(childElement);
        }

      })
    }
    return nativeElement;

  }

}
