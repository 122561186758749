import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-list-removable-images',
  templateUrl: './list-removable-images.component.html',
  styleUrls: ['./list-removable-images.component.css']
})
export class ListRemovableImagesComponent implements OnInit {
  tableUrl:string = environment.apiUrl + "api/range/vm/removableimages/list"
  constructor() { }

  ngOnInit(): void {

  }

}
