import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { RoleDetailsModel } from '../../models/AccessControl/roleDetailsModel';
import { ResponseModel } from '../../models/responseModel';
import { CustomTableMainModel } from '../../models/CustomTableModels/customTableMainModel';
import { AddRoleToUserRequestModel } from '../../models/AccessControl/addRoleToUserRequestModel';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { RemoveRoleFromUserRequestModel } from '../../models/AccessControl/removeRoleFromUser';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  constructor(private httpClient:HttpClient) { }
  deleteUserRole(id:string){
    var payload = {
      id:id
    }
    var apiUrl:string = environment.apiUrl + "api/acl/user/roles/"+id+"/delete";
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:payload});
  }
  deleteAdminRole(id:string){
    var payload = {
      id:id
    }
    var apiUrl:string = environment.apiUrl + "api/acl/admin/roles/"+id+"/delete";
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:payload});
  }
  deleteServiceAccountRole(id:string){
    var payload = {
      id:id
    }
    var apiUrl:string = environment.apiUrl + "api/acl/service/roles/"+id+"/delete";
    return this.httpClient.delete<ResponseModel>(apiUrl,{body:payload});
  }
  userRoleDetails(id:string){
    var apiUrl = environment.apiUrl + "api/acl/user/roles/"+id+"/details"
    return this.httpClient.get<SingleResponseModel<RoleDetailsModel>>(apiUrl);
  }
  adminRoleDetails(id:string){
    var apiUrl = environment.apiUrl + "api/acl/admin/roles/"+id+"/details"
    return this.httpClient.get<SingleResponseModel<RoleDetailsModel>>(apiUrl);
  }
  serviceAccountRoleDetails(id:string){
    var apiUrl = environment.apiUrl + "api/acl/service/roles/"+id+"/details"
    return this.httpClient.get<SingleResponseModel<RoleDetailsModel>>(apiUrl);
  }
  getAdminRoles(){
    var apiUrl = environment.apiUrl + "api/acl/admin/roles";
    return this.httpClient.get<SingleResponseModel<CustomTableMainModel>>(apiUrl);
  }
  getServiceRoles(){
    var apiUrl = environment.apiUrl + "api/acl/service/roles";
    return this.httpClient.get<SingleResponseModel<CustomTableMainModel>>(apiUrl);
  }
  getUserRoles(){
    var apiUrl = environment.apiUrl + "api/acl/user/roles";
    return this.httpClient.get<SingleResponseModel<CustomTableMainModel>>(apiUrl);
  }
  addRoleToUser(addRoleToUserRequest:AddRoleToUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/user/"+addRoleToUserRequest.userId+"/roles/"+addRoleToUserRequest.roleId+"/add";
    return this.httpClient.post<ResponseModel>(apiUrl,addRoleToUserRequest);
  }
  addRoleToAdmin(addRoleToUserRequest:AddRoleToUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/admin/"+addRoleToUserRequest.userId+"/roles/"+addRoleToUserRequest.roleId+"/add";
    return this.httpClient.post<ResponseModel>(apiUrl,addRoleToUserRequest);
  }
  addRoleToService(addRoleToUserRequest:AddRoleToUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/service/"+addRoleToUserRequest.userId+"/roles/"+addRoleToUserRequest.roleId+"/add";
    return this.httpClient.post<ResponseModel>(apiUrl,addRoleToUserRequest);
  }
  getAssignedUserRoles(userId:string){
    var apiUrl = environment.apiUrl + "api/acl/user/"+userId+"/roles"
    return this.httpClient.get<ListResponseModel<RoleDetailsModel>>(apiUrl);
  }
  getAssignedAdminRoles(userId:string){
    var apiUrl = environment.apiUrl + "api/acl/admin/"+userId+"/roles"
    return this.httpClient.get<ListResponseModel<RoleDetailsModel>>(apiUrl);
  }
  getAssignedServiceRoles(userId:string){
    var apiUrl = environment.apiUrl + "api/acl/service/"+userId+"/roles"
    return this.httpClient.get<ListResponseModel<RoleDetailsModel>>(apiUrl);
  }
  removeRoleFromUserAccount(req:RemoveRoleFromUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/user/"+req.userId+"/roles/"+req.roleId+"/remove";
    return this.httpClient.post<ResponseModel>(apiUrl,req);
  }
  removeRoleFromAdminAccount(req:RemoveRoleFromUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/admin/"+req.userId+"/roles/"+req.roleId+"/remove";
    return this.httpClient.post<ResponseModel>(apiUrl,req);
  }
  removeRoleFromServiceAccount(req:RemoveRoleFromUserRequestModel){
    var apiUrl = environment.apiUrl + "api/acl/service/"+req.userId+"/roles/"+req.roleId+"/remove";
    return this.httpClient.post<ResponseModel>(apiUrl,req);
  }
}
