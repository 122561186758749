import { Component, Input, OnInit } from '@angular/core';
import { HostDashboardCounterModel } from '../../../models/DashboardModels/hostDashboardCounterModel';
declare var bootstrap:any;
function popoverstart(){
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    console.log(popoverTriggerList);
    return new bootstrap.Popover(popoverTriggerEl)

  })
}
@Component({
  selector: 'lib-host-dashboard-counter',
  templateUrl: './host-dashboard-counter.component.html',
  styleUrls: ['./host-dashboard-counter.component.css']
})
export class HostDashboardCounterComponent implements OnInit {
  @Input() hostDashobardCounter:HostDashboardCounterModel;
  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      popoverstart();
    },500)

  }
  getValue(){
    if(!this.hostDashobardCounter.isDecimal){
      return Math.round(this.hostDashobardCounter.value);
    }
    return this.hostDashobardCounter.value;
  }

}
