<form class="formMain form-default-border form-maroon-border" *ngIf="parentsLoaded && loadData" [formGroup]="updateQuestionSetForm">
  <div class="form-title-div">
    <font class="form-title-content">{{'questionSet.updatePageTitle' | translate}}</font>
</div>
  <div class="mb-3">
    <label for="name" class="form-label mainLabel">{{'questionSet.name' | translate}}</label>
    <input type="text" class="form-control" id="name" name="name" formControlName="name">
  </div>
  <div class="mb-3">
    <label for="file" class="form-label mainLabel">{{'questionSet.selectImage' | translate}}<br/>
      <input type="file" name="file" id="file" (change)="setImage($event)">
    </label>

    <image-cropper [imageChangedEvent]="imageChangedEvent" [autoCrop]="true" (imageCropped)="croppedImage($event)" [output]="'base64'" [maintainAspectRatio]="false" ></image-cropper>
  </div>
  <div *ngIf="questionSet.imageId != null" class="mb-3">
    <h5>{{'questionSet.currentImage' | translate}}</h5>
    <img [src]="cmsImageService.getUrl(questionSet.imageId)">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="deleteCurrentImage" name="deleteCurrentImage" formControlName="deleteCurrentImage">
      <label class="form-check-label mainLabel" for="deleteCurrentImage">
        {{'questionSet.deleteCurrentImage' | translate}}
      </label>
    </div>
  </div>

  <div class="mb-3">
    <input type="hidden" class="form-control" id="image" name="image" formControlName="image">
  </div>
  <div class="form-check mb-3">
    <input class="form-check-input" type="checkbox" value="" id="enabled" name="enabled" formControlName="enabled">
    <label class="form-check-label mainLabel" for="enabled">
      {{'questionSet.enabled' | translate}}
    </label>
  </div>
  <div class="mb-3">
    <label for="color" class="form-label mainLabel" style="vertical-align: middle;">{{'questionSet.color' | translate}}</label>
    <input type="color" style="vertical-align: sub; margin-left: 6px;" id="color" name="color" formControlName="color">
  </div>
  <div class="mb-3">
    <label for="parentId" class="form-label mainLabel">{{'questionSet.parent' | translate}}</label>
    <select class="form-control" id="parentId" name="parentId" formControlName="parentId">
      <option [value]="null">Not Selected</option>
      <option *ngFor="let parent of parents" [value]="parent.value">{{parent.label}}</option>
    </select>
  </div>



<div style="text-align: right;">
  <button (click)="updateQuestionSet()" [disabled]="sending" class="btn btn-primary btn-sm" style="float: right;">{{'btn.update' | translate}}</button>
</div>
<br/>
</form>

