import { Component, Input, OnInit } from '@angular/core';
import { ShowNoteModel } from '../../../models/LearnModels/Notes/showNoteModel';
import { NotesService } from '../../../services/notes/notes.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateNoteModel } from '../../../models/LearnModels/Notes/updateNoteModel';
import { CreateNoteModel } from '../../../models/LearnModels/Notes/createNoteModel';
declare var $:any;
@Component({
  selector: 'lib-note-edit',
  templateUrl: './note-edit.component.html',
  styleUrls: ['./note-edit.component.css']
})
export class NoteEditComponent implements OnInit {
  editMode:boolean = false;
  noteEditId?:string = undefined;
  @Input() notes:ShowNoteModel[];
  @Input() courseId:string = ""
  @Input() noteWindow:Window;
  @Input() contentId:string; //this.learnContent.currentContent.id bu parametre geçilecek
  constructor(private noteService:NotesService,private toastrService:ToastrService) { }

  ngOnInit(): void {
  }
  
  createNote(){
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let contentId = this.contentId;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let createNoteModel:CreateNoteModel = {contentId:contentId,noteContent:noteContent,title:noteTitle};
    console.log(createNoteModel);
    this.noteService.createNote(createNoteModel,this.courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        }else{
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  saveNote(){
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let noteId = this.noteEditId ?? "";
    if(noteId == ""){
      this.toastrService.error("Not güncellenemedi");
      return;
    };
    let updateNoteModel:UpdateNoteModel = {title:noteTitle,noteContent:noteContent,id:noteId};
    console.log(updateNoteModel);
    this.noteService.updateNote(updateNoteModel,this.courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        }else{
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
    this.destroySummerNote();
  }
  readHtmlFromSummernote(){
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let code = $(editNoteContent).summernote('code') as string;
    console.log(code);
  }
  private destroySummerNote(){
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote('destroy');
    this.editMode = false;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
  }
  editNote(note:ShowNoteModel){
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = note.noteContent;
    editNoteTitle.value = note.title ?? "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({height:200});
    this.noteEditId = note.id;
  }

  newNote(){
    this.noteEditId = undefined;
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({height:200});
  }

  deleteNote(courseId:string,noteId:string){
    this.noteService.deleteNote(noteId,courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla silindi");
        }else{
          this.toastrService.error("Not silinemedi");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  cancelNote(){
    this.noteEditId = undefined;
    this.destroySummerNote();
  }

}
