import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-list-user-roles',
  templateUrl: './list-user-roles.component.html',
  styleUrls: ['./list-user-roles.component.css']
})
export class ListUserRolesComponent implements OnInit {
  tableUrl:string
  constructor() { }

  ngOnInit(): void {
    this.tableUrl = environment.apiUrl + "api/acl/user/roles"
  }

}
