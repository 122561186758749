import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-education-category-list',
  templateUrl: './education-category-list.component.html',
  styleUrls: ['./education-category-list.component.css']
})
export class EducationCategoryListComponent implements OnInit {
  apiUrl:string;
  categoryTreeId:number;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.categoryTreeId = parseInt(param["treeId"]);
        this.apiUrl = environment.apiUrl;
      }
    })
  }

}
