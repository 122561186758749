<h5>Seçenek Ekle</h5>
<hr>
<form *ngIf="questionOption" [formGroup]="optionForm">
    <input type="hidden" id="id" name="id" formControlName="id">
    <div class="mb-3">
        <label for="value" class="form-label">Yanıtın Değer Karşılığı (Backend)</label>
        <input type="text" class="form-control" id="value" name="value" formControlName="value">
    </div>
    <div class="mb-3">
        <label for="label" class="form-label">Yanıt</label>
        <input type="hidden" class="form-control" id="label" name="label" formControlName="label"/>
        <lib-typed-strings (getValue)="getOptionTextFnc($event)" [inputType]="optionTextFormat" [defaultValue]="optionTextDefault"></lib-typed-strings>
    </div>

    <div class="mb-3">
        <label for="format" class="form-label">Format</label>
        <select (change)="changeOptionFormat($event)" class="form-select" id="format" name="format" formControlName="format">
            <option [value]="0">PlainText</option>
            <option [value]="1">HTML</option>
            <option [value]="2">Markdown</option>
            <option [value]="3">Json</option>
            <option [value]="4">Xml</option>
            <option [value]="5">Layout</option>
        </select>
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="active" name="active" formControlName="active">
            <label class="form-check-label" for="active">
                Aktif
            </label>
        </div>
    </div>
    <div class="mb-3">
        <label for="optionPrefix" class="form-label">Ön Yazı (Prefix)</label>
        <input type="text" class="form-control" id="optionPrefix" name="optionPrefix" formControlName="optionPrefix">
    </div>

    <div class="mb-3">
        <label for="orderValue" class="form-label">Sıralama Değeri</label>
        <input type="number" class="form-control" id="orderValue" name="orderValue" formControlName="orderValue">
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="isCorrect" name="isCorrect" formControlName="isCorrect">
            <label class="form-check-label" for="isCorrect">
                Doğru Yanıt
            </label>
        </div>
    </div>
    <div class="mb-3">
        <label for="orderValue" class="form-label">Skor (0-100)</label>
        <input type="number" class="form-control" [min]="0" [max]="100" id="scoreFactor" name="scoreFactor"
            formControlName="scoreFactor">
    </div>

    <div class="mb-3" *ngIf="wrongAnswerTextFormat">
        <label for="wrongAnswerInfo" class="form-label">Yanlış Cevap Açıklama Metni</label>
        <input type="hidden" class="form-control" id="wrongAnswerInfo" formControlName="wrongAnswerInfo" placeholder="">
        <lib-typed-strings (getValue)="getWrongAnswerInfoTextFnc($event)" [inputType]="wrongAnswerTextFormat" [defaultValue]="wrongAnswerTextDefault"></lib-typed-strings>
    </div>

    <div class="mb-3">
        <label for="wrongAnswerInfoFormat" class="form-label">Format</label>
        <select (change)="changeWrongAnswerInfoFormat($event)" class="form-select" id="wrongAnswerInfoFormat" name="wrongAnswerInfoFormat"
            formControlName="wrongAnswerInfoFormat">
            <option [value]="0">PlainText</option>
            <option [value]="1">HTML</option>
            <option [value]="2">Markdown</option>
            <option [value]="3">Json</option>
            <option [value]="4">Xml</option>
            <option [value]="5">Layout</option>
        </select>
    </div>

</form>
<button class="btn btn-primary" (click)="updateOption()">{{'btn.update' | translate}}</button>