import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DynamicFormToJsonElementTypesModel } from 'projects/usorta-library/src/lib/models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { AddControlCriteriaModel } from 'projects/usorta-library/src/lib/models/VirtualLabModels/addControlCriteriaModel';
import { VirtualLabService } from 'projects/usorta-library/src/lib/services/lab/virtual-lab.service';
declare var $: any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-add-control-criteria',
  templateUrl: './add-control-criteria.component.html',
  styleUrls: ['./add-control-criteria.component.css']
})

/*
Krayir servisinde model adı LabObjectives olarak tanımlanmıştır.
*/
export class AddControlCriteriaComponent implements OnInit {
  criteriaList: DynamicFormToJsonElementTypesModel[] = [];
  addParam: DynamicFormToJsonElementTypesModel[] = [];
  resetForm: boolean[] = [false];
  controlCriteriaForm:FormGroup;
  params:string = "";
  instructions:string = "";
  selectedLang:number = 0;
  labId:string;
  constructor(private activatedRoute:ActivatedRoute, private formBuilder:FormBuilder,private toastrService:ToastrService,private virtualLabService:VirtualLabService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.labId = param["labId"];
        this.createCriteriaList();
        this.createAddControlCriteriaForm();
        this.changeLang();
      }
    })

  }
  createAddControlCriteriaForm(){
    this.controlCriteriaForm = this.formBuilder.group({
      title:["",Validators.required],
      summary:[""],
      instructionsFormat:[0],
      orderValue:[0],
      type:[null,Validators.required]
    })
  }
  initializeMonaco(){
    if(window["criteriaEditor"] != undefined){
      return;
    }
    setTimeout(()=>{
      var lang = "plaintext"
      if(this.selectedLang == 1){
        lang = "html"
      }else if(this.selectedLang == 2){
        lang = "markdown";
      }
      var editorDiv = document.getElementsByClassName("controlMonaco")[0] as HTMLDivElement;
      var editor = monaco.editor.create(editorDiv,{
        value: "",
        language: lang,
        theme: 'vs-light',
      })
      window["criteriaEditor"] = editor;
    },500)

  }
  changeLang(){
    this.selectedLang = this.controlCriteriaForm.get("instructionsFormat")?.value;
    if(window["criteriaEditor"] !== undefined){
      console.log("lang changed");
      var lang = "plaintext"
      if(this.selectedLang == 1){
        lang = "html"
      }else if(this.selectedLang == 2){
        lang = "markdown";
      }
      monaco.editor.setModelLanguage(window["criteriaEditor"].getModel(), lang);
    }

  }
  onParamsChange(ev:string){
    var p = ev;
    var js = JSON.parse(p);
    if(js != undefined || js != ""){
      this.params = js[0];
    }else{
      this.params = "";
    }
  }
  saveInstructions(){
    var editor = window["criteriaEditor"]
    if(editor !== undefined){
      this.instructions = editor.getValue();
    }
  }
  changeType(ev: any) {
    this.resetForm = [true];
    this.addParam = [];
    setTimeout(()=>{
      var type = $(ev.target).val();
      switch (type) {
        case "textProof":
  
          this.textProofControl();
          break;
        case "createFile":
  
          this.createFileControl()
          break;
        case "fileContents":
  
          this.fileContentsControl();
          break;
        case "userLogin":
  
          this.userLoginControl();
  
          break;
      }
    },50)

  }
  textProofControl() {
    this.addParam = [
      {
        typeName: "textProof",
        typeText: "Text Proof",
        element: [
          { label: "Değer", name: "value", valueType: "string" },
          { label: "Regex", name: "isRegex", valueType: "boolean" }
        ]
      }
    ]
  }
  createFileControl() {
    this.addParam = [
      {
        typeName: "createFile",
        typeText: "Dosya Oluşturma Kontrolü",
        element: [
          { label: "Dosya Konumu", name: "filePath", valueType: "string" },
          { label: "Hedef VM", name: "targetVM", valueType: "string" }
        ]
      }
    ]
  }
  fileContentsControl() {
    this.addParam = [
      {
        typeName: "fileContents",
        typeText: "Dosya İçerik Kontrolü",
        element: [
          { label: "Dosya Konumu", name: "filePath", valueType: "string" },
          { label: "Hedef VM", name: "targetVM", valueType: "string" },
          { label: "Değer", name: "value", valueType: "string" },
          {
            label: "Kontrol Modu", name: "checkMode", valueType: "select", subItems: [
              {
                label: "Exact",
                selected: false,
                value: "exact"
              },
              {
                label: "Contains",
                selected: true,
                value: "contains"
              },
              {
                label: "Regex",
                selected: false,
                value: "regex"
              }
            ]
          }
        ]
      }
    ]
  }
  userLoginControl() {
    this.addParam = [
      {
        typeName: "userLogin",
        typeText: "Belirtilen Kullanıcı İle Giriş Kontrolü",
        element: [
          { label: "Hedef VM", name: "targetVM", valueType: "string" },
          { label: "Kullanıcı", name: "user", valueType: "string" }

        ]
      }
    ]
  }
  createCriteriaList() {
    this.criteriaList.push({
      typeName: "textProof",
      typeText: "Text Proof",
      element: [
        { label: "Değer", name: "value", valueType: "string" },
        { label: "Regex", name: "isRegex", valueType: "boolean" }
      ]
    })
    this.criteriaList.push({
      typeName: "createFile",
      typeText: "Dosya Oluşturma Kontrolü",
      element: [
        { label: "Dosya Konumu", name: "filePath", valueType: "string" },
        { label: "Hedef VM", name: "targetVM", valueType: "string" }
      ]
    })
    this.criteriaList.push({
      typeName: "fileContents",
      typeText: "Dosya İçerik Kontrolü",
      element: [
        { label: "Dosya Konumu", name: "filePath", valueType: "string" },
        { label: "Hedef VM", name: "targetVM", valueType: "string" },
        { label: "Değer", name: "value", valueType: "string" },
        {
          label: "Kontrol Modu", name: "checkMode", valueType: "select", subItems: [
            {
              label: "Exact",
              selected: false,
              value: "exact"
            },
            {
              label: "Contains",
              selected: true,
              value: "contains"
            },
            {
              label: "Regex",
              selected: false,
              value: "regex"
            }
          ]
        }
      ]
    })
    this.criteriaList.push({
      typeName: "userLogin",
      typeText: "Belirtilen Kullanıcı İle Giriş Kontrolü",
      element: [
        { label: "Hedef VM", name: "targetVM", valueType: "string" },
        { label: "Kullanıcı", name: "user", valueType: "string" }

      ]
    })
  }
  addControlCriteria(){
    if(this.controlCriteriaForm.valid){
      var ccfModel:AddControlCriteriaModel = Object.assign({},this.controlCriteriaForm.value);
      ccfModel.params = this.params;
      ccfModel.instructions = this.instructions;
      console.log(ccfModel);
      ccfModel.labId = this.labId;
      this.virtualLabService.addLabObjective(this.labId,ccfModel).subscribe({
        next:(response)=>{
          this.toastrService.success("Denetim Kriteri Eklendi");
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }
}
