<h5></h5>
<hr>
<form class="formMain form-default-border form-maroon-border" *ngIf="providers">
    <div class="form-title-div">
        <font class="form-title-content">{{'virtualLab.defineNewLab' | translate}}</font>
    </div>
    <div class="mb-3">
        <label for="name" class="form-label mainLabel">{{'virtualLab.labName' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off"  id="name" placeholder="">
      </div>
    <div class="mb-3">
        <label for="code" class="form-label mainLabel">{{'virtualLab.code' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off"  id="code" placeholder="">
      </div>

    <div class="mb-3">
        <label for="tags" class="form-label mainLabel">{{'virtualLab.tags' | translate}}</label>
        <input type="text" class="form-control" autocomplete="off" id="tags"  placeholder="">
    </div>
    <div class="mb-3">
        <label for="descriptionFormat" class="form-label mainLabel">{{'global.format' | translate}}</label>
        <select (change)="changeDescriptionFormat($event)" class="form-select" id="descriptionFormat" name="descriptionFormat">
            <option [value]="0">PlainText</option>
            <option [value]="1">HTML</option>
            <option [value]="2">Markdown</option>
            <option [value]="3">Json</option>
            <option [value]="4">Xml</option>
            <option [value]="5">Layout</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="description" class="form-label mainLabel">{{'global.description' | translate}}</label>
        <input type="hidden" class="form-control" id="description" name="description"/>
        <lib-typed-strings (getValue)="descriptionValueFnc($event)" [defaultType]="0" [inputType]="inputFormats"></lib-typed-strings>
    </div>
    <lib-dynamic-form-to-json [showDeleteBtn]="true" (isValid)="getValidation($event)" [autoSave]="true" (dataChanges)="saveNetwork($event)" [formElements]="networkSelect"></lib-dynamic-form-to-json>
    <br/>
    <div style="text-align: right;">
        <button [disabled]="loading == true"  class="btn btn-primary btn-sm" (click)="createData()">{{'btn.add' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
    </div>

</form>
