import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.css']
})
export class CreateTenantComponent implements OnInit {

  formUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.formUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/multitenancy/tenant/create"
  }

}
