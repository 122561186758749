<lib-custom-form [formBottomElement]="formBottomInput" *ngIf="updateForm" [externalFormData]="updateForm" [getFormInput]="requestForm" (GetSendingObjects)="updateSession($event)" (GetFormGroup)="getFormGroup($event)"></lib-custom-form>
<div id="updateSessionDiv">
    <button (click)="newString()" class="btn btn-warning btn-sm">{{'addStringValue' | translate}}</button> &nbsp;
    <button (click)="newBoolean()" class="btn btn-warning btn-sm">{{'addBooleanValue' | translate}}</button> &nbsp;
    <button (click)="formatCode()" class="btn btn-info btn-sm">{{'editCode' | translate}}</button> &nbsp;
    <hr>
    <div  class="dataEditor">
        <div class="jsonEditor" style="height: 300px; overflow:hidden;">

        </div>
    </div>
    <br/>
    <button (click)="saveDataJson()" class="btn btn-success btn-sm">{{'saveJsonValue' | translate}}</button>
</div>