<!--<div class="chart-container" style="width: 100%; height:50px;">
  <canvas id="myChart"></canvas>
</div>
<img style="width:100%" id="currentChart">-->
<!--<canvas baseChart
[datasets]="scatterChartDatasets"
[options]="scatterChartOptions"
[type]="'scatter'">
</canvas> -->
<div *ngIf="chartModel">
<lib-area-chart *ngIf="chartModel.type == 'area'" [options]="chartModel.options" [data]="areaChartData"></lib-area-chart>
<lib-bar-chart *ngIf="chartModel.type == 'bar'" [options]="chartModel.options" [data]="chartModel.data"></lib-bar-chart>
<lib-pie-chart *ngIf="chartModel.type == 'pie'" [options]="chartModel.options" [data]="pieChartData"></lib-pie-chart>
<lib-line-chart *ngIf="chartModel.type == 'line'" [options]="chartModel.options" [data]="lineChartData"></lib-line-chart>
<lib-scatter-chart *ngIf="chartModel.type == 'scatter'" [options]="chartModel.options" [data]="scatterChartData"></lib-scatter-chart>
<lib-radar-chart *ngIf="chartModel.type == 'radar'" [options]="chartModel.options" [data]="radarChartData"></lib-radar-chart>
<lib-polar-area-chart *ngIf="chartModel.type == 'polarArea'" [options]="chartModel.options" [data]="polarAreaChartData"></lib-polar-area-chart>

</div>
