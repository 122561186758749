import { ChartModel } from './../../models/CustomChartModels/chartModel';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
/*import { Chart, registerables, ChartData } from 'chart.js';*/
import { CustomChartService } from '../../services/customChart/custom-chart.service';
import { ChartConfiguration, ChartData } from 'chart.js';
@Component({
  selector: 'lib-custom-chart',
  templateUrl: './custom-chart.component.html',
  styleUrls: ['./custom-chart.component.css']
})
export class CustomChartComponent implements OnInit {
  @Input() externalDataUrl:string;
  @Input() externalData:ChartModel;
  chart:any;
  chartModel:ChartModel;
  randName:string="myChart";
  areaChartData:ChartData<'line'>;
  barChartData:ChartData<'bar'>;
  pieChartData:ChartData<'pie'>;
  lineChartData:ChartData<'line'>;
  scatterChartData:ChartData<'scatter'>;
  polarAreaChartData:ChartData<'polarArea'>;
  radarChartData:ChartData<'radar'>;
  constructor(private customChartService:CustomChartService) { }

  ngOnInit(): void {
    this.getData();
  }
  getData():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      if(this.externalData != undefined && this.externalData.type != undefined){
        this.chartModel = this.externalData;
        switch(this.chartModel.type){
          case "pie":
            console.log(this.chartModel.data);
            this.pieChartData = this.chartModel.data as ChartData<'pie'>;
            break;
          case "area":
            this.areaChartData = this.chartModel.data as ChartData<'line'>;
            break;
          case "bar":
            this.barChartData = this.chartModel.data as ChartData<'bar'>;
            break;
          case "line":
            this.lineChartData = this.chartModel.data as ChartData<'line'>;
            break;
          case "scatter":
            this.scatterChartData = this.chartModel.data as ChartData<'scatter'>;
            break;
          case "polarArea":
            this.polarAreaChartData = this.chartModel.data as ChartData<'polarArea'>;
            break;
          case "radar":
            this.radarChartData = this.chartModel.data as ChartData<'radar'>;
            break;
        }
        resolve(true);
      }else{
        this.customChartService.externalUrl = this.externalDataUrl;
        this.customChartService.getChart().subscribe({
          next:(response)=>{
            this.chartModel = response;
            resolve(true);
          }
        })
      }
    })

  }
  /*createChart(context:CanvasRenderingContext2D){
    var myChart = new Chart("myChart", {
      type: 'bar',

      data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
              ],
              borderWidth: 1,

          }]

      },
      options: {

        scales: {
              y: {
                  beginAtZero: true
              }
          },
          animation:false,
          aspectRatio:4,
          skipNull:true,
      }
  });
  let img = myChart.toBase64Image();
  //myChart.destroy();
  let currentImg = document.getElementById("currentChart") as HTMLImageElement;
  currentImg.src = img;
  }
  switchToLine() {
    this.barAndLineData();
    this.chartModel.type = "line";
    this.chart.destroy();

  }
  switchToBar(){
    this.barAndLineData();
    this.chartModel.type = "bar";
    this.chart.destroy();

  }
  barAndLineData(){
    this.chartModel.data = {// values on X-Axis
      labels: ['2022-05-10', '2022-05-11', '2022-05-12','2022-05-13',
               '2022-05-14', '2022-05-15', '2022-05-16','2022-05-17', ],
       datasets: [
        {
          label: "Sales",
          data: ['467','576', '572', '79', '92',
               '574', '573', '576'],
          backgroundColor: 'blue'
        },
        {
          label: "Profit",
          data: ['542', '542', '536', '327', '17',
                 '0.00', '538', '541'],
          backgroundColor: 'limegreen'
        }
      ]
    }
  }
  switchToPie(){
    let type:string = 'pie'
    this.chartModel.type = type;
    this.chartModel.data = {
      labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
      }]
    }
    //this.createChart()
  } */

}

