<div class="formMain form-default-border form-maroon-border">
    <div class="filterInput mb-3">
        <label style="font-weight: bold;" for="usernameFilter">{{'username' | translate}}</label>
        <input class="form-control" type="text" (change)="changeFilter($event)" (keyup)="changeFilter($event)">
    </div>
    <div class="mb-3" *ngIf="users">
        <ul class="list-group" *ngFor="let user of users | usernameFilter : filter">
            <li class="list-group-item mb-3">{{user.firstName}} {{user.lastName}} ( {{user.username}} ) <i style="float:right;" (click)="addRole(user.id)" class="bi bi-plus-lg"></i></li>
          </ul>
    </div>
</div>
