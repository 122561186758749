import { Component, OnInit } from '@angular/core';
import { GuacamoleService } from '../../services/guacamole/guacamole.service';
import { ActiveConnectionItemModel, ConnectionProviderPagerModel } from '../../models/GuacamoleModels/activeConnectionItemModel';
import { ApiUrlService } from '../../services/api/api-url.service';

@Component({
  selector: 'lib-active-connections',
  templateUrl: './active-connections.component.html',
  styleUrls: ['./active-connections.component.css']
})
export class ActiveConnectionsComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }
  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl() + "api/terminal/admin/activeconnection/list"
  }
}
