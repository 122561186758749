import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-global-delete-page',
  templateUrl: './global-delete-page.component.html',
  styleUrls: ['./global-delete-page.component.css']
})
export class GlobalDeletePageComponent implements OnInit {
  @Input() deleteUrl:string;
  @Input() itemIdKey:string = "id";
  @Input() itemId:any;
  @Input() messageKey:string;
  @Input() deletedSuccessfullyKey:string = "global.successfullyDeleted";
  @Input() deleteFailedKey:string = "global.deleteFailed";
  checkedUnderstandCheck:boolean = false;
  @Input() additionalConfirmation:boolean = false;
  @Input() additionalConfirmationMessage:string;
  checkAdditionalConfirmation:boolean = false;
  
  success:boolean = false;
  constructor(private toastrService:ToastrService,private httpClient:HttpClient,private translateService:TranslateService) { }

  ngOnInit(): void {
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  additionalCheckChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkAdditionalConfirmation = target.checked;
  }
  deleteItem(){
    var requestUrl = environment.apiUrl + this.deleteUrl;
    var obj:any = new Array();
    obj[this.itemIdKey] = this.itemId;
    var requestBody:any;
    if(this.additionalConfirmation){
      obj["confirmation"] = this.checkAdditionalConfirmation;
      requestBody = {[this.itemIdKey]:this.itemId,confirmation:this.checkAdditionalConfirmation}
    }else{
      requestBody = {[this.itemIdKey]:this.itemId}
    }
    console.log(requestBody);

    this.httpClient.delete(requestUrl,{body:requestBody}).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant(this.deletedSuccessfullyKey));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant(this.deleteFailedKey));
      }
    })
  }

}
