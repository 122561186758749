import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';

@Component({
  selector: 'lib-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css']
})
export class AreaChartComponent implements OnInit {
  @Input() options:any;
  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
    this.options = {fill:true,...this.options};
  }

}
