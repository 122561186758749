import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale/locale.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-add-locale',
  templateUrl: './add-locale.component.html',
  styleUrls: ['./add-locale.component.css']
})
export class AddLocaleComponent implements OnInit {
  locales:ListItemModel[];
  addLocaleForm:FormGroup;
  constructor(private localeService:LocaleService,private toastrService:ToastrService,private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.getLocaleItems().then(()=>{
      this.createForm();
    })
  }
  createForm(){
    this.addLocaleForm = this.formBuilder.group({
      id:["",Validators.required],
      name:["",Validators.required],
      active:[false],
      cultureCode:["",Validators.required],
      iso639P1:[""],
      iso639P2:[""],
      wildcardLocaleId:[null],
      parentLocaleId:[""],
    })
  }
  getLocaleItems():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.localeService.getLocalesWithSelectListSchema().subscribe({
        next:(response)=>{
          this.locales = response.content;
          resolve(true);
          return;
        },error:(err)=>{
          resolve(false);
          return;
        }
      })
    })

  }
  submitForm(){
    if(this.addLocaleForm.valid){
      var formData = Object.assign({},this.addLocaleForm.value);
      formData.parentLocaleId = formData.parentLocaleId == '' ? null : formData.parentLocaleId;
      this.localeService.addLocale(formData).subscribe({
        next:(response)=>{
          this.toastrService.success("Dil eklendi");
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }

}
