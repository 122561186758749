<div class="addMemberContainer">
<h5>{{'sessionManagement.addNewMember' | translate}}</h5>
<hr>
<font><b>{{'sessionManagement.sessionMember.userId' | translate}}</b></font>
<form [formGroup]="memberAddForm">
<div class="input-group">
    <input type="text" name="id" id="id" formControlName="id" class="form-control" placeholder="User Id" aria-label="User Id" aria-describedby="addon-wrapping">
  </div>
  <br/>
  <font><b>{{'sessionManagement.sessionMember.displayName' | translate}}</b></font>
  <div class="input-group">

    <input type="text" id="displayNameOverride" name="displayNameOverride" formControlName="displayNameOverride" class="form-control" placeholder="Display Name" aria-label="Display Name" aria-describedby="addon-wrapping">
  </div>
  <br/>
  <font><b>{{'sessionManagement.sessionMember.roles' | translate}}</b></font>
  <div class="input-group">

    <input type="text" id="roles" name="roles" formControlName="roles" class="form-control" placeholder="Roles" aria-label="Roles" aria-describedby="addon-wrapping">
  </div>
  <br/>
  <button type="submit" class="btn btn-sm btn-primary" (click)="submitForm()">{{'sessionManagement.sessionMember.btn.addNewMember' | translate}}</button>
</form>
</div>