import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QuestionService } from '../../../services/questionManager/question.service';
import { QuestionSetModel } from '../../../models/questionManagementModels/questionSetModel';
import { UpdateQuestionSetModel } from '../../../models/questionManagementModels/updateQuestionSetModel';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsImageService } from '../../../services/cmsImages/cms-image.service';

@Component({
  selector: 'lib-update-question-set',
  templateUrl: './update-question-set.component.html',
  styleUrls: ['./update-question-set.component.css']
})
export class UpdateQuestionSetComponent implements OnInit {
  questionSet:QuestionSetModel;
  questionSetId:number;
  updateQuestionSetForm:FormGroup;
  loadData:boolean = false;
  parents:ListItemModel[];
  parentsLoaded:boolean = false;
  imageChangedEvent:any;
  sending:boolean = false;
  constructor(private formBuilder:FormBuilder,public cmsImageService:CmsImageService,private sanitizer:DomSanitizer,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private questionService:QuestionService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.questionSetId = param["questionSetId"];
        this.getQuestionSet();
        this.getParents();
        
      }
    })
  }
  setImage(ev:any){
    this.imageChangedEvent = ev;
  }
  croppedImage(data:ImageCroppedEvent){
    var img = this.sanitizer.bypassSecurityTrustUrl(data.objectUrl ?? "");
    this.updateQuestionSetForm.get("image")?.setValue(data.base64);
  }
  getQuestionSet(){
    this.questionService.getQuestionSetUpdateFields(this.questionSetId).subscribe({
      next:(response)=>{
        this.questionSet = response.content;
        this.loadData = true;
        this.prepareForm();
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getParents() : Promise<boolean> {
    return new Promise<boolean>((resolve,reject)=>{
      this.questionService.getQuestionSetList(true).subscribe({
        next:(response)=>{
          
          this.parents = response.content.filter(x=>x.value != this.questionSetId);
          resolve(true);
          this.parentsLoaded = true;
        },error:(err)=>{
          this.toastrService.error("An error occurred");
          resolve(true);
        }
      })
    })
  }
  prepareForm(){
    this.updateQuestionSetForm = this.formBuilder.group({
      id:[this.questionSet.id,Validators.required],
      name:[this.questionSet.name,Validators.required],
      enabled:[this.questionSet.enabled],
      image:[null],
      deleteCurrentImage:[false],
      color:[this.questionSet.color],
      parentId:[this.questionSet.parentId]
    })
  }
  updateQuestionSet(){
    if(this.updateQuestionSetForm.valid){
      this.sending = true;
      var data:UpdateQuestionSetModel = Object.assign({},this.updateQuestionSetForm.value);
      data.parentId = data.parentId as any == "null" ? null : parseInt(data.parentId as any);
      this.questionService.updateQuestionSet(data).subscribe({
        next:(response)=>{
          this.toastrService.success("Soru kümesi başarıyla güncellendi");
          this.sending = false;
        },error:(err)=>{
          this.toastrService.error("Soru kümesi güncellenemedi");
          this.sending = false;
        }
      })
    }else{
      this.toastrService.error("Lütfen formu kontrol edin");
    }
  }

}
