<div class="objectives-container" *ngIf="objectives">
    <h5>{{'setupInstance.objectives'}}</h5>
    <div class="objective-toolbar">
        <div class="toolbar-item" [title]="'setupVmInstances.addObjectiveTooltip' | translate">
            <i class="bi bi-body-text" style="margin-right:4px;"></i><i class="bi bi-plus-lg"></i>
        </div>

    </div>
    <div class="objective-items">
        <div class="objective-item" *ngFor="let objective of objectives">
            <div class="objective-top">
                <div class="item-name">{{objective.name}}</div>
                <div class="objective-actions" style="float: right;">
                    <div class="objective-action">
                        <i class="bi bi-pencil-fill"></i>
                    </div>
                    <div class="objective-action">
                        <i class="bi bi-trash-fill"></i>
                    </div>
        
                </div>
            </div>
            <div class="objective-bottom">
                <font class="objective-description">
                    {{objective.description}}
                </font>
            </div>

        </div>
    </div>

</div>