import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Timeline, TimelineModel, TimelineOptions, TimelineRowStyle } from 'animation-timeline-js';
import { GlobalModalService } from '../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
import { VideoTimelineService } from '../../services/videoTimeline/video-timeline.service';
import { ActivatedRoute } from '@angular/router';
import { VideoTimelineModel } from '../../models/Timeline/videoTimelineModel';
declare var $: any;
@Component({
  selector: 'lib-video-timeline',
  templateUrl: './video-timeline.component.html',
  styleUrls: ['./video-timeline.component.css']
})
export class VideoTimelineComponent implements OnInit {
  allowedZooms: number[] = [1, 5, 10, 30, 60];
  videoTimeline: VideoTimelineModel;
  editor: boolean = false;
  videoId: string;
  timeline: any;
  videoItem: HTMLVideoElement;
  playing: boolean = false;
  playStep: number = 10;
  trackTimelineMovement: boolean = false;
  videoDuration: number;
  inputText: string;
  acceptInput: boolean = false;
  zoomValue = 10;
  selectedKeyframe: any;
  selectedKeyframeInfo: { group: string, begin: string, end: string | undefined } | undefined = undefined;
  selectedKeyframeType: number = 0; //0 section , 1 content
  @ViewChild("modalInput") modalInput: ElementRef;
  constructor(private globalModalService: GlobalModalService, private videoTimelineService: VideoTimelineService, private activatedRoute: ActivatedRoute) { }
  ngOnInit() {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.videoId = param["video"];
      }
    })
  }
  inputChangeFnc(ev: any) {
    this.inputText = $(ev.target).val()
    this.acceptInput = false;
  }
  acceptInputFnc() {
    this.acceptInput = true;
  }
  videoLoaded() {
    this.videoItem = document.getElementById("activeVideo") as HTMLVideoElement

    /*videoItem.onprogress = (progress)=>{

    }*/
    setInterval(() => {
      if (this.playing) {
        this.timeline.setTime(this.videoItem.currentTime * 1000);
      }
      //this.timeline.rescale();
    }, 50);
    this.videoDuration = this.videoItem.duration * 1000;
    const options = {
      id: "timeline",
      controlKeyIsMetaKey: false,
      max: this.videoDuration,
      min: 0,
      zoomMax: 60,
      zoom: this.zoomValue,
      snapAllKeyframesOnMove: false,
      snapEnabled: false,
      rowsStyle: {
        height: 25,
        groupsStyle: {
          fillColor: "#454545",
        },
        keyframesStyle: {
          height: 12,
          width: 12
        }
      } as TimelineRowStyle,
    } as TimelineOptions;
    const model: TimelineModel = {
      rows: [
        {
          keyframes: [

          ]
        }
      ]
    }
    this.timeline = new Timeline(options, model);
    //this.timeline.initialize({ id: 'timeline', headerHeight: 45 });
    //this.timeline.setModel({ rows: rows });
    document.addEventListener('keydown', (args) => {
      if (args.which === 65 && this.timeline._controlKeyPressed(args)) {
        this.timeline.selectAllKeyframes();
        args.preventDefault();
      }
    });

    this.timeline.onTimeChanged(() => {
      this.showActivePositionInformation();
      this.activePositionChanged();

    });
    //this.timeline.onSelected((obj) => this.logMessage('Selected Event: (' + obj.selected.length + '). changed selection :' + obj.changed.length, 2));
    this.timeline.onSelected((obj: any) => this.setSelectedKeyframe(obj));
    this.timeline.onDragStarted((obj: any) => console.log(obj));
    //this.timeline.onDragStarted((obj) => this.logDraggingMessage(obj, 'dragstarted'));
    this.timeline.onDrag((obj: any) => this.logDraggingMessage(obj, 'drag'));
    this.timeline.onKeyframeChanged((obj: any) => console.log('keyframe: ' + obj.val));
    this.timeline.onDragFinished((obj: any) => this.dragFinished(obj));
    this.timeline.onMouseDown((obj: any) => {
      var type = obj.target ? obj.target.type : '';
      this.logMessage('mousedown:' + obj.val + '.  target:' + type + '. ' + Math.floor(obj.pos.x) + 'x' + Math.floor(obj.pos.y), 2);
    });
    this.timeline.onDoubleClick((obj: any) => {
      var type = obj.target ? obj.target.type : '';
      this.logMessage('doubleclick:' + obj.val + '.  target:' + type + '. ' + Math.floor(obj.pos.x) + 'x' + Math.floor(obj.pos.y), 2);
    });
    this.timeline.onScroll(() => this.showActivePositionInformation());
    this.timeline.onScrollFinished(() => this.logMessage('on scroll finished', 2));

    this.generateOutline();

  }
  dragFinished(obj: any) {
    //Sürükle bıraktan sonra bilgilerin yenilenmesi
    try {
      var frames = this.getKeyframesWithGroupName(this.selectedKeyframe.selected[0].group);
      var val1 = frames[0].val;
      var val2 = frames[1].val;
      var max = Math.max(val1, val2);
      var min = Math.min(val1, val2);
      this.selectedKeyframeInfo = {
        group: this.selectedKeyframe.selected[0].group,
        begin: min.toString(),
        end: max.toString()
      }
    } catch {

    }

  }
  setSelectedKeyframe(obj: any) {
    if (obj.selected != null && obj.selected.length > 0) {
      this.selectedKeyframe = obj;
      var frames = this.getKeyframesWithGroupName(this.selectedKeyframe.selected[0].group);
      var val1 = frames[0].val;
      if(frames.length > 1){
        var val2 = frames[1].val;
        var max = Math.max(val1, val2);
        var min = Math.min(val1, val2);
        this.selectedKeyframeInfo = {
          group: this.selectedKeyframe.selected[0].group,
          begin: min.toString(),
          end: max.toString()
        }
      }else{
        this.selectedKeyframeInfo = {
          group: this.selectedKeyframe.selected[0].group,
          begin: val1,
          end: undefined
        }
      }


    }

  }
  getKeyframesWithGroupName(group: string) {
    console.log(group);
    var keyframes: any[] = [];
    var model: any = this.timeline.getModel();
    var rowLen = model.rows.length;
    for (let rowIndex = 0; rowIndex < rowLen; rowIndex++) {
      const currentRow = model.rows[rowIndex];
      if (currentRow.keyframes.find((a: any) => a.group == group) != null) {
        var keyframeLength = currentRow.keyframes.length;
        for (let keyLen = 0; keyLen < keyframeLength; keyLen++) {
          const element = currentRow.keyframes[keyLen];
          if (element.group == group) {
            keyframes.push(element);
          }
        }
      }

    }
    console.log(keyframes);
    return keyframes;
  }
  getResult() {
    var preEl = document.getElementById("result") as HTMLPreElement;
    preEl.innerText = JSON.stringify(this.timeline.getModel());
  }
  outlineMouseWheel(e: any) {
    const container = document.getElementById('outline-scroll-container');
    if (container) {
      container.scrollTop += e.deltaY;
      e.preventDefault();
    }
  }
  zoomIn() { // +

    var index = this.allowedZooms.findIndex(x => x == this.zoomValue);
    if (index != -1 && index != 0) {
      var nextZoom = this.allowedZooms[index - 1];
      this.zoomValue = nextZoom;
      this.timeline.setZoom(nextZoom);
    }


  }
  zoomOut() {
    var index = this.allowedZooms.findIndex(x => x == this.zoomValue);
    if (index != -1 && index != this.allowedZooms.length - 1) {
      var nextZoom = this.allowedZooms[index + 1];
      this.zoomValue = nextZoom;
      this.timeline.setZoom(nextZoom);
    }
  }

  onPlayClick() {
    console.log(this.timeline.getTime() / 1000);
    this.videoItem.currentTime = this.timeline.getTime() / 1000;
    this.videoItem.play();
    this.playing = true;
  }

  activePositionChanged() {
    if (this.playing == false) {
      console.log(this.timeline.getTime() / 1000);
      this.videoItem.currentTime = this.timeline.getTime() / 1000;
    }

  }

  onPauseClick() {
    this.videoItem.pause();
    this.playing = false;
  }

  selectMode() {
    this.timeline.setInteractionMode('Select');
  }

  panMode(interactive: boolean) {
    this.timeline.setInteractionMode('Pan', { interactive: interactive });
  }

  zoomMode() {
    this.timeline.setInteractionMode('Zoom');
  }

  noneMode() {
    this.timeline.setTool(null);
  }

  removeKeyframe() {
    var row = 0;
    var indexes: number[] = [];
    var guid = this.generateGuid();
    console.log(this.timeline.getModel())
    var currentModel = this.timeline.getModel();
    for (let i = 0; i < currentModel.rows.length; i++) {
      currentModel.rows[i].keyframes.forEach((keyframe: any) => {
        if (keyframe.selected) {
          row = i;
          var groupName = keyframe.group;
          var keyframeLength = currentModel.rows[i].keyframes.length;
          console.log(keyframeLength)
          for (let keyLen = 0; keyLen < keyframeLength; keyLen++) {
            const element = currentModel.rows[i].keyframes[keyLen];
            console.log(element.group);
            console.log(groupName + "gm");
            if (element.group == groupName) {
              indexes.push(keyLen);
            }

          }
          //currentModel.rows.splice(i, 1);
        }
      })
    }
    indexes = indexes.sort().reverse();
    indexes.forEach(index => {
      currentModel.rows[row].keyframes.splice(index, 1);
    })
    if (row != 0 && currentModel.rows[row].keyframes.length == 0) {
      currentModel.rows.splice(row, 1);
    }
    this.timeline.setModel(currentModel);
  }

  addRow() {
    this.resetInputText();
    this.selectedKeyframeType = 1;
    this.acceptInput = false;
    var modal = this.globalModalService.showModal({ data: this.modalInput.nativeElement, helperModals: [], title: "", type: ModalTypes.HTMLELEMENT }, undefined)
    modal.onclose.subscribe((response: any) => {
      console.log(this.timeline.getModel())
      if (this.acceptInput == false) {
        return;
      }
      var currentModel = this.timeline.getModel();
      currentModel.rows.push({
        keyframes: [
          {
            val: this.videoItem.currentTime * 1000,
            group: this.inputText
          },
          {
            val: (this.videoItem.currentTime * 1000) + 1000,
            group: this.inputText
          }
        ]
      })
      this.timeline.setModel(currentModel);

    })

    // this.timeline.addEmptyTrack();
  }
  addRowWithSingleKeyframe() {
    this.resetInputText();
    this.selectedKeyframeType = 1;
    this.acceptInput = false;
    var modal = this.globalModalService.showModal({ data: this.modalInput.nativeElement, helperModals: [], title: "", type: ModalTypes.HTMLELEMENT }, undefined)
    modal.onclose.subscribe((response: any) => {
      console.log(this.timeline.getModel())
      if (this.acceptInput == false) {
        return;
      }
      var currentModel = this.timeline.getModel();
      currentModel.rows.push({
        keyframes: [
          {
            val: this.videoItem.currentTime * 1000,
            group: this.inputText
          }
        ]
      })
      this.timeline.setModel(currentModel);

    })

    // this.timeline.addEmptyTrack();
  }
  resetInputText() {
    var inputTextEl = document.getElementById("inputTextEl") as HTMLInputElement;
    inputTextEl.value = "";
  }
  addSection() {
    this.resetInputText();
    this.selectedKeyframeType = 0
    this.acceptInput = false;
    var modal = this.globalModalService.showModal({ data: this.modalInput.nativeElement, helperModals: [], title: "", type: ModalTypes.HTMLELEMENT }, undefined)
    modal.onclose.subscribe((response: any) => {
      if (this.acceptInput) {
        var guid = this.generateGuid();
        console.log(this.timeline.getModel())
        var currentModel = this.timeline.getModel();
        var framePos = this.videoItem.currentTime * 1000;
        currentModel.rows[0].keyframes.push(
          {
            val: framePos,
            group: this.inputText
          },
          {
            val: framePos + 2000,
            group: this.inputText
          }
        )
        this.timeline.setModel(currentModel);
      }

    })

    // this.timeline.addEmptyTrack();
  }


  logMessage(msg: string, outputNumber: number) {
    console.log(" => " + msg + "Out : " + outputNumber)
    const output = document.getElementById('output' + outputNumber);
    if (output) {
      output.innerHTML = msg;
    }
  }

  logDraggingMessage(obj: any, ev: string) {
    const msg = ev + ' ' + obj.val + ', px:' + obj.px + ' selected:' + obj.selected + '  moved:' + (obj.moved ? obj.moved.length : '');
    this.logMessage(msg, 1);
  }

  showActivePositionInformation() {
    console.log("active time")
    const currentTimeDiv = document.getElementById('currentTime');
    if (currentTimeDiv) {
      const scroll = this.timeline.getScrollAreaContainer();
      currentTimeDiv.innerHTML = `scrollTop: ${Math.floor(scroll.scrollTop)}, scrollLeft: ${Math.floor(scroll.scrollLeft)}, selected: ${this.timeline.getSelectedKeyframes().length}, time: ${this.timeline.getCurrentTime()}`;
    }
  }

  generateModel() {
    return [
      { name: 'Layer 1', height: 70, keyframes: [{ time: 10, value: 10, id: this.generateGuid() }, { time: 100, value: 20, id: this.generateGuid() }] },
      { name: 'Layer 2', height: 50, keyframes: [{ time: 10, value: 10, id: this.generateGuid() }, { time: 50, value: 20, id: this.generateGuid() }] }
    ];
  }

  generateOutline() {
    const outlineHeader = document.getElementById('outline-header');
    if (outlineHeader) {
      outlineHeader.innerHTML = '<div class="outline-node" style="height:100%;">Outline Header</div>';
    }

    const outlineContainer = document.getElementById('outline-container');
    if (outlineContainer) {
      for (let i = 0; i < 20; i++) {
        const outlineNode = document.createElement('div');
        outlineNode.className = 'outline-node';
        outlineNode.innerHTML = 'Outline Item ' + (i + 1);
        outlineContainer.appendChild(outlineNode);
      }
    }
  }

  generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  applyContents() {
    var model = this.videoTimelineService.createVideoTimelineModelByTimelineModel(this.timeline.getModel(), "");
    this.videoTimeline = model;
    setTimeout(() => {
      this.editor = true;
    }, 100);
    console.log(model);
  }
  goBack() {
    this.editor = false;
  }

}
