<div class="formMain form-default-border form-maroon-border">
    <h4>{{'userManagement.importUsersTitle' | translate}}</h4>
    <hr />
    <form>
        <div class="mb-3">
            <label for="file" class="form-label">{{'userManagement.uploadXlsxOrCsvFile' | translate}}</label>
            <input (change)="changeFile($event)" class="form-control" type="file" id="file">
        </div>
        <div class="mb-3">
            <label for="cultureCode" class="form-label">{{'userManagement.cultureCode' | translate}}</label>
            <input type="text" class="form-control" value="en-US" id="cultureCode" placeholder="">
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="ignoreFirstRow">
            <label class="form-check-label" for="ignoreFirstRow">
                {{'userManagement.ignoreFirstRow' | translate}}
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="export">
            <label class="form-check-label" for="export">
              {{'userManagement.exportUsers' | translate}}
            </label>
          </div>
          <div class="mb-3">
            <label for="setPassword" class="form-label">{{'userManagement.setUserPasswords' | translate}}</label>
            <input type="text" class="form-control" value="" id="setPassword" placeholder="">
        </div>
        <div class="mb-3" *ngIf="realmRoles">
            <label class="form-label">{{'userManagement.selectRoles' | translate}}</label>
            <lib-autocomplete-input [name]="'realmRoles'" [values]="realmRoles" [showMiniItems]="true"></lib-autocomplete-input>
        </div>

    </form>
    <button [disabled]="loading" (click)="submit()" class="btn btn-primary btn-sm">{{'btn.import' | translate}}   <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></button>
    <br/><br/>
    <div *ngIf="result" class="resultView">
        <b>{{'userManagement.createdUserCount' | translate}} : </b><font>{{result.created}}</font><br/>
        <b>{{'userManagement.numberOfFailedRequest' | translate}} : </b><font>{{result.failed}}</font><br/>
    </div>
    <div class="downloadEmptyFile">
        <p class="emptyFileDownloadTitleText">{{'userManagement.downloadEmptyFileTitle' | translate}}</p>
        <font class="downloadFileTextItem">
            {{'userManagement.emptyFileDownloadText' | translate}}
        </font>
        <a target="_blank" [href]="apiUrl+'api/accounts/user/empty-import-file?withoutPassword=false'"><font>{{'userManagement.downloadFile' | translate}}</font></a>
        <br/><br/>
        
        <font class="downloadFileTextItem">
            {{'userManagement.emptyFileWithoutPasswordColumnDownloadText' | translate}}
        </font>
        <a target="_blank" [href]="apiUrl+'api/accounts/user/empty-import-file?withoutPassword=true'"><font>{{'userManagement.downloadFile' | translate}}</font></a> 
    </div>
</div>