<h4>Tenant Details</h4>
<hr>
<div>
    <div class="mb-3">
        <b>Tenant Id : </b><font>{{tenantResponse.id}}</font>
    </div>
    <div class="mb-3">
        <b>Tenant Name : </b><font>{{tenantResponse.name}}</font>
    </div>
    <div class="mb-3">
        <b>Tenant Enabled : </b><font>{{tenantResponse.enabled}}</font>
    </div>
</div>
