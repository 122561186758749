import { Component, Input, OnInit } from '@angular/core';
import { SessionFeatureListItem } from '../../../models/SessionApiModels/sessionFeatureListItem';
import { SessionApiService } from '../../../services/session/session-api.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { SessionTemplateService } from '../../../services/session/session-template.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-session-management-list-features',
  templateUrl: './session-management-list-features.component.html',
  styleUrls: ['./session-management-list-features.component.css']
})
export class SessionManagementListFeaturesComponent implements OnInit {
  features:SessionFeatureListItem[];
  showFeatures:SessionFeatureListItem[] = [];
  allFeatures:boolean = false;
  showAllFeaturesBtn:boolean = false;
  featureTypesSelectList:ListItemModel[];
  @Input() sessionId:string;
  constructor(private translateService:TranslateService ,private sessionTemplateService:SessionTemplateService,private sessionApiService:SessionApiService,private toastrService:ToastrService,private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    this.getFeatures();
    this.getFeatureList();
  }
  getFeatures(){
    this.sessionApiService.getFeaturesFromSession(this.sessionId).subscribe({
      next:(response)=>{
        this.features = response.content;
        this.createShowFeatures();
        if(response.content.length > 5){
          this.showAllFeaturesBtn = true;
        }

      },error:(err)=>{
        var errorMessage = this.translateService.instant("global.errorOccurred");
        this.toastrService.error(errorMessage);
      }
    })
  }
  getFeatureList(){
    this.sessionTemplateService.getFeatureTypesForSelectList().subscribe({
      next:(response)=>{
        this.featureTypesSelectList = response.content;
      },
      error:(err)=>{

      }
    })
  }
  getFeatureLabel(featureId:string){
    return this.featureTypesSelectList.find(x=>x.value == featureId)?.label;
  }
  createShowFeatures(){
    this.allFeatures = false;
    var count = 5;
    this.showFeatures = [];
    for (let index = this.features.length-1; index >= 0; index--) {
      this.showFeatures.push(this.features[index]);
      count--;
      if(count <= 0){
        break;
      }
      
    }
  }
  deleteSessionFeature(featureId:number){
    var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:"Delete Feature",data:`/modal/sessions/${this.sessionId}/feature/${featureId}/delete`,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }
  showAllToggle(){
    if(this.allFeatures == false){

      this.allFeatures = true;
      this.showFeatures = [];
      for (let index = this.features.length-1; index >= 0; index--) {
        this.showFeatures.push(this.features[index]);  
      }
    }else{
      this.createShowFeatures();
    }

  }
  showFeatureConfiguration(feature:SessionFeatureListItem){
    var configuration = JSON.parse(feature.data);
    var keys = Object.keys(configuration);
    var mainDiv = document.createElement("div");
    mainDiv.style.padding = "15px"
    keys.forEach(key=>{
      var subElement = document.createElement("p");
      subElement.innerHTML = `<b>${key} : </b> ${configuration[key]}`;
      mainDiv.appendChild(subElement);
    })
    var modalCreate:ModalCreateModel = {type:ModalTypes.HTMLELEMENT,title:"List Configuration",data:mainDiv,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }
  addNewFeature(){
    var modalCreate:ModalCreateModel = {type:ModalTypes.LINK,title:"Add Feature",data:`modal/session/${this.sessionId}/addfeature`,helperModals:[]};
    this.globalModalService.showModal(modalCreate);
  }

}
