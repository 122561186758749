<div *ngIf="imageDetails" class="formMain form-default-border form-maroon-border">
    <h5>Installer Image Details</h5>
    <hr>
    <div class="imageInfoItem">
        <font class="infoTitle">Image Id</font>
        <font class="infoValue">{{imageDetails.id}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Image Name</font>
        <font class="infoValue">{{imageDetails.name}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Operating System Type</font>
        <font class="infoValue">{{imageDetails.osType}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Flavor</font>
        <font class="infoValue">{{imageDetails.flavor}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Os Info</font>
        <font class="infoValue">{{imageDetails.osInfo}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Arch</font>
        <font class="infoValue">{{imageDetails.arch}}</font>
    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Active</font>

            <input class="form-check-input" [checked]="imageDetails.active" type="checkbox" [readOnly]="true" onclick="return false" value="" id="flexCheckDefault">

    </div>
    <div class="imageInfoItem">
        <font class="infoTitle">Description</font>
        <font class="infoValue">{{imageDetails.description}}</font>
    </div>
    <div class="imageFileInfo" *ngIf="imageDetails.filesize && imageDetails.filesize > 0">
        <p class="fileInfoText">Installer Image File</p>
        <div class="imageInfoFileItem">
            <font class="infoTitle">File Name</font>
            <font class="infoValue">{{imageDetails.filename}}</font>
        </div>
        <div class="imageInfoFileItem">
            <font class="infoTitle">File Size</font>
            <font class="infoValue">{{imageDetails.filesizeStr}}</font>
        </div>
        <div class="imageInfoFileItem">
            <font class="infoTitle">File Hash</font>
            <font class="infoValue">{{imageDetails.filehash}}</font>
        </div>
        <div class="downloadProgress" *ngIf="downloading">
            <div class="d-flex align-items-center">
                <strong>{{'Downloaded %'+mathFloor((downloaded / total)*100)}}</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              </div>
              <br/>
        </div>
        <div style="text-align: right;">
            <button class="btn btn-success btn-sm" style="margin-right: 5px;" (click)="downloadFile()">Download File</button>
            <button class="btn btn-warning btn-sm" [routerLink]="'/range/vm/installerimages/'+imageDetails.id+'/upload'">Update File</button>
        </div>
        
    </div>

</div>