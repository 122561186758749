import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-list-course-seat-access-items',
  templateUrl: './list-course-seat-access-items.component.html',
  styleUrls: ['./list-course-seat-access-items.component.css']
})
export class ListCourseSeatAccessItemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
