import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-objective-management-panel',
  templateUrl: './objective-management-panel.component.html',
  styleUrls: ['./objective-management-panel.component.css']
})
export class ObjectiveManagementPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
