<div class="mb-3">
    <div class="valueEditor">
        <textarea *ngIf="inputType[0] == 0 || inputType[0] == 2" [class]="'editorTextarea_'+uuid+' form-control'"
            style="width: 100%;"></textarea>
        <div [class]="'editorMonaco_'+uuid" *ngIf="inputType[0] == 1 || inputType[0] == 3 || inputType[0] == 4" style="height: 500px;">

        </div>
        <div *ngIf="inputType[0] == 5" class="layout-editor-container">
            <textarea [innerHTML]="sanitizedValue" [class]="'editorLayout_'+uuid" [id]="'editorLayout_'+uuid"
                data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
                autocomplete="off"
                data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;">
            </textarea>
        </div>

    </div>
</div>