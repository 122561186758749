import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiePreferenceListenerService {
  cookiePreferencesSource = new BehaviorSubject<boolean>(true);
  public cookiePreferences$ = this.cookiePreferencesSource.asObservable();
  constructor() {
    this.cookiePreferences$.subscribe(status => window.localStorage.setItem("cookiePreferences",status ? "true" : "false"));
  }
  setCookiePreferences(state:boolean):Observable<boolean>{
    let ts = window.localStorage.getItem("cookiePreferences");
    ts = state ? "true" : "false";
    this.cookiePreferencesSource.next(state);
    return this.cookiePreferences$;
  }

}
