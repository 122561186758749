import { FormResponseObject } from './../../models/CustomFormModels/formResponseObject';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-custom-table-editor',
  templateUrl: './custom-table-editor.component.html',
  styleUrls: ['./custom-table-editor.component.css']
})
export class CustomTableEditorComponent implements OnInit {

  constructor() { }
  @Input() formId:string;
  @Input() showSuccessIcon:boolean;
  @Input() width:string = "100%";
  @Input() maxWidth:string = "600px";
  @Output() responseSender = new EventEmitter<FormResponseObject>();
  ngOnInit(): void {

  }
  responseHandler(response:FormResponseObject){
    this.responseSender.emit(response);
  }

}
