import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VMEnvironmentModel } from '../../../models/VirtualLabModels/VMEnvironment';
import { UpdateVMEnvironmentModel } from '../../../models/VirtualLabModels/updateVMEnvironment';
import { TranslateService } from '@ngx-translate/core';
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-update-vm-environment',
  templateUrl: './update-vm-environment.component.html',
  styleUrls: ['./update-vm-environment.component.css']
})
export class UpdateVmEnvironmentComponent implements OnInit {
  updateEnvironmentForm:FormGroup
  environmentId:string;
  vmEnvironment:VMEnvironmentModel;
  loaded:boolean;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute ,private formBuilder:FormBuilder,private virtualLabService:VirtualLabService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.getEnvironmentId();
  }
  createForm(){
    this.updateEnvironmentForm = this.formBuilder.group({
      name:[this.vmEnvironment.name,Validators.required],
      enabled:[this.vmEnvironment.enabled]
    })
  }
  initializeMonaco(){
    var configuration = this.vmEnvironment.data ?? "[]";
    var monacoEditor: any = null;
    if (typeof monaco !== 'undefined') {
      //codeEditor.outerHTML = '<div class="style-code"></div>';
      setTimeout(function () {
        monacoEditor = monaco.editor.create(document.getElementsByClassName("jsonEditor")[0], {
          language: 'json',
          value: configuration,
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['monacoEditor'] = monacoEditor;
      }, 50),
        setTimeout(() => {
          monacoEditor.trigger('editor', "editor.action.formatDocument");
          monacoEditor.layout();
          console.log(monacoEditor)
          monacoEditor.onDidChangeModelContent((event: any) => {
            console.log("code changed");
          });
        }, 100)

    }
  }
  getEnvironmentId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.environmentId = param["hostId"];
        this.getVMEnvironment();
      }
    })
  }
  getVMEnvironment(){
    this.virtualLabService.getVMEnvironment(this.environmentId).subscribe({
      next:(response)=>{
        this.vmEnvironment = response.content;
        this.loaded = true;
        this.createForm();
        this.initializeMonaco();


      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    });
  }
  validJson(){
    try {
      var json = window['monacoEditor'].getValue();
      JSON.parse(json);
      return true;
    }catch{
      return false;
    }
  }
  updateVMEnvironment(){
    if(!this.validJson()){
      this.toastrService.error("JSON u kontrol edin");
      return;
    }
    if(this.updateEnvironmentForm.valid){
      var updateValues:UpdateVMEnvironmentModel = Object.assign({},this.updateEnvironmentForm.value);
      updateValues.hostId = this.vmEnvironment.hostId;
      updateValues.data = window['monacoEditor'].getValue();
      console.log(updateValues);
      this.virtualLabService.updateVMEnvironment(updateValues).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("vmhosts.updated"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }



}
