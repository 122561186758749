import { Component, Input, OnInit } from '@angular/core';
import { InputFormats } from '../../models/Enums/inputFormatsEnum';

@Component({
  selector: 'lib-format-viewer',
  templateUrl: './format-viewer.component.html',
  styleUrls: ['./format-viewer.component.css']
})
export class FormatViewerComponent implements OnInit {
 @Input() content:string = '';
 @Input()  format:number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
