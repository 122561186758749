import { Component, OnInit } from '@angular/core';
import { AdminManagerService } from '../../../services/admin-manager.service';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {
  createAdminFormData:CustomForm;
  constructor(private adminManagement:AdminManagerService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.getCreateAdminForm();
  }
  getCreateAdminForm(){
    this.adminManagement.getCreateAdminForm().subscribe({
      next:(response)=>{
        this.createAdminFormData = response.content;
      },error:(err)=>{
        this.toastrService.error("Yönetici oluşturma formu alınamadı");
      }
    })
  }

}
