import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionApiService } from 'projects/usorta-library/src/lib/services/session/session-api.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';

@Component({
  selector: 'app-delete-session-template',
  templateUrl: './delete-session-template.component.html',
  styleUrls: ['./delete-session-template.component.css']
})
export class DeleteSessionTemplateComponent implements OnInit {
  checkedUnderstandCheck:boolean = false;
  sessionTemplateId:string | null;
  success:boolean = false;
  constructor(private translateService:TranslateService, private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private sessionApiService:SessionApiService,private sessionTemplateService:SessionTemplateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param=>{
      this.sessionTemplateId = param['sessionTemplateId'];
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteSessionTemplate(){
    if(this.sessionTemplateId){
      this.sessionTemplateService.deleteSessionTemplate(this.sessionTemplateId).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
            this.success = true;
            return;
          }else{
            this.toastrService.error(this.translateService.instant("global.deletedError"));
          }
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }


  }

}
