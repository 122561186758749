<h5>Delete Installer Image</h5>
<hr>
<form *ngIf="imageList" [formGroup]="deleteForm">
    <div class="mb-3">
        <div class="form-check">
            <input class="form-check-input" formControlName="deleteLinkedVMs" type="checkbox" value=""
                id="deleteLinkedVMs">
            <label class="form-check-label" for="deleteLinkedVMs">
                Delete Linked VMs
            </label>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label" for="moveLinkedVMsTo">
            Move Linked VMs To
        </label>
        <select formControlName="moveLinkedVMsTo" class="form-select" aria-label="Default select example">
            <option [value]="null" selected>No Move</option>
            <option *ngFor="let image of imageList" [value]="image.value">{{image.label}}</option>
        </select>
    </div>
    <button (click)="deleteInstallerImage()" class="btn btn-danger btn-sm">Delete</button>
</form>