import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private toastrService:ToastrService,private translateService:TranslateService) { }
  formValidate(formValues:any,requiredFields:string[]){
    requiredFields.forEach(field=>{
      if(formValues[field] === undefined){
        this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));

        throw "";
      }
      if(formValues[field] == ""){
        this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));

        throw "";
      }
      if(typeof(formValues[field]) == "string"){
        if(formValues[field].trim() == ""){
          this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));

          throw "";
        }
      }
    })
  }
}
