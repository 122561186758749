import { Injectable } from '@angular/core';
import { TimelineModel } from 'animation-timeline-js';
import { VideoTimelineModel, VideoTimelineRowModel } from '../../models/Timeline/videoTimelineModel';

@Injectable({
  providedIn: 'root'
})
export class VideoTimelineService {

  constructor() { }
  createVideoTimelineModelByTimelineModel(timelineModel: TimelineModel, videoId: string) {
    var timelineRows: VideoTimelineRowModel[] = [];
    for (let rowIndex = 0; rowIndex < timelineModel.rows.length; rowIndex++) {
      const row = timelineModel.rows[rowIndex];
      //0 : section, 1:two-keyframe content 2: single-keyframe content
      var currentRow: VideoTimelineRowModel = { keyframes: [], type: rowIndex == 0 ? 0 : 1 };
      var keyframeNames: (string | undefined)[] = [];
      row.keyframes?.forEach(keyframe => {
        keyframeNames.push(keyframe.group?.toString());
      })
      keyframeNames = [...new Set(keyframeNames)];
      keyframeNames.forEach(keyframeName => {
        if (keyframeName != undefined) {
          var keyframes = this.getKeyframesWithGroupName(keyframeName, timelineModel);
          if (keyframes.length > 1) {
            var max = Math.max(keyframes[0].val, keyframes[1].val);
            var min = Math.min(keyframes[0].val, keyframes[1].val);
            currentRow.keyframes.push({
              id: this.generateGuid().substring(0, 8),
              name: keyframes[0].group,
              begin: min,
              end: max
            })
          }else{
            if(rowIndex != 0){
              currentRow.type = 2;
            }
            currentRow.keyframes.push({
              id: this.generateGuid().substring(0, 8),
              name: keyframes[0].group,
              begin: keyframes[0].val,
              end: undefined
            })
          }
        }
      })
      timelineRows.push(currentRow);
    }
    /*timelineModel.rows.forEach(row => {

    })*/
    var videoTimeline: VideoTimelineModel = {
      rows: timelineRows,
      videoId: videoId
    }
    return videoTimeline;
  }
  private getKeyframesWithGroupName(group: string, timelineModel: TimelineModel) {
    console.log(group);
    var keyframes: any[] = [];
    var model: any = timelineModel;
    var rowLen = model.rows.length;
    for (let rowIndex = 0; rowIndex < rowLen; rowIndex++) {
      const currentRow = model.rows[rowIndex];
      if (currentRow.keyframes.find((a: any) => a.group == group) != null) {
        var keyframeLength = currentRow.keyframes.length;
        for (let keyLen = 0; keyLen < keyframeLength; keyLen++) {
          const element = currentRow.keyframes[keyLen];
          if (element.group == group) {
            keyframes.push(element);
          }
        }
      }

    }
    console.log(keyframes);
    return keyframes;
  }
  private generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
