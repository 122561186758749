import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-application-list-table',
  templateUrl: './application-list-table.component.html',
  styleUrls: ['./application-list-table.component.css']
})
export class ApplicationListTableComponent implements OnInit {
  tableUrl:string = environment.apiUrl + "api/cms/admin/appdata/applications/list";
  constructor() { }

  ngOnInit(): void {
  }

}
