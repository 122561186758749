import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TerminalUserModel } from '../../models/RemoteClientModels/terminalUserModel';
import { TerminalMessageModel } from '../../models/RemoteClientModels/terminalMessageModel';

@Component({
  selector: 'lib-terminal-chat-sender',
  templateUrl: './terminal-chat-sender.component.html',
  styleUrls: ['./terminal-chat-sender.component.css']
})
export class TerminalChatSenderComponent implements OnInit {
  @Input() userList:TerminalUserModel[] = [];
  @Output() message = new EventEmitter<TerminalMessageModel>();
  @Input() messageHistory:TerminalMessageModel[] = [];
  selectedUser:TerminalUserModel | null = null;
  inputMessage:string = "";
  constructor() { }
  ngOnChanges(changes:SimpleChanges){
    //this.selectedUser = null;
    this.userList = [...this.userList];
    console.log("degisiklik algilandi")
    this.messageHistory = [...this.messageHistory];
  }
  ngOnInit(): void {

  }
  clearTextarea(){
    let textarea = document.getElementById('sendTextarea') as HTMLTextAreaElement;
    textarea.value = "";
  }
  goUserList(){
    this.selectedUser = null;
  }
  selectUser(user:TerminalUserModel){
    this.selectedUser = user;
  }
  sendMessage(){
    if(this.selectedUser == null){
      return;
    }else{
      let textarea = document.getElementById('sendTextarea') as HTMLTextAreaElement;
      this.inputMessage = textarea.value;
      if(this.inputMessage.trim().length > 0){
        this.message.emit({userId:this.selectedUser.userId,message:this.inputMessage});
        this.clearTextarea();
        this.inputMessage = "";
      }

    }

  }
  getMessages(){
    return this.messageHistory.filter(x=>x.userId == this.selectedUser?.userId);
  }

}
