import { Component, OnInit } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { DynamicFormToJsonElementTypesModel } from '../../../models/DynamicFormToJsonModels/dynamicFormToJsonElementTypes';
import { VirtualMachineModel } from '../../../models/VirtualLabModels/virtualMachineModel';
import { ActivatedRoute } from '@angular/router';
import { UpdateVirtualMachineModel } from '../../../models/VirtualLabModels/updateVirtualMachine';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';

@Component({
  selector: 'lib-update-virtual-machine',
  templateUrl: './update-virtual-machine.component.html',
  styleUrls: ['./update-virtual-machine.component.css']
})
export class UpdateVirtualMachineComponent implements OnInit {

  constructor(private vmInstanceService: VmInstanceService, private virtualLabService: VirtualLabService, private toastrService: ToastrService, private activatedRoute: ActivatedRoute) { }
  ramList: ListItemModel[];
  cpuList: ListItemModel[];
  providers: ListItemModel[] = [];
  instanceTypes: ListItemModel[];
  showSelects: boolean;
  instanceTypeId: string;
  hosts: ListItemModel[] = [];
  vmId: string;
  virtualMachine: VirtualMachineModel;
  elementList: DynamicFormToJsonElementTypesModel[] = [];
  disksWithData: DynamicFormToJsonElementTypesModel[] = [];
  nicElementList: DynamicFormToJsonElementTypesModel[] = [];
  nicWithData: DynamicFormToJsonElementTypesModel[] = [];
  diskJson: string;
  nicJson: string;
  allowCustomSelect:boolean = true;
  customSelect: boolean = false;
  ngOnInit(): void {
    this.createElementList();
    this.createNicElementList();
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.vmId = param["vmId"];
        this.getRamList();
        this.initializeInstanceTypes();
      }
    })

  }
  createElementList() {
    this.elementList.push({ typeName: "disk", typeText: "Yeni Disk Ekle", element: [{ name: "diskName", label: "Disk Adı", valueType: "string" }, { name: "diskSize", min: 0, max: 1000, label: "Disk Boyutu (GB)", valueType: "number" }] });
  }
  createNicElementList() {
    this.nicElementList.push({ typeName: "nic", typeText: "Yeni Ağ Kartı Ekle", element: [{ name: "nicName", label: "Tanımlayıcı Ad", valueType: "string" }, { name: "settings", label: "Ayarlamalar", valueType: "textarea" }] });
  }
  createDiskData() {
    var diskData: DynamicFormToJsonElementTypesModel[] = [];
    var parsedDisk = JSON.parse(this.virtualMachine.disks) as any;
    parsedDisk.forEach((data: any) => {
      console.log(data);
      diskData.push({ typeName: data.diskName, typeText: "Yeni Disk Ekle", element: [{ name: "diskName", defaultValue: data.diskName, label: "Disk Adı", valueType: "string" }, { name: "diskSize", min: 0, max: 1000, defaultValue: data.diskSize, label: "Disk Boyutu (GB)", valueType: "number" }] });
    })
    this.disksWithData = diskData;

  }
  async initializeInstanceTypes(){
    await this.getProviderList();
    await this.getHostList(this.providers[0].value);
    this.getInstanceTypes(this.providers[0].value);
  }
  createNicData() {
    var nicData: DynamicFormToJsonElementTypesModel[] = [];
    var parsedNic = JSON.parse(this.virtualMachine.nicCount) as any;
    parsedNic.forEach((data: any) => {
      console.log(data);
      nicData.push({ typeName: data.nicName, typeText: "Yeni Ağ Kartı Ekle", element: [{ name: "nicName", defaultValue: data.nicName, label: "Tanımlayıcı Ad", valueType: "string" }, { name: "settings", defaultValue: data.settings, label: "Ayarlamalar", valueType: "textarea" }] });
    })
    this.nicWithData = nicData;

  }
  changeProvider() {
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    this.getHostList(providerSelect.value);
    this.getInstanceTypes(providerSelect.value);
  }
  getProviderList(): Promise<boolean> {
    return new Promise((res, rej) => {
      this.vmInstanceService.getProviderList().subscribe({
        next: (response) => {
          this.providers = response.content;
          res(true);
        }, error: (err) => {
          res(false);
        }
      })
    })
  }
  getHostList(provider: string): Promise<boolean> {
    return new Promise((res, rej) => {
      this.vmInstanceService.getHostList(provider).subscribe({
        next: (response) => {
          this.hosts = response.content;
          res(true);
        }, error: (err) => {
          res(false);
        }
      })
    })
  }
  changeHost() {
    var providerSelect = document.getElementById("providerType") as HTMLInputElement;
    var hostSelect = document.getElementById("hostId") as HTMLInputElement;
    this.getInstanceTypes(providerSelect.value, hostSelect.value);
  }
  getInstanceTypes(provider: string, host: string | undefined | null = null): Promise<boolean> {
    return new Promise((res, rej) => {
      this.vmInstanceService.getVmInstanceTypesWithProviderTypeAndHost(provider, host).subscribe({
        next: (response) => {
          this.instanceTypes = response.content;
          this.instanceTypeId = response.content[0].value
          var hostSelect = document.getElementById("instanceType") as HTMLSelectElement;
          hostSelect.value = response.content[0].value;
          this.showSelects = true;
          res(true);
        }, error: (err) => {
          res(false);
        }
      })
    })
  }
  changeInstanceType() {
    var hostSelect = document.getElementById("instanceType") as HTMLInputElement;
    this.instanceTypeId = hostSelect.value;
  }
  changeCustomSelectSwitch($event: any) {
    this.customSelect = ($event.target as HTMLInputElement).checked;
  }
  getVm() {
    this.virtualLabService.getVirtualMachine(this.vmId).subscribe({
      next: (response) => {
        this.virtualMachine = response.content;
        this.customSelect = response.content.vmInstanceTypeId == "custom" ? true : false;
        setTimeout(() => {
          //this.prepareForm();
          this.createDiskData();
          this.createNicData();

        }, 50)

      }, error: () => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getRamList() {
    this.virtualLabService.getRamList().subscribe({
      next: (response) => {
        this.ramList = response.content;
        this.getCPUList();
      }, error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getCPUList() {
    this.virtualLabService.getCPUList().subscribe({
      next: (response) => {
        this.cpuList = response.content;
        this.getVm();
      }, error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  saveDisks(ev: any) {
    this.diskJson = ev;
  }
  saveNic(ev: any) {
    this.nicJson = ev;
  }
  prepareForm() {
    var nameInput = document.getElementById("name") as HTMLInputElement;
    nameInput.value = this.virtualMachine.name;
  }
  updateVM() {
    var name = document.getElementById("name") as HTMLInputElement;
    var providerId = (document.getElementById("providerType") as HTMLInputElement).value;
    var hostSelect = document.getElementById("hostId") as HTMLInputElement;
    var cpuCount =undefined;
    var ram = undefined;
    if (this.customSelect) {
      ram = (document.getElementById("ram") as HTMLSelectElement).value;
      cpuCount = (document.getElementById("cpuCount") as HTMLSelectElement).value;
    }

    var nic = this.nicJson;
    var disks = this.diskJson;
    var updateVMModel: UpdateVirtualMachineModel = {
      name: name.value,
      ram: ram,
      cpuCount: cpuCount == undefined ? undefined : parseInt(cpuCount.toString()),
      vmInstanceTypeId : this.customSelect ? "custom" : this.instanceTypeId,
      providerId: providerId,
      hostId : hostSelect.value == "undefined" ? undefined : hostSelect.value,
      disks: disks,
      nicCount: nic,
      id: this.virtualMachine.id
    }

    this.virtualLabService.updateVirtualMachine(updateVMModel).subscribe({
      next: (response) => {
        this.toastrService.success("Sanal makine güncellendi");
      }, error: (err) => {
        this.toastrService.error("Sanal makine güncellenirken bir hata oluştu");
      }
    })
    console.log(updateVMModel);
  }
  parseInteger(j: any) {
    return parseInt(j.toString());
  }

}
