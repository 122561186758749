import { ChartModel } from './../../../models/CustomChartModels/chartModel';
import { MenuItems } from 'projects/usorta-library/src/lib/models/menu/menuItems';
import { environment } from './../../../../environments/environment';

import { Component, Input, OnInit } from '@angular/core';
import { BlockLayoutModel } from '../../../models/Layout/BlockLayoutModel';

@Component({
  selector: 'lib-custom-layout-block',
  templateUrl: './custom-layout-block.component.html',
  styleUrls: ['./custom-layout-block.component.css']
})
export class CustomLayoutBlockComponent implements OnInit {
  @Input() dataUrl:string = "";
  @Input() externalContent:string = ""; //custom block componenti için içerik
  @Input() externalType:string = "text/html"; //custom block componenti için content-type
  @Input() externalMenuData:MenuItems[]; //custom menu componenti için içerik
  @Input() externalChartModel:ChartModel; //custom-chart componenti için tablo verisi
  constructor() { }
  layoutBlock:BlockLayoutModel

  ngOnInit(): void {
    this.dataUrl = this.layoutBlock.params.dataUrl != "" ? environment.apiUrl + this.layoutBlock.params.dataUrl : "";
    this.externalContent = this.layoutBlock.params.externalContent ?? "";
    console.log("this.layoutBlock.params.externalType");
    console.log(this.layoutBlock.params.externalType);
    this.externalType = this.layoutBlock.params.externalType ?? "";
    this.externalMenuData = this.layoutBlock.params.externalMenuData ?? [];
    this.externalChartModel = this.layoutBlock.params.chartData ?? {};
  }

}
