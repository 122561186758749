import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { ToastrService } from 'ngx-toastr';
import { InstallerImageUpdateInfoModel } from '../../../models/VirtualLabModels/installerImageUpdateInfoModel';
import { ActivatedRoute } from '@angular/router';
import { UpdateInstallerImageModel } from '../../../models/VirtualLabModels/updateInstallerImageModel';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-update-installer-image',
  templateUrl: './update-installer-image.component.html',
  styleUrls: ['./update-installer-image.component.css']
})
export class UpdateInstallerImageComponent implements OnInit {

  installerImageId: string;
  formUrl:string;
  apiUrl:string = this.apiUrlService.getApiUrl();
  constructor(private activatedRoute: ActivatedRoute,private apiUrlService:ApiUrlService, private virtualLabService: VirtualLabService, private formBuilder: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.installerImageId = param["imageId"];
        this.formUrl = this.apiUrlService.getApiUrl() + "api/range/vm/installerimages/"+this.installerImageId+"/update"
      }
    })
  }



}
