import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'app-create-service-account',
  templateUrl: './create-service-account.component.html',
  styleUrls: ['./create-service-account.component.css']
})
export class CreateServiceAccountComponent implements OnInit {
  createFormUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.createFormUrl = environment.apiUrl + "api/accounts/service-account/create";
  }

}
