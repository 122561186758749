import { Component, OnInit } from '@angular/core';
import '../../../assets/usortaEditor/src/lib/lms-layout-editor/js/usorta-editor.js';
declare var $:any;
declare var window:any;
@Component({
  selector: 'app-signee-editor',
  templateUrl: './signee-editor.component.html',
  styleUrls: ['./signee-editor.component.css']
})

export class SigneeEditorComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      window.initEditor($('.signees-editor'),{});
    },1000)

  }

}
