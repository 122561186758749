import { Component, OnInit } from '@angular/core';
import { MonacoService } from '../../../services/monacoService/monaco.service';
import { ToastrService } from 'ngx-toastr';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ActivatedRoute } from '@angular/router';
import { HostProviderTypeUpdateInfoModel } from '../../../models/HostProviderModels/hostProviderTypeUpdateInfoModel';
import { UpdateHostProviderTypeRequestModel } from '../../../models/HostProviderModels/updateHostProviderTypeRequest';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '../../../services/formService/forms.service';
declare var $:any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-update-host-provider-type',
  templateUrl: './update-host-provider-type.component.html',
  styleUrls: ['./update-host-provider-type.component.css']
})
export class UpdateHostProviderTypeComponent implements OnInit {
  editorType:string = "json";
  typeId:string;
  providerTypeUpdateInfo:HostProviderTypeUpdateInfoModel;
  constructor(private formsService:FormsService, private translateService:TranslateService,private activatedRoute:ActivatedRoute,private monacoService:MonacoService,private toastrService:ToastrService,private hostProviderService:HostProviderService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:async (param)=>{
        this.typeId = param["typeId"]; 
        await this.getUpdateModel();
        this.findEditorType(this.providerTypeUpdateInfo.defaultConfiguration);
        this.initializeDefaultConfigurationEditor();
        this.initInputs();
      }
    })

  }
  getUpdateModel():Promise<boolean>{
    return new Promise((resolve,reject)=>{
      this.hostProviderService.getHostProviderTypeUpdateInfoModel(this.typeId).subscribe({
        next:(response)=>{
          this.providerTypeUpdateInfo = response.content;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
          resolve(false);
        }
      })
    })

  }
  findEditorType(str:string){
    //Json
    try {
      JSON.parse(str);
      this.editorType = "json";
      return;
    }catch{

    }
    try{
      var parser = new DOMParser();
      const doc = parser.parseFromString(str, "application/xml");
      const errorNode = doc.querySelector("parsererror");
      if (!errorNode) {
        this.editorType = "xml";
        return;
      }
    }catch{

    }
    this.editorType = "yaml";
    
    return;
  }
  changeEditorLanguage(lang:string){
    this.editorType = lang;
    monaco.editor.setModelLanguage(window["defaultConfigurationEditor"].getModel(),lang);
  }
  initializeDefaultConfigurationEditor(){
    this.monacoService.disposeAllModels();
    console.log(monaco.editor.getModels());
    var defaultConfiguration = document.getElementsByClassName("defaultConfigurationMonaco")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(defaultConfiguration,{
        language:this.editorType,
        minimap:{enabled:false},
        value: this.providerTypeUpdateInfo.defaultConfiguration
      })
      window["defaultConfigurationEditor"] = editor;
    }
  }
  checkErrors(){
    var def = window["defaultConfigurationEditor"].getValue()
     if(def == undefined || def == ""){
       return true;
     }
     switch(this.editorType){
       case "json":
           try {
             var removedRN = this.monacoService.jsonString(def);
             return true;
           }catch{
             this.toastrService.error(this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidJson"));
             throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidJson");
           }
         break;
       case "yaml":
           return true;
         break;
       case "xml":
         try{
           var parser = new DOMParser();
           const doc = parser.parseFromString(def, "application/xml");
           const errorNode = doc.querySelector("parsererror");
           if (errorNode) {
             this.toastrService.error(this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml"));
             throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml");
           }
           return true;
         }catch{
           throw this.translateService.instant("hostprovidertype.defaultConfigurationIsNotValidXml");
         }
 
         
         break;
     }
     return false;
 
   }
   initInputs(){
    var name = (document.getElementById("name") as HTMLInputElement);
    var serviceClassName = (document.getElementById("serviceClassName") as HTMLInputElement);
    var enabled = (document.getElementById("enabled") as HTMLInputElement);
    var description = (document.getElementById("description") as HTMLInputElement);
    var defaultConfiguration = window["defaultConfigurationEditor"];
    name.value = this.providerTypeUpdateInfo.name;
    serviceClassName.value = this.providerTypeUpdateInfo.serviceClassName;
    enabled.checked = this.providerTypeUpdateInfo.enabled;
    description.value = this.providerTypeUpdateInfo.description;
   }
  updateHostProviderType(){
    var name = (document.getElementById("name") as HTMLInputElement).value;
    var serviceClassName = (document.getElementById("serviceClassName") as HTMLInputElement).value;
    var enabled = (document.getElementById("enabled") as HTMLInputElement).checked;
    var description = (document.getElementById("description") as HTMLInputElement).value;
    var defaultConfiguration = window["defaultConfigurationEditor"].getValue();
    this.checkErrors();
    var defaultConfigurationValue = "";
    switch(this.editorType){
      case "json":
        defaultConfigurationValue = this.monacoService.jsonString(defaultConfiguration);
        break;
      case "yaml":
        defaultConfigurationValue = this.monacoService.newlineOnlyN(defaultConfiguration);
        break;
      case "xml":
        defaultConfigurationValue = this.monacoService.newlineOnlyN(defaultConfiguration);
        break;
    }
    console.log(defaultConfigurationValue);
    var updateModel:UpdateHostProviderTypeRequestModel = {
      typeId:this.typeId,
      enabled:enabled,
      name:name,
      serviceClassName:serviceClassName,
      defaultConfiguration:defaultConfigurationValue,
      description:description
    }
    this.formsService.formValidate(updateModel,["name","serviceClassName","typeId"]);
    this.hostProviderService.updateHostProviderType(updateModel).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("global.updateSuccessfull"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
