import { Component, OnDestroy, OnInit } from '@angular/core';
import { GuacAuthService, GuacConnectionInfo } from '@usorta-packages/guac-library';
import { GuacamoleService } from '../../../services/guacamole/guacamole.service';
import { ActivatedRoute } from '@angular/router';
import { SetupVmInstanceService } from '../../../services/setupVmInstance/setup-vm-instance.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SetupVmInstanceSpecsResponse } from '../../../models/setupVMInstanceModels/setupVmInstanceSpecsResponse';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { RangeSocketService } from '../../../services/rangeSocket/range-socket.service';

@Component({
  selector: 'lib-manage-setup-vm-instance',
  templateUrl: './manage-setup-vm-instance.component.html',
  styleUrls: ['./manage-setup-vm-instance.component.css']
})
export class ManageSetupVmInstanceComponent implements OnInit,OnDestroy {
  setupInstanceId:string;
  baseVmId:string;
  jwt?:string = undefined;
  vmName?:string = undefined;
  openVm:boolean = false;
  machineName:string;
  showNote:boolean = false;
  specs:SetupVmInstanceSpecsResponse;
  start:boolean;

  constructor(private rangeSocketService:RangeSocketService,private guacAuthService:GuacAuthService,private okCancelEventService:GlobalOkCancelEventService,private setupVmInstanceService:SetupVmInstanceService,private globalModalService:GlobalModalService,private toastrService:ToastrService,private translateService:TranslateService,private setupInstanceService:SetupVmInstanceService,private guacamoleService:GuacamoleService,private activatedRoute:ActivatedRoute) { }
  ngOnDestroy(): void {
    console.log("vm page closed");
  }

  ngOnInit(): void {
    let guacConnectionInfo: GuacConnectionInfo = {
      url: `//${window.location.hostname}`,
      wsUrl: `ws://${window.location.hostname}`,
      //url: '//10.8.0.19:8080',
      //wsUrl: 'wss://10.8.0.19:8080',
      signalRSocket: '/hubs/session',
      mainPath: '/guacamole/',
    };
    localStorage.setItem('GUAC_AUTH', '{"authToken":""}');
    this.guacAuthService.setGuacamoleParams(guacConnectionInfo);
    this.getBaseVmId();
    this.getSetupVmInstaceSpecs();
    setTimeout(()=>{
      this.start = true;
      this.guacamoleService.getGuacamoleUserToken({expiresIn:3600}).subscribe({
        next:(response)=>{
          this.jwt = response.content;
          console.log(this.jwt);
        },error:(err)=>{
          this.toastrService.error("Terminal Bağlantı Hatası")
        }
      })

    },2000);
  }
  getSetupVmInstaceSpecs(){
    this.setupInstanceService.getSetupVmInstanceSpecs(this.setupInstanceId).subscribe({
      next:(response)=>{
        if(response.content.targetBaseVMImageId === undefined || response.content.targetBaseVMImageId == null){
          this.buildSetupVmInstance();
        }else{
          this.specs = response.content;
          this.baseVmId = this.specs.targetBaseVMImageId ?? "";
          this.rangeSocketService.getHub().then(x=>{
            x.on("VMSessionJoined",(a)=>{
              console.log("YANIT GELDİ");
              console.log(a);
            })
            x.on("VMStatusChange",(sessionId,vmId,status)=>{
              console.log(sessionId,vmId,status);
            })
            x.on("VMTerminalConnectionAvailable",(data)=>{
              if(this.setupInstanceId == data["vmId"]){
                this.vmName = data["connectionName"]
              }

            })
            x.on("VMTerminalConnectionClosed",(data)=>{
              if(this.setupInstanceId == data["vmId"]){
              this.vmName = undefined
              }
            })
            console.log(this.specs.sessionId);
            x.invoke("JoinVMSession",{sessionId:this.specs.sessionId});

          })
        }
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  buildSetupVmInstance(){
    this.setupInstanceService.buildSetupVmInstance(this.setupInstanceId).subscribe({
      next:(response)=>{
        if(response.success){
          this.getSetupVmInstaceSpecs();
        }else{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          
        }
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        console.error(err);
      }
    })
  }
  getBaseVmId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.setupInstanceId = param["setupInstanceId"];
      }
    })
  }
  showNoteItems(){
    this.showNote = true;
  }
  showInfoItems(){
    this.showNote = false;
  }
  /*guacamoleAuth(){
    var serviceJwt = sessionStorage.getItem("token");
    if(serviceJwt != null){
      this.guacAuthService.authenticate({data:serviceJwt}).subscribe({
        next:(response)=>{
          
        },error:(err)=>{

        }
      })
    }

  }*/


  shutdownVm() {
    if (this.setupInstanceId == null || this.setupInstanceId == '') {
      this.toastrService.error("global.errorOccureed");
      return;
    }
    this.okCancelEventService.showOkCancelWindow(this.translateService.instant("setupVmInstance.shutdownVmModalTitle"), this.translateService.instant("setupVmInstance.shutdownMessage"), true, undefined, undefined
      , false, undefined).then(x => {
        if (x == true) {
          if (this.setupInstanceId != null) {
            this.setupVmInstanceService.shutdownVm({ setupVMId: this.setupInstanceId ?? "" }).subscribe({
              next: (response) => {
                this.toastrService.success(this.translateService.instant("setupVmInstances.shutdownProcessMessage"));
              }
            })
          }

        }
      });
  }
  restartVm() {
    if (this.setupInstanceId == null || this.setupInstanceId == '') {
      this.toastrService.error("global.errorOccureed");
      return;
    }
    this.okCancelEventService.showOkCancelWindow(this.translateService.instant("setupVmInstance.restartVmModalTitle"), this.translateService.instant("setupVmInstance.restartMessage"), true, undefined, undefined
      , false, undefined).then(x => {
        if (x == true) {
          if (this.setupInstanceId != null) {
            this.setupVmInstanceService.restartVm({ setupVMId: this.setupInstanceId ?? "" }).subscribe({
              next: (response) => {
                this.toastrService.success(this.translateService.instant("setupVmInstances.restartProcessMessage"));
              }
            })
          }

        }
      });
  }
  startVm() {
    if (this.setupInstanceId != null) {
      this.setupVmInstanceService.startVm({ setupVMId: this.setupInstanceId ?? "" }).subscribe({
        next: (response) => {
          this.toastrService.success(this.translateService.instant("setupVmInstances.startProcessMessage"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.errorOccurred"));
    }
  }

  openClonePage() {
    this.globalModalService.showModal({
      data: `/modal/range/vm/setupinstance/${this.setupInstanceId}/clone`,
      helperModals: [],
      title: this.translateService.instant("setupVmInstance.cloneModalTitle"),
      type: ModalTypes.LINK
    })
  }
  openPublishPage() {
    this.globalModalService.showModal({
      data: `/modal/range/vm/setupinstance/${this.setupInstanceId}/publish`,
      helperModals: [],
      title: this.translateService.instant("setupVmInstance.publishModalTitle"),
      type: ModalTypes.LINK
    })
  }
  openTerminal(){
    var newwindow=window.open(`/modal/range/setupvminstances/connect/${this.jwt}/${this.vmName}`,'name','height=600,width=800');
    if(newwindow != null){
      newwindow.focus();
    }
  }


}
