<div class="add-course-translation-form">
    <form *ngIf="loaded" class="form-maroon-border form-default-border formMain" [formGroup]="updateCourseTranslationForm">
        <div class="mb-3">
            <label for="name" class="form-label mainLabel">{{'courseManagement.courseName' | translate}}</label>
            <input type="text" class="form-control" formControlName="name" id="name" name="name"
                [placeholder]="'courseManagement.courseName' | translate">
        </div>
        <div class="mb-3">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="active" value="" name="active" id="active">
                <label class="form-check-label mainLabel"  for="active">
                    {{'courseManagement.enableCourse' | translate}}
                </label>
            </div>
        </div>
        <div class="mb-3">
            <label for="localeId" class="mainLabel">Dil Seçimi</label>
            <select formControlName="localeId" class="form-select" id="localeId" name="localeId">
                <option *ngFor="let language of languageList" [value]="language.value">{{language.label}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="image" class="form-label mainLabel">{{'courseManagement.catalogImage' | translate}}</label>
            <input class="form-control" (change)="changeImage($event)" type="file" id="image">
        </div>

        <image-cropper [output]="'base64'" [imageChangedEvent]="imageChangedEvent" format="png"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" [cropperStaticHeight]="150"
            [cropperStaticWidth]="250"></image-cropper>
        
            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="deleteImage" value="" name="deleteImage" id="deleteImage">
                    <label class="form-check-label mainLabel"  for="deleteImage">
                        {{'courseManagement.deleteImage' | translate}}
                    </label>
                </div>
            </div>

        <div class="mb-3">
            <label for="teaserImage" class="form-label mainLabel">{{'courseManagement.detailImage' | translate}}</label>
            <input class="form-control" (change)="changeTeaserImage($event)" type="file" id="teaserImage">
        </div>
        <image-cropper [output]="'base64'" [autoCrop]="true" [imageChangedEvent]="teaserImageChangedEvent" format="png"
            (imageCropped)="teaserImageCropped($event)" (imageLoaded)="teaserImageLoaded($event)"
            [cropperStaticHeight]="695" [cropperStaticWidth]="1043"></image-cropper>

            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="deleteTeaserImage" value="" name="deleteTeaserImage" id="deleteTeaserImage">
                    <label class="form-check-label mainLabel"  for="deleteTeaserImage">
                        {{'courseManagement.deleteTeaserImage' | translate}}
                    </label>
                </div>
            </div>
        
        <div class="mb-3">
            <label  for="primaryVersionId" class="form-label mainLabel">{{'courseManagement.primaryVersionId' | translate}}</label>
            <input formControlName="primaryVersionId" type="text" class="form-control" id="primaryVersionId" name="primaryVersionId"
                [placeholder]="'courseManagement.primaryVersionId' | translate">
        </div>
        <div class="mb-3">
            <label for="initialVersionId" class="form-label mainLabel">{{'courseManagement.initialVersionId' | translate}}</label>
            <input formControlName="initialVersionId" type="text" class="form-control" id="initialVersionId" name="initialVersionId"
                [placeholder]="'courseManagement.initialVersionId' | translate">
        </div>
        <div class="mb-3">
            <label for="themeId" class="mainLabel">Tema Seçimi</label>
            <select formControlName="themeId" class="form-select" id="themeId" name="themeId">
                <option [value]="''">Not Selected</option>
                <option *ngFor="let theme of themeList" [value]="theme.value">{{theme.label}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label class="mainLabel" for="teaserText">Teaser Text</label>
            <textarea class="form-control" id="teaserText" formControlName="teaserText" name="teaserText"></textarea>
        </div>
        <div class="mb-3">
            <label for="format" class="form-label mainLabel">Format</label>
            <select (change)="changeDescriptionFormat($event)" class="form-select" id="format" name="format" formControlName="descriptionFormat">
                <option [value]="0">PlainText</option>
                <option [value]="1">HTML</option>
                <option [value]="2">Markdown</option>
                <option [value]="3">Json</option>
                <option [value]="4">Xml</option>
                <option [value]="5">Layout</option>
            </select>
        </div>
        <lib-typed-strings [defaultValue]="defaultDescription" [defaultType]="defaultType" [inputType]="descriptionInputTypes" (getValue)="getDescriptionValueEvent($event)"></lib-typed-strings>
        <button class="btn btn-sm btn-primary" (click)="updateTranslation()">{{'btn.update' | translate}}</button>
    </form>
</div>