import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EducationModel } from 'projects/usorta-library/src/lib/models/Education/educationModel';
import { ListItemModel } from 'projects/usorta-library/src/lib/models/ListItem/listItemModel';
import { EducationService } from 'projects/usorta-library/src/lib/services/education/education.service';

@Component({
  selector: 'lib-education-details-basic',
  templateUrl: './education-details-basic.component.html',
  styleUrls: ['./education-details-basic.component.css']
})
export class EducationDetailsBasicComponent implements OnInit {

  constructor(private translateService:TranslateService,private educationService:EducationService) { }
  @Input() educationModel:EducationModel;
  @Input() categoryTreeId:number;
  categories:ListItemModel[] = [];
  ngOnInit(): void {
    this.getCategories();
  }
  getCategories(){
    this.educationService.getCategories(this.categoryTreeId).subscribe({
      next:(response)=>{
        this.categories = response.content;
      }
    })
  }
  getCategoryName(val:number[]){
    var categoryNames:string[] = [];
    val.forEach(num=>{
      categoryNames.push(this.categories.find(x=>x.value == num)?.label ?? "");
    })
    return categoryNames;
  }
  dateParse(){
    let kvpMap:Map<string,string> = new Map<string,string>();
    kvpMap.set('M','datetime.month');
    kvpMap.set('w','datetime.week');
    kvpMap.set('d','datetime.day');
    kvpMap.set('h','datetime.hour');
    kvpMap.set('m','datetime.minute');
    let dateString = "";
    var datetime = this.educationModel.courseLength;
    var reg = datetime.match(/\d+[dhmwM]/g) as any;
    console.log("reg");
    console.log(reg);
    let mSelect = 0;
    let paramlist = ['M','w','d','h','m'];
    for(let i=0;i<paramlist.length;i++){
      reg.forEach((data:string)=>{
        let ends = data[data.length-1];
        if(ends === paramlist[mSelect]){
          console.log(paramlist[mSelect]);
          let langParam = kvpMap.get(paramlist[mSelect]) ?? paramlist[mSelect];
          console.log(data.substring(0,data.length-1)+this.translateService.instant(langParam));
          dateString += data.substring(0,data.length-1)+" "+this.translateService.instant(langParam)+" ";
        }
      })
      mSelect++;
      console.log(mSelect);
    }
    return dateString;
  }
  getEducationLevel(){
    let level = this.translateService.instant("courseLevel."+this.educationModel.level);
    return level;
  }

}
