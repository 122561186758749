<div class="sessionLoaderMain">
  <div class="sessionLoader">
  <div class="logo">
    <img *ngIf="logo" class="imglogo" [src]="logo">
  </div>
  <div *ngIf="error[0]==true" class="errorDiv">
    <p >Oturum Bağlantısı Kurulamadı</p>
    <button (click)="startSocketFnc()" class="btn btn-sm btn-danger">Tekrar Dene</button>
  </div>
  <div *ngIf="status[0]==2" class="errorDiv">
    <p >Oturum Kapalı</p>
  </div>
  <div *ngIf="status[0]==0" class="errorDiv">
    <p>Eğitmenin oturumu başlatması bekleniyor.</p>
  </div>
  <div *ngIf="error[0]==false && status[0] != 2" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>


</div>
</div>
