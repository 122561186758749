import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomFormJsonValidationModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/customFormJsonValidationModel';
import { CustomFormJsonInputAndValidateModel, RadioInputModel, TextInputModel } from 'projects/usorta-library/src/lib/models/CustomFormJson/inputModel';
import { CustomForm } from 'projects/usorta-library/src/lib/models/CustomFormModels/customForm';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { FeatureType } from 'projects/usorta-library/src/lib/models/SessionTemplateModels/featureType';
import { CustomFormService } from 'projects/usorta-library/src/lib/services/customForm/custom-form.service';
import { CustomFormJsonService } from 'projects/usorta-library/src/lib/services/customFormJson/custom-form-json.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { SessionTemplateService } from 'projects/usorta-library/src/lib/services/session/session-template.service';
declare var JSONEditor: any;
declare var $: any;
declare var monaco: any;
declare var window:any;
@Component({
  selector: 'app-update-feature-type',
  templateUrl: './update-feature-type.component.html',
  styleUrls: ['./update-feature-type.component.css']
})
export class UpdateFeatureTypeComponent implements OnInit {

  requestForm: boolean[] = [false];
  savedFormValues: { customId: string, valueName: string, valueType: string, defaultValue?: any,required:boolean }[] = [];
  jsonEditorValue: string = "";
  monacoJsonEditor:any;
  saveJsonBtn = document.createElement('button');
  reqResponse:any = null;
  selectedFeatureId:string;
  selectedFeature:FeatureType;
  updateForm:CustomForm;
  currentFormGroup:FormGroup;
  formPrepared=false;
  formBottomInput:HTMLElement |undefined = undefined;
  constructor(private translateService:TranslateService,private customFormService:CustomFormService ,private activatedRoute:ActivatedRoute, private customFormJsonService: CustomFormJsonService, private globalModalService: GlobalModalService,private sessionTemplateService:SessionTemplateService,private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.formBottomInput = document.getElementById("updateFeatureConfig") as HTMLElement;
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.selectedFeatureId = param["featureId"];
        this.getFeatureType(param["featureId"]);
      }
    })

    document.addEventListener("change", (ev) => {
      if ($(ev.target).hasClass("configurationType")) {
        var sel = ev.target as HTMLSelectElement;
        console.log("degisti")
        if (sel.value == "boolean") {
          this.defaultOptionVal(sel.getAttribute("data-id") ?? "")
        } else if (sel.value == "string") {
          this.defaultStringVal(sel.getAttribute("data-id") ?? "")
        }
      }
    })
    this.saveJsonEventListener();
  }
  getResponse($ev:any){
    this.reqResponse = $ev;
  }
  getFeatureType(id:string){
    this.sessionTemplateService.getFeatureType(id).subscribe({
      next:(response)=>{
        this.selectedFeature = response.content;
        this.getForm();
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  getForm(){
    this.customFormService.getForm("updateFeatureType").subscribe({
      next:(response)=>{
        response.action = response.action.replace("[id]",this.selectedFeatureId);
        this.updateForm = response;
        this.createConfigurationsFromCustomJson(this.selectedFeature.defaultConfiguration);
        setTimeout(()=>{
          this.prepareForm();
        },100);

      },
      error:(err)=>{

      }
    })
  }
  prepareForm(){
    this.requestForm = [true];
  }
  getFormGroup(ev: FormGroup<any>) {

    this.currentFormGroup = ev;
    if(this.formPrepared == false){
      ev.get("name")?.setValue(this.selectedFeature.name);
      this.formPrepared = true;
    }
    ev.get("defaultConfiguration")?.setValue(this.getJsonValue());
    ev.get("configurationSchema")?.setValue(this.createFormElements());
  }
  createNewConfiguration() {
    var customId = (Math.random() + 1).toString(36).substring(7);
    var confDiv = document.createElement('div');
    confDiv.classList.add("configuration");
    confDiv.classList.add("conf_" + customId);
    confDiv.setAttribute("data-id",customId);
    var nameLabel = document.createElement("label");
    nameLabel.innerHTML = this.translateService.instant("global.configurationName");
    var configurationName = document.createElement('input');
    configurationName.classList.add("configurationName", "cn_" + customId);
    configurationName.setAttribute("data-id", customId);
    configurationName.classList.add("form-control");
    var typeLabel = document.createElement("label");
    typeLabel.innerHTML = this.translateService.instant("global.configurationType");
    var configurationType = document.createElement('select');
    configurationType.classList.add("configurationType", "ct_" + customId)
    configurationType.setAttribute("data-id", customId);
    configurationType.classList.add("form-control");
    var stringOption = document.createElement('option');
    stringOption.value = "string";
    stringOption.innerHTML = "String";
    var boolOption = document.createElement('option');
    boolOption.value = "boolean";
    boolOption.innerHTML = "Boolean";
    configurationType.appendChild(stringOption);
    configurationType.appendChild(boolOption);
    nameLabel.appendChild(configurationName);
    typeLabel.appendChild(configurationType);
    confDiv.appendChild(nameLabel);
    confDiv.innerHTML += "&nbsp;&nbsp;"
    confDiv.appendChild(typeLabel);
    console.log(confDiv)
    confDiv.innerHTML += "&nbsp;&nbsp;"
    document.getElementById("configurations")?.append(confDiv);
    this.defaultStringVal(customId);
    return confDiv;
  }
  defaultStringVal(id: string) {
    if (document.getElementsByClassName("vl_" + id).length > 0) {
      document.getElementsByClassName("vl_" + id)[0].remove();
    }
    var valueDiv = document.createElement('div');
    valueDiv.style.display = "inline-block";
    valueDiv.classList.add("vl_" + id);
    var valueLabel = document.createElement("label");
    valueLabel.innerHTML = this.translateService.instant("global.configurationDefaultValue");
    var configurationValue = document.createElement('input');
    configurationValue.classList.add("configurationValue", "cv_" + id);
    configurationValue.setAttribute("data-id", id);
    configurationValue.classList.add("form-control");
    var requireCheckLabel = document.createElement('label');
    requireCheckLabel.innerHTML = this.translateService.instant("global.required");
    var requireCheck = document.createElement('input');
    requireCheck.classList.add("form-check-input")
    requireCheck.type = 'checkbox';
    requireCheck.classList.add("requireCheckEl", "req_" + id);
    requireCheckLabel.prepend(requireCheck);

    valueLabel.appendChild(configurationValue);
    valueDiv.appendChild(valueLabel);
    valueDiv.innerHTML += "&nbsp;&nbsp;"
    valueDiv.appendChild(requireCheckLabel);
    document.getElementsByClassName("conf_" + id)[0].appendChild(valueDiv);

  }
  defaultOptionVal(id: string) {
    if (document.getElementsByClassName("vl_" + id).length > 0) {
      document.getElementsByClassName("vl_" + id)[0].remove();
    }

    var valueLabel = document.createElement("label");
    valueLabel.innerHTML = this.translateService.instant("global.configurationDefaultValue");
    valueLabel.classList.add("vl_" + id);
    var configurationValue = document.createElement('select');
    configurationValue.classList.add("configurationValue", "cv_" + id)
    configurationValue.setAttribute("data-id", id);
    configurationValue.classList.add("form-control");
    var trueOption = document.createElement('option');
    trueOption.value = "true";
    trueOption.innerHTML = "True";
    var falseOption = document.createElement('option');
    falseOption.value = "false";
    falseOption.innerHTML = "False";
    configurationValue.appendChild(falseOption);
    configurationValue.appendChild(trueOption);
    valueLabel.appendChild(configurationValue);
    document.getElementsByClassName("conf_" + id)[0].appendChild(valueLabel);
  }
  saveJsonValue() {
    this.requestForm = [true];
  }
  getJsonValue() {
    var values: { valueName: string, valueType: string, defaultValue?: any }[] = [];
    this.savedFormValues = [];
    var jsonEditorValues = [];
    var configurationNameItems = document.getElementsByClassName("configurationName") as HTMLCollectionOf<HTMLInputElement>;
    for (let index = 0; index < configurationNameItems.length; index++) {
      const element = configurationNameItems[index];
      var dataId = element.getAttribute("data-id");
      const typeElement = document.getElementsByClassName("ct_" + dataId)[0] as HTMLSelectElement;
      const valueElement = document.getElementsByClassName("cv_" + dataId)[0] as HTMLInputElement;


      var requiredCheck = true;
      var reqElement = document.getElementsByClassName("req_"+dataId)[0] as HTMLInputElement;
      if(reqElement != null && reqElement.checked == false){
        requiredCheck = false;
      }
      var value: { valueName: string, valueType: string, defaultValue?: any,required:boolean } = { valueName: configurationNameItems[index].value, valueType: typeElement.value, defaultValue: valueElement.value,required:requiredCheck };
      values.push(value);
      this.savedFormValues.push({ customId: dataId ?? "", valueName: configurationNameItems[index].value, valueType: typeElement.value, defaultValue: valueElement.value,required:requiredCheck })
      jsonEditorValues.push({valueName: configurationNameItems[index].value, valueType: typeElement.value, defaultValue: valueElement.value,required:requiredCheck});
    }
    this.jsonEditorValue = JSON.stringify(jsonEditorValues);
    return JSON.stringify(values);
  }
  createFormElements() {
    var formJsonElements: CustomFormJsonInputAndValidateModel[] = [];
    var validationRules: CustomFormJsonValidationModel[] = [];
    this.savedFormValues.forEach((value) => {
      switch (value.valueType) {
        case 'string':
          validationRules = [];
          var textInput: TextInputModel = {
            type: 'text',
            name: value.valueName,
            label: value.valueName,
            defaultValue: value.defaultValue
          }
          var requireEl = document.getElementsByClassName("req_" + value.customId)[0] as HTMLInputElement;
          if (requireEl != null && requireEl.checked) {
            var validationRule: CustomFormJsonValidationModel = {
              key: 'required',
              value: ''
            }
            validationRules.push(validationRule);
          }
          var customFormInputAndValidate: CustomFormJsonInputAndValidateModel = { input: textInput, validation: validationRules }
          formJsonElements.push(customFormInputAndValidate);
          break;
        case 'boolean':
          validationRules = [];
          var radioInput: RadioInputModel = {
            type: 'radio',
            name: value.valueName,
            label: value.valueName,
            options: [
              {
                label: "True",
                value: "true",
                selected: false
              },
              {
                label: "False",
                value: "false",
                selected: false
              }
            ]
          }
          var validationRule: CustomFormJsonValidationModel = {
            key: 'required',
            value: ''
          }
          validationRules.push(validationRule);
          var customFormInputAndValidate: CustomFormJsonInputAndValidateModel = { input: radioInput, validation: validationRules }
          formJsonElements.push(customFormInputAndValidate);
          break;
      }
    })
    var formElementsArray = this.customFormJsonService.createElements(formJsonElements);
    console.log(JSON.stringify(formElementsArray));
    return JSON.stringify(formElementsArray);
  }
  saveJsonEventListener(){
    document.addEventListener('click',ev=>{
      if($(ev.target).hasClass('save-json-value')){
        console.log(window['monacoEditor'].getValue());
        let jsonStr = window['monacoEditor'].getValue()
        this.createConfigurationsFromCustomJson(jsonStr);

      }
    })
  }
  openJsonEditor() {
    let json = this.jsonEditorValue;
    var monacoEditor: any = null;
    var monacoModal = document.createElement('div');
    monacoModal.style.height = "100%";
    var codeEditor = document.createElement('div');
    this.monacoJsonEditor = monacoEditor;
    var saveJsonBtn = this.saveJsonBtn;
    saveJsonBtn.classList.add("btn","btn-success");
    saveJsonBtn.innerText = this.translateService.instant("btn.save");
    saveJsonBtn.classList.add("save-json-value");
    saveJsonBtn.style.marginLeft = "10px";
    saveJsonBtn.style.marginTop = "5px";

    codeEditor.style.height = "calc(100% - 50px)";
    monacoModal.appendChild(codeEditor);
    monacoModal.appendChild(this.saveJsonBtn);
    this.globalModalService.showModal({ type: ModalTypes.HTMLELEMENT, title: "Json Editor", data: monacoModal, helperModals: [] })
    if (typeof monaco !== 'undefined') {
      //codeEditor.outerHTML = '<div class="style-code"></div>';
      setTimeout(function () {
        monacoEditor = monaco.editor.create(codeEditor, {
          language: 'json',
          value: json,
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['monacoEditor'] = monacoEditor;
      }, 50),
        setTimeout(() => {
          monacoEditor.trigger('editor', "editor.action.formatDocument");
        }, 100)
    }
  }
  createConfigurationsFromCustomJson(configurationJson:string){
    var configurationsEl = document.getElementById("configurations") as HTMLDivElement;
    configurationsEl.innerHTML = "";
    var parsedJson:{valueName:string,valueType:string,defaultValue:string,required?:boolean}[] = JSON.parse(configurationJson);
    for (let i = 0; i < parsedJson.length; i++) {
      const configuration = parsedJson[i];
      var confDiv = this.createNewConfiguration();

      var id = confDiv.getAttribute('data-id') ?? "";
      var configurationName = document.getElementsByClassName("cn_"+id)[0] as HTMLInputElement;
      configurationName.value = configuration.valueName;
      if(configuration.valueType == 'boolean'){
        this.defaultOptionVal(id);
        var typeSelect = document.getElementsByClassName("ct_"+id)[0] as HTMLSelectElement;
        typeSelect.selectedIndex = 1;
        
        var valSelect = document.getElementsByClassName("cv_"+id)[0] as HTMLSelectElement;
        valSelect.selectedIndex = configuration.defaultValue == "true" ? 1 : 0;
      }else if(configuration.valueType == 'string'){
        this.defaultStringVal(id);
        var typeSelect = document.getElementsByClassName("ct_"+id)[0] as HTMLSelectElement;
        typeSelect.selectedIndex = 0;
        var input = document.getElementsByClassName("cv_"+id)[0] as HTMLInputElement;
        input.value = configuration.defaultValue;

        var requiredCheck =  document.getElementsByClassName("req_"+id)[0] as HTMLInputElement
        if(configuration.required){
          requiredCheck.checked = true;
        }
      }
      
    }
  }

}
