export interface InteractiveVideoModel {
    id:string,
    sections:InteractiveVideoSectionModel[],
    contents:InteractiveVideoContentModel[]

}
export interface InteractiveVideoSectionModel {
    id:string,
    sectionName:string,
    begin:number,
    end:number
}
export interface InteractiveVideoContentModel {
    id:string,
    data?:string,
    contentType:InteractiveVideoContentTypes,
    begin:number,
    end?:number
}
export interface InteractiveVideoQuestionModel {
    id:string,
    text:string,
    answers:InteractiveVideoQuestionAnswerModel[]
}
export interface InteractiveVideoQuestionAnswerModel{
    id:string,
    text:string,
    correct:boolean
}
export enum InteractiveVideoContentTypes {
    QUESTION,
    TEXT
}
