import { Component, Input, OnInit } from '@angular/core';
import { VersionDetailsModel } from '../../../models/Education/versionDetailsModel';
import { EducationVersionService } from '../../../services/education/education-version.service';
import { EducationService } from '../../../services/education/education.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-version-details',
  templateUrl: './version-details.component.html',
  styleUrls: ['./version-details.component.css']
})
export class VersionDetailsComponent implements OnInit {
  versionDetail:VersionDetailsModel
  versionId:string;
  apiUrl:string;
  constructor(private activatedRoute:ActivatedRoute,private educationVersionService:EducationVersionService,private educationService:EducationService) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.versionId = param["versionId"];
        this.getVersion();
      }
    })
    //this.getVersion();
  }
  getVersion(){
    this.educationVersionService.getVersionDetail(this.versionId).subscribe({
      next:(response)=>{
        this.versionDetail = response.content;
        console.log(this.versionDetail);
      },error:(err)=>{

      }
    })
  }


}
