import { Component, OnInit } from '@angular/core';
import { IconService } from '../../../services/icons/icon.service';
import { IconSetGridResponse } from '../../../models/Icons/iconSetGridResponse';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'lib-icon-set-list',
  templateUrl: './icon-set-list.component.html',
  styleUrls: ['./icon-set-list.component.css']
})
export class IconSetListComponent implements OnInit {
  iconSets:IconSetGridResponse[];
  apiUrl:string = environment.apiUrl;
  selectedIconSet:string | null;
  constructor(private router:Router,private globalModalService:GlobalModalService,private iconService:IconService,private toastrService:ToastrService,private translateService:TranslateService) { }

  ngOnInit(): void {
    this.getIconSets();
  }
  getIconSets(){
    this.iconService.getIconSets().subscribe({
      next:(response)=>{
        this.iconSets = response.content;
        this.selectedIconSet = null;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }
  selectIconSet(iconDiv:any,iconSetId:string){
    
    var otherSelectedItems = $(".iconSet-item.selected").removeClass("selected");
    var elem = $(iconDiv.currentTarget).addClass("selected");
    this.selectedIconSet = iconSetId;
  }
  addNew(){
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/create",
      helperModals:[],
      title:this.translateService.instant("iconSets.addNewTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconSets();
      }
    })
  }
  routeDetailsPage(){
    if(this.selectedIconSet){
      this.router.navigate(["/cms/iconsets/"+this.selectedIconSet+"/details"])
    }else{
    
    }

  }
  updateIconSet(){
    if(this.selectedIconSet == null ||this.selectedIconSet == ''){
      this.toastrService.error(this.translateService.instant("iconSets.pleaseSelectIconSet"));
      return;
    }
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/"+this.selectedIconSet+"/update",
      helperModals:[],
      title:this.translateService.instant("iconSets.addNewTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconSets();
      }
    })
  }
  removeSelectedAttributes($ev:MouseEvent){
    if($($ev.target).hasClass("iconSets-area") || $($ev.target).hasClass("iconSets-content")){
      var otherSelectedItems = $(".iconSet-item.selected").removeClass("selected");
      this.selectedIconSet = null;
    }

  }
  deleteIconSet(){
    if(this.selectedIconSet == null ||this.selectedIconSet == ''){
      this.toastrService.error(this.translateService.instant("iconSets.pleaseSelectIconSet"));
      return;
    }
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/"+this.selectedIconSet+"/delete",
      helperModals:[],
      title:this.translateService.instant("iconSets.deleteIconSetTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIconSets();
      }
    })
  }
  routeIconsPage(id:string){

    this.router.navigate(["/cms/iconsets/"+id+"/icons/grid"])
  }

}
