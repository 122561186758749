<div class="footer customfooter" id="footer">
  <div class="links" *ngIf="showFooterMenu">
    <div class="menu" *ngFor="let link of menuItems">
      <ul>
        <li><a class="link" (click)="routeUser(link.link)"> {{link.title| translate}} </a></li>
        <li *ngFor="let subLink of link.subItems"><a class="subLink" (click)="routeUser(subLink.link)">{{subLink.title| translate}}</a></li>
      </ul>
    </div>
  </div>
  <div class="legalNotice">
    {{'LegalNotice' | translate}}
  </div>
  <br />
  <div class="btn-group dropup languageDiv">
    <button class="languageMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-globe2"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end" *ngIf="languages">
      <li *ngFor="let lang of languages"><a class="dropdown-item" (click)="changeLanguage(lang.value)"><i
            class="bi bi-translate"></i> {{lang.label}} <span class="badge bg-success"
            *ngIf="getCurrentLanguage() == lang.value"> <i class="bi bi-check-lg"></i></span></a></li>
    </ul>
  </div>
  <hr style="padding: 0px; margin:0px;"/>

  <div class="footerBottomDiv">


    <div class="copyright" style="text-align: center;">
        <span>{{tenantInfoModel.name}} </span> <span *ngIf="parentOrganization !=''">, {{parentOrganization}} </span><span>&copy; {{'allRightsReserved' | translate}}</span><br/>
        <div *ngIf="tenantInfoModel.parentOrganizationUrl == '' && tenantInfoModel.parentOrganizationTitle != ''" [innerHTML]="'ParentOrganizationScheme' | translate:{brand:tenantInfoModel.name, parent:tenantInfoModel.parentOrganizationTitle } "></div>
        <div *ngIf="tenantInfoModel.parentOrganizationTitle != undefined && tenantInfoModel.parentOrganizationUrl != '' && tenantInfoModel.parentOrganizationTitle != ''" [innerHTML]="'ParentOrganizationSchemeWithUrl' | translate:{brand:tenantInfoModel.name, parent:tenantInfoModel.parentOrganizationTitle, url:tenantInfoModel.parentOrganizationUrl } "></div>
    </div>
    <a href="https://usorta.siberzincir.com/" target="_blank">
      <div class="poweredBy">
        <font>Powered By</font><img style="width: 100px;" src="/assets/usorta.png">
      </div>
    </a>
    <br/>
  </div>

</div>
