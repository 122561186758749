import { Component, OnInit } from '@angular/core';
import { GuacamoleService } from '../../services/guacamole/guacamole.service';
import { ToastInjector, ToastrService } from 'ngx-toastr';
import { UserManagerService } from 'projects/management-panel/src/app/services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
import { GuacamoleSessionTerminalActiveConnectionModel, GuacamoleSessionTerminalsModel } from '../../models/GuacamoleModels/guacamoleSessionTerminalsModel';

@Component({
  selector: 'lib-guacamole-lms-session-terminals',
  templateUrl: './guacamole-lms-session-terminals.component.html',
  styleUrls: ['./guacamole-lms-session-terminals.component.css']
})
export class GuacamoleLmsSessionTerminalsComponent implements OnInit {
  lmsSessionId:string;
  terminalDetails:GuacamoleSessionTerminalsModel;
  terminals:any[];
  users:{id:string,username:string,firstName:string,lastName:string}[];
  usersLoading:boolean = false;
  terminalsLoading:boolean = false;
  constructor(private activatedRoute:ActivatedRoute ,private guacamoleService:GuacamoleService,private toastrService:ToastrService,private userManagerService:UserManagerService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.lmsSessionId = param["lmsSession"];
        this.getTerminalsByLmsSessionId();
        this.getUsers();
      }
    })
  }
  getTerminalsByLmsSessionId(){
    this.terminalsLoading = true;
    this.guacamoleService.getTerminalDetailsByLmsSessionId(this.lmsSessionId).subscribe({
      next:(response)=>{
        this.terminalDetails = response;
        this.terminals = Object.values(response.terminals);
        this.terminalsLoading = false;
      },error:(err)=>{
        this.terminalsLoading = false;
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  getUsers(){
    this.usersLoading = true
    this.userManagerService.getUsers().subscribe({
      next:(response)=>{
        this.users = response.content;
        console.log(this.users);
        this.usersLoading = false;
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
        this.usersLoading = false;
      }
    })
  }
  getUsername(userId:string){
    return this.users.find(x=>x.id == userId)?.username ?? userId;
  }

}
