<div *ngIf="virtualMachine">
    <div class="mb-3">
        <label for="providerType" class="form-label">Provider Type</label>
        <select (change)="changeProvider()" id="providerType" class="form-select" aria-label="Default select example">
            <option *ngFor="let provider of providers" [selected]="virtualMachine.providerId == provider.value" [value]="provider.value">{{provider.label}}</option>
        </select>
        <label for="hostId" class="form-label">Host</label>
        <select (change)="changeHost()" id="hostId" class="form-select" aria-label="Default select example">
            <option value=undefined>Not Selected</option>
            <option *ngFor="let host of hosts" [selected]="virtualMachine.hostId == host.value" [value]="host.value">{{host.label}}</option>
        </select>
    </div>
    <div class="mb-3">
        <label for="name" class="form-label">{{'lab.virtualMachine.name' | translate}}</label>
        <input type="text" class="form-control" id="name" [value]="virtualMachine.name">
    </div>
    <div class="form-check form-switch">
        <input [checked]="virtualMachine.vmInstanceTypeId == 'custom'" [disabled]="allowCustomSelect == false" (change)="changeCustomSelectSwitch($event)" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        <label  class="form-check-label" for="flexSwitchCheckDefault">Custom RAM & CPU Count</label>
      </div>
    <div class="mb-3" [class.displayNone]="showSelects == false || customSelect == true">
        <label for="instanceType" class="form-label">Instance Type</label>
        <select (change)="changeInstanceType()" id="instanceType" class="form-select"
            aria-label="Default select example">
            <option *ngFor="let instanceType of instanceTypes" [value]="instanceType.value">{{instanceType.label}}
            </option>
        </select>
    </div>
    <div class="mb-3" [class.displayNone]="customSelect == false">
        <label for="ram" class="form-label">{{'lab.virtualMachine.ramInfo' | translate}}</label>
        <select id="ram" class="form-select" aria-label="Default select example">
            <option *ngFor="let ram of ramList" [selected]="virtualMachine.ram == ram.value" [value]="ram.value">
                {{ram.label}}</option>
        </select>
    </div>

    <div class="mb-3" [class.displayNone]="customSelect == false">
        <label for="cpuCount" class="form-label">{{'lab.virtualMachine.processorCoreCount' | translate}}</label>
        <select id="cpuCount" class="form-select" aria-label="Default select example">
            <option *ngFor="let cpu of cpuList" [selected]="virtualMachine.cpuCount == parseInteger(cpu.value)"
                [value]="cpu.value">{{cpu.label}}</option>
        </select>
    </div>
    <h5>{{'lab.virtualMachine.diskList' | translate}}</h5>
    <lib-dynamic-form-to-json *ngIf="elementList" [addWithData]="disksWithData" (dataChanges)="saveDisks($event)"
        [autoSave]="true" [formElements]="elementList">

    </lib-dynamic-form-to-json>
    <hr>
    <h5>{{'lab.virtualMachine.networkCards' | translate}}</h5>
    <lib-dynamic-form-to-json [addWithData]="nicWithData" *ngIf="nicElementList" (dataChanges)="saveNic($event)"
        [autoSave]="true" [formElements]="nicElementList">

    </lib-dynamic-form-to-json>
    <br />
    <button class="btn btn-primary btn-sm" (click)="updateVM()">{{'btn.update' | translate}}</button>
</div>