import { Injectable } from '@angular/core';
import { CmsImageSizes } from '../../models/Enums/CmsImageSizes';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CmsImageService {

  constructor() { }
  getUrl(imageId:string,imageSize?:CmsImageSizes ){
    var imageApi = environment.imageApi;
    if(imageSize){
      switch(imageSize){
        case CmsImageSizes.Size_1043x695:
          return `${imageApi}${imageId}/1043x695`
        case CmsImageSizes.Size_1191x671:
          return `${imageApi}${imageId}/1191x671`
        case CmsImageSizes.Size_250x150:
          return `${imageApi}${imageId}/250x150`
        case CmsImageSizes.Size_40x40:
          return `${imageApi}${imageId}/40x40`
        default:
          return `${imageApi}${imageId}`
      }

    }else{
      return `${imageApi}${imageId}`
    }
  }
}
