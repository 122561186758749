<lib-custom-form [formBottomElement]="formBottomInput" *ngIf="updateForm" [externalFormData]="updateForm" [getFormInput]="requestForm" (GetFormGroup)="getFormGroup($event)" (formResponseOut)="getResponse($event)"></lib-custom-form>
<div id="updateFeatureConfig">
<button class="btn btn-warning" (click)="createNewConfiguration()" style="margin-right: 5px;">{{'global.addNewConfiguration' | translate}}</button>
<button class="btn btn-warning" (click)="openJsonEditor()">{{'global.jsonEditor' | translate}}</button>
<br/><br/>
<div class="configurations" id="configurations">

</div>
<br/>
<button (click)="saveJsonValue()" class="btn btn-success">{{'global.saveConfiguration' | translate}}</button>
</div>