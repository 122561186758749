import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { EducationService } from '../../../services/education/education.service';
import { EducationVersionService } from '../../../services/education/education-version.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { UpdateEducationCategoryRequestModel } from '../../../models/Education/updateEducationCategoryRequestModel';
import { EducationVersionUpdateRequestModel } from '../../../models/Education/educationVersionUpdateRequestModel';
import { ModalCreateModel } from '../../../models/ModalManagementModels/modalCreateModel';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { CmsImageService } from '../../../services/cmsImages/cms-image.service';
import { CmsImageSizes } from '../../../models/Enums/CmsImageSizes';
import { EducationVersionUpdateModel } from '../../../models/Education/educationVersionUpdateInfoModel';
declare var window:any;
declare var $:any;
declare var monaco:any;
@Component({
  selector: 'lib-update-education-version',
  templateUrl: './update-education-version.component.html',
  styleUrls: ['./update-education-version.component.css']
})
export class UpdateEducationVersionComponent implements OnInit {

  treeId:number = 1;
  //courseId:string;
  versionId:string;
  educationCategoryList:ListItemModel[] = [];
  themeList:ListItemModel[] = [];
  versionUpdateModel:EducationVersionUpdateModel;
  catalogImageChangedEvent:any = '';
  croppedCatalogImage:any = '';
  croppedCatalogImage64:string | null | undefined = undefined;
  loading:boolean = false;
  detailImageChangedEvent:any = '';
  croppedDetailImage:any = '';
  croppedDetailImage64:string | null | undefined = undefined;
  constructor(private cmsImageService:CmsImageService,private activatedRoute:ActivatedRoute,private translateService:TranslateService ,private globalModalService:GlobalModalService,private sanitizer:DomSanitizer,private toastrService:ToastrService,private educationService:EducationService,private educationVersionService:EducationVersionService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.versionId = param["versionId"];
        this.getVersionUpdateInfo();
      }
    })
    //this.initializeEditor();
    this.getEducationCategories();
    this.getEducationThemeList();


  }
  getVersionUpdateInfo(){
    this.educationVersionService.getVersionUpdateInfo(this.versionId).subscribe({
      next:(response)=>{
        this.versionUpdateModel = response.content;
        setTimeout(()=>{
          window.initLayoutEditor($('#descriptionDetail'), {});
        },150)
      }
    })
  }
  getImageUrl(imageId:string){
    return this.cmsImageService.getUrl(imageId,CmsImageSizes.Size_250x150);
  }
  initializeEditor(){
    var editorElement = document.getElementById("editOutlineEditor") as HTMLDivElement;
    var monacoEditor: any = null;
    if(typeof(monaco) !== 'undefined'){
      setTimeout(function () {
        monacoEditor = monaco.editor.create(editorElement, {
          language: 'json',
          theme: 'vs',
          autoIndent: true,
          tabSize: 2,
          automaticLayout: true
        });
        window['outlineEditor'] = monacoEditor;
      }, 50)
    }
  }
  getEducationThemeList(){
    this.educationService.getEducationThemeList().subscribe({
      next:(response)=>{
        this.themeList = response.content;
      }
    })
  }
  getEducationCategories(){
    this.educationService.getCategories(this.treeId).subscribe({
      next:(response)=>{
        this.educationCategoryList = response.content;
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"))
      }
    })
  }
  getLayoutEditor(){
    var ta = document.getElementById("description-layout-editor") as HTMLTextAreaElement;
  }
  /* Katalog Resmi Kırpma Fonksiyonları */
  changeCatalogImage(ev:Event){
    this.catalogImageChangedEvent = ev;
  }
  catalogImageCropped(event:ImageCroppedEvent){
    this.croppedCatalogImage64 = event.base64;
    this.croppedCatalogImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success(this.translateService.instant("imageCropper.savedSelectedArea"));
  }
  catalogImageLoaded(image:LoadedImage){

  }
  catalogCropperReady(){

  }
  catalogImageLoadFailed(){

  }

  /* Detay Resmi Kırpma Fonksiyonları */
  changeDetailImage(ev:Event){
    this.detailImageChangedEvent = ev;
  }
  detailImageCropped(event:ImageCroppedEvent){
    this.croppedDetailImage64 = event.base64;
    this.croppedDetailImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    this.toastrService.success(this.translateService.instant("imageCropper.savedSelectedArea"));
  }
  detailImageLoaded(image:LoadedImage){

  }
  detailCropperReady(){

  }
  detailImageLoadFailed(){

  }
  /* Model oluştur */
  createAddEducationModel(){
    var codeSuffix = (document.getElementById("codeSuffix") as HTMLInputElement).value;
    var educationName = (document.getElementById("educationName") as HTMLInputElement).value;
    var isActive = (document.getElementById("isActive") as HTMLInputElement).checked;
    var deleteImage = (document.getElementById("deleteImage") as HTMLInputElement).checked;
    var deleteTeaserImage = (document.getElementById("deleteTeaserImage") as HTMLInputElement).checked;
    var description = (document.getElementById("description") as HTMLInputElement).value;
    var descriptionDetail = (document.getElementById("descriptionDetail") as HTMLInputElement).value;
    var maxScore = (document.getElementById("maxScore") as HTMLInputElement).value;
    var passingScore = (document.getElementById("passingScore") as HTMLInputElement).value;
    var passingPct = (document.getElementById("passingPct") as HTMLInputElement).value;
    //var outline = window['outlineEditor'].getValue();
    var themeId = (document.getElementById("theme") as HTMLSelectElement).value;
    var educationModel:EducationVersionUpdateRequestModel = {
      active: isActive,
      name: educationName,
      codeSuffix: codeSuffix,
      teaserText: description,
      description: descriptionDetail,
      descriptionFormat: 0,
      passingPct: parseInt(passingPct),
      passingScore: parseInt(passingScore),
      maxScore: parseInt(maxScore),
      themeId: parseInt(themeId),
      image: this.croppedCatalogImage64 ?? undefined,
      teaserImage: this.croppedDetailImage64 ?? undefined,
      versionId: this.versionUpdateModel.versionId,
      deleteImage: deleteImage,
      deleteTeaserImage: deleteTeaserImage
    }
    //this.educationModelControl(educationModel);
    return educationModel;
  }
  educationModelControl(educationModel:EducationVersionUpdateRequestModel){
    var emptyAllowed = ["outline","maxScore","passingPct","passingScore","image","teaserImage"];
    var educationModelKeys = Object.keys(educationModel);
    educationModelKeys.forEach(key=>{
      var model = educationModel as any;
      if((model[key] === "" || model[key] === undefined) && emptyAllowed.findIndex(x=> x == key) == -1){
        console.log(key);
        //var label = (document.querySelector('[for="'+key+'"]') as HTMLLabelElement).innerText;
        var label = key;
        this.toastrService.error(this.translateService.instant("field.cannotBeEmpty",{value:label}))
        throw "Modeli kontrol edin";
      }
    });
  }
  updateEducation(){
    var educationModel = this.createAddEducationModel();
    console.log(educationModel);
    this.loading = true;
    this.educationVersionService.updateVersion(educationModel).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("Sürüm başarıyla güncellendi"));
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("Sürüm güncellenemedi"));
      }
    });
  }

  showAddCategoryModal(){
    var modalCreateModel:ModalCreateModel = {
      title: this.translateService.instant("courseManagement.newCategory"),
      type: ModalTypes.LINK,
      data: "modal/education/addCategory",
      helperModals: []
    }
    var modal = this.globalModalService.showModal(modalCreateModel);
    modal.onclose.subscribe({
      next:()=>{
        this.getEducationCategories();
      }
    })
  }

}
