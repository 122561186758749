<div class="form-maroon-border formMain form-default-border">
<h4>{{'applications.detailsPageTitle' | translate}}</h4>
<hr/>
    <div class="mb-3">
        <b> {{'applications.applicationName' | translate}} : </b> <font class="detailsValue">{{appDetails.name}}</font>
    </div>
    <div class="mb-3">
        <b> {{'applications.description' | translate}} : </b> <font class="detailsValue">{{appDetails.description}}</font>
    </div>
    <div class="mb-3">
        <b> {{'applications.type' | translate}} : </b> <font class="detailsValue">{{appDetails.type}}</font>
    </div>
    <div class="mb-3">
        <b> {{'applications.flags' | translate}} : </b> <font class="detailsValue">{{appDetails.flags}}</font>
    </div>
    <div class="mb-3">
        <b> {{'applications.enabled' | translate}} : </b> <input type="checkbox" class="form-check-input" [checked]="appDetails.enabled" onclick="return false">
    </div>
</div>
