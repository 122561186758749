import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-lab-objectives',
  templateUrl: './lab-objectives.component.html',
  styleUrls: ['./lab-objectives.component.css']
})
export class LabObjectivesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
