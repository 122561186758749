import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseVmImageGetInfoResponse } from 'projects/usorta-library/src/lib/models/BaseVMImages/baseVmImageGetInfoResponse';
import { BaseVmImageService } from 'projects/usorta-library/src/lib/services/baseVmImages/base-vm-image.service';
declare var $:any;
@Component({
  selector: 'lib-update-base-vm-image-info',
  templateUrl: './update-base-vm-image-info.component.html',
  styleUrls: ['./update-base-vm-image-info.component.css']
})
export class UpdateBaseVmImageInfoComponent implements OnInit {
  baseVmId:string;
  locale:string;
  currentInfo:BaseVmImageGetInfoResponse;
  format:number[] = []; 
  loaded:boolean;
  editorValue:any;
  createRevision:boolean = false;
  constructor(private translateService:TranslateService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private baseImageService:BaseVmImageService) { }

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.getBaseVmId();
    this.getInfo();
  }
  getBaseVmId(){
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.baseVmId = param["vmImageId"];
      }
    })
  }
  changeFormat(ev:any){
    //Yalnızca layout a izin veriliyor.
    this.format = [5];
    //this.format = [parseInt($("#format").val())];
  }
  getValueFnc(ev:any){
    this.editorValue = ev;
    console.log(ev);
  }
  getInfo(){
    this.baseImageService.getInfo(this.baseVmId,this.locale).subscribe({
      next:(response)=>{
        this.format = [5];
        //this.format = [response.content.format];
        this.currentInfo = response.content;
        this.loaded = true;
      },error:(err)=>{
        this.format = [5];
        this.loaded = true;
      }
    })
  }
  createRevisionChange(){
    var checkEl = document.getElementById("createRevisionInput") as HTMLInputElement;
    this.createRevision = checkEl.checked;
  }
  update(){
    var revisionName = undefined;
    if(this.createRevision){
      revisionName =  (document.getElementById("revisionName") as HTMLInputElement).value;
    }
    var val:string = this.editorValue();
    this.baseImageService.updateBaseVmImageInfoRevision({
      content:val,
      createRevision:this.createRevision,
      format:this.format[0],
      localeId:this.locale,
      revisionName:revisionName,
      vmImageId:this.baseVmId
    }).subscribe({
      next:(response)=>{
        this.toastrService.success(this.translateService.instant("baseVmImages.info.updatedSuccessfully"));
      },error:(err)=>{
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
