export const CourseContentFlags = {
    None : 0,
    Scored : 1,
    RequireAnswerOnQuestion : 2,
    RequireCorrectAnswerOnQuestion : 4,
    CompressedRawContent : 8,
    Optional : 16,
    UseAudioCommentaryVerbatim : 32,
    InlineMilestone : 64,
    Hidden : 128,
    DisableAudioCommentary : 256

}