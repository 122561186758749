import { Component, OnInit } from '@angular/core';
import { EducationService } from '../../../services/education/education.service';
import { ToastrService } from 'ngx-toastr';
import { MonacoService } from '../../../services/monacoService/monaco.service';
import { EducationThemeModel } from '../../../models/Education/educationThemeModel';
import { ActivatedRoute } from '@angular/router';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateCourseThemeModel } from '../../../models/Education/updateCourseThemeModel';
declare var $:any;
declare var monaco:any;
declare var window:any;
@Component({
  selector: 'lib-update-education-theme',
  templateUrl: './update-education-theme.component.html',
  styleUrls: ['./update-education-theme.component.css']
})
export class UpdateEducationThemeComponent implements OnInit {
  educationThemeId:number;
  educationTheme:EducationThemeModel;
  loading:boolean = false;
  constructor(private translateService:TranslateService ,private educationService:EducationService,private toastrService:ToastrService,private monacoService:MonacoService,private activatedRoute:ActivatedRoute,private globalOkCancelEvent:GlobalOkCancelEventService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.educationThemeId = parseInt(param["themeId"]);
        this.getEducationTheme();
      }
    })


  }
  getEducationTheme(){
    this.educationService.getEducationTheme(this.educationThemeId).subscribe({
      next:(response)=>{
        this.educationTheme = response.content;
        setTimeout(()=>{
          this.initializeInputs();
          this.initializeScripts();
          this.initializeRawStyle();
          this.initializeStyle();
          this.initializeTextoverrides();
        },500)
      },error:(err)=>{
        this.toastrService.error("Eğitim teması getirilemedi");
      }
    })
  }
  initializeInputs(){
    (document.getElementById("name") as HTMLInputElement).value = this.educationTheme.name;
    (document.getElementById("key") as HTMLInputElement).value = this.educationTheme.themeKey;
    (document.getElementById("active") as HTMLInputElement).checked = this.educationTheme.active;
  }
  initializeRawStyle(){
    var rawStyleDiv = document.getElementsByClassName("themeStyleRaw")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(rawStyleDiv,{
        language:"css",
        value:this.educationTheme.rawStyles,
        minimap:{enabled:false}
      })
      window["rawStyle"] = editor;
    }
  }
  initializeStyle(){
    var themeStyle = document.getElementsByClassName("themeStyle")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeStyle,{
        language:"json",
        value:this.educationTheme.styles,
        minimap:{enabled:false}
      })
      window["themeStyle"] = editor;
    }
  }
  initializeScripts(){
    var themeScripts = document.getElementsByClassName("themeScripts")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeScripts,{
        language:"javascript",
        value:this.educationTheme.scripts,
        minimap:{enabled:false}
      })
      window["themeScript"] = editor;
    }
  }
  initializeTextoverrides(){
    var textOverrides = document.getElementsByClassName("themeTextOverrides")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(textOverrides,{
        language:"json",
        value:this.educationTheme.textOverrides,
        minimap:{enabled:false}
      })
      window["themeTextoverrides"] = editor;
    }
  }
  initializeThemeData(){
    var themeData = document.getElementsByClassName("themeData")[0] as HTMLDivElement;
    if(monaco === undefined){
      return;
    }else{
      var editor = monaco.editor.create(themeData,{
        language:"json",
        minimap:{enabled:false},
        value:this.educationTheme.themeData
      })
      window["themeData"] = editor;
    }
  }

  goUrl(file:{id:number,name:string,url:string}){
    var a = document.createElement('a');
    a.target="_blank";
    a.href=file.url;
    a.click();
  }

  deleteThemeFile(file:{id:number,name:string,url:string}){
    this.globalOkCancelEvent.showOkCancelWindow(this.translateService.instant("educationTheme.fileDelete"),this.translateService.instant("educationTheme.fileDeleteMessage")).then((r)=>{
      if(r == true){
        this.educationService.deleteEducationThemeFile(this.educationThemeId,file.id).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("educationTheme.fileDeletedSuccessful"));
          },
          error:(err)=>{
            this.toastrService.error(this.translateService.instant("educationTheme.fileDeletedError"));
          }
        })
      }
    })

  }
  deleteEducationTheme(){
    this.globalOkCancelEvent.showOkCancelWindow(this.translateService.instant("educationTheme.deleteTheme"),this.translateService.instant("educationTheme.updatePageDeleteMessage")).then((r)=>{
      if(r == true){
        this.educationService.deleteTheme(this.educationThemeId).subscribe({
          next:(response)=>{
            this.toastrService.success(this.translateService.instant("educationTheme.themeDeletedSuccessful"));
          },error:(err)=>{
            this.toastrService.error(this.translateService.instant("educationTheme.themeDeletedError"));
          }
        })
      }

    })
  }

  updateEducationTheme(){
    var formData = new FormData();
    var nameVal = (document.getElementById("name") as HTMLInputElement).value;
    var keyVal = (document.getElementById("key") as HTMLInputElement).value;
    var activeVal = (document.getElementById("active") as HTMLInputElement).checked;
    var themeStyleVal = window["themeStyle"].getValue();
    var themeRawStyleVal = window["rawStyle"].getValue();
    var themeScriptVal = window["themeScript"].getValue();
    var themeTextOverridesVal = window["themeTextoverrides"].getValue();
    var themeDataVal = window["themeData"].getValue();
    var files = (document.getElementById("themeFiles") as HTMLInputElement).files;
    /*formData.append("id",this.educationThemeId.toString());
    formData.append("name",nameVal);
    formData.append("key",keyVal);
    formData.append("active",activeVal ? "true" : "false");
    formData.append("styles",themeStyleVal);
    formData.append("rawStyles",themeRawStyleVal);
    formData.append("scripts",themeScriptVal);
    formData.append("textOverrides",themeTextOverridesVal);
    if(files != null){
      for (let index = 0; index < files.length; index++) {
        var file = files[index];
        formData.append("files[]",file);        
      }
    }
    console.log(formData.getAll("id"));
    console.log(formData.getAll("name"));
    console.log(formData.getAll("key"));
    console.log(formData.getAll("active"));
    console.log(formData.getAll("styles"));
    console.log(formData.getAll("rawStyles"));
    console.log(formData.getAll("scripts"));
    console.log(formData.getAll("textOverrides"));
    console.log(formData.getAll("files[]"));*/
    var updateModel:UpdateCourseThemeModel = {
      themeId: this.educationThemeId,
      name: nameVal,
      themeKey: keyVal,
      active: activeVal,
      styles: themeStyleVal,
      rawStyle: themeRawStyleVal,
      scripts: themeScriptVal,
      textOverrides: themeTextOverridesVal,
      themeData: themeDataVal
    }
    this.loading = true;
    this.educationService.updateEducationTheme(this.educationThemeId,updateModel).subscribe({
      next:(response)=>{
        this.loading = false;
        this.toastrService.success(this.translateService.instant("educationTheme.addedSuccessful"));
      },error:(err)=>{
        this.loading = false;
        this.toastrService.error(this.translateService.instant("global.errorOccurred"));
      }
    })
  }

}
