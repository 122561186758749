import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { RemovableImageResponse } from '../../models/RemovableImages/removableImageResponse';
import { SingleResponseModel } from '../../models/singleResponseModel';

@Injectable({
  providedIn: 'root'
})
export class RemovableImageService {

  constructor(private httpClient:HttpClient) { }
  getRemovableImageDetails(id:string){
    var apiUrl = environment.apiUrl + "api/range/vm/removableimages/"+id+"/details"
    return this.httpClient.get<SingleResponseModel<RemovableImageResponse>>(apiUrl);
  }
}
