<div class=" formMain form-maroon-border form-default-border" *ngIf="setupInstanceId">
    <h4 style="border-bottom: 3px solid black; display: inline-block;">{{'setupVmInstance.workbench' | translate}}</h4>
    <div class="main-toolbar">
        <div class="toolbar-item" (click)="startVm()" [title]="'setupVmInstances.startVm' | translate">
            <button class="btn btn-success btn-sm toolbar-btn"><i class="bi bi-play-fill"></i></button>
        </div>
        <div class="toolbar-item" (click)="shutdownVm()" [title]="'setupVmInstances.shutdownVm' | translate">
            <button class="btn btn-danger btn-sm toolbar-btn"><i class="bi bi-stop-fill"></i></button>
        </div>
        <div class="toolbar-item" (click)="restartVm()" [title]="'setupVmInstances.restartVm' | translate">
            <button class="btn btn-info btn-sm toolbar-btn"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div class="toolbar-item" (click)="openClonePage()" [title]="'setupVmInstances.cloneVm' | translate">
            <button class="btn btn-warning btn-sm toolbar-btn"><i class="bi bi-copy"></i></button>
        </div>
        <div class="toolbar-item" (click)="openPublishPage()" [title]="'setupVmInstances.publishVm' | translate">
            <button class="btn btn-secondary btn-sm toolbar-btn"><i class="bi bi-share"></i></button>
        </div>
    </div>
    <br/>
    <div class="manage-main-container">
    <div class="left-main-container">
        <div class="vm-specs">
            <lib-manage-setup-instance-details [setupInstanceId]="setupInstanceId"></lib-manage-setup-instance-details>
        </div>
        <br/><br/>
        <div class="vm-objectives">
            <lib-manage-base-vm-image-objectives></lib-manage-base-vm-image-objectives>
        </div>
    </div>
    &nbsp;&nbsp;
    <div class="right-main-container">
        <div class="guacamole-remote-control">
            
            <font class="not-connected" *ngIf="vmName ==undefined || jwt == undefined">Not Availabe</font>
            <button (click)="openTerminal()" class="btn btn-success" *ngIf="vmName !=undefined && jwt != undefined">Connect To Virtual Machine</button>
        </div>
        <br/><br/>
        <div class="note-and-info">
            <h5>{{'setupVmInstances.notesAndVmInfo' | translate}}</h5>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [style.cursor]="'pointer'" (click)="showInfoItems()"
                        [class.active]="showNote == false" aria-current="page">Info</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [style.cursor]="'pointer'" (click)="showNoteItems()"
                        [class.active]="showNote == true">Notes</a>
                </li>
            </ul>
            <lib-manage-base-vm-image-notes *ngIf="baseVmId && showNote" [baseVmImageId]="baseVmId"></lib-manage-base-vm-image-notes>
            <lib-manage-base-vm-image-infos *ngIf="baseVmId && showNote==false" [baseVmImageId]="baseVmId"></lib-manage-base-vm-image-infos>
        </div>
    </div>
</div>
</div>

<div>
    <!--<lib-guac-client *ngIf="openVm" [allowControls]="true" [guacJwt]="" [mouseAccess]="[true]" [keyboardAccess]="[true]"></lib-guac-client>-->
</div>