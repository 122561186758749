import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizedStringService } from '../../../services/localizedString/localized-string.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-localized-string',
  templateUrl: './delete-localized-string.component.html',
  styleUrls: ['./delete-localized-string.component.css']
})
export class DeleteLocalizedStringComponent implements OnInit {

  

  localizedStringId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private localizedStringService:LocalizedStringService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.localizedStringId = parseInt(param["id"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteLocalizedString(){
    this.localizedStringService.deleteLocalizedString(this.localizedStringId,{id:this.localizedStringId}).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Localized string successfully deleted");
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }

}
