import { Component, OnInit } from '@angular/core';
import { IconGridResponse } from '../../../models/Icons/iconGridResponse';
import { IconService } from '../../../services/icons/icon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
import { GlobalOkCancelEventService } from '../../../services/globalOkCancelEvent/global-ok-cancel-event.service';
import { ToastrService } from 'ngx-toastr';
import { IconDetailsResponse } from '../../../models/Icons/iconDetailsResponse';
declare var $:any;
@Component({
  selector: 'lib-icon-set-icon-list',
  templateUrl: './icon-set-icon-list.component.html',
  styleUrls: ['./icon-set-icon-list.component.css']
})
export class IconSetIconListComponent implements OnInit {
  icons:IconGridResponse[];
  iconSetId:string;
  apiUrl:string = environment.apiUrl;
  selectedIconId:number | null;
  iconDetails:IconDetailsResponse;
  constructor(private toastrService:ToastrService,private globalOkCancelEventService:GlobalOkCancelEventService,private router:Router,private activatedRoute:ActivatedRoute,private translateService:TranslateService,private iconService:IconService,private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.iconSetId = param["id"];
        this.getIcons();
      }
    })
  }
  routeIconDetails(id:number){
    this.router.navigate(["/cms/iconsets/icons/"+id+"/details"]);
  }
  getIcons(){
    this.iconService.getIconsWithIconSet(this.iconSetId).subscribe({
      next:(response)=>{
        this.icons = response.content;
      }
    });
  }
  selectIcon(iconDiv:any,iconId:number){
    var otherSelectedItems = $(".iconSet-item.selected").removeClass("selected");
    var elem = $(iconDiv.currentTarget).addClass("selected");
    this.selectedIconId = iconId;
    this.getIconDetails();
  }
  addNew(){
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/"+this.iconSetId+"/icons/create",
      helperModals:[],
      title:this.translateService.instant("icons.createNewTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIcons();
      }
    })
  }
  getIconDetails(){
    if(this.selectedIconId != null){
      this.iconService.getIconDetails(this.selectedIconId).subscribe({
        next:(response)=>{
          this.iconDetails = response.content;
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("icons.detailsCouldBeRetrieved"));
        }
      })
    }

  }
  updateIcon(){
    var modal = this.globalModalService.showModal({
      data:"/modal/cms/iconsets/icons/"+this.selectedIconId+"/update",
      helperModals:[],
      title:this.translateService.instant("icons.updateIconTitle"),
      type:ModalTypes.LINK
    })
    modal["onclose"].subscribe({
      next:(response:any)=>{
        this.getIcons();
      }
    })
  }
  removeSelectedAttributes($ev:MouseEvent){
    if($($ev.target).hasClass("iconSets-area") || $($ev.target).hasClass("iconSets-content")){
      var otherSelectedItems = $(".iconSet-item.selected").removeClass("selected");
      this.selectedIconId = null;
    }
  }
  returnIconsets(){
    this.router.navigate(["/cms/iconsets/grid"])
  }
  deleteIcon(){
    this.globalOkCancelEventService.showOkCancelWindow(this.translateService.instant("icons.deleteIconTitle"),'<div>'+this.translateService.instant("icons.deleteIconMessage")+'</div>',true,undefined,undefined,true,this.translateService.instant("icons.deleteConfirmationMessage")).then(x=>{
      if(x == true){
        if(this.selectedIconId != null){
          this.iconService.deleteIcon(this.selectedIconId).subscribe({
            next:(response)=>{
              this.toastrService.success(this.translateService.instant("global.deletedSuccessfully"));
              this.getIcons();
            },error:(err)=>{
              this.toastrService.error(this.translateService.instant("global.errorOccurred"));
            }
          })
        }

      }
    })
  }

}
