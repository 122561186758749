import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormatStringService } from '../../../services/localizedFormatString/format-string.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-delete-localized-format-string',
  templateUrl: './delete-localized-format-string.component.html',
  styleUrls: ['./delete-localized-format-string.component.css']
})
export class DeleteLocalizedFormatStringComponent implements OnInit {

  formatStringId:number;
  checkedUnderstandCheck:boolean = false;
  
  constructor(private activatedRoute:ActivatedRoute,private localizedStringService:FormatStringService,private toastrService:ToastrService) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.formatStringId = parseInt(param["id"]);
      }
    })
  }
  checkChanged(ev:Event){
    let target = ev.target as HTMLInputElement;
    this.checkedUnderstandCheck = target.checked;
  }
  deleteLocalizedString(){
    this.localizedStringService.deleteFormatString(this.formatStringId,{id:this.formatStringId}).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Localized format string successfully deleted");
        }else{
          this.toastrService.error("An error occurred");
        }
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })
  }


}
