import { Component, OnInit } from '@angular/core';
import { InputFormats } from '../../../models/Enums/inputFormatsEnum';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { LocaleService } from '../../../services/locale/locale.service';
import { EducationService } from '../../../services/education/education.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddCourseTranslationModel } from '../../../models/Education/CourseTranslationModels/addCourseTranslationModel';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CourseTranslationService } from '../../../services/education/course-translation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-add-course-translation',
  templateUrl: './add-course-translation.component.html',
  styleUrls: ['./add-course-translation.component.css']
})
export class AddCourseTranslationComponent implements OnInit {
  imageChangedEvent:any;
  teaserImageChangedEvent:any;
  descriptionInputTypes:number[] = [InputFormats.PlainText];
  getDescriptionFunc:any;
  themeList:ListItemModel[];
  languageList:ListItemModel[];
  image?:string | undefined;
  teaserImage?:string | undefined;
  addCourseTranslationForm:FormGroup;
  loaded:boolean;
  courseId:string;
  baseThemeId:number | undefined;
  constructor(private activatedRoute:ActivatedRoute,private translateService:TranslateService,private toastrService:ToastrService,private localeService:LocaleService,private educationService:EducationService,private formBuilder:FormBuilder,private courseTranslationService:CourseTranslationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseId = param["courseId"];
      }
    })
    this.getLanguages().then(()=>{
      this.getThemes().then(()=>{
        this.getCourseDetails().then(()=>{
          this.loaded = true;
          this.buildAddCourseTranslationForm();
        })

      })
    });
  }
  getCourseDetails():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.educationService.getEducation(this.courseId).subscribe({
        next:(response)=>{
          this.baseThemeId = response.content.themeId;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })
  }
  buildAddCourseTranslationForm(){
    this.addCourseTranslationForm = this.formBuilder.group({
      localeId:[this.languageList[0].value,Validators.required],
      primaryVersionId:[null],
      initialVersionId:[null],
      themeId:[this.baseThemeId ?? ''],
      name:["",Validators.required],
      teaserText:["",Validators.required],
      active:[false],
      descriptionFormat:[0]
    })
  }
  getLanguages():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.localeService.getLocalesWithSelectListSchema().subscribe({
        next:(response)=>{
          this.languageList = response.content;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })

  }
  getThemes():Promise<boolean>{
    return new Promise((resolve,reject)=>{
      this.educationService.getEducationThemeList().subscribe({
        next:(response)=>{
          this.themeList = response.content;
          resolve(true);
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
          resolve(false);
        }
      })
    })

  }
  changeDescriptionFormat($ev:any){
    var el = $ev.target as HTMLInputElement;
    this.descriptionInputTypes = [parseInt(el.value)]
  }
  getDescriptionValueEvent($ev:any){
    this.getDescriptionFunc = $ev;
  }
  changeImage($ev:any){
    this.imageChangedEvent = $ev;
  }
  imageCropped($ev:ImageCroppedEvent){
    this.image = $ev.base64 as string | undefined;
  }
  imageLoaded($ev:any){

  }
  changeTeaserImage($ev:any){
    this.teaserImageChangedEvent = $ev;
  }
  teaserImageCropped($ev:ImageCroppedEvent){
    this.teaserImage = $ev.base64 as string | undefined;
  }
  teaserImageLoaded($ev:any){

  }
  addTranslation(){
    if(this.addCourseTranslationForm.valid){
      var values:AddCourseTranslationModel = Object.assign({},this.addCourseTranslationForm.value);
      values.image = this.image;
      values.teaserImage = this.teaserImage;
      values.courseId = this.courseId;
      values.primaryVersionId = values.primaryVersionId == "" ? undefined : values.primaryVersionId;
      values.initialVersionId = values.initialVersionId == "" ? undefined : values.initialVersionId;
      values.description = this.getDescriptionFunc();
      values.descriptionFormat = typeof(values.descriptionFormat) == "string" ? parseInt(values.descriptionFormat) : values.descriptionFormat;
      if(values.themeId as any != "" && values.themeId != undefined){
        values.themeId = typeof(values.themeId) == "string" ? parseInt(values.themeId) : values.themeId;
      }else{
        values.themeId = undefined
      }

      console.log(values);
      this.courseTranslationService.addCourseTranslation(this.courseId,values).subscribe({
        next:(response)=>{
          this.toastrService.success(this.translateService.instant("courseManagement.translationAddedSuccessfully"));
        },error:(err)=>{
          this.toastrService.error(this.translateService.instant("global.errorOccurred"));
        }
      })
    }else{
      this.toastrService.error(this.translateService.instant("global.pleaseCheckForm"));
    }
  }

  


}
