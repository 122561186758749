import { Component, OnInit } from '@angular/core';
import { ApiUrlService } from '../../../services/api/api-url.service';

@Component({
  selector: 'lib-get-tenants-table',
  templateUrl: './get-tenants-table.component.html',
  styleUrls: ['./get-tenants-table.component.css']
})
export class GetTenantsTableComponent implements OnInit {
  tableUrl:string;
  constructor(private apiUrlService:ApiUrlService) { }

  ngOnInit(): void {
    this.tableUrl = this.apiUrlService.getApiUrl() + "api/cms/admin/multitenancy/tenant/list"
  }

}
