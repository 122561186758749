<div class="text-preview-content">
    <div class="preview-menu">
        <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" (click)="toggle(false)"  [class.active]="preview == false" style="cursor: pointer;" aria-current="page">Text</a>
            </li>
            <li class="nav-item" *ngIf="allowHtml">
              <a class="nav-link" (click)="toggle(true)" [class.active]="preview == true" style="cursor: pointer;">HTML Preview</a>
            </li>
          </ul>
    </div>
    <div class="previewArea">
        <div class="textPreview" *ngIf="preview == false">
            <pre style="white-space: pre-wrap;">{{text}}</pre>
        </div>
        <div [class.displayNone]="preview == false" class="htmlPreview">
            <iframe #frame *ngIf="uuid" [class]="'htmlPreviewFrame_'+uuid">

            </iframe>
        </div>
    </div>
</div>
