<h3>{{'menuitem.details.detailsTitle' | translate}}</h3>
<div *ngIf="itemDetails" class="details-main">
    <div *ngIf="itemDetails.id"><b>{{'menuitem.details.idField' | translate}} : </b>
        <font>{{itemDetails.id}}</font>
    </div>
    <div *ngIf="itemDetails.menuId"><b>{{'menuitem.details.menuidField' | translate}} : </b>
        <font>{{itemDetails.menuId}}</font>
    </div>
    <div *ngIf="itemDetails.title"><b>{{'menuitem.details.titleField' | translate}} : </b>
        <font>{{itemDetails.title}}</font>
    </div>
    <div *ngIf="itemDetails.tooltip"><b>{{'menuitem.details.tooltipField' | translate}} : </b>
        <font>{{itemDetails.tooltip}}</font>
    </div>
    <div *ngIf="itemDetails.cssClass"><b>{{'menuitem.details.cssClassField' | translate}} : </b>
        <font>{{itemDetails.cssClass}}</font>
    </div>
    <div *ngIf="itemDetails.preIcon"><b>{{'menuitem.details.preIconField' | translate}} : </b>
        <font>{{itemDetails.preIcon}}</font>
    </div>
    <div *ngIf="itemDetails.postIcon"><b>{{'menuitem.details.postIconField' | translate}} : </b>
        <font>{{itemDetails.postIcon}}</font>
    </div>
    <div *ngIf="itemDetails.target"><b>{{'menuitem.details.targetField' | translate}} : </b>
        <font>{{itemDetails.target}}</font>
    </div>
    <div *ngIf="itemDetails.url"><b>{{'menuitem.details.urlField' | translate}} : </b>
        <font>{{itemDetails.url}}</font>
    </div>
    <div *ngIf="itemDetails.enabled"><b>{{'menuitem.details.enabledField' | translate}} : </b>
        <input type="checkbox" class="form-check-input" [checked]="itemDetails.enabled"/>
    </div>
    <div *ngIf="itemDetails.loadAction"><b>{{'menuitem.details.loadActionField' | translate}} : </b>
        <font>{{itemDetails.loadAction}}</font>
    </div>
    <div *ngIf="itemDetails.translations" class="translations">
        <h5>{{'menuitem.details.translationsTitle' | translate}} <button [routerLink]="'/navigation/menu/'+itemDetails.menuId+'/items/'+itemDetails.id+'/localize/list'" class="btn btn-sm btn-warning"><i class="bi bi-pencil"></i></button></h5>
        <ul>
        <div style="display: inline;" *ngFor="let translation of itemDetails.translations | keyvalue">
            <li><b>{{translation.key}}</b> : <font>{{translation.value}}</font></li>
        </div></ul>
    </div>
</div>
<h4 *ngIf="itemDetails && itemDetails.children && itemDetails.children.length > 0">{{'menuitem.details.childrenTitle' | translate}}</h4>
<div class="">
    <div class="childDivMain" *ngIf="itemDetails && itemDetails.children">

        <div class="details-main childDiv" *ngFor="let item of itemDetails.children">
            <div *ngIf="item.id"><b>{{'menuitem.details.idField' | translate}} : </b>
                <font>{{item.id}}</font>
            </div>
            <div *ngIf="item.menuId"><b>{{'menuitem.details.menuidField' | translate}} : </b>
                <font>{{item.menuId}}</font>
            </div>
            <div *ngIf="item.title"><b>{{'menuitem.details.titleField' | translate}} : </b>
                <font>{{item.title}}</font>
            </div>
            <div *ngIf="item.tooltip"><b>{{'menuitem.details.tooltipField' | translate}} : </b>
                <font>{{item.tooltip}}</font>
            </div>
            <div *ngIf="item.cssClass"><b>{{'menuitem.details.cssClassField' | translate}} : </b>
                <font>{{item.cssClass}}</font>
            </div>
            <div *ngIf="item.preIcon"><b>{{'menuitem.details.preIconField' | translate}} : </b>
                <font>{{item.preIcon}}</font>
            </div>
            <div *ngIf="item.postIcon"><b>{{'menuitem.details.postIconField' | translate}} : </b>
                <font>{{item.postIcon}}</font>
            </div>
            <div *ngIf="item.target"><b>{{'menuitem.details.targetField' | translate}} : </b>
                <font>{{item.target}}</font>
            </div>
            <div *ngIf="item.url"><b>{{'menuitem.details.urlField' | translate}} : </b>
                <font>{{item.url}}</font>
            </div>
            <div *ngIf="item.enabled"><b>{{'menuitem.details.enabledField' | translate}} : </b>
                <input type="checkbox" class="form-check-input" [checked]="item.enabled"/>
            </div>
            <div *ngIf="item.loadAction"><b>{{'menuitem.details.loadActionField' | translate}} : </b>
                <font>{{item.loadAction}}</font>
            </div>
            <div *ngIf="item.translations" class="translations">
                <h5>{{'menuitem.details.translationsTitle' | translate}} <button [routerLink]="'/navigation/menu/'+item.menuId+'/items/'+item.id+'/localize/list'" class="btn btn-sm btn-warning"><i class="bi bi-pencil"></i></button></h5>
                <ul>
                <div style="display: inline;" *ngFor="let translation of item.translations | keyvalue">
                    
                    <li><b>{{translation.key}} : </b>
                    <font>{{translation.value}}</font></li>
                </div>
                
                </ul>
            </div>
            <div class="btn btn-sm btn-primary" [routerLink]="'/navigation/menu/'+item.menuId+'/items/'+item.id+'/details'">Detaylar</div>
        </div>
    </div>
</div>
