import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoPartService {

  constructor(private httpClient:HttpClient) { }
  sendVideoPartToServer(formData:FormData,sessionId:string){
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    //return this.httpClient.post("https://localhost:61140/api/v2/sessions/"+sessionId+"/webrtc/recordings/add",formData,{headers:headers});
    return this.httpClient.post("/api/v2/sessions/"+sessionId+"/webrtc/recordings/add",formData,{headers:headers});
  }
  getKeyForSendVideoPartToServer(sessionId:string,recordType:number){
    //return this.httpClient.post("https://localhost:61140/api/v2/sessions/"+sessionId+"/webrtc/recordings/start",{sessionId:sessionId,type:recordType});
    return this.httpClient.post("/api/v2/sessions/"+sessionId+"/webrtc/recordings/start",{sessionId:sessionId,recordType:recordType});
  }
  endVideoPart(sessionId:string,recordKey:string){
    //return this.httpClient.post("https://localhost:61140/api/v2/sessions/"+sessionId+"/webrtc/recordings/end",{sessionId:sessionId,recordKey:recordKey});
    return this.httpClient.post("/api/v2/sessions/"+sessionId+"/webrtc/recordings/end",{sessionId:sessionId,recordKey:recordKey});
  }
}
