import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';

@Component({
  selector: 'lib-virtual-lab-list',
  templateUrl: './virtual-lab-list.component.html',
  styleUrls: ['./virtual-lab-list.component.css']
})
export class VirtualLabListComponent implements OnInit {
  apiUrl:string;
  constructor() { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
  }

}
