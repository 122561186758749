import { environment } from './../../../environments/environment.prod';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomTableMainModel } from '../../models/CustomTableModels/customTableMainModel';

@Injectable({
  providedIn: 'root'
})
export class CustomTableService {

  constructor(private httpClient:HttpClient) { }
  public externalUrl = "";
  getTableApiUrl(tableName:string):string{
    console.log(this.externalUrl + "dis api url")
    if(this.externalUrl != ""){
      return this.externalUrl;
    }else{
      var apiUrl = environment.apiUrl+"api/tables/"+tableName;
      return apiUrl;
    }

  }
  getTable(tableName:string,queryString?:string):Observable<CustomTableMainModel>{
    let apiUrl = this.getTableApiUrl(tableName);
    var tableUrl:string;
    if(queryString){
      if(apiUrl.includes("?")){
        tableUrl = apiUrl+"&"+queryString;
      }else{
        tableUrl = apiUrl+"?"+queryString;
      }
      return this.httpClient.get<CustomTableMainModel>(tableUrl);
    }else{
      return this.httpClient.get<CustomTableMainModel>(apiUrl);
    }




  }
}
