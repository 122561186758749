
<h5>{{'sessionManagement.addNewFeature' | translate}}</h5>
<!--<div>
    İçe aktarma özelliği iptal ! İçe aktarma işlemini oturum oluşturma sayfasına taşıdık.
    <label><b>Özellikleri şablondan içe aktar </b></label>
    <lib-import-features [sessionId]="sessionId"></lib-import-features>
</div>-->
<hr>

<div style="display: flex;">
<select id="featureSelect" (change)="getData($event)" class="form-select">
    <option *ngFor="let featureType of featureList" [value]="featureType.value">{{featureType.label}}</option>
</select>

</div>
<lib-custom-form *ngIf="featureConfiguration && show == true && loading == false" (GetSendingObjects)="getJsonData($event)" [externalFormData]="featureConfiguration" [externalAPIUrl]="''"></lib-custom-form>
<br/>
<div class="clearfix" *ngIf="loading">
    <div class="spinner-border float-end" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>